import _ from 'lodash';
import { CreatePharmacyOrderState, CreatePharmacyOrderAction } from './types';

export const getCreatePharmacyOrderDefaultInitialState = (): CreatePharmacyOrderState => {
  return {
    articles: [],
    invalidInputs: [],
  } as CreatePharmacyOrderState;
};

export const CreatePharmacyOrderReducer = (
  state: CreatePharmacyOrderState,
  action: CreatePharmacyOrderAction,
) => {
  const newState = { ...state };
  switch (action.type) {
    case 'setArticles':
      newState.articles = _.concat(state.articles, action.payload);
      return newState;

    case 'removeArticle':
      newState.articles = state.articles.filter(
        item => item.idArticle !== action.payload,
      );
      return newState;

    case 'setUnitPriceArticle': {
      const articleId = action.payload.id;
      const articlePrice = action.payload.value;

      return {
        ...state,
        articles: state.articles.map(r =>
          r.idArticle === articleId ? { ...r, unitPrice: articlePrice } : r,
        ),
      };
    }

    case 'setQuantityArticle': {
      const articleId = action.payload.id;
      const articleValue = action.payload.value;

      return {
        ...state,
        articles: state.articles.map(r =>
          r.idArticle === articleId ? { ...r, quantityOrdered: articleValue } : r,
        ),
      };
    }

    case 'resetArticles':
      newState.articles = [];
      return newState;

    default:
      return state;
  }
};
