/* eslint-disable react/no-unescaped-entities */
import { App as AppContainer, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import _ from 'lodash';
import { useEffect, useReducer, useState } from 'react';
import { BsNodePlus, BsPrinter } from 'react-icons/bs';
import { useMediaQuery } from 'react-responsive';
import {
  createPrescriptionApi,
  searchPrescriptionsApi,
} from '../../../api/prescriptionService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import Loader from '../../../components/loader';
import ResponsiveTable from '../../../components/responsiveTable';
import { StyledCol, StyledRow, StyledSpace } from '../../../components/style';
import PdfDrawerViewer from '../../../components/viewer/PdfDrawerViewer';
import {
  CreatePrescriptionReducer,
  getCreatePrescriptionDefaultInitialState,
} from '../../../reducers/prescription/createPrescription';
import chartColors from '../../../styles/color';
import {
  CreateMedicalRecordApiResponse,
  CreatePrescriptionRequestBody,
  IMedicationPrescription,
  IPrescriptionItem,
  SearchPrescriptionResponse,
  UserDto,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import {
  ErrorMsgTitle,
  SuccesssMsgTitle,
  WarningMsgTitle,
} from '../../../utils/constants';
import { getDataFromStorage } from '../../../utils/storage';
import RenderDocument from '../../document';
import { StyledMedicalRecordBlocContainer } from '../../style';
import AddRemoveMedication from './medication/AddRemoveMedication';

type PrescriptionHistoryProps = IPrescriptionItem;

const PrescriptionHistory: React.FC = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const { notification } = AppContainer.useApp();

  const [state, reducer] = useReducer(
    CreatePrescriptionReducer,
    getCreatePrescriptionDefaultInitialState(),
  );

  const [prescriptionsData, setPrescriptionsData] = useState<SearchPrescriptionResponse>(
    [],
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPrescriptionList, setShowPrescriptionList] = useState<boolean>(true);
  const [showModalMedication, setShowModalMedication] = useState<boolean>(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedPrescriptionsRow, setSelectedPrescriptionsRow] =
    useState<SearchPrescriptionResponse>([]);
  const [showPrescriptionPdf, setShowPrescriptionPdf] = useState<boolean>(false);

  const medicalRecord =
    getDataFromStorage<CreateMedicalRecordApiResponse>('medicalRecord');
  const user = getDataFromStorage<UserDto>('connectedUserData');

  const loadPatientPrescriptions = async () => {
    setIsLoading(true);
    Promise.resolve(
      searchPrescriptionsApi({
        idMedicalRecord: medicalRecord.id,
        idPatient: medicalRecord.patientDto.id as number,
        paid: false,
      }),
    )
      .then(result => {
        setPrescriptionsData(result);
        setIsLoading(false);
      })
      .catch(error => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadPatientPrescriptions();
  }, []);

  const columnData: ColumnsType<PrescriptionHistoryProps> = [
    {
      title: 'Numéro',
      dataIndex: 'id',
    },
    {
      title: 'Date',
      dataIndex: 'createdDate',
    },
    {
      title: 'Nbr. médicaments',
      render: (value, record) => <>{record.prescriptionLineDto.length} médicaments</>,
    },
    // {
    //   align: 'left',
    //   render: () => {
    //     return (
    //       <StyledSpace>
    //         <StyledButton shape='circle' size='middle' icon={<FiPrinter />} />
    //         <StyledButton shape='circle' size='middle' icon={<FiEdit2 />} />
    //       </StyledSpace>
    //     );
    //   },
    // },
  ];

  const mapStateToRequestBody = (): CreatePrescriptionRequestBody => {
    const medications = state.prescriptionLines.map(item => {
      delete item.id;
      return item;
    });
    const result: CreatePrescriptionRequestBody = {
      idPatient: medicalRecord.patientDto.id ?? null,
      idMedecin: user.id,
      idMedicalRecord: medicalRecord.id,
      prescriptionLines: medications,
    } as CreatePrescriptionRequestBody;
    return result;
  };

  const createPrescription = async () => {
    setIsLoading(true);
    try {
      const allPropertiesFilled = state.prescriptionLines.every(
        (medication: IMedicationPrescription) => {
          return (
            medication.posology.trim() !== '' &&
            medication.treatmentDuration.trim() !== '' &&
            medication.quantity !== 0
          );
        },
      );

      if (!allPropertiesFilled) {
        setIsLoading(false);
        notification.warning({
          message: WarningMsgTitle,
          description:
            'Les quantités, durées de traitement et les posologies sont obligatoires',
        });
      } else {
        createPrescriptionApi(mapStateToRequestBody()).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: 'La prescription a bien été créée.',
          });
          loadPatientPrescriptions();
          resetPrescription();
          setIsLoading(false);
        });
      }
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
      setIsLoading(false);
    }
  };

  const resetPrescription = () => {
    setShowPrescriptionList(true);
    reducer({ type: 'setResetPrescriptionMedications' });
  };

  const resetPrescriptionPdfViewer = () => {
    setSelectedPrescriptionsRow([]);
    setSelectedRowKeys([]);
    setShowPrescriptionPdf(false);
  };

  const handleDisplayPrescrptionPdf = () => {
    setShowPrescriptionPdf(true);
  };

  const getData = () => {
    const datas: string[][] = [];
    prescriptionsData.forEach(item => {
      datas.push([item.createdDate, 'pdf']);
    });
    return datas;
  };

  const onSelectPrescriptionChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: IPrescriptionItem[],
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedPrescriptionsRow(selectedRows);
  };

  const prescriptionRowSelection: TableRowSelection<IPrescriptionItem> = {
    selectedRowKeys,
    getCheckboxProps: (record: IPrescriptionItem) => ({
      disabled: selectedRowKeys.length === 1 && !selectedRowKeys.includes(record.id),
      // (record.treated === true && record.idInvoiceRef > 0) ||
      // name: record.invoicePlanHeadLabel,
    }),
    onChange: onSelectPrescriptionChange,
    selections: [Table.SELECTION_NONE],
    hideSelectAll: true,
  };

  const handleModalMedication = () => {
    setShowModalMedication(state => !state);
  };

  const renderValidationComponent = () => {
    return (
      <>
        {!_.isEmpty(state.prescriptionLines) && (
          <StyledButton
            type='primary'
            loading={isLoading}
            disabled={isLoading}
            onClick={() => createPrescription()}>
            Créer une prescription
          </StyledButton>
        )}
        <StyledButton
          danger
          type='text'
          disabled={isLoading}
          onClick={() => resetPrescription()}>
          Annuler la prescription
        </StyledButton>
      </>
    );
  };

  const renderModalMedicationActions = () => {
    return (
      <StyledSpace>
        <StyledButton
          loading={isLoading}
          disabled={isLoading}
          size='middle'
          icon={<BsNodePlus />}
          block
          type='dashed'
          onClick={handleModalMedication}>
          Sélectionnez un médicament
        </StyledButton>
        {renderValidationComponent()}
      </StyledSpace>
    );
  };

  const renderSelectedMedications = () => {
    return (
      <>
        <SectionHeader title='Nouvelle prescription'>
          {renderModalMedicationActions()}
        </SectionHeader>
        <StyledRow gutter={[20, 20]}>
          <StyledCol span={24}>
            <AddRemoveMedication
              key={'medication'}
              listTitle='Sélectionnez des médicaments'
              reducer={reducer}
              state={state}
              isLoading={false}
              userData={user}
              isModalVisible={showModalMedication}
              handleCloseModalVisibility={handleModalMedication}
            />
          </StyledCol>
        </StyledRow>
      </>
    );
  };

  const renderPrescriptionsListActions = () => {
    return (
      <StyledSpace>
        {_.isEmpty(selectedRowKeys) ? (
          <StyledButton
            block
            type='primary'
            loading={isLoading}
            disabled={isLoading}
            onClick={() => setShowPrescriptionList(false)}>
            Créer une ordonnance
          </StyledButton>
        ) : (
          <>
            <StyledButton
              type='primary'
              style={{ backgroundColor: chartColors.success }}
              loading={isLoading}
              disabled={isLoading}
              icon={<BsPrinter />}
              onClick={() => handleDisplayPrescrptionPdf()}>
              Voir l'ordonnance
            </StyledButton>
            {/* <StyledButton
              type='dashed'
              loading={isLoading}
              disabled={isLoading}
              icon={<MdAutorenew />}
              onClick={() => console.log('r')}>
              Renouveler
            </StyledButton> */}
          </>
        )}
      </StyledSpace>
    );
  };

  const renderPrescriptionsList = () => {
    return (
      <StyledMedicalRecordBlocContainer
        style={{
          backgroundColor: '#FFF',
          padding: '30px 20px',
        }}>
        <SectionHeader title='Mes prescriptions'>
          {renderPrescriptionsListActions()}
        </SectionHeader>
        {isTabletOrMobile ? (
          <ResponsiveTable headerNames={['Date', 'Document']} data={getData()} />
        ) : (
          <Table<PrescriptionHistoryProps>
            rowKey='id'
            dataSource={prescriptionsData}
            size='small'
            columns={columnData}
            pagination={false}
            bordered={false}
            rowSelection={prescriptionRowSelection}
          />
        )}
      </StyledMedicalRecordBlocContainer>
    );
  };

  const renderContent = () => {
    return (
      <StyledRow justify='end' gutter={[16, 16]}>
        <StyledCol span={24}>
          <StyledMedicalRecordBlocContainer style={{ padding: '10px 10px' }}>
            {showPrescriptionList
              ? renderPrescriptionsList()
              : renderSelectedMedications()}
          </StyledMedicalRecordBlocContainer>
        </StyledCol>
      </StyledRow>
    );
  };

  return (
    <>
      <Loader showSpinner={isLoading}>{renderContent()}</Loader>

      {/* Afficher l'ordonnance sélectionné*/}
      {showPrescriptionPdf && (
        <PdfDrawerViewer
          drawerTitle={`Apercu de l'ordonnance`}
          closeDrawer={resetPrescriptionPdfViewer}
          extraActions={
            <>
              <StyledButton
                key='cancel_viewer'
                type='primary'
                size='middle'
                danger
                disabled={isLoading}
                onClick={resetPrescriptionPdfViewer}>
                Quitter
              </StyledButton>
            </>
          }
          isDrawerOpen={showPrescriptionPdf}>
          <RenderDocument<IPrescriptionItem>
            documentData={selectedPrescriptionsRow[0]}
            documentType='PRESCRIPTION_PDF'
            isDuplicata={false}
          />
        </PdfDrawerViewer>
      )}
    </>
  );
};

export default PrescriptionHistory;
