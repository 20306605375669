import { App as AppContainer } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { FaUserMd } from 'react-icons/fa';
import { FiRefreshCcw } from 'react-icons/fi';
import { getServicesApi } from '../../api/referentialService';
import { getDoctorsApi, searchUsersLightApi } from '../../api/userService';
import { StyledButton } from '../../components/button/style';
import EmptyNotFound from '../../components/emptyData/EmptyNotFound';
import FormFieldLabel from '../../components/label/FormFieldLabel';
import Loader from '../../components/loader';
import PageHeader from '../../components/pageHeader/PageHeader';
import {
  StyledCol,
  StyledRow,
  StyledSelectOption,
  StyledSelectPicker,
  StyledSpace,
} from '../../components/style';
import PageContentLeftLayout from '../../layout/PageContentLeftLayout';
import PageContentRightLayout from '../../layout/PageContentRightLayout';
import VerticalLayout from '../../layout/VerticalLayout';
import { StyledLayoutGridWrapper } from '../../layout/style';
import chartColors from '../../styles/color';
import {
  GetServicesResponse,
  GetUsersResponse,
  SearchUserRequestBody,
  UserDto,
} from '../../types';
import { getApiErrorMsg, mapStateToRequestBody } from '../../utils/commun';
import { ErrorMsgTitle } from '../../utils/constants';
import { StyleListContainer, StyledBlocContainer } from '../style';
import MedecinAppointment from './MedecinAppointment';

const RendezVous: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [medecins, setMedecins] = useState<GetUsersResponse>([]);
  const [usersSearch, setUsersSearch] = useState<GetUsersResponse>([]);
  const [services, setServices] = useState<GetServicesResponse>([]);
  const [selectedMedecin, setSelectedMedecin] = useState<number | null>(null);
  const [selectedService, setSelectedService] = useState<number | null>(null);
  const [, setCurrentMedecin] = useState<UserDto>();
  const { notification } = AppContainer.useApp();

  const loadFiltersData = () => {
    setIsLoading(true);
    Promise.all([getDoctorsApi(), getServicesApi()])
      .then(result => {
        setUsersSearch(result[0]);
        setServices(result[1]);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetSearch = () => {
    setMedecins([]);
    setSelectedMedecin(null);
    setSelectedService(null);
  };

  const searchMedecins = () => {
    try {
      setIsLoading(true);
      Promise.resolve(
        searchUsersLightApi(
          mapStateToRequestBody<SearchUserRequestBody>({
            idService: selectedService,
            idUser: selectedMedecin,
          }),
        ),
      ).then(res => {
        setMedecins(res);
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
    }
  };

  useEffect(() => {
    loadFiltersData();
  }, []);

  const renderMedecinAppointments = () => {
    if (_.isEmpty(medecins)) {
      return (
        <EmptyNotFound
          message='Aucun médecin trouvé'
          description='Veuillez effectuer une rechercher pour voir les médecins'
          icon={<FaUserMd size={100} />}
        />
      );
    }
    return _.map(medecins, item => (
      <MedecinAppointment medecinData={item} key={item.id} />
    ));
  };

  const renderHeader = () => {
    return <PageHeader title='Rendez-vous' />;
  };

  const renderSearchFilters = () => {
    return (
      <StyledBlocContainer
        style={{
          backgroundColor: `${chartColors.blue50}20`,
          padding: '20px 20px',
        }}>
        <StyledRow gutter={[12, 12]}>
          <StyledCol span={24}>
            <FormFieldLabel libelle='Service' />
            <StyledSelectPicker
              placeholder='Sélectionner un service'
              onClear={() => console.log('Reset selected')}
              style={{ width: '100%' }}
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              onChange={value => {
                setSelectedService(value === 0 ? null : (value as number));
              }}
              defaultValue={''}
              value={selectedService}>
              {_.map(services, item => (
                <StyledSelectOption
                  key={item.id}
                  value={item.id}
                  data={item}
                  label={item.name}>
                  {item.name}
                </StyledSelectOption>
              ))}
            </StyledSelectPicker>
          </StyledCol>

          <StyledCol span={24}>
            <FormFieldLabel libelle='Médecin' />
            <StyledSelectPicker
              placeholder='Sélectionner un médecin'
              onClear={() => console.log('Reset selected')}
              style={{ width: '100%' }}
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value, option: DefaultOptionType['data']) => {
                setSelectedMedecin(value === 0 ? null : (value as number));
                setCurrentMedecin(option?.data ?? null);
              }}
              defaultValue={''}
              value={selectedMedecin}>
              {_.map(usersSearch, item => (
                <StyledSelectOption
                  key={item.id}
                  value={item.id}
                  data={item}
                  label={`${item.firstname} ${item.name}`}>
                  {`${item.firstname} ${item.name}`}
                </StyledSelectOption>
              ))}
            </StyledSelectPicker>
          </StyledCol>

          <StyledCol span={24}>
            <StyledSpace style={{ width: '100%' }}>
              <StyledButton
                type='primary'
                size='middle'
                block
                disabled={isLoading}
                onClick={() => searchMedecins()}>
                Rechercher
              </StyledButton>
              <StyledButton
                type='link'
                danger
                size='middle'
                disabled={isLoading}
                icon={<FiRefreshCcw />}
                onClick={() => resetSearch()}
              />
            </StyledSpace>
          </StyledCol>
        </StyledRow>
      </StyledBlocContainer>
    );
  };

  const renderContent = () => {
    return (
      <Loader showSpinner={isLoading}>
        <div style={{ rowGap: '20px' }}>{renderMedecinAppointments()}</div>
      </Loader>
    );
  };

  return (
    <VerticalLayout enableVerticalScroll='false'>
      {renderHeader()}
      <StyledLayoutGridWrapper>
        <PageContentLeftLayout layerShowPadding='false'>
          {renderSearchFilters()}
        </PageContentLeftLayout>
        <PageContentRightLayout layerShowPadding='false'>
          <StyleListContainer
            style={{ padding: 0, height: 'calc(100vh - 100px)', paddingBottom: '250px' }}>
            {renderContent()}
          </StyleListContainer>
        </PageContentRightLayout>
      </StyledLayoutGridWrapper>
      {/* <div style={{ padding: '10px 20px', height: '100%' }}>{renderContent()}</div> */}
    </VerticalLayout>
  );
};

export default RendezVous;
