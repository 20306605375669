/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate, useRouteError } from 'react-router-dom';
import { StyledButton } from '../button/style';
import ErrorHeader from '../header/ErrorHeader';
import { StyledCol, StyledResult, StyledRow, StyledSpace } from '../style';

const AppErrorBoundary = () => {
  const error: any = useRouteError();
  const navigate = useNavigate();

  return (
    <div style={{ height: '100vh' }}>
      <ErrorHeader />
      <StyledResult
        status='error'
        title={`${error.status} ${error.statusText}`}
        subTitle={error.data}
        style={{ marginTop: '5em' }}
        extra={[
          <StyledRow key='actions'>
            <StyledCol span={24}>
              <StyledSpace>
                <StyledButton type='primary' key='back' onClick={() => navigate(-1)}>
                  Retour à la page précédente
                </StyledButton>
              </StyledSpace>
            </StyledCol>
          </StyledRow>,
        ]}>
        {/* <Paragraph>{error.error.stack}</Paragraph> */}
      </StyledResult>
    </div>
  );
};

export default AppErrorBoundary;
