import { App as AppContainer } from 'antd';
import _ from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import {
  createInvoicePlanApi,
  updateInvoicePlanApi,
} from '../../../../api/invoiceService';
import { StyledButton } from '../../../../components/button/style';
import SectionHeader from '../../../../components/header/SectionHeader';
import CustomInput from '../../../../components/input/CustomInput';
import FormFieldLabel from '../../../../components/label/FormFieldLabel';
import Loader from '../../../../components/loader';
import {
  StyledCol,
  StyledDivider,
  StyledRadio,
  StyledRow,
  StyledSpace,
} from '../../../../components/style';
import {
  CreateInvoicePlanReducer,
  getCreateInvoicePlanDefaultInitialState,
} from '../../../../reducers/invoicePlan/createInvoicePlan';
import { CreateInvoicePlanState } from '../../../../reducers/invoicePlan/types';
import {
  CreateInvoicePlanRequestBody,
  InvoicePlanDataProps,
  InvoiceViewType,
} from '../../../../types';
import { getApiErrorMsg } from '../../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../../utils/constants';
import { StyledBlocContainer } from '../../../style';

interface InvoicePlanFormProps {
  toggleInvoicePlanForm: (arg: InvoiceViewType) => void;
  currentInvoicePlan?: InvoicePlanDataProps;
}

const InvoicePlanForm: React.FC<InvoicePlanFormProps> = ({
  toggleInvoicePlanForm,
  currentInvoicePlan,
}) => {
  const { notification } = AppContainer.useApp();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Label Invoice Plan
  const [, setIsLabelInvoicePlanValid] = useState<boolean | undefined>();
  const [, setIsLabelInvoicePlanFocused] = useState<boolean>(false);
  const [labelInvoicePlanErrorMsg, setLabelInvoicePlanErrorMsg] = useState<string>('');

  const [state, reducer] = useReducer(
    CreateInvoicePlanReducer,
    getCreateInvoicePlanDefaultInitialState(),
  );

  useEffect(() => {
    if (!_.isUndefined(currentInvoicePlan)) {
      reducer({
        type: 'setLabelInvoicePlan',
        payload: currentInvoicePlan.label,
      });
      reducer({
        type: 'setActiveInvoicePlan',
        payload: currentInvoicePlan.active,
      });
    }
  }, []);

  const mapStateToRequestBody = (
    state: CreateInvoicePlanState,
  ): CreateInvoicePlanRequestBody => {
    const result = {
      active: state.active,
      label: state.label,
    } as CreateInvoicePlanRequestBody;
    return result;
  };

  const areRequiredInputValid = () => {
    if (!state.label || state.label.trim().length === 0) {
      setIsLabelInvoicePlanValid(false);
      setLabelInvoicePlanErrorMsg('Libellé obligatoire');
      return false;
    } else {
      if (labelInvoicePlanErrorMsg) {
        setLabelInvoicePlanErrorMsg('');
      }
    }
    return true;
  };

  const createInvoicePlan = async () => {
    if (!areRequiredInputValid()) {
      return;
    }
    try {
      setIsLoading(true);

      await createInvoicePlanApi(mapStateToRequestBody(state)).then(() => {
        notification.success({
          message: SuccesssMsgTitle,
          description: 'Le plan de facture a bien été créé.',
        });

        setTimeout(() => {
          toggleInvoicePlanForm('INVOICE_PLAN_LIST');
        }, 1000);
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateInvoicePlan = async () => {
    if (!areRequiredInputValid()) {
      return;
    }
    try {
      setIsLoading(true);
      const result = await updateInvoicePlanApi(
        currentInvoicePlan?.id as number,
        mapStateToRequestBody(state),
      );
      notification.success({
        message: SuccesssMsgTitle,
        description: 'Le plan de facture a bien été modifié.',
      });
      console.log(result);
      setTimeout(() => {
        toggleInvoicePlanForm('INVOICE_PLAN_LIST');
      }, 1000);
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderHeader = () => {
    return (
      <SectionHeader
        title={
          _.isUndefined(currentInvoicePlan)
            ? 'Nouveau plan de facture'
            : 'Modification de plan de facture'
        }
        onBack={() => toggleInvoicePlanForm('INVOICE_PLAN_LIST')}
      />
    );
  };

  const renderConfirmButton = () => {
    return _.isUndefined(currentInvoicePlan) ? (
      <StyledButton
        type='primary'
        loading={isLoading}
        disabled={isLoading}
        onClick={() => createInvoicePlan()}>
        Créer plan de facture
      </StyledButton>
    ) : (
      <StyledButton
        type='primary'
        loading={isLoading}
        disabled={isLoading}
        onClick={() => updateInvoicePlan()}>
        Modifier le plan de facture
      </StyledButton>
    );
  };

  const renderForm = () => {
    return (
      <StyledRow gutter={[20, 20]}>
        <StyledCol span={24}>
          <FormFieldLabel libelle='Libellé du plan de facture' isRequired={true} />
          <CustomInput
            name='label'
            placeholder='Libellé du plan de facture'
            type='text'
            required
            handleInputChange={val => {
              reducer({
                type: 'setLabelInvoicePlan',
                payload: val,
              });
            }}
            handleInputBlur={val => {
              setIsLabelInvoicePlanFocused(false);
              if (!val || val.trim().length === 0) {
                setIsLabelInvoicePlanValid(false);
              } else {
                setIsLabelInvoicePlanValid(true);
              }
            }}
            error={labelInvoicePlanErrorMsg}
            value={state.label}
            isDisabled={isLoading}
          />
        </StyledCol>
        <StyledCol span={24}>
          <StyledSpace direction='horizontal' align='baseline'>
            <FormFieldLabel libelle='Etat plan du facture' isRequired={true} />
            <StyledRadio.Group
              size='large'
              onChange={e => {
                reducer({
                  type: 'setActiveInvoicePlan',
                  payload: e.target.value,
                });
              }}
              value={state.active}
              disabled={isLoading}>
              <StyledRadio value={true}>Actif</StyledRadio>
              <StyledRadio value={false}>Inactif</StyledRadio>
            </StyledRadio.Group>
          </StyledSpace>
        </StyledCol>
        <StyledCol span={24}>{renderConfirmButton()}</StyledCol>
      </StyledRow>
    );
  };

  const renderContent = () => {
    return (
      <>
        {renderHeader()}
        <StyledBlocContainer style={{ padding: 20 }}>
          <StyledDivider />
          {renderForm()}
        </StyledBlocContainer>
      </>
    );
  };

  return <Loader showSpinner={isLoading}>{renderContent()}</Loader>;
};
export default InvoicePlanForm;
