import { Space } from 'antd';
import { PropsWithChildren } from 'react';
import { StyledPrimarytext } from '../../containers/style';
import {
  StyledPageHeaderActions,
  StyledPageHeaderContainer,
  StyledPageHeaderTitle,
  StyledPageHeaderWrapper,
} from './style';
import { StyledButton } from '../button/style';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';

interface PageHeaderProps {
  title: string;
  count?: number;
  showBack?: boolean;
  subContent?: React.ReactNode;
  breadcrumbs?: [];
}

const PageHeader: React.FC<PropsWithChildren<PageHeaderProps>> = ({
  title,
  count,
  children,
  showBack,
  subContent,
  breadcrumbs,
}) => {
  const navigate = useNavigate();
  return (
    <StyledPageHeaderWrapper>
      <StyledPageHeaderContainer>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {showBack && (
            <StyledButton
              shape='circle'
              size='middle'
              type='default'
              onClick={() => navigate(-1)}
              icon={<BsArrowLeft />}
              style={{ marginRight: '5px' }}
            />
          )}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <StyledPageHeaderTitle>
              {count && count > 0 ? (
                <StyledPrimarytext style={{ marginRight: '5px' }}>
                  {count}
                </StyledPrimarytext>
              ) : null}
              {`${title}${count && count > 1 ? 's' : ''}`} {subContent && subContent}
            </StyledPageHeaderTitle>
            {breadcrumbs && <div>dqsdksds</div>}
          </div>
        </div>

        <StyledPageHeaderActions>
          {/* Liste des actions près d'un entete */}
          <Space>{children}</Space>
        </StyledPageHeaderActions>
      </StyledPageHeaderContainer>
    </StyledPageHeaderWrapper>
  );
};

export default PageHeader;
