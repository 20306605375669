export enum SettingItemKeys {
  GENERAL = 'GENERAL',
  INVOICE_PLAN = 'PLAN_FACTURE',
  INVOICE_RUBRIC = 'RUBRIQUE_FACTURE',
  NOTIFICATION = 'NOTIFICATION',
  USERS = 'USERS',
  ROLES = 'ROLES',
  SITE = 'SITE',
  RATIO = 'RATIO',
  INSURANCE = 'INSURANCE',
  ACTS = 'ACTS',
  RECEIPT_STAMP = 'RECEIPT_STAMP',
  SUBSCRIBERS = 'SUBSCRIBERS',
}
export type SettingItemKeyType = 'GENERAL' | 'PLAN_FACTURE' | 'NOTIFICATION';

export type ISettingMenuItem = ConfigMenuItemProps<SettingItemKeys>;

export interface ParameterItem {
  id?: number;
  value: string | number;
  name?: string;
}

export type ConfigMenuItemProps<K> = {
  key: K;
  name: string;
  component: JSX.Element;
  icon: JSX.Element;
  value: number;
  darkBg?: boolean;
};

export interface IConfigMenu<K> {
  menuItems: Array<ConfigMenuItemProps<K>>;
  activeTab: K;
  handleSelectMenuRef: (arg: ConfigMenuItemProps<K>) => void;
}
