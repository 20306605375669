import { useState } from 'react';
import { IReceiptStampItem, ReferentialViewProps } from '../../../types';
import ReceiptStampsList from './ReceiptStampsList';

const ReceiptStampSetting = () => {
  const [activeView, setActiveView] = useState<ReferentialViewProps>('LIST_ITEMS');
  const [, setSelectedStamp] = useState<IReceiptStampItem>();

  const handleNavigateToView = (newView: ReferentialViewProps) => {
    setActiveView(newView);
  };

  const handleUpdateSelectedRatio = (item?: IReceiptStampItem) => {
    setSelectedStamp(item);
  };

  const renderContent = () => {
    switch (activeView) {
      case 'LIST_ITEMS':
        return (
          <ReceiptStampsList
            navigateToview={handleNavigateToView}
            updateCurrentItem={handleUpdateSelectedRatio}
          />
        );
    }
  };

  return <>{renderContent()}</>;
};

export default ReceiptStampSetting;
