import _ from 'lodash';
import {
  CreateHospitPrescriptionAction,
  CreateHospitalizationPrescriptionState,
} from './types';

export const getCreateHospitPrescriptionDefaultInitialState =
  (): CreateHospitalizationPrescriptionState => {
    return {
      prescriptionLineHospitalization: [],
      invalidInputs: [],
    } as CreateHospitalizationPrescriptionState;
  };

export const CreateHospitPrescriptionReducer = (
  state: CreateHospitalizationPrescriptionState,
  action: CreateHospitPrescriptionAction,
) => {
  const newState = { ...state };
  switch (action.type) {
    case 'setHospiPrescriptionLine':
      newState.prescriptionLineHospitalization = _.concat(
        state.prescriptionLineHospitalization,
        action.payload,
      );
      return newState;

    case 'removeHospiPrescriptionLine':
      newState.prescriptionLineHospitalization =
        state.prescriptionLineHospitalization.filter(
          item => item.idHospitalizatrionExam !== action.payload,
        );
      return newState;

    case 'setQuantityHospit': {
      const hospiLineId = action.payload.idHospitalizatrionExam;
      const hospiLineQty = action.payload.value;

      return {
        ...state,
        prescriptionLineHospitalization: state.prescriptionLineHospitalization.map(m =>
          m.idHospitalizatrionExam === hospiLineId ? { ...m, quantity: hospiLineQty } : m,
        ),
      };
    }

    case 'setCommentHospit': {
      const hospiLineId = action.payload.idHospitalizatrionExam;
      const hospiLineComment = action.payload.value;

      return {
        ...state,
        prescriptionLineHospitalization: state.prescriptionLineHospitalization.map(m =>
          m.idHospitalizatrionExam === hospiLineId
            ? { ...m, comments: hospiLineComment }
            : m,
        ),
      };
    }

    case 'setResetHospitPrescrition': {
      newState.prescriptionLineHospitalization = [];
      return newState;
    }

    case 'setInvalidInputs':
      newState.invalidInputs = action.payload;
      return newState;

    default:
      return state;
  }
};
