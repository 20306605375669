import { PDFViewer } from '@react-pdf/renderer';
import {
  Badge,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  FloatButton,
  Input,
  InputNumber,
  List,
  Menu,
  Modal,
  Pagination,
  Popover,
  Radio,
  Result,
  Row,
  Segmented,
  Select,
  Space,
  Spin,
  Steps,
  Tag,
  Timeline,
  Tooltip,
  Typography,
} from 'antd';
import Table from 'antd/es/table';
import { PhoneInput } from 'react-international-phone';
import styled from 'styled-components';
import chartColors from '../styles/color';
import { MenuProps } from '../types';
import { DATE_FORMAT } from '../utils/constants';

export const StyledPageContainer = styled.div`
  width: 85%;
  padding: 0 2rem;
`;

export const StyledTableHeaderWrapper = styled.div`
  display: flex;
  gap: 10rem;
  height: 5rem;
  background-color: ${({ theme }) => theme.colors.blue50};
  text-align: center;
  font-weight: bold;
`;

export const StyledTableHeader = styled.span`
  width: 16rem;
  padding: 1.5rem;
  color: ${({ theme }) => theme.colors.blue};
`;

export const StyledResponsiveTableHeader = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-family: 'Metropolis', sans-serif;
`;

export const StyledResponsiveTableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 3rem;
  border: ${({ theme }) => `1px dashed ${theme.colors.blue}`};
  background: #fff;
  border-radius: 7px;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

export const UserDataWrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  gap: 35rem;
  padding: 2rem;
  color: ${({ theme }) => theme.colors.mainGray};
  @media (max-width: 992px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const StyledResponsiveTableRowValue = styled.div`
  /* color: ${({ theme }) => theme.colors.mainGray}; */
  font-family: 'Metropolis', sans-serif;
  font-weight: 600;
`;

export const StyledTableRowWrapper = styled.div`
  display: flex;
  gap: 10rem;
  height: 5rem;
  background-color: #fff;
  border-top: 1px solid #e9eaec;
  text-align: center;
`;

export const StyledTableRowItem = styled.span`
  width: 16rem;
  padding: 1.5rem;
  color: ${({ theme }) => theme.colors.mainGray};
  font-style: italic;
  cursor: pointer;
`;

export const StyledSelect = styled.select`
  border: 1px solid #ced4da;
  border-radius: 0.4rem;
  padding: 1rem;
  min-width: 20rem;
  @media (max-width: 992px) {
    min-width: auto;
    width: fit-content;
  }
`;

export const StyledContentLayout = styled.div<MenuProps>`
  transition: margin-left 0.2s ease-in-out;
  right: 0%;
  /* height: 100vh; */
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-left: ${({ $isclosed, $isTabletOrMobile }) =>
    $isTabletOrMobile ? '0px' : $isclosed === 'true' ? `70px` : '200px'};
  /* width: ${({ $isclosed, $isTabletOrMobile }) =>
    $isTabletOrMobile
      ? '100%'
      : $isclosed === 'true'
      ? `calc(100vw - 70px)`
      : 'calc(100vw - 200px)'}; */
  /* background-color: red; */
`;

/**
 * Elements de Personnalisés de antd
 */
export const StyledSteps = styled(Steps)``;
export const StyledRadio = styled(Radio)`
  font-size: 1.7rem;
`;
export const StyledCheckbox = styled(Checkbox)`
  font-size: 1.7rem;
`;
export const StyledInput = styled(Input)`
  border-radius: 0.4rem;
`;
export const StyledPasswordInput = styled(Input.Password)`
  border-radius: 0.4rem;
`;
export const StyledInputTextArea = styled(Input.TextArea)``;
export const StyledCol = styled(Col)``;
export const StyledRow = styled(Row)``;
export const StyledSpace = styled(Space)``;
export const StyledTag = styled(Tag)``;
export const StyledSegmented = styled(Segmented)``;

export const StyledDatePicker = styled(DatePicker).attrs(props => ({
  size: props.size || 'middle',
  format: DATE_FORMAT,
}))`
  height: '100%';
  border-radius: 0.4rem;
`;

export const StyledSelectOption = styled(Select.Option).attrs(() => ({}))``;

export const StyledSelectPicker = styled(Select).attrs(props => ({
  size: props.size || 'large',
  showSearch: props.showSearch || true,
}))`
  & .ant-select-selector {
    border-radius: 0.4rem !important;
  }
`;

export const StyledPopover = styled(Popover)``;
export const StyledDivider = styled(Divider)``;
export const StyledList = styled(List)``;

export const StyledPhoneInput = styled(PhoneInput).attrs(props => ({
  defaultCountry: props.defaultCountry || 'ci',
  forceDialCode: true,
}))`
  width: '100%';
`;
export const StyledSpin = styled(Spin)``;
export const StyledModal = styled(Modal)``;
export const StyledTable = styled(Table)``;
export const StyledMenu = styled(Menu)``;
export const StyledTooltip = styled(Tooltip)``;
export const StyledResult = styled(Result)``;
export const StyledTypography = styled(Typography)``;
export const StyledCardItem = styled(Card)`
  &:hover {
    box-shadow: 0px 0px 10px #00000020;
    border: ${() => `1px solid ${chartColors.blue}`};
  }
`;
export const StyledInputNumber = styled(InputNumber)`
  border-radius: 0.4rem;
`;
export const StyledViewer = styled(PDFViewer);

export const StyledDropdown = styled(Dropdown)``;
export const StyledDropdownButton = styled(Dropdown.Button)``;
export const StyledTimeline = styled(Timeline)``;
export const StyledFloatButton = styled(FloatButton)``;
export const StyledPagination = styled(Pagination)``;
export const StyledBadge = styled(Badge)``;
