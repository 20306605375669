import {
  CreateHospitalizationExamRequestBody,
  CreatePrescriptionHospitalizationRequestBody,
  CreatePrescriptionHospitalizationResponse,
  GetHospitalizationExamResponse,
  HospitalizationExamItem,
  SearchHospitalizationPrescriptionsResponse,
  SearchHospitalizationsRequestBody,
  SearchHospitalizationsResponse,
  SearchPrescriptionsRequestBody,
} from '../types';
import { apiRequest } from './axiosInstance';

export const getHospitalizationExamsApi = (): Promise<GetHospitalizationExamResponse> => {
  return apiRequest<void, GetHospitalizationExamResponse>({
    method: 'get',
    url: '/hospitalizationExams',
  });
};

export const searchHospitalizationPrescriptionsApi = (
  data: SearchPrescriptionsRequestBody,
): Promise<SearchHospitalizationPrescriptionsResponse> => {
  return apiRequest<
    SearchPrescriptionsRequestBody,
    SearchHospitalizationPrescriptionsResponse
  >({
    method: 'post',
    url: '/searchHospitalizationPrescriptions',
    data,
  });
};

export const searchHospitalizationApi = (
  data: SearchHospitalizationsRequestBody,
): Promise<SearchHospitalizationsResponse> => {
  return apiRequest<SearchHospitalizationsRequestBody, SearchHospitalizationsResponse>({
    method: 'post',
    url: '/searchHospitalizations',
    data,
  });
};

export const createHospitalizationPrescriptionApi = (
  data: CreatePrescriptionHospitalizationRequestBody,
): Promise<CreatePrescriptionHospitalizationResponse> => {
  return apiRequest<
    CreatePrescriptionHospitalizationRequestBody,
    CreatePrescriptionHospitalizationResponse
  >({
    method: 'post',
    url: '/hospitalizationPrescription',
    data,
  });
};

export const createHospitalizationExamApi = (
  data: CreateHospitalizationExamRequestBody,
): Promise<HospitalizationExamItem> => {
  return apiRequest<CreateHospitalizationExamRequestBody, HospitalizationExamItem>({
    method: 'post',
    url: '/hospitalizationExam',
    data,
  });
};

export const updateHospitalizationExamApi = (
  idExam: number,
  data: CreateHospitalizationExamRequestBody,
): Promise<string> => {
  return apiRequest<CreateHospitalizationExamRequestBody, string>({
    method: 'put',
    url: `/hospitalizationExam?id=${idExam}`,
    data,
  });
};

export const deleteHospitalizationExamApi = (idExam: number): Promise<string> => {
  return apiRequest<void, string>({
    method: 'delete',
    url: `/hospitalizationExam?id=${idExam}`,
  });
};
