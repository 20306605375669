import { Space } from 'antd';
import { PropsWithChildren, ReactNode } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { StyledPrimarytext } from '../../containers/style';
import { StyledButton } from '../button/style';
import {
  StyledSectionHeaderActions,
  StyledSectionHeaderContainer,
  StyledSectionHeaderTitle,
  StyledSectionHeaderWrapper,
} from './style';

interface SectionHeaderProps {
  title: string | JSX.Element;
  count?: number;
  onBack?: () => void;
}

const SectionHeader: React.FC<PropsWithChildren<SectionHeaderProps>> = ({
  title,
  count,
  onBack,
  children,
}) => {
  return (
    <StyledSectionHeaderWrapper>
      <StyledSectionHeaderContainer>
        <StyledSectionHeaderTitle>
          {onBack && (
            <StyledButton
              shape='circle'
              size='middle'
              type='default'
              onClick={onBack}
              icon={<BsArrowLeft />}
              style={{ marginRight: '5px' }}
            />
          )}
          {count && count > 0 ? (
            <StyledPrimarytext style={{ marginRight: '5px' }}>{count}</StyledPrimarytext>
          ) : null}
          {typeof title === 'string' ? `${title}${count && count > 1 ? 's' : ''}` : title}
        </StyledSectionHeaderTitle>
        <StyledSectionHeaderActions>
          {/* Liste des actions près d'un entete */}
          <Space>{children}</Space>
        </StyledSectionHeaderActions>
      </StyledSectionHeaderContainer>
    </StyledSectionHeaderWrapper>
  );
};

export default SectionHeader;
