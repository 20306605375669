import { useState } from 'react';
import { IInvoiceRubricItem, ReferentialViewProps } from '../../../types';
import InvoiceRubricsList from './InvoiceRubricsList';
import InvoiceRubricForm from './InvoiceRubricForm';

const InvoicePlanRubricSetting = () => {
  const [activeView, setActiveView] = useState<ReferentialViewProps>('LIST_ITEMS');
  const [selectedItem, setSelectedItem] = useState<IInvoiceRubricItem>();

  const handleNavigateToView = (newView: ReferentialViewProps) => {
    setActiveView(newView);
  };

  const handleUpdateSelectedItem = (item?: IInvoiceRubricItem) => {
    setSelectedItem(item);
  };

  const renderContent = () => {
    switch (activeView) {
      case 'LIST_ITEMS':
        return (
          <InvoiceRubricsList
            navigateToview={handleNavigateToView}
            updateCurrentItem={handleUpdateSelectedItem}
          />
        );
      case 'EDIT_ITEM':
        return (
          <InvoiceRubricForm
            navigateToview={handleNavigateToView}
            currentData={selectedItem}
          />
        );
      case 'NEW_ITEM':
        return <InvoiceRubricForm navigateToview={handleNavigateToView} />;
    }
  };

  return <>{renderContent()}</>;
};

export default InvoicePlanRubricSetting;
