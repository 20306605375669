import { useState } from 'react';
import PatientHistory from '../../components/patientHistory/PatientHistory';
import { SearchPatientResult } from '../../types';

const ViewPatientHistory: React.FC = () => {
  const [patientInformation] = useState<SearchPatientResult | undefined>();

  return (
    <div style={{ marginTop: '2rem' }}>
      <PatientHistory data={patientInformation} />
    </div>
  );
};

export default ViewPatientHistory;
