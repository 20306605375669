import {
  CompletePrescriptionRequestBody,
  CreateBiologicalPrescriptionRequestBody,
  CreateBiologicalPrescriptionResponse,
  CreateImageryPrescriptionRequestBody,
  CreateImageryPrescriptionResponse,
  CreatePrescriptionRequestBody,
  CreatePrescriptionResponse,
  ImageryResultsPrescriptionLineResponse,
  SearchBiologicalPrescriptionLinesResponse,
  SearchBiologicalPrescriptionResponse,
  SearchImageryPrescriptionLinesResponse,
  SearchImageryPrescriptionResponse,
  SearchPrescriptionResponse,
  SearchPrescriptionsRequestBody,
  UpdateImageryFilesPrescriptionLineRequestBody,
  UpdateImageryPrescriptionLineRequestBody,
  UpdatePrescriptionLineRequestBody,
} from '../types';
import { axiosInstance } from './axiosInstance';

export const createPrescriptionApi = (
  data: CreatePrescriptionRequestBody,
): Promise<CreatePrescriptionResponse> => {
  return axiosInstance
    .post('/prescription', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création d'une prescription. Raison: ${message}`);
    });
};

export const createBiologicalPrescriptionApi = (
  data: CreateBiologicalPrescriptionRequestBody,
): Promise<CreateBiologicalPrescriptionResponse> => {
  return axiosInstance
    .post('/biologicalPrescription', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la création d'une prescription biologique. Raison: ${message}`,
      );
    });
};

export const createImageryPrescriptionApi = (
  data: CreateImageryPrescriptionRequestBody,
): Promise<CreateImageryPrescriptionResponse> => {
  return axiosInstance
    .post('/imageryPrescription', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la création d'une prescription d'imagerie. Raison: ${message}`,
      );
    });
};

export const searchPrescriptionsApi = (
  data: SearchPrescriptionsRequestBody,
): Promise<SearchPrescriptionResponse> => {
  return axiosInstance
    .post('/searchPrescriptions', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recherche de prescriptions. Raison: ${message}`);
    });
};

export const searchBiologicalPrescriptionsApi = (
  data: SearchPrescriptionsRequestBody,
): Promise<SearchBiologicalPrescriptionResponse> => {
  return axiosInstance
    .post('/searchBiologicalPrescriptions', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recherche de prescriptions biologiques. Raison: ${message}`,
      );
    });
};

export const searchBiologicalPrescriptionsLinesApi = (
  data: SearchPrescriptionsRequestBody,
): Promise<SearchBiologicalPrescriptionLinesResponse> => {
  return axiosInstance
    .post('/searchBiologicalPrescriptionLines', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recherche des lignes de prescriptions biologiques. Raison: ${message}`,
      );
    });
};

export const searchImageryPrescriptionsApi = (
  data: SearchPrescriptionsRequestBody,
): Promise<SearchImageryPrescriptionResponse> => {
  return axiosInstance
    .post('/searchImageryPrescriptions', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recherche de prescriptions d'imageries. Raison: ${message}`,
      );
    });
};

export const searchImageryPrescriptionsLinesApi = (
  data: SearchPrescriptionsRequestBody,
): Promise<SearchImageryPrescriptionLinesResponse> => {
  return axiosInstance
    .post('/searchImageryPrescriptionLines', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recherche des lignes de prescriptions d'imagerie. Raison: ${message}`,
      );
    });
};

export const completePrescriptionApi = (
  data: CompletePrescriptionRequestBody,
): Promise<string> => {
  return axiosInstance
    .put('/completePrescription', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la completion d'une prescription. Raison: ${message}`);
    });
};

export const updateBiologicalPrescriptionLineApi = (
  prescriptionId: number,
  data: UpdatePrescriptionLineRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/biologicalPrescriptionLine?id=${prescriptionId}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la mise a jour de la ligne prescription. Raison: ${message}`,
      );
    });
};

export const updateImageryPrescriptionLineApi = (
  prescriptionId: number,
  data: UpdateImageryPrescriptionLineRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/imageryPrescriptionLine?id=${prescriptionId}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Er reur lors de la mise a jour de la ligne prescription. Raison: ${message}`,
      );
    });
};

export const uploadImageryPrescriptionFiles = (
  data: UpdateImageryFilesPrescriptionLineRequestBody,
): Promise<string> => {
  const formData = new FormData();
  data.files.forEach(item => {
    formData.append('files', item.originFileObj as Blob, item.name);
  });
  formData.append('comment', data.comments);
  formData.append('idPrescriptionLineImg', data.idPrescriptionLineImg.toString());

  return axiosInstance
    .post(`/saveImageryPrescriptionResult`, formData, {
      headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' },
    })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de l'enregistrement des fichiers de prescription. Raison: ${message}`,
      );
    });
};

export const getImageryPrescriptionLineResultApi = (
  imageryId: number,
): Promise<string> => {
  return axiosInstance
    .get(`/imageryResultById?idImagery=${imageryId}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la récupération des résultats. Raison: ${message}`);
    });
};

export const getImageryResultsByPrescriptionLineApi = (
  prescriptionLineId: number,
): Promise<ImageryResultsPrescriptionLineResponse> => {
  return axiosInstance
    .get(`/imageriesResultByIdPresctionLine?idPrescription=${prescriptionLineId}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la récupération des résultats. Raison: ${message}`);
    });
};

export const deletImageryResultsFromPrescriptionLineApi = (
  resultId: number,
): Promise<string> => {
  return axiosInstance
    .delete(`/deleteImageryExamFromInscriptionLine?id=${resultId}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la suppression du résultat. Raison: ${message}`);
    });
};
