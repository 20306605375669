import dayjsInstance from '../../dayjs';
import { DATE_FORMAT } from '../../utils/constants';
import { CreateDossierAction, CreateDossierState } from './types';

export const getCreateDossierDefaultInitialState = (): CreateDossierState => {
  return {
    createdBy: 1,
    birthDate: '',
    enterDate: dayjsInstance().format(DATE_FORMAT),
    personToContact: '',
    personToContactPhoneNumber: '',
    patientDto: {
      cni: '',
      sexe: undefined,
      civility: undefined,
      birthDate: '',
      name: '',
      firstName: '',
      enterMode: undefined,
      nationality: undefined,
      ethnic: undefined,
      regionOrigin: undefined,
      placeOfResidence: undefined,
      job: undefined,
      phoneNumber: '',
    },
    idMedecin: undefined,
    idInsurance: undefined,
    idSubscriber: undefined,
    insuRegistrationNumber: '',
    bonNumber: '',
    idRubriqueConsulatation: undefined,
    insuRatio: 0,

    invalidInputs: [],
  } as CreateDossierState;
};

export const createDossierReducer = (
  state: CreateDossierState,
  action: CreateDossierAction,
): CreateDossierState => {
  const newState = { ...state };
  switch (action.type) {
    case 'setEnterDate':
      newState.enterDate = action.payload;
      return newState;
    case 'setIdentityNumber':
      newState.patientDto.cni = action.payload;
      return newState;
    case 'setSexe':
      newState.patientDto.sexe = action.payload;
      return newState;
    case 'setCivility':
      newState.patientDto.civility = action.payload;
      return newState;
    case 'setLastName':
      newState.patientDto.name = action.payload;
      return newState;
    case 'setFirstName':
      newState.patientDto.firstName = action.payload;
      return newState;
    case 'setBirthDate':
      newState.patientDto.birthDate = action.payload;
      return newState;
    case 'setEnterMode':
      newState.enterMode = action.payload;
      return newState;
    case 'setNationality':
      newState.patientDto.nationality = action.payload;
      return newState;
    case 'setRegionOrigin':
      newState.patientDto.regionOrigin = action.payload;
      return newState;
    case 'setEthnic':
      newState.patientDto.ethnic = action.payload;
      return newState;
    case 'setPlaceOfResidence':
      newState.patientDto.placeOfResidence = action.payload;
      return newState;
    case 'setProfession':
      newState.patientDto.job = action.payload;
      return newState;
    case 'setPersonToContact':
      newState.personToContact = action.payload;
      return newState;
    case 'setPersonToContactPhoneNumber':
      newState.personToContactPhoneNumber = action.payload;
      return newState;
    case 'setPhoneNumber':
      newState.patientDto.phoneNumber = action.payload;
      return newState;
    case 'setIdMedecin':
      newState.idMedecin = action.payload;
      return newState;
    case 'setIdInsurance':
      newState.idInsurance = action.payload;
      return newState;
    case 'setIdSubscriber':
      newState.idSubscriber = action.payload;
      return newState;
    case 'setInsuRatio':
      newState.insuRatio = action.payload;
      return newState;
    case 'setInsuRegistrationNumber':
      newState.insuRegistrationNumber = action.payload;
      return newState;
    case 'setIdRubriqueConsulatation':
      newState.idRubriqueConsulatation = action.payload;
      return newState;
    case 'setBonNumber':
      newState.bonNumber = action.payload;
      return newState;
    case 'setInvalidInputs':
      newState.invalidInputs = action.payload;
      return newState;
    case 'setResetPatientDto':
      newState.patientDto = {
        ...getCreateDossierDefaultInitialState().patientDto,
        cni: state.patientDto.cni,
      };
      return newState;
    default:
      return state;
  }
};
