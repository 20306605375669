import React, { PropsWithChildren } from 'react';
import { Drawer } from 'antd';
import { StyledSpace } from '../style';
import chartColors from '../../styles/color';

interface IStyledDrawer extends PropsWithChildren {
  isDrawerOpen: boolean;
  drawerTitle?: string;
  drawerSize?: 'large' | 'default';
  placement?: 'left' | 'right' | 'top' | 'bottom';
  maskClosable?: boolean;
  extraActions?: React.ReactNode;
  customDrawerStyle?: React.CSSProperties;
  customWidth?: string | number;
  closeDrawer: () => void;
}

const StyledDrawer: React.FC<IStyledDrawer> = ({
  closeDrawer,
  drawerTitle,
  isDrawerOpen,
  extraActions,
  drawerSize,
  maskClosable = false,
  customWidth,
  customDrawerStyle,
  placement = 'left',
  children,
}) => {
  return (
    <Drawer
      keyboard={false}
      closable={false}
      title={drawerTitle}
      maskClosable={maskClosable}
      destroyOnClose
      width={customWidth}
      onClose={closeDrawer}
      extra={[<StyledSpace key='actions'>{extraActions}</StyledSpace>]}
      styles={{
        body: { overflow: 'hidden', padding: 0 },
        header: {
          backgroundColor: chartColors.blue50,
          fontFamily: 'Mudclaw',
          textTransform: 'uppercase',
        },
        content: { ...customDrawerStyle },
      }}
      open={isDrawerOpen}
      placement={placement}
      size={drawerSize ?? 'large'}>
      {children}
    </Drawer>
  );
};

export default StyledDrawer;
