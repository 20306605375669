import SectionHeader from '../../../components/header/SectionHeader';
import { StyledCol, StyledRow } from '../../../components/style';
import { StyledMedicalRecordBlocContainer } from '../../style';
import BiologicalExams from './BiologicalExams';
import IrmExams from './IrmExams';

const DiagnosticExams = () => {
  const renderBiologicalExamsList = () => {
    return (
      <>
        <SectionHeader title='Examens Biologiques' />
        <BiologicalExams />
      </>
    );
  };
  const renderIrmExamsList = () => {
    return (
      <>
        <SectionHeader title="Examens d'imagerie" />
        <IrmExams />
      </>
    );
  };

  const renderContent = () => {
    return (
      <StyledRow gutter={[16, 16]}>
        <StyledCol span={24}>
          <StyledMedicalRecordBlocContainer
            style={{
              backgroundColor: '#FFF',
              padding: '30px 20px',
            }}>
            {renderBiologicalExamsList()}
          </StyledMedicalRecordBlocContainer>
        </StyledCol>
        <StyledCol span={24}>
          <StyledMedicalRecordBlocContainer
            style={{
              backgroundColor: '#FFF',
              padding: '30px 20px',
            }}>
            {renderIrmExamsList()}
          </StyledMedicalRecordBlocContainer>
        </StyledCol>
      </StyledRow>
    );
  };
  return <>{renderContent()}</>;
};

export default DiagnosticExams;
