/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer, Avatar, Statistic } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { FaBox, FaCoins, FaFileMedical } from 'react-icons/fa';
import { GiExpense } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';
import {
  getNumberOfMedicalRecordsApi,
  getStocksAlertApi,
  getTotalAmountOfExpensesApi,
  getTotalAmountOfInvoicesApi,
} from '../../api/dashboardService';
import Loader from '../../components/loader';
import {
  StyledCol,
  StyledDatePicker,
  StyledList,
  StyledRow,
  StyledSpace,
  StyledTag,
  StyledTooltip,
} from '../../components/style';
import dayjsInstance from '../../dayjs';
import VerticalLayout from '../../layout/VerticalLayout';
import chartColors from '../../styles/color';
import { GetArticlesResponse, IArticleItem } from '../../types';
import { getApiErrorMsg, thousandSeparatorFormat } from '../../utils/commun';
import { DATE_FORMAT, ErrorMsgTitle } from '../../utils/constants';
import { DashboardCard, StyleListContainer } from '../style';
import PageHeader from '../../components/pageHeader/PageHeader';

type DashboardStatsProps = {
  medicalRecordNumber: number;
  invoicesAmountHT: number;
  invoicesAmountTVA: number;
  invoicesAmountTTC: number;
  expensesAmountHT: number;
  expensesAmountTVA: number;
  expensesAmountTTC: number;
};

const statsValueStyle: React.CSSProperties = {
  color: chartColors.deepBlue,
  fontSize: 35,
  fontWeight: 'bold',
};

const StatsTitle = ({ title }: { title: string }) => {
  return (
    <span
      style={{
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: chartColors.black,
      }}>
      {title}
    </span>
  );
};

const Dashboard: React.FC = () => {
  const { notification } = AppContainer.useApp();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingStockAlert, setIsLoadingStockAlert] = useState<boolean>(false);
  const [stockAlertArticles, setStockAlertArticles] = useState<GetArticlesResponse>([]);

  const [dateDebut, setDateDebut] = useState<string>(
    dayjsInstance().startOf('month').format(DATE_FORMAT),
  );
  const [dateEnd, setDateEnd] = useState<string>(
    dayjsInstance().endOf('month').format(DATE_FORMAT),
  );
  const [dashboardStats, setDashboardStats] = useState<DashboardStatsProps>({
    medicalRecordNumber: 0,
    // TotalInvoicesState
    invoicesAmountHT: 0,
    invoicesAmountTVA: 0,
    invoicesAmountTTC: 0,
    // TotalExpensesState
    expensesAmountHT: 0,
    expensesAmountTVA: 0,
    expensesAmountTTC: 0,
  });

  /**
   * The function `loadStatistics` asynchronously fetches and sets various statistics related to
   * medical records, invoices, and expenses for a dashboard.
   */
  const loadStatistics = async () => {
    const requestBody = {
      dateDebut,
      dateEnd,
    };
    setIsLoading(true);
    try {
      Promise.all([
        getNumberOfMedicalRecordsApi(requestBody),
        getTotalAmountOfInvoicesApi(requestBody),
        getTotalAmountOfExpensesApi(requestBody),
      ]).then(res => {
        setDashboardStats({
          medicalRecordNumber: res[0],
          invoicesAmountHT: res[1].amountHT,
          invoicesAmountTVA: res[1].amountTVA,
          invoicesAmountTTC: res[1].amountTTC,
          expensesAmountHT: res[2].amountHT,
          expensesAmountTVA: res[2].amountTVA,
          expensesAmountTTC: res[2].amountTTC,
        });
        setIsLoading(false);
      });
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
      setIsLoading(false);
    }
  };

  const loadStockAlert = async () => {
    setIsLoadingStockAlert(true);
    try {
      getStocksAlertApi({ actualstock: 10 }).then(res => {
        setStockAlertArticles(res);
        setIsLoadingStockAlert(false);
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
      setIsLoading(false);
    }
  };

  const handleNavigateToInvoice = () => {
    navigate('/facturation', { state: { dateDebut, dateEnd } });
  };

  useEffect(() => {
    loadStatistics();
  }, [dateDebut, dateEnd]);
  useEffect(() => {
    loadStockAlert();
  }, []);

  return (
    <VerticalLayout enableVerticalScroll='true'>
      <PageHeader title='Tableau de bord'>
        {/* Date Selector */}
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <StyledCol style={{ fontWeight: 600 }}>Période du &nbsp; </StyledCol>
          <StyledCol>
            <StyledDatePicker.RangePicker
              style={{ width: '100%' }}
              allowClear={false}
              onChange={(value, dateString) => {
                setDateDebut(dateString[0]);
                setDateEnd(dateString[1]);
              }}
              value={[
                _.isEmpty(dateDebut) ? null : dayjsInstance(dateDebut, DATE_FORMAT),
                _.isEmpty(dateEnd) ? null : dayjsInstance(dateEnd, DATE_FORMAT),
              ]}
              format={DATE_FORMAT}
            />
          </StyledCol>
        </div>
      </PageHeader>
      <div style={{ padding: '20px 20px', height: '100%' }}>
        {/* Statistics */}
        <StyledRow gutter={[16, 16]} style={{ marginBottom: 20 }}>
          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 8 }}>
            <Loader showSpinner={isLoading}>
              <DashboardCard
                $borderColor={chartColors.warning}
                bordered={false}
                styles={{ body: { height: '152px' } }}>
                <Statistic
                  loading={isLoading}
                  groupSeparator=''
                  title={<StatsTitle title='Nombre de dossiers en cours' />}
                  value={dashboardStats.medicalRecordNumber}
                  prefix={<FaFileMedical color={chartColors.warning} />}
                  valueStyle={statsValueStyle}
                />
              </DashboardCard>
            </Loader>
          </StyledCol>

          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 8 }}>
            <Loader showSpinner={isLoading}>
              <DashboardCard
                $borderColor={chartColors.success}
                bordered={false}
                style={{ cursor: 'pointer' }}
                styles={{ body: { height: '152px' } }}
                onClick={handleNavigateToInvoice}>
                <StyledTooltip
                  title={thousandSeparatorFormat(dashboardStats.invoicesAmountTTC)}>
                  <Statistic
                    loading={isLoading}
                    title={<StatsTitle title='Factures - Montant TTC' />}
                    value={dashboardStats.invoicesAmountTTC ?? 0}
                    decimalSeparator=','
                    groupSeparator={' '}
                    prefix={<FaCoins color={chartColors.success} />}
                    suffix='FCFA'
                    valueStyle={statsValueStyle}
                  />
                </StyledTooltip>
                <StyledSpace split='|'>
                  <StyledCol style={{ color: chartColors.mainGray }}>
                    <StyledTooltip
                      title={thousandSeparatorFormat(dashboardStats.invoicesAmountHT)}>
                      Montant HT :{' '}
                      {thousandSeparatorFormat(dashboardStats.invoicesAmountHT)} FCFA
                    </StyledTooltip>
                  </StyledCol>
                  <StyledCol style={{ color: chartColors.mainGray }}>
                    <StyledTooltip
                      title={thousandSeparatorFormat(dashboardStats.invoicesAmountTVA)}>
                      Montant TVA:{' '}
                      {thousandSeparatorFormat(dashboardStats.invoicesAmountTVA)} FCFA
                    </StyledTooltip>
                  </StyledCol>
                </StyledSpace>
              </DashboardCard>
            </Loader>
          </StyledCol>

          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 8 }}>
            <Loader showSpinner={isLoading}>
              <DashboardCard
                $borderColor={chartColors.danger}
                bordered={false}
                style={{ cursor: 'pointer' }}
                styles={{ body: { height: '152px' } }}>
                <StyledTooltip
                  title={thousandSeparatorFormat(dashboardStats.expensesAmountTTC)}>
                  <Statistic
                    loading={isLoading}
                    title={<StatsTitle title='Dépenses - Montant TTC' />}
                    value={dashboardStats.expensesAmountTTC ?? 0}
                    decimalSeparator=','
                    groupSeparator={' '}
                    prefix={<GiExpense color={chartColors.danger} />}
                    suffix='FCFA'
                    valueStyle={statsValueStyle}
                  />
                </StyledTooltip>
                <StyledSpace split='|'>
                  <StyledCol style={{ color: chartColors.mainGray }}>
                    <StyledTooltip
                      title={thousandSeparatorFormat(dashboardStats.expensesAmountHT)}>
                      Montant HT :{' '}
                      {thousandSeparatorFormat(dashboardStats.expensesAmountHT)} FCFA
                    </StyledTooltip>
                  </StyledCol>
                  <StyledCol style={{ color: chartColors.mainGray }}>
                    <StyledTooltip
                      title={thousandSeparatorFormat(dashboardStats.expensesAmountTVA)}>
                      Montant TVA:{' '}
                      {thousandSeparatorFormat(dashboardStats.expensesAmountTVA)} FCFA
                    </StyledTooltip>
                  </StyledCol>
                </StyledSpace>
              </DashboardCard>
            </Loader>
          </StyledCol>
        </StyledRow>

        {/* Alerte Stock */}
        <StyledRow gutter={[16, 16]} style={{ marginBottom: 20 }}>
          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 8 }}>
            <DashboardCard
              bordered={false}
              title={<StatsTitle title='Alerte Stock' />}
              styles={{ body: { padding: 0 } }}>
              <StyleListContainer
                autoHide={false}
                forceVisible='y'
                style={{ height: 400, padding: '0px 10px 20px' }}>
                <Loader showSpinner={isLoadingStockAlert}>
                  <StyledList
                    pagination={false}
                    size='small'
                    dataSource={stockAlertArticles}
                    renderItem={(item: IArticleItem | any) => (
                      <StyledList.Item key={item.id}>
                        <StyledList.Item.Meta
                          avatar={
                            <Avatar
                              style={{ backgroundColor: chartColors.lightGray }}
                              icon={<FaBox />}
                            />
                          }
                          title={
                            <span style={{ textTransform: 'uppercase', fontWeight: 600 }}>
                              {item.designation}
                            </span>
                          }
                          description={
                            <StyledTag color={chartColors.deepBlue}>
                              {item.reference}
                            </StyledTag>
                          }
                        />
                        <div style={{ fontSize: '2.2rem', fontWeight: 'bold' }}>
                          {item.stock}
                        </div>
                      </StyledList.Item>
                    )}
                  />
                </Loader>
              </StyleListContainer>
            </DashboardCard>
          </StyledCol>
        </StyledRow>
      </div>
    </VerticalLayout>
  );
};

export default Dashboard;
