import styled from 'styled-components';

export const StyledPatientCardPhone = styled.span``;

export const StyledPatientCardName = styled.h4`
  font-family: 'Metropolis', sans-serif;
  font-weight: bold;
  margin-bottom: 0px;
  text-transform: uppercase;
`;

export const StyledPatientInfosContainer = styled.div`
  margin-left: 10px;
`;

export const StyledPatientCardContent = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledPatientCardContainer = styled.div`
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* background-color: ${({ theme }) => theme.colors.white}; */
  height: 70px;
  padding: 10px 8px;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 10px;

  &:hover {
    transition: all 0.4s ease-in-out;
    background-color: ${({ theme }) => theme.colors.blue};

    ${StyledPatientCardContent} {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

/**
 * Medical Record Patient
 */

export const StyledMedicalRecordPatientActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
  box-shadow: 0px 10px 10px ${({ theme }) => theme.colors.black}10;
  padding-left: 20px;
`;

export const StyledMedicalRecordPatientContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  row-gap: 20px;
`;

export const StyledMedicalRecordPatientNumeroContainer = styled.div`
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledMedicalRecordPatientHeaderInfos = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  padding: 0px 20px;
  align-items: center;
`;

export const StyledMedicalRecordPatientHeaderContainer = styled.div`
  height: 100px;
  background-color: ${({ theme }) => theme.colors.blue};
  display: flex;
  flex-direction: row;
  align-items: stretch;
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledMedicalRecordPatientWrapper = styled.div``;

/**
 * Constantes Patient
 */

export const StyledConstanteItemTitle = styled.div`
  font-size: 1.2rem;
  text-transform: uppercase;
`;

export const StyledConstanteItemValue = styled.div`
  font-family: 'Metropolis', sans-serif;
  font-size: 2.3rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.blue};
  height: 70px;
  min-width: 70px;
  width: fit-content;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blue}50;
  margin-bottom: 5px;
`;

export const StyledConstanteItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledConstantesPatientContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px 0px;
`;

export const StyledSectionPatientContainer = styled.div`
  /* background-color: ${({ theme }) => theme.colors.blue}15; */
  /* min-height: calc(100vh - 200px); */
  padding: 0px 20px;
  /* padding-bottom: 30px; */
`;
