import _ from 'lodash';
import { CreateManualInvoiceProformaState, CreateInvoiceProformaAction } from './types';

export const getCreateManualInvoiceProformaDefaultInitialState =
  (): CreateManualInvoiceProformaState => {
    return {
      proformaLines: [],
      invalidInputs: [],
    } as CreateManualInvoiceProformaState;
  };

export const CreateManualInvoiceProformaReducer = (
  state: CreateManualInvoiceProformaState,
  action: CreateInvoiceProformaAction,
) => {
  const newState = { ...state };
  switch (action.type) {
    case 'setProformaRubrics':
      newState.proformaLines = _.concat(state.proformaLines, action.payload);
      return newState;

    case 'removeProformaRubrics':
      newState.proformaLines = state.proformaLines.filter(
        item => item.id !== action.payload,
      );
      return newState;

    case 'setUnitRubric': {
      const rubricId = action.payload.id;
      const rubricValue = action.payload.value;
      return {
        ...state,
        proformaLines: state.proformaLines.map(r =>
          r.idRubric === rubricId ? { ...r, unit: rubricValue } : r,
        ),
      };
    }

    case 'setAmountPatientRubric': {
      const rubricId = action.payload.id;
      const rubricValue = action.payload.value;
      return {
        ...state,
        proformaLines: state.proformaLines.map(r =>
          r.idRubric === rubricId ? { ...r, amountPatient: rubricValue } : r,
        ),
      };
    }

    case 'setAmountAssuRubric': {
      const rubricId = action.payload.id;
      const rubricValue = action.payload.value;
      return {
        ...state,
        proformaLines: state.proformaLines.map(r =>
          r.idRubric === rubricId ? { ...r, amountAssu: rubricValue } : r,
        ),
      };
    }

    case 'setAmountTotalRubric': {
      const rubricId = action.payload.id;
      const rubricValue = action.payload.value;
      return {
        ...state,
        proformaLines: state.proformaLines.map(r =>
          r.idRubric === rubricId ? { ...r, amountTotal: rubricValue } : r,
        ),
      };
    }

    case 'setQuantityRubric': {
      const rubricId = action.payload.id;
      const rubricValue = action.payload.value;
      return {
        ...state,
        proformaLines: state.proformaLines.map(r =>
          r.idRubric === rubricId
            ? {
                ...r,
                quantity: rubricValue,
              }
            : r,
        ),
      };
    }

    case 'setUnitPriceRubric': {
      const rubricId = action.payload.id;
      const rubricValue = action.payload.value;
      return {
        ...state,
        proformaLines: state.proformaLines.map(r =>
          r.idRubric === rubricId ? { ...r, unitPrice: rubricValue } : r,
        ),
      };
    }

    case 'resetRubrics':
      newState.proformaLines = [];
      return newState;

    default:
      return state;
  }
};
