import { CreateAppointmentRequestBody, GetMedecinAppointmentsResponse } from '../types';
import { axiosInstance } from './axiosInstance';

export const getMedecinAppointmentsByIdApi = (
  idMedecin: number,
): Promise<GetMedecinAppointmentsResponse> => {
  return axiosInstance
    .get(`/appointementByIdMedecin?idMedecin=${idMedecin}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des rendez-vous. Raison: ${message}`);
    });
};

export const createAppointmentApi = (
  data: CreateAppointmentRequestBody,
): Promise<string> => {
  return axiosInstance
    .post('/appointment', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création du rendez-vous: ${message}`);
    });
};

export const deleteAppointmentApi = (timeslotId: number): Promise<string> => {
  return axiosInstance
    .delete(`/appointment?id=${timeslotId}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de l'annulation du rendez-vous: ${message}`);
    });
};
