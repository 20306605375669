import { StyledTitle } from '../header/style';
import ConstanteItem from './ConstanteItem';
import { StyledSectionPatientContainer, StyledConstantesPatientContent } from './style';

interface ConstantesPatientProps {
  poids: string;
  taille: string;
  temperature: string;
  tension: string;
  pouls: string;
}

const ConstantesPatients: React.FC<ConstantesPatientProps> = ({
  poids,
  taille,
  temperature,
  tension,
  pouls,
}) => {
  return (
    <StyledSectionPatientContainer>
      <StyledTitle>Constantes</StyledTitle>

      <StyledConstantesPatientContent>
        <ConstanteItem title='Poids(Kg)' value={poids} />
        <ConstanteItem title='Taille(Cm)' value={taille} />
        <ConstanteItem title='Temp.(C)' value={temperature} />
        <ConstanteItem title='Tens.(Pa)' value={tension} />
        <ConstanteItem title='Pouls' value={pouls} />
      </StyledConstantesPatientContent>
    </StyledSectionPatientContainer>
  );
};
export default ConstantesPatients;
