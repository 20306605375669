/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer, Form } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { createEthnicApi, updateEthnicApi } from '../../../api/referentialService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import {
  StyledCol,
  StyledDivider,
  StyledInput,
  StyledRow,
  StyledSpace,
} from '../../../components/style';
import {
  CreateEthnicItemRequestBody,
  IEthnicItem,
  ReferentialViewProps,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { StyledBlocContainer } from '../../style';

type EthnicFormProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  currentData?: IEthnicItem;
};

type FormPropertiesProps = {
  id: number;
  code: string;
  libelle: string;
};

const EthnicForm: React.FC<EthnicFormProps> = ({ navigateToview, currentData }) => {
  const { notification } = AppContainer.useApp();
  const [form] = Form.useForm<FormPropertiesProps>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const renderHeader = () => {
    return (
      <SectionHeader
        title={_.isUndefined(currentData) ? 'Nouvelle ethnie' : 'Modification ethnie'}
        onBack={() => navigateToview('LIST_ITEMS')}
      />
    );
  };

  const mapPropsToRequest = (row: FormPropertiesProps) => {
    const itemData: CreateEthnicItemRequestBody = {
      code: row.code,
      libelle: row.libelle,
    };

    return itemData;
  };

  const createItem = async () => {
    try {
      await form.validateFields().then(async row => {
        setIsLoading(true);
        Promise.resolve(createEthnicApi(mapPropsToRequest(row))).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `${row.libelle} a été enregistrée!`,
          });
          navigateToview('LIST_ITEMS');
        });
      });
    } catch (error: any) {
      if (_.isUndefined(error.values)) {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const editItem = async () => {
    try {
      await form.validateFields().then(async row => {
        setIsLoading(true);
        await updateEthnicApi(currentData?.id as number, mapPropsToRequest(row)).then(
          () => {
            notification.success({
              message: SuccesssMsgTitle,
              description: `L'ethnie a été modifiée!`,
            });
            navigateToview('LIST_ITEMS');
          },
        );
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    return (
      <StyledBlocContainer style={{ padding: 20 }}>
        <Form
          disabled={isLoading}
          form={form}
          component={false}
          initialValues={{
            code: _.isUndefined(currentData) ? '' : currentData.code,
            libelle: _.isUndefined(currentData) ? '' : currentData.libelle,
          }}>
          <StyledRow gutter={[16, 16]} align='middle' justify='center'>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Code ethnie' />
              <Form.Item style={{ margin: 0 }} name='code'>
                <StyledInput size='large' placeholder='Code ethnie' />
              </Form.Item>
            </StyledCol>
            <StyledCol span={24}>
              <FormFieldLabel libelle="Libellé de l'ethnie" isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='libelle'
                rules={[
                  { required: true, message: 'Libellé obligatoire' },
                  { whitespace: false },
                ]}>
                <StyledInput size='large' placeholder='Libellé ethnie' />
              </Form.Item>
            </StyledCol>
            <StyledDivider />
            <StyledCol span={24}>
              <StyledSpace>
                <StyledButton
                  type='primary'
                  htmlType='submit'
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={_.isUndefined(currentData) ? createItem : editItem}>
                  {_.isUndefined(currentData)
                    ? "Enregistrer l'ethnie"
                    : "Modifier l'ethnie'"}
                </StyledButton>
              </StyledSpace>
            </StyledCol>
          </StyledRow>
        </Form>
      </StyledBlocContainer>
    );
  };

  return (
    <>
      {renderHeader()}
      <StyledDivider />
      {renderContent()}
    </>
  );
};

export default EthnicForm;
