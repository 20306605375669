import { App as AppContainer } from 'antd';
import _ from 'lodash';
import { useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  createInterrogatoireApi,
  getExaminationsByMedicalRecord,
  updateInterrogatoireApi,
} from '../../../../api/dossierPatientService';
import {
  getFunctionalSigns,
  getReasonsForConsultation,
  getTreatmentstaken,
} from '../../../../api/referenceService';
import Space from '../../../../components/Space';
import { StyledButton } from '../../../../components/button/style';
import FormFieldLabel from '../../../../components/label/FormFieldLabel';
import Loader from '../../../../components/loader';
import { StyledCol, StyledInputTextArea, StyledRow } from '../../../../components/style';
import {
  createInterrogatoireReducer,
  getCreateInterrogatoireDefaultInitialState,
} from '../../../../reducers/interrogatoire/createInterrogatoire';
import { CreateInterrogatoireState } from '../../../../reducers/interrogatoire/types';
import {
  CheckBoxItem,
  CreateInterrogatoireRequestBody,
  CreateMedicalRecordApiResponse,
  NavigationMode,
  StepperComponentProps,
} from '../../../../types';
import { getApiErrorMsg } from '../../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../../utils/constants';
import { getDataFromStorage } from '../../../../utils/storage';
import { StyledMedicalRecordBlocContainer } from '../../../style';
import AddRemoveInterrogatoire from './AddRemoveInterrogatoire';

const Interrogatoire: React.FC<StepperComponentProps> = () => {
  const location = useLocation();
  const locationState = location.state as { mode: NavigationMode };

  const { notification } = AppContainer.useApp();

  const [motifsInterrogatoire, setMotifsInterrogatoire] = useState<CheckBoxItem[]>([]);
  const [signesInterrogatoire, setSignesInterrogatoire] = useState<CheckBoxItem[]>([]);

  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [state, reducer] = useReducer(
    createInterrogatoireReducer,
    getCreateInterrogatoireDefaultInitialState(),
  );

  const medicalRecord =
    getDataFromStorage<CreateMedicalRecordApiResponse>('medicalRecord');

  const loadMedicalRecordExamination = async () => {
    setIsLoading(true);
    try {
      const result = await getExaminationsByMedicalRecord(medicalRecord?.id);
      reducer({ type: 'setComments', payload: result.comments });
      reducer({ type: 'setFonctionnalSigns', payload: result.functionalSigns });
      reducer({ type: 'setReasonOfConsultation', payload: result.reasonForConsultation });
      reducer({ type: 'setTreatmentsTaken', payload: result.treatmentsTaken });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description:
          typeof error === 'object' ? JSON.stringify(error) : (error as string),
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([getReasonsForConsultation(), getTreatmentstaken(), getFunctionalSigns()])
      .then(results => {
        setMotifsInterrogatoire(results[0]);
        setSignesInterrogatoire(results[2]);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!_.isNull(medicalRecord)) {
      // Charger les résultats d'Interrogatoires liés au dossier
      loadMedicalRecordExamination();
    }
  }, []);

  const mapStateToRequestBody = (
    state: CreateInterrogatoireState,
  ): CreateInterrogatoireRequestBody => {
    const requestBody = {} as CreateInterrogatoireRequestBody;
    requestBody.reasonForConsultation = state.reasonOfConsultation;
    requestBody.functionalSigns = state.functionalSigns;
    requestBody.treatmentsTaken = state.treatments;
    requestBody.comments = state.comments;
    requestBody.idMedicalRecord = medicalRecord && medicalRecord?.id;
    return requestBody;
  };

  const postInterrogatoire = async () => {
    try {
      setIsLoading(true);
      console.log(state);
      await createInterrogatoireApi(mapStateToRequestBody(state)).then(() => {
        notification.success({
          message: SuccesssMsgTitle,
          description: "L'interrogatoire a bien été créé.",
        });
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateInterrogatoire = async () => {
    setIsLoading(true);
    try {
      await updateInterrogatoireApi(medicalRecord?.id, mapStateToRequestBody(state)).then(
        () => {
          notification.success({
            message: SuccesssMsgTitle,
            description: "L'interrogatoire a bien été mis à jour.",
          });
        },
      );
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderActionsButton = () => {
    if (locationState.mode == NavigationMode.READ_MODIFY_MODE) {
      return (
        <StyledButton
          size='large'
          type='primary'
          onClick={() => updateInterrogatoire()}
          loading={isLoading}
          disabled={isLoading}>
          Mettre à jour
        </StyledButton>
      );
    }
    return (
      <StyledButton
        size='large'
        type='primary'
        onClick={() => postInterrogatoire()}
        loading={isLoading}
        disabled={isLoading}>
        Valider
      </StyledButton>
    );
  };

  const renderForm = () => {
    return (
      <>
        <StyledRow gutter={[16, 16]}>
          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 16 }}>
            <StyledMedicalRecordBlocContainer
              style={{
                backgroundColor: '#FFF',
                padding: '30px 20px',
              }}>
              <StyledRow gutter={[16, 16]}>
                <StyledCol span={24}>
                  <AddRemoveInterrogatoire
                    title='Motif de la consultation'
                    items={motifsInterrogatoire}
                    reducerTypeLabel='setReasonOfConsultation'
                    listTitle='Sélectionnez un ou plusieurs motifs'
                    reducer={reducer}
                    value={state.reasonOfConsultation}
                  />
                </StyledCol>
                <StyledCol span={24}>
                  <AddRemoveInterrogatoire
                    title='Signes fonctionnels'
                    items={signesInterrogatoire}
                    reducerTypeLabel='setFonctionnalSigns'
                    listTitle='Sélectionnez un ou plusieurs signes fonctionnels'
                    reducer={reducer}
                    value={state.functionalSigns}
                  />
                </StyledCol>
                {/* <StyledCol span={24}>
                  <AddRemoveInterrogatoire
                    title='Traitements pris'
                    items={traitementsInterrogatoire}
                    reducerTypeLabel='setTreatmentsTaken'
                    listTitle='Sélectionnez un ou plusieurs traitements pris'
                    reducer={reducer}
                    value={state.treatments}
                  />
                </StyledCol> */}
              </StyledRow>
            </StyledMedicalRecordBlocContainer>
          </StyledCol>
          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}>
            <StyledMedicalRecordBlocContainer
              style={{
                backgroundColor: '#FFF',
                padding: '30px 20px',
              }}>
              <FormFieldLabel libelle='Commentaires' />
              <StyledInputTextArea
                rows={20}
                name='comments'
                placeholder='Commentaires'
                showCount
                maxLength={800}
                value={state.comments}
                onChange={e =>
                  reducer({
                    type: 'setComments',
                    payload: e.target.value,
                  })
                }
              />
            </StyledMedicalRecordBlocContainer>
          </StyledCol>
          <StyledCol
            span={24}
            style={{ display: 'flex', placeItems: 'center', placeContent: 'center' }}>
            {!_.isNull(medicalRecord) && renderActionsButton()}
          </StyledCol>
        </StyledRow>
      </>
    );
  };

  return (
    <>
      <Space marginTop='2rem' />
      <Loader showSpinner={isLoading}>{renderForm()}</Loader>
      {/* <div style={{ textAlign: isTabletOrMobile ? 'left' : 'center' }}>
        <StyledButton type='primary' onClick={() => postInterrogatoire()}>
          Valider
        </StyledButton>
      </div> */}
    </>
  );
};

export default Interrogatoire;
