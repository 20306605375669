import {
  CreatePharmacyOrderRequestBody,
  CreatePharmacyOrderResponse,
  GetArticlesHistoryResponse,
  PharmacyLinesResponse,
  ReceivePharmacyOrderRequestBody,
  RemovePharmacyArticlesRequestBody,
  SearchPharmacyOrdersLinesResponse,
  SearchPharmacyOrdersRequestBody,
  SearchPharmacyOrdersResponse,
  ValidatePharmacyOrderDeliveryRequestBody,
} from '../types';
import { axiosInstance } from './axiosInstance';

export const searchPharmacyOrdersApi = (
  data: SearchPharmacyOrdersRequestBody,
): Promise<SearchPharmacyOrdersResponse> => {
  return axiosInstance
    .post('/searchOrders', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recherche des commandes: ${message}`);
    });
};

export const createPharmacyOrdersApi = (
  data: CreatePharmacyOrderRequestBody,
): Promise<CreatePharmacyOrderResponse> => {
  return axiosInstance
    .post('/order', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création de commande: ${message}`);
    });
};

export const searchPharmacyOrderLinesApi = (
  idOrder: number,
): Promise<SearchPharmacyOrdersLinesResponse> => {
  return axiosInstance
    .get(`/searchOrderLines?idOrder=${idOrder}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération des lignes de commandes. Raison: ${message}`,
      );
    });
};

export const receivePharmacyOrderLinesApi = (
  data: ReceivePharmacyOrderRequestBody,
): Promise<string> => {
  return axiosInstance
    .post('/receiveOrder?', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la réception de la commande. Raison: ${message}`);
    });
};

export const getArticlesStockHistoryApi = (
  idArticle: number,
): Promise<GetArticlesHistoryResponse> => {
  return axiosInstance
    .get(`/stockHistory?idArticle=${idArticle}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération de l'historique de l'article: ${message}`,
      );
    });
};

export const removePharmacyArticlesApi = (
  data: RemovePharmacyArticlesRequestBody,
): Promise<string> => {
  return axiosInstance
    .post('/removeArticleFromStock', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la sortie des articles: ${message}`);
    });
};

export const validatePharmacyOrderDeliveryApi = (
  orderId: number,
): Promise<ValidatePharmacyOrderDeliveryRequestBody> => {
  return axiosInstance
    .put(`/deliveryNote?idOrder=${orderId}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la validation de livraison de la commande: ${message}`);
    });
};

export const getPharmacyInvoiceLinesApi = (
  idMedicalrecord: number,
): Promise<PharmacyLinesResponse> => {
  return axiosInstance
    .get(`/invoicePharmayLines?idMedicalrecord=${idMedicalrecord}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération des lignes de facturation de pharmacie. Raison: ${message}`,
      );
    });
};
