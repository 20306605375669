import styled from 'styled-components';
import chartColors from '../../styles/color';

export const TimeSlotCalendarDayHeaderTitle = styled.div`
  font-family: 'Metropolis', sans-serif;
  font-weight: 700;
  font-size: 1.9vh;
  color: ${chartColors.deepBlue};
  margin-bottom: 0px;
`;
export const TimeSlotCalendarDayHeaderDate = styled.div`
  font-family: 'Metropolis', sans-serif;
  font-weight: 500;
  font-size: 1.9vh;
`;
export const TimeSlotCalendarDayHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  user-select: none;
  position: sticky;
  top: 0px;
  background-color: ${chartColors.white};
  z-index: 1;
`;
export const TimeSlotTimeContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 10;
`;
export const TimeSlotCalendarDayContainer = styled.div`
  padding: 0px 6px;
`;

export const TimeSlotCalendarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TimeSlotNavigationContainer = styled.div`
  top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const TimeSlotNavigationWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
`;

export const TimeSlotCalendarWrapper = styled.div`
  position: relative;
  padding: 0px 45px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  max-height: 350px;
  overflow-y: auto;
  width: auto;
`;
