/* eslint-disable @typescript-eslint/no-explicit-any */
console.error = function (message: any) {
  // if (!message?.includes('finDOMNode') ?? null) {
  //   // Log the error only if it's not related to finDOMNode
  console.log(message);
  // }
};

console.warn = function () {
  // Silence warnings
};

export {};
