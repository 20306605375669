import { MdOutlineFolderOff } from 'react-icons/md';
import chartColors from '../../styles/color';

interface IEmptyNotSelectedProps {
  message?: string;
  description?: string;
  icon?: JSX.Element;
}

const defaultData = {
  message: 'Aucun sélectionné',
  description: 'Veuillez sélectionner un élément',
};

const EmptyNotSelected: React.FC<IEmptyNotSelectedProps> = ({
  message,
  description,
  icon,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'center',
        height: 'inherit',
        textAlign: 'center',
        paddingTop: '50px',
        borderRight: '0.5px solid #CECECE',
        minHeight: '100%',
      }}>
      {icon ?? <MdOutlineFolderOff size={100} color={chartColors.black} />}
      <h2 style={{ color: chartColors.blue, marginBottom: '0px' }}>
        {message ?? defaultData.message}
      </h2>
      <p>{description ?? defaultData.description}</p>
    </div>
  );
};

export default EmptyNotSelected;
