/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnType } from 'antd/es/table';
import type { RcFile } from 'antd/es/upload';
import { dataURLToBlob } from 'blob-util';
import Holidays from 'date-holidays';
import _ from 'lodash';
import React from 'react';
import { FiSearch } from 'react-icons/fi';
import dayjsInstance from '../dayjs';
import {
  EInvoiceType,
  IRightItem,
  IRoleItem,
  TableColumnSearchInterface,
} from '../types';
import { DAY_END_HOUR, DAY_START_HOUR } from './constants';

export const getApiErrorMsg = (error: unknown) => {
  return typeof error === 'object' ? JSON.stringify(error) : (error as string);
};

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

export const PdfFile = 'application/pdf';
export const JpegFile = 'image/jpeg';
export const PngFile = 'image/png';

export const UploaderAcceptedFile = `${PdfFile}, ${JpegFile}, ${PngFile}`;
export const UploaderAcceptedFileType = [PdfFile, JpegFile, PngFile];

export const getColumnSearchProps = <R>({
  dataIndex,
  filterDropdownComponent,
  filterIconComponent,
}: TableColumnSearchInterface<R>): ColumnType<R> => {
  const columnSearchFilter: ColumnType<R> = {
    filterDropdown: filterProps => filterDropdownComponent(filterProps),
    onFilter: (value, record: any) => {
      const recordValue = record[dataIndex];
      if (recordValue === null || recordValue === undefined) {
        return false;
      }
      return recordValue
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    filterIcon: filterIconComponent ?? (FiSearch as unknown as React.ReactNode),
    render: text => text,
  };

  return columnSearchFilter;
};

export const thousandSeparatorFormat = (value: string | number) => {
  const parseValue = typeof value === 'string' ? parseInt(value) : value ?? '';
  return parseValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  // return new Intl.NumberFormat('ci').format(parseValue);
};

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const mapStateToRequestBody = <T>(data: T): T => {
  const result: T = data;
  return result;
};

export const stringifyRoles = (roles: Array<IRoleItem>, renderType: 'CODE' | 'LABEL') => {
  if (renderType === 'CODE') {
    return roles.map(role => role.code);
  } else {
    return roles.map(role => role.label);
  }
};

export const stringifyRights = (
  rights: Array<IRightItem>,
  renderType: 'CODE' | 'LABEL',
) => {
  if (renderType === 'CODE') {
    return rights.map(right => right.code);
  } else {
    return rights.map(right => right.label);
  }
};

export const checkExistingRole = (roles: Array<IRoleItem>, roleToCheck: string) => {
  if (stringifyRoles(roles, 'CODE').includes(roleToCheck)) {
    return true;
  } else {
    return false;
  }
};

export const generateTimesIntervals = () => {
  const startHour = 8;
  const endHour = 16;

  const hours = Array.from({ length: endHour - startHour + 1 }, (_, i) => i + startHour);
  const timeIntervals: Array<string> = [];

  hours.forEach(hour => {
    for (let minute = 0; minute < 60; minute += 30) {
      const formattedHour = hour.toString().padStart(2, '0');
      const formattedMinute = minute.toString().padStart(2, '0');
      timeIntervals.push(`${formattedHour}:${formattedMinute}`);
    }
  });

  return timeIntervals;
};

export const timeToMinutes = (time: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

export const convertToHHMM = (timeString: string) => {
  const [hours, minutes] = timeString.split(':').slice(0, 2);
  return `${hours}:${minutes}`;
};

export const base64ToBlob = (base64: string): Blob => {
  return dataURLToBlob(base64);
};

export const abbreviateNumber = (numberToAbbreviate: number): string => {
  if (numberToAbbreviate >= 1000000) {
    return (numberToAbbreviate / 1000000).toFixed(1) + 'M';
  } else if (numberToAbbreviate >= 1000) {
    return (numberToAbbreviate / 1000).toFixed(1) + 'K';
  } else if (_.isNull(numberToAbbreviate)) {
    return '0';
  } else {
    return thousandSeparatorFormat(numberToAbbreviate.toString());
  }
};

export const isHolyday = (): boolean => {
  const hd = new Holidays('CI');
  const isHoliday = hd.isHoliday(dayjsInstance(new Date()).toString());
  return isHoliday ? true : false;
};

export const isBusinessDay = () => {
  return dayjsInstance(new Date()).isBusinessDay();
};

export const isDayTime = () => {
  const currentHour = dayjsInstance().hour();
  if (currentHour >= DAY_START_HOUR && currentHour < DAY_END_HOUR) {
    return true;
  }
  return false;
};

export const renderInvoiceTitle = (invoiceType: EInvoiceType) => {
  switch (invoiceType) {
    // case EInvoiceType.BIOLOGICAL:
    //   return "FACTURE D'ANALYSE BIOLOGIQUE";

    // case EInvoiceType.IMAGERY:
    //   return "FACTURE D'IMAGERIE";

    default:
      return 'FACTURE';
  }
};
