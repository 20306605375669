/* eslint-disable @typescript-eslint/no-explicit-any */
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Empty } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FiSearch, FiX } from 'react-icons/fi';
import { StyledButton } from '../../../../components/button/style';
import CustomModal from '../../../../components/modal/Modal';
import {
  StyledCardItem,
  StyledDivider,
  StyledInput,
  StyledTable,
} from '../../../../components/style';
import { CreateAntecedentAction } from '../../../../reducers/antecedent/types';
import {
  AntecedentCategoryType,
  AntecedentType,
  CheckBoxAntecedentItem,
} from '../../../../types';
import { ScrollContainer, StyledAddTextButton } from '../../../style';
import chartColors from '../../../../styles/color';

interface AddRemoveAntecedentProps {
  title?: string;
  items: CheckBoxAntecedentItem[];
  showBorder?: boolean;
  reducerTypeLabel: AntecedentType;
  antecedentcategory: AntecedentCategoryType;
  listTitle: string;
  reducer: React.Dispatch<CreateAntecedentAction>;
  value?: CheckBoxAntecedentItem[];
}

const AddRemoveAntecedent: React.FC<
  React.PropsWithChildren<AddRemoveAntecedentProps>
> = ({
  title,
  items,
  listTitle,
  reducerTypeLabel,
  antecedentcategory,
  reducer,
  value,
  showBorder = true,
  children,
}) => {
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [savedItems, setSavedItems] = useState<CheckBoxAntecedentItem[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[] | any>([]);
  const [checkedItems, setCheckedItems] = useState<CheckBoxAntecedentItem[]>([]);

  useEffect(() => {
    if (!_.isEmpty(value) && !_.isUndefined(value)) {
      const orderedValues = value.filter(item => item?.category === antecedentcategory);
      const defaultState = _.map(orderedValues, 'id');
      reducer({ type: reducerTypeLabel, payload: orderedValues });
      setSavedItems(orderedValues);
      setSelectedRowKeys(defaultState);
    }
  }, [value]);

  const filteredItems =
    items &&
    items.filter(
      item =>
        item?.category === antecedentcategory &&
        item?.libelle.toLowerCase().includes(searchFilter),
    );

  const itemsColumns = [{ title: 'label', dataIndex: 'libelle' }];

  const handCloseModalVisility = () => {
    setIsModalVisible(false);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any) => {
    // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setCheckedItems(selectedRows);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const itemsSelections = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const removeItemFromSavedItem = (currentItem: CheckBoxAntecedentItem) => {
    setSelectedRowKeys(selectedRowKeys.filter(itemId => itemId !== currentItem.id));
    const result = savedItems.filter(item => item.id !== currentItem.id);
    setSavedItems(result);
  };

  const handleValidateItems = () => {
    setSavedItems(checkedItems);
    reducer({ type: reducerTypeLabel, payload: checkedItems });
    setIsModalVisible(false);
  };

  const renderModalSelectionList = () => {
    return (
      <StyledTable
        rowKey='id'
        size='small'
        showHeader={false}
        bordered={false}
        dataSource={filteredItems}
        pagination={false}
        rowSelection={itemsSelections}
        columns={itemsColumns}
      />
    );
  };

  const renderSelectedItems = () => {
    if (_.isEmpty(savedItems)) {
      return (
        <Empty
          style={{ width: '100%', margin: '0px' }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description='Aucun antécédent'
        />
      );
    } else {
      return savedItems.map(item => {
        return (
          <StyledButton.Group key={_.uniqueId(item.libelle)}>
            <StyledButton size='small'> {item.libelle}</StyledButton>
            <StyledButton
              size='small'
              danger
              icon={<FiX />}
              onClick={() => {
                removeItemFromSavedItem(item);
              }}
            />
          </StyledButton.Group>
        );
      });
    }
  };

  {
    /* <StyledTag
          key={_.uniqueId(item.libelle)}
          closable
          closeIcon={
            <FontAwesomeIcon
              icon={faClose}
              style={{
                color: '#c42f24',
              }}
              onClick={() => {
                removeItemFromSavedItem(item);
              }}
            />
          }>
          {item.libelle}
        </StyledTag> */
  }

  return (
    <>
      <StyledCardItem
        bordered={showBorder}
        title={!_.isUndefined(title) && title}
        styles={{ header: { backgroundColor: chartColors.lightGray } }}
        style={{ boxShadow: '0px 0px 5px #00000020' }}
        extra={
          <StyledAddTextButton
            onClick={() => {
              setIsModalVisible(true);
            }}>
            <span style={{ marginRight: '0.5rem' }}>
              <FontAwesomeIcon icon={faCirclePlus} />
            </span>
            Ajouter
          </StyledAddTextButton>
        }>
        <div
          style={{
            display: 'flex',
            minHeight: '70px',
            flexWrap: 'wrap',
            flexDirection: 'row',
            columnGap: '5px',
            justifyContent: 'flex-start',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}>
          {renderSelectedItems()}
          {children && (
            <>
              <StyledDivider />
              {children}
            </>
          )}
        </div>
      </StyledCardItem>

      <CustomModal
        isVisible={isModalVisible}
        handleVisibility={handCloseModalVisility}
        title={listTitle}
        afterCloseModal={() => setSearchFilter('')}
        footer={[
          <StyledButton
            key='cancel'
            type='primary'
            onClick={() => handleValidateItems()}
            size='middle'
            block>
            Valider et Fermer
          </StyledButton>,
        ]}>
        <StyledInput
          placeholder='Rechercher un élément'
          value={searchFilter}
          onChange={e => setSearchFilter(e.target.value)}
          prefix={<FiSearch />}
        />
        <ScrollContainer style={{ height: '50vh' }}>
          <div>{renderModalSelectionList()}</div>
        </ScrollContainer>
      </CustomModal>
    </>
  );
};

export default AddRemoveAntecedent;
