import { CreateEvolutionAction, CreateEvolutionState } from './types';

export const getCreateEvolutionDefaultInitialState = (): CreateEvolutionState => {
  return {
    evolution: '',
    evolutionDate: '',
    closed: false,
    invalidInputs: [],
  } as CreateEvolutionState;
};

export const CreateEvolutionReducer = (
  state: CreateEvolutionState,
  action: CreateEvolutionAction,
) => {
  const newState = { ...state };
  switch (action.type) {
    case 'setCommentEvolution':
      newState.evolution = action.payload;
      return newState;
    case 'setClosedEvolution':
      newState.closed = action.payload;
      return newState;
    case 'setEvolutionDate':
      newState.evolutionDate = action.payload;
      return newState;
    case 'setInvalidInputs':
      newState.invalidInputs = action.payload;
      return newState;
    default:
      return state;
  }
};
