import _ from 'lodash';
import {
  CreateBiologicalPrescriptionAction,
  CreateBiologicalPrescriptionState,
} from './types';

export const getCreateBiologicalPrescriptionDefaultInitialState =
  (): CreateBiologicalPrescriptionState => {
    return {
      prescriptionLinesBiological: [],
      invalidInputs: [],
    } as CreateBiologicalPrescriptionState;
  };

export const CreateBiologicalPrescriptionReducer = (
  state: CreateBiologicalPrescriptionState,
  action: CreateBiologicalPrescriptionAction,
) => {
  const newState = { ...state };
  switch (action.type) {
    case 'setBiologicalPrescriptionExams':
      newState.prescriptionLinesBiological = _.concat(
        state.prescriptionLinesBiological,
        action.payload,
      );
      return newState;

    case 'removeBiologicalExamLine':
      newState.prescriptionLinesBiological = state.prescriptionLinesBiological.filter(
        item => item.idBiologicalExam !== action.payload,
      );
      return newState;

    case 'setQuantityBiologicalExam': {
      const biologicalExamId = action.payload.idBiologicalExam;
      const biologicalExamValue = action.payload.value;

      return {
        ...state,
        prescriptionLinesBiological: state.prescriptionLinesBiological.map(m =>
          m.idBiologicalExam === biologicalExamId
            ? { ...m, quantity: biologicalExamValue }
            : m,
        ),
      };
    }

    case 'setResetBiologicalPrescritionExams': {
      newState.prescriptionLinesBiological = [];
      return newState;
    }

    case 'setInvalidInputs':
      newState.invalidInputs = action.payload;
      return newState;

    default:
      return state;
  }
};
