export interface IRightItem {
  id?: number;
  code: string;
  label: string;
}

export enum RIGHTS {
  TAB_BORD = 'TAB_BORD',
  DOSSIER = 'DOSSIER',
  PARACLI = 'PARACLI',
  HOSP = 'HOSP',
  PHARM = 'PHARM',
  IA = 'IA',
  FACTURATION = 'FACTURATION',
  CAISSE = 'CAISSE',
  KPI = 'KPI',
  REF = 'REF',
  ADMIN = 'ADMIN',
  RDV = 'RDV',
  RECOUV = 'RECOUV',
}

export enum PERMISSIONS {
  CONSULT = '_CONSULT',
  ETAB = '_ETAB',
  CREATION = '_CREATION',
  CREATION_CMD = '_CREATION_CMD',
  ETAT = '_ETAT',
  ENCAISSEMENT = '_ENCAISSEMENT',
  AVOIR = '_AVOIR',
}

export type GetRightsResponse = Array<IRightItem>;
export type CreateRightRequestBody = IRightItem & Omit<IRightItem, 'id'>;
export type UpdateUserRightsRequestBody = {
  idUser: number;
  idRightsToAdd: number[];
  idRightsToremove: number[];
};
