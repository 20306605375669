/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { getImageryExamsApi } from '../../../api/referentialService';
import { StyledButton } from '../../../components/button/style';
import CustomModal from '../../../components/modal/Modal';
import {
  StyledCol,
  StyledInput,
  StyledRow,
  StyledSpace,
  StyledTable,
} from '../../../components/style';
import {
  CreateImageryPrescriptionAction,
  CreateImageryPrescriptionState,
} from '../../../reducers/prescription/types';
import {
  GetImageryExamsResponse,
  IImageryExamItem,
  IImageryExamPrescription,
  UserDto,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle } from '../../../utils/constants';
import { ScrollContainer } from '../../style';
import ImageryExamItemCard from './ImageryExamItemCard';

interface AddRemoveImageryExamProps {
  listTitle: string;
  isLoading: boolean;
  reducer: React.Dispatch<CreateImageryPrescriptionAction>;
  state: CreateImageryPrescriptionState;
  userData: UserDto;
  isModalVisible: boolean;
  handleCloseModalVisibility: () => void;
}

const AddRemoveImageryExam: React.FC<AddRemoveImageryExamProps> = ({
  listTitle,
  isLoading,
  state,
  reducer,
  isModalVisible,
  handleCloseModalVisibility,
}) => {
  const { notification } = AppContainer.useApp();

  const [searchFilter, setSearchFilter] = useState<string>('');
  const [savedItems, setSavedItems] = useState<IImageryExamItem[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key | any>([]);
  const [checkedItems, setCheckedItems] = useState<IImageryExamItem[]>([]);
  const [imageryExamsData, setImageryExamsData] = useState<GetImageryExamsResponse>([]);

  const filteredItems = _.differenceBy(imageryExamsData, savedItems, 'id').filter(item =>
    item?.designation.toLowerCase().includes(searchFilter),
  );

  const itemsColumns = [
    {
      title: 'label',
      dataIndex: 'designation',
    },
  ];

  const loadImageryExams = async () => {
    Promise.resolve(getImageryExamsApi())
      .then(result => {
        setImageryExamsData(result);
      })
      .catch(error => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  useEffect(() => {
    loadImageryExams();
  }, []);

  useEffect(() => {
    const defaultState = _.map(state.prescriptionLinesImagery, 'idImageryExam');
    setSelectedRowKeys(defaultState);
  }, [state.prescriptionLinesImagery]);

  const handCloseModalVisility = () => {
    handleCloseModalVisibility();
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setCheckedItems(selectedRows);
  };

  const itemsSelections = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const mapToProformaLine = (imageryExams: IImageryExamItem[]) => {
    const prescriptionLinesItems: IImageryExamPrescription[] = [];

    imageryExams.map(item => {
      const proformaLineItem: IImageryExamPrescription = {
        idImageryExam: item.id,
        id: item.id,
        imageryExamDesignation: item.designation,
        quantity: 1,
      };

      prescriptionLinesItems.push(proformaLineItem);
    });

    return prescriptionLinesItems;
  };

  const handleValidateItems = () => {
    // On verifie que les éléments ajoutés n'existent pas déja
    const itemsToAdd = _.differenceBy(checkedItems, state.prescriptionLinesImagery, 'id');
    console.log(checkedItems);

    console.log(itemsToAdd);

    setSavedItems(_.concat(savedItems, itemsToAdd));
    handleCloseModalVisibility();
    reducer({
      type: 'setImageryPrescriptionExams',
      payload: mapToProformaLine(itemsToAdd),
    });
  };

  const removeItemFromSavedItem = (currentImageryExamId: number) => {
    const result = savedItems.filter(item => item.id !== currentImageryExamId);
    setSelectedRowKeys(selectedRowKeys.filter(itemId => itemId !== currentImageryExamId));
    setSavedItems(result);
    reducer({ type: 'removeImageryExamLine', payload: currentImageryExamId });
  };

  const renderModalSelectionList = () => {
    return (
      <StyledTable
        rowKey='id'
        size='small'
        showHeader={false}
        bordered={false}
        dataSource={filteredItems}
        pagination={false}
        rowSelection={itemsSelections}
        columns={itemsColumns}
      />
    );
  };

  const renderSelectedItems = () => {
    return (
      <StyledRow gutter={16}>
        {state.prescriptionLinesImagery.map(item => (
          <StyledCol
            key={item.imageryExamDesignation}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}>
            <ImageryExamItemCard
              imageryExamData={item}
              key={item.imageryExamDesignation}
              state={state}
              reducer={reducer}
              handleRemoveRubric={() => removeItemFromSavedItem(item.idImageryExam)}
            />
          </StyledCol>
        ))}
      </StyledRow>
    );
  };

  /**
   * Liste des médicaments a sélectionnés et a ajouter a la prescription
   * @returns JSX.Element
   */
  const renderMedicationSelection = () => {
    return (
      <>
        <StyledInput
          placeholder='Rechercher un examen'
          value={searchFilter}
          onChange={e => setSearchFilter(e.target.value)}
          prefix={<FiSearch />}
        />
        <ScrollContainer style={{ height: '50vh' }}>
          <div>{renderModalSelectionList()}</div>
        </ScrollContainer>
      </>
    );
  };

  return (
    <>
      {renderSelectedItems()}

      {isModalVisible && (
        <CustomModal
          isVisible={isModalVisible}
          handleVisibility={handCloseModalVisility}
          title={listTitle}
          afterCloseModal={() => setSearchFilter('')}
          footer={[
            <StyledSpace
              key='modal_actions'
              direction='vertical'
              style={{ width: '100%' }}>
              <StyledButton
                key='validate_biological_exam'
                type='primary'
                onClick={handleValidateItems}
                size='middle'
                loading={isLoading}
                disabled={isLoading || _.isEmpty(checkedItems)}
                block>
                Valider et Fermer
              </StyledButton>
            </StyledSpace>,
          ]}>
          {renderMedicationSelection()}
        </CustomModal>
      )}
    </>
  );
};

export default AddRemoveImageryExam;
