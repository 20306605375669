import {
  CreateRightRequestBody,
  GetRightsResponse,
  UpdateRightsFromRoleRequestBody,
  UpdateUserRightsRequestBody,
} from '../types';
import { axiosInstance } from './axiosInstance';

export const getRightsApi = (): Promise<GetRightsResponse> => {
  return axiosInstance
    .get('/rights')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des droits. Raison: ${message}`);
    });
};

export const getAllRightsFromRoleApi = (idRole: number): Promise<GetRightsResponse> => {
  return axiosInstance
    .get(`/getRightsFromRole?idRole=${idRole}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération des droits d'un rôle. Raison: ${message}`,
      );
    });
};

export const createRightApi = (data: CreateRightRequestBody): Promise<string> => {
  return axiosInstance
    .post('/right', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création de right. Raison: ${message}`);
    });
};

export const updateRightApi = (
  idRight: number,
  data: CreateRightRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/right?id=${idRight}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la mise à jour de right. Raison: ${message}`);
    });
};

export const deleteRightApi = (idRight: number): Promise<string> => {
  return axiosInstance
    .delete(`/right?id=${idRight}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la suppression du droit. Raison: ${message}`);
    });
};

export const updateUserRightsApi = (
  data: UpdateUserRightsRequestBody,
): Promise<string> => {
  return axiosInstance
    .post('/updateRightToUser', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la mise à jour des droits de l'utilisateur. Raison: ${message}`,
      );
    });
};

export const updateRightsFromRoleApi = (
  data: UpdateRightsFromRoleRequestBody,
): Promise<string> => {
  return axiosInstance
    .post('/updateRightsToRole', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la mise à jour des droits de du role. Raison: ${message}`,
      );
    });
};
