import React, { useState } from 'react';
import RegionsList from './RegionsList';
import RegionForm from './RegionForm';
import { IRegionItem, ReferentialViewProps } from '../../../types';

const RegionRef = () => {
  const [activeView, setActiveView] = useState<ReferentialViewProps>('LIST_ITEMS');
  const [selectedItem, setSelectedItem] = useState<IRegionItem>();

  const handleNavigateToView = (newView: ReferentialViewProps) => {
    setActiveView(newView);
  };

  const handleUpdateSelectedItem = (item?: IRegionItem) => {
    setSelectedItem(item);
  };

  const renderContent = () => {
    switch (activeView) {
      case 'LIST_ITEMS':
        return (
          <RegionsList
            navigateToview={handleNavigateToView}
            updateCurrentItem={handleUpdateSelectedItem}
          />
        );
      case 'EDIT_ITEM':
        return (
          <RegionForm navigateToview={handleNavigateToView} currentData={selectedItem} />
        );
      case 'NEW_ITEM':
        return <RegionForm navigateToview={handleNavigateToView} />;
    }
  };

  return <>{renderContent()}</>;
};

export default RegionRef;
