import { AiFillDashboard } from 'react-icons/ai';
import {
  BsBookmarkPlusFill,
  BsCalendar2DateFill,
  BsCollectionFill,
  BsFillFolderFill,
  BsFillGearFill,
  BsRobot,
} from 'react-icons/bs';
import {
  FaAmbulance,
  FaCashRegister,
  FaFileInvoiceDollar,
  FaHandHoldingMedical,
} from 'react-icons/fa';
import { MdLocalPharmacy } from 'react-icons/md';
import { IRightItem, RIGHTS } from '../../types';
import { stringifyRights } from '../../utils/commun';

export interface RouteItemsProps {
  title: string;
  path: string;
  icon: JSX.Element;
  focusedLinks: Array<string | RegExp>;
  rightsAccess: string[];
}

export const routes: Array<RouteItemsProps> = [
  {
    title: 'Tableau de bord',
    path: '/tableau-de-bord',
    icon: <AiFillDashboard />,
    focusedLinks: ['/tableau-de-bord'],
    rightsAccess: [RIGHTS.ADMIN, RIGHTS.TAB_BORD],
  },
  {
    title: 'Dossiers patient',
    path: '/consulter-dossier',
    icon: <BsFillFolderFill />,
    focusedLinks: ['/consulter-dossier', '/dossier-patient'],
    rightsAccess: [RIGHTS.ADMIN, RIGHTS.DOSSIER],
  },
  {
    title: 'Analyses paracliniques',
    path: '/analyses-paracliniques',
    icon: <FaHandHoldingMedical />,
    focusedLinks: ['/analyses-paracliniques'],
    rightsAccess: [RIGHTS.ADMIN, RIGHTS.PARACLI],
  },
  {
    title: 'Hospitalisation',
    path: '/hospitalisation',
    icon: <FaAmbulance />,
    focusedLinks: ['/hospitalisation'],
    rightsAccess: [RIGHTS.ADMIN, RIGHTS.HOSP],
  },
  {
    title: 'Pharmacie',
    path: '/pharmacie',
    icon: <MdLocalPharmacy />,
    focusedLinks: [/\/pharmacie\/(\w+)/, '/pharmacie/nouvelle-commande', '/pharmacie'],
    rightsAccess: [RIGHTS.ADMIN, RIGHTS.PHARM],
  },
  {
    title: 'Facturation',
    path: '/facturation',
    icon: <FaFileInvoiceDollar />,
    focusedLinks: ['/facturation/facturation-proforma', '/facturation'],
    rightsAccess: [RIGHTS.ADMIN, RIGHTS.FACTURATION],
  },
  {
    title: 'Caisse',
    path: '/caisse',
    icon: <FaCashRegister />,
    focusedLinks: ['/caisse'],
    rightsAccess: [RIGHTS.ADMIN, RIGHTS.CAISSE],
  },
  {
    title: 'Recouvrement',
    path: '/recouvrement',
    icon: <BsCollectionFill />,
    focusedLinks: ['/recouvrement'],
    rightsAccess: [RIGHTS.ADMIN, RIGHTS.RECOUV],
  },
  {
    title: 'Kpi et Décision assistée',
    path: '/kpi-decision-assistee',
    icon: <BsRobot />,
    focusedLinks: ['/kpi-decision-assistee'],
    rightsAccess: [RIGHTS.ADMIN, RIGHTS.IA, RIGHTS.KPI],
  },
  // {
  //   title: 'Ordonnances',
  //   path: '/ordonnances',
  //   icon: <BsClipboard2PulseFill />,
  //   focusedLinks: ['/ordonnances'],
  // },
  {
    title: 'Rendez-vous',
    path: '/rendez-vous',
    icon: <BsCalendar2DateFill />,
    focusedLinks: ['/rendez-vous/prise-de-rendez-vous', '/rendez-vous'],
    rightsAccess: [RIGHTS.ADMIN, RIGHTS.RDV],
  },
];

export const standardRoutes: Array<RouteItemsProps> = [
  {
    title: 'Référentiels',
    path: '/referentiels',
    icon: <BsBookmarkPlusFill />,
    focusedLinks: ['/referentiels'],
    rightsAccess: [RIGHTS.ADMIN, RIGHTS.REF],
  },
  {
    title: 'Paramètres',
    path: '/parametres',
    icon: <BsFillGearFill />,
    focusedLinks: ['/parametres'],
    rightsAccess: [RIGHTS.ADMIN],
  },
];

// export const filteredRoutesByUserRoles = (
//   routesParam: Array<RouteItemsProps>,
//   userRoles: Array<IRoleItem>,
// ) => {
//   const verifyAccess = (route: RouteItemsProps) => {
//     return stringifyRoles(userRoles, 'CODE').some(r => route.rolesAccess.includes(r));
//   };

//   return routesParam.filter(verifyAccess);
// };

export const filteredRoutesByUserRights = (
  routesParam: Array<RouteItemsProps>,
  userRights: Array<IRightItem>,
) => {
  const verifyAccess = (route: RouteItemsProps) => {
    return route.rightsAccess.some(el =>
      stringifyRights(userRights, 'CODE').some(r => r.includes(el)),
    );
    // return stringifyRights(userRights, 'CODE').some(r => route.rightsAccess.includes(r));
  };

  return routesParam.filter(verifyAccess);
};
