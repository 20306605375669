import { useState } from 'react';
import { ReferentialViewProps } from '../../../types';
import { IRoleItem } from '../../../types/role.types';
import RoleForm from './RoleForm';
import RolesList from './RolesList';

const RoleSetting = () => {
  const [activeView, setActiveView] = useState<ReferentialViewProps>('LIST_ITEMS');
  const [selectedRole, setSelectedRole] = useState<IRoleItem>();

  const handleNavigateToView = (newView: ReferentialViewProps) => {
    setActiveView(newView);
  };

  const handleUpdateSelectedRole = (role?: IRoleItem) => {
    setSelectedRole(role);
  };

  const renderContent = () => {
    switch (activeView) {
      case 'LIST_ITEMS':
        return (
          <RolesList
            navigateToview={handleNavigateToView}
            updateCurrentItem={handleUpdateSelectedRole}
          />
        );
      case 'EDIT_ITEM':
        return (
          <RoleForm navigateToview={handleNavigateToView} currentData={selectedRole} />
        );
      case 'NEW_ITEM':
        return <RoleForm navigateToview={handleNavigateToView} />;
    }
  };

  return <>{renderContent()}</>;
};

export default RoleSetting;
