import { PropsWithChildren } from 'react';
import { StyledVerticalLayoutWrapper } from './style';

interface VerticalLayoutProps {
  layerShowPadding?: 'true' | 'false';
  enableVerticalScroll?: 'true' | 'false';
}

const VerticalLayout: React.FC<PropsWithChildren<VerticalLayoutProps>> = ({
  layerShowPadding = 'false',
  enableVerticalScroll = 'false',
  children,
}) => {
  return (
    <StyledVerticalLayoutWrapper
      $layerShowPadding={layerShowPadding}
      $enableVerticalScroll={enableVerticalScroll}>
      {children}
    </StyledVerticalLayoutWrapper>
  );
};

export default VerticalLayout;
