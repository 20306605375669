import { useState } from 'react';
import { IMedicationItem, ReferentialViewProps } from '../../../types';
import MedicationForm from './MedicationForm';
import MedicationsList from './MedicationsList';

const MedicationsRef = () => {
  const [activeView, setActiveView] = useState<ReferentialViewProps>('LIST_ITEMS');
  const [selectedMedication, setSelectedMedication] = useState<IMedicationItem>();

  const handleNavigateToView = (newView: ReferentialViewProps) => {
    setActiveView(newView);
  };

  const handleUpdateSelectedMedication = (medication?: IMedicationItem) => {
    setSelectedMedication(medication);
  };

  const renderContent = () => {
    switch (activeView) {
      case 'LIST_ITEMS':
        return (
          <MedicationsList
            navigateToview={handleNavigateToView}
            updateCurrentItem={handleUpdateSelectedMedication}
          />
        );
      case 'EDIT_ITEM':
        return (
          <MedicationForm
            navigateToview={handleNavigateToView}
            currentData={selectedMedication}
          />
        );
      case 'NEW_ITEM':
        return <MedicationForm navigateToview={handleNavigateToView} />;
    }
  };

  return <>{renderContent()}</>;
};

export default MedicationsRef;
