import { App as AppContainer, Avatar, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { BiMinusCircle, BiPlusCircle, BiSolidBadgeCheck } from 'react-icons/bi';
import { LuBox } from 'react-icons/lu';
import { MdCheckCircle } from 'react-icons/md';
import { TbHistoryOff } from 'react-icons/tb';
import { getArticlesStockHistoryApi } from '../../../api/pharmacyService';
import { getArticlesApi } from '../../../api/referentialService';
import { StyledButton } from '../../../components/button/style';
import EmptyNotFound from '../../../components/emptyData/EmptyNotFound';
import EmptyNotSelected from '../../../components/emptyData/EmptyNotSelected';
import SectionHeader from '../../../components/header/SectionHeader';
import Loader from '../../../components/loader';
import {
  StyledCardItem,
  StyledCol,
  StyledRow,
  StyledSpace,
  StyledTimeline,
} from '../../../components/style';
import PageContentLeftLayout from '../../../layout/PageContentLeftLayout';
import PageContentRightLayout from '../../../layout/PageContentRightLayout';
import { StyledLayoutGridWrapper } from '../../../layout/style';
import chartColors from '../../../styles/color';
import {
  GetArticlesHistoryResponse,
  GetArticlesResponse,
  IArticleItem,
  StepperComponentProps,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle } from '../../../utils/constants';
import { StyleListContainer, StyledPrimarytext } from '../../style';

const PharmacyStock: React.FC<StepperComponentProps> = () => {
  const { notification } = AppContainer.useApp();
  const [isLoadingArticles, setIsLoadingArticles] = useState<boolean>(false);
  const [isLoadingStockHistory, setIsLoadingStockHistory] = useState<boolean>(false);

  const [selectedArticle, setSelectedArticle] = useState<IArticleItem>();
  const [articles, setArticles] = useState<GetArticlesResponse>([]);
  const [articleHistory, setArticleHistory] = useState<GetArticlesHistoryResponse>([]);
  const [articleHistoryTimelineData, setArticleHistoryTimelineData] = useState<
    Array<{ children: React.ReactNode; label?: string }>
  >([]);

  const articlesColumnData: ColumnsType<IArticleItem> = [
    {
      title: '',
      dataIndex: 'designation',
    },
    {
      title: 'Actions',
      align: 'right',
      render: (value, record) => {
        return (
          <StyledSpace>
            {record.id === selectedArticle?.id ? (
              <Avatar
                style={{ backgroundColor: chartColors.deepBlue }}
                icon={<BiSolidBadgeCheck size={30} />}
              />
            ) : (
              <StyledButton
                shape='circle'
                icon={<MdCheckCircle />}
                size='middle'
                onClick={() => {
                  setSelectedArticle(record);
                }}
              />
            )}
          </StyledSpace>
        );
      },
    },
  ];

  // const historyColumnData: ColumnsType<IArticleHistoryItem> = [
  //   {
  //     title: '',
  //     dataIndex: 'date',
  //   },
  //   {
  //     title: '',
  //     dataIndex: 'referenceArticle',
  //   },
  //   {
  //     title: '',
  //     dataIndex: 'quantity',
  //   },
  // ];

  const loadArticles = async () => {
    setIsLoadingArticles(true);
    Promise.resolve(getArticlesApi())
      .then(result => {
        setArticles(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoadingArticles(false);
      });
  };

  const loadArticleHistory = async (articleId: number) => {
    setIsLoadingStockHistory(true);
    Promise.resolve(getArticlesStockHistoryApi(articleId))
      .then(result => {
        setArticleHistory(result);
        setArticleHistoryTimelineData(
          result.map(t => ({
            dot:
              t.quantity > 0 ? (
                <BiPlusCircle />
              ) : (
                <BiMinusCircle color={chartColors.danger} />
              ),
            children: (
              <StyledSpace direction='horizontal' split='|'>
                <StyledPrimarytext
                  style={{
                    color: t.quantity > 0 ? chartColors.deepBlue : chartColors.danger,
                  }}>
                  {t.date}
                </StyledPrimarytext>
                <div>
                  <span style={{ fontWeight: 600 }}>{t.referenceArticle} </span>
                  <span style={{ fontWeight: 600 }}>{t.quantity} unité(s)</span>
                </div>
              </StyledSpace>
            ),
          })),
        );
        setIsLoadingStockHistory(false);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
        setIsLoadingStockHistory(false);
      });
  };

  useEffect(() => {
    loadArticles();
  }, []);

  useEffect(() => {
    if (!_.isUndefined(selectedArticle)) {
      loadArticleHistory(selectedArticle.id as number);
    }
  }, [selectedArticle]);

  const renderArticles = () => {
    return (
      <StyleListContainer
        style={{
          padding: '0px 20px',
          paddingBottom: '30px',
          maxHeight: '750px',
        }}>
        <Table
          rowKey='id'
          size='small'
          showHeader={false}
          columns={articlesColumnData}
          dataSource={articles}
          pagination={false}
          loading={isLoadingArticles}
        />
      </StyleListContainer>
    );
  };

  const renderArticleStock = () => {
    return (
      <>
        <StyledCardItem
          title='Stock actuel'
          styles={{ header: { backgroundColor: chartColors.lightGray } }}
          style={{
            marginBottom: 20,
            fontSize: 60,
            fontWeight: 600,
            color: chartColors.deepBlue,
          }}>
          {selectedArticle?.stock}
        </StyledCardItem>
      </>
    );
  };

  const renderArticleStockHistory = () => {
    return (
      <>
        <StyledCardItem
          title='Historique du stock'
          styles={{
            body: {
              padding: '10px  0px',
            },
            header: {
              backgroundColor: chartColors.lightGray,
            },
          }}>
          <StyleListContainer forceVisible='y' autoHide={false} style={{ height: 400 }}>
            <StyledTimeline mode='left' items={articleHistoryTimelineData} />
          </StyleListContainer>
        </StyledCardItem>
        {/* <SectionHeader title='Historique du stock' /> */}
        {/* <Table
          rowKey='idArticle'
          size='small'
          showHeader={false}
          columns={historyColumnData}
          dataSource={articleHistory}
          pagination={false}
        /> */}
      </>
    );
  };

  const renderContent = () => {
    return (
      <>
        {_.isUndefined(selectedArticle) ? (
          <EmptyNotSelected
            message='Aucun article sélectionné'
            description='Veuillez sélectionné un article'
            icon={<LuBox size={80} />}
          />
        ) : (
          <StyleListContainer
            style={{ padding: '20px', paddingBottom: 180, maxHeight: 750 }}>
            <StyledRow gutter={[16, 16]}>
              <StyledCol
                xs={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 18 }}
                xl={{ span: 18 }}>
                <Loader showSpinner={isLoadingStockHistory}>
                  {_.isEmpty(articleHistory) ? (
                    <EmptyNotFound
                      message="Aucun historique pour l'instant"
                      description='Veuillez essayer avec un autre article'
                      icon={<TbHistoryOff size={80} />}
                    />
                  ) : (
                    renderArticleStockHistory()
                  )}
                </Loader>
              </StyledCol>
              <StyledCol
                xs={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}>
                {renderArticleStock()}
              </StyledCol>
            </StyledRow>
          </StyleListContainer>
        )}
      </>
    );
  };

  return (
    <StyledLayoutGridWrapper style={{ marginTop: 4 }}>
      <PageContentLeftLayout
        layerShowPadding='false'
        headerTitle={
          <div
            style={{
              padding: '0px 20px',
            }}>
            <SectionHeader title='Article' count={articles.length} />
          </div>
        }>
        {renderArticles()}
      </PageContentLeftLayout>
      <PageContentRightLayout layerShowPadding='false'>
        <div style={{ overflowY: 'auto' }}>{renderContent()}</div>
      </PageContentRightLayout>
    </StyledLayoutGridWrapper>
  );
};

export default PharmacyStock;
