/* eslint-disable @typescript-eslint/no-explicit-any */
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { BsNodePlus } from 'react-icons/bs';
import { FiSearch } from 'react-icons/fi';
import { StyledButton } from '../../../../components/button/style';
import CustomModal from '../../../../components/modal/Modal';
import { StyledInput } from '../../../../components/style';
import { UpdateRubricsIntoInvoicePlanAction } from '../../../../reducers/invoicePlan/types';
import { IInvoiceRubricItem, InvoiceRubricsActionType } from '../../../../types';
import { ScrollContainer } from '../../../style';

interface AddRemoveRubricProps {
  items: IInvoiceRubricItem[];
  reducerTypeLabel: InvoiceRubricsActionType;
  listTitle: string;
  reducer: React.Dispatch<UpdateRubricsIntoInvoicePlanAction>;
  value?: IInvoiceRubricItem[];
  isLoading: boolean;
  handleUpdate: () => void;
}
const AddRemoveRubric: React.FC<AddRemoveRubricProps> = ({
  items,
  reducerTypeLabel,
  listTitle,
  reducer,
  value,
  isLoading,
  handleUpdate,
}) => {
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  // const [savedItems, setSavedItems] = useState<IInvoiceRubricItem[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key | any>([]);

  const filteredItems = items.filter(item => item?.label.includes(searchFilter));

  const itemsColumns: ColumnsType<IInvoiceRubricItem> = [
    {
      title: 'Rubriques',
      dataIndex: 'label',
      render: (_value, record) => <>{record.label}</>,
    },
    {
      title: 'Montant classique',
      align: 'center',
      children: [
        {
          title: 'Jour Ouvrable',
          dataIndex: 'classique_amount_JO',
          align: 'center',
        },
        {
          title: 'Dimanche, Nuit et Férié',
          dataIndex: 'classique_amount_D_N_JF',
          align: 'center',
        },
      ],
    },
    {
      title: 'Montant Mutualiste',
      align: 'center',
      children: [
        {
          title: 'Jour Ouvrable',
          dataIndex: 'mutualiste_amount_JO',
          align: 'center',
        },
        {
          title: 'Dimanche, Nuit et Férié',
          dataIndex: 'mutualiste_amount_D_N_JF',
          align: 'center',
        },
      ],
    },
  ];

  useEffect(() => {
    if (!_.isEmpty(value) && !_.isUndefined(value)) {
      const defaultState = _.map(value, 'id');
      //   setSavedItems(value);
      setSelectedRowKeys(defaultState);
    }
  }, [value]);

  const handCloseModalVisility = () => {
    setIsModalVisible(false);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    // setCheckedItems(selectedRows);
    reducer({ type: reducerTypeLabel, payload: newSelectedRowKeys as number[] });
  };

  const itemsSelections = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleValidateItems = () => {
    handleUpdate();
    setIsModalVisible(false);
  };

  const renderModalSelectionList = () => {
    return (
      <Table
        rowKey='id'
        size='small'
        showHeader={false}
        bordered={false}
        dataSource={filteredItems}
        pagination={false}
        rowSelection={itemsSelections}
        columns={itemsColumns}
      />
    );
  };

  return (
    <>
      <StyledButton
        size='middle'
        icon={<BsNodePlus />}
        onClick={() => {
          setIsModalVisible(true);
        }}>
        Ajouter/Retirer une Rubrique
      </StyledButton>

      <CustomModal
        isVisible={isModalVisible}
        handleVisibility={handCloseModalVisility}
        title={listTitle}
        afterCloseModal={() => setSearchFilter('')}
        footer={[
          <StyledButton
            key='cancel'
            type='primary'
            onClick={() => handleValidateItems()}
            size='middle'
            loading={isLoading}
            disabled={isLoading}
            block>
            Valider et Fermer
          </StyledButton>,
        ]}>
        <StyledInput
          placeholder='Rechercher un élément'
          value={searchFilter}
          onChange={e => setSearchFilter(e.target.value)}
          prefix={<FiSearch />}
        />
        <ScrollContainer style={{ height: '50vh' }}>
          <div>{renderModalSelectionList()}</div>
        </ScrollContainer>
      </CustomModal>
    </>
  );
};

export default AddRemoveRubric;
