import { CreateInvoicePlanAction, CreateInvoicePlanState } from './types';

export const getCreateInvoicePlanDefaultInitialState = (): CreateInvoicePlanState => {
  return {
    label: '',
    active: true,
    invalidInputs: [],
  } as CreateInvoicePlanState;
};

export const CreateInvoicePlanReducer = (
  state: CreateInvoicePlanState,
  action: CreateInvoicePlanAction,
) => {
  const newState = { ...state };
  switch (action.type) {
    case 'setLabelInvoicePlan':
      newState.label = action.payload;
      return newState;
    case 'setActiveInvoicePlan':
      newState.active = action.payload;
      return newState;

    case 'setInvalidInputs':
      newState.invalidInputs = action.payload;
      return newState;
    default:
      return state;
  }
};
