import React from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { StyledButton } from '../../../components/button/style';
import { StyledCardItem } from '../../../components/style';

import {
  CreateBiologicalPrescriptionAction,
  CreateBiologicalPrescriptionState,
} from '../../../reducers/prescription/types';
import chartColors from '../../../styles/color';
import { IBiologicalExamPrescription } from '../../../types';

interface BiologicalExamItemCardProps {
  biologicalExamData: IBiologicalExamPrescription;
  reducer: React.Dispatch<CreateBiologicalPrescriptionAction>;
  state: CreateBiologicalPrescriptionState;
  handleRemoveRubric: () => void;
}

const BiologicalExamItemCard: React.FC<BiologicalExamItemCardProps> = ({
  biologicalExamData,
  handleRemoveRubric,
}) => {
  // const checkExistingRubric = (): number => {
  //   const indexFound = state.prescriptionLinesBiological.findIndex(
  //     item => item.idBiologicalExam === biologicalExamData.idBiologicalExam,
  //   );
  //   if (indexFound !== -1) {
  //     return indexFound;
  //   }
  //   return 0;
  // };

  return (
    <StyledCardItem
      style={{ margin: '10px 0px' }}
      styles={{
        header: {
          fontFamily: 'Metropolis',
          textTransform: 'uppercase',
          backgroundColor: chartColors.blue50,
        },
      }}
      title={biologicalExamData.biologicalExamDesignation}
      size='small'
      extra={
        <StyledButton
          danger
          size='small'
          icon={<FiTrash2 />}
          onClick={() => handleRemoveRubric()}
        />
      }
    />
    // <StyledRow gutter={[20, 20]}>
    //   <StyledCol span={8}>
    //     <FormFieldLabel libelle='Quantité' />
    //     <StyledInputNumber
    //       style={{ width: '100%' }}
    //       placeholder='Quantité'
    //       min={1}
    //       onChange={val =>
    //         reducer({
    //           type: 'setQuantityBiologicalExam',
    //           payload: {
    //             idBiologicalExam: biologicalExamData.idBiologicalExam,
    //             value: val as number,
    //           },
    //         })
    //       }
    //       value={state.prescriptionLinesBiological[checkExistingRubric()]?.quantity}
    //     />
    //   </StyledCol>
    //   <StyledCol span={21}>
    //     <FormFieldLabel libelle='Commentaire' />
    //     <StyledInput
    //       style={{ width: '100%' }}
    //       placeholder='Posologie'
    //       onChange={e =>
    //         reducer({
    //           type: 'set',
    //           payload: {
    //             idMedication: biologicalExamData.idBiologicalExam,
    //             value: e.target.value,
    //           },
    //         })
    //       }
    //       value={
    //         state.prescriptionLinesBiological[checkExistingRubric()]
    //           ?.biologicalExamDesignation
    //       }
    //     />
    //   </StyledCol>
    // </StyledRow>
  );
};

export default BiologicalExamItemCard;
