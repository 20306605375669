import { App as AppContainer } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { ColumnsType } from 'antd/es/table';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FaUserInjured } from 'react-icons/fa';
import { getMedicalRecordsByPatient } from '../../api/dossierPatientService';
import { getPatients } from '../../api/patientService';
import EmptyNotSelected from '../../components/emptyData/EmptyNotSelected';
import SectionHeader from '../../components/header/SectionHeader';
import FormFieldLabel from '../../components/label/FormFieldLabel';
import Loader from '../../components/loader';
import PageHeader from '../../components/pageHeader/PageHeader';
import MedicalRecordPatientList from '../../components/patientHistory/MedicalRecordPatientList';
import {
  StyledSelectOption,
  StyledSelectPicker,
  StyledSpace,
} from '../../components/style';
import PageContentLeftLayout from '../../layout/PageContentLeftLayout';
import PageContentRightLayout from '../../layout/PageContentRightLayout';
import VerticalLayout from '../../layout/VerticalLayout';
import { StyledLayoutGridWrapper } from '../../layout/style';
import chartColors from '../../styles/color';
import {
  CreateMedicalRecordApiResponse,
  GetPatientApiResponse,
  PatientDto,
} from '../../types';
import { getApiErrorMsg } from '../../utils/commun';
import { ErrorMsgTitle } from '../../utils/constants';
import {
  StyleListContainer,
  StyledBlocContainer,
  StyledMedicalRecordsPatientHeader,
} from '../style';
import ExternalAnalysis from './ExternalAnalysis';
import InternalAnalysis from './InternalAnalysis';

// const RenderTabsHeader = ({
//   currentTab,
//   handleTabChanged,
// }: {
//   currentTab: number;
//   handleTabChanged: (value: SegmentedValue) => void;
// }) => {
//   const tabs: Array<ITab> = [
//     { value: 1, label: 'Analyses Biologiques' },
//     { value: 2, label: 'Imageries/IRM' },
//   ];

//   return (
//     <>
//       <StyledSegmented
//         size='large'
//         block
//         options={tabs}
//         value={currentTab}
//         onChange={handleTabChanged}
//         style={{
//           backgroundColor: chartColors.blue50,
//           borderRadius: '0',
//           margin: 0,
//         }}
//       />
//       <StyledDivider style={{ margin: -2, border: `2px solid ${chartColors.blue}` }} />
//     </>
//   );
// };

const ParaClinicAnalysis: React.FC = () => {
  const { notification } = AppContainer.useApp();
  // const [currentTab, setCurrentTab] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedPatient, setSelectedPatient] = useState<number | null>(null);
  const [currentPatient, setCurrentPatient] = useState<PatientDto>();
  const [currentMedicalRecord, setCurrentMedicalRecord] =
    useState<CreateMedicalRecordApiResponse | null>(null);

  const [medicalRecords, setMedicalRecords] = useState<
    Array<CreateMedicalRecordApiResponse>
  >([]);
  const [patientsList, setPatientsList] = useState<GetPatientApiResponse[]>([]);

  const loadPatients = () => {
    setIsLoading(true);
    Promise.resolve(getPatients())
      .then(result => {
        setPatientsList(result);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadPatientMedicalRecords = () => {
    setIsLoading(true);
    Promise.resolve(getMedicalRecordsByPatient(currentPatient?.id as number))
      .then(result => {
        setMedicalRecords(result);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadPatients();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(currentPatient)) {
      loadPatientMedicalRecords();
    }
  }, [currentPatient]);

  // const handleTabChanged = value => {
  //   setCurrentTab(value);
  // };

  const handleResetSelectedMedicalRecord = () => {
    setSelectedPatient(null);
    setCurrentPatient(undefined);
    setCurrentMedicalRecord(null);
  };

  /* The `columnsMedicalRecords` constant is an array of column configurations for a table component.
  Each object in the array represents a column and contains properties such as `title`, `dataIndex`,
  and `render`. */
  const columnsMedicalRecords: ColumnsType<CreateMedicalRecordApiResponse> = [
    {
      title: 'No Dossier',
      dataIndex: 'numMedicalRecord',
      render: (value, record) => (
        <StyledSpace direction='vertical'>
          <StyledSpace
            className={`cursor-pointer hover:text-blue-600 ${
              record.id === currentMedicalRecord?.id && 'text-blue-600'
            }`}
            split='|'
            onClick={() => {
              setCurrentMedicalRecord(record);
            }}>
            <div style={{ fontWeight: 700 }}>{record.numMedicalRecord}</div>
            <span>{record.createdDate}</span>
          </StyledSpace>
        </StyledSpace>
      ),
    },
    // {
    //   title: 'Actions',
    //   align: 'right',
    //   render: (value, record) => {
    //     return (
    //       <StyledSpace>
    //         {record.id === currentMedicalRecord?.id ? (
    //           <Avatar
    //             style={{ backgroundColor: chartColors.deepBlue }}
    //             icon={<BiSolidBadgeCheck size={30} />}
    //           />
    //         ) : (
    //           <StyledButton
    //             shape='circle'
    //             icon={<MdCheckCircle />}
    //             size='middle'
    //             onClick={() => {
    //               setCurrentMedicalRecord(record);
    //             }}
    //           />
    //         )}
    //       </StyledSpace>
    //     );
    //   },
    // },
  ];

  /**
   * The function `renderHeader` returns a `PageHeader` component with the title 'Analyses
   * paracliniques'.
   * @returns The renderHeader function is returning a PageHeader component with the title prop set to
   * 'Analyses paracliniques'.
   */
  const renderHeader = () => {
    return (
      <>
        <PageHeader title='Analyses paracliniques' />
      </>
    );
  };

  const renderPatientSelector = () => {
    return (
      <Loader showSpinner={isLoading}>
        <StyledBlocContainer style={{ padding: 20 }}>
          <FormFieldLabel libelle='Patient' />
          <StyledSelectPicker
            placeholder='Rechercher par nom, CNI ou Numéro patient'
            onClear={() => handleResetSelectedMedicalRecord()}
            style={{ width: '100%' }}
            allowClear
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            onChange={(value, option: DefaultOptionType['data']) => {
              setSelectedPatient(value as number);
              setCurrentMedicalRecord(null);
              setCurrentPatient(option?.data ?? []);
            }}
            defaultValue={''}
            value={selectedPatient}>
            {_.map(patientsList, item => (
              <StyledSelectOption
                key={item.id}
                value={item.id}
                data={item}
                label={`${item.firstname} ${item.name} | ${item.cni} | ${item.numPatient}`}>
                <StyledSpace split='|'>
                  <span style={{ fontWeight: 700 }}>{item.numPatient}</span>
                  {`${item.firstname} ${item.name}`}
                  <span style={{ fontWeight: 700 }}>{item.cni}</span>
                </StyledSpace>
              </StyledSelectOption>
            ))}
          </StyledSelectPicker>
        </StyledBlocContainer>
      </Loader>
    );
  };

  const renderTabContent = () => {
    if (
      _.isNull(selectedPatient) ||
      _.isUndefined(currentMedicalRecord) ||
      _.isNull(currentMedicalRecord)
    ) {
      return (
        <EmptyNotSelected
          description='Veuillez sélectionner un dossier médical'
          message='Aucun dossier Sélectionné'
        />
      );
    }
    return (
      <>
        <InternalAnalysis
          selectedPatientId={selectedPatient}
          selectedMedicalRecord={currentMedicalRecord}
        />

        <ExternalAnalysis
          selectedPatientId={selectedPatient}
          selectedMedicalRecord={currentMedicalRecord}
        />
      </>
    );
  };
  // const renderTabContent = () => {

  /**
   * The function `renderMedicalRecordPatient` renders a list of medical records for a selected
   * patient, or displays a message if no patient is selected.
   * @returns The function `renderMedicalRecordPatient` returns JSX elements. If the `currentPatient`
   * is empty, it returns an `<EmptyPatientSelected>` component with a message and description.
   * Otherwise, it returns a container with a header and a list of medical records.
   */
  const renderMedicalRecordPatient = () => {
    if (_.isEmpty(currentPatient) || _.isUndefined(currentPatient)) {
      return (
        <EmptyNotSelected
          message='Aucun patient sélectionné'
          description='Veuillez sélectionner un patient pour voir des dossiers médicaux'
          icon={<FaUserInjured size={80} />}
        />
      );
    }
    return (
      <>
        <StyledMedicalRecordsPatientHeader>
          <SectionHeader title='Dossier' count={medicalRecords.length} />
        </StyledMedicalRecordsPatientHeader>
        <StyleListContainer
          style={{
            padding: '0px',
            paddingBottom: '30px',
            position: 'relative',
            height: '100vh',
          }}>
          <MedicalRecordPatientList
            dataMedicalRecords={medicalRecords}
            customColumns={columnsMedicalRecords}
            showListHeader={false}
            showTableHeader={false}
            hasPagination={false}
          />
        </StyleListContainer>
      </>
    );
  };

  /**
   * The function `renderContent` returns a JSX element that renders a layout grid wrapper with two
   * child divs, one of which renders a medical record patient component and the other renders tab
   * content.
   * @returns The function `renderContent` is returning a JSX element. The JSX element is wrapped in a
   * `StyledLayoutGridWrapper` component and consists of two `div` elements. The first `div` has a
   * background color of `chartColors.white` and renders the result of the `renderMedicalRecordPatient`
   * function. The second `div` renders the result of the `renderTabContent` function
   */
  const renderContent = () => {
    return (
      <div style={{ marginTop: 15 }}>
        <StyleListContainer
          style={{ padding: 0, height: 'calc(100vh - 100px)', paddingBottom: '250px' }}>
          {/* <RenderTabsHeader currentTab={currentTab} handleTabChanged={handleTabChanged} /> */}
          {renderTabContent()}
        </StyleListContainer>
      </div>
    );
  };

  return (
    <VerticalLayout enableVerticalScroll='false'>
      {renderHeader()}
      <StyledLayoutGridWrapper>
        <PageContentLeftLayout layerShowPadding='false'>
          <div style={{ backgroundColor: `${chartColors.blue50}20` }}>
            {renderPatientSelector()}
            {renderMedicalRecordPatient()}
          </div>
        </PageContentLeftLayout>
        <PageContentRightLayout layerShowPadding='false'>
          <div style={{ overflowY: 'auto' }}>{renderContent()}</div>
        </PageContentRightLayout>
      </StyledLayoutGridWrapper>
    </VerticalLayout>
  );
};

export default ParaClinicAnalysis;
