import {
  CreateInsuranceRequestBody,
  GetInsurancesResponse,
} from '../types/insurance.types';
import { axiosInstance } from './axiosInstance';

export const getInsurancesApi = (): Promise<GetInsurancesResponse> => {
  return axiosInstance
    .get('/insurances')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des assurances. Raison: ${message}`);
    });
};

export const createInsuranceApi = (data: CreateInsuranceRequestBody): Promise<string> => {
  return axiosInstance
    .post('/insurance', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création de l'assurance. Raison: ${message}`);
    });
};

export const updateInsuranceApi = (
  idInsurance: number,
  data: CreateInsuranceRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/insurance?id=${idInsurance}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la mise à jour de l'assurance. Raison: ${message}`);
    });
};

export const deleteInsuranceApi = (idRoom: number): Promise<string> => {
  return axiosInstance
    .delete(`/insurance?id=${idRoom}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la suppression de l'assurance. Raison: ${message}`);
    });
};
