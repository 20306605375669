/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { getBiologicalExamsApi } from '../../../api/biologicalExamService';
import { StyledButton } from '../../../components/button/style';
import CustomModal from '../../../components/modal/Modal';
import {
  StyledCol,
  StyledInput,
  StyledRow,
  StyledSpace,
  StyledTable,
} from '../../../components/style';
import {
  CreateBiologicalPrescriptionAction,
  CreateBiologicalPrescriptionState,
} from '../../../reducers/prescription/types';
import {
  GetBiologicalExamsResponse,
  IBiologicalExamItem,
  IBiologicalExamPrescription,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle } from '../../../utils/constants';
import { ScrollContainer } from '../../style';
import BiologicalExamItemCard from './BiologicalExamItemCard';

interface AddRemoveBiologicalExamProps {
  listTitle: string;
  isLoading: boolean;
  reducer: React.Dispatch<CreateBiologicalPrescriptionAction>;
  state: CreateBiologicalPrescriptionState;
  isModalVisible: boolean;
  handleCloseModalVisibility: () => void;
}

const AddRemoveBiologicalExam: React.FC<AddRemoveBiologicalExamProps> = ({
  listTitle,
  isLoading,
  state,
  reducer,
  isModalVisible,
  handleCloseModalVisibility,
}) => {
  const { notification } = AppContainer.useApp();

  const [searchFilter, setSearchFilter] = useState<string>('');
  const [savedItems, setSavedItems] = useState<IBiologicalExamItem[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key | any>([]);
  const [checkedItems, setCheckedItems] = useState<IBiologicalExamItem[]>([]);
  const [biologicalExamsData, setBiologicalExamsData] =
    useState<GetBiologicalExamsResponse>([]);

  const [showCreateMedicationForm, setShowCreateMedicationForm] =
    useState<boolean>(false);

  const filteredItems = _.differenceBy(biologicalExamsData, savedItems, 'id').filter(
    item => item?.designation.toLowerCase().includes(searchFilter),
  );

  const itemsColumns = [
    {
      title: 'label',
      dataIndex: 'label',
      render: (value, record) => <>{record.designation}</>,
    },
  ];

  const loadBiologicalExams = async () => {
    Promise.resolve(getBiologicalExamsApi())
      .then(result => {
        setBiologicalExamsData(result);
      })
      .catch(error => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  useEffect(() => {
    loadBiologicalExams();
  }, []);

  useEffect(() => {
    const defaultState = _.map(state.prescriptionLinesBiological, 'idBiologicalExam');
    setSelectedRowKeys(defaultState);
  }, [state.prescriptionLinesBiological]);

  const handCloseModalVisility = () => {
    handleCloseModalVisibility();
    setShowCreateMedicationForm(false);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any) => {
    // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    setCheckedItems(selectedRows);
    // console.log(selectedRows);
  };

  const itemsSelections = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const mapToProformaLine = (biologicalExams: IBiologicalExamItem[]) => {
    const prescriptionLinesItems: IBiologicalExamPrescription[] = [];

    biologicalExams.map(item => {
      const proformaLineItem: IBiologicalExamPrescription = {
        idBiologicalExam: item.id,
        id: item.id,
        biologicalExamDesignation: item.designation,
        quantity: 1,
      };

      prescriptionLinesItems.push(proformaLineItem);
    });

    return prescriptionLinesItems;
  };

  const handleValidateItems = () => {
    // On verifie que les éléments ajoutés n'existent pas déja
    const itemsToAdd = _.differenceBy(
      checkedItems,
      state.prescriptionLinesBiological,
      'id',
    );
    console.log(checkedItems);

    console.log(itemsToAdd);

    setSavedItems(_.concat(savedItems, itemsToAdd));
    handleCloseModalVisibility();
    reducer({
      type: 'setBiologicalPrescriptionExams',
      payload: mapToProformaLine(itemsToAdd),
    });
  };

  const removeItemFromSavedItem = (currentBiologicalExamId: number) => {
    const result = savedItems.filter(item => item.id !== currentBiologicalExamId);
    setSelectedRowKeys(
      selectedRowKeys.filter(itemId => itemId !== currentBiologicalExamId),
    );
    setSavedItems(result);
    reducer({ type: 'removeBiologicalExamLine', payload: currentBiologicalExamId });
  };

  const renderModalSelectionList = () => {
    return (
      <StyledTable
        rowKey='id'
        size='small'
        showHeader={false}
        bordered={false}
        dataSource={filteredItems}
        pagination={false}
        rowSelection={itemsSelections}
        columns={itemsColumns}
      />
    );
  };

  const renderSelectedItems = () => {
    return (
      <StyledRow gutter={16}>
        {state.prescriptionLinesBiological.map(item => (
          <StyledCol
            key={item.biologicalExamDesignation}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}>
            <BiologicalExamItemCard
              biologicalExamData={item}
              key={item.biologicalExamDesignation}
              state={state}
              reducer={reducer}
              handleRemoveRubric={() => removeItemFromSavedItem(item.idBiologicalExam)}
            />
          </StyledCol>
        ))}
      </StyledRow>
    );
  };

  /**
   * Liste des médicaments a sélectionnés et a ajouter a la prescription
   * @returns JSX.Element
   */
  const renderMedicationSelection = () => {
    return (
      <>
        <StyledInput
          placeholder='Rechercher un examen'
          value={searchFilter}
          onChange={e => setSearchFilter(e.target.value)}
          prefix={<FiSearch />}
        />
        <ScrollContainer style={{ height: '50vh' }}>
          <div>{renderModalSelectionList()}</div>
        </ScrollContainer>
      </>
    );
  };

  return (
    <>
      {renderSelectedItems()}

      {isModalVisible && (
        <CustomModal
          isVisible={isModalVisible}
          handleVisibility={handCloseModalVisility}
          title={
            showCreateMedicationForm ? 'Enregistrer un nouveau médicament' : listTitle
          }
          afterCloseModal={() => setSearchFilter('')}
          footer={[
            <StyledSpace
              key='modal_actions'
              direction='vertical'
              style={{ width: '100%' }}>
              <StyledButton
                key='validate_biological_exam'
                type='primary'
                onClick={handleValidateItems}
                size='middle'
                loading={isLoading}
                disabled={isLoading || _.isEmpty(checkedItems)}
                block>
                Valider et Fermer
              </StyledButton>
            </StyledSpace>,
          ]}>
          {renderMedicationSelection()}
        </CustomModal>
      )}
    </>
  );
};

export default AddRemoveBiologicalExam;
