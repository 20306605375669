import React, { useState } from 'react';
import { IBiologicalExamItem, ReferentialViewProps } from '../../../types';
import BiologicalExamForm from './BiologicalExamForm';
import BiologicalExamsList from './BiologicalExamList';

const BiologicalExamsRef: React.FC = () => {
  const [activeView, setActiveView] = useState<ReferentialViewProps>('LIST_ITEMS');
  const [selectedCategory, setSelectedCategory] = useState<IBiologicalExamItem>();

  const handleNavigateToView = (newView: ReferentialViewProps) => {
    setActiveView(newView);
  };

  const handleUpdateSelectedCategorie = (biologicalExam?: IBiologicalExamItem) => {
    setSelectedCategory(biologicalExam);
  };

  const renderContent = () => {
    switch (activeView) {
      case 'LIST_ITEMS':
        return (
          <BiologicalExamsList
            navigateToview={handleNavigateToView}
            updateCurrentItem={handleUpdateSelectedCategorie}
          />
        );
      case 'EDIT_ITEM':
        return (
          <BiologicalExamForm
            navigateToview={handleNavigateToView}
            currentData={selectedCategory}
          />
        );
      case 'NEW_ITEM':
        return <BiologicalExamForm navigateToview={handleNavigateToView} />;
    }
  };

  return <>{renderContent()}</>;
};

export default BiologicalExamsRef;
