import {
  StyledConstanteItemContainer,
  StyledConstanteItemTitle,
  StyledConstanteItemValue,
} from './style';

interface ConstantItemProps {
  title: string;
  value: string | number;
}
const ConstanteItem: React.FC<ConstantItemProps> = ({ title, value }) => {
  return (
    <StyledConstanteItemContainer>
      <StyledConstanteItemValue>{value}</StyledConstanteItemValue>
      <StyledConstanteItemTitle>{title}</StyledConstanteItemTitle>
    </StyledConstanteItemContainer>
  );
};

export default ConstanteItem;
