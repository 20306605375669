/* eslint-disable react/no-unescaped-entities */
import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import chartColors from '../../styles/color';
import {
  IBiologicalPrescriptionItem,
  IImageryPrescriptionItem,
  IPrescriptionItem,
  ISite,
  instanceOfIBiologicalPrescriptionItem,
  instanceOfImageryPrescriptionItem,
} from '../../types';
import _ from 'lodash';

interface PrescriptionDocProps {
  data: IPrescriptionItem | IBiologicalPrescriptionItem | IImageryPrescriptionItem;
  title?: string;
  siteInfos: ISite;
}

const PrescriptionDoc: React.FC<PrescriptionDocProps> = ({ data, siteInfos }) => {
  const renderPrescriptionItems = (data: IPrescriptionItem) => {
    return (
      <>
        {data.prescriptionLineDto.map(medItem => (
          <View key={medItem.idMedication} wrap={false} style={styles.medicationItem}>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={[styles.bold]}> {medItem.medicationDesignation} </Text>
              <Text> x{medItem.quantity} </Text>
            </View>
            {!_.isEmpty(medItem.posology) && <Text> {medItem.posology} </Text>}
            {!_.isEmpty(medItem.treatmentDuration) && (
              <Text> {medItem.treatmentDuration} Jour(s) </Text>
            )}
          </View>
        ))}
      </>
    );
  };

  const renderBiologicalPrescriptionItems = (data: IBiologicalPrescriptionItem) => {
    return (
      <>
        {data.prescriptionLineBiologicalDto.map(item => (
          <View key={item.idBiologicalExam} wrap={false} style={styles.medicationItem}>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={[styles.bold]}> {item.biologicalExamDesignation} </Text>
              <Text> x{item.quantity} </Text>
            </View>
          </View>
        ))}
      </>
    );
  };

  const renderImageryPrescriptionItems = (data: IImageryPrescriptionItem) => {
    return (
      <>
        {data.prescriptionLineImageryDto.map(item => (
          <View key={item.idImageryExam} wrap={false} style={styles.medicationItem}>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={[styles.bold]}> {item.imageryExamDesignation} </Text>
              <Text> x{item.quantity} </Text>
            </View>
          </View>
        ))}
      </>
    );
  };

  return (
    <Document>
      <Page style={styles.body}>
        <View
          wrap={false}
          style={{ marginBottom: 10, border: `1px solid ${chartColors.black}` }}>
          <Text
            style={{
              textAlign: 'center',
              fontSize: 40,
              fontWeight: 600,
              textTransform: 'capitalize',
              border: `1px solid ${chartColors.black}`,
              backgroundColor: chartColors.lightGray,
            }}>
            Bulletin
          </Text>
        </View>
        {/* Header */}
        <View wrap={false} style={styles.header}>
          <View wrap={false}>
            <Image
              src={{ uri: siteInfos.url as string, method: 'GET', headers: {}, body: '' }}
              style={{ width: 100 }}
            />
            <View wrap={false}>
              <Text style={[styles.bold, { color: chartColors.blue }]}>
                {siteInfos.name}
              </Text>
              <Text>{siteInfos.address}</Text>
            </View>
          </View>
          <View wrap={false} style={styles.headerDateContainer}>
            <Text>Date: {data.createdDate}</Text>
            <Text style={[styles.bold]}>{data.numMedicalRecord}</Text>
          </View>
        </View>

        {/* Infos Entreprise et Client Section */}
        <View wrap={false} style={styles.userHeader}>
          <View wrap={false}>
            <Text style={[styles.bold, { color: chartColors.blue }]}>
              {`${data.patientDto.name} ${data.patientDto.firstname}`}
            </Text>
            <Text>{data.patientDto.placeOfResidence}</Text>
            <Text>{data.patientDto.phoneNumber}</Text>
          </View>
        </View>

        {/* Medecin Section */}
        <View style={[styles.objetContainer, { marginBottom: 10 }]}>
          <Text style={[styles.bold, { fontSize: 12 }]}>Médecin : </Text>
          <Text
            style={{
              fontSize: 12,
            }}>
            {`${data.utilisateurDto.level} ${data.utilisateurDto.firstname} ${data.utilisateurDto.name}`}
          </Text>
        </View>

        {/* Liste des Médicaments */}

        <View wrap={false} style={styles.medicationContainer}>
          {instanceOfIBiologicalPrescriptionItem(data)
            ? renderBiologicalPrescriptionItems(data)
            : instanceOfImageryPrescriptionItem(data)
            ? renderImageryPrescriptionItems(data)
            : renderPrescriptionItems(data)}
        </View>

        {/* <Text style={styles.pageFooter} fixed>
          Clinique Demo - ApiPatient, Tous droits réservés
        </Text> */}
      </Page>
    </Document>
  );
};

Font.register({
  family: 'Metropolis',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
  fonts: [
    { src: require('../../assets/fonts/Metropolis-Regular.woff') },
    { src: require('../../assets/fonts/Metropolis-Bold.woff'), fontWeight: 600 },
    { src: require('../../assets/fonts/Metropolis-Light.woff'), fontWeight: 400 },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 12,
    fontFamily: 'Metropolis',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 50,
  },
  headerDateContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: chartColors.blue50,
    padding: 10,
    height: 42,
  },
  userHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  objetContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #000000',
  },
  bold: {
    fontWeight: 600,
  },
  columnTitle: {
    fontSize: 12,
    fontWeight: 600,
  },
  columnContent: {
    border: 'none',
    fontSize: 12,
    borderColor: chartColors.blue50,
  },
  totalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 15,
  },
  noteContainer: {
    marginTop: 15,
    fontSize: 10,
    textAlign: 'justify',
  },
  pageFooter: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  duplicata: {
    position: 'absolute',
    zIndex: -100,
    textAlign: 'center',
    left: 5,
    right: 5,
    top: 300,
    fontSize: 98,
    fontWeight: 700,
    textTransform: 'capitalize',
    transform: 'rotate(-45deg)',
    opacity: 0.2,
  },
  medicationContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  medicationItem: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 5,
  },
});

export default PrescriptionDoc;
