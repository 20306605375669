import { CreateRatioRequestBody, GetRatiosResponse } from '../types';
import { axiosInstance } from './axiosInstance';

export const getRatiosApi = (): Promise<GetRatiosResponse> => {
  return axiosInstance
    .get('/ratios')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des coefficients. Raison: ${message}`);
    });
};

export const createRatioApi = (data: CreateRatioRequestBody): Promise<string> => {
  return axiosInstance
    .post('/ratio', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création du coefficient. Raison: ${message}`);
    });
};

export const updateRatioApi = (
  idRatio: number,
  data: CreateRatioRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/ratio?id=${idRatio}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la mise à jour du coefficient. Raison: ${message}`);
    });
};

export const deleteRatioApi = (idRoom: number): Promise<string> => {
  return axiosInstance
    .delete(`/ratio?id=${idRoom}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la suppression du ration. Raison: ${message}`);
    });
};
