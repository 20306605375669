import { useState } from 'react';
import { ISite, ReferentialViewProps } from '../../../types';
import SiteForm from './SiteForm';
import SiteInfos from './SiteInfos';

const SiteSetting = () => {
  const [activeView, setActiveView] = useState<ReferentialViewProps>('LIST_ITEMS');
  const [selectedSite, setSelectedRole] = useState<ISite>();

  const handleNavigateToView = (newView: ReferentialViewProps) => {
    setActiveView(newView);
  };

  const handleUpdateSelectedRole = (site?: ISite) => {
    setSelectedRole(site);
  };

  const renderContent = () => {
    switch (activeView) {
      case 'LIST_ITEMS':
        return (
          <SiteInfos
            navigateToview={handleNavigateToView}
            updateCurrentItem={handleUpdateSelectedRole}
          />
        );
      case 'EDIT_ITEM':
        return (
          <SiteForm navigateToview={handleNavigateToView} currentData={selectedSite} />
        );
      case 'NEW_ITEM':
        return <SiteForm navigateToview={handleNavigateToView} />;
    }
  };

  return <>{renderContent()}</>;
};

export default SiteSetting;
