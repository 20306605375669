import _ from 'lodash';
import { CreatePharmacyOutputState, CreatePharmacyOutputAction } from './types';

export const getCreatePharmacyOutputDefaultInitialState =
  (): CreatePharmacyOutputState => {
    return {
      articles: [],
      invalidInputs: [],
    } as CreatePharmacyOutputState;
  };

export const CreatePharmacyOutputReducer = (
  state: CreatePharmacyOutputState,
  action: CreatePharmacyOutputAction,
) => {
  const newState = { ...state };
  switch (action.type) {
    case 'setOutputArticles':
      newState.articles = _.concat(state.articles, action.payload);
      return newState;

    case 'removeOutputArticle':
      newState.articles = state.articles.filter(
        item => item.idArticle !== action.payload,
      );
      return newState;

    case 'setQuantityToRemove': {
      const articleId = action.payload.id;
      const articleValue = action.payload.value;

      return {
        ...state,
        articles: state.articles.map(r =>
          r.idArticle === articleId ? { ...r, quantityRemoved: articleValue } : r,
        ),
      };
    }

    case 'resetOutputArticles':
      newState.articles = [];
      return newState;

    default:
      return state;
  }
};
