/* eslint-disable @typescript-eslint/no-explicit-any */
import DocViewer, {
  IHeaderOverride,
  JPGRenderer,
  PDFRenderer,
  PNGRenderer,
} from '@cyntler/react-doc-viewer';
import { Drawer, Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import chartColors from '../../styles/color';
import { StyledSpace } from '../style';
import { ImageryResultsPrescriptionLineResponse } from '../../types';
import { StyledButton } from '../button/style';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

interface ImageryResultDrawerProps {
  isDrawerOpen: boolean;
  drawerTitle: string;
  extraActions?: React.ReactNode;
  files: ImageryResultsPrescriptionLineResponse;
  closeDrawer: () => void;
}

interface IDocument {
  uri: string;
  fileType?: string;
  fileData?: string | ArrayBuffer;
  fileName?: string;
}

const ImageryResultDrawer: React.FC<ImageryResultDrawerProps> = ({
  isDrawerOpen,
  drawerTitle,
  extraActions,
  closeDrawer,
  files,
}) => {
  const [docs, setDocs] = useState<IDocument[]>([]);
  const [viewerKey, setViewerKey] = useState(0);

  const mapFilesToDocs = () => {
    const tempDocs: IDocument[] = files.map(file => ({
      uri: file.url,
      fileType: file.type,
    }));
    setDocs(tempDocs);
  };

  useEffect(() => {
    setViewerKey(prevKey => prevKey + 1);
    mapFilesToDocs();
  }, [files]);

  const ViewerHeader: IHeaderOverride = (state, previousDocument, nextDocument) => {
    if (!state.currentDocument || state.config?.header?.disableFileName) {
      return null;
    }

    return (
      <div
        style={{
          position: 'absolute',
          left: '0px',
          right: '0px',
          bottom: '20px',
        }}>
        <StyledSpace
          style={{
            display: 'flex',
            backgroundColor: chartColors.white,
            flexDirection: 'row',
            justifyContent: 'center',
            width: 'max-content',
            padding: 20,
            margin: '0px auto',
            borderRadius: 60,
            boxShadow: `0px 0px 10px ${chartColors.black}30`,
          }}>
          <StyledButton
            type='default'
            onClick={previousDocument}
            disabled={state.currentFileNo === 0}
            icon={<FaArrowLeft />}>
            Précedent
          </StyledButton>
          <div>
            {state.currentFileNo + 1} sur {state.documents.length}{' '}
          </div>
          <StyledButton
            type='primary'
            onClick={nextDocument}
            disabled={state.currentFileNo >= state.documents.length - 1}
            icon={<FaArrowRight />}>
            Suivant
          </StyledButton>
        </StyledSpace>
      </div>
    );
  };

  return (
    <Drawer
      keyboard={false}
      closable={false}
      title={drawerTitle}
      destroyOnClose
      onClose={closeDrawer}
      extra={[<StyledSpace key='actions'>{extraActions}</StyledSpace>]}
      styles={{
        header: {
          backgroundColor: chartColors.blue50,
        },
        body: { overflow: 'hidden', padding: 0 },
      }}
      open={isDrawerOpen}
      placement='right'
      size='large'
      width={'100vw'}>
      <DocViewer
        key={viewerKey}
        documents={docs}
        initialActiveDocument={docs[0]}
        pluginRenderers={[PNGRenderer, PDFRenderer, JPGRenderer]}
        config={{
          pdfVerticalScrollByDefault: true,
          header: { overrideComponent: ViewerHeader },
          noRenderer: {
            overrideComponent: () => {
              return (
                <Empty description='Aucun rendu disponible pour ce type de fichier' />
              );
            },
          },
        }}
      />
    </Drawer>
  );
};

export default ImageryResultDrawer;
