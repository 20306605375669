import { CreateSubscriberRequestBody, GetSubscribersResponse } from '../types';
import { apiRequest } from './axiosInstance';

export const getSubscribersApi = (): Promise<GetSubscribersResponse> => {
  return apiRequest<void, GetSubscribersResponse>({
    method: 'get',
    url: '/subscribers',
  });
};

export const createSubscriberApi = (
  data: CreateSubscriberRequestBody,
): Promise<string> => {
  return apiRequest<CreateSubscriberRequestBody, string>({
    method: 'post',
    url: '/subscriber',
    data,
  });
};

export const updateSubscriberApi = (
  idSubscriber: number,
  data: CreateSubscriberRequestBody,
): Promise<string> => {
  return apiRequest<CreateSubscriberRequestBody, string>({
    method: 'put',
    url: `/subscriber?id=${idSubscriber}`,
    data,
  });
};

export const deleteSubscriberApi = (idSubscriber: number): Promise<string> => {
  return apiRequest<void, string>({
    method: 'delete',
    url: `/subscriber?id=${idSubscriber}`,
  });
};
