import { App as AppContainer, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { BsExclamationTriangleFill, BsPlus } from 'react-icons/bs';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import Loader from '../../../components/loader';
import { StyledDivider, StyledSpace } from '../../../components/style';
import chartColors from '../../../styles/color';
import {
  GetDiagnosticsResponse,
  IDiagnosticItem,
  ReferentialViewProps,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import {
  ErrorDeleteMsg,
  ErrorMsgTitle,
  SuccesssMsgTitle,
} from '../../../utils/constants';
import { deleteDiagnosticApi, getDiagnosticsApi } from '../../../api/referentialService';

type DiagnosticsListProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  updateCurrentItem: (p: IDiagnosticItem) => void;
};

const DiagnosticsList: React.FC<DiagnosticsListProps> = ({
  navigateToview,
  updateCurrentItem,
}) => {
  const { notification, modal } = AppContainer.useApp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [diagnosticsData, setDiagnosticsData] = useState<GetDiagnosticsResponse>([]);

  const loadDiagnostics = async () => {
    setIsLoading(true);
    Promise.resolve(getDiagnosticsApi())
      .then(result => {
        setDiagnosticsData(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadDiagnostics();
  }, []);

  const handleUpdate = (diagnostic: IDiagnosticItem) => {
    updateCurrentItem(diagnostic);
    navigateToview('EDIT_ITEM');
  };

  const deleteDiagnostic = async (diagnostic: IDiagnosticItem) => {
    try {
      setIsLoading(true);
      Promise.resolve(deleteDiagnosticApi(diagnostic.id as number)).then(() => {
        loadDiagnostics();
        notification.success({
          message: SuccesssMsgTitle,
          description: `Le diagnostic a été supprimé`,
        });
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(ErrorDeleteMsg),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveDiagnostic = (diagnostic: IDiagnosticItem) => {
    modal.confirm({
      title: 'Confirmation de suppression',
      icon: <BsExclamationTriangleFill size={40} color={chartColors.warning} />,
      content: `Êtes-vous sûr de vouloir supprimer le diagnostic ${diagnostic.label} de manière permanente ?`,
      okText: 'Oui, je Confirme',
      cancelText: 'Annuler',
      onOk: () => deleteDiagnostic(diagnostic),
    });
  };

  const columnData: ColumnsType<IDiagnosticItem> = [
    {
      title: 'Code',
      dataIndex: 'code',
      width: 150,
    },
    {
      title: 'Désignation',
      dataIndex: 'label',
    },
    {
      title: 'Actions',
      align: 'left',
      render: (_value, record) => {
        return (
          <StyledSpace>
            <StyledButton
              shape='circle'
              size='middle'
              icon={<FiEdit />}
              onClick={() => handleUpdate(record)}
            />
            <StyledButton
              shape='circle'
              size='middle'
              danger
              icon={<FiTrash2 />}
              onClick={() => handleRemoveDiagnostic(record)}
            />
          </StyledSpace>
        );
      },
    },
  ];

  const renderHeader = () => {
    return (
      <SectionHeader title='Diagnostic' count={diagnosticsData.length}>
        <StyledButton
          icon={<BsPlus />}
          type='primary'
          onClick={() => navigateToview('NEW_ITEM')}>
          Ajouter un diagnostic
        </StyledButton>
      </SectionHeader>
    );
  };

  const renderContent = () => {
    return (
      <>
        <StyledDivider />
        <Table
          rowKey='id'
          bordered={false}
          columns={columnData}
          dataSource={diagnosticsData}
          size='small'
          pagination={false}
        />
      </>
    );
  };

  return (
    <>
      {renderHeader()}
      <Loader showSpinner={isLoading}>{renderContent()}</Loader>
    </>
  );
};

export default DiagnosticsList;
