import chartColors from '../../styles/color';
import {
  StyledResponsiveTableHeader,
  StyledResponsiveTableRowValue,
  StyledResponsiveTableWrapper,
} from '../style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronRight, faPrint } from '@fortawesome/free-solid-svg-icons';

interface ResponsiveTableProps {
  headerNames: string[];
  data: string[][];
  onClick?: () => void;
}
const ResponsiveTable: React.FC<ResponsiveTableProps> = ({
  headerNames,
  onClick,
  data,
}) => {
  const renderHeaders = () => {
    return headerNames.map((item, index) => {
      return (
        <div key={index}>
          <StyledResponsiveTableHeader>{item}</StyledResponsiveTableHeader>
          {index === headerNames.length - 1 && onClick ? (
            <div
              style={{
                color: chartColors.blue,
                display: 'flex',
                flexDirection: 'row',
                gap: '0.5rem',
                alignItems: 'center',
              }}>
              <FontAwesomeIcon icon={faCircleChevronRight} />
              <span style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>Voir plus</span>
            </div>
          ) : null}
        </div>
      );
    });
  };

  const renderValues = (values: string[]) => {
    return values.map(item => {
      return (
        <div key={item}>
          <StyledResponsiveTableRowValue>
            {item === 'pdf' ? (
              <FontAwesomeIcon
                icon={faPrint}
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  alert('TODO: open PDF when backend is ready');
                }}
              />
            ) : (
              item
            )}
          </StyledResponsiveTableRowValue>
        </div>
      );
    });
  };

  const render = () => {
    return data.map((item, index) => {
      return (
        <StyledResponsiveTableWrapper key={index} onClick={onClick && onClick}>
          <div>{renderHeaders()}</div>
          <div>{renderValues(item)}</div>
        </StyledResponsiveTableWrapper>
      );
    });
  };
  return <>{render()}</>;
};

export default ResponsiveTable;
