import React, { useEffect, useReducer, useState } from 'react';
import { App as AppContainer, Table } from 'antd';
import Loader from '../../../components/loader';
import { StyledCol, StyledRow, StyledSpace } from '../../../components/style';
import { StyledMedicalRecordBlocContainer } from '../../style';
import {
  CreateMedicalRecordApiResponse,
  CreatePrescriptionHospitalizationRequestBody,
  HospitPrescpritionItem,
  SearchHospitalizationPrescriptionLine,
  UserDto,
} from '../../../types';
import SectionHeader from '../../../components/header/SectionHeader';
import {
  createHospitalizationPrescriptionApi,
  searchHospitalizationPrescriptionsApi,
} from '../../../api/hospitalizationService';
import { getDataFromStorage } from '../../../utils/storage';
import {
  ErrorMsgTitle,
  SuccesssMsgTitle,
  WarningMsgTitle,
} from '../../../utils/constants';
import { getApiErrorMsg, mapStateToRequestBody } from '../../../utils/commun';
import { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import _ from 'lodash';
import { StyledButton } from '../../../components/button/style';
import chartColors from '../../../styles/color';
import { BsNodePlus, BsPrinter } from 'react-icons/bs';
import {
  CreateHospitPrescriptionReducer,
  getCreateHospitPrescriptionDefaultInitialState,
} from '../../../reducers/prescription/createHospitPrescription';
import AddRemoveHospitExam from './hospitalization/AddRemoveHospitExam';

type DataProps = {
  hospitPrescriptions: Array<SearchHospitalizationPrescriptionLine>;
  selectedRowKeys: React.Key[];
  selectedPrescriptionsRow: Array<SearchHospitalizationPrescriptionLine>;
};

type LoadingProps = {
  hospitPrescription: boolean;
  createPrescription: boolean;
};

type VisibilityProps = {
  prescriptionList: boolean;
  prescriptionPdf: boolean;
  hospitExamsModal: boolean;
};

const HospitPrescriptionHistory = () => {
  const medicalRecord =
    getDataFromStorage<CreateMedicalRecordApiResponse>('medicalRecord');
  const user = getDataFromStorage<UserDto>('connectedUserData');
  const [state, reducer] = useReducer(
    CreateHospitPrescriptionReducer,
    getCreateHospitPrescriptionDefaultInitialState(),
  );

  const { notification } = AppContainer.useApp();

  const [isLoading, setIsLoading] = useState<LoadingProps>({
    hospitPrescription: false,
    createPrescription: false,
  });

  const [show, setShow] = useState<VisibilityProps>({
    prescriptionList: true,
    prescriptionPdf: false,
    hospitExamsModal: false,
  });

  const [data, setData] = useState<DataProps>({
    hospitPrescriptions: [],
    selectedRowKeys: [],
    selectedPrescriptionsRow: [],
  });

  const loadPrescriptions = async () => {
    setIsLoading(c => ({ ...c, hospitPrescription: true }));
    Promise.resolve(
      searchHospitalizationPrescriptionsApi({
        idMedicalRecord: medicalRecord.id,
        idPatient: medicalRecord.patientDto.id as number,
        paid: false,
      }),
    )
      .then(result => {
        setData(c => ({ ...c, hospitPrescriptions: result }));
        setIsLoading(c => ({ ...c, hospitPrescription: false }));
      })
      .catch(error => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      })
      .finally(() => {
        setIsLoading(c => ({ ...c, hospitPrescription: false }));
      });
  };

  const resetPrescription = () => {
    setShow(c => ({ ...c, prescriptionList: true }));
    reducer({ type: 'setResetHospitPrescrition' });
    loadPrescriptions();
  };

  const createPrescription = async () => {
    setIsLoading(c => ({ ...c, createPrescription: true }));
    try {
      const allPropertiesFilled = state.prescriptionLineHospitalization.every(
        (hospitLine: HospitPrescpritionItem) => {
          return hospitLine.comments.trim() !== '' && hospitLine.quantity !== 0;
        },
      );

      if (!allPropertiesFilled) {
        setIsLoading(c => ({ ...c, createPrescription: false }));
        notification.warning({
          message: WarningMsgTitle,
          description: 'Les quantités et commentaires sont obligatoires',
        });
      } else {
        createHospitalizationPrescriptionApi(
          mapStateToRequestBody<CreatePrescriptionHospitalizationRequestBody>({
            idMedecin: user.id,
            idMedicalRecord: medicalRecord.id,
            idPatient: medicalRecord.patientDto.id as number,
            prescriptionLineHospitalization: state.prescriptionLineHospitalization,
          }),
        ).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: 'La demande a bien été créée.',
          });
          loadPrescriptions();
          resetPrescription();
          setIsLoading(c => ({ ...c, createPrescription: false }));
        });
      }
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
      setIsLoading(c => ({ ...c, createPrescription: false }));
    }
  };

  useEffect(() => {
    loadPrescriptions();
  }, []);

  const columnData: ColumnsType<SearchHospitalizationPrescriptionLine> = [
    {
      title: 'Numéro',
      dataIndex: 'id',
    },
    {
      title: 'Date',
      dataIndex: 'createdDate',
    },
    {
      title: "Nbr. d'hospitalisation",
      render: (value, record) => (
        <>{record.prescriptionLineHospitalizationDto.length} hospitalisation(s)</>
      ),
    },
    // {
    //   align: 'left',
    //   render: () => {
    //     return (
    //       <StyledSpace>
    //         <StyledButton shape='circle' size='middle' icon={<FiPrinter />} />
    //         <StyledButton shape='circle' size='middle' icon={<FiEdit2 />} />
    //       </StyledSpace>
    //     );
    //   },
    // },
  ];

  const onSelectPrescriptionChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: Array<SearchHospitalizationPrescriptionLine>,
  ) => {
    setData(c => ({
      ...c,
      selectedRowKeys: newSelectedRowKeys,
      selectedPrescriptionsRow: selectedRows,
    }));
  };

  const prescriptionRowSelection: TableRowSelection<SearchHospitalizationPrescriptionLine> =
    {
      selectedRowKeys: data.selectedRowKeys,
      getCheckboxProps: (record: SearchHospitalizationPrescriptionLine) => ({
        disabled:
          data.selectedRowKeys.length === 1 && !data.selectedRowKeys.includes(record.id),
        // (record.treated === true && record.idInvoiceRef > 0) ||
        // name: record.invoicePlanHeadLabel,
      }),
      onChange: onSelectPrescriptionChange,
      selections: [Table.SELECTION_NONE],
      hideSelectAll: true,
    };

  const renderPrescriptionsListActions = () => {
    return (
      <StyledSpace>
        {_.isEmpty(data.selectedRowKeys) ? (
          <StyledButton
            block
            type='primary'
            loading={isLoading.hospitPrescription}
            disabled={isLoading.hospitPrescription}
            onClick={() => setShow(c => ({ ...c, prescriptionList: false }))}>
            Créer une demande
          </StyledButton>
        ) : (
          <StyledButton
            type='primary'
            style={{ backgroundColor: chartColors.success }}
            loading={isLoading.hospitPrescription}
            disabled={isLoading.hospitPrescription}
            icon={<BsPrinter />}
            onClick={() => setShow(c => ({ ...c, prescriptionPdf: false }))}>
            Voir la demande
          </StyledButton>
        )}
      </StyledSpace>
    );
  };

  const renderPrescriptionsList = () => {
    return (
      <StyledMedicalRecordBlocContainer
        style={{
          backgroundColor: '#FFF',
          padding: '30px 20px',
        }}>
        <SectionHeader title="Demandes d'hospitalisation">
          {renderPrescriptionsListActions()}
        </SectionHeader>

        <Table<SearchHospitalizationPrescriptionLine>
          rowKey='id'
          dataSource={data.hospitPrescriptions}
          size='small'
          columns={columnData}
          pagination={false}
          bordered={false}
          rowSelection={prescriptionRowSelection}
        />
      </StyledMedicalRecordBlocContainer>
    );
  };

  const renderValidationComponent = () => {
    return (
      <>
        {!_.isEmpty(state.prescriptionLineHospitalization) && (
          <StyledButton
            type='primary'
            loading={isLoading.createPrescription}
            disabled={isLoading.createPrescription}
            onClick={() => createPrescription()}>
            Créer la demande
          </StyledButton>
        )}
        <StyledButton
          danger
          type='text'
          disabled={isLoading.createPrescription}
          onClick={resetPrescription}>
          Annuler la prescription
        </StyledButton>
      </>
    );
  };

  const handleModalHospitExam = () => {
    setShow(c => ({ ...c, hospitExamsModal: !c.hospitExamsModal }));
  };

  const renderModalHospitActions = () => {
    return (
      <StyledSpace>
        <StyledButton
          loading={isLoading.createPrescription}
          disabled={isLoading.createPrescription}
          size='middle'
          icon={<BsNodePlus />}
          block
          type='dashed'
          onClick={handleModalHospitExam}>
          Sélectionnez un examen
        </StyledButton>
        {renderValidationComponent()}
      </StyledSpace>
    );
  };

  const renderSelectedMedications = () => {
    return (
      <>
        <SectionHeader title="Nouvelle demande d'hospitalisation">
          {renderModalHospitActions()}
        </SectionHeader>
        <StyledRow gutter={[20, 20]}>
          <StyledCol span={24}>
            <AddRemoveHospitExam
              key={'hospitalisation'}
              listTitle='Sélectionnez des examens'
              reducer={reducer}
              state={state}
              loading={false}
              userData={user}
              isModalVisible={show.hospitExamsModal}
              handleCloseModalVisibility={handleModalHospitExam}
            />
          </StyledCol>
        </StyledRow>
      </>
    );
  };

  const renderContent = () => {
    return (
      <StyledRow justify='end' gutter={[16, 16]}>
        <StyledCol span={24}>
          <StyledMedicalRecordBlocContainer style={{ padding: '10px 10px' }}>
            {show.prescriptionList
              ? renderPrescriptionsList()
              : renderSelectedMedications()}
          </StyledMedicalRecordBlocContainer>
        </StyledCol>
      </StyledRow>
    );
  };

  return <Loader showSpinner={isLoading.hospitPrescription}>{renderContent()}</Loader>;
};

export default HospitPrescriptionHistory;
