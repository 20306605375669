import React, { useState } from 'react';
import { IArticleItem, ReferentialViewProps } from '../../../types';
import ArticleForm from './ArticleForm';
import ArticlesList from './ArticlesList';

const ArticlesRef: React.FC = () => {
  const [activeView, setActiveView] = useState<ReferentialViewProps>('LIST_ITEMS');
  const [selectedItem, setSelectedItem] = useState<IArticleItem>();

  const handleNavigateToView = (newView: ReferentialViewProps) => {
    setActiveView(newView);
  };

  const handleUpdateSelectedItem = (item?: IArticleItem) => {
    setSelectedItem(item);
  };

  const renderContent = () => {
    switch (activeView) {
      case 'LIST_ITEMS':
        return (
          <ArticlesList
            navigateToview={handleNavigateToView}
            updateCurrentItem={handleUpdateSelectedItem}
          />
        );
      case 'EDIT_ITEM':
        return (
          <ArticleForm navigateToview={handleNavigateToView} currentData={selectedItem} />
        );
      case 'NEW_ITEM':
        return <ArticleForm navigateToview={handleNavigateToView} />;
    }
  };

  return <>{renderContent()}</>;
};

export default ArticlesRef;
