import { Empty, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import _ from 'lodash';
import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { StyledCardItem, StyledPopover, StyledSpace } from '../../components/style';
import chartColors from '../../styles/color';
import {
  BiologicalPrescriptionLineDto,
  SearchBiologicalPrescriptionLinesResponse,
} from '../../types';
import { thousandSeparatorFormat } from '../../utils/commun';

type BiologicalPrescritionsCardProps = {
  prescriptionLines: SearchBiologicalPrescriptionLinesResponse;
  selectedRowKeys: React.Key[];
  handleRowChange: (
    keys: React.Key[],
    rows: SearchBiologicalPrescriptionLinesResponse,
  ) => void;
  isLoading: boolean;
};

const BiologicalPrescritionsCard: React.FC<BiologicalPrescritionsCardProps> = ({
  prescriptionLines,
  selectedRowKeys,
  handleRowChange,
  isLoading,
}) => {
  const rowSelection: TableRowSelection<BiologicalPrescriptionLineDto> = {
    selectedRowKeys,
    onChange: handleRowChange,
    hideSelectAll: false,
  };

  const biologicalExamscolumn: ColumnsType<BiologicalPrescriptionLineDto> = [
    {
      title: 'Examen',
      dataIndex: 'biologicalExamDesignation',
      render: (value, record) => (
        <StyledPopover
          content={
            <>
              <div>Norme: {record.norm}</div>
              <div>Unité: {record.usualUnit}</div>
            </>
          }>
          <StyledSpace>
            {value}
            <FaInfoCircle />
          </StyledSpace>
        </StyledPopover>
      ),
    },
    {
      title: 'PU.',
      dataIndex: 'unitPrice',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
    },
    {
      title: 'Ratio',
      dataIndex: 'ratioLabel',
      align: 'right',
    },
    {
      title: 'Total',
      dataIndex: 'amountTotal',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
    },
    {
      title: 'Part Assu.',
      dataIndex: 'amountAssu',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
    },
    {
      title: 'Part Patient',
      dataIndex: 'amountPatient',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
    },
    // {
    //   title: 'Effectué le',
    //   dataIndex: 'doneDate',
    //   align: 'right',
    //   render: () => (
    //     <FormFieldLabel libelle={`Effectué le ${dayjsInstance().format(DATE_FORMAT)}`} />
    //   ),
    // },
    // {
    //   title: 'Actions',
    //   align: 'left',
    //   render: (_value, record) => {
    //     return (
    //       <StyledSpace>
    //         <StyledButton
    //           icon={<FiTrash2 />}
    //           type='primary'
    //           danger
    //           shape='circle'
    //           size='middle'
    //           onClick={() => record}
    //           loading={isLoading}
    //           disabled={isLoading}
    //         />
    //       </StyledSpace>
    //     );
    //   },
    // },
  ];

  const renderContent = () => {
    return _.isEmpty(prescriptionLines) ? (
      <Empty description='Aucun examen biologique effectué' />
    ) : (
      <Table
        rowKey='id'
        size='small'
        bordered={false}
        pagination={false}
        columns={biologicalExamscolumn}
        dataSource={prescriptionLines}
        rowSelection={rowSelection}
        loading={isLoading}
      />
    );
  };

  // <StyledButton
  //   danger
  //   size='small'
  //   disabled={isLoading}
  //   icon={<FiTrash2 />}
  //   onClick={handleRemove}>
  //   Retirer
  // </StyledButton>;

  return (
    <StyledCardItem
      styles={{
        header: {
          fontFamily: 'Metropolis',
          textTransform: 'uppercase',
          backgroundColor: chartColors.lightGray,
        },
        body: { padding: 0 },
      }}
      title={<StyledSpace>Examens biologiques</StyledSpace>}
      size='small'
      extra={[]}>
      {renderContent()}
    </StyledCardItem>
  );
};

export default BiologicalPrescritionsCard;
