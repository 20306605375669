/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { App as AppContainer, Form } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  createBiologicalExamApi,
  updateBiologicalExamApi,
} from '../../../api/biologicalExamService';
import { getRatiosApi } from '../../../api/ratioService';
import {
  getBiologicalAnalysisCategoriesApi,
  getBiologicalAnalysisSubCategoriesByCategory,
} from '../../../api/referentialService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import {
  StyledCol,
  StyledDivider,
  StyledInput,
  StyledRow,
  StyledSelectOption,
  StyledSelectPicker,
  StyledSpace,
} from '../../../components/style';
import {
  CreateBiologicalExamRequestBody,
  GetBiologicalAnalysisCategoriesResponse,
  GetBiologicalAnalysisSubCategoriesResponse,
  GetRatiosResponse,
  IBiologicalExamItem,
  ReferentialViewProps,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { StyledBlocContainer } from '../../style';

type BiologicalExamFormProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  currentData?: IBiologicalExamItem;
};

type FormPropertiesProps = {
  idBiologicalAnalysisCategory: number;
  idBiologicalAnalysisSubCategory: number;
  designation: string;
  ratioId: 0;
  norm: string;
  usual_unit: string;
};

const BiologicalExamForm: React.FC<BiologicalExamFormProps> = ({
  navigateToview,
  currentData,
}) => {
  const { notification } = AppContainer.useApp();
  const [form] = Form.useForm<FormPropertiesProps>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSubCategories, setIsLoadingSubCategories] = useState<boolean>(false);

  const [biologicalExamCategories, setBiologicalExamCategories] =
    useState<GetBiologicalAnalysisCategoriesResponse>([]);
  const [biologicalExamSubCategories, setBiologicalExamSubCategories] =
    useState<GetBiologicalAnalysisSubCategoriesResponse>([]);
  const [ratios, setRatios] = useState<GetRatiosResponse>([]);

  const loadData = async () => {
    setIsLoading(true);
    Promise.all([getBiologicalAnalysisCategoriesApi(), getRatiosApi()])
      .then(result => {
        setBiologicalExamCategories(result[0]);
        setRatios(result[1]);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadSubCategoriesByCatId = async (categoryId: number) => {
    form.setFieldValue('idBiologicalAnalysisSubCategory', null);
    setIsLoadingSubCategories(true);
    Promise.resolve(getBiologicalAnalysisSubCategoriesByCategory(categoryId))
      .then(result => {
        setBiologicalExamSubCategories(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoadingSubCategories(false);
      });
  };

  useEffect(() => {
    if (!_.isUndefined(currentData)) {
      const idCat =
        currentData.biologicalAnalysisSubCategorieDto.biologicalAnalysisCategoryDto.id;

      loadSubCategoriesByCatId(idCat as number).then(() => {
        form.setFieldValue(
          'idBiologicalAnalysisSubCategory',
          currentData.biologicalAnalysisSubCategorieDto.id,
        );
      });
    }
    loadData();
  }, []);

  const mapPropsToRequest = (row: FormPropertiesProps) => {
    const itemData: CreateBiologicalExamRequestBody = {
      designation: row.designation,
      norm: row.norm,
      usual_unit: row.usual_unit,
      idBiologicalAnalysisCategory: row.idBiologicalAnalysisCategory,
      ratioId: row.ratioId,
      idBiologicalAnalysisSubCategory: _.isNull(row.idBiologicalAnalysisSubCategory)
        ? 0
        : row.idBiologicalAnalysisSubCategory,
    };

    return itemData;
  };

  const createBiologicalExamCategory = async () => {
    try {
      await form.validateFields().then(async row => {
        const biologicalExamData: CreateBiologicalExamRequestBody =
          mapPropsToRequest(row);

        setIsLoading(true);
        Promise.resolve(createBiologicalExamApi(biologicalExamData)).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `${row.designation} a été enregistrée!`,
          });
          navigateToview('LIST_ITEMS');
        });
      });
    } catch (error: any) {
      if (_.isUndefined(error.values)) {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const editBiologicalExamCategory = async () => {
    try {
      await form.validateFields().then(async row => {
        const biologicalExamData: CreateBiologicalExamRequestBody =
          mapPropsToRequest(row);

        setIsLoading(true);
        Promise.resolve(
          updateBiologicalExamApi(currentData?.id as number, biologicalExamData),
        ).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `L'examen biologique a été modifié!`,
          });
          navigateToview('LIST_ITEMS');
        });
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderHeader = () => {
    return (
      <SectionHeader
        title={
          _.isUndefined(currentData)
            ? 'Nouvel examen biologique'
            : "Modification d'examen biologique"
        }
        onBack={() => navigateToview('LIST_ITEMS')}
      />
    );
  };

  const renderContent = () => {
    return (
      <StyledBlocContainer style={{ padding: 20 }}>
        <Form
          disabled={isLoading}
          form={form}
          component={false}
          initialValues={{
            designation: _.isUndefined(currentData) ? '' : currentData.designation,
            norm: _.isUndefined(currentData) ? '' : currentData.norm,
            ratioId: _.isUndefined(currentData) ? null : currentData.ratioId,
            usual_unit: _.isUndefined(currentData) ? '' : currentData.usual_unit,
            idBiologicalAnalysisCategory: _.isUndefined(currentData)
              ? null
              : currentData.biologicalAnalysisSubCategorieDto
                  .biologicalAnalysisCategoryDto.id,
            idBiologicalAnalysisSubCategory: _.isUndefined(currentData)
              ? null
              : currentData.biologicalAnalysisSubCategorieDto.id,
          }}>
          <StyledRow gutter={[16, 16]} align='middle' justify='center'>
            <StyledCol span={24}>
              <FormFieldLabel libelle="Désignation de l'examen" isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='designation'
                rules={[
                  { required: true, message: 'Désignation obligatoire' },
                  { whitespace: false },
                ]}>
                <StyledInput size='large' placeholder='Désignation examen biologique' />
              </Form.Item>
            </StyledCol>

            <StyledCol span={12}>
              <FormFieldLabel libelle="Norme de l'examen" />
              <Form.Item style={{ margin: 0 }} name='norm'>
                <StyledInput size='large' placeholder='Norme examen biologique' />
              </Form.Item>
            </StyledCol>

            <StyledCol span={12}>
              <FormFieldLabel libelle='Unité usuelle' />
              <Form.Item style={{ margin: 0 }} name='usual_unit'>
                <StyledInput size='large' placeholder='Unité usuelle examen biologique' />
              </Form.Item>
            </StyledCol>

            <StyledCol span={24}>
              <FormFieldLabel libelle='Coefficient' />
              <Form.Item
                style={{ margin: 0 }}
                name='ratioId'
                rules={[{ min: 1, type: 'number' }]}>
                <StyledSelectPicker
                  loading={isLoading}
                  placeholder='Coefficient'
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }>
                  {_.map(ratios, item => (
                    <StyledSelectOption key={item.id} value={item.id} label={item.ratio}>
                      {item.code}
                    </StyledSelectOption>
                  ))}
                </StyledSelectPicker>
              </Form.Item>
            </StyledCol>

            <StyledCol span={24}>
              <FormFieldLabel libelle='Catégorie' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='idBiologicalAnalysisCategory'
                rules={[{ required: true, message: 'Catégorie parente obligatoire' }]}>
                <StyledSelectPicker
                  onChange={value => {
                    loadSubCategoriesByCatId(value as number);
                  }}
                  placeholder='Catégorie parente'
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }>
                  {_.map(biologicalExamCategories, item => (
                    <StyledSelectOption key={item.id} value={item.id} label={item.label}>
                      {item.label}
                    </StyledSelectOption>
                  ))}
                </StyledSelectPicker>
              </Form.Item>
            </StyledCol>

            <StyledCol span={24}>
              <FormFieldLabel libelle='Sous-Catégorie' />
              <Form.Item style={{ margin: 0 }} name='idBiologicalAnalysisSubCategory'>
                <StyledSelectPicker
                  loading={isLoadingSubCategories}
                  placeholder='Sous-catégorie parente'
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }>
                  {_.map(biologicalExamSubCategories, item => (
                    <StyledSelectOption key={item.id} value={item.id} label={item.label}>
                      {item.label}
                    </StyledSelectOption>
                  ))}
                </StyledSelectPicker>
              </Form.Item>
            </StyledCol>
            <StyledDivider />
            <StyledCol span={24}>
              <StyledSpace>
                <StyledButton
                  type='primary'
                  htmlType='submit'
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={
                    _.isUndefined(currentData)
                      ? createBiologicalExamCategory
                      : editBiologicalExamCategory
                  }>
                  {_.isUndefined(currentData)
                    ? "Enregistrer l'examen"
                    : "Modifier l'examen"}
                </StyledButton>
              </StyledSpace>
            </StyledCol>
          </StyledRow>
        </Form>
      </StyledBlocContainer>
    );
  };

  return (
    <>
      {renderHeader()}
      <StyledDivider />
      {renderContent()}
    </>
  );
};

export default BiologicalExamForm;
