import { App as AppContainer, RadioChangeEvent } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import type { ColumnsType } from 'antd/es/table';
import _ from 'lodash';
import { useEffect, useReducer, useState } from 'react';
import { FaUserInjured } from 'react-icons/fa';
import { getMedicalRecordsByPatient } from '../../api/dossierPatientService';
import { getPatients } from '../../api/patientService';
import { getPharmacyInvoiceLinesApi } from '../../api/pharmacyService';
import {
  searchBiologicalPrescriptionsLinesApi,
  searchImageryPrescriptionsLinesApi,
} from '../../api/prescriptionService';
import EmptyNotSelected from '../../components/emptyData/EmptyNotSelected';
import SectionHeader from '../../components/header/SectionHeader';
import FormFieldLabel from '../../components/label/FormFieldLabel';
import Loader from '../../components/loader';
import PageHeader from '../../components/pageHeader/PageHeader';
import MedicalRecordPatientList from '../../components/patientHistory/MedicalRecordPatientList';
import {
  StyledCol,
  StyledRadio,
  StyledRow,
  StyledSelectOption,
  StyledSelectPicker,
  StyledSpace,
} from '../../components/style';
import PageContentLeftLayout from '../../layout/PageContentLeftLayout';
import PageContentRightLayout from '../../layout/PageContentRightLayout';
import VerticalLayout from '../../layout/VerticalLayout';
import { StyledLayoutGridWrapper } from '../../layout/style';
import {
  CreateAutoInvoiceProformaReducer,
  getCreateAutoInvoiceProformaDefaultInitialState,
} from '../../reducers/invoicePlan/createAutoInvoiceproforma';
import {
  CreateManualInvoiceProformaReducer,
  getCreateManualInvoiceProformaDefaultInitialState,
} from '../../reducers/invoicePlan/createManualInvoiceProforma';
import chartColors from '../../styles/color';
import {
  CreateMedicalRecordApiResponse,
  EInvoiceType,
  GetPatientApiResponse,
  OptionValueLabel,
  PatientDto,
  PharmacyLinesResponse,
  SearchBiologicalPrescriptionLinesResponse,
  SearchImageryPrescriptionLinesResponse,
} from '../../types';
import { getApiErrorMsg } from '../../utils/commun';
import { ErrorMsgTitle } from '../../utils/constants';
import {
  StyleListContainer,
  StyledBlocContainer,
  StyledMedicalRecordsPatientHeader,
} from '../style';
import AutoProformaInvoice from './AutoProformaInvoice';
import ManualProformaInvoice from './ManualProformaInvoice';

const facturationMode: Array<OptionValueLabel> = [
  { value: 1, label: 'Facturation Manuelle' },
  { value: 2, label: 'Facturation Automatique' },
];

// const RenderTabsHeader = ({
//   currentTab,
//   handleTabChanged,
// }: {
//   currentTab: number;
//   handleTabChanged: (value: SegmentedValue) => void;
// }) => {
//   const tabs: Array<ITab> = [
//     { value: 1, label: 'Facturation Manuelle' },
//     { value: 2, label: 'Facturation Automatique' },
//   ];

//   return (
//     <>
//       <StyledSegmented
//         size='large'
//         block
//         options={tabs}
//         value={currentTab}
//         onChange={handleTabChanged}
//         style={{
//           backgroundColor: chartColors.blue50,
//           borderRadius: '0px',
//           margin: 0,
//         }}
//       />
//       <StyledDivider style={{ margin: -2, border: `2px solid ${chartColors.blue}` }} />
//     </>
//   );
// };

export default function InvoiceProforma() {
  const { notification } = AppContainer.useApp();
  const [currentTab, setCurrentTab] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patientsList, setPatientsList] = useState<GetPatientApiResponse[]>([]);
  const [selectedPatient, setSelectedPatient] = useState<number | null>(null);
  const [currentPatient, setCurrentPatient] = useState<PatientDto>();
  const [invoiceType, setInvoiceType] = useState<EInvoiceType>(EInvoiceType.OTHERS);
  const [medicalRecords, setMedicalRecords] = useState<
    Array<CreateMedicalRecordApiResponse>
  >([]);
  const [currentMedicalRecord, setCurrentMedicalRecord] =
    useState<CreateMedicalRecordApiResponse | null>(null);

  const [biologicalPrescriptionsLines, setBiologicalPrescriptionsLines] =
    useState<SearchBiologicalPrescriptionLinesResponse>([]);

  const [imageryPrescriptionLines, setImageryPrescriptionLines] =
    useState<SearchImageryPrescriptionLinesResponse>([]);

  const [pharmacyArticlesLines, setPharmacyArticlesLines] =
    useState<PharmacyLinesResponse>([]);

  const [manualInvoiceProformaState, manualInvoiceProformaReducer] = useReducer(
    CreateManualInvoiceProformaReducer,
    getCreateManualInvoiceProformaDefaultInitialState(),
  );
  const [autoInvoiceProformaState, autoInvoiceProformaReducer] = useReducer(
    CreateAutoInvoiceProformaReducer,
    getCreateAutoInvoiceProformaDefaultInitialState(),
  );

  const loadPatients = () => {
    setIsLoading(true);
    Promise.resolve(getPatients())
      .then(result => {
        setPatientsList(result);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadPatientMedicalRecords = () => {
    setIsLoading(true);
    Promise.resolve(getMedicalRecordsByPatient(currentPatient?.id as number))
      .then(result => {
        setMedicalRecords(result);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadBiologicalPrescriptionsLines = () => {
    setIsLoading(true);
    Promise.resolve(
      searchBiologicalPrescriptionsLinesApi({
        idPatient: currentPatient?.id as number,
        idMedicalRecord: currentMedicalRecord?.id as number,
        paid: null,
      }),
    )
      .then(result => {
        setBiologicalPrescriptionsLines(result);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadImageryPrescriptionsLines = () => {
    setIsLoading(true);
    Promise.resolve(
      searchImageryPrescriptionsLinesApi({
        idPatient: currentPatient?.id as number,
        idMedicalRecord: currentMedicalRecord?.id as number,
        paid: null,
      }),
    )
      .then(result => {
        setImageryPrescriptionLines(result);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadPharmacyInvoiceLines = () => {
    setIsLoading(true);
    Promise.resolve(getPharmacyInvoiceLinesApi(currentMedicalRecord?.id as number))
      .then(result => {
        setPharmacyArticlesLines(result);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadPatients();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(currentPatient) && !_.isUndefined(currentPatient)) {
      loadPatientMedicalRecords();
    }
  }, [currentPatient]);

  useEffect(() => {
    if (!_.isNull(currentMedicalRecord) && !_.isUndefined(currentMedicalRecord)) {
      if (invoiceType === EInvoiceType.BIOLOGICAL) {
        loadBiologicalPrescriptionsLines();
      } else if (invoiceType === EInvoiceType.IMAGERY) {
        loadImageryPrescriptionsLines();
      } else if (invoiceType === EInvoiceType.PHARMACY) {
        loadPharmacyInvoiceLines();
      } else {
        // loadBiologicalPrescriptionsLines();
        // loadImageryPrescriptionsLines();
        // loadPharmacyInvoiceLines();
      }
    }
  }, [currentMedicalRecord, invoiceType]);

  const handleResetSelectedMedicalRecord = () => {
    setSelectedPatient(null);
    setCurrentPatient(undefined);
    handleResetState();
  };

  const handleResetState = () => {
    setCurrentTab(1);
    manualInvoiceProformaReducer({ type: 'resetRubrics' });
    autoInvoiceProformaReducer({ type: 'removePlan' });
  };

  const handleInvoiceType = ({ target: { value } }: RadioChangeEvent) => {
    setInvoiceType(value);
  };

  const columnsMedicalRecords: ColumnsType<CreateMedicalRecordApiResponse> = [
    {
      title: 'No Dossier',
      dataIndex: 'numMedicalRecord',
      render: (value, record) => (
        <StyledSpace direction='vertical'>
          <StyledSpace
            className={`cursor-pointer hover:text-blue-600 ${
              record.id === currentMedicalRecord?.id && 'text-blue-600'
            }`}
            split='|'
            onClick={() => {
              setCurrentMedicalRecord(record);
            }}>
            <div style={{ fontWeight: 700 }}>{record.numMedicalRecord}</div>
            <span>{record.createdDate}</span>
          </StyledSpace>
        </StyledSpace>
      ),
    },
    {
      title: 'Service',
      dataIndex: 'serviceDto.id',
    },
    // {
    //   title: 'Actions',
    //   align: 'right',
    //   render: (value, record) => {
    //     return (
    //       <StyledSpace>
    //         {record.id === currentMedicalRecord?.id ? (
    //           <Avatar
    //             style={{ backgroundColor: chartColors.deepBlue }}
    //             icon={<BiSolidBadgeCheck size={30} />}
    //           />
    //         ) : (
    //           <StyledButton
    //             shape='circle'
    //             icon={<MdCheckCircle />}
    //             size='middle'
    //             onClick={() => {
    //               setCurrentMedicalRecord(record);
    //             }}
    //           />
    //         )}
    //       </StyledSpace>
    //     );
    //   },
    // },
  ];

  const renderHeader = () => {
    return (
      <>
        <PageHeader title='Rechercher' showBack />
      </>
    );
  };

  // Selecteur de patient
  const renderPatientSelector = () => {
    return (
      <Loader showSpinner={isLoading}>
        <StyledBlocContainer
          style={{
            backgroundColor: `${chartColors.blue}20`,
            padding: '20px 20px',
            boxShadow: '0px 5px 10px #00000030',
          }}>
          <StyledRow gutter={[16, 16]}>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Patient à facturer' />
              <StyledSelectPicker
                placeholder='Rechercher par nom, CNI ou Numéro patient'
                onClear={() => handleResetSelectedMedicalRecord()}
                style={{ width: '100%' }}
                allowClear
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                onChange={(value, option: DefaultOptionType['data']) => {
                  setSelectedPatient(value as number);
                  setCurrentMedicalRecord(null);
                  setCurrentPatient(option?.data ?? []);
                  handleResetState();
                }}
                defaultValue={''}
                value={selectedPatient}>
                {_.map(patientsList, item => (
                  <StyledSelectOption
                    key={item.id}
                    value={item.id}
                    data={item}
                    label={`${item.firstname} ${item.name} | ${item.cni} | ${item.numPatient}`}>
                    <StyledSpace split='|'>
                      <span style={{ fontWeight: 700 }}>{item.numPatient}</span>
                      {`${item.firstname} ${item.name}`}
                      <span style={{ fontWeight: 700 }}>{item.cni}</span>
                    </StyledSpace>
                  </StyledSelectOption>
                ))}
              </StyledSelectPicker>
            </StyledCol>

            <StyledCol span={24}>
              <FormFieldLabel libelle='Mode de facturation' />
              <StyledSelectPicker
                placeholder='Sélectionner le mode de facturation'
                style={{ width: '100%' }}
                allowClear
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                onChange={value => {
                  setCurrentTab(value as number);
                }}
                defaultValue={1}
                value={currentTab}>
                {_.map(facturationMode, item => (
                  <StyledSelectOption
                    key={item.value}
                    value={item.value}
                    label={item.label}>
                    {item.label}
                  </StyledSelectOption>
                ))}
              </StyledSelectPicker>
            </StyledCol>

            <StyledCol span={24}>
              <FormFieldLabel libelle='Type de facture' />
              <StyledRadio.Group onChange={handleInvoiceType} value={invoiceType}>
                <StyledRow>
                  <StyledCol span={12}>
                    <StyledRadio value={EInvoiceType.BIOLOGICAL}>
                      Analyse Biologique
                    </StyledRadio>
                  </StyledCol>
                  <StyledCol span={12}>
                    <StyledRadio value={EInvoiceType.IMAGERY}>Imagerie</StyledRadio>
                  </StyledCol>
                  <StyledCol span={12}>
                    <StyledRadio value={EInvoiceType.PHARMACY}>Pharmacie</StyledRadio>
                  </StyledCol>
                  <StyledCol span={12}>
                    <StyledRadio value={EInvoiceType.OTHERS}>Autres</StyledRadio>
                  </StyledCol>
                </StyledRow>
              </StyledRadio.Group>
            </StyledCol>
          </StyledRow>
        </StyledBlocContainer>
      </Loader>
    );
  };

  const renderTabContent = () => {
    switch (currentTab) {
      case 1:
        return (
          <ManualProformaInvoice
            selectedPatientId={selectedPatient}
            selectedMedicalRecord={currentMedicalRecord}
            biologicalPrescriptionsLines={biologicalPrescriptionsLines}
            imageryPrescriptionsLines={imageryPrescriptionLines}
            pharmacyInvoiceLines={pharmacyArticlesLines}
            currentPatient={currentPatient}
            state={manualInvoiceProformaState}
            reducer={manualInvoiceProformaReducer}
            invoiceType={invoiceType}
            isParentLoading={isLoading}
          />
        );
      case 2:
        return (
          <AutoProformaInvoice
            selectedPatientId={selectedPatient}
            selectedMedicalRecord={currentMedicalRecord}
            biologicalPrescriptionsLines={biologicalPrescriptionsLines}
            imageryPrescriptionsLines={imageryPrescriptionLines}
            pharmacyInvoiceLines={pharmacyArticlesLines}
            currentPatient={currentPatient}
            state={autoInvoiceProformaState}
            reducer={autoInvoiceProformaReducer}
            invoiceType={invoiceType}
            isParentLoading={isLoading}
          />
        );

      default:
        return null;
    }
  };

  const renderMedicalRecordPatient = () => {
    if (_.isEmpty(currentPatient) || _.isUndefined(currentPatient)) {
      return (
        <EmptyNotSelected
          message='Aucun patient sélectionné'
          description='Veuillez sélectionner un patient à facturer'
          icon={<FaUserInjured size={80} />}
        />
      );
    }
    return (
      <>
        <StyledMedicalRecordsPatientHeader>
          <SectionHeader title='Dossier' count={medicalRecords.length} />
        </StyledMedicalRecordsPatientHeader>
        <StyleListContainer
          style={{
            padding: '0px',
            maxHeight: '400px',
          }}>
          <MedicalRecordPatientList
            // isLoading={isLoading}
            dataMedicalRecords={medicalRecords}
            customColumns={columnsMedicalRecords}
            showListHeader={false}
            showTableHeader={false}
            hasPagination={false}
          />
        </StyleListContainer>
      </>
    );
  };

  const renderContent = () => {
    return (
      <VerticalLayout enableVerticalScroll='false' layerShowPadding='false'>
        <StyledLayoutGridWrapper>
          <PageContentLeftLayout headerTitle={renderHeader()}>
            {renderPatientSelector()}
            {renderMedicalRecordPatient()}
          </PageContentLeftLayout>
          <PageContentRightLayout>{renderTabContent()}</PageContentRightLayout>
        </StyledLayoutGridWrapper>
      </VerticalLayout>
    );
  };

  // <VerticalLayout enableVerticalScroll='false'>
  //   {renderHeader()}
  //   <div style={{ backgroundColor: 'white' }}>{renderContent()}</div>
  // </VerticalLayout>
  return renderContent();
}
