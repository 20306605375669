import { NavLink } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import styled from 'styled-components';
import { MenuProps, RouteItemProps } from '../../types';

// const swing = keyframes`
//   20% { transform: rotate(15deg); }
// 	40% { transform: rotate(-10deg); }
// 	60% { transform: rotate(5deg); }
// 	80% { transform: rotate(-5deg); }
// 	90% { transform: rotate(0deg); }
// 	100% { transform: rotate(-5deg); }
// `;

// const swingMotion = css`
//   transform-origin: center center;
//   animation: ${swing} 2s ease;
//   transform: rotate(-5deg);
// `;

export const StyledMenuWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.deepBlue};
  height: 100vh;
  margin-bottom: 4rem;
  /* selection de tous les p (qui sont enfants directs) des div (qui sont enfants directs) du composant */
  & > div > div > p {
    margin: 0;
    padding: 1rem;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
  }
  /* hover sur les p (qui sont enfants directs) des div (qui sont enfants directs) du composant */
  & > div > div > p:hover {
    color: black;
    cursor: pointer;
    font-weight: bold;
  }
`;

export const StyledSubMenuWrapper = styled.div`
  margin-left: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #fff;
  & > span:hover {
    color: black;
    cursor: pointer;
    font-weight: bold;
  }
`;

export const StyledMenuToggleButton = styled.button<MenuProps>`
  transition: left 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 40px;
  width: fit-content;
  border-radius: 0% 10% 10% 0%;
  outline: 0px;
  border: none;
  top: 12px;
  font-size: 2.5rem;
  left: ${({ $isclosed }) => ($isclosed === 'true' ? '70px' : '200px')};
  z-index: 100;
  box-shadow: 0px 0px 5px ${({ theme }) => theme.colors.black}10;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.deepBlue};
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledMobileMenuToggleButton = styled(StyledMenuToggleButton)`
  border-radius: 10%;
  width: 40px;
  left: 20px;
`;

export const StyledMenuHeader = styled.div`
  z-index: 2;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.deepBlue};
  font-size: 2rem;
  font-family: 'Mudclaw', sans-serif;
  padding-top: 15px;
  padding-left: 5px;
  width: 100%;
  margin-bottom: 0px;
  position: sticky;
  top: 0px;
  left: 0px;
  transition: left 0.1s;
`;

export const StyledMobileMenuHeader = styled(StyledMenuHeader)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.deepBlue};
  padding: 0px 0px 0px 50px;
  font-size: 3rem;
`;

export const StyledLogoTitle = styled(StyledMenuHeader)`
  color: ${({ theme }) => theme.colors.deepBlue};
`;

export const StyledDesktopMenuItemIcon = styled.div<RouteItemProps>`
  position: relative;
  font-size: ${({ $isclosed }) => ($isclosed === 'true' ? '3rem' : '3rem')};
  color: ${({ theme, $isactive }) => $isactive === 'true' && theme.colors.deepBlue};
  margin-top: 5px;
  left: 0px;
`;

export const StyledDesktopMenuItemTitle = styled.span<RouteItemProps>`
  transition: display 0.3s 200ms ease-in-out;
  color: ${({ theme, $isactive }) => $isactive === 'true' && theme.colors.deepBlue};
  display: ${({ $isclosed }) => ($isclosed === 'true' ? 'none' : 'flex')};
  width: 100%;
  font-size: 0.8em;
  font-weight: 600;
  padding-top: 10px;
  padding-left: 5px;
  word-wrap: break-word;
  transition: all 0.9s;
  transition-delay: 5s;
  transition-property: right, visibility, opacity, inline-size;
  visibility: ${({ $isclosed }) => ($isclosed === 'true' ? 'hidden' : 'visible')};
  inline-size: ${({ $isclosed }) => ($isclosed === 'true' ? '10px' : '120px')};
`;

export const StyledDesktopMenuItem = styled(NavLink)<RouteItemProps>`
  position: relative;
  display: flex;
  align-items: center;
  font-family: 'Metropolis', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 5px;
  /* margin: 0px auto auto; */
  padding: 10px 20px;
  padding-left: 18px;
  /* padding: ${({ $isclosed }) => ($isclosed === 'true' ? '5px 0px' : '5px 20px')}; */
  flex-direction: row;
  align-items: center;
  /* justify-content: ${({ $isclosed }) =>
    $isclosed === 'true' ? 'center' : 'flex-start'}; */
  width: 100%;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  left: 0px;
  background-color: ${({ theme, $isactive }) =>
    $isactive === 'true' ? theme.colors.white : theme.colors.deepBlue};

  &:hover {
    color: ${({ theme }) => theme.colors.deepBlue};
    background-color: ${({ theme }) => theme.colors.white};
    ${StyledDesktopMenuItemTitle} {
      color: ${({ theme }) => theme.colors.deepBlue};
    }
  }
`;

export const StyledDesktopStandardMenuItemIcon = styled(StyledDesktopMenuItemIcon)`
  color: ${({ theme, $isactive }) => $isactive === 'true' && theme.colors.deepBlue};
`;

export const StyledDesktopStandardMenuItemTitle = styled(StyledDesktopMenuItemTitle)`
  color: ${({ theme, $isactive }) => $isactive === 'true' && theme.colors.deepBlue};
`;

export const StyledDesktopStandardMenuItem = styled(StyledDesktopMenuItem)`
  background-color: ${({ theme, $isactive }) =>
    $isactive === 'true' ? theme.colors.white : theme.colors.lightGray};
  color: ${({ theme, $isactive }) =>
    $isactive === 'true' ? theme.colors.deepBlue : theme.colors.mainGray};
  &:hover {
    color: ${({ theme }) => theme.colors.deepBlue};
    background-color: ${({ theme }) => theme.colors.white};
    ${StyledDesktopStandardMenuItemTitle} {
      color: ${({ theme }) => theme.colors.deepBlue};
    }
  }
`;

// Mobile Menu

export const StyledMobileMenuItemTitle = styled(StyledDesktopMenuItemTitle)`
  display: flex;
  visibility: visible;
`;
export const StyledMobileMenuItemIcon = styled(StyledDesktopMenuItemIcon)``;
export const StyledMobileMenuItem = styled(StyledDesktopMenuItem)``;

export const StyledMobileStandardMenuItemTitle = styled(StyledMobileMenuItemTitle)`
  color: ${({ theme, $isactive }) => $isactive === 'true' && theme.colors.deepBlue};
`;

export const StyledMobileStandardMenuItemIcon = styled(StyledDesktopStandardMenuItemIcon)`
  color: ${({ theme, $isactive }) => $isactive === 'true' && theme.colors.deepBlue};
`;

export const StyledMobileStandardMenuItem = styled(StyledDesktopStandardMenuItem)`
  background-color: ${({ theme, $isactive }) =>
    $isactive === 'true' ? theme.colors.white : theme.colors.lightGray};
  color: ${({ theme, $isactive }) =>
    $isactive === 'true' ? theme.colors.deepBlue : theme.colors.mainGray};
  &:hover {
    color: ${({ theme }) => theme.colors.deepBlue};
    background-color: ${({ theme }) => theme.colors.white};
    ${StyledDesktopStandardMenuItemTitle} {
      color: ${({ theme }) => theme.colors.deepBlue};
    }
  }
`;

// Menus Containers and Wrappers

export const StyledMenuDesktopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0px;
  row-gap: 10px;
`;

export const StyledMenuDesktopWrapper = styled(SimpleBar)<MenuProps>`
  transition: width 0.5s;
  position: fixed;
  z-index: 70;
  height: 100vh;
  padding: 0px 3px;
  background-color: ${({ theme }) => theme.colors.deepBlue};
  width: ${({ $isclosed }) => ($isclosed === 'true' ? '70px' : '200px')};
  box-shadow: 10px 0px 10px #00000010;

  /* &:hover {
    box-shadow: 10px 0px 15px ${({ theme }) => theme.colors.black}10;
    transition: all 0.5s ease-out;
    width: 200px;
    
    ${StyledDesktopMenuItem}{
      justify-content: flex-start;
    }
    
    ${StyledDesktopMenuItem} ${StyledDesktopMenuItemTitle} {
      display: block;
    }
  } */
`;

export const StyledMenuMobileContainer = styled(StyledMenuDesktopContainer)``;
export const StyledMenuMobileWrapper = styled(StyledMenuDesktopWrapper)`
  width: 70vw;
  box-shadow: none;
`;
