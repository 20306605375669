import { PropsWithChildren } from 'react';
import { StyledRightLayoutWrapper } from './style';

interface RightLayoutProps {
  value?: string;
  layerShowPadding?: 'true' | 'false';
}

const PageContentRightLayout: React.FC<PropsWithChildren<RightLayoutProps>> = ({
  children,
  layerShowPadding = 'false',
}) => {
  return (
    <StyledRightLayoutWrapper $layerShowPadding={layerShowPadding}>
      {children}
    </StyledRightLayoutWrapper>
  );
};

export default PageContentRightLayout;
