import styled from 'styled-components';

export const StyledSectionHeaderTitle = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-family: 'Mudclaw', sans-serif;
  font-size: 1.8rem;
  text-transform: uppercase;
`;
export const StyledSectionHeaderActions = styled.div``;

export const StyledSectionHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  flex: 1;
`;

export const StyledSectionHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 50px;
  /* background-color: #edf0f8; */
  justify-content: center;
`;

export const StyledHeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  height: 6.5rem;
  box-shadow: 0px 10px 15px ${({ theme }) => theme.colors.black}10;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  z-index: 60;
  position: sticky;
  top: 0px;
  right: 0px;
  left: 0px;
`;

export const StyledErrorHeaderWrapper = styled(StyledHeaderWrapper)`
  position: relative;
`;

export const StyledTitle = styled.div`
  font-family: 'Mudclaw', sans-serif;
  font-size: 1.8rem;
  text-transform: uppercase;
`;

export const StyledModalTitle = styled.div`
  font-family: 'Metropolis', sans-serif;
  font-size: 1.3rem;
  text-transform: uppercase;
`;

/**
 * User Header
 */
export const StyledUserHeaderName = styled.div`
  font-size: 1.5em;
  margin-bottom: -7px;
  font-weight: 700;
`;

export const StyledUserHeaderRole = styled.span`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 1.5rem;
  font-weight: 600;
`;
export const StyledUserHeaderInfo = styled.div`
  text-align: right;
  font-weight: 600;
`;

export const StyledUserHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;
