import { useState } from 'react';
import { StyledButton } from '../../../components/button/style';
import { StyledSteps } from '../../../components/style';
import { StyledMedicalRecordWrapper } from '../../style';
import PrescriptionHistory from './PrescriptionHistory';
import Pronostic from './Pronostic';
import HospitPrescriptionHistory from './HospitPrescriptionHistory';

const stepsItems = [
  {
    title: 'Saisie du diagnostic',
    content: <Pronostic />,
  },
  {
    title: 'Prescriptions',
    content: <PrescriptionHistory />,
  },
  {
    title: 'Hospitalisation',
    content: <HospitPrescriptionHistory />,
  },
  // {
  //   title: 'Nouvelle prescription',
  //   content: <AddPrescription />,
  // },
];
const Diagnostic: React.FC = () => {
  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const [activeStep, setActiveStep] = useState<number>(0);

  const next = () => {
    setActiveStep(activeStep + 1);
  };

  const prev = () => {
    setActiveStep(activeStep - 1);
  };

  const renderStepsFooter = () => {
    return (
      <div
        style={{
          marginBottom: 24,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        {activeStep >= 1 ? (
          <StyledButton onClick={() => prev()}>Précédent</StyledButton>
        ) : (
          <div></div>
        )}
        {activeStep < stepsItems.length - 1 && (
          <StyledButton type='primary' onClick={() => next()}>
            Suivant
          </StyledButton>
        )}
      </div>
    );
  };

  return (
    <>
      <StyledSteps
        current={activeStep}
        items={stepsItems}
        className='examen_clinique_inline_step'
      />
      <StyledMedicalRecordWrapper>
        {renderStepsFooter()}
        {stepsItems[activeStep].content}
      </StyledMedicalRecordWrapper>
    </>
  );
};

export default Diagnostic;
