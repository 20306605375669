/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer, Form } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { BsExclamationTriangleFill } from 'react-icons/bs';
import { FiRefreshCcw } from 'react-icons/fi';
import { getServicesApi } from '../../../api/referentialService';
import {
  createUserApi,
  resetUserPasswordApi,
  updateUserApi,
} from '../../../api/userService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import CustomPhoneInput from '../../../components/input/CustomPhoneInput';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import {
  StyledCol,
  StyledDivider,
  StyledInput,
  StyledRow,
  StyledSelectOption,
  StyledSelectPicker,
  StyledSpace,
} from '../../../components/style';
import levels from '../../../mocks/getLevelMock.json';
import chartColors from '../../../styles/color';
import {
  CreateUserRequestBody,
  GetServicesResponse,
  IUserItem,
  ROLES,
  ReferentialViewProps,
  UserDto,
} from '../../../types';
import { checkExistingRole, getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { getDataFromStorage } from '../../../utils/storage';
import { StyledBlocContainer } from '../../style';

type UserFormProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  currentData?: IUserItem;
};

type FormPropertiesProps = {
  // username: string;
  name: string;
  firstname: string;
  // speciality: string;
  idService: number;
  tel: string;
  email: string;
  pwd: string;
  level: string;
};

const UserForm: React.FC<UserFormProps> = ({ navigateToview, currentData }) => {
  const { notification, modal } = AppContainer.useApp();
  const [form] = Form.useForm<FormPropertiesProps>();
  const user = getDataFromStorage<UserDto>('connectedUserData');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingList, setIsLoadingList] = useState<boolean>(false);
  const [servicesData, setServicesData] = useState<GetServicesResponse>([]);

  const loadResources = async () => {
    setIsLoadingList(true);
    Promise.all([getServicesApi()])
      .then(result => {
        setServicesData(result[0]);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoadingList(false);
      });
  };

  useEffect(() => {
    loadResources();
  }, []);

  const renderHeader = () => {
    return (
      <SectionHeader
        title={
          _.isUndefined(currentData) ? 'Nouvel utilisateur' : 'Modification utilisateur'
        }
        onBack={() => navigateToview('LIST_ITEMS')}
      />
    );
  };

  const mapPropsToRequest = (row: FormPropertiesProps) => {
    const userData: CreateUserRequestBody = {
      email: row.email,
      pwd: row.pwd,
      // username: row.username,
      firstname: row.firstname,
      level: row.level,
      name: row.name,
      // speciality: row.speciality,
      tel: row.tel,
      idService: row.idService,
    };

    return userData;
  };

  const createUser = async () => {
    try {
      await form.validateFields().then(async row => {
        setIsLoading(true);
        await createUserApi(mapPropsToRequest(row)).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `L'utilisateur a été enregistré!`,
          });
          navigateToview('LIST_ITEMS');
        });
      });
    } catch (error: any) {
      if (_.isUndefined(error.values)) {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const resetUserPassword = async (userId: number) => {
    try {
      setIsLoading(true);
      Promise.resolve(resetUserPasswordApi(userId as number)).then(() => {
        notification.success({
          message: SuccesssMsgTitle,
          description: `Le mot de passe a été réinitialisé`,
        });
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetUserPassword = () => {
    modal.confirm({
      title: 'Réinitialisation de mot de passe',
      icon: <BsExclamationTriangleFill size={40} color={chartColors.warning} />,
      content: `Êtes-vous sûr de vouloir réinitaliser le mot de passe de l'utilisateur ${
        currentData?.firstname + '' + currentData?.name
      } ?`,
      okText: 'Oui, je Confirme',
      cancelText: 'Annuler',
      onOk: () => resetUserPassword(currentData?.id as number),
    });
  };

  const editUser = async () => {
    try {
      await form.validateFields().then(async row => {
        setIsLoading(true);
        await updateUserApi(currentData?.id as number, mapPropsToRequest(row)).then(
          () => {
            notification.success({
              message: SuccesssMsgTitle,
              description: `L'utilisateur a été modifié!`,
            });
            navigateToview('LIST_ITEMS');
          },
        );
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderResetButton = () => {
    return (
      <StyledButton
        type='dashed'
        icon={<FiRefreshCcw />}
        style={{ marginTop: 10 }}
        onClick={() => handleResetUserPassword()}>
        Réinitialiser le mot de passe
      </StyledButton>
    );
  };

  const renderContent = () => {
    return (
      <StyledBlocContainer style={{ padding: 20 }}>
        <Form
          disabled={isLoading}
          form={form}
          component={false}
          initialValues={{ ...currentData, idService: currentData?.serviceDto.id }}>
          <StyledRow gutter={[16, 16]}>
            <StyledDivider orientation='left'>Informations générales</StyledDivider>
            <StyledCol span={12}>
              <FormFieldLabel libelle='Titre' isRequired />
              <Form.Item style={{ margin: 0 }} name='level'>
                <StyledSelectPicker
                  placeholder='Sélectionnez un titre'
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }>
                  {_.map(levels, item => (
                    <StyledSelectOption
                      key={item.code}
                      value={item.value}
                      label={item.libelle}>
                      {item.libelle}
                    </StyledSelectOption>
                  ))}
                </StyledSelectPicker>
              </Form.Item>
            </StyledCol>
            <StyledCol span={12}>
              <FormFieldLabel libelle='Nom' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='name'
                rules={[
                  { required: true, message: 'Nom obligatoire' },
                  { whitespace: false },
                ]}>
                <StyledInput size='large' placeholder="Nom de l'utilisateur" />
              </Form.Item>
            </StyledCol>
            <StyledCol span={12}>
              <FormFieldLabel libelle='Prénoms' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='firstname'
                rules={[
                  { required: true, message: 'Prénoms obligatoire' },
                  { whitespace: false },
                ]}>
                <StyledInput size='large' placeholder="Prénoms de l'utilisateur" />
              </Form.Item>
            </StyledCol>

            <StyledCol span={12}>
              <FormFieldLabel libelle='Téléphone' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='tel'
                rules={[
                  { required: true, message: 'Prénoms obligatoire' },
                  { whitespace: false },
                ]}>
                <CustomPhoneInput
                  placeholder='Numéro de téléphone'
                  value={form.getFieldValue('tel')}
                  onChange={val => form.setFieldValue('tel', val)}
                />
              </Form.Item>
            </StyledCol>

            <StyledCol span={12}>
              <FormFieldLabel libelle='Service' isRequired />
              <Form.Item style={{ margin: 0 }} name='idService'>
                <StyledSelectPicker
                  placeholder='Sélectionnez un service'
                  onChange={(_value, option) => {
                    form.setFieldValue('serviceDto', option);
                  }}
                  style={{ width: '100%' }}
                  loading={isLoadingList}
                  disabled={isLoadingList}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }>
                  {_.map(servicesData, item => (
                    <StyledSelectOption key={item.id} value={item.id} label={item.name}>
                      {item.name}
                    </StyledSelectOption>
                  ))}
                </StyledSelectPicker>
              </Form.Item>
            </StyledCol>

            <StyledDivider orientation='left'>Informations de connexion</StyledDivider>

            <StyledCol span={24}>
              <FormFieldLabel libelle='Adresse Email' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='email'
                rules={[
                  { required: true, message: 'Email obligatoire' },
                  { whitespace: false },
                ]}>
                <StyledInput size='large' placeholder="Email de l'utilisateur" />
              </Form.Item>
            </StyledCol>
            <StyledCol span={24}>
              {_.isUndefined(currentData) && (
                <>
                  <FormFieldLabel libelle='Mot de passe' isRequired />
                  <Form.Item
                    style={{ margin: 0 }}
                    name='pwd'
                    rules={[
                      { required: true, message: 'Mot de passe obligatoire' },
                      { whitespace: false },
                    ]}>
                    <StyledInput.Password
                      size='large'
                      placeholder="Mot de passe de l'utilisateur"
                      readOnly={currentData ? true : false}
                      disabled={currentData ? true : false}
                      value={form.getFieldValue('pwd')}
                    />
                  </Form.Item>
                </>
              )}
              {currentData && checkExistingRole(user.roles, ROLES.ADMIN)
                ? renderResetButton()
                : null}
            </StyledCol>

            <StyledDivider />
            <StyledCol span={24}>
              <StyledSpace>
                <StyledButton
                  type='primary'
                  htmlType='submit'
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={_.isUndefined(currentData) ? createUser : editUser}>
                  {_.isUndefined(currentData)
                    ? "Enregistrer l'utilisateur"
                    : "Modifier l'utilisateur"}
                </StyledButton>
              </StyledSpace>
            </StyledCol>
          </StyledRow>
        </Form>
      </StyledBlocContainer>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};

export default UserForm;
