import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { TbLayoutSidebarRightCollapse } from 'react-icons/tb';
import { useLocation } from 'react-router-dom';
import chartColors from '../../styles/color';
import { UserDto } from '../../types';
import { getDataFromStorage } from '../../utils/storage';
import { StyledButton } from '../button/style';
import StyledDrawer from '../drawer/StyledDrawer';
import {
  RouteItemsProps,
  filteredRoutesByUserRights,
  routes,
  standardRoutes,
} from '../menu/routes';
import {
  StyledMenuHeader,
  StyledMenuMobileContainer,
  StyledMenuMobileWrapper,
  StyledMobileMenuHeader,
  StyledMobileMenuItem,
  StyledMobileMenuItemIcon,
  StyledMobileMenuItemTitle,
  StyledMobileMenuToggleButton,
  StyledMobileStandardMenuItem,
  StyledMobileStandardMenuItemIcon,
  StyledMobileStandardMenuItemTitle,
} from '../menu/style';
import { StyledDivider } from '../style';
import LogoApiPatientLgBl from '../../assets/images/logo_api_patient_lg_bl.svg';
import LogoApiPatientCrBlue from '../../assets/images/logo_api_patient_cr_blue.svg';

const MobileHeader: React.FC = () => {
  const [isClosed, setIsClosed] = useState<boolean>(true);
  const { pathname } = useLocation();
  const user = getDataFromStorage<UserDto>('connectedUserData');

  const toggleMenu = () => {
    setIsClosed(state => !state);
  };

  const renderRouteItemProps = (routeItem: RouteItemsProps) => {
    const isItemActive = routeItem.focusedLinks.includes(pathname);
    const itemProps = {
      to: routeItem.path,
      $isclosed: isClosed.toString(),
      $isactive: isItemActive.toString(),
    };
    return itemProps;
  };

  const renderDesktopRoutes = () => {
    return filteredRoutesByUserRights(routes, user.rights).map((routeItem, idx) => {
      const itemProps = renderRouteItemProps(routeItem);

      return (
        <StyledMobileMenuItem key={idx} {...itemProps} onClick={toggleMenu}>
          <StyledMobileMenuItemIcon {...itemProps}>
            {routeItem.icon}
          </StyledMobileMenuItemIcon>
          <StyledMobileMenuItemTitle {...itemProps}>
            {routeItem.title}
          </StyledMobileMenuItemTitle>
        </StyledMobileMenuItem>
      );
    });
  };

  const renderDesktopStandardRoutes = () => {
    return filteredRoutesByUserRights(standardRoutes, user.rights).map(
      (routeItem, idx) => {
        const itemProps = renderRouteItemProps(routeItem);
        return (
          <StyledMobileStandardMenuItem key={idx} onClick={toggleMenu} {...itemProps}>
            <StyledMobileStandardMenuItemIcon {...itemProps}>
              {routeItem.icon}
            </StyledMobileStandardMenuItemIcon>
            <StyledMobileStandardMenuItemTitle {...itemProps}>
              {routeItem.title}
            </StyledMobileStandardMenuItemTitle>
          </StyledMobileStandardMenuItem>
        );
      },
    );
  };

  return (
    <>
      <div>
        <StyledMobileMenuHeader>
          <img src={LogoApiPatientCrBlue} style={{ width: '100%', height: '1.5em' }} />
        </StyledMobileMenuHeader>
        <StyledMobileMenuToggleButton
          $isclosed={isClosed.toString()}
          onClick={() => toggleMenu()}>
          <TbLayoutSidebarRightCollapse />
        </StyledMobileMenuToggleButton>
      </div>

      <StyledDrawer
        drawerSize='default'
        closeDrawer={toggleMenu}
        maskClosable={true}
        customDrawerStyle={{ backgroundColor: chartColors.deepBlue }}
        customWidth='60vw'
        isDrawerOpen={!isClosed}>
        <StyledMenuMobileWrapper>
          <StyledMenuHeader>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <div>
                <img src={LogoApiPatientLgBl} style={{ width: '90%', height: '1.5em' }} />
              </div>
              <StyledButton
                shape='circle'
                size='large'
                danger
                type='primary'
                icon={<FaTimes />}
                onClick={toggleMenu}
              />
            </div>
            <StyledDivider style={{ marginBottom: 0 }} />
          </StyledMenuHeader>

          <StyledMenuMobileContainer>
            {renderDesktopRoutes()}
            <StyledDivider />
            {renderDesktopStandardRoutes()}
          </StyledMenuMobileContainer>
        </StyledMenuMobileWrapper>
      </StyledDrawer>
    </>
  );
};

export default MobileHeader;
