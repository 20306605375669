import { App as AppContainer, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useState, useEffect } from 'react';
import { GetServicesResponse, IServiceItem, ReferentialViewProps } from '../../../types';
import {
  ErrorDeleteMsg,
  ErrorMsgTitle,
  SuccesssMsgTitle,
} from '../../../utils/constants';
import { getApiErrorMsg } from '../../../utils/commun';
import { deleteServiceApi, getServicesApi } from '../../../api/referentialService';
import { BsExclamationTriangleFill, BsPlus } from 'react-icons/bs';
import chartColors from '../../../styles/color';
import { StyledButton } from '../../../components/button/style';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { StyledDivider, StyledSpace } from '../../../components/style';
import SectionHeader from '../../../components/header/SectionHeader';
import Loader from '../../../components/loader';

type ServicesListProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  updateCurrentItem: (p: IServiceItem) => void;
};

const ServicesList: React.FC<ServicesListProps> = ({
  navigateToview,
  updateCurrentItem,
}) => {
  const { notification, modal } = AppContainer.useApp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [servicesData, setServicesData] = useState<GetServicesResponse>([]);

  const loadServices = async () => {
    setIsLoading(true);
    Promise.resolve(getServicesApi())
      .then(result => {
        setServicesData(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadServices();
  }, []);

  const handleUpdate = (service: IServiceItem) => {
    updateCurrentItem(service);
    navigateToview('EDIT_ITEM');
  };

  const deleteService = async (service: IServiceItem) => {
    try {
      setIsLoading(true);
      Promise.resolve(deleteServiceApi(service.id as number)).then(() => {
        loadServices();
        notification.success({
          message: SuccesssMsgTitle,
          description: `Le service a été supprimé`,
        });
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(ErrorDeleteMsg),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveService = (service: IServiceItem) => {
    modal.confirm({
      title: 'Confirmation de suppression',
      icon: <BsExclamationTriangleFill size={40} color={chartColors.warning} />,
      content: `Êtes-vous sûr de vouloir supprimer le service ${service.name} de manière permanente ?`,
      okText: 'Oui, je Confirme',
      cancelText: 'Annuler',
      onOk: () => deleteService(service),
    });
  };

  const columnData: ColumnsType<IServiceItem> = [
    {
      title: 'Code',
      dataIndex: 'code',
      width: 150,
    },
    {
      title: 'Désignation',
      dataIndex: 'name',
    },
    {
      title: 'Actions',
      align: 'left',
      render: (_value, record) => {
        return (
          <StyledSpace>
            <StyledButton
              shape='circle'
              size='middle'
              icon={<FiEdit />}
              onClick={() => handleUpdate(record)}
            />
            <StyledButton
              shape='circle'
              size='middle'
              danger
              icon={<FiTrash2 />}
              onClick={() => handleRemoveService(record)}
            />
          </StyledSpace>
        );
      },
    },
  ];

  const renderHeader = () => {
    return (
      <SectionHeader title='Service' count={servicesData.length}>
        <StyledButton
          icon={<BsPlus />}
          type='primary'
          onClick={() => navigateToview('NEW_ITEM')}>
          Ajouter un service
        </StyledButton>
      </SectionHeader>
    );
  };

  const renderContent = () => {
    return (
      <>
        <StyledDivider />
        <Table
          rowKey='id'
          bordered={false}
          columns={columnData}
          dataSource={servicesData}
          size='small'
          pagination={false}
        />
      </>
    );
  };

  return (
    <>
      {renderHeader()}
      <Loader showSpinner={isLoading}>{renderContent()}</Loader>
    </>
  );
};

export default ServicesList;
