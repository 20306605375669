import React, { PropsWithChildren } from 'react';
import chartColors from '../../styles/color';

type CguParagraphProps = {
  rank: number;
  title: string;
};

const CguParagraph: React.FC<PropsWithChildren<CguParagraphProps>> = ({
  rank,
  title,
  children,
}) => {
  return (
    <div style={{ marginBottom: 16 }}>
      <span
        className='font-semibold'
        style={{
          color: chartColors.deepBlue,
          fontSize: '2.3rem',
        }}>{`${rank}. ${title}`}</span>
      <div style={{ fontSize: '1.5rem', fontFamily: 'Sharp sans, sans-serif' }}>
        {children}
      </div>
    </div>
  );
};

export default CguParagraph;
