import { ConfigMenuItemProps } from './settings.types';

export enum ReferentialTabs {
  ARTICLES_TAB = 'ARTICLES',
  BIOLOGICAL_EXAM_CAT_TAB = 'BIOLOGICAL_EXAM_CAT',
  BIOLOGICAL_EXAM_SUB_CAT_TAB = 'BIOLOGICAL_EXAM_SUB_CAT',
  BIOLOGICAL_EXAMS_TAB = 'BIOLOGICAL_EXAMS',
  IMAGERY_EXAMS_TAB = 'IMAGERY_EXAMS',
  HOSPITALIZATION_EXAMS_TAB = 'HOSPITALIZATION_EXAMS',
  SUPPLIERS_TAB = 'SUPPLIERS',
  MEDICATIONS_TAB = 'MEDICATIONS',
  ANTECEDENTS_TAB = 'ANTECEDENTS',
  DIAGNOSTICS_TAB = 'DIAGNOSTICS',
  SERVICES_TAB = 'SERVICES',
  ETHNICS_TAB = 'ETHNICS',
  REGIONS_TAB = 'REGIONS',
  PROFESSIONS_TAB = 'PROFESSIONS',
  PLACES_OF_RESIDENCE_TAB = 'PLACES_OF_RESIDENCE',
  FUNCTIONAL_SIGNS = 'FUNCTIONAL_SIGNS',
  CONSULTATION_REASONS = 'CONSULTATION_REASONS',
  ROOMS_TAB = 'CHAMBRES',
}

export type IReferentialMenuItem = ConfigMenuItemProps<ReferentialTabs>;

export interface ReferentialCodeLabel {
  id?: number;
  code: string;
  label: string;
}
export type BiologicalAnalysisCategoryDto = ReferentialCodeLabel;
export type BiologicalAnalysisSubCategoryDto = ReferentialCodeLabel & {
  biologicalAnalysisCategoryDto: BiologicalAnalysisCategoryDto;
};

export type ReferentialTabType = ReferentialTabs;

export type GetBiologicalAnalysisCategoriesResponse = ReferentialCodeLabel[];
export type GetBiologicalAnalysisSubCategoriesResponse =
  BiologicalAnalysisSubCategoryDto[];

export type CreateBiologicalAnalysisCategoryRequestBody = Omit<
  ReferentialCodeLabel,
  'id'
>;

export type CreateBiologicalAnalysisSubCategoryRequestBody = Omit<
  ReferentialCodeLabel,
  'id'
> & {
  idBiologicalAnalysisCategory: number;
};

export type ReferentialViewProps = 'LIST_ITEMS' | 'NEW_ITEM' | 'EDIT_ITEM';
