/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer, Form } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import {
  createBiologicalAnalysisCategoryApi,
  updateBiologicalAnalysisCategoryApi,
} from '../../../api/referentialService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import {
  StyledCol,
  StyledDivider,
  StyledInput,
  StyledRow,
  StyledSpace,
} from '../../../components/style';
import { BiologicalAnalysisCategoryDto, ReferentialViewProps } from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { StyledBlocContainer } from '../../style';

type BiologicalExamCategorieFormProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  currentData?: BiologicalAnalysisCategoryDto;
};

const BiologicalExamCategorieForm: React.FC<BiologicalExamCategorieFormProps> = ({
  navigateToview,
  currentData,
}) => {
  const { notification } = AppContainer.useApp();
  const [form] = Form.useForm<{ code: string; label: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const renderHeader = () => {
    return (
      <SectionHeader
        title={
          _.isUndefined(currentData)
            ? "Nouvelle catégorie d'examen biologique"
            : "Modification catégorie d'examen biologique"
        }
        onBack={() => navigateToview('LIST_ITEMS')}
      />
    );
  };

  const createBiologicalExamCategory = async () => {
    try {
      await form.validateFields().then(async row => {
        const categoryData: BiologicalAnalysisCategoryDto = {
          code: row.code,
          label: row.label,
        };

        setIsLoading(true);
        Promise.resolve(createBiologicalAnalysisCategoryApi(categoryData)).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `${row.label} a été enregistrée!`,
          });
          navigateToview('LIST_ITEMS');
        });
      });
    } catch (error: any) {
      if (_.isUndefined(error.values)) {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const editBiologicalExamCategory = async () => {
    try {
      await form.validateFields().then(async row => {
        const categoryData: BiologicalAnalysisCategoryDto = {
          code: row.code,
          label: row.label,
        };

        setIsLoading(true);
        Promise.resolve(
          updateBiologicalAnalysisCategoryApi(currentData?.id as number, categoryData),
        ).then(() => {
          updateBiologicalAnalysisCategoryApi(currentData?.id as number, categoryData);
        });

        notification.success({
          message: SuccesssMsgTitle,
          description: `La catégorie a été modifiée!`,
        });
        navigateToview('LIST_ITEMS');
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    return (
      <StyledBlocContainer style={{ padding: 20 }}>
        <Form
          disabled={isLoading}
          form={form}
          component={false}
          initialValues={{
            code: _.isUndefined(currentData) ? '' : currentData.code,
            label: _.isUndefined(currentData) ? '' : currentData.label,
          }}>
          <StyledRow gutter={[16, 16]} align='middle' justify='center'>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Libellé de la catégorie' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='label'
                rules={[
                  { required: true, message: 'Libellé obligatoire' },
                  { whitespace: false },
                ]}>
                <StyledInput
                  size='large'
                  placeholder='Libellé catégorie examen biologique'
                />
              </Form.Item>
            </StyledCol>
            <StyledDivider />
            <StyledCol span={24}>
              <StyledSpace>
                <StyledButton
                  type='primary'
                  htmlType='submit'
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={
                    _.isUndefined(currentData)
                      ? createBiologicalExamCategory
                      : editBiologicalExamCategory
                  }>
                  {_.isUndefined(currentData)
                    ? 'Enregistrer la catégorie'
                    : 'Modifier la catégorie'}
                </StyledButton>
              </StyledSpace>
            </StyledCol>
          </StyledRow>
        </Form>
      </StyledBlocContainer>
    );
  };

  return (
    <>
      {renderHeader()}
      <StyledDivider />
      {renderContent()}
    </>
  );
};

export default BiologicalExamCategorieForm;
