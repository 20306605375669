/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { getAllRightsFromRoleApi, updateUserRightsApi } from '../../../api/rightService';
import { getUsersFullPrivilegesApi } from '../../../api/userService';
import { StyledButton } from '../../../components/button/style';
import CustomModal from '../../../components/modal/Modal';
import { StyledInput, StyledTable } from '../../../components/style';
import {
  GetRightsResponse,
  GetRolesResponse,
  IRightItem,
  IUserItem,
  UpdateUserRightsRequestBody,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { ScrollContainer } from '../../style';
import Loader from '../../../components/loader';

type AddRemoveUserRightProps = {
  isModalVisible: boolean;
  handCloseModalVisility: () => void;
  currentUser?: IUserItem;
  rightsData: GetRightsResponse;
};

const AddRemoveUserRight: FC<AddRemoveUserRightProps> = ({
  isModalVisible,
  handCloseModalVisility,
  currentUser,
  rightsData,
}) => {
  const { notification } = AppContainer.useApp();
  const [searchFilter, setSearchFilter] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key | any>([]);
  const [rightsIdFromRoles, setRightsIdFromRoles] = useState<number[]>([]);
  const [defaultSelected, setDefaultSelected] = useState<Array<number>>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userRoles, setUserRoles] = useState<GetRolesResponse>([]);
  const [userRights, setUserRights] = useState<GetRightsResponse>([]);
  const [isLoadingPrivileges, setIsLoadingPrivileges] = useState<boolean>(false);

  const filteredItems = rightsData.filter(droit => droit?.label.includes(searchFilter));

  const itemsColumns = [{ title: 'label', dataIndex: 'label' }];

  const loadUserPrivileges = () => {
    try {
      setIsLoadingPrivileges(true);
      Promise.resolve(getUsersFullPrivilegesApi(currentUser?.id as number)).then(res => {
        setUserRoles(res.roles), setUserRights(res.rights);
      });
      setIsLoadingPrivileges(false);
    } catch (error) {
      setIsLoadingPrivileges(false);
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
    }
  };

  const loadRightsFromRoles = (rolesId: number[]) => {
    const rightsRequest: Promise<GetRightsResponse>[] = [];
    rolesId.map(roleId => {
      rightsRequest.push(getAllRightsFromRoleApi(roleId));
    });
    Promise.all(rightsRequest).then(res => {
      const rights = _.uniqBy(_.flattenDeep(res), 'id');
      setRightsIdFromRoles(rights.map(r => r.id as number));
    });
  };

  useEffect(() => {
    loadUserPrivileges();
  }, []);

  useEffect(() => {
    loadRightsFromRoles(userRoles.map(role => role.id) as Array<number>);
  }, [userRoles]);

  useEffect(() => {
    if (!_.isEmpty(userRights) && !_.isUndefined(userRights)) {
      const defaultState = _.map(userRights, 'id');
      setSelectedRowKeys(defaultState);
      setDefaultSelected(defaultState as number[]);
    }
  }, [userRights]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const itemsSelections: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: IRightItem) => ({
      disabled: rightsIdFromRoles.includes(record.id as number),
    }),
  };

  const mapDataToRequestBody = () => {
    const data = {
      idUser: currentUser?.id as number,
      idRightsToAdd: selectedRowKeys,
      idRightsToremove: defaultSelected.filter(
        el => !(selectedRowKeys as Array<number>).includes(el),
      ),
    } as UpdateUserRightsRequestBody;
    return data;
  };

  const handleUpdateUserRights = () => {
    setIsLoading(true);
    Promise.resolve(updateUserRightsApi(mapDataToRequestBody()))
      .then(() => {
        notification.success({
          message: SuccesssMsgTitle,
          description: 'Droits mis à jour avec succès!',
        });

        handCloseModalVisility();
      })
      .catch(error => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderModalSelectionList = () => {
    return (
      <Loader showSpinner={isLoadingPrivileges || isLoading}>
        <StyledTable
          rowKey='id'
          size='small'
          showHeader={false}
          bordered={false}
          dataSource={filteredItems}
          pagination={false}
          rowSelection={itemsSelections}
          columns={itemsColumns}
        />
      </Loader>
    );
  };

  return (
    <CustomModal
      isVisible={isModalVisible}
      handleVisibility={handCloseModalVisility}
      title={`Ajouter/Rétirer des droits à ${currentUser?.name} ${currentUser?.firstname}`}
      afterCloseModal={() => setSearchFilter('')}
      footer={[
        <StyledButton
          key='cancel'
          type='primary'
          onClick={() => handleUpdateUserRights()}
          loading={isLoading}
          disabled={isLoading}
          size='middle'
          block>
          Valider et Fermer
        </StyledButton>,
      ]}>
      <StyledInput
        placeholder='Rechercher un élément'
        value={searchFilter}
        onChange={e => setSearchFilter(e.target.value)}
        prefix={<FiSearch />}
      />
      <ScrollContainer style={{ height: '50vh' }}>
        <div>{renderModalSelectionList()}</div>
      </ScrollContainer>
    </CustomModal>
  );
};

export default AddRemoveUserRight;
