import { App as AppContainer, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { getReceiptStampsApi } from '../../../api/receiptStampService';
import SectionHeader from '../../../components/header/SectionHeader';
import Loader from '../../../components/loader';
import { StyledDivider } from '../../../components/style';
import {
  GetReceiptStampResponse,
  IReceiptStampItem,
  ReferentialViewProps,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle } from '../../../utils/constants';

type ReceiptStampsListProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  updateCurrentItem: (p: IReceiptStampItem) => void;
};

const ReceiptStampsList: React.FC<ReceiptStampsListProps> = () => {
  const { notification } = AppContainer.useApp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [stamps, setStamps] = useState<GetReceiptStampResponse>([]);

  const columnData: ColumnsType<IReceiptStampItem> = [
    {
      title: "Tranches d'imposition",
      render: (_value, record) => <>{`[${record.amount_min} - ${record.amount_max}]`}</>,
    },
    {
      title: 'Droits DUS',
      dataIndex: 'rigth',
    },
  ];

  const loadData = async () => {
    setIsLoading(true);
    Promise.resolve(getReceiptStampsApi())
      .then(result => {
        setStamps(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const renderHeader = () => {
    return <SectionHeader title='Tarification timbre' count={stamps.length} />;
  };

  const renderContent = () => {
    return (
      <>
        <StyledDivider />
        <Table
          rowKey='id'
          bordered={false}
          columns={columnData}
          dataSource={stamps}
          size='small'
          pagination={false}
        />
      </>
    );
  };

  return (
    <>
      {renderHeader()}
      <Loader showSpinner={isLoading}>{renderContent()}</Loader>
    </>
  );
};

export default ReceiptStampsList;
