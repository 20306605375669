import { Drawer } from 'antd';
import React, { PropsWithChildren } from 'react';
import chartColors from '../../styles/color';
import { StyledSpace } from '../style';

interface PdfDrawerViewerProps {
  isDrawerOpen: boolean;
  drawerTitle: string;
  extraActions?: React.ReactNode;
  closeDrawer: () => void;
}

const PdfDrawerViewer: React.FC<PropsWithChildren<PdfDrawerViewerProps>> = ({
  isDrawerOpen,
  drawerTitle,
  extraActions,
  closeDrawer,
  children,
}) => {
  return (
    <Drawer
      keyboard={false}
      closable={false}
      title={drawerTitle}
      destroyOnClose
      onClose={closeDrawer}
      extra={[<StyledSpace key='actions'>{extraActions}</StyledSpace>]}
      open={isDrawerOpen}
      placement='right'
      size='large'
      width={'100vw'}
      styles={{
        header: { boxShadow: '0px 5px 15px red', backgroundColor: chartColors.blue50 },
        body: { overflow: 'hidden', padding: 0 },
      }}>
      {children}
    </Drawer>
  );
};

export default PdfDrawerViewer;
