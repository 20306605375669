import { Tab } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { FiFilePlus } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { StyledButton } from '../../components/button/style';
import PageHeader from '../../components/pageHeader/PageHeader';
import { StyledSpace } from '../../components/style';
import VerticalLayout from '../../layout/VerticalLayout';
import OrdersArticles from './ordersArticles';
import OutputsArticles from './outputsArticles';
import PharmacyStock from './pharmacyStock';
import chartColors from '../../styles/color';

interface Tab {
  value: string;
  label: string;
}

const Pharmacy: React.FC = () => {
  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<number>(0);

  // useEffect(() => {
  //   if (_.isNull(state)) {
  //     setCurrentTab(0);
  //   } else {
  //     setCurrentTab(state.activeTab);
  //   }
  // }, [params]);

  const tabs: Array<Tab> = [
    { value: '', label: "Commandes d'articles" },
    { value: 'outputs', label: "Sorties d'articles" },
    { value: 'stock', label: 'Stock' },
  ];

  const handleTabChange = (value: number) => {
    setCurrentTab(value);
  };

  const renderTabsContent = () => {
    return (
      <Tab.Panels>
        <Tab.Panel>
          <OrdersArticles />
        </Tab.Panel>
        <Tab.Panel>
          <OutputsArticles handleChangeCurrentTab={handleTabChange} />
        </Tab.Panel>
        <Tab.Panel>
          <PharmacyStock />
        </Tab.Panel>
      </Tab.Panels>
    );
  };
  // const renderTabsContent = () => {
  //   switch (params.tabId) {
  //     case '' || undefined:
  //       return <OrdersArticles />;
  //     // case 'receipt':
  //     //   return <PharmacyReceipt />;
  //     case 'outputs':
  //       return <OutputsArticles />;
  //     case 'stock':
  //       return <PharmacyStock />;
  //     default:
  //       return null;
  //   }
  // };

  // const handleTabChanged = value => {
  //   if (_.isEmpty(value)) {
  //     navigate('/pharmacie');
  //   } else {
  //     if (value === 'receipt') {
  //       removeDataFromStorage(StorageType.LS, 'pharmacyOrderHead');
  //     }
  //     navigate(`/pharmacie/${value}`);
  //   }
  // };

  const renderHeaderActions = () => {
    return (
      <StyledSpace direction='horizontal'>
        <StyledButton
          type='primary'
          icon={<FiFilePlus />}
          size='middle'
          onClick={() => navigate('/pharmacie/nouvelle-commande')}>
          Nouvelle commande
        </StyledButton>
      </StyledSpace>
    );
  };

  const renderTabsHeader = () => {
    return (
      <Tab.List className='flex flex-row space-x-2 pt-2 px-5 bg-white sticky top-[72px] z-10 border-b-blue-700 border-b-4 items-end'>
        {tabs.map((tabHead, idx) => (
          <Tab as={Fragment} key={idx}>
            {({ selected }) => (
              <StyledButton
                type={selected ? 'primary' : 'text'}
                className='md:basis-1/3 rounded-none rounded-t-xl'
                shape={'default'}
                size={selected ? 'large' : 'middle'}
                style={{
                  backgroundColor: !selected ? chartColors.lightGray : '',
                }}>
                {tabHead.label}
              </StyledButton>
            )}
          </Tab>
        ))}
      </Tab.List>
    );
  };

  const renderContent = () => {
    return (
      <Tab.Group selectedIndex={currentTab} onChange={setCurrentTab}>
        {renderTabsHeader()}
        {renderTabsContent()}
      </Tab.Group>
    );
  };

  return (
    <VerticalLayout enableVerticalScroll='false'>
      <PageHeader title='Pharmacie'>{renderHeaderActions()}</PageHeader>
      {renderContent()}
    </VerticalLayout>
  );
};

export default Pharmacy;
