import styled from 'styled-components';
import packageJson from '../../../package.json';
import chartColors from '../../styles/color';
import { StyledButton } from '../button/style';
import { useNavigate } from 'react-router-dom';

const FooterLeft = styled.div``;
const FooterRight = styled.div``;

const FooterWrapper = styled.footer`
  font-size: 0.8em;
  font-family: 'Sharp Sans', sans-serif;
  height: 30px;
  width: 100%;
  background-color: ${chartColors.mainGray};
  color: ${chartColors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
`;

const Footer = () => {
  const navigate = useNavigate();
  const handleOpenCGU = () => {
    navigate('/cgu');
  };
  return (
    <FooterWrapper>
      <FooterLeft>
        {`ApiPatient build-${packageJson.version}`} - Tous droits réservés
      </FooterLeft>
      <FooterRight>
        <StyledButton ghost type='link' onClick={handleOpenCGU} className='text-white'>
          Conditions générales d&apos;utilisation
        </StyledButton>
      </FooterRight>
    </FooterWrapper>
  );
};

export default Footer;
