import { App as AppContainer, Form } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import {
  createAntecedentItemApi,
  updateAntecedentItemApi,
} from '../../../api/referentialService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import {
  StyledCol,
  StyledDivider,
  StyledInput,
  StyledRow,
  StyledSelectOption,
  StyledSelectPicker,
  StyledSpace,
} from '../../../components/style';
import {
  AntecedentCategoryType,
  CreateAntecedentRequestBody,
  IAntecedentItem,
  ReferentialViewProps,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { StyledBlocContainer } from '../../style';

import antecedentCategories from '../../../mocks/getAntecedentMock.json';

type AntecedentFormProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  currentData?: IAntecedentItem;
};

type FormPropertiesProps = {
  id: number;
  code: string;
  libelle: string;
  category: AntecedentCategoryType;
};

const AntecedentForm: React.FC<AntecedentFormProps> = ({
  navigateToview,
  currentData,
}) => {
  const { notification } = AppContainer.useApp();
  const [form] = Form.useForm<FormPropertiesProps>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const renderHeader = () => {
    return (
      <SectionHeader
        title={
          _.isUndefined(currentData) ? 'Nouvel Antécédent' : 'Modification antécédent'
        }
        onBack={() => navigateToview('LIST_ITEMS')}
      />
    );
  };

  const mapPropsToRequest = (row: FormPropertiesProps) => {
    const antecedentData: CreateAntecedentRequestBody = {
      code: row.code,
      libelle: row.libelle,
      category: row.category,
    };

    return antecedentData;
  };

  const createAntecedent = async () => {
    try {
      await form.validateFields().then(async row => {
        setIsLoading(true);
        await createAntecedentItemApi(mapPropsToRequest(row)).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `${row.libelle} a été enregistré!`,
          });
          navigateToview('LIST_ITEMS');
        });
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const editAntecedent = async () => {
    try {
      await form.validateFields().then(async row => {
        setIsLoading(true);
        await updateAntecedentItemApi(
          currentData?.id as number,
          mapPropsToRequest(row),
        ).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `L'antécédent à été modifié!`,
          });
          navigateToview('LIST_ITEMS');
        });
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    return (
      <StyledBlocContainer style={{ padding: 20 }}>
        <Form
          disabled={isLoading}
          form={form}
          component={false}
          initialValues={currentData}>
          <StyledRow gutter={[16, 16]} align='middle' justify='center'>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Code antécédent' />
              <Form.Item style={{ margin: 0 }} name='code'>
                <StyledInput size='large' placeholder='Code antécédent ' />
              </Form.Item>
            </StyledCol>

            <StyledCol span={24}>
              <FormFieldLabel libelle='Libellé antécédent' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='libelle'
                rules={[
                  { required: true, message: 'Libellé obligatoire' },
                  { whitespace: false },
                ]}>
                <StyledInput size='large' placeholder='Libellé antécédent' />
              </Form.Item>
            </StyledCol>

            <StyledCol span={24}>
              <FormFieldLabel libelle='Catégorie' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='category'
                rules={[
                  { required: true, message: 'Catégorie obligatoire' },
                  { whitespace: false },
                ]}>
                <StyledSelectPicker
                  placeholder='Sélectionnez une catégorie'
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }>
                  {_.map(antecedentCategories, item => (
                    <StyledSelectOption
                      key={item.value}
                      value={item.value}
                      label={item.label}>
                      {item.label}
                    </StyledSelectOption>
                  ))}
                </StyledSelectPicker>
              </Form.Item>
            </StyledCol>

            <StyledDivider />
            <StyledCol span={24}>
              <StyledSpace>
                <StyledButton
                  type='primary'
                  htmlType='submit'
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={
                    _.isUndefined(currentData) ? createAntecedent : editAntecedent
                  }>
                  {_.isUndefined(currentData)
                    ? "Enregistrer l'antécédent"
                    : "Modifier l'antécédent"}
                </StyledButton>
              </StyledSpace>
            </StyledCol>
          </StyledRow>
        </Form>
      </StyledBlocContainer>
    );
  };

  return (
    <>
      {renderHeader()}
      <StyledDivider />
      {renderContent()}
    </>
  );
};

export default AntecedentForm;
