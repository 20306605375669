import {
  CreateUserAbsenceRequestBody,
  CreateUserRequestBody,
  GetUsersAbsencesResponse,
  GetUsersPrivilegesResponse,
  GetUsersResponse,
  SearchUserRequestBody,
} from '../types';
import { axiosInstance } from './axiosInstance';

export const getUsersApi = (): Promise<GetUsersResponse> => {
  return axiosInstance
    .get('/users')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des users. Raison: ${message}`);
    });
};
export const getUsersLightApi = (): Promise<GetUsersResponse> => {
  return axiosInstance
    .get('/usersLight')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des users. Raison: ${message}`);
    });
};
export const getDoctorsApi = (): Promise<GetUsersResponse> => {
  return axiosInstance
    .get('/doctors')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des médecins. Raison: ${message}`);
    });
};

export const searchUsersLightApi = (
  data: SearchUserRequestBody,
): Promise<GetUsersResponse> => {
  return axiosInstance
    .post('/searchUserslight', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recherhche du user. Raison: ${message}`);
    });
};

export const createUserApi = (data: CreateUserRequestBody): Promise<string> => {
  return axiosInstance
    .post('/user', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création du user. Raison: ${message}`);
    });
};

export const updateUserApi = (
  idUser: number,
  data: CreateUserRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/user?id=${idUser}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la mise à jour du user. Raison: ${message}`);
    });
};

export const resetUserPasswordApi = (idUser: number): Promise<string> => {
  return axiosInstance
    .put(`/resetPassword?idPatient=${idUser}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la réinitialisation du mot de passe. Raison: ${message}`,
      );
    });
};

export const getUserAbsenceByIdApi = (
  idUser: number,
): Promise<GetUsersAbsencesResponse> => {
  return axiosInstance
    .get(`/userAbsenceByIdUser?idUser=${idUser}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des absences. Raison: ${message}`);
    });
};

export const createUserAbsencesApi = (
  data: CreateUserAbsenceRequestBody,
): Promise<string> => {
  return axiosInstance
    .post('/userAbsence', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création des absences. Raison: ${message}`);
    });
};

export const getUsersFullPrivilegesApi = (
  idUser: number,
): Promise<GetUsersPrivilegesResponse> => {
  return axiosInstance
    .get(`/fullPriviligesByUser?idUser=${idUser}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des privileges. Raison: ${message}`);
    });
};
