/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer, Input, InputRef, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FilterConfirmProps, FilterDropdownProps } from 'antd/es/table/interface';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FiRefreshCcw, FiSearch } from 'react-icons/fi';
import { searchHospitalizationApi } from '../../api/hospitalizationService';
import { getPatients } from '../../api/patientService';
import { StyledButton } from '../../components/button/style';
import FormFieldLabel from '../../components/label/FormFieldLabel';
import PageHeader from '../../components/pageHeader/PageHeader';
import {
  StyledCol,
  StyledDatePicker,
  StyledRow,
  StyledSelectOption,
  StyledSelectPicker,
  StyledSpace,
  StyledTag,
} from '../../components/style';
import dayjsInstance from '../../dayjs';
import PageContentLeftLayout from '../../layout/PageContentLeftLayout';
import PageContentRightLayout from '../../layout/PageContentRightLayout';
import VerticalLayout from '../../layout/VerticalLayout';
import { StyledLayoutGridWrapper } from '../../layout/style';
import chartColors from '../../styles/color';
import {
  PatientDto,
  SearchHospitalizationLine,
  SearchHospitalizationsRequestBody,
  SearchHospitalizationsResponse,
} from '../../types';
import {
  getApiErrorMsg,
  getColumnSearchProps,
  mapStateToRequestBody,
} from '../../utils/commun';
import { DATE_FORMAT, ErrorMsgTitle } from '../../utils/constants';
import { StyledBlocContainer } from '../style';

type DataIndex = keyof SearchHospitalizationLine;

type DataProps = {
  hospitalizations: SearchHospitalizationsResponse;
  patientsList: Array<PatientDto>;
  searchText: string;
  searchedColumn: string;
};
const Hospitalization: React.FC = () => {
  const { notification } = AppContainer.useApp();

  const [isLoading, setIsLoading] = useState({
    patients: false,
    search: false,
  });

  const [searchFilters, setSearchFilters] = useState({
    creationDate: [
      dayjsInstance().subtract(1, 'days').format(DATE_FORMAT),
      dayjsInstance().format(DATE_FORMAT),
    ],
    patient: null,
  });

  const [data, setData] = useState<DataProps>({
    hospitalizations: [],
    patientsList: [],
    searchText: '',
    searchedColumn: '',
  });

  const searchInput = useRef<InputRef>(null);

  const loadPatients = () => {
    setIsLoading(c => ({ ...c, patients: true }));
    Promise.resolve(getPatients())
      .then(result => {
        setData(c => ({ ...c, patientsList: result }));
        setIsLoading(c => ({ ...c, patients: false }));
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
        setIsLoading(c => ({ ...c, patients: false }));
      });
  };

  useEffect(() => {
    loadPatients();
  }, []);

  const handleChangeFilter = (name: string, value: any) => {
    setSearchFilters(c => ({ ...c, [name]: value }));
  };

  const resetFilters = () => {
    setSearchFilters({
      creationDate: [
        dayjsInstance().subtract(1, 'days').format(DATE_FORMAT),
        dayjsInstance().format(DATE_FORMAT),
      ],
      patient: null,
    });
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setData(c => ({ ...c, searchText: '' }));
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setData(c => ({ ...c, searchText: selectedKeys[0], searchedColumn: dataIndex }));
  };

  const handleSearchHospitalizations = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(c => ({ ...c, search: true }));
    searchHospitalizationApi(
      mapStateToRequestBody<SearchHospitalizationsRequestBody>({
        dateDebut: searchFilters.creationDate[0],
        dateEnd: searchFilters.creationDate[1],
        idPatient: searchFilters.patient,
      }),
    ).then(result => {
      setData(c => ({ ...c, hospitalizations: result }));
      setIsLoading(c => ({ ...c, search: false }));
    });
  };

  const renderHeader = () => {
    return <PageHeader title='Hospitalisation' />;
  };

  const renderSearchFilters = () => {
    return (
      <StyledBlocContainer
        style={{
          backgroundColor: `${chartColors.blue50}20`,
          padding: '20px 20px',
        }}>
        <form onSubmit={handleSearchHospitalizations}>
          <StyledRow gutter={[12, 12]}>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Date de création' />
              <StyledDatePicker.RangePicker
                name='creationDate'
                placeholder={['Date de debut', 'Date de fin']}
                size='large'
                allowClear
                style={{ width: '100%' }}
                onChange={(_value, dateString) => {
                  handleChangeFilter('creationDate', dateString);
                }}
                value={[
                  _.isEmpty(searchFilters.creationDate[0])
                    ? null
                    : dayjsInstance(searchFilters.creationDate[0], DATE_FORMAT),
                  _.isEmpty(searchFilters.creationDate[1])
                    ? null
                    : dayjsInstance(searchFilters.creationDate[1], DATE_FORMAT),
                ]}
                // disabledDate={current => dayjsInstance().isBefore(current, 'day')}
                format={DATE_FORMAT}
              />
            </StyledCol>

            <StyledCol span={24}>
              <FormFieldLabel libelle='Patient' />
              <StyledSelectPicker
                disabled={isLoading.patients}
                placeholder='Rechercher par nom, CNI ou Numéro patient'
                onClear={() => handleChangeFilter('patient', null)}
                style={{ width: '100%' }}
                allowClear
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                onChange={(value: any) => {
                  handleChangeFilter('patient', value);
                }}
                defaultValue={''}
                value={searchFilters.patient}>
                {_.map(data.patientsList, item => (
                  <StyledSelectOption
                    key={item.id}
                    value={item.id}
                    data={item}
                    label={`${item.firstname} ${item.name} | ${item.cni} | ${item.numPatient}`}>
                    <StyledSpace split='|'>
                      <span style={{ fontWeight: 700 }}>{item.numPatient}</span>
                      {`${item.firstname} ${item.name}`}
                      <span style={{ fontWeight: 700 }}>{item.cni}</span>
                    </StyledSpace>
                  </StyledSelectOption>
                ))}
              </StyledSelectPicker>
            </StyledCol>

            <StyledCol span={24}>
              <StyledSpace style={{ width: '100%' }}>
                <StyledButton
                  type='primary'
                  size='middle'
                  htmlType='submit'
                  block
                  disabled={isLoading.patients || isLoading.search}>
                  Rechercher
                </StyledButton>
                <StyledButton
                  type='link'
                  htmlType='reset'
                  size='middle'
                  disabled={isLoading.patients || isLoading.search}
                  icon={<FiRefreshCcw />}
                  onClick={() => resetFilters()}
                />
              </StyledSpace>
            </StyledCol>
          </StyledRow>
        </form>
      </StyledBlocContainer>
    );
  };

  const columnFilterComponent = (
    { selectedKeys, setSelectedKeys, confirm, clearFilters }: FilterDropdownProps,
    dataIndex: DataIndex,
    placeholder: string,
  ) => {
    return (
      <>
        <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Rechercher ${placeholder}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: 'block' }}
          />
          <StyledSpace>
            <StyledButton
              type='primary'
              onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
              icon={<FiSearch />}
              size='small'
              block>
              Rechercher
            </StyledButton>
            <StyledButton
              onClick={() => clearFilters && handleReset(clearFilters)}
              size='small'
              htmlType='reset'
              style={{ width: 90 }}>
              Vider
            </StyledButton>
          </StyledSpace>
        </div>
      </>
    );
  };

  const columnData: ColumnsType<SearchHospitalizationLine> = [
    {
      title: 'Date Hospitalisation',
      dataIndex: 'createdDate',
    },
    {
      title: 'N° Hospitalisation',
      dataIndex: 'numHospit',
      ...getColumnSearchProps({
        dataIndex: 'numHospit',
        filterDropdownComponent: filterProps =>
          columnFilterComponent(filterProps, 'numHospit', "Numéro d'hospitalisation"),
      }),
    },
    {
      title: 'N° Dossier',
      dataIndex: 'numMedicalRecord',
      width: 150,
      ...getColumnSearchProps({
        dataIndex: 'numMedicalRecord',
        filterDropdownComponent: filterProps =>
          columnFilterComponent(filterProps, 'numMedicalRecord', 'Numéro de dossier'),
      }),
    },
    {
      title: 'Statut',
      dataIndex: 'status',
      align: 'left',
      width: 100,
      render: value => (
        <>
          {value === true ? (
            <StyledTag color='warning'>Payée</StyledTag>
          ) : value === true ? (
            <StyledTag color='success'>Validé</StyledTag>
          ) : (
            <StyledTag color='volcano'>Non traité</StyledTag>
          )}
        </>
      ),
    },
    // {
    //   render: (_value, record) => (
    //     <StyledSpace>
    //       <StyledDropdown
    //         menu={{
    //           items: getMenuItems(record),
    //           onClick: e => handleMenuClick(e, record),
    //         }}
    //         trigger={['click', 'hover']}>
    //         <StyledButton icon={<BsThreeDots />} shape='circle' />
    //       </StyledDropdown>
    //     </StyledSpace>
    //   ),
    // },
  ];

  const renderContent = () => {
    return (
      <>
        <Table<SearchHospitalizationLine>
          rowKey='id'
          dataSource={data.hospitalizations}
          size='small'
          columns={columnData}
          bordered={false}
          loading={isLoading.search}
          // rowSelection={facturationRowSelection}
        />
      </>
    );
  };

  return (
    <VerticalLayout enableVerticalScroll='false'>
      {renderHeader()}
      <StyledLayoutGridWrapper>
        <PageContentLeftLayout layerShowPadding='false'>
          {renderSearchFilters()}
        </PageContentLeftLayout>
        <PageContentRightLayout layerShowPadding='false'>
          <div style={{ overflowY: 'auto' }}>{renderContent()}</div>
        </PageContentRightLayout>
      </StyledLayoutGridWrapper>
    </VerticalLayout>
  );
};

export default Hospitalization;
