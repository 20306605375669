import {
  CreateRoleRequestBody,
  GetRolesResponse,
  UpdateUserRolesRequestBody,
} from '../types';
import { axiosInstance } from './axiosInstance';

export const getRolesApi = (): Promise<GetRolesResponse> => {
  return axiosInstance
    .get('/roles')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des roles. Raison: ${message}`);
    });
};

export const createRoleApi = (data: CreateRoleRequestBody): Promise<string> => {
  return axiosInstance
    .post('/role', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création du role. Raison: ${message}`);
    });
};

export const updateRoleApi = (
  idRole: number,
  data: CreateRoleRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/role?id=${idRole}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la mise à jour du role. Raison: ${message}`);
    });
};

export const deleteRoleApi = (idRole: number): Promise<string> => {
  return axiosInstance
    .delete(`/role?id=${idRole}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la suppression du role. Raison: ${message}`);
    });
};

export const updateUserRolesApi = (data: UpdateUserRolesRequestBody): Promise<string> => {
  return axiosInstance
    .post('/updateRoleToUser', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la mise à jour des roles de l'utilisateur. Raison: ${message}`,
      );
    });
};
