import { App as AppContainer, Form, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import React, { useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import {
  searchBiologicalPrescriptionsApi,
  updateBiologicalPrescriptionLineApi,
} from '../../api/prescriptionService';
import { StyledButton } from '../../components/button/style';
import StyledDrawer from '../../components/drawer/StyledDrawer';
import SectionHeader from '../../components/header/SectionHeader';
import FormFieldLabel from '../../components/label/FormFieldLabel';
import {
  StyledCheckbox,
  StyledCol,
  StyledDivider,
  StyledInput,
  StyledInputTextArea,
  StyledRow,
  StyledSpace,
  StyledTag,
} from '../../components/style';
import chartColors from '../../styles/color';
import {
  BiologicalPrescriptionLineDto,
  CreateMedicalRecordApiResponse,
  IBiologicalPrescriptionItem,
  SearchBiologicalPrescriptionResponse,
} from '../../types';
import { getApiErrorMsg } from '../../utils/commun';
import { ErrorMsgTitle } from '../../utils/constants';
import _ from 'lodash';

type BiologicPrescriptionsProps = IBiologicalPrescriptionItem;

interface InternalInvoiceProps {
  selectedPatientId: number | null;
  selectedMedicalRecord: CreateMedicalRecordApiResponse | null;
}

type PrescriptionFormProps = { comments: string; result: string; done: boolean };

/**
 * Analyses Biologiques
 * @param param0
 * @returns
 */
const InternalAnalysis: React.FC<InternalInvoiceProps> = ({
  selectedMedicalRecord,
  selectedPatientId,
}) => {
  const [form] = Form.useForm<PrescriptionFormProps>();
  const { notification } = AppContainer.useApp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedPrescriptionLine, setSelectedPrescriptionLine] =
    useState<BiologicalPrescriptionLineDto>();
  const [showResultDrawer, setShowResultDrawer] = useState<boolean>(false);

  const [biologicalPrescriptionsData, setBiologicalPrescriptionsData] =
    useState<SearchBiologicalPrescriptionResponse>([]);

  /**
   * The function `validatePrescriptionLineResult` is an asynchronous function that validates a
   * prescription line and updates it with the provided data if validation is successful.
   * @param {BiologicalPrescriptionLineDto} prescriptionLine - The `prescriptionLine` parameter is an
   * object of type `BiologicalPrescriptionLineDto`. It contains the following properties:
   */
  const validatePrescriptionLineResult = async () => {
    try {
      await form.validateFields().then(async row => {
        const prescriptionData = {
          result: row.result,
          done: row.done,
          quantity: selectedPrescriptionLine?.quantity as number,
          idBiologicalExam: selectedPrescriptionLine?.idBiologicalExam as number,
          biologicalExamDesignation:
            selectedPrescriptionLine?.biologicalExamDesignation as string,
          comments: row.comments,
        };

        setIsLoading(true);
        Promise.resolve(
          updateBiologicalPrescriptionLineApi(
            selectedPrescriptionLine?.id as number,
            prescriptionData,
          ),
        ).then(() => {
          loadPatientBiologicalPrescriptions();
          notification.success({
            message: 'Mise à jour effectuée',
            description: `${selectedPrescriptionLine?.biologicalExamDesignation} a été mis à jour!`,
          });
          setShowResultDrawer(false);
        });
      });
    } catch (error) {
      notification.error({
        message: "Une erreur s'est produite",
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * The function `handleEditPrescriptionLine` sets the form fields values and the editing key based on
   * the provided record.
   * @param record - The `record` parameter is of type `Partial<BiologicalPrescriptionLineDto>`. It
   * represents a partial object of type `BiologicalPrescriptionLineDto`, which is used to populate the
   * form fields for editing a prescription line.
   */
  const handleEditPrescriptionLine = (record: BiologicalPrescriptionLineDto) => {
    form.resetFields(['result', 'done', 'comments']);
    setSelectedPrescriptionLine(record);
    setShowResultDrawer(true);
  };

  const handleModalResult = () => {
    setShowResultDrawer(false);
    form.resetFields(['result', 'done', 'comments']);
    setSelectedPrescriptionLine(undefined);
  };

  /**
   * The function `cancelEditPrescriptionLine` resets the form fields to their initial values.
   */

  /* The `biologicalPrescriptioncolumnData` constant is an array of objects that defines the columns
  for a table in the `InternalAnalysis` component. Each object represents a column and contains
  properties such as `title`, `dataIndex`, and `render`. */
  const biologicalPrescriptioncolumnData: ColumnsType<BiologicPrescriptionsProps> = [
    {
      title: 'Numéro',
      dataIndex: 'numMedicalRecord',
      render: (_value, record, index) => (
        <StyledSpace>
          <span
            style={{
              fontFamily: 'Metropolis',
              textTransform: 'uppercase',
              color: chartColors.blue,
              fontWeight: 700,
            }}>
            Prescription {`#${index + 1}`}
          </span>
          <StyledTag color={chartColors.blue}>Créé le {record.createdDate}</StyledTag>
        </StyledSpace>
      ),
    },
  ];

  /* The above code is defining a column configuration for a table in a TypeScript React application.
  The table is used to display biological exam data. The columns include the exam name, norm, unit,
  result, whether the exam is done or not, and actions. The "result" column has a custom render
  function that displays an input field for editing the result if the record is editable, otherwise
  it displays a read-only input field. The "done" column has a custom render function that displays
  a tag indicating whether the exam is done or not. The "actions" column has buttons for editing and
  canceling */
  const biologicalExamscolumn: ColumnsType<BiologicalPrescriptionLineDto> = [
    {
      title: 'Examen',
      dataIndex: 'biologicalExamDesignation',
    },
    {
      title: 'Unité',
      dataIndex: 'usualUnit',
      width: 30,
      align: 'center',
    },
    {
      title: 'Norme',
      dataIndex: 'norm',
      width: 150,
    },

    {
      title: 'Résultat',
      dataIndex: 'result',
      width: '35%',
      render: (value, record) => {
        return (
          <StyledInput
            readOnly={true}
            disabled={true}
            placeholder={`Résultat ${record.biologicalExamDesignation}`}
            value={value}
          />
        );
      },
    },
    {
      title: 'Statut',
      align: 'center',
      width: 60,
      render: (value, record) => {
        return record.done ? (
          <StyledTag color='success'>Effectuée</StyledTag>
        ) : (
          <StyledTag color='warning'>En attente</StyledTag>
        );
      },
    },
    {
      title: 'Actions',
      align: 'left',
      width: 100,
      render: (_value, record) => {
        return (
          <StyledSpace>
            <Tooltip title='Mettre à jour les résultats'>
              <StyledButton
                icon={<FiEdit />}
                shape='circle'
                size='middle'
                onClick={() => handleEditPrescriptionLine(record)}
              />
            </Tooltip>
          </StyledSpace>
        );
      },
    },
  ];

  /**
   * The function `loadPatientBiologicalPrescriptions` is an asynchronous function that loads
   * biological prescriptions data for a selected patient and medical record.
   */
  const loadPatientBiologicalPrescriptions = async () => {
    setIsLoading(true);
    Promise.resolve(
      searchBiologicalPrescriptionsApi({
        idMedicalRecord: selectedMedicalRecord?.id as number,
        idPatient: selectedPatientId as number,
        paid: true,
      }),
    )
      .then(result => {
        setBiologicalPrescriptionsData(result);
        setIsLoading(false);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!_.isNull(selectedMedicalRecord) && !_.isNull(selectedPatientId)) {
      loadPatientBiologicalPrescriptions();
    }
  }, [selectedMedicalRecord, selectedPatientId]);

  /* The above code is rendering a section for displaying biological prescriptions. It includes a table
that shows the list of biological prescriptions along with their details. Each prescription can be
expanded to show additional information about the prescription lines. There is also a button to mark
selected prescriptions as completed, but it is currently commented out. */
  const renderBiologicalPrescriptions = () => {
    return (
      <div style={{ padding: '0px 20px' }}>
        <StyledRow gutter={[20, 20]}>
          <StyledCol span={24}>
            <SectionHeader
              title='prescription(s) Biologique'
              count={biologicalPrescriptionsData.length}
            />
            <Table
              rowKey='id'
              showHeader={false}
              dataSource={biologicalPrescriptionsData}
              size='small'
              columns={biologicalPrescriptioncolumnData}
              pagination={false}
              bordered={false}
              loading={isLoading}
              expandable={{
                expandedRowRender: record => (
                  <Table
                    rowKey='id'
                    bordered={false}
                    pagination={false}
                    columns={biologicalExamscolumn}
                    dataSource={record.prescriptionLineBiologicalDto}
                  />
                ),
                defaultExpandAllRows: true,
              }}
            />
          </StyledCol>
        </StyledRow>
      </div>
    );
  };

  /**
   * The function `renderContent` checks if a medical record is selected and returns either an empty
   * message or the result of rendering biological prescriptions.
   * @returns The function `renderContent` returns either an `<EmptyNotSelected>` component with
   * specific props if `selectedMedicalRecord` is null or undefined, or it returns the result of
   * calling the `renderBiologicalPrescriptions` function.
   */
  const renderContent = () => {
    return renderBiologicalPrescriptions();
  };

  const renderModalResult = () => {
    return (
      <StyledDrawer
        drawerTitle={`Résultat - ${selectedPrescriptionLine?.biologicalExamDesignation}`}
        isDrawerOpen={showResultDrawer}
        closeDrawer={handleModalResult}
        placement='right'
        extraActions={
          <>
            <StyledButton
              key='cancel_invoice'
              type='primary'
              size='middle'
              danger
              disabled={isLoading}
              onClick={handleModalResult}>
              Fermer
            </StyledButton>
          </>
        }>
        <Form form={form} component={false}>
          <StyledRow gutter={[16, 16]} style={{ padding: 30 }}>
            <StyledCol span={24}>
              <StyledSpace>
                <Form.Item
                  name='done'
                  valuePropName='checked'
                  style={{ margin: 0 }}
                  initialValue={selectedPrescriptionLine?.done}>
                  <StyledCheckbox>Marquer comme Effectué</StyledCheckbox>
                </Form.Item>
              </StyledSpace>
            </StyledCol>
            <StyledCol span={24}>
              <FormFieldLabel libelle="Résultat de l'examen" />
              <Form.Item
                style={{ margin: 0 }}
                name='result'
                initialValue={selectedPrescriptionLine?.result}>
                <StyledInputTextArea placeholder='Ecrivez le résultat' rows={4} />
              </Form.Item>
            </StyledCol>

            <StyledCol span={24}>
              <FormFieldLabel libelle='Commentaire' />
              <Form.Item
                style={{ margin: 0 }}
                name='comments'
                initialValue={selectedPrescriptionLine?.comments}>
                <StyledInputTextArea placeholder='Ecrivez un commentaire' rows={4} />
              </Form.Item>
            </StyledCol>
            {/* <StyledCol span={24}>
              <FormFieldLabel libelle='Joindre un fichier' />
              <div style={{ marginBottom: 10 }}>
                <Form.Item name='done'>
                  <StyledUploader
                    callback={uploadedFiles => {
                      console.log(uploadedFiles);
                    }}
                  />
                </Form.Item>
              </div>
            </StyledCol> */}
            <StyledDivider />
            <StyledCol span={24}>
              <StyledButton
                type='primary'
                loading={isLoading}
                disabled={isLoading}
                onClick={() => validatePrescriptionLineResult()}>
                Enregistrer le résultat
              </StyledButton>
            </StyledCol>
          </StyledRow>
        </Form>
      </StyledDrawer>
    );
  };

  return (
    <>
      <div style={{ padding: '0px' }}>{renderContent()}</div>
      {showResultDrawer && renderModalResult()}
    </>
  );
};

export default InternalAnalysis;
