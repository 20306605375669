import { CreateRoomRequestBody, GetRoomsResponse } from '../types';
import { axiosInstance } from './axiosInstance';

export const getRoomsApi = (): Promise<GetRoomsResponse> => {
  return axiosInstance
    .get('/rooms')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des chambres. Raison: ${message}`);
    });
};

export const createRoomApi = (data: CreateRoomRequestBody): Promise<string> => {
  return axiosInstance
    .post('/room', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création de la chambre. Raison: ${message}`);
    });
};

export const updateRoomApi = (
  idRoom: number,
  data: CreateRoomRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/room?id=${idRoom}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la mise à jour de la chambre. Raison: ${message}`);
    });
};

export const deleteRoomApi = (idRoom: number): Promise<string> => {
  return axiosInstance
    .delete(`/room?id=${idRoom}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la suppression de la chambre. Raison: ${message}`);
    });
};
