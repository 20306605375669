import { App as AppContainer, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { BsExclamationTriangleFill, BsPlus } from 'react-icons/bs';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { deleteArticleApi, getArticlesApi } from '../../../api/referentialService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import Loader from '../../../components/loader';
import { StyledDivider, StyledSpace } from '../../../components/style';
import chartColors from '../../../styles/color';
import { GetArticlesResponse, IArticleItem, ReferentialViewProps } from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import XLSX from 'xlsx';
import {
  ErrorDeleteMsg,
  ErrorMsgTitle,
  SuccesssMsgTitle,
} from '../../../utils/constants';
import { AiFillFileExcel } from 'react-icons/ai';
import _ from 'lodash';
import dayjsInstance from '../../../dayjs';

type ArticlesListProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  updateCurrentItem: (p: IArticleItem) => void;
};

const xlsxFileHeader = [
  'Reference',
  'Designation',
  'Stock',
  'Nom du fournisseur',
  'Email du fournisseur',
  'Téléphone du fournisseur',
  'Adresse du fournisseur',
];

const ArticlesList: React.FC<ArticlesListProps> = ({
  navigateToview,
  updateCurrentItem,
}) => {
  const { notification, modal } = AppContainer.useApp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [articles, setArticles] = useState<GetArticlesResponse>([]);

  const handleUpdate = (item: IArticleItem) => {
    updateCurrentItem(item);
    navigateToview('EDIT_ITEM');
  };

  const columnData: ColumnsType<IArticleItem> = [
    {
      title: 'Référence',
      dataIndex: 'reference',
      width: 150,
    },
    {
      title: 'Désignation',
      dataIndex: 'designation',
    },
    {
      title: 'Fournisseur',
      dataIndex: ['supplier', 'name'],
    },
    {
      title: 'Stock',
      dataIndex: 'stock',
    },
    {
      title: 'Montant',
      dataIndex: 'amount',
      align: 'right',
    },
    {
      title: 'Actions',
      align: 'right',
      render: (_value, record) => {
        return (
          <StyledSpace>
            <StyledButton
              shape='circle'
              size='middle'
              icon={<FiEdit />}
              onClick={() => handleUpdate(record)}
            />
            <StyledButton
              shape='circle'
              size='middle'
              danger
              icon={<FiTrash2 />}
              onClick={() => handleRemove(record)}
            />
          </StyledSpace>
        );
      },
    },
  ];

  const loadData = async () => {
    setIsLoading(true);
    Promise.resolve(getArticlesApi())
      .then(result => {
        setArticles(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteItem = async (item: IArticleItem) => {
    try {
      setIsLoading(true);
      Promise.resolve(
        deleteArticleApi(item.id as number).then(() => {
          loadData();
          notification.success({
            message: SuccesssMsgTitle,
            description: `Le consommable a été supprimé`,
          });
        }),
      );
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(ErrorDeleteMsg),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemove = (item: IArticleItem) => {
    modal.confirm({
      title: 'Confirmation de suppression',
      icon: <BsExclamationTriangleFill size={40} color={chartColors.warning} />,
      content: `Êtes-vous sûr de vouloir supprimer le consommable ${item.designation} de manière permanente ?`,
      okText: 'Oui, je Confirme',
      cancelText: 'Annuler',
      onOk: () => deleteItem(item),
    });
  };

  const handleExportToXls = () => {
    const rows = articles.map(articleRow => ({
      reference: articleRow.reference,
      designation: articleRow.designation,
      stock: articleRow.stock,
      supplierName: articleRow.supplier.name,
      supplierEmail: articleRow.supplier.email,
      supplierTel: articleRow.supplier.tel,
      supplierAddress: articleRow.supplier.address,
    }));

    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Dates');
    XLSX.utils.sheet_add_aoa(worksheet, [xlsxFileHeader], { origin: 'A1' });
    const maxWidth = rows.reduce((w, r) => Math.max(w, r.supplierEmail.length), 20);
    worksheet['!cols'] = [{ wch: maxWidth }];

    XLSX.writeFile(
      workbook,
      `Etat_Articles_${dayjsInstance().format('DD_MM_YYYY_HH_mm_ss')}.xlsx`,
      { compression: true },
    );
  };

  useEffect(() => {
    loadData();
  }, []);

  const renderHeader = () => {
    return (
      <SectionHeader title='Consommable' count={articles.length}>
        <StyledButton
          icon={<BsPlus />}
          type='primary'
          onClick={() => navigateToview('NEW_ITEM')}>
          Ajouter un consommable
        </StyledButton>
        <StyledButton
          type='dashed'
          icon={<AiFillFileExcel />}
          disabled={_.isEmpty(articles)}
          onClick={_.isEmpty(articles) ? undefined : handleExportToXls}>
          Exporter en Excel
        </StyledButton>
      </SectionHeader>
    );
  };

  const renderContent = () => {
    return (
      <>
        <StyledDivider />
        <Table
          rowKey='id'
          bordered={false}
          columns={columnData}
          dataSource={articles}
          size='small'
          pagination={false}
        />
      </>
    );
  };

  return (
    <>
      {renderHeader()}
      <Loader showSpinner={isLoading}>{renderContent()}</Loader>
    </>
  );
};

export default ArticlesList;
