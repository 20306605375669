import { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import dayjsInstance from '../../dayjs';
import { TimeSlotProps } from '../../types';
import { generateTimesIntervals, timeToMinutes } from '../../utils/commun';
import TimeSlot from './TimeSlot';
import {
  TimeSlotCalendarDayContainer,
  TimeSlotCalendarDayHeader,
  TimeSlotCalendarDayHeaderDate,
  TimeSlotCalendarDayHeaderTitle,
  TimeSlotTimeContainer,
} from './style';
import { DATE_FORMAT } from '../../utils/constants';

type CalendarDayProps = {
  day: string;
  dateIndex: number;
  dayDates: Dayjs[];
  handleUpdateTimeSlots: (timeSlot: TimeSlotProps) => void;
  selectedTimeSlots: Array<TimeSlotProps>;
};

const CalendarDay: React.FC<CalendarDayProps> = ({
  day,
  dateIndex,
  dayDates,
  handleUpdateTimeSlots,
  selectedTimeSlots,
}) => {
  const checkIsTimeslotDisabled = (hour: string) => {
    return (
      dayjsInstance().diff(dayDates[dateIndex], 'day') > 0 ||
      (dayjsInstance().isSame(dayDates[dateIndex], 'day') &&
        timeToMinutes(dayjsInstance().format('HH:mm')) > timeToMinutes(hour))
    );
  };

  const checkIsTimeslotSelected = (timeslot: TimeSlotProps) => {
    return _.findIndex(selectedTimeSlots, {
      date: timeslot.date,
      hour: timeslot.hour,
    }) != -1
      ? true
      : false;
  };

  return (
    <TimeSlotCalendarDayContainer>
      <TimeSlotCalendarDayHeader>
        <TimeSlotCalendarDayHeaderTitle>
          {`${day.charAt(0).toUpperCase()}${day.slice(1)} `}
        </TimeSlotCalendarDayHeaderTitle>
        <TimeSlotCalendarDayHeaderDate>
          {`${!_.isUndefined(dayDates) && dayDates[dateIndex]?.format('DD MMM')}`}
        </TimeSlotCalendarDayHeaderDate>
      </TimeSlotCalendarDayHeader>
      <TimeSlotTimeContainer>
        {generateTimesIntervals().map(hour => (
          <TimeSlot
            key={day.concat(hour.toString())}
            hour={hour}
            isDisabled={checkIsTimeslotDisabled(hour)}
            isSelected={checkIsTimeslotSelected({
              date: dayDates[dateIndex]?.format(DATE_FORMAT),
              hour: hour,
            })}
            handleSelectTime={() =>
              handleUpdateTimeSlots({
                date: dayDates[dateIndex].format(DATE_FORMAT),
                hour: hour,
              })
            }
          />
        ))}
      </TimeSlotTimeContainer>
    </TimeSlotCalendarDayContainer>
  );
};

export default CalendarDay;
