import { MessageWrapper } from './style';

interface SuccessOrErrorMessageProps {
  text: string;
  type: 'success' | 'error';
}
const SuccessOrErrorMessage: React.FC<SuccessOrErrorMessageProps> = ({ type, text }) => {
  return <MessageWrapper msgTpe={type}>{text}</MessageWrapper>;
};

export default SuccessOrErrorMessage;
