import { App as AppContainer, Input, InputRef, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  FilterConfirmProps,
  FilterDropdownProps,
  TableRowSelection,
} from 'antd/es/table/interface';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { AiFillFileExcel } from 'react-icons/ai';
import { BsCashCoin, BsExclamationTriangleFill, BsPrinterFill } from 'react-icons/bs';
import { FiRefreshCcw, FiSearch } from 'react-icons/fi';
import XLSX from 'xlsx';
import { collectCashInApi, searchInvoiceApi } from '../../api/invoiceService';
import { StyledButton } from '../../components/button/style';
import CustomInput from '../../components/input/CustomInput';
import FormFieldLabel from '../../components/label/FormFieldLabel';
import Loader from '../../components/loader';
import PageHeader from '../../components/pageHeader/PageHeader';
import {
  StyledCheckbox,
  StyledCol,
  StyledDatePicker,
  StyledRow,
  StyledSpace,
  StyledTag,
  StyledTooltip,
} from '../../components/style';
import PdfDrawerViewer from '../../components/viewer/PdfDrawerViewer';
import dayjsInstance from '../../dayjs';
import PageContentLeftLayout from '../../layout/PageContentLeftLayout';
import PageContentRightLayout from '../../layout/PageContentRightLayout';
import VerticalLayout from '../../layout/VerticalLayout';
import { StyledLayoutGridWrapper } from '../../layout/style';
import chartColors from '../../styles/color';
import { SearchInvoiceApiRequestBody, SearchInvoiceApiResponse } from '../../types';
import {
  getApiErrorMsg,
  getColumnSearchProps,
  thousandSeparatorFormat,
} from '../../utils/commun';
import { DATE_FORMAT, ErrorMsgTitle, SuccesssMsgTitle } from '../../utils/constants';
import RenderDocument from '../document';
import { StyleListContainer, StyledBlocContainer } from '../style';

interface CashboxProps extends SearchInvoiceApiResponse {
  super();
}

const xlsxFileHeader = [
  'No Facture',
  'No Dossier',
  'Nom du patient',
  'Prénoms du patient',
  'Total Assurance',
  'Total Patient',
  'Montant Total ',
  'Date de création',
  'Statut facture',
  'Date de paiement',
];

type DataIndex = keyof CashboxProps;

const Cashbox: React.FC = () => {
  const { modal, notification } = AppContainer.useApp();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedInvoicesRow, setSelectedInvoicesRow] = useState<CashboxProps[]>([]);
  const [, setSearchText] = useState('');
  const [, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [invoiceData, setInvoiceData] = useState<CashboxProps[]>([]);

  const [dateDebut, setDateDebut] = useState<string>(
    dayjsInstance().subtract(1, 'days').format(DATE_FORMAT),
  );
  const [dateEnd, setDateEnd] = useState<string>(
    dayjsInstance().add(1, 'days').format(DATE_FORMAT),
  );
  const [paymentDateStart, setPaymentDateStart] = useState<string>('');
  const [paymentDateEnd, setPaymentDateEnd] = useState<string>('');
  const [numInvoice, setNumInvoice] = useState<string | null>(null);
  const [isPaid, setIsPaid] = useState<boolean>(false);

  const [totalTVA, setTotalAssu] = useState(0);
  const [totalHT, setTotalPatient] = useState(0);
  const [totalTTC, setTotalTTC] = useState(0);

  const [showPdfViewer, setShowPdfViewer] = useState<boolean>(false);
  const [isDuplicata] = useState<boolean>(false);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  /**
   * Reinitialiser le filtre et la recherche pour une colonne
   * @param clearFilters () => void
   */
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  /**
   *
   * @param param0 FilterDropdownProps
   * @param dataIndex DataIndex
   * @param placeholder string
   * @returns
   */
  const columnFilterComponent = (
    { selectedKeys, setSelectedKeys, confirm, clearFilters }: FilterDropdownProps,
    dataIndex: DataIndex,
    placeholder: string,
  ) => {
    return (
      <>
        <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Rechercher ${placeholder}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: 'block' }}
          />
          <StyledSpace>
            <StyledButton
              type='primary'
              onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
              icon={<FiSearch />}
              size='small'
              block>
              Rechercher
            </StyledButton>
            <StyledButton
              onClick={() => clearFilters && handleReset(clearFilters)}
              size='small'
              htmlType='reset'
              style={{ width: 90 }}>
              Vider
            </StyledButton>
          </StyledSpace>
        </div>
      </>
    );
  };

  const onSelectFacturationChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: CashboxProps[],
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedInvoicesRow(selectedRows);
  };

  const handleValidateCashIn = async () => {
    try {
      setIsLoading(true);
      await collectCashInApi(selectedInvoicesRow[0]?.id as number).then(() => {
        notification.success({
          message: SuccesssMsgTitle,
          description: 'La facture a bien été encaissée',
        });
      });
      resetSearchInvoice();
    } catch (e) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmValidateCashIn = () => {
    modal.confirm({
      title: "Confirmation d'encaissement",
      icon: <BsExclamationTriangleFill size={40} color={chartColors.warning} />,
      content: `Voulez-vous encaisser cette facture (${selectedInvoicesRow[0].numProforma})? `,
      okText: 'Oui, je Confirme',
      cancelText: 'Annuler',
      onOk: () => handleValidateCashIn(),
    });
  };

  const handleExportToXls = () => {
    const rows = invoiceData.map(invoiceRow => ({
      invoiceNumber: invoiceRow.numInvoice,
      numMedicalRecord: invoiceRow.numMedicalRecord,
      patientLastname: invoiceRow.patientName,
      patientFirstname: invoiceRow.patientFirstname,
      amountAssu: invoiceRow.amountAssu,
      amountPatient: invoiceRow.amountPatient,
      amountTotal: invoiceRow.amountTotal,
      createdDate: invoiceRow.createdDate,
      status: _.isNull(invoiceRow.datePayment) ? 'En Attente' : 'Encaissé',
      paymentDate: invoiceRow.datePayment,
    }));

    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Dates');
    XLSX.utils.sheet_add_aoa(worksheet, [xlsxFileHeader], { origin: 'A1' });
    const maxWidth = rows.reduce((w, r) => Math.max(w, r.patientFirstname.length), 20);
    worksheet['!cols'] = [{ wch: maxWidth }];

    XLSX.writeFile(
      workbook,
      `Etat_Caisse_${dayjsInstance().format('DD_MM_YYYY_HH_mm_ss')}.xlsx`,
      { compression: true },
    );
  };

  const handlePrintList = () => {
    setShowPdfViewer(true);
  };

  const calculateTotal = (invoices: SearchInvoiceApiResponse[]) => {
    let tempAssu = 0;
    let tempPatient = 0;
    let tempTTC = 0;

    invoices.map(invoiceItem => {
      tempAssu = tempAssu + invoiceItem.amountAssu;
      tempPatient = tempPatient + invoiceItem.amountPatient;
      tempTTC = tempTTC + invoiceItem.amountTotal;
    });

    setTotalAssu(tempAssu);
    setTotalPatient(tempPatient);
    setTotalTTC(tempTTC);
  };

  const searchInvoice = async () => {
    setIsLoading(true);
    const body: SearchInvoiceApiRequestBody = {
      dateDebut: dateDebut,
      dateEnd: dateEnd,
      paid: isPaid,
      num: numInvoice,
      dateDebutPayment: paymentDateStart,
      dateEndPayment: paymentDateEnd,
      invoice: true,
      proforma: false,
      creditNote: false,
    };
    try {
      const result = await searchInvoiceApi(body);
      // setItems(result.patients as SearchPatientResult[]);
      setInvoiceData(result as CashboxProps[]);
      calculateTotal(result);
    } catch (e) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    searchInvoice();
  }, []);

  /**
   * Réinitialiser le formulaire de recherche de facture
   */
  const resetSearchInvoice = () => {
    // Reset Search patient filters
    // setNumInvoice(null);
    setIsPaid(false);
    setPaymentDateStart('');
    setPaymentDateEnd('');
    setDateDebut(dayjsInstance().subtract(1, 'days').format(DATE_FORMAT));
    setDateEnd(dayjsInstance().add(1, 'days').format(DATE_FORMAT));

    // Reset invoice selected in table
    setSelectedRowKeys([]);
    setSelectedInvoicesRow([]);

    searchInvoice();
  };

  /**
   * Colonnes du tableau de factures
   */
  const columnData: ColumnsType<CashboxProps> = [
    {
      title: 'N° Facture',
      dataIndex: 'numInvoice',
      ...getColumnSearchProps({
        dataIndex: 'numInvoice',
        filterDropdownComponent: filterProps =>
          columnFilterComponent(filterProps, 'numInvoice', 'Numéro'),
      }),
    },
    {
      title: 'Date Facture',
      dataIndex: 'createdDate',
    },
    {
      title: 'Libellé',
      dataIndex: 'invoicePlanHeadLabel',
      ...getColumnSearchProps({
        dataIndex: 'invoicePlanHeadLabel',
        filterDropdownComponent: filterProps =>
          columnFilterComponent(filterProps, 'invoicePlanHeadLabel', 'Libéllé Facture'),
      }),
    },
    {
      title: 'N° Dossier',
      dataIndex: 'numMedicalRecord',
      ...getColumnSearchProps({
        dataIndex: 'numMedicalRecord',
        filterDropdownComponent: filterProps =>
          columnFilterComponent(filterProps, 'numMedicalRecord', 'Numéro de dossier'),
      }),
    },
    {
      title: 'Patient',
      dataIndex: 'idPatient',
      render: (value, record) => (
        <>
          {record.patientFirstname} {record.patientName}
        </>
      ),
    },

    {
      title: 'Statut',
      dataIndex: 'datePayment',
      align: 'left',
      render: value => (
        <>
          {value !== null ? (
            <StyledTag color='success'>Encaissé</StyledTag>
          ) : (
            <StyledTag color='volcano'>En attente</StyledTag>
          )}
        </>
      ),
    },
    {
      title: 'Date paiement',
      dataIndex: 'datePayment',
      align: 'left',
    },
    {
      title: 'Total Assurance',
      dataIndex: 'amountAssu',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
    },
    {
      title: 'Total Patient',
      dataIndex: 'amountPatient',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
    },
    {
      title: 'Montant Total',
      dataIndex: 'amountTotal',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
    },
  ];

  const invoiceRowSelection: TableRowSelection<CashboxProps> = {
    selectedRowKeys,
    getCheckboxProps: (record: CashboxProps) => ({
      disabled:
        record.datePayment !== null ||
        (selectedRowKeys.length === 1 && !selectedRowKeys.includes(record.id)),
      // name: record.invoicePlanHeadLabel,
    }),
    onChange: onSelectFacturationChange,
    selections: [Table.SELECTION_NONE],
    hideSelectAll: true,
  };

  const renderInvoicesSearchFilters = () => {
    return (
      <StyledBlocContainer
        style={{
          backgroundColor: `${chartColors.blue50}20`,
          padding: '20px 20px',
        }}>
        <StyledRow gutter={[12, 12]}>
          <StyledCol span={24}>
            <FormFieldLabel libelle='Date de facture' />
            <StyledDatePicker.RangePicker
              name='creationDate'
              placeholder={['Date de debut', 'Date de fin']}
              size='middle'
              style={{ width: '100%' }}
              onChange={(value, dateString) => {
                setDateDebut(dateString[0]);
                setDateEnd(dateString[1]);
                console.log(dateString);
              }}
              value={[
                _.isEmpty(dateDebut) ? null : dayjsInstance(dateDebut, DATE_FORMAT),
                _.isEmpty(dateEnd) ? null : dayjsInstance(dateEnd, DATE_FORMAT),
              ]}
              // disabledDate={current => dayjsInstance().isBefore(current, 'day')}
              format={DATE_FORMAT}
            />
          </StyledCol>

          <StyledCol span={24}>
            <FormFieldLabel libelle='Date de paiement' />
            <StyledDatePicker.RangePicker
              name='paymentDate'
              placeholder={['Date de debut', 'Date de fin']}
              size='middle'
              style={{ width: '100%' }}
              onChange={(value, dateString) => {
                setPaymentDateStart(dateString[0]);
                setPaymentDateEnd(dateString[1]);
                // console.log(dateString);
              }}
              allowClear
              value={[
                _.isEmpty(paymentDateStart)
                  ? null
                  : dayjsInstance(paymentDateStart, DATE_FORMAT),
                _.isEmpty(paymentDateEnd)
                  ? null
                  : dayjsInstance(paymentDateEnd, DATE_FORMAT),
              ]}
              // disabledDate={current => dayjsInstance().isBefore(current, 'day')}
              format={DATE_FORMAT}
            />
          </StyledCol>

          <StyledCol span={24}>
            <FormFieldLabel libelle='Numéro de facture' />
            <CustomInput
              name='numInvoice'
              isFocused={false}
              placeholder='Numéro de facture'
              type='text'
              handleInputChange={val => setNumInvoice(val)}
              width='100%'
              value={numInvoice}
              isDisabled={isLoading}
            />
          </StyledCol>

          <StyledCol span={24}>
            <FormFieldLabel libelle='Statut' />
            <StyledCheckbox
              disabled={isLoading}
              checked={isPaid}
              onChange={e => setIsPaid(e.target.checked)}>
              Payée?
            </StyledCheckbox>
          </StyledCol>

          <StyledCol span={24} style={{ width: '100%' }}>
            <StyledSpace style={{ width: '100%' }}>
              <StyledButton
                type='primary'
                size='middle'
                block
                disabled={isLoading}
                onClick={() => searchInvoice()}>
                Rechercher
              </StyledButton>
              <StyledButton
                type='link'
                htmlType='reset'
                size='middle'
                icon={<FiRefreshCcw />}
                disabled={isLoading}
                onClick={() => resetSearchInvoice()}
              />
            </StyledSpace>
          </StyledCol>
        </StyledRow>
      </StyledBlocContainer>
    );
  };

  /**
   * Affiche L'entête de la page
   * @returns
   */
  const renderHeader = () => {
    return (
      <>
        <PageHeader title='Caisse'>
          <StyledTooltip
            title='Veuillez sélectionner la facture à encaisser'
            color={chartColors.blue}
            placement='bottomRight'>
            <StyledButton
              type='primary'
              icon={<BsCashCoin />}
              disabled={_.isEmpty(selectedRowKeys)}
              onClick={
                _.isEmpty(selectedRowKeys) ? undefined : handleConfirmValidateCashIn
              }>
              Encaisser
            </StyledButton>
          </StyledTooltip>

          <StyledTooltip
            title='Imprimer le résultat de la recherche'
            color={chartColors.blue}
            placement='bottomRight'>
            <StyledButton
              type='primary'
              icon={<BsPrinterFill />}
              disabled={_.isEmpty(invoiceData)}
              onClick={_.isEmpty(invoiceData) ? undefined : handlePrintList}
            />
          </StyledTooltip>

          <StyledTooltip
            title='Export le résultat de la recherche vers un fichier Excel'
            color={chartColors.blue}
            placement='bottomRight'>
            <StyledButton
              type='dashed'
              icon={<AiFillFileExcel />}
              disabled={_.isEmpty(invoiceData)}
              onClick={_.isEmpty(invoiceData) ? undefined : handleExportToXls}
            />
          </StyledTooltip>
        </PageHeader>
      </>
    );
  };

  const renderCashBoxSummary = () => {
    return (
      <Table.Summary fixed>
        <Table.Summary.Row
          style={{ backgroundColor: chartColors.blue50, fontWeight: 700 }}>
          <Table.Summary.Cell index={0} colSpan={8}>
            TOTAUX
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} align='right'>
            {thousandSeparatorFormat(totalTVA)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} align='right'>
            {thousandSeparatorFormat(totalHT)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} align='right'>
            {thousandSeparatorFormat(totalTTC)}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  /**
   * Affiche le contenu de la page
   * @returns JSX.Element
   */
  const renderContent = () => {
    return (
      <Loader showSpinner={isLoading}>
        <StyleListContainer
          forceVisible='y'
          autoHide={false}
          style={{ padding: '0px', maxHeight: 700, paddingBottom: '100px' }}>
          <Table<CashboxProps>
            rowKey='id'
            dataSource={invoiceData}
            size='small'
            columns={columnData}
            bordered={false}
            rowSelection={invoiceRowSelection}
            pagination={false}
            summary={renderCashBoxSummary}
          />
        </StyleListContainer>
      </Loader>
    );
  };

  return (
    <VerticalLayout enableVerticalScroll='false'>
      {renderHeader()}

      <StyledLayoutGridWrapper>
        <PageContentLeftLayout layerShowPadding='false'>
          <div style={{ backgroundColor: `${chartColors.blue50}20` }}>
            {renderInvoicesSearchFilters()}
          </div>
        </PageContentLeftLayout>
        <PageContentRightLayout layerShowPadding='false'>
          <div style={{ overflowY: 'auto' }}>{renderContent()}</div>
        </PageContentRightLayout>
      </StyledLayoutGridWrapper>

      {/* <div style={{ padding: '10px 20px', backgroundColor: 'white', height: '100%' }}>
        <Loader showSpinner={isLoading}>{renderContent()}</Loader>
      </div> */}

      {showPdfViewer && (
        <PdfDrawerViewer
          drawerTitle='Etat de la caisse'
          closeDrawer={() => setShowPdfViewer(false)}
          extraActions={
            <>
              <StyledButton
                key='cancel_invoice'
                type='primary'
                size='middle'
                danger
                disabled={isLoading}
                onClick={() => setShowPdfViewer(false)}>
                Fermer
              </StyledButton>
            </>
          }
          isDrawerOpen={showPdfViewer}>
          <RenderDocument<CashboxProps[] | undefined, SearchInvoiceApiRequestBody>
            documentData={invoiceData}
            documentType='CASHBOX_INVOICE_LIST'
            isDuplicata={isDuplicata}
            extraData={{
              dateDebut: dateDebut,
              dateEnd: dateEnd,
              paid: isPaid,
              num: numInvoice,
              dateDebutPayment: paymentDateStart,
              dateEndPayment: paymentDateEnd,
              invoice: true,
              proforma: false,
              creditNote: false,
            }}
          />
        </PdfDrawerViewer>
      )}
    </VerticalLayout>
  );
};

export default Cashbox;
