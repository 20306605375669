import React from 'react';
import { PatientDto, SearchPatientResult } from '../../types/';
import PatientCard from '../patientHistory/PatientCard';

interface SearchResultProps {
  items: SearchPatientResult[];
  handleSetSelectedPatient: (patient: PatientDto) => void;
  currentSelectedPatient?: PatientDto;
}
const SearchResult: React.FC<SearchResultProps> = ({
  items,
  handleSetSelectedPatient,
  currentSelectedPatient,
}) => {
  const renderItems = () => {
    return (
      items &&
      items.map((item, idx) => {
        return (
          <PatientCard
            key={idx}
            infosPatient={item as PatientDto}
            handleSelectPatient={() => handleSetSelectedPatient(item as PatientDto)}
            isSelected={currentSelectedPatient?.id === item.id}
          />
        );
      })
    );
  };

  return <> {renderItems()}</>;
};

export default SearchResult;
