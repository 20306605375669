/* eslint-disable @typescript-eslint/no-explicit-any */
import { UploadFile } from 'antd';
import { UserDto } from './auth.types';
import { IBiologicalExamPrescription } from './biologicalExam.types';
import { IImageryExamPrescription } from './imageryExam.types';
import { IMedicationPrescription } from './medication.types';
import { PatientDto } from './patient.types';

export type PrescriptionLineDto = IMedicationPrescription & {
  id: number;
  idPrescriptionHead: number;
};

export type BiologicalPrescriptionLineDto = {
  id: number;
  idPrescriptionHead: number;
  idInvoiceHead: number;
  idBiologicalExam: number;
  biologicalExamDesignation: string;
  result: string;
  ratio: number;
  ratioLabel: string;
  comments: string;
  usualUnit: string;
  unit: string;
  unitPrice: number;
  amountTotal: number;
  amountAssu: number;
  amountPatient: number;
  norm: string;
  doneDate: string;
  quantity: number;
  done: boolean;
};

export type ImageryPrescriptionLineDto = {
  id: number;
  idPrescriptionHead: number;
  idInvoiceHead: number;
  idImageryExam: number;
  imageryExamDesignation: string;
  doneDate: string;
  ratio: number;
  ratioLabel: string;
  quantity: number;
  unit: string;
  unitPrice: number;
  amountTotal: number;
  amountAssu: number;
  amountPatient: number;
  done: boolean;
};

export type ImageryResultItem = {
  id: number;
  idPrescriptionLineImg: number;
  date: string;
  idResultImagery: string;
  name: string;
  type: string;
  size: number;
  data: string[];
};

export type IPrescriptionItem = {
  id: number;
  createdDate: string;
  idMedicalRecord: number;
  numMedicalRecord: string;
  createdBy: number;
  patientDto: PatientDto;
  utilisateurDto: UserDto;
  prescriptionLineDto: Array<PrescriptionLineDto>;
};

export type ImageryPrescriptionLine = {
  id: number;
  idPrescriptionHead: number;
  idInvoiceHead: number;
  idImageryExam: number;
  quantity: number;
  imageryExamDesignation: string;
  comments: string;
  doneDate: string;
  done: boolean;
  results: Array<ImageryResultItem>;
};

export type IImageryPrescriptionItem = {
  id: number;
  createdDate: string;
  idMedicalRecord: number;
  numMedicalRecord: string;
  createdBy: number;
  patientDto: PatientDto;
  utilisateurDto: UserDto;
  prescriptionLineImageryDto: Array<ImageryPrescriptionLine>;
};

export type SearchPrescriptionsRequestBody = {
  idMedicalRecord: number;
  idPatient: number;
  paid: boolean | null;
};

export type CreatePrescriptionRequestBody = {
  idMedicalRecord: number;
  idPatient: number;
  idMedecin: number;
  prescriptionLines: IMedicationPrescription[];
};

export type CreatePrescriptionResponse = {
  id: number;
  createdDate: string;
  idMedicalRecord: number;
  idPatient: number;
  idMedecin: number;
  prescriptionLines: Array<PrescriptionLineDto>;
};

export type CompletePrescriptionRequestBody = {
  ids: Array<number>;
};

export type UpdatePrescriptionLineRequestBody = {
  idBiologicalExam: number;
  biologicalExamDesignation: string;
  result: string;
  comments: string;
  quantity: number;
  done: boolean;
};

export type UpdateImageryPrescriptionLineRequestBody = {
  idImageryExam: number;
  imageryExamDesignation: string;
  quantity: number;
  comments: string;
  done: boolean;
};

export type UpdateImageryFilesPrescriptionLineRequestBody = {
  idPrescriptionLineImg: number;
  comments: string;
  files: UploadFile[];
};

export type CreateBiologicalPrescriptionRequestBody = {
  idMedicalRecord: number;
  idPatient: number;
  idMedecin: number;
  prescriptionLineBiologicalInputDto: IBiologicalExamPrescription[];
};

export type CreateImageryPrescriptionRequestBody = {
  idMedicalRecord: number;
  idPatient: number;
  idMedecin: number;
  prescriptionLines: IImageryExamPrescription[];
};

export type CreateBiologicalPrescriptionResponse = {
  id: number;
  createdDate: string;
  idMedicalRecord: number;
  idPatient: number;
  idMedecin: number;
  prescriptionLines: Array<BiologicalPrescriptionLineDto>;
};
export type CreateImageryPrescriptionResponse = {
  id: number;
  createdDate: string;
  idMedicalRecord: number;
  idPatient: number;
  idMedecin: number;
  prescriptionLines: Array<IImageryExamPrescription>;
};

export type ImageryResultsPrescriptionLine = {
  id: number;
  url: string;
  type: string;
};

export type IBiologicalPrescriptionItem = {
  id: number;
  createdDate: string;
  idMedicalRecord: number;
  numMedicalRecord: string;
  createdBy: number;
  patientDto: PatientDto;
  utilisateurDto: UserDto;
  prescriptionLineBiologicalDto: Array<BiologicalPrescriptionLineDto>;
};

export function instanceOfIPrescriptionItem(
  object: any,
): object is IBiologicalPrescriptionItem {
  return 'prescriptionLineDto' in object;
}
export function instanceOfIBiologicalPrescriptionItem(
  object: any,
): object is IBiologicalPrescriptionItem {
  return 'prescriptionLineBiologicalDto' in object;
}

export function instanceOfImageryPrescriptionItem(
  object: any,
): object is IImageryPrescriptionItem {
  return 'prescriptionLineImageryDto' in object;
}

export type SearchPrescriptionResponse = IPrescriptionItem[];
export type SearchBiologicalPrescriptionResponse = IBiologicalPrescriptionItem[];
export type SearchBiologicalPrescriptionLinesResponse = BiologicalPrescriptionLineDto[];

export type SearchImageryPrescriptionResponse = IImageryPrescriptionItem[];
export type SearchImageryPrescriptionLinesResponse = ImageryPrescriptionLineDto[];
export type ImageryResultsPrescriptionLineResponse = ImageryResultsPrescriptionLine[];
