import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import dayjsInstance from '../../dayjs';
import { StyledButton } from '../button/style';
import CalendarDay from './CalendarDay';
import {
  TimeSlotCalendarContainer,
  TimeSlotCalendarWrapper,
  TimeSlotNavigationContainer,
  TimeSlotNavigationWrapper,
} from './style';
import { TimeSlotProps } from '../../types';
import _ from 'lodash';

const days = dayjsInstance.weekdays(true);

interface ITimeslotCalendar {
  callback: (timeslots: Array<TimeSlotProps>) => void;
  initialValues: TimeSlotProps[];
}

const TimeslotCalendar: React.FC<ITimeslotCalendar> = ({ callback, initialValues }) => {
  const [week, setWeek] = useState(dayjsInstance().week());
  const [daysWeekDate, setDaysWeekDate] = useState<Dayjs[]>([]);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState<Array<TimeSlotProps>>([]);

  useEffect(() => {
    setSelectedTimeSlots(initialValues);
  }, [initialValues]);

  console.log(selectedTimeSlots);

  const handleUpdateTimeSlots = (timeSlot: TimeSlotProps) => {
    const tempTimeslots = [...selectedTimeSlots];
    const timeslotIndex = _.findIndex(tempTimeslots, timeSlot);

    if (timeslotIndex === -1) {
      // Adding Timeslot
      tempTimeslots.push(timeSlot);
      setSelectedTimeSlots(tempTimeslots);
      callback(tempTimeslots);
    } else {
      // Removing Timeslot
      tempTimeslots.splice(timeslotIndex, 1);
      setSelectedTimeSlots(tempTimeslots);
      callback(tempTimeslots);
    }
  };

  useEffect(() => {
    const tempDates: Dayjs[] = [];
    const initialdate = dayjsInstance().week(week).startOf('week');
    for (let i = 0; i < days.length; i++) {
      tempDates.push(initialdate.add(i, 'day'));
    }
    setDaysWeekDate(tempDates);
  }, [week]);

  const nextWeek = () => {
    setWeek(week + 1);
  };

  const prevWeek = () => {
    if (week > 0) {
      setWeek(week - 1);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <TimeSlotCalendarWrapper>
        <TimeSlotCalendarContainer>
          {days.map((day, index) => (
            <CalendarDay
              key={day}
              day={day}
              dayDates={daysWeekDate}
              dateIndex={index}
              handleUpdateTimeSlots={handleUpdateTimeSlots}
              selectedTimeSlots={selectedTimeSlots}
            />
          ))}
        </TimeSlotCalendarContainer>
      </TimeSlotCalendarWrapper>
      <TimeSlotNavigationWrapper>
        <TimeSlotNavigationContainer>
          <StyledButton
            onClick={prevWeek}
            icon={<FiChevronLeft />}
            shape='circle'
            size='large'
          />
          <StyledButton
            onClick={nextWeek}
            icon={<FiChevronRight />}
            shape='circle'
            size='large'
          />
        </TimeSlotNavigationContainer>
      </TimeSlotNavigationWrapper>
    </div>
  );
};

export default TimeslotCalendar;
