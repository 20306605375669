import { useState } from 'react';
import { BsBoxes, BsClipboard2PulseFill, BsHeartPulse } from 'react-icons/bs';
import {
  FaAmbulance,
  FaBriefcase,
  FaClipboardList,
  FaCommentMedical,
  FaHospitalUser,
  FaLanguage,
  FaMapMarked,
  FaMapPin,
  FaPills,
  FaPumpMedical,
} from 'react-icons/fa';
import { LuLayoutList, LuListTree } from 'react-icons/lu';
import { RiHealthBookLine } from 'react-icons/ri';
import ConfigMenu from '../../components/menu/configMenu';
import PageHeader from '../../components/pageHeader/PageHeader';
import VerticalLayout from '../../layout/VerticalLayout';
import { IReferentialMenuItem, ReferentialTabType, ReferentialTabs } from '../../types';
import {
  ConfigLayoutContentWrapper,
  ConfigLayoutMenuContainer,
  ConfigLayoutMenuWrapper,
  ConfigLayoutPageContainer,
} from '../style';
import AntecedentsRef from './antecedents';
import ArticlesRef from './article';
import BiologicalExamCategoriesRef from './biologicalExamCategories';
import BiologicalExamSubCategoriesRef from './biologicalExamSubCategories';
import BiologicalExamsRef from './biologicalExams';
import DiagnosticsRef from './diagnostics';
import MedicationsRef from './medication';
import SupplierRef from './supplier';
import ServiceRef from './services';
import ProfessionRef from './profession';
import EthnicRef from './ethnic';
import RegionRef from './region';
import PlaceOfResidenceRef from './placeOfResidence';
import ImageryExamRef from './imageryExams';
import FunctionalSignsRef from './functionalSigns';
import ConsultationReasonsRef from './reasonForConsultation';
import { MdBedroomParent } from 'react-icons/md';
import RoomRef from './rooms';
import HospitalizationExamRef from './hospitalizationExams';

const referentialMenuItems: Array<IReferentialMenuItem> = [
  {
    key: ReferentialTabs.ANTECEDENTS_TAB,
    name: 'Antécédents',
    component: <AntecedentsRef />,
    icon: <BsClipboard2PulseFill />,
    value: 1,
  },
  {
    key: ReferentialTabs.BIOLOGICAL_EXAM_CAT_TAB,
    name: "Catégories d'examens Biologiques",
    component: <BiologicalExamCategoriesRef />,
    icon: <LuLayoutList />,
    value: 2,
  },
  {
    key: ReferentialTabs.ROOMS_TAB,
    name: 'Chambres',
    component: <RoomRef />,
    icon: <MdBedroomParent />,
    value: 3,
  },
  {
    key: ReferentialTabs.ARTICLES_TAB,
    name: 'Consommables',
    component: <ArticlesRef />,
    icon: <BsBoxes />,
    value: 4,
  },
  {
    key: ReferentialTabs.DIAGNOSTICS_TAB,
    name: 'Diagnostics',
    component: <DiagnosticsRef />,
    icon: <BsHeartPulse />,
    value: 5,
  },
  {
    key: ReferentialTabs.BIOLOGICAL_EXAMS_TAB,
    name: 'Examens Biologiques',
    component: <BiologicalExamsRef />,
    icon: <RiHealthBookLine />,
    value: 6,
  },
  {
    key: ReferentialTabs.HOSPITALIZATION_EXAMS_TAB,
    name: "Examens d'hospitalisation",
    component: <HospitalizationExamRef />,
    icon: <FaAmbulance />,
    value: 7,
  },
  {
    key: ReferentialTabs.IMAGERY_EXAMS_TAB,
    name: "Examens d'imagerie",
    component: <ImageryExamRef />,
    icon: <FaPumpMedical />,
    value: 8,
  },
  {
    key: ReferentialTabs.ETHNICS_TAB,
    name: 'Ethnies',
    component: <EthnicRef />,
    icon: <FaLanguage />,
    value: 9,
  },
  {
    key: ReferentialTabs.SUPPLIERS_TAB,
    name: 'Fournisseurs',
    component: <SupplierRef />,
    icon: <BsBoxes />,
    value: 10,
  },

  {
    key: ReferentialTabs.PLACES_OF_RESIDENCE_TAB,
    name: 'Lieux de résidence',
    component: <PlaceOfResidenceRef />,
    icon: <FaMapPin />,
    value: 11,
  },
  {
    key: ReferentialTabs.MEDICATIONS_TAB,
    name: 'Médicaments',
    component: <MedicationsRef />,
    icon: <FaPills />,
    value: 12,
  },
  {
    key: ReferentialTabs.CONSULTATION_REASONS,
    name: 'Motifs de consultation',
    component: <ConsultationReasonsRef />,
    icon: <FaClipboardList />,
    value: 13,
  },
  {
    key: ReferentialTabs.SERVICES_TAB,
    name: 'Services',
    component: <ServiceRef />,
    icon: <FaHospitalUser />,
    value: 14,
  },
  {
    key: ReferentialTabs.PROFESSIONS_TAB,
    name: 'Professions',
    component: <ProfessionRef />,
    icon: <FaBriefcase />,
    value: 15,
  },

  {
    key: ReferentialTabs.REGIONS_TAB,
    name: 'Régions',
    component: <RegionRef />,
    icon: <FaMapMarked />,
    value: 16,
  },
  {
    key: ReferentialTabs.FUNCTIONAL_SIGNS,
    name: 'Signes fonctionnels',
    component: <FunctionalSignsRef />,
    icon: <FaCommentMedical />,
    value: 17,
  },
  {
    key: ReferentialTabs.BIOLOGICAL_EXAM_SUB_CAT_TAB,
    name: "Sous-Catégories d'examens Biologiques",
    component: <BiologicalExamSubCategoriesRef />,
    icon: <LuListTree />,
    value: 18,
  },
];

const Referential: React.FC = () => {
  const [activeRefTab, setActiveRefTab] = useState<ReferentialTabType>(
    ReferentialTabs.ANTECEDENTS_TAB,
  );
  const [activeRefTabContent, setActiveRefTabContent] = useState<number>(1);

  const handleSelectMenuRef = (menu: IReferentialMenuItem) => {
    setActiveRefTab(menu.key);
    setActiveRefTabContent(menu.value);
  };

  const renderReferentialMenuItems = () => {
    return (
      <ConfigMenu<ReferentialTabType>
        activeTab={activeRefTab}
        handleSelectMenuRef={menu => handleSelectMenuRef(menu)}
        menuItems={referentialMenuItems}
      />
    );
  };

  const renderReferentialMenuContent = () => {
    return referentialMenuItems[activeRefTabContent - 1].component;
  };

  const renderContent = () => {
    return (
      <>
        <ConfigLayoutMenuWrapper style={{ maxHeight: '90vh' }}>
          {renderReferentialMenuItems()}
        </ConfigLayoutMenuWrapper>
        <ConfigLayoutContentWrapper style={{ maxHeight: '90vh' }}>
          <ConfigLayoutMenuContainer>
            {renderReferentialMenuContent()}
          </ConfigLayoutMenuContainer>
        </ConfigLayoutContentWrapper>
      </>
    );
  };
  return (
    <VerticalLayout enableVerticalScroll='false'>
      <PageHeader title='Référentiels' />
      <ConfigLayoutPageContainer>{renderContent()}</ConfigLayoutPageContainer>
    </VerticalLayout>
  );
};

export default Referential;
