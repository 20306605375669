/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer, Form } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import {
  StyledCol,
  StyledDivider,
  StyledInput,
  StyledRow,
  StyledSpace,
} from '../../../components/style';
import {
  CreateDiagnosticItemRequestBody,
  IDiagnosticItem,
  ReferentialViewProps,
} from '../../../types';
import { StyledBlocContainer } from '../../style';
import {
  createDiagnosticApi,
  updateDiagnosticApi,
} from '../../../api/referentialService';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { getApiErrorMsg } from '../../../utils/commun';

type DiagnosticFormProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  currentData?: IDiagnosticItem;
};

const DiagnosticForm: React.FC<DiagnosticFormProps> = ({
  currentData,
  navigateToview,
}) => {
  const { notification } = AppContainer.useApp();
  const [form] = Form.useForm<{ code: string; label: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createDiagnostic = async () => {
    try {
      await form.validateFields().then(async row => {
        const diagnosticData: CreateDiagnosticItemRequestBody = {
          code: row.code,
          label: row.label,
        };

        setIsLoading(true);
        await createDiagnosticApi(diagnosticData).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `Le diagnostic ${row.label} a été enregistré!`,
          });
          navigateToview('LIST_ITEMS');
        });
      });
    } catch (error: any) {
      if (_.isUndefined(error.values)) {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const editDiagnostic = async () => {
    try {
      await form.validateFields().then(async row => {
        const diagnosticData: CreateDiagnosticItemRequestBody = {
          code: row.code,
          label: row.label,
        };

        setIsLoading(true);
        await updateDiagnosticApi(currentData?.id as number, diagnosticData).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `Le diagnostic a été modifié!`,
          });
          navigateToview('LIST_ITEMS');
        });
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderHeader = () => {
    return (
      <SectionHeader
        title={
          _.isUndefined(currentData) ? 'Nouveau diagnostic' : 'Modification diagnostic'
        }
        onBack={() => navigateToview('LIST_ITEMS')}
      />
    );
  };

  const renderContent = () => {
    return (
      <StyledBlocContainer style={{ padding: 20 }}>
        <Form
          disabled={isLoading}
          form={form}
          component={false}
          initialValues={{
            code: _.isUndefined(currentData) ? '' : currentData.code,
            label: _.isUndefined(currentData) ? '' : currentData.label,
          }}>
          <StyledRow gutter={[16, 16]} align='middle' justify='center'>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Code du diagnostic' />
              <Form.Item style={{ margin: 0 }} name='code'>
                <StyledInput size='large' placeholder='Code du diagnostic' />
              </Form.Item>
            </StyledCol>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Désignation' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='label'
                rules={[
                  { required: true, message: 'Désignation obligatoire' },
                  { whitespace: false },
                ]}>
                <StyledInput size='large' placeholder='Désignation diagnostic' />
              </Form.Item>
            </StyledCol>
            <StyledDivider />
            <StyledCol span={24}>
              <StyledSpace>
                <StyledButton
                  type='primary'
                  htmlType='submit'
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={
                    _.isUndefined(currentData) ? createDiagnostic : editDiagnostic
                  }>
                  {_.isUndefined(currentData)
                    ? 'Enregistrer le diagnostic'
                    : 'Modifier le diganostic'}
                </StyledButton>
              </StyledSpace>
            </StyledCol>
          </StyledRow>
        </Form>
      </StyledBlocContainer>
    );
  };

  return (
    <>
      {renderHeader()}
      <StyledDivider />
      {renderContent()}
    </>
  );
};

export default DiagnosticForm;
