import { StyledLabel, StyledRequiredMark } from '../../containers/style';

interface FormFieldlabelProps {
  libelle: string;
  isRequired?: boolean;
}

const FormFieldLabel: React.FC<FormFieldlabelProps> = ({ libelle, isRequired }) => {
  return (
    <StyledLabel className='-mb-2 relative'>
      {libelle} {isRequired && <StyledRequiredMark />}
    </StyledLabel>
  );
};

export default FormFieldLabel;
