import { PropsWithChildren } from 'react';
import {
  StyledLayoutLeftContent,
  StyledLayoutLeftHeader,
  StyledLeftLayoutWrapper,
} from './style';

interface LeftLayoutProps {
  headerTitle?: React.ReactNode;
  layerShowPadding?: 'true' | 'false';
}

const PageContentLeftLayout: React.FC<PropsWithChildren<LeftLayoutProps>> = ({
  headerTitle,
  children,
  layerShowPadding = 'false',
}) => {
  return (
    <StyledLeftLayoutWrapper $layerShowPadding={layerShowPadding}>
      {headerTitle && <StyledLayoutLeftHeader>{headerTitle}</StyledLayoutLeftHeader>}
      <StyledLayoutLeftContent>{children}</StyledLayoutLeftContent>
    </StyledLeftLayoutWrapper>
  );
};

export default PageContentLeftLayout;
