/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer, Avatar } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { isEmpty, isNull } from 'lodash';
import { useEffect, useState } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { FaUsers } from 'react-icons/fa6';
import { FiActivity, FiLogOut, FiRefreshCcw, FiUser } from 'react-icons/fi';
import { MdEditNote } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDataFromStorage, saveInStorage } from '../../../src/utils/storage';
import { getMedicalRecordsByDoctorApi } from '../../api/dossierPatientService';
import { logOutUser } from '../../api/loginService';
import { ScrollContainer } from '../../containers/style';
import chartColors from '../../styles/color';
import {
  CreateMedicalRecordApiResponse,
  NavigationMode,
  StorageType,
  UserDto,
} from '../../types';
import { StyledButton } from '../button/style';
import Loader from '../loader';
import CustomModal from '../modal/Modal';
import MedicalRecordPatientList from '../patientHistory/MedicalRecordPatientList';
import {
  StyledBadge,
  StyledList,
  StyledPopover,
  StyledSpace,
  StyledTooltip,
} from '../style';
import {
  StyledUserHeaderInfo,
  StyledUserHeaderName,
  StyledUserHeaderRole,
  StyledUserHeaderWrapper,
} from './style';

type UserDropdownItemProps = {
  title: string;
  path: string;
  icon: React.ReactNode;
  isLogout: boolean;
};

const userDropdownMenuItems: Array<UserDropdownItemProps> = [
  {
    title: 'Mon profil',
    path: '/mon-profil',
    icon: <FiUser />,
    isLogout: false,
  },
  {
    title: 'Mon activité',
    path: '/mon-activite',
    icon: <FiActivity />,
    isLogout: false,
  },
  {
    title: 'Déconnexion',
    path: '',
    icon: <FiLogOut />,
    isLogout: true,
  },
];

const UserDropdownMenu = () => {
  const navigate = useNavigate();
  const { notification } = AppContainer.useApp();

  const logOut = () => {
    navigate('/', { replace: true });
    logOutUser();
    notification.info({
      message: 'Déconnexion',
      description: 'Vous avez été déconnecté avec succès',
    });
  };

  return (
    <div style={{ width: '250px' }}>
      <StyledList
        size='small'
        style={{ margin: 0 }}
        dataSource={userDropdownMenuItems}
        itemLayout='vertical'
        renderItem={(item: any) => (
          <StyledList.Item>
            <StyledButton
              style={{ justifyContent: 'flex-start' }}
              type='text'
              danger={item.isLogout}
              icon={item.icon}
              block
              size='small'
              onClick={() => (item.isLogout ? logOut() : navigate(item.path))}>
              {item.title}
            </StyledButton>
          </StyledList.Item>
        )}
      />
      {/* <StyledDivider type='horizontal' />
      <StyledButton
        danger
        block
        size='small'
        onClick={() => logOut()}
        type='text'
        icon={<FiLogOut />}>
        Déconnexion
      </StyledButton> */}
    </div>
  );
};

const UserHeader = () => {
  const userData = getDataFromStorage<UserDto>('connectedUserData');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showMedalMedicalRecord, setShowMedalMedicalRecord] = useState<boolean>(false);
  const [medicalRecords, setMedicalRecords] = useState<
    Array<CreateMedicalRecordApiResponse>
  >([]);

  const handleOpenMediclaRecord = (record: CreateMedicalRecordApiResponse) => {
    setShowMedalMedicalRecord(false);
    saveInStorage(StorageType.SS, 'medicalRecord', record);
    navigate('/dossier-patient', {
      state: {
        mode: NavigationMode.READ_MODIFY_MODE,
      },
    });
  };

  const columnData: ColumnsType<CreateMedicalRecordApiResponse> = [
    {
      title: 'No Dossier',
      dataIndex: 'numMedicalRecord',
    },
    {
      title: 'Patient',
      render: (value, record) => (
        <>{`${record.patientDto.name} ${record.patientDto.firstname}`}</>
      ),
    },
    {
      title: 'Crée le',
      dataIndex: 'createdDate',
      align: 'left',
      defaultSortOrder: 'descend',
    },
    {
      align: 'right',
      render: (_value, record) => {
        return (
          <StyledSpace>
            <StyledButton
              shape='circle'
              icon={<MdEditNote />}
              size='middle'
              onClick={() => handleOpenMediclaRecord(record)}
            />
          </StyledSpace>
        );
      },
    },
  ];

  const loadMedicalRecordsByDoctor = () => {
    setIsLoading(true);
    try {
      getMedicalRecordsByDoctorApi(userData.id).then(res => {
        setMedicalRecords(res);
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const isMedicalRecordOpened = () => {
    return pathname === '/dossier-patient';
  };

  useEffect(() => {
    loadMedicalRecordsByDoctor();
  }, []);

  const renderMedicalRecord = () => {
    return (
      <CustomModal
        isVisible={showMedalMedicalRecord}
        footer={[
          <StyledButton
            key='reload'
            htmlType='reset'
            icon={<FiRefreshCcw />}
            disabled={isLoading}
            onClick={loadMedicalRecordsByDoctor}>
            Actualiser
          </StyledButton>,
        ]}
        handleVisibility={() => setShowMedalMedicalRecord(false)}
        title={`${medicalRecords.length} patient(s) dans la file d'attente`}
        width='1000px'>
        <Loader showSpinner={isLoading}>
          <ScrollContainer style={{ height: '50vh' }}>
            <MedicalRecordPatientList
              dataMedicalRecords={medicalRecords}
              hasPagination={false}
              showListHeader={false}
              showPrintBtn={false}
              customColumns={columnData}
            />
          </ScrollContainer>
        </Loader>
      </CustomModal>
    );
  };

  const renderPatientFileButton = () => {
    return (
      <StyledTooltip
        placement='bottom'
        title="File d'attente"
        style={{ marginRight: 10 }}>
        <StyledBadge count={medicalRecords.length} showZero>
          <StyledButton
            shape='circle'
            size='large'
            icon={<FaUsers size={20} />}
            loading={isLoading}
            disabled={isLoading || isMedicalRecordOpened()}
            onClick={() =>
              isMedicalRecordOpened() ? null : setShowMedalMedicalRecord(true)
            }
          />
        </StyledBadge>
      </StyledTooltip>
    );
  };

  return (
    <>
      <StyledSpace size='large'>
        {!isNull(userData.level) && !isEmpty(userData.level)
          ? renderPatientFileButton()
          : null}
        <StyledPopover
          content={UserDropdownMenu}
          trigger={['click', 'hover']}
          placement='bottomRight'
          arrow={false}>
          <StyledUserHeaderWrapper>
            <StyledUserHeaderInfo>
              <StyledUserHeaderName>
                {`${userData?.level} ${userData?.firstname} ${userData?.name}`}
              </StyledUserHeaderName>
              <StyledUserHeaderRole>{userData?.serviceDto.name}</StyledUserHeaderRole>
            </StyledUserHeaderInfo>

            <Avatar
              style={{ backgroundColor: chartColors.blue }}
              shape='circle'
              size={50}
              icon={<AiOutlineUser />}
            />
          </StyledUserHeaderWrapper>
        </StyledPopover>
      </StyledSpace>

      {showMedalMedicalRecord && renderMedicalRecord()}
    </>
  );
};

export default UserHeader;
