import { Alert, App as AppContainer, MenuProps, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { BiSolidWidget } from 'react-icons/bi';
import { BsThreeDots } from 'react-icons/bs';
import { FiCheck, FiPrinter, FiRefreshCcw } from 'react-icons/fi';
import {
  searchPharmacyOrderLinesApi,
  searchPharmacyOrdersApi,
  validatePharmacyOrderDeliveryApi,
} from '../../../api/pharmacyService';
import { StyledButton } from '../../../components/button/style';
import StyledDrawer from '../../../components/drawer/StyledDrawer';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import Loader from '../../../components/loader';
import {
  StyledCol,
  StyledDatePicker,
  StyledDropdown,
  StyledInput,
  StyledRow,
  StyledSpace,
} from '../../../components/style';
import PdfDrawerViewer from '../../../components/viewer/PdfDrawerViewer';
import dayjsInstance from '../../../dayjs';
import PageContentLeftLayout from '../../../layout/PageContentLeftLayout';
import PageContentRightLayout from '../../../layout/PageContentRightLayout';
import { StyledLayoutGridWrapper } from '../../../layout/style';
import chartColors from '../../../styles/color';
import {
  CreatePharmacyOrderResponse,
  IPharmacyArticle,
  IPharmacyOrder,
  SearchPharmacyOrdersLinesResponse,
  SearchPharmacyOrdersRequestBody,
  SearchPharmacyOrdersResponse,
  StorageType,
} from '../../../types';
import {
  getApiErrorMsg,
  mapStateToRequestBody,
  thousandSeparatorFormat,
} from '../../../utils/commun';
import { DATE_FORMAT, ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { removeDataFromStorage, saveInStorage } from '../../../utils/storage';
import RenderDocument from '../../document';
import { StyleListContainer, StyledBlocContainer, StyledPrimarytext } from '../../style';
import PharmacyReceipt from '../pharmacyReceipt';

const linesMenuItems: MenuProps['items'] = [
  {
    label: 'Imprimer',
    key: '2',
    icon: <FiPrinter />,
  },
  {
    label: 'Bon de réception',
    key: '1',
    icon: <FiCheck />,
  },
];
const deliverylinesMenuItems: MenuProps['items'] = [
  {
    label: 'Réceptionner',
    key: '3',
    icon: <BiSolidWidget />,
  },
];

const OrdersArticles: React.FC = () => {
  const { notification } = AppContainer.useApp();
  const [dateDebut, setDateDebut] = useState<string>(
    dayjsInstance().subtract(1, 'days').format(DATE_FORMAT),
  );
  const [dateEnd, setDateEnd] = useState<string>(dayjsInstance().format(DATE_FORMAT));
  const [orderNumber, setOrderNumber] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pharmacyOrders, setPharmacyOrders] = useState<SearchPharmacyOrdersResponse>([]);
  const [showDetailsDrawer, setShowDetailsDrawer] = useState<boolean>(false);
  const [showReceiptDrawer, setShowReceiptDrawer] = useState<boolean>(false);
  const [orderHeader, setOrderHeader] = useState<IPharmacyOrder>();
  const [orderLines, setOrderLines] = useState<SearchPharmacyOrdersLinesResponse>([]);
  const [showOrderPdf, setShowOrderPdf] = useState<boolean>(false);

  const searchOrders = () => {
    try {
      setIsLoading(true);
      searchPharmacyOrdersApi(
        mapStateToRequestBody<SearchPharmacyOrdersRequestBody>({
          dateDebut: dateDebut,
          dateEnd: dateEnd,
          num: orderNumber,
        }),
      ).then(res => {
        setIsLoading(false);
        setPharmacyOrders(res);
      });
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
      setIsLoading(false);
    }
  };

  const validateDelivery = (orderId: number) => {
    setIsLoading(true);
    try {
      validatePharmacyOrderDeliveryApi(orderId).then(() => {
        setIsLoading(false);
        notification.success({
          message: SuccesssMsgTitle,
          description: `La livraison de la commande ${orderHeader?.num} à été validée`,
        });
        searchOrders();
        handleModalDetailsOrder();
        handleModalOrderPDF();
      });
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
      setIsLoading(false);
    }
  };

  const loadOrderlines = async (orderId: number) => {
    try {
      setIsLoading(true);
      searchPharmacyOrderLinesApi(orderId).then(res => {
        setOrderLines(res);
        setIsLoading(false);
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    }
  };

  useEffect(() => {
    searchOrders();
  }, []);

  const resetSearchOrders = () => {
    setDateDebut(dayjsInstance().subtract(1, 'days').format(DATE_FORMAT));
    setDateEnd(dayjsInstance().format(DATE_FORMAT));
    setOrderNumber('');
    setPharmacyOrders([]);
  };

  const handleMenuClick = (menu, selectedLine: IPharmacyOrder) => {
    setOrderLines([]);
    switch (menu.key) {
      case '1': // Details
        setOrderHeader(selectedLine);
        loadOrderlines(selectedLine.id).then(() => {
          setShowDetailsDrawer(true);
        });
        break;
      case '2': // Print
        setOrderHeader(selectedLine);
        loadOrderlines(selectedLine.id).then(() => {
          setShowOrderPdf(true);
        });
        break;

      case '3': // Receive
        removeDataFromStorage(StorageType.LS, 'pharmacyOrderHead');
        saveInStorage(StorageType.LS, 'pharmacyOrderHead', selectedLine);
        setShowReceiptDrawer(true);
        // navigate('/pharmacie/receipt');
        break;

      case '4': // Validate Delivery
        validateDelivery(selectedLine.id);
        break;

      default:
        break;
    }
  };

  const handleModalDetailsOrder = () => {
    setShowDetailsDrawer(state => !state);
  };

  const handleModalReceiptOrder = () => {
    setShowReceiptDrawer(state => !state);
  };

  const handleModalOrderPDF = () => {
    setShowOrderPdf(state => !state);
  };

  const columnData: ColumnsType<IPharmacyOrder> = [
    {
      title: 'Date',
      dataIndex: 'createdDate',
    },
    {
      title: (
        <>
          N<sup>O</sup> Commande
        </>
      ),
      dataIndex: 'num',
    },
    {
      title: (
        <>
          N<sup>O</sup> Bon de réception
        </>
      ),
      dataIndex: 'numBl',
    },
    {
      title: 'Fournisseur',
      dataIndex: 'nameSupplier',
    },
    {
      title: 'Montant HT',
      dataIndex: 'amountHT',
      render: value => thousandSeparatorFormat(value),
    },
    {
      title: 'TVA',
      dataIndex: 'amountTVA',
      render: value => thousandSeparatorFormat(value),
    },
    {
      title: 'Montant TTC',
      dataIndex: 'amountTTC',
      render: value => thousandSeparatorFormat(value),
    },
    {
      align: 'center',
      render: (_value, record) => (
        <StyledSpace>
          <StyledDropdown
            menu={{
              items: _.isNull(record.numBl)
                ? [linesMenuItems[0], ...deliverylinesMenuItems, linesMenuItems[1]]
                : linesMenuItems,
              onClick: e => handleMenuClick(e, record),
            }}
            trigger={['click', 'hover']}>
            <StyledButton icon={<BsThreeDots />} shape='circle' />
          </StyledDropdown>
        </StyledSpace>
      ),
    },
  ];

  const columnDataOrderLines: ColumnsType<IPharmacyArticle> = [
    {
      title: 'Désignation',
      dataIndex: 'designation',
    },
    {
      title: 'PU',
      dataIndex: 'unitPrice',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
      width: 100,
    },
    {
      title: 'Qté Commandé',
      dataIndex: 'quantityOrdered',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
    },
    {
      title: 'Qté Reçue',
      dataIndex: 'quantityRecieved',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
    },
    {
      title: 'Total HT',
      dataIndex: 'amountHT',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
    },
    {
      title: 'TVA',
      dataIndex: 'amountTVA',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
    },
    {
      title: 'Montant TTC',
      dataIndex: 'amountTTC',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
    },
  ];

  const renderSearchFilters = () => {
    return (
      <StyledBlocContainer
        style={{
          backgroundColor: `${chartColors.blue50}20`,
          padding: '20px 20px',
        }}>
        <StyledRow gutter={[12, 12]}>
          <StyledCol span={24}>
            <FormFieldLabel libelle='Date de commande' />
            <StyledDatePicker.RangePicker
              allowClear={false}
              name='creationDate'
              placeholder={['Date de debut', 'Date de fin']}
              style={{ width: '100%' }}
              onChange={(value, dateString) => {
                setDateDebut(dateString[0]);
                setDateEnd(dateString[1]);
              }}
              value={[
                _.isEmpty(dateDebut) ? null : dayjsInstance(dateDebut, DATE_FORMAT),
                _.isEmpty(dateEnd) ? null : dayjsInstance(dateEnd, DATE_FORMAT),
              ]}
              // disabledDate={current => dayjsInstance().isBefore(current, 'day')}
              format={DATE_FORMAT}
            />
          </StyledCol>
          <StyledCol span={24}>
            <FormFieldLabel libelle='Numéro de commande' />
            <StyledInput
              placeholder='Numéro de commande'
              value={orderNumber}
              onChange={e => setOrderNumber(e.currentTarget.value)}
            />
          </StyledCol>

          <StyledCol span={24}>
            <StyledSpace style={{ width: '100%' }}>
              <StyledButton
                type='primary'
                size='middle'
                block
                loading={isLoading}
                disabled={isLoading}
                onClick={() => searchOrders()}>
                Rechercher
              </StyledButton>
              <StyledButton
                type='link'
                htmlType='reset'
                size='middle'
                disabled={isLoading}
                icon={<FiRefreshCcw />}
                onClick={() => resetSearchOrders()}
              />
            </StyledSpace>
          </StyledCol>
        </StyledRow>
      </StyledBlocContainer>
    );
  };

  const renderContent = () => {
    return (
      <StyleListContainer
        forceVisible='y'
        autoHide={false}
        style={{ padding: '0px', maxHeight: 700, paddingBottom: '100px' }}>
        <Table
          rowKey='id'
          dataSource={pharmacyOrders}
          size='small'
          columns={columnData}
          bordered={false}
          pagination={false}
        />
      </StyleListContainer>
    );
  };

  const renderDeliveryConfirmationButton = (orderId: number) => {
    const isDisabled =
      _.isEmpty(orderLines) ||
      orderLines.some(orderLine => orderLine.quantityRecieved <= 0);
    const isValidated = !_.isNull(orderHeader?.numBl);

    return (
      <StyledSpace direction='vertical' style={{ width: '100%' }}>
        {isValidated ? (
          <Alert
            type='success'
            showIcon
            message={`La livraison de la commande a été effectuée le ${orderHeader?.dateBl}`}
          />
        ) : (
          <>
            {isDisabled && (
              <Alert
                type='warning'
                showIcon
                message='Les articles doivent être réceptionnés au moins une fois avant de valider la livraison'
              />
            )}
            <StyledButton
              type='primary'
              size='middle'
              block
              onClick={() => validateDelivery(orderId)}
              disabled={isDisabled}>
              Valider la réception
            </StyledButton>
          </>
        )}
      </StyledSpace>
    );
  };

  const renderModalOrderDetails = () => {
    return (
      <StyledDrawer
        drawerTitle='Détails de la commande'
        isDrawerOpen={showDetailsDrawer}
        closeDrawer={handleModalDetailsOrder}
        placement='right'
        customWidth={800}
        extraActions={
          <>
            <StyledButton
              key='cancel_modal'
              type='primary'
              size='middle'
              danger
              disabled={isLoading}
              onClick={handleModalDetailsOrder}>
              Fermer
            </StyledButton>
          </>
        }>
        <div style={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
          <StyledRow gutter={[16, 16]} style={{ padding: 30 }}>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Informations de la commande' />
              <StyledSpace direction='vertical'>
                <StyledSpace>
                  <StyledPrimarytext>
                    N<sup>O</sup> Commande:
                  </StyledPrimarytext>
                  <div>{orderHeader?.num}</div>
                </StyledSpace>
                <StyledSpace>
                  <StyledPrimarytext>Date Commande:</StyledPrimarytext>
                  <div>{orderHeader?.createdDate}</div>
                </StyledSpace>
                {orderHeader?.numBl && (
                  <StyledSpace>
                    <StyledPrimarytext>
                      N<sup>O</sup> Bon de réception:
                    </StyledPrimarytext>
                    <div>{orderHeader?.numBl}</div>
                  </StyledSpace>
                )}
                {orderHeader?.dateBl && (
                  <StyledSpace>
                    <StyledPrimarytext>Date Bon de réception:</StyledPrimarytext>
                    <div>{orderHeader?.dateBl}</div>
                  </StyledSpace>
                )}
                <StyledSpace>
                  <StyledPrimarytext>Nom:</StyledPrimarytext>
                  <div>{orderHeader?.nameSupplier}</div>
                </StyledSpace>
                <StyledSpace>
                  <StyledPrimarytext>Adresse Email:</StyledPrimarytext>
                  <div>{orderHeader?.emailSupplier}</div>
                </StyledSpace>
                <StyledSpace>
                  <StyledPrimarytext>Adresse:</StyledPrimarytext>
                  <div>{orderHeader?.addressSupplier}</div>
                </StyledSpace>
              </StyledSpace>
            </StyledCol>
            <StyledCol span={12}></StyledCol>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Articles' />
              <Table
                rowKey='id'
                size='small'
                dataSource={orderLines}
                columns={columnDataOrderLines}
                pagination={false}
              />
            </StyledCol>

            <StyledCol span={24}>
              {renderDeliveryConfirmationButton(orderHeader?.id as number)}
            </StyledCol>
          </StyledRow>
        </div>
      </StyledDrawer>
    );
  };

  const renderModalReceiptOrder = () => {
    return (
      <StyledDrawer
        drawerTitle='Réception de la commande'
        isDrawerOpen={showReceiptDrawer}
        closeDrawer={handleModalReceiptOrder}
        placement='right'
        customWidth={1000}
        extraActions={
          <>
            <StyledButton
              key='cancel_modal'
              type='primary'
              size='middle'
              danger
              onClick={handleModalReceiptOrder}>
              Fermer
            </StyledButton>
          </>
        }>
        <PharmacyReceipt
          handleModal={() => {
            console.log('dd');
          }}
        />
      </StyledDrawer>
    );
  };

  const renderOrderPDF = () => {
    return (
      <PdfDrawerViewer
        drawerTitle={`Commande N${String.fromCharCode(176)} ${orderHeader?.num}`}
        closeDrawer={() => setShowOrderPdf(false)}
        extraActions={
          <>
            <StyledButton
              key='cancel_invoice'
              type='primary'
              size='middle'
              danger
              disabled={isLoading}
              onClick={() => handleModalOrderPDF()}>
              Quitter
            </StyledButton>
          </>
        }
        isDrawerOpen={showOrderPdf}>
        <RenderDocument<CreatePharmacyOrderResponse | undefined>
          documentData={{ ...(orderHeader as IPharmacyOrder), orderLines: orderLines }}
          documentType='PHARMACY_ARTICLE_ORDER'
          isDuplicata={false}
        />
      </PdfDrawerViewer>
    );
  };

  return (
    <>
      <StyledLayoutGridWrapper style={{ marginTop: 4 }}>
        <PageContentLeftLayout layerShowPadding='false'>
          {renderSearchFilters()}
        </PageContentLeftLayout>
        <PageContentRightLayout layerShowPadding='false'>
          <Loader showSpinner={isLoading}>
            <div style={{ overflowY: 'auto' }}>{renderContent()}</div>
          </Loader>
        </PageContentRightLayout>
      </StyledLayoutGridWrapper>

      {showDetailsDrawer === true && renderModalOrderDetails()}
      {showReceiptDrawer === true && renderModalReceiptOrder()}
      {showOrderPdf === true && renderOrderPDF()}
    </>
  );
};

export default OrdersArticles;
