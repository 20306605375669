import { useState } from 'react';
import { ISubscriberItem, ReferentialViewProps } from '../../../types';
import SubscriberForm from './SubscriberForm';
import SubscribersList from './SubscribersList';

const SubscriberSetting = () => {
  const [activeView, setActiveView] = useState<ReferentialViewProps>('LIST_ITEMS');
  const [selectedRatio, setSelectedRatio] = useState<ISubscriberItem>();

  const handleNavigateToView = (newView: ReferentialViewProps) => {
    setActiveView(newView);
  };

  const handleUpdateSelectedRatio = (item?: ISubscriberItem) => {
    setSelectedRatio(item);
  };

  const renderContent = () => {
    switch (activeView) {
      case 'LIST_ITEMS':
        return (
          <SubscribersList
            navigateToview={handleNavigateToView}
            updateCurrentItem={handleUpdateSelectedRatio}
          />
        );
      case 'EDIT_ITEM':
        return (
          <SubscriberForm
            navigateToview={handleNavigateToView}
            currentData={selectedRatio}
          />
        );
      case 'NEW_ITEM':
        return <SubscriberForm navigateToview={handleNavigateToView} />;
    }
  };

  return <>{renderContent()}</>;
};

export default SubscriberSetting;
