import { Alert, App as AppContainer, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import _ from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import { FiAlertTriangle } from 'react-icons/fi';
import {
  receivePharmacyOrderLinesApi,
  searchPharmacyOrderLinesApi,
} from '../../../api/pharmacyService';
import { StyledButton } from '../../../components/button/style';
import EmptyNotFound from '../../../components/emptyData/EmptyNotFound';
import Loader from '../../../components/loader';
import {
  StyledCardItem,
  StyledCol,
  StyledInputNumber,
  StyledRow,
  StyledSpace,
  StyledTag,
  StyledTooltip,
} from '../../../components/style';
import {
  RecieveArticlesReducer,
  getRecieveArticlesDefaultInitialState,
} from '../../../reducers/pharmacy/recieveArticles';
import chartColors from '../../../styles/color';
import {
  IPharmacyOrder,
  IPharmacyOrderLine,
  ReceivePharmacyOrderRequestBody,
} from '../../../types';
import { getApiErrorMsg, thousandSeparatorFormat } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { getDataFromStorage } from '../../../utils/storage';
import { StyleListContainer, StyledPrimarytext } from '../../style';

type PharmacyReceiptProps = {
  handleModal: () => void;
};

const PharmacyReceipt: React.FC<PharmacyReceiptProps> = () => {
  const [state, reducer] = useReducer(
    RecieveArticlesReducer,
    getRecieveArticlesDefaultInitialState(),
  );

  const { notification } = AppContainer.useApp();
  const orderHead = getDataFromStorage<IPharmacyOrder>('pharmacyOrderHead');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleUpdateReceiptOrder = (orderLineId: number, quantity: number) => {
    reducer({
      type: 'setQuantityArticleQteToRecieve',
      payload: { id: orderLineId, value: quantity },
    });
  };

  const getArticleQteToRecieve = (orderLineId: number) => {
    const index = _.findIndex(state.articles, line => line.id === orderLineId);
    if (index != -1) {
      return state.articles[index].quantityToReceive;
    }
    return 0;
  };

  const columnDataOrderLines: ColumnsType<
    IPharmacyOrderLine & { quantityToReceive: number }
  > = [
    {
      title: 'Désignation',
      dataIndex: 'designation',
      render: (_value, record) => (
        <StyledSpace>
          <span style={{ fontWeight: 600 }}>{record.designation}</span>
          {record.quantityOrdered - record.quantityRecieved === 0 ? (
            <StyledTag color={chartColors.danger}>
              Ne sera pas réceptionné{' '}
              <StyledTooltip
                color={chartColors.danger}
                title='La quantité commandée est égale à la quantité déjà reçue'>
                <BsInfoCircle />
              </StyledTooltip>
            </StyledTag>
          ) : record.quantityToReceive < 1 ? (
            <FiAlertTriangle color={chartColors.warning} />
          ) : null}
        </StyledSpace>
      ),
    },
    {
      title: 'PU',
      dataIndex: 'unitPrice',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
      width: 100,
    },
    {
      title: 'Qté Commandée',
      dataIndex: 'quantityOrdered',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
      width: 150,
    },
    {
      title: 'Qté déjà reçue',
      dataIndex: 'quantityRecieved',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
      width: 150,
    },
    {
      title: 'Qté reçue',
      align: 'right',
      dataIndex: 'quantityToReceive',
      render: (_value, record) => (
        <StyledInputNumber
          style={{ width: '100%' }}
          max={record.quantityOrdered - record.quantityRecieved}
          min={0}
          onChange={qte => handleUpdateReceiptOrder(record.id as number, qte as number)}
          disabled={record.quantityOrdered - record.quantityRecieved <= 0}
          value={getArticleQteToRecieve(record.id as number)}
        />
      ),
      width: 200,
    },
    {
      title: 'TVA',
      dataIndex: 'amountTVA',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
      width: 100,
    },
    {
      title: 'Total HT',
      dataIndex: 'amountHT',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
      width: 100,
    },
    {
      title: 'Montant TTC',
      dataIndex: 'amountTTC',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
      width: 100,
    },
  ];

  const loadOrderlines = async (orderId: number) => {
    setIsLoading(true);
    try {
      searchPharmacyOrderLinesApi(orderId).then(res => {
        const orderLinesArticles = res.map(orderLine => ({
          ...orderLine,
          quantityToReceive: 0,
        }));
        reducer({
          type: 'setArticlesToRecieve',
          payload: orderLinesArticles,
        });
        setIsLoading(false);
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    }
  };

  const handleReceiveArticle = () => {
    const orderLinesToReceipt = state.articles.filter(
      orderLine =>
        orderLine.quantityOrdered > orderLine.quantityRecieved &&
        orderLine.quantityToReceive > 0,
    );

    const isValid = !_.isEmpty(orderLinesToReceipt);

    if (isValid === true) {
      const receivedOrderLines: ReceivePharmacyOrderRequestBody = state.articles.map(
        orderLine => ({
          idOrderline: orderLine.id as number,
          quantityReceived: orderLine.quantityToReceive,
        }),
      );

      setIsLoading(true);

      try {
        receivePharmacyOrderLinesApi(receivedOrderLines).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: 'Articles réceptionnés avec succès',
          });
          setIsLoading(false);
          loadOrderlines(orderHead.id);
          // navigate('/pharmacie');
        });
      } catch (error) {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      }
    } else {
      notification.warning({
        message: ErrorMsgTitle,
        description:
          "Impossible de réceptionner les articles, veuillez vous assurer qu'au moins un article ai une quantité reçue supérieur à 0",
      });
    }
  };

  useEffect(() => {
    if (!_.isNull(orderHead)) {
      loadOrderlines(orderHead.id);
    }
  }, []);

  const renderReceiptHeader = () => {
    return (
      <StyledCardItem
        styles={{ header: { backgroundColor: chartColors.lightGray } }}
        title='Informations de la commande'>
        <StyledSpace direction='vertical'>
          <StyledSpace>
            <StyledPrimarytext>
              N<sup>O</sup> Commande:
            </StyledPrimarytext>
            <div>{orderHead?.num}</div>
          </StyledSpace>
          <StyledSpace>
            <StyledPrimarytext>Date Commande:</StyledPrimarytext>
            <div>{orderHead?.createdDate}</div>
          </StyledSpace>
          <StyledSpace>
            <StyledPrimarytext>Nom:</StyledPrimarytext>
            <div>{orderHead.nameSupplier}</div>
          </StyledSpace>
          <StyledSpace>
            <StyledPrimarytext>Adresse Email:</StyledPrimarytext>
            <div>{orderHead.emailSupplier}</div>
          </StyledSpace>
          <StyledSpace>
            <StyledPrimarytext>Adresse:</StyledPrimarytext>
            <div>{orderHead.addressSupplier}</div>
          </StyledSpace>
        </StyledSpace>
      </StyledCardItem>
    );
  };

  const renderReceiptArticles = () => {
    return _.isNull(orderHead) ? (
      <EmptyNotFound
        message='Aucune commande receptionnée'
        description='Veuillez réceptionner une commande dans la liste des commandes'
      />
    ) : _.isEmpty(state.articles) ? (
      <EmptyNotFound
        message='Aucun article trouvé'
        description='Cette commande ne contient aucun article'
      />
    ) : (
      <Loader showSpinner={isLoading}>
        <StyledSpace direction='vertical' style={{ width: '100%' }}>
          <Alert
            type='warning'
            showIcon
            icon={<FiAlertTriangle color={chartColors.warning} />}
            message={
              <>
                Les articles avec <b>0</b> comme <b>quantité reçue</b> ne seront pas
                sélectionnés
              </>
            }
          />
          <Table
            rowKey='id'
            size='small'
            dataSource={state.articles}
            columns={columnDataOrderLines}
            pagination={false}
            rowClassName={record =>
              record.quantityOrdered - record.quantityRecieved === 0
                ? 'not_receive_row'
                : ' '
            }
          />
        </StyledSpace>
      </Loader>
    );
  };

  const renderValidateButton = () => {
    return (
      <StyledButton
        type='primary'
        onClick={handleReceiveArticle}
        disabled={isLoading}
        loading={isLoading}>
        Valider la réception
      </StyledButton>
    );
  };

  const renderContent = () => {
    return (
      <StyleListContainer
        forceVisible='y'
        autoHide={false}
        style={{
          padding: '20px',
          height: '100%',
          paddingBottom: '150px',
        }}>
        <StyledRow gutter={[16, 16]}>
          <StyledCol span={24}>{!_.isNull(orderHead) && renderReceiptHeader()}</StyledCol>
          <StyledCol span={24}>{renderReceiptArticles()}</StyledCol>
          <StyledCol span={24}>
            <StyledSpace>
              {!_.isEmpty(state.articles) && renderValidateButton()}
            </StyledSpace>
          </StyledCol>
        </StyledRow>
      </StyleListContainer>
    );
  };

  return renderContent();
};

export default PharmacyReceipt;
