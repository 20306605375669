import medicalRecordMock from '../mocks/getMedicalRecordsMock.json';
import {
  CreateAntecedentApiRequestBody,
  CreateDiagnosticRequestBody,
  CreateEvolutionRequestBody,
  CreateInterrogatoireRequestBody,
  CreateMedicalRecordApiRequestBody,
  CreateMedicalRecordApiResponse,
  CreateParaclinicalApiRequestBody,
  CreatePhysicalRequestBody,
  GetAntecedentByMedicalRecordResponse,
  GetDiagnosticByMedicalRecordResponse,
  GetEvolutionByMedicalRecordResponse,
  GetExaminationByMedicalRecordResponse,
  GetInvoiceRubricsResponse,
  GetMedicalRecordResponseConsultation,
  GetParaclinicalHeadByMedicalRecordResponse,
  GetParaclinicalLinesByParaclinicalHeadResponse,
  GetPhysicalByMedicalRecordResponse,
  MedicalRecordDetailsApiResponse,
} from '../types';
import { axiosInstance } from './axiosInstance';

export const createMedicalRecordApi = (
  data: CreateMedicalRecordApiRequestBody,
): Promise<CreateMedicalRecordApiResponse> => {
  return axiosInstance
    .post('/medicalRecord', { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création du dossier patient. Raison: ${message}`);
    });
};

export const getMedicalRecordDetailsByIdsApi = (
  data: Array<number>,
): Promise<MedicalRecordDetailsApiResponse> => {
  return axiosInstance
    .post('/medicalRecordDetailByIds', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la récupération des détails des dossiers patient. Raison: ${message}`,
      );
    });
};

export const getMedicalRecordsApi = (): Promise<
  GetMedicalRecordResponseConsultation[]
> => {
  return new Promise(resolve => {
    const result: GetMedicalRecordResponseConsultation[] =
      medicalRecordMock as GetMedicalRecordResponseConsultation[];
    setTimeout(() => {
      resolve(result);
    }, 1000);
  });

  /* return axiosInstance
    .get(`/getMedicalRecordsByPatient?patient=${patientId}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération des consulations du patient ${patientId}. Raison: ${message}`,
      );
    }); */
};

export const createInterrogatoireApi = (
  data: CreateInterrogatoireRequestBody,
): Promise<void> => {
  return axiosInstance
    .post('/examination', { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création de l'interrogatoire. Raison: ${message}`);
    });
};

export const updateInterrogatoireApi = (
  idMedicalRecord: number,
  data: CreateInterrogatoireRequestBody,
): Promise<void> => {
  return axiosInstance
    .put(`/examination?id=${idMedicalRecord}`, { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la mise a jour de l'interrogatoire. Raison: ${message}`,
      );
    });
};

export const createAntecedentApi = (
  data: CreateAntecedentApiRequestBody,
): Promise<void> => {
  return axiosInstance
    .post('/antecedents', { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création de antecedent. Raison: ${message}`);
    });
};

export const updateAntecedentApi = (
  data: CreateAntecedentApiRequestBody,
): Promise<void> => {
  return axiosInstance
    .put('/antecedents', { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la modification de antecedent. Raison: ${message}`);
    });
};

export const createPhysicalApi = (data: CreatePhysicalRequestBody): Promise<void> => {
  return axiosInstance
    .post('/physical', { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création de physiqye. Raison: ${message}`);
    });
};

export const updatePhysicalApi = (
  idMedicalRecord: number,
  data: CreatePhysicalRequestBody,
): Promise<void> => {
  return axiosInstance
    .put(`/physical?id=${idMedicalRecord}`, { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création de physiqye. Raison: ${message}`);
    });
};

export const getPhysicalByMedicalRecord = (
  idMedicalRecord: number,
): Promise<GetPhysicalByMedicalRecordResponse> => {
  return axiosInstance
    .get(`/physicalByIdMedicalRecord?id=${idMedicalRecord}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération de physical. Raison: ${message}`);
    });
};

export const createDiagnosticApi = (data: CreateDiagnosticRequestBody): Promise<void> => {
  return axiosInstance
    .post('/diagnostic', { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création de diagnostic. Raison: ${message}`);
    });
};

export const updateDiagnosticApi = (
  idMedicalRecord: number,
  data: CreateDiagnosticRequestBody,
): Promise<void> => {
  return axiosInstance
    .put(`/diagnostic?idMedicalRecord=${idMedicalRecord}`, { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la mise à jour de diagnostic. Raison: ${message}`);
    });
};

export const getDiagnosticbyMedicalRecord = (
  idMedicalRecord: number,
): Promise<GetDiagnosticByMedicalRecordResponse> => {
  return axiosInstance
    .get(`/diagnosticByIdMedicalRecord?idMedicalRecord=${idMedicalRecord}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération du diagnostic. Raison: ${message}`);
    });
};

export const createEvolutionApi = (data: CreateEvolutionRequestBody): Promise<void> => {
  return axiosInstance
    .post('/evolution', { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création d'evolution. Raison: ${message}`);
    });
};

export const updateEvolutionApi = (
  idMedicalRecord: number,
  data: CreateEvolutionRequestBody,
): Promise<void> => {
  return axiosInstance
    .put(`/evolution?idMedicalRecord=${idMedicalRecord}`, { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la mise à jour d'evolution. Raison: ${message}`);
    });
};

export const getEvolutionByMedicalRecord = (
  idMedicalRecord: number,
): Promise<GetEvolutionByMedicalRecordResponse> => {
  return axiosInstance
    .get(`/evolutionByIdMedicalRecord?idMedicalRecord=${idMedicalRecord}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération d'evolution. Raison: ${message}`);
    });
};

export const getExaminationsByMedicalRecord = (
  idMedicalRecord: number,
): Promise<GetExaminationByMedicalRecordResponse> => {
  return axiosInstance
    .get(`/examinationByIdMedicalRecord?id=${idMedicalRecord}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération des interrogatoires. Raison: ${message}`,
      );
    });
};

export const getAntecedentByMedicalRecord = (
  idMedicalRecord: number,
): Promise<GetAntecedentByMedicalRecordResponse> => {
  return axiosInstance
    .get(`/antecedentsByIdMedicalRecord?id=${idMedicalRecord}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des antécédents. Raison: ${message}`);
    });
};

export const getMedicalRecordsByPatient = (
  patientId: number,
): Promise<Array<CreateMedicalRecordApiResponse>> => {
  return axiosInstance
    .get(`/medicalRecordsByPatient?patient=${patientId}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération des dossiers du patient ${patientId}. Raison: ${message}`,
      );
    });
};

export const createParaclinicalApi = (
  data: CreateParaclinicalApiRequestBody,
): Promise<void> => {
  const formData = new FormData();
  data.file.forEach(item => {
    formData.append('files', item.originFileObj as Blob, item.name);
  });
  formData.append('idMedicalrecord', data.idMedicalRecord);
  formData.append('comment', data.comments);

  return axiosInstance
    .post('/createParaclinical', formData, {
      headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' },
    })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création de paraclinical. Raison: ${message}`);
    });
};

export const getParaclinicalHeadByMedicalRecord = (
  idMedicalRecord: number,
): Promise<GetParaclinicalHeadByMedicalRecordResponse> => {
  return axiosInstance
    .get(`/paraclinicalHeadByIdMedicalRecord?id=${idMedicalRecord}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération des praclinical head. Raison: ${message}`,
      );
    });
};

export const getParaclinicalLinesByIdParaclinicalHead = (
  idParaclinicalHead: number,
): Promise<GetParaclinicalLinesByParaclinicalHeadResponse> => {
  return axiosInstance
    .get(
      `/paraclinicalLinesByIdParaclinicalHead?idParaclinicalHead=${idParaclinicalHead}`,
    )
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération des praclinical head. Raison: ${message}`,
      );
    });
};

export const getConsultationsRubricsApi = (): Promise<GetInvoiceRubricsResponse> => {
  return axiosInstance
    .get('/consultationsRubrics')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération des rubriques de consultation Raison: ${message}`,
      );
    });
};

export const getMedicalRecordsByDoctorApi = (
  idUser: number,
): Promise<Array<CreateMedicalRecordApiResponse>> => {
  return axiosInstance
    .get(`/medicalRecordsByDoctor?idUser=${idUser}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des dossiers par médecin: ${message}`);
    });
};
