/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unescaped-entities */
import { App as AppContainer, Input, InputRef, MenuProps, Modal } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { FilterConfirmProps, FilterDropdownProps } from 'antd/es/table/interface';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { AiFillMoneyCollect } from 'react-icons/ai';
import { BsExclamationTriangleFill, BsThreeDots } from 'react-icons/bs';
import { FiCheck, FiCopy, FiFilePlus, FiRefreshCcw, FiSearch } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  createCreditNoteProformaApi,
  createInvoiceApi,
  getDuplicataApi,
  searchInvoiceApi,
} from '../../api/invoiceService';
import { getPatients } from '../../api/patientService';
import { StyledButton } from '../../components/button/style';
import FormFieldLabel from '../../components/label/FormFieldLabel';
import PageHeader from '../../components/pageHeader/PageHeader';
import {
  StyledCheckbox,
  StyledCol,
  StyledDatePicker,
  StyledDropdown,
  StyledRow,
  StyledSelectOption,
  StyledSelectPicker,
  StyledSpace,
  StyledTag,
} from '../../components/style';
import PdfDrawerViewer from '../../components/viewer/PdfDrawerViewer';
import dayjsInstance from '../../dayjs';
import PageContentLeftLayout from '../../layout/PageContentLeftLayout';
import PageContentRightLayout from '../../layout/PageContentRightLayout';
import VerticalLayout from '../../layout/VerticalLayout';
import { StyledLayoutGridWrapper } from '../../layout/style';
import chartColors from '../../styles/color';
import {
  CreateCreditNoteProformaRequestBody,
  CreateProformaResponse,
  GetPatientApiResponse,
  GetRubricsByInvoiceIdResponse,
  IBiologicalExamItem,
  IImageryExamItem,
  InvoiceCreationType,
  PharmacyInvoiceLine,
  SearchInvoiceApiRequestBody,
  SearchInvoiceApiResponse,
  UserDto,
} from '../../types';
import {
  getApiErrorMsg,
  getColumnSearchProps,
  thousandSeparatorFormat,
} from '../../utils/commun';
import { DATE_FORMAT, ErrorMsgTitle, SuccesssMsgTitle } from '../../utils/constants';
import { AVOIR_PERM } from '../../utils/permissions';
import { getDataFromStorage } from '../../utils/storage';
import RenderDocument from '../document';
import { StyledBlocContainer } from '../style';

type InvoiceProps = SearchInvoiceApiResponse & {
  idInsurance: number;
  insuranceLabel: string;
  idSubscriber: number;
  subscriberLabel: string;
  invoicesLines: GetRubricsByInvoiceIdResponse[];
  biologicalExams: IBiologicalExamItem[];
  imageryExams: IImageryExamItem[];
  invoicePharmayLine: PharmacyInvoiceLine[];
};

type DataIndex = keyof InvoiceProps;

const invoiceLinesMenuItems: MenuProps['items'] = [
  {
    label: 'Duplicata',
    key: '1',
    icon: <FiCopy />,
  },
];

const Invoice: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [modal, contextHolder] = Modal.useModal();
  const user: UserDto = getDataFromStorage<UserDto>('connectedUserData');
  const userRights: Array<string> = user.rights.map(r => r.code);

  const { notification } = AppContainer.useApp();

  // const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  // const [selectedInvoicesRow, setSelectedInvoicesRow] = useState<InvoiceProps[]>([]);
  const [, setSearchText] = useState('');
  const [, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patientsList, setPatientsList] = useState<GetPatientApiResponse[]>([]);

  // Search Invoice Filters state
  const [dateDebut, setDateDebut] = useState<string>(
    location.state?.dateDebut ?? dayjsInstance().subtract(1, 'days').format(DATE_FORMAT),
  );
  const [dateEnd, setDateEnd] = useState<string>(
    location.state?.dateEnd ?? dayjsInstance().format(DATE_FORMAT),
  );
  const [idPatient, setIdPatient] = useState<number | null>(null);
  const [isInvoice, setIsInvoice] = useState<boolean>(true);
  const [isCreditNote, setIsCreditNote] = useState<boolean>(false);
  const [isProforma, setIsProforma] = useState<boolean>(true);
  const [invoiceData, setInvoiceData] = useState<InvoiceProps[]>([]);

  const [showInvoicePdf, setShowInvoicePdf] = useState<boolean>(false);
  const [invoicePdfData, setInvoicePdfData] = useState<
    CreateProformaResponse | InvoiceProps
  >();

  const [showProformaPdf, setShowProformaPdf] = useState<boolean>(false);
  const [proformaPdfData, setProformaPdfData] = useState<
    CreateProformaResponse | InvoiceProps
  >();
  // const [showProformaPdfActions, setShowProformaPdfActions] = useState<boolean>(true);

  const [isDuplicata, setIsDuplicata] = useState<boolean>(false);
  const [isLoadingDuplicata, setIsLoadingDuplicata] = useState<boolean>(false);

  const [validatedInvoiceTitle, setValidatedInvoiceTitle] = useState<'FACTURE' | 'AVOIR'>(
    'FACTURE',
  );

  /**
   * Listener lorsqu'une ligne du tableau est sélectionné/dé-sélectionné
   * @param newSelectedRowKeys
   * @param selectedRows
   */
  // const onSelectFacturationChange = (
  //   newSelectedRowKeys: React.Key[],
  //   selectedRows: InvoiceProps[],
  // ) => {
  //   setSelectedRowKeys(newSelectedRowKeys);
  //   setSelectedInvoicesRow(selectedRows);
  // };

  /**
   * Recherche Un élement dans une colonne du tableau de factures
   * @param selectedKeys
   * @param confirm
   * @param dataIndex
   */
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  /**
   * Reinitialiser les filtres de colonnes du tableau de factures
   * @param clearFilters
   */
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  /**
   * Champ de filtre interne au niveau d'une colonne du tableau
   * @param param0
   * @param dataIndex
   * @param placeholder
   * @returns
   */
  const columnFilterComponent = (
    { selectedKeys, setSelectedKeys, confirm, clearFilters }: FilterDropdownProps,
    dataIndex: DataIndex,
    placeholder: string,
  ) => {
    return (
      <>
        <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Rechercher ${placeholder}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: 'block' }}
          />
          <StyledSpace>
            <StyledButton
              type='primary'
              onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
              icon={<FiSearch />}
              size='small'
              block>
              Rechercher
            </StyledButton>
            <StyledButton
              onClick={() => clearFilters && handleReset(clearFilters)}
              size='small'
              htmlType='reset'
              style={{ width: 90 }}>
              Vider
            </StyledButton>
          </StyledSpace>
        </div>
      </>
    );
  };

  /**
   * Lancer la requete de recherche de facture
   */
  const searchInvoice = async () => {
    setIsLoading(true);

    const body: SearchInvoiceApiRequestBody = {
      dateDebut: dateDebut,
      dateEnd: dateEnd,
      idPatient: idPatient,
      invoice: isInvoice,
      proforma: isProforma,
      creditNote: isCreditNote,
      dateDebutPayment: null,
      dateEndPayment: null,
      num: null,
    };
    try {
      const result = await searchInvoiceApi(body);
      // setItems(result.patients as SearchPatientResult[]);
      setInvoiceData(result as InvoiceProps[]);
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description:
          typeof error === 'object' ? JSON.stringify(error) : (error as string),
      });
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Réinitialiser le formulaire de recherche de facture
   */
  const resetSearchInvoice = () => {
    // Reset Search patient filters
    setIdPatient(null);
    // setNumInvoice(null);
    setIsInvoice(true);
    setIsProforma(true);
    setIsCreditNote(false);
    setDateDebut(dayjsInstance().subtract(1, 'days').format(DATE_FORMAT));
    setDateEnd(dayjsInstance().format(DATE_FORMAT));

    // Reset invoice selected in table
    // setSelectedRowKeys([]);
    // setSelectedInvoicesRow([]);

    searchInvoice();
  };

  const handleMenuClick = (menu, selectedInvoiceLine: InvoiceProps) => {
    switch (menu.key) {
      case '1': // Duplicate Invoice
        handleCreateDuplicata(selectedInvoiceLine);
        break;
      case '2': // Validate Invoice
        handleConfirmValidateInvoice(selectedInvoiceLine);
        break;
      case '3': // Avoir Invoice
        handleConfirmCreditNote(selectedInvoiceLine);
        break;

      default:
        break;
    }
  };

  /**
   * lance la requete de chargement de la liste des patients
   */
  const loadPatients = () => {
    setIsLoading(true);
    Promise.resolve(getPatients())
      .then(result => {
        setPatientsList(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Lance la requete de validation de la facture sélectionné
   */
  const handleValidateInvoice = async (selectedInvoice: InvoiceProps) => {
    try {
      setIsLoading(true);
      const result = await createInvoiceApi({
        createdBy: user?.id,
        idProforma: selectedInvoice.id as number,
        typeInvoice: selectedInvoice.amountTotal < 0 ? 'CREDIT_NOTE' : 'INVOICE',
      });
      notification.success({
        message: SuccesssMsgTitle,
        description: 'La facture a bien été créé.',
      });
      setInvoicePdfData(result);
      setValidatedInvoiceTitle('FACTURE');
      setShowInvoicePdf(true);
      resetSearchInvoice();
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Modal de Confirmation de validation de facture
   */
  const handleConfirmValidateInvoice = (selectedInvoice: InvoiceProps) => {
    modal.confirm({
      title: 'Confirmation de validation',
      icon: <BsExclamationTriangleFill size={40} color={chartColors.warning} />,
      content: `Êtes-vous sûr de vouloir valider la facture ${selectedInvoice.numProforma} ?`,
      okText: 'Oui, je Confirme',
      cancelText: 'Annuler',
      onOk: () => handleValidateInvoice(selectedInvoice),
    });
  };

  useEffect(() => {
    searchInvoice();
    loadPatients();
  }, []);

  const createCreditNote = async (selectedInvoice: InvoiceProps) => {
    const data: CreateCreditNoteProformaRequestBody = {
      createdBy: user?.id,
      idInvoice: selectedInvoice.id,
    };
    try {
      setIsLoading(true);
      const result = await createCreditNoteProformaApi(data);
      notification.success({
        message: SuccesssMsgTitle,
        description: "L'avoir a bien été créé.",
      });
      // console.log(result);
      setIsDuplicata(false);
      setProformaPdfData(result);
      setShowProformaPdf(true);
      resetSearchInvoice();
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmCreditNote = (selectedInvoice: InvoiceProps) => {
    modal.confirm({
      title: "Confirmation d'avoir",
      icon: <BsExclamationTriangleFill size={40} color={chartColors.warning} />,
      content: `Êtes-vous sûr de créer un avoir pour la facture ${selectedInvoice.numProforma} ?`,
      okText: 'Oui, je Confirme',
      cancelText: 'Annuler',
      onOk: () => createCreditNote(selectedInvoice),
    });
  };

  const createInvoice = async (invoiceType: InvoiceCreationType) => {
    try {
      setIsLoading(true);
      const result = await createInvoiceApi({
        createdBy: user?.id,
        idProforma: proformaPdfData?.id as number,
        typeInvoice: invoiceType,
      });
      notification.success({
        message: SuccesssMsgTitle,
        description: 'La facture a bien été créé.',
      });
      setInvoicePdfData(result);
      setValidatedInvoiceTitle('AVOIR');
      setShowProformaPdf(false);
      setShowInvoicePdf(true);

      // navigate('/facturation');
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
    } finally {
      setIsLoading(false);
    }
  };

  function getMenuItems(record: InvoiceProps) {
    const menuItems: MenuProps['items'] = [];

    // Add invoice lines menu items
    menuItems.push(...(invoiceLinesMenuItems as any));

    // Add "Avoir" or "Valider" option based on conditions
    if (_.isNull(record.numInvoiceRef)) {
      if (record.idInvoiceRef <= 0 && record.treated === true) {
        if (AVOIR_PERM.some(p => userRights.includes(p))) {
          menuItems.push({
            label: 'Avoir',
            key: '3',
            icon: <AiFillMoneyCollect />,
          });
        }
      } else {
        menuItems.push({
          label: 'Valider',
          key: '2',
          icon: <FiCheck />,
        });
      }
    } else if (!record.treated) {
      menuItems.push({
        label: 'Valider',
        key: '2',
        icon: <FiCheck />,
      });
    }

    return menuItems;
  }
  /**
   * Colonnes du tableau de factures
   */
  const columnData: ColumnsType<InvoiceProps> = [
    {
      title: 'N° Proforma',
      dataIndex: 'numProforma',
      ...getColumnSearchProps({
        dataIndex: 'numProforma',
        filterDropdownComponent: filterProps =>
          columnFilterComponent(filterProps, 'numProforma', 'Numéro'),
      }),
    },
    {
      title: 'N° Facture',
      dataIndex: 'numInvoice',
      ...getColumnSearchProps({
        dataIndex: 'numInvoice',
        filterDropdownComponent: filterProps =>
          columnFilterComponent(filterProps, 'numInvoice', 'Numéro'),
      }),
    },
    {
      title: 'Date Facture',
      dataIndex: 'createdDate',
    },
    {
      title: 'N° Dossier',
      dataIndex: 'numMedicalRecord',
      width: 150,
      ...getColumnSearchProps({
        dataIndex: 'numMedicalRecord',
        filterDropdownComponent: filterProps =>
          columnFilterComponent(filterProps, 'numMedicalRecord', 'Numéro de dossier'),
      }),
    },
    {
      title: 'Patient',
      dataIndex: 'idPatient',
      render: (value, record) => (
        <>
          {record.patientFirstname} {record.patientName}
        </>
      ),
    },
    {
      title: 'Total Assur.',
      dataIndex: 'amountAssu',
      align: 'left',
      render: value => thousandSeparatorFormat(value),
      width: 100,
    },
    {
      title: 'Total Patient',
      dataIndex: 'amountPatient',
      align: 'left',
      render: value => thousandSeparatorFormat(value),
      width: 100,
    },
    {
      title: 'Total',
      dataIndex: 'amountTotal',
      align: 'left',
      render: value => thousandSeparatorFormat(value),
      width: 100,
    },
    {
      title: 'Statut',
      dataIndex: 'treated',
      align: 'left',
      width: 100,
      render: (value, record) => (
        <>
          {record.datePayment && value === true ? (
            <StyledTag color='warning'>Payée</StyledTag>
          ) : value === true ? (
            <StyledTag color='success'>Validé</StyledTag>
          ) : (
            <StyledTag color='volcano'>Non traité</StyledTag>
          )}
        </>
      ),
    },
    {
      render: (_value, record) => (
        <StyledSpace>
          <StyledDropdown
            menu={{
              items: getMenuItems(record),
              onClick: e => handleMenuClick(e, record),
            }}
            trigger={['click', 'hover']}>
            <StyledButton icon={<BsThreeDots />} shape='circle' />
          </StyledDropdown>
        </StyledSpace>
      ),
    },
  ];

  /**
   * Gerer l'état des checkbox au niveau de chaque ligne du tableau de facture
   */
  // const facturationRowSelection: TableRowSelection<InvoiceProps> = {
  //   selectedRowKeys,
  //   getCheckboxProps: (record: InvoiceProps) => ({
  //     disabled: selectedRowKeys.length === 1 && !selectedRowKeys.includes(record.id),
  //     // (record.treated === true && record.idInvoiceRef > 0) ||
  //     // name: record.invoicePlanHeadLabel,
  //   }),
  //   onChange: onSelectFacturationChange,
  //   selections: [Table.SELECTION_NONE],
  //   hideSelectAll: true,
  // };

  /**
   * Affiche le formulaire de filters de la requete de recherche de factures
   * @returns JSX.Element
   */
  const renderInvoiceSearchFilters = () => {
    return (
      <StyledBlocContainer
        style={{
          backgroundColor: `${chartColors.blue50}20`,
          padding: '20px 20px',
        }}>
        <StyledRow gutter={[12, 12]}>
          <StyledCol span={24}>
            <FormFieldLabel libelle='Date de création' />
            <StyledDatePicker.RangePicker
              name='creationDate'
              placeholder={['Date de debut', 'Date de fin']}
              size='large'
              allowClear
              style={{ width: '100%' }}
              onChange={(value, dateString) => {
                setDateDebut(dateString[0]);
                setDateEnd(dateString[1]);
              }}
              value={[
                _.isEmpty(dateDebut) ? null : dayjsInstance(dateDebut, DATE_FORMAT),
                _.isEmpty(dateEnd) ? null : dayjsInstance(dateEnd, DATE_FORMAT),
              ]}
              // disabledDate={current => dayjsInstance().isBefore(current, 'day')}
              format={DATE_FORMAT}
            />
          </StyledCol>

          {/* <StyledCol span={6}>
            <FormFieldLabel libelle='Numéro de facture' />
            <CustomInput
              name='numInvoice'
              isFocused={false}
              placeholder='Numéro de facture'
              type='text'
              handleInputChange={val => setNumInvoice(val)}
              width='100%'
              value={numInvoice}
              isDisabled={isLoading}
            />
          </StyledCol> */}

          <StyledCol span={24}>
            <FormFieldLabel libelle='Patient' />
            <StyledSelectPicker
              disabled={isLoading}
              placeholder='Rechercher par nom, CNI ou Numéro patient'
              onClear={() => setIdPatient(null)}
              style={{ width: '100%' }}
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value: any) => {
                setIdPatient(value);
              }}
              defaultValue={''}
              value={idPatient}>
              {_.map(patientsList, item => (
                <StyledSelectOption
                  key={item.id}
                  value={item.id}
                  data={item}
                  label={`${item.firstname} ${item.name} | ${item.cni} | ${item.numPatient}`}>
                  <StyledSpace split='|'>
                    <span style={{ fontWeight: 700 }}>{item.numPatient}</span>
                    {`${item.firstname} ${item.name}`}
                    <span style={{ fontWeight: 700 }}>{item.cni}</span>
                  </StyledSpace>
                </StyledSelectOption>
              ))}
            </StyledSelectPicker>
          </StyledCol>

          <StyledCol span={24}>
            <FormFieldLabel libelle='Statut' />
            <StyledCheckbox
              disabled={isLoading}
              checked={isProforma}
              onChange={e => setIsProforma(e.target.checked)}>
              Proforma
            </StyledCheckbox>
            <StyledCheckbox
              checked={isInvoice}
              onChange={e => setIsInvoice(e.target.checked)}>
              Facture
            </StyledCheckbox>

            <StyledCheckbox
              checked={isCreditNote}
              onChange={e => setIsCreditNote(e.target.checked)}>
              Avoir
            </StyledCheckbox>
          </StyledCol>

          <StyledCol span={24}>
            <StyledSpace style={{ width: '100%' }}>
              <StyledButton
                type='primary'
                size='middle'
                block
                disabled={isLoading}
                onClick={() => searchInvoice()}>
                Rechercher
              </StyledButton>
              <StyledButton
                type='link'
                htmlType='reset'
                size='middle'
                disabled={isLoading}
                icon={<FiRefreshCcw />}
                onClick={() => resetSearchInvoice()}
              />
            </StyledSpace>
          </StyledCol>
        </StyledRow>
      </StyledBlocContainer>
    );
  };

  /**
   * Afficher le duplicata d'une Facture/Proforma/Avoir
   */
  const handleCreateDuplicata = async (selectedInvoice: InvoiceProps) => {
    setIsLoadingDuplicata(true);
    setIsDuplicata(true);

    const selectedInvoiceId = selectedInvoice.id;
    const selectedInvoiceRefId = selectedInvoice.idInvoiceRef;
    const selectedInvoiceTreated = selectedInvoice.treated;

    Promise.resolve(getDuplicataApi(selectedInvoiceId))
      .then(result => {
        // selectedInvoice.numPatient = result.numPatient;
        // selectedInvoice.bonNumber = result.bonNumber;
        // selectedInvoice.invoicesLines = result.invoicesLines;
        // selectedInvoice.biologicalExams = result.invoiceLineBiologicalDto;
        // selectedInvoice.imageryExams = result.invoiceLineImageryDto;
        // selectedInvoice.invoicePharmayLine = result.invoicePharmayLine;

        if (selectedInvoiceRefId > 0 && selectedInvoiceTreated === true) {
          // AVOIR
          setValidatedInvoiceTitle('AVOIR');
          setInvoicePdfData(result);
          setShowInvoicePdf(true);
        } else if (selectedInvoiceRefId <= 0 && selectedInvoiceTreated === true) {
          // FACTURE
          setValidatedInvoiceTitle('FACTURE');
          setInvoicePdfData(result);
          setShowInvoicePdf(true);
        } else {
          // PROFORMA
          setProformaPdfData(result);
          setShowProformaPdf(true);
        }
      })
      .catch(error => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      })
      .finally(() => {
        setIsLoadingDuplicata(false);
      });
  };

  const resetProformaPdfViewer = () => {
    setShowProformaPdf(false);
    setProformaPdfData(undefined);
    setIsDuplicata(false);
  };

  const resetInvoicePdfViewer = () => {
    setShowInvoicePdf(false);
    setInvoicePdfData(undefined);
    setIsDuplicata(false);

    // resetSearchInvoice();
    searchInvoice();
  };

  /**
   * Afficher les actions du drawer du Proforma PDF
   * @returns JSX.Element
   */
  const renderProformaPdfActions = () => {
    return isDuplicata ? (
      <StyledButton
        key='cancel_invoice'
        type='primary'
        size='middle'
        danger
        disabled={isLoading}
        onClick={() => resetProformaPdfViewer()}>
        Quitter
      </StyledButton>
    ) : (
      <>
        <StyledButton
          key='create_invoice'
          type='primary'
          size='middle'
          loading={isLoading}
          disabled={isLoading}
          onClick={() => createInvoice(InvoiceCreationType.CREDIT_NOTE)}>
          Créer l'avoir
        </StyledButton>
        <StyledButton
          key='cancel_invoice'
          type='primary'
          size='middle'
          danger
          disabled={isLoading}
          onClick={() => resetProformaPdfViewer()}>
          Quitter
        </StyledButton>
      </>
    );
  };

  /**
   * Afficher les actions "Nouveau", "Duplicata", "Valider","Avoir
   * @returns JSX.Element
   */
  const renderHeaderActions = () => {
    return (
      <StyledButton
        type='primary'
        icon={<FiFilePlus />}
        size='middle'
        onClick={() => navigate('/facturation/facturation-proforma')}>
        Nouveau
      </StyledButton>
    );
  };

  /**
   * Affiche L'entête de la page
   * @returns
   */
  const renderHeader = () => {
    return (
      <>
        <PageHeader title='Facturation'> {renderHeaderActions()} </PageHeader>
      </>
    );
  };

  /**
   * Affiche le contenu de la page
   * @returns JSX.Element
   */
  const renderContent = () => {
    return (
      <>
        <Table<InvoiceProps>
          rowKey='id'
          dataSource={invoiceData}
          size='small'
          columns={columnData}
          bordered={false}
          loading={isLoadingDuplicata || isLoading}
          // rowSelection={facturationRowSelection}
        />
      </>
    );
  };

  return (
    <>
      <VerticalLayout enableVerticalScroll='false'>
        {renderHeader()}
        <StyledLayoutGridWrapper>
          <PageContentLeftLayout layerShowPadding='false'>
            {renderInvoiceSearchFilters()}
          </PageContentLeftLayout>
          <PageContentRightLayout layerShowPadding='false'>
            <div style={{ overflowY: 'auto' }}>{renderContent()}</div>
          </PageContentRightLayout>
        </StyledLayoutGridWrapper>
      </VerticalLayout>

      {/* Afficher les proforma*/}
      {showProformaPdf && (
        <PdfDrawerViewer
          drawerTitle={`${isDuplicata ? 'Duplicata' : ''} ${
            proformaPdfData?.numProforma
          } `}
          closeDrawer={() => resetProformaPdfViewer()}
          extraActions={<>{renderProformaPdfActions()}</>}
          isDrawerOpen={showProformaPdf}>
          <RenderDocument<CreateProformaResponse | undefined>
            documentData={proformaPdfData as CreateProformaResponse}
            documentType='PROFORMA_INVOICE'
            isDuplicata={isDuplicata}
          />
        </PdfDrawerViewer>
      )}

      {/* Afficher les factures et avoir */}
      {showInvoicePdf && (
        <PdfDrawerViewer
          drawerTitle={`${isDuplicata ? 'Duplicata' : ''} ${validatedInvoiceTitle}`}
          closeDrawer={() => resetInvoicePdfViewer()}
          extraActions={
            <>
              <StyledButton
                key='cancel_invoice'
                type='primary'
                size='middle'
                danger
                disabled={isLoading}
                onClick={() => resetInvoicePdfViewer()}>
                Quitter
              </StyledButton>
            </>
          }
          isDrawerOpen={showInvoicePdf}>
          <RenderDocument<CreateProformaResponse | undefined, { title: string }>
            documentData={invoicePdfData as CreateProformaResponse}
            documentType='VALIDATED_INVOICE'
            extraData={{ title: validatedInvoiceTitle }}
            isDuplicata={isDuplicata}
          />
        </PdfDrawerViewer>
      )}

      {contextHolder}
    </>
  );
};

export default Invoice;
