import { App as AppContainer } from 'antd';
import React, { useState } from 'react';
import { deleteAppointmentApi } from '../../api/appointmentService';
import chartColors from '../../styles/color';
import { getApiErrorMsg } from '../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../utils/constants';
import { StyledButton } from '../button/style';
import CustomModal from '../modal/Modal';
import { StyledTooltip } from '../style';

type TimeSlotProps = {
  timeslotId?: number;
  day: string;
  hour: string;
  isSelected: boolean;
  isDisabled: boolean;
  isAppointed: boolean;
  handleSelectTime: () => void;
  disableSelection?: boolean;
  resetData: () => void;
};

const AppointmentTimeSlot: React.FC<TimeSlotProps> = ({
  hour,
  isSelected,
  isDisabled = false,
  isAppointed = false,
  handleSelectTime,
  disableSelection,
  timeslotId,
  day,
  resetData,
}) => {
  const { notification } = AppContainer.useApp();
  const [isLoading, setIsLoading] = useState(false);
  const [showModalCancelation, setShowModalCancelation] = useState<boolean>(false);
  const handleModalCancelAppointment = () => {
    setShowModalCancelation(state => !state);
  };

  const confirmAppointmentCancelation = () => {
    try {
      setIsLoading(true);
      Promise.resolve(deleteAppointmentApi(timeslotId as number)).then(() => {
        resetData();
        setShowModalCancelation(false);
        notification.success({
          message: SuccesssMsgTitle,
          description: 'Rendez-vous annulé avec succès!',
        });
        setIsLoading(false);
      });
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
      setIsLoading(false);
    }
  };

  const handleClickTimeSlot = isAppointed
    ? handleModalCancelAppointment
    : disableSelection === false
    ? handleSelectTime
    : undefined;

  const TimeslotItem = () => {
    const buttonStyles = {
      backgroundColor: isAppointed && !isDisabled ? chartColors.danger : '',
      color: isAppointed && !isDisabled ? chartColors.white : '',
    };

    return (
      <div style={{ padding: '5px 0px' }}>
        <StyledButton
          style={buttonStyles}
          onClick={handleClickTimeSlot}
          type={isSelected === false ? 'dashed' : 'primary'}
          block
          danger={isAppointed}
          disabled={isDisabled}>
          {hour}
        </StyledButton>
      </div>
    );
  };

  const TimeslotWithTooltip = () => {
    const tooltipTitle =
      "Un rendez-vous est déja prévu pour cette heure, cliquez pour l'annuler";

    const buttonStyles = {
      backgroundColor: isAppointed && !isDisabled ? chartColors.danger : '',
      color: isAppointed && !isDisabled ? chartColors.white : '',
    };

    return (
      <StyledTooltip title={tooltipTitle} color='volcano'>
        <div style={{ padding: '5px 0px' }}>
          <StyledButton
            style={buttonStyles}
            onClick={handleClickTimeSlot}
            type={isSelected === false ? 'dashed' : 'primary'}
            block
            danger={isAppointed}
            disabled={isDisabled}>
            {hour}
          </StyledButton>
        </div>
      </StyledTooltip>
    );
  };

  return (
    <>
      {isAppointed ? <TimeslotWithTooltip /> : <TimeslotItem />}
      <CustomModal
        title='Annulation de rendez-vous'
        isVisible={showModalCancelation}
        handleVisibility={handleModalCancelAppointment}
        footer={[
          <StyledButton
            key='cancel'
            type='primary'
            onClick={() => confirmAppointmentCancelation()}
            size='middle'
            danger
            loading={isLoading}
            block>
            Confirmer
          </StyledButton>,
        ]}>
        <div style={{ fontSize: '2.5rem' }}>
          Êtes-vous sûr de vouloir annuler le rendez-vous du{' '}
          <span style={{ fontWeight: 700 }}>{day}</span> à{' '}
          <span style={{ fontWeight: 700 }}>{hour}</span>?
        </div>
      </CustomModal>
    </>
  );
};

export default AppointmentTimeSlot;
