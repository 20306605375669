import styled from 'styled-components';
import { Button } from 'antd';
import chartColors from '../../styles/color';

export const StyledButton = styled(Button).attrs(props => ({
  size: props.size || 'middle',
  shape: props.shape || 'round',
}))`
  /* color: #fff; */
  /* border: none; */
  font-weight: 600;
  font-family: 'Sharp sans', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  background-color: ${props => props.htmlType === 'reset' && `${chartColors.deepBlue}70`};
  color: ${props => props.htmlType === 'reset' && chartColors.white};
`;
