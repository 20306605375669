import { App as AppContainer, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { getMedecinAppointmentsByIdApi } from '../../api/appointmentService';
import AppointmentCalendar from '../../components/appointmentCalendar';
import EmptyNotFound from '../../components/emptyData/EmptyNotFound';
import SectionHeader from '../../components/header/SectionHeader';
import Loader from '../../components/loader';
import PageHeader from '../../components/pageHeader/PageHeader';
import { StyledCol, StyledRow } from '../../components/style';
import VerticalLayout from '../../layout/VerticalLayout';
import {
  GetMedecinAppointmentsResponse,
  IPatientAppointment,
  IUserItem,
} from '../../types';
import { convertToHHMM, getApiErrorMsg } from '../../utils/commun';
import { ErrorMsgTitle } from '../../utils/constants';
import { getDataFromStorage } from '../../utils/storage';
import { StyledMedicalRecordBlocContainer } from '../style';

const MyActivity = () => {
  const { notification } = AppContainer.useApp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userAppointments, setUserAppointments] =
    useState<GetMedecinAppointmentsResponse>([]);

  const user = getDataFromStorage<IUserItem>('connectedUserData');

  const loadMedecinAppointments = async () => {
    try {
      setIsLoading(true);
      Promise.resolve(getMedecinAppointmentsByIdApi(user.id as number)).then(
        res => {
          const timesSlots = res.map(t => ({
            ...t,
            timeSlot: {
              id: t.timeSlot.id,
              hour: convertToHHMM(t.timeSlot.hour),
              date: t.timeSlot.date,
            },
          }));
          setUserAppointments(timesSlots);
        },
        err => {
          notification.error({
            message: ErrorMsgTitle,
            description: getApiErrorMsg(err),
          });
          setIsLoading(false);
        },
      );
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadMedecinAppointments();
  }, []);

  const columnData: ColumnsType<IPatientAppointment> = [
    {
      title: 'Patient',
      render: (_value, record) => (
        <span
          style={{
            textTransform: 'uppercase',
          }}>{`${record.namePatient} ${record.firstnamePatient}`}</span>
      ),
    },
    {
      title: 'Date et Heure du rendez-vous',
      render: (_value, record) =>
        `${record.timeSlot.date} ${convertToHHMM(record.timeSlot.hour)}`,
    },
  ];

  const renderContent = () => {
    return (
      <StyledRow gutter={[16, 16]}>
        <StyledCol
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 12 }}>
          <StyledMedicalRecordBlocContainer
            style={{
              backgroundColor: '#FFF',
              padding: '10px 20px',
              height: '100%',
            }}>
            <SectionHeader title='Mes rendez-vous' />
            <AppointmentCalendar
              absencesTimeslots={[]}
              currentAppointments={userAppointments}
              callback={() => console.log()}
              resetData={loadMedecinAppointments}
              allowTimeslotSelection={false}
            />
          </StyledMedicalRecordBlocContainer>
        </StyledCol>

        <StyledCol
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 12 }}>
          <StyledMedicalRecordBlocContainer
            style={{
              backgroundColor: '#FFF',
              padding: '10px 20px',
              height: '100%',
            }}>
            <SectionHeader title='Mes patients' />
            <Loader showSpinner={isLoading}>
              {_.isEmpty(userAppointments) ? (
                <EmptyNotFound
                  message='Aucun patient'
                  description="Aucun patient pour rendez-vous pour l'instant"
                />
              ) : (
                <Table<IPatientAppointment>
                  size='small'
                  bordered={false}
                  columns={columnData}
                  dataSource={userAppointments}
                  pagination={{ pageSize: 6 }}
                />
              )}
            </Loader>
          </StyledMedicalRecordBlocContainer>
        </StyledCol>
      </StyledRow>
    );
  };

  return (
    <VerticalLayout>
      <PageHeader title='Mon Activité' />
      <div
        style={{
          padding: '10px 20px',
          height: '100%',
        }}>
        {renderContent()}
      </div>
    </VerticalLayout>
  );
};

export default MyActivity;
