import { StorageKeys, StorageType } from '../types';

export const saveInStorage = (
  storage: StorageType,
  key: StorageKeys,
  data: unknown,
): boolean => {
  try {
    if (storage === StorageType.LS) {
      localStorage.setItem(key, JSON.stringify(data));
      console.log('In local Storage');
    } else {
      sessionStorage.setItem(key, JSON.stringify(data));
      console.log('Session storage');
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const removeDataFromStorage = (
  storage: StorageType,
  key: StorageKeys,
): boolean => {
  try {
    if (storage === StorageType.LS) {
      localStorage.removeItem(key);
      console.log('In local Storage');
    } else {
      sessionStorage.removeItem(key);
      console.log('Session storage');
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const getDataFromStorage = <T>(key: StorageKeys): T => {
  const data = sessionStorage.getItem(key) ?? localStorage.getItem(key);
  try {
    return JSON.parse(data as string) as T;
  } catch (error) {
    return data as T;
  }
};

// export const getDataFromStorage = (key: StorageKeys): unknown | string => {
//   const data = sessionStorage.getItem(key) ?? localStorage.getItem(key);
//   if (data) {
//     try {
//       return JSON.parse(data);
//     } catch (error) {
//       return data;
//     }
//   }
// };
