import { GetReceiptStampResponse } from '../types';
import { axiosInstance } from './axiosInstance';

export const getReceiptStampsApi = (): Promise<GetReceiptStampResponse> => {
  return axiosInstance
    .get('/receiptStamp')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des quittances. Raison: ${message}`);
    });
};
