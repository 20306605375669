import { App as AppContainer, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { BsExclamationTriangleFill, BsPlus } from 'react-icons/bs';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { deleteSubscriberApi, getSubscribersApi } from '../../../api/subscriberService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import Loader from '../../../components/loader';
import { StyledDivider, StyledSpace } from '../../../components/style';
import chartColors from '../../../styles/color';
import {
  GetSubscribersResponse,
  ISubscriberItem,
  ReferentialViewProps,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import {
  ErrorDeleteMsg,
  ErrorMsgTitle,
  SuccesssMsgTitle,
} from '../../../utils/constants';

type SubscribersListProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  updateCurrentItem: (p: ISubscriberItem) => void;
};

const SubscribersList: React.FC<SubscribersListProps> = ({
  navigateToview,
  updateCurrentItem,
}) => {
  const { notification, modal } = AppContainer.useApp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subscribers, setSubscribers] = useState<GetSubscribersResponse>([]);

  const handleUpdate = (item: ISubscriberItem) => {
    updateCurrentItem(item);
    navigateToview('EDIT_ITEM');
  };

  const columnData: ColumnsType<ISubscriberItem> = [
    {
      title: 'Désignation',
      dataIndex: 'name',
    },
    {
      title: "Type d'assurance",
      dataIndex: 'typeInsurance',
    },
    {
      title: 'Actions',
      align: 'left',
      render: (_value, record) => {
        return (
          <StyledSpace>
            <StyledButton
              shape='circle'
              size='middle'
              icon={<FiEdit />}
              onClick={() => handleUpdate(record)}
            />
            <StyledButton
              shape='circle'
              size='middle'
              danger
              icon={<FiTrash2 />}
              onClick={() => handleRemove(record)}
            />
          </StyledSpace>
        );
      },
    },
  ];

  const loadData = async () => {
    setIsLoading(true);
    Promise.resolve(getSubscribersApi())
      .then(result => {
        setSubscribers(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteItem = async (item: ISubscriberItem) => {
    try {
      setIsLoading(true);
      Promise.resolve(
        deleteSubscriberApi(item.id as number).then(() => {
          loadData();
          notification.success({
            message: SuccesssMsgTitle,
            description: `La souscription a été supprimée`,
          });
        }),
      );
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(ErrorDeleteMsg),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemove = (item: ISubscriberItem) => {
    modal.confirm({
      title: 'Confirmation de suppression',
      icon: <BsExclamationTriangleFill size={40} color={chartColors.warning} />,
      content: `Êtes-vous sûr de vouloir supprimer la souscription ${item.name} de manière permanente ?`,
      okText: 'Oui, je Confirme',
      cancelText: 'Annuler',
      onOk: () => deleteItem(item),
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const renderHeader = () => {
    return (
      <SectionHeader title='Souscripteur' count={subscribers.length}>
        <StyledButton
          icon={<BsPlus />}
          type='primary'
          onClick={() => navigateToview('NEW_ITEM')}>
          Ajouter un souscripteur
        </StyledButton>
      </SectionHeader>
    );
  };

  const renderContent = () => {
    return (
      <>
        <StyledDivider />
        <Table
          rowKey='id'
          bordered={false}
          columns={columnData}
          dataSource={subscribers}
          size='small'
          pagination={false}
        />
      </>
    );
  };

  return (
    <>
      {renderHeader()}
      <Loader showSpinner={isLoading}>{renderContent()}</Loader>
    </>
  );
};

export default SubscribersList;
