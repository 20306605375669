import { GetActsResponse } from '../types/acts.types';
import { axiosInstance } from './axiosInstance';

export const getActsPricingsApi = (): Promise<GetActsResponse> => {
  return axiosInstance
    .get('/pricingOfActs')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des actes. Raison: ${message}`);
    });
};
