import { App as AppContainer, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { searchBiologicalPrescriptionsApi } from '../../../api/prescriptionService';
import { StyledSpace, StyledTag } from '../../../components/style';
import {
  BiologicalPrescriptionLineDto,
  CreateMedicalRecordApiResponse,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle } from '../../../utils/constants';
import { getDataFromStorage } from '../../../utils/storage';

const BiologicalExams = () => {
  const medicalRecord =
    getDataFromStorage<CreateMedicalRecordApiResponse>('medicalRecord');

  const { notification } = AppContainer.useApp();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [biologicalPrescriptionsData, setBiologicalPrescriptionsData] = useState<
    BiologicalPrescriptionLineDto[]
  >([]);

  const loadPatientBiologicalPrescriptions = async () => {
    setIsLoading(true);
    Promise.resolve(
      searchBiologicalPrescriptionsApi({
        idMedicalRecord: medicalRecord?.id as number,
        idPatient: medicalRecord.patientDto.id as number,
        paid: false,
      }),
    )
      .then(result => {
        const tempArray: Array<BiologicalPrescriptionLineDto> = [];
        result.map(item => {
          _.forEach(item.prescriptionLineBiologicalDto, lineItem => {
            tempArray.push(lineItem);
          });
        });
        setBiologicalPrescriptionsData(tempArray);
        setIsLoading(false);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadPatientBiologicalPrescriptions();
  }, []);

  const columnData: ColumnsType<BiologicalPrescriptionLineDto> = [
    {
      title: 'Examen',
      dataIndex: 'biologicalExamDesignation',
    },
    {
      title: 'Unité usuelle',
      dataIndex: 'usualUnit',
      width: 100,
      align: 'center',
    },
    {
      title: 'Norme',
      dataIndex: 'norm',
      align: 'center',
    },
    {
      title: 'Résultats',
      dataIndex: 'result',
      className: 'biological_exam_result',
      align: 'center',
      width: 400,
      render: value => value ?? '',
    },
    {
      title: 'Statut',
      dataIndex: 'done',
      align: 'center',
      width: 100,
      render: (value, record) =>
        record.done && <StyledTag color='success'>Effectuée</StyledTag>,
    },
    {
      title: 'Effectué le',
      dataIndex: 'doneDate',
      align: 'left',
      width: 100,
    },
    {
      title: 'Actions',
      align: 'left',
      width: 100,
      render: () => {
        return <StyledSpace />;
      },
    },
  ];

  return (
    <Table<BiologicalPrescriptionLineDto>
      loading={isLoading}
      rowKey='id'
      dataSource={biologicalPrescriptionsData}
      size='small'
      columns={columnData}
      pagination={false}
      bordered={false}
    />
  );
};

export default BiologicalExams;
