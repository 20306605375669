import React from 'react';
import { PharmacyInvoiceLine, PharmacyLinesResponse } from '../../types';
import { StyledCardItem, StyledSpace } from '../../components/style';
import chartColors from '../../styles/color';
import { Empty, Table } from 'antd';
import _ from 'lodash';
import FormFieldLabel from '../../components/label/FormFieldLabel';
import { thousandSeparatorFormat } from '../../utils/commun';
import type { ColumnsType } from 'antd/es/table';

type PharmacyLinesCard = {
  phamacyLines: PharmacyLinesResponse;
};

const PharmacyLinesCard: React.FC<PharmacyLinesCard> = ({ phamacyLines }) => {
  const phamacyLinesColumn: ColumnsType<PharmacyInvoiceLine> = [
    {
      title: 'Référence',
      dataIndex: 'referenceArticle',
      width: 120,
      render: value => <FormFieldLabel libelle={value} />,
    },
    {
      title: 'Désignation',
      dataIndex: 'designation',
      render: value => <FormFieldLabel libelle={value} />,
    },
    {
      title: 'Qté',
      dataIndex: 'quantity',
      width: 100,
      render: value => <FormFieldLabel libelle={thousandSeparatorFormat(value)} />,
    },
    {
      title: 'Montant',
      dataIndex: 'amount',
      width: 100,
      render: value => <FormFieldLabel libelle={thousandSeparatorFormat(value)} />,
    },
  ];

  const renderContent = () => {
    return _.isEmpty(phamacyLines) ? (
      <Empty description='Aucun examen biologique effectué' />
    ) : (
      <Table
        rowKey='id'
        size='small'
        bordered={false}
        pagination={false}
        columns={phamacyLinesColumn}
        dataSource={phamacyLines}
      />
    );
  };
  return (
    <StyledCardItem
      styles={{
        header: {
          fontFamily: 'Metropolis',
          textTransform: 'uppercase',
          backgroundColor: chartColors.blue50,
        },
      }}
      title={<StyledSpace>Articles de pharmacie</StyledSpace>}
      size='small'
      extra={[]}>
      {renderContent()}
    </StyledCardItem>
  );
};

export default PharmacyLinesCard;
