import { App as AppContainer, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import {
  GetSuppliersResponse,
  ISupplierItem,
  ReferentialViewProps,
} from '../../../types';
import { BsExclamationTriangleFill, BsPlus } from 'react-icons/bs';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { deleteSupplierApi, getSuppliersApi } from '../../../api/referentialService';
import {
  ErrorDeleteMsg,
  ErrorMsgTitle,
  SuccesssMsgTitle,
} from '../../../utils/constants';
import { getApiErrorMsg } from '../../../utils/commun';
import { StyledDivider, StyledSpace } from '../../../components/style';
import { StyledButton } from '../../../components/button/style';
import chartColors from '../../../styles/color';
import SectionHeader from '../../../components/header/SectionHeader';
import Loader from '../../../components/loader';

type SuppliersListProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  updateCurrentItem: (p: ISupplierItem) => void;
};

const SuppliersList: React.FC<SuppliersListProps> = ({
  navigateToview,
  updateCurrentItem,
}) => {
  const { notification, modal } = AppContainer.useApp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [suppliers, setSuppliers] = useState<GetSuppliersResponse>([]);

  const handleUpdate = (item: ISupplierItem) => {
    updateCurrentItem(item);
    navigateToview('EDIT_ITEM');
  };

  const handleRemove = (item: ISupplierItem) => {
    modal.confirm({
      title: 'Confirmation de suppression',
      icon: <BsExclamationTriangleFill size={40} color={chartColors.warning} />,
      content: `Êtes-vous sûr de vouloir supprimer le fournisseur ${item.name} de manière permanente ?`,
      okText: 'Oui, je Confirme',
      cancelText: 'Annuler',
      onOk: () => deleteItem(item),
    });
  };

  const columnData: ColumnsType<ISupplierItem> = [
    {
      title: 'Nom du fournisseur',
      dataIndex: 'name',
    },
    {
      title: 'Téléphone',
      dataIndex: 'tel',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Adresse',
      dataIndex: 'address',
    },
    {
      title: 'Actions',
      align: 'left',
      render: (_value, record) => {
        return (
          <StyledSpace>
            <StyledButton
              shape='circle'
              size='middle'
              icon={<FiEdit />}
              onClick={() => handleUpdate(record)}
            />
            <StyledButton
              shape='circle'
              size='middle'
              danger
              icon={<FiTrash2 />}
              onClick={() => handleRemove(record)}
            />
          </StyledSpace>
        );
      },
    },
  ];

  const loadData = async () => {
    setIsLoading(true);
    Promise.resolve(getSuppliersApi())
      .then(result => {
        setSuppliers(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteItem = async (item: ISupplierItem) => {
    try {
      setIsLoading(true);
      Promise.resolve(
        deleteSupplierApi(item.id as number).then(() => {
          loadData();
          notification.success({
            message: SuccesssMsgTitle,
            description: `Le fournisseur a été supprimé`,
          });
        }),
      );
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(ErrorDeleteMsg),
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const renderHeader = () => {
    return (
      <SectionHeader title='Fournisseur' count={suppliers.length}>
        <StyledButton
          icon={<BsPlus />}
          type='primary'
          onClick={() => navigateToview('NEW_ITEM')}>
          Ajouter un fournisseur
        </StyledButton>
      </SectionHeader>
    );
  };

  const renderContent = () => {
    return (
      <>
        <StyledDivider />
        <Table
          rowKey='id'
          bordered={false}
          columns={columnData}
          dataSource={suppliers}
          size='small'
          pagination={false}
        />
      </>
    );
  };

  return (
    <>
      {renderHeader()}
      <Loader showSpinner={isLoading}>{renderContent()}</Loader>
    </>
  );
};

export default SuppliersList;
