import _ from 'lodash';
import { CreateSiteRequestBody, ISite } from '../types';
import { axiosInstance } from './axiosInstance';
import { base64ToBlob } from '../utils/commun';

export const getSiteInfo = (): Promise<ISite> => {
  return axiosInstance
    .get('/site')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des users. Raison: ${message}`);
    });
};

export const createSiteApi = (data: CreateSiteRequestBody): Promise<string> => {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('address', data.address);
  formData.append('file', data.logo[0].originFileObj as Blob);

  return axiosInstance
    .post('/site', formData, {
      headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' },
    })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création du site. Raison: ${message}`);
    });
};

export const updateSiteApi = (
  id: number,
  data: CreateSiteRequestBody,
): Promise<ISite> => {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('address', data.address);

  if (_.isEmpty(data.logo) || _.isUndefined(data.logo)) {
    formData.append(
      'file',
      base64ToBlob(data.currentSiteData?.url as string),
      _.trim(`logo${data.currentSiteData.name}`),
    );
  } else {
    formData.append('file', data.logo[0].originFileObj as Blob);
  }

  return axiosInstance
    .put(`/site?id=${id}`, formData, {
      headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' },
    })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la mise à jour du site. Raison: ${message}`);
    });
};
