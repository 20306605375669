import dayjsInstance from '../dayjs';

export const ErrorMsgTitle = "Une erreur s'est produite";
export const WarningMsgTitle = 'Données ou formats incorrectes';
export const SuccesssMsgTitle = 'Opération effectuée';
export const ErrorDeleteMsg = 'Suppression impossible de cette donnée';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DAY_START_HOUR = 6;
export const DAY_END_HOUR = 18;
export const CURRENT_YEAR = dayjsInstance().year();

export const RUBRICS_TO_DISABLE: Array<string> = ['ANABIO', 'ANAIMG', 'PHARMA'];
export const RUBRICS_LABELS: Array<string> = [
  'ANALYSES MEDICALES',
  "ANALYSES D'IMAGERIE",
  'PHARMACIE',
];
export const RUBRICS_LABELS_TO_DISABLE = {
  ANABIO: 'ANALYSES MEDICALES',
  ANAIMG: "ANALYSES D'IMAGERIE",
  PHARMA: 'PHARMACIE',
};
export const CURRENCY = 'FCFA';
