import { FiFolder } from 'react-icons/fi';
import chartColors from '../../styles/color';

interface IEmptyNotFoundProps {
  message?: string;
  description?: string;
  icon?: JSX.Element;
}

const defaultData = {
  message: 'Aucun trouvé',
  description: 'Veuillez faire une recherche',
};

const EmptyNotFound: React.FC<IEmptyNotFoundProps> = ({ message, description, icon }) => {
  return (
    <div className='flex flex-col items-center pt-20'>
      {icon ?? <FiFolder size={100} color={chartColors.black} />}
      <h2 style={{ color: chartColors.blue, marginBottom: '0px' }}>
        {' '}
        {message ?? defaultData.message}
      </h2>
      <p> {description ?? defaultData.description} </p>
    </div>
  );
};

export default EmptyNotFound;
