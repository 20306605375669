/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer, Form } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import {
  createInvoiceRubricApi,
  updateInvoiceRubricApi,
} from '../../../api/invoiceService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import {
  StyledCol,
  StyledDivider,
  StyledInput,
  StyledInputNumber,
  StyledRadio,
  StyledRow,
  StyledSelectOption,
  StyledSelectPicker,
  StyledSpace,
} from '../../../components/style';
import rubricsModes from '../../../mocks/getRubricModesMock.json';
import {
  CreateInvoiceRubricRequestBody,
  IInvoiceRubricItem,
  InvoiceRubricMode,
  ReferentialViewProps,
  UserDto,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { getDataFromStorage } from '../../../utils/storage';
import { StyledBlocContainer } from '../../style';

type InvoiceRubricFormProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  currentData?: IInvoiceRubricItem;
};

type FormPropertiesProps = CreateInvoiceRubricRequestBody;

const InvoiceRubricForm: React.FC<InvoiceRubricFormProps> = ({
  navigateToview,
  currentData,
}) => {
  const currentUser: UserDto = getDataFromStorage<UserDto>('connectedUserData');
  const { notification } = AppContainer.useApp();
  const [form] = Form.useForm<FormPropertiesProps>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const renderHeader = () => {
    return (
      <SectionHeader
        title={_.isUndefined(currentData) ? 'Nouvelle rubrique' : 'Modification rubrique'}
        onBack={() => navigateToview('LIST_ITEMS')}
      />
    );
  };

  const mapPropsToRequest = (row: FormPropertiesProps) => {
    const itemData: CreateInvoiceRubricRequestBody = {
      label: row.label,
      classique_amount_JO: row.classique_amount_JO,
      classique_amount_D_N_JF: row.classique_amount_D_N_JF,
      mutualiste_amount_JO: row.mutualiste_amount_JO,
      mutualiste_amount_D_N_JF: row.mutualiste_amount_D_N_JF,
      active: row.active,
      mode: row.mode,
      updatedBy: currentUser.id,
    };

    return itemData;
  };

  const createItem = async () => {
    try {
      await form.validateFields().then(async row => {
        setIsLoading(true);
        Promise.resolve(createInvoiceRubricApi(mapPropsToRequest(row))).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `la rubrique ${row.label} a été enregistrée!`,
          });
          navigateToview('LIST_ITEMS');
        });
      });
    } catch (error: any) {
      if (_.isUndefined(error.values)) {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      }
      setIsLoading(false);
    }
  };

  const editItem = async () => {
    try {
      await form.validateFields().then(async row => {
        setIsLoading(true);
        await updateInvoiceRubricApi(
          currentData?.id as number,
          mapPropsToRequest(row),
        ).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `La rubrique a été modifié!`,
          });
          navigateToview('LIST_ITEMS');
        });
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    return (
      <StyledBlocContainer style={{ padding: 20 }}>
        <Form
          disabled={isLoading}
          form={form}
          component={false}
          initialValues={{
            label: _.isUndefined(currentData) ? '' : currentData.label,
            classique_amount_JO: _.isUndefined(currentData)
              ? ''
              : currentData.classique_amount_JO,
            classique_amount_D_N_JF: _.isUndefined(currentData)
              ? ''
              : currentData.classique_amount_D_N_JF,
            mutualiste_amount_JO: _.isUndefined(currentData)
              ? ''
              : currentData.mutualiste_amount_JO,
            mutualiste_amount_D_N_JF: _.isUndefined(currentData)
              ? ''
              : currentData.mutualiste_amount_D_N_JF,
            mode: _.isUndefined(currentData)
              ? InvoiceRubricMode.MONTANT_FIXE
              : currentData.mode,
            active: _.isUndefined(currentData) ? true : currentData.active,
          }}>
          <StyledRow gutter={[16, 16]} align='middle' justify='center'>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Libellé rubrique' />
              <Form.Item style={{ margin: 0 }} name='label'>
                <StyledInput size='large' placeholder='Libellé rubrique' />
              </Form.Item>
            </StyledCol>

            <StyledCol span={12} style={{ padding: '0px' }}>
              <StyledDivider orientation='left'>Montant Classique</StyledDivider>
              <StyledCol span={24}>
                <FormFieldLabel libelle='Jour ouvrable' isRequired />
                <Form.Item style={{ margin: 0 }} name='classique_amount_JO'>
                  <StyledInputNumber style={{ width: '100%' }} size='large' min={1} />
                </Form.Item>
              </StyledCol>
              <StyledCol span={24}>
                <FormFieldLabel libelle='Dimanche, Nuit et Férié' isRequired />
                <Form.Item style={{ margin: 0 }} name='classique_amount_D_N_JF'>
                  <StyledInputNumber style={{ width: '100%' }} size='large' min={1} />
                </Form.Item>
              </StyledCol>
            </StyledCol>
            <StyledCol span={12} style={{ padding: '0px' }}>
              <StyledDivider orientation='left'>Montant Mutualiste</StyledDivider>
              <StyledCol span={24}>
                <FormFieldLabel libelle='Jour ouvrable' isRequired />
                <Form.Item style={{ margin: 0 }} name='mutualiste_amount_JO'>
                  <StyledInputNumber style={{ width: '100%' }} size='large' min={1} />
                </Form.Item>
              </StyledCol>
              <StyledCol span={24}>
                <FormFieldLabel libelle='Dimanche, Nuit et Férié' isRequired />
                <Form.Item style={{ margin: 0 }} name='mutualiste_amount_D_N_JF'>
                  <StyledInputNumber style={{ width: '100%' }} size='large' min={1} />
                </Form.Item>
              </StyledCol>
            </StyledCol>

            <StyledCol span={24}>
              <FormFieldLabel libelle='Mode' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='mode'
                rules={[{ required: true, message: 'Mode obligatoire' }]}>
                <StyledSelectPicker
                  placeholder='Sélectionnez un mode'
                  style={{ width: '100%' }}
                  defaultValue={InvoiceRubricMode.MONTANT_FIXE}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }>
                  {_.map(rubricsModes, item => (
                    <StyledSelectOption
                      key={item.code}
                      value={item.value}
                      label={item.libelle}>
                      {item.libelle}
                    </StyledSelectOption>
                  ))}
                </StyledSelectPicker>
              </Form.Item>
            </StyledCol>

            <StyledCol span={24}>
              <FormFieldLabel libelle='Montant rubrique' />
              <Form.Item style={{ margin: 0 }} name='active'>
                <StyledRadio.Group size='large' disabled={isLoading}>
                  <StyledRadio value={true}>Actif</StyledRadio>
                  <StyledRadio value={false}>Inactif</StyledRadio>
                </StyledRadio.Group>
              </Form.Item>
            </StyledCol>

            <StyledDivider />
            <StyledCol span={24}>
              <StyledSpace>
                <StyledButton
                  type='primary'
                  htmlType='submit'
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={_.isUndefined(currentData) ? createItem : editItem}>
                  {_.isUndefined(currentData)
                    ? 'Enregistrer la rubrique'
                    : 'Modifier la rubrique'}
                </StyledButton>
              </StyledSpace>
            </StyledCol>
          </StyledRow>
        </Form>
      </StyledBlocContainer>
    );
  };

  return (
    <>
      {renderHeader()}
      <StyledDivider />
      {renderContent()}
    </>
  );
};

export default InvoiceRubricForm;
