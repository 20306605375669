/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer, Form } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  createPlaceOfResidenceApi,
  getRegionsApi,
  updatePlaceOfResidenceApi,
} from '../../../api/referentialService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import {
  StyledCol,
  StyledDivider,
  StyledInput,
  StyledRow,
  StyledSelectOption,
  StyledSelectPicker,
  StyledSpace,
} from '../../../components/style';
import {
  CreatePlaceOfResidenceItemRequestBody,
  GetRegionsResponse,
  IPlaceOfResidenceItem,
  ReferentialViewProps,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { StyledBlocContainer } from '../../style';

type PlaceOfresidenceFormProps = {
  navigateToview?: (p: ReferentialViewProps) => void;
  currentData?: IPlaceOfResidenceItem;
  showBack?: boolean;
  handleSuccess?: () => void;
};

type FormPropertiesProps = {
  id: number;
  code: string;
  libelle: string;
  idRegion: number;
};

const PlaceOfresidenceForm: React.FC<PlaceOfresidenceFormProps> = ({
  navigateToview,
  currentData,
  handleSuccess,
  showBack,
}) => {
  const { notification } = AppContainer.useApp();
  const [form] = Form.useForm<FormPropertiesProps>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [regionsData, setRegionsData] = useState<GetRegionsResponse>([]);

  const loadRegions = async () => {
    setIsLoading(true);
    Promise.resolve(getRegionsApi())
      .then(result => {
        setRegionsData(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadRegions();
  }, []);

  const handleBackClick = () => {
    navigateToview && navigateToview('LIST_ITEMS');
  };

  const renderHeader = () => {
    return (
      <SectionHeader
        title={
          _.isUndefined(currentData)
            ? 'Nouveau lieu de résidence'
            : 'Modification lieu de résidence'
        }
        onBack={showBack ? handleBackClick : undefined}
      />
    );
  };

  const mapPropsToRequest = (row: FormPropertiesProps) => {
    const itemData: CreatePlaceOfResidenceItemRequestBody = {
      code: row.code,
      libelle: row.libelle,
      idRegion: row.idRegion,
    };

    return itemData;
  };

  const createItem = async () => {
    try {
      await form.validateFields().then(async row => {
        setIsLoading(true);
        Promise.resolve(createPlaceOfResidenceApi(mapPropsToRequest(row))).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `${row.libelle} a été enregistré!`,
          });
          {
            handleSuccess
              ? handleSuccess()
              : navigateToview && navigateToview('LIST_ITEMS');
          }
        });
      });
    } catch (error: any) {
      if (_.isUndefined(error.values)) {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const editItem = async () => {
    try {
      await form.validateFields().then(async row => {
        setIsLoading(true);
        await updatePlaceOfResidenceApi(
          currentData?.id as number,
          mapPropsToRequest(row),
        ).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `Le lieu de résidence a été modifié!`,
          });
          {
            handleSuccess
              ? handleSuccess()
              : navigateToview && navigateToview('LIST_ITEMS');
          }
        });
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    return (
      <StyledBlocContainer style={{ padding: 20 }}>
        <Form
          disabled={isLoading}
          form={form}
          component={false}
          initialValues={{
            libelle: _.isUndefined(currentData) ? '' : currentData.libelle,
            code: _.isUndefined(currentData) ? '' : currentData.code,
            idRegion: _.isUndefined(currentData) ? null : currentData.region.id,
          }}>
          <StyledRow gutter={[16, 16]} align='middle' justify='center'>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Code du lieu de résidence' />
              <Form.Item style={{ margin: 0 }} name='code'>
                <StyledInput size='large' placeholder='Code lieu de résidence' />
              </Form.Item>
            </StyledCol>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Libellé du lieu de résidence' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='libelle'
                rules={[
                  { required: true, message: 'Libellé obligatoire' },
                  { whitespace: false },
                ]}>
                <StyledInput size='large' placeholder='Libellé du lieu de résidence' />
              </Form.Item>
            </StyledCol>

            <StyledCol span={24}>
              <FormFieldLabel libelle='Région' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='idRegion'
                rules={[{ required: true, message: 'Région obligatoire' }]}>
                <StyledSelectPicker
                  placeholder='Sélectionnez une région'
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }>
                  {_.map(regionsData, item => (
                    <StyledSelectOption
                      key={item.id}
                      value={item.id}
                      label={item.libelle}>
                      {item.libelle}
                    </StyledSelectOption>
                  ))}
                </StyledSelectPicker>
              </Form.Item>
            </StyledCol>
            <StyledDivider />
            <StyledCol span={24}>
              <StyledSpace>
                <StyledButton
                  type='primary'
                  htmlType='submit'
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={_.isUndefined(currentData) ? createItem : editItem}>
                  {_.isUndefined(currentData)
                    ? 'Enregistrer le lieu de résidence'
                    : 'Modifier le lieu de résidence'}
                </StyledButton>
              </StyledSpace>
            </StyledCol>
          </StyledRow>
        </Form>
      </StyledBlocContainer>
    );
  };

  return (
    <>
      {renderHeader()}
      <StyledDivider />
      {renderContent()}
    </>
  );
};

export default PlaceOfresidenceForm;
