import {
  StyledCustomInput,
  StyledCustomInputContainer,
  StyledInputErrorMessage,
} from './style';

interface CustomInputProps {
  name: string;
  type: string;
  value?: string | number | null;
  placeholder?: string;
  handleInputChange?: (value: string) => void;
  handleInputBlur?: (value: string) => void;
  handleInputFocus?: () => void;
  required?: boolean;
  width?: string;
  height?: string;
  isValid?: boolean;
  isFocused?: boolean;
  isDisabled?: boolean;
  error?: string;
  backgroundColor?: string;
}
const CustomInput: React.FC<CustomInputProps> = ({
  name,
  type,
  value,
  placeholder,
  handleInputChange,
  // handleInputBlur,
  // handleInputFocus,
  required,
  width,
  // height,
  isValid,
  isFocused,
  error,
  backgroundColor,
  isDisabled,
}) => {
  return (
    <>
      <StyledCustomInputContainer
        $isFocused={isFocused}
        $isValid={isValid}
        $inputWidth={width}
        $backgroundColor={backgroundColor}>
        <StyledCustomInput
          size='middle'
          name={name}
          type={type}
          value={value || ''}
          placeholder={`${placeholder}${required ? '*' : ''}`}
          onChange={e => handleInputChange && handleInputChange(e.target.value)}
          // onFocus={() => handleInputFocus && handleInputFocus()}
          // onBlur={e => handleInputBlur && handleInputBlur(e.target.value)}
          disabled={isDisabled}
        />
      </StyledCustomInputContainer>
      {error && <StyledInputErrorMessage>{error}</StyledInputErrorMessage>}
    </>
  );
};

CustomInput.defaultProps = {
  type: 'text',
  placeholder: '',
  handleInputChange: undefined,
  required: false,
  width: '100%',
  height: '3rem',
};

export default CustomInput;
