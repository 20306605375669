/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { useState } from 'react';
import { InvoicePlanDataProps } from '../../../types';
import InvoicePlanRubrics from './InvoicePlanRubrics';
import InvoicePlanSetting from './InvoicePlanSetting';

const InvoiceSetting = () => {
  const [selectedInvoicePlan, setSelectedInvoicePlan] = useState<
    InvoicePlanDataProps | any
  >([]);

  const handleResetSelectedInvoicePlan = () => {
    setSelectedInvoicePlan([]);
  };
  const handleSelectInvoicePlan = (invoicePlan: InvoicePlanDataProps) => {
    setSelectedInvoicePlan(invoicePlan);
  };

  const renderContent = () => {
    if (_.isEmpty(selectedInvoicePlan)) {
      return <InvoicePlanSetting handleSelectInvoicePlan={handleSelectInvoicePlan} />;
    }

    return (
      <InvoicePlanRubrics
        selectedInvoicePlan={selectedInvoicePlan}
        resetSelectedPlan={handleResetSelectedInvoicePlan}
      />
    );
  };

  return <>{renderContent()}</>;
};

export default InvoiceSetting;
