import { App as AppContainer, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { BsExclamationTriangleFill, BsPlus } from 'react-icons/bs';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { deleteMedicationApi, getMedicationsApi } from '../../../api/medicationService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import Loader from '../../../components/loader';
import { StyledDivider, StyledSpace } from '../../../components/style';
import chartColors from '../../../styles/color';
import { IMedicationItem, ReferentialViewProps } from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import {
  ErrorDeleteMsg,
  ErrorMsgTitle,
  SuccesssMsgTitle,
} from '../../../utils/constants';

type MedicationsListProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  updateCurrentItem: (p: IMedicationItem) => void;
};

const MedicationsList: React.FC<MedicationsListProps> = ({
  navigateToview,
  updateCurrentItem,
}) => {
  const { notification, modal } = AppContainer.useApp();
  const [medicationsData, setMedicationsData] = useState<IMedicationItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [paginationData, setPaginationData] = useState({
    pageNo: 0,
    pageSize: 150,
    sortBy: 'designation',
  });
  const [totalItems, setTotalItems] = useState<number>(0);

  const handleUpdate = (medication: IMedicationItem) => {
    updateCurrentItem(medication);
    navigateToview('EDIT_ITEM');
  };

  const deleteMedication = async (medication: IMedicationItem) => {
    try {
      setIsLoading(true);
      Promise.resolve(deleteMedicationApi(medication.id as number)).then(() => {
        loadMedications();
        notification.success({
          message: SuccesssMsgTitle,
          description: `Le médicament a été supprimé`,
        });
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(ErrorDeleteMsg),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveMedication = (medication: IMedicationItem) => {
    modal.confirm({
      title: 'Confirmation de suppression',
      icon: <BsExclamationTriangleFill size={40} color={chartColors.warning} />,
      content: `Êtes-vous sûr de vouloir supprimer le médicament ${medication.designation} de manière permanente ?`,
      okText: 'Oui, je Confirme',
      cancelText: 'Annuler',
      onOk: () => deleteMedication(medication),
    });
  };

  const loadMedications = async () => {
    setIsLoading(true);
    Promise.resolve(getMedicationsApi(paginationData))
      .then(result => {
        setMedicationsData(result.content);
        setTotalItems(result.totalElements);
        setIsLoading(false);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadMedications();
  }, [paginationData]);

  const handlePaginate = (page: number, pageSize: number) => {
    setPaginationData(state => ({ ...state, pageNo: page - 1, pageSize: pageSize }));
  };

  const columnData: ColumnsType<IMedicationItem> = [
    {
      title: 'Code',
      dataIndex: 'cisCode',
      width: 150,
    },
    {
      title: 'Désignation',
      dataIndex: 'designation',
    },
    // {
    //   title: 'Unité',
    //   render: (_value, record) => record.unit.libelle,
    // },
    // {
    //   title: 'Créé le',
    //   dataIndex: 'createdDate',
    //   width: 100,
    // },
    {
      title: 'Actions',
      align: 'left',
      render: (_value, record) => {
        return (
          <StyledSpace>
            <StyledButton
              shape='circle'
              size='middle'
              icon={<FiEdit />}
              onClick={() => handleUpdate(record)}
            />
            <StyledButton
              shape='circle'
              size='middle'
              danger
              icon={<FiTrash2 />}
              onClick={() => handleRemoveMedication(record)}
            />
          </StyledSpace>
        );
      },
    },
  ];

  const renderHeader = () => {
    return (
      <SectionHeader title='Médicament' count={totalItems}>
        <StyledButton
          icon={<BsPlus />}
          type='primary'
          onClick={() => navigateToview('NEW_ITEM')}>
          Ajouter un médicament
        </StyledButton>
      </SectionHeader>
    );
  };

  const renderContent = () => {
    return (
      <>
        <StyledDivider />
        <Table
          rowKey='id'
          bordered={false}
          columns={columnData}
          dataSource={medicationsData}
          size='small'
          pagination={{
            current: paginationData.pageNo + 1,
            pageSize: paginationData.pageSize,
            total: totalItems,
            size: 'small',
            onChange(page, pageSize) {
              handlePaginate(page, pageSize);
            },
          }}
        />
      </>
    );
  };

  return (
    <>
      {renderHeader()}
      <Loader showSpinner={isLoading}>{renderContent()}</Loader>
    </>
  );
};

export default MedicationsList;
