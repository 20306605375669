export enum InsuranceType {
  CLASSIQUE = 'CLASSIQUE',
  MUTUALISTE = 'MUTUALISTE',
}

export interface IInsuranceItem {
  id?: number;
  name: string;
}

export type GetInsurancesResponse = Array<IInsuranceItem>;
export type CreateInsuranceRequestBody = IInsuranceItem & Omit<IInsuranceItem, 'id'>;
