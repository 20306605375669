/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { Form, notification } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { createInvoiceRubricApi } from '../../../../api/invoiceService';
import { StyledButton } from '../../../../components/button/style';
import SectionHeader from '../../../../components/header/SectionHeader';
import FormFieldLabel from '../../../../components/label/FormFieldLabel';
import Loader from '../../../../components/loader';
import {
  StyledCol,
  StyledDivider,
  StyledInputNumber,
  StyledRadio,
  StyledRow,
  StyledSelectOption,
  StyledSelectPicker,
  StyledSpace,
} from '../../../../components/style';
import rubricsModes from '../../../../mocks/getRubricModesMock.json';
import {
  CreateInvoiceRubricRequestBody,
  InvoiceRubricMode,
  InvoiceViewType,
  UserDto,
} from '../../../../types';
import { getApiErrorMsg } from '../../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../../utils/constants';
import { getDataFromStorage } from '../../../../utils/storage';

interface InvoiceRubricFormProps {
  toggleInvoicePlanForm: (arg: InvoiceViewType) => void;
}
type FormPropertiesProps = CreateInvoiceRubricRequestBody;

const InvoiceRubricForm: React.FC<InvoiceRubricFormProps> = ({
  toggleInvoicePlanForm,
}) => {
  const [form] = Form.useForm<FormPropertiesProps>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const currentUser: UserDto = getDataFromStorage<UserDto>('connectedUserData');

  const mapPropsToRequest = (row: FormPropertiesProps) => {
    const itemData: CreateInvoiceRubricRequestBody = {
      label: row.label,
      classique_amount_JO: row.classique_amount_JO,
      classique_amount_D_N_JF: row.classique_amount_D_N_JF,
      mutualiste_amount_JO: row.mutualiste_amount_JO,
      mutualiste_amount_D_N_JF: row.mutualiste_amount_D_N_JF,
      active: row.active,
      mode: row.mode,
      updatedBy: currentUser.id,
    };

    return itemData;
  };

  const createInvoiceRubric = async () => {
    try {
      await form.validateFields().then(async row => {
        setIsLoading(true);
        Promise.resolve(createInvoiceRubricApi(mapPropsToRequest(row))).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `la rubrique ${row.label} a été enregistrée!`,
          });
          setTimeout(() => {
            toggleInvoicePlanForm('INVOICE_PLAN_LIST');
          }, 1000);
        });
      });
    } catch (error: any) {
      if (_.isUndefined(error.values)) {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      }
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    return (
      <>
        <SectionHeader
          title='Nouvelle rubrique'
          onBack={() => toggleInvoicePlanForm('INVOICE_PLAN_LIST')}
        />

        <Form
          disabled={isLoading}
          form={form}
          component={false}
          initialValues={{
            label: '',
            classique_amount_JO: '',
            classique_amount_D_N_JF: '',
            mutualiste_amount_JO: '',
            mutualiste_amount_D_N_JF: '',
            mode: InvoiceRubricMode.MONTANT_FIXE,
            active: true,
          }}>
          <StyledRow gutter={[16, 16]}>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Libellé rubrique' />
              <Form.Item style={{ margin: 0 }} name='label'>
                <StyledInputNumber
                  size='large'
                  placeholder='Libellé rubrique'
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </StyledCol>

            <StyledCol span={12} style={{ padding: '0px' }}>
              <StyledDivider orientation='left'>Montant Classique</StyledDivider>
              <StyledCol span={24}>
                <FormFieldLabel libelle='Jour ouvrable' isRequired />
                <Form.Item style={{ margin: 0 }} name='classique_amount_JO'>
                  <StyledInputNumber style={{ width: '100%' }} size='large' min={1} />
                </Form.Item>
              </StyledCol>
              <StyledCol span={24}>
                <FormFieldLabel libelle='Dimanche, Nuit et Férié' isRequired />
                <Form.Item style={{ margin: 0 }} name='classique_amount_D_N_JF'>
                  <StyledInputNumber style={{ width: '100%' }} size='large' min={1} />
                </Form.Item>
              </StyledCol>
            </StyledCol>
            <StyledCol span={12} style={{ padding: '0px' }}>
              <StyledDivider orientation='left'>Montant Mutualiste</StyledDivider>
              <StyledCol span={24}>
                <FormFieldLabel libelle='Jour ouvrable' isRequired />
                <Form.Item style={{ margin: 0 }} name='mutualiste_amount_JO'>
                  <StyledInputNumber style={{ width: '100%' }} size='large' min={1} />
                </Form.Item>
              </StyledCol>
              <StyledCol span={24}>
                <FormFieldLabel libelle='Dimanche, Nuit et Férié' isRequired />
                <Form.Item style={{ margin: 0 }} name='mutualiste_amount_D_N_JF'>
                  <StyledInputNumber style={{ width: '100%' }} size='large' min={1} />
                </Form.Item>
              </StyledCol>
            </StyledCol>

            <StyledCol span={24}>
              <FormFieldLabel libelle='Mode' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='mode'
                rules={[{ required: true, message: 'Mode obligatoire' }]}>
                <StyledSelectPicker
                  placeholder='Sélectionnez un mode'
                  style={{ width: '100%' }}
                  defaultValue={InvoiceRubricMode.MONTANT_FIXE}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }>
                  {_.map(rubricsModes, item => (
                    <StyledSelectOption
                      key={item.code}
                      value={item.value}
                      label={item.libelle}>
                      {item.libelle}
                    </StyledSelectOption>
                  ))}
                </StyledSelectPicker>
              </Form.Item>
            </StyledCol>

            <StyledCol span={24}>
              <FormFieldLabel libelle='Montant rubrique' />
              <Form.Item style={{ margin: 0 }} name='active'>
                <StyledRadio.Group size='large' disabled={isLoading}>
                  <StyledRadio value={true}>Actif</StyledRadio>
                  <StyledRadio value={false}>Inactif</StyledRadio>
                </StyledRadio.Group>
              </Form.Item>
            </StyledCol>

            <StyledDivider />
            <StyledCol span={24}>
              <StyledSpace>
                <StyledButton
                  type='primary'
                  htmlType='submit'
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={createInvoiceRubric}>
                  Enregistrer la rubrique
                </StyledButton>
              </StyledSpace>
            </StyledCol>
          </StyledRow>
        </Form>
      </>
    );
  };

  return (
    <>
      <Loader showSpinner={isLoading}>{renderContent()}</Loader>
    </>
  );
};
export default InvoiceRubricForm;
