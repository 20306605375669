/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import MaskedInput from 'react-input-mask';
import { StyledInput } from '../style';
import { InputProps } from 'antd';

interface CustomMaskedInputProps {
  mask: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  inputSize: InputProps['size'];
  inputName: string;
  contentAfter?: React.ReactNode | string;
}

const CustomMaskedInput: React.FC<CustomMaskedInputProps> = ({
  handleChange,
  mask,
  value,
  inputSize = 'large',
  inputName,
  contentAfter,
}) => {
  return (
    <div>
      <MaskedInput
        alwaysShowMask={false}
        mask={mask}
        placeholder='Tension Artérielle'
        onChange={handleChange}
        value={value}>
        <StyledInput
          size={inputSize}
          name={inputName}
          type='text'
          required
          addonAfter={contentAfter}
        />
      </MaskedInput>
    </div>
  );
};

export default CustomMaskedInput;
