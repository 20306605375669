import { App as AppContainer, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { BsExclamationTriangleFill, BsPlus } from 'react-icons/bs';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { deleteProfessionApi, getProfessionsApi } from '../../../api/referentialService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import Loader from '../../../components/loader';
import { StyledDivider, StyledSpace } from '../../../components/style';
import chartColors from '../../../styles/color';
import {
  GetProfessionsResponse,
  IProfessionItem,
  ReferentialViewProps,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import {
  ErrorDeleteMsg,
  ErrorMsgTitle,
  SuccesssMsgTitle,
} from '../../../utils/constants';

type ProfessionsListProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  updateCurrentItem: (p: IProfessionItem) => void;
};

const ProfessionsList: React.FC<ProfessionsListProps> = ({
  navigateToview,
  updateCurrentItem,
}) => {
  const { notification, modal } = AppContainer.useApp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [professions, setProfessions] = useState<GetProfessionsResponse>([]);

  const handleUpdate = (item: IProfessionItem) => {
    updateCurrentItem(item);
    navigateToview('EDIT_ITEM');
  };

  const columnData: ColumnsType<IProfessionItem> = [
    {
      title: 'Code',
      dataIndex: 'code',
      width: 150,
    },
    {
      title: 'Libellé',
      dataIndex: 'libelle',
    },
    {
      title: 'Actions',
      align: 'left',
      render: (_value, record) => {
        return (
          <StyledSpace>
            <StyledButton
              shape='circle'
              size='middle'
              icon={<FiEdit />}
              onClick={() => handleUpdate(record)}
            />
            <StyledButton
              shape='circle'
              size='middle'
              danger
              icon={<FiTrash2 />}
              onClick={() => handleRemove(record)}
            />
          </StyledSpace>
        );
      },
    },
  ];

  const loadData = async () => {
    setIsLoading(true);
    Promise.resolve(getProfessionsApi())
      .then(result => {
        setProfessions(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteItem = async (item: IProfessionItem) => {
    try {
      setIsLoading(true);
      Promise.resolve(
        deleteProfessionApi(item.id as number).then(() => {
          loadData();
          notification.success({
            message: SuccesssMsgTitle,
            description: `La profession a été supprimée`,
          });
        }),
      );
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(ErrorDeleteMsg),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemove = (item: IProfessionItem) => {
    modal.confirm({
      title: 'Confirmation de suppression',
      icon: <BsExclamationTriangleFill size={40} color={chartColors.warning} />,
      content: `Êtes-vous sûr de vouloir supprimer la profession ${item.libelle} de manière permanente ?`,
      okText: 'Oui, je Confirme',
      cancelText: 'Annuler',
      onOk: () => deleteItem(item),
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const renderHeader = () => {
    return (
      <SectionHeader title='Profession' count={professions.length}>
        <StyledButton
          icon={<BsPlus />}
          type='primary'
          onClick={() => navigateToview('NEW_ITEM')}>
          Ajouter une profession
        </StyledButton>
      </SectionHeader>
    );
  };

  const renderContent = () => {
    return (
      <>
        <StyledDivider />
        <Table
          rowKey='id'
          bordered={false}
          columns={columnData}
          dataSource={professions}
          size='small'
          pagination={false}
        />
      </>
    );
  };

  return (
    <>
      {renderHeader()}
      <Loader showSpinner={isLoading}>{renderContent()}</Loader>
    </>
  );
};

export default ProfessionsList;
