import { Input } from 'antd';
import styled from 'styled-components';

export const StyledCustomInputContainer = styled.div<{
  $isValid?: boolean;
  $isFocused?: boolean;
  $inputWidth?: string;
  $backgroundColor?: string;
}>`
  display: flex;
  width: ${({ $inputWidth }) => $inputWidth || '100%'};
  /* border-radius: 5px; */
  /* height: 4.5rem; */
  background-color: ${({ $backgroundColor }) => $backgroundColor || '#fff'};
  border: 0.1rem solid
    ${({ $isValid, $isFocused, theme }) => {
      if ($isFocused) {
        return theme.colors.blue;
      }
      if ($isValid === false) {
        return '#ff0000';
      }
      return '#CECECE';
    }};
  border-radius: 0.4rem;
  cursor: pointer;
  &:focus {
    border-color: ${({ theme }) => theme.colors.blue};
  }
  @media (max-width: 992px) {
    width: 100%;
  }
`;
export const StyledCustomInput = styled(Input).attrs(props => ({
  size: props.size || 'large',
}))`
  margin: 0;
  padding-left: 1rem;
  font-size: 1.6rem;
  border: none;
  border-radius: 5px;
  background-color: transparent;
  /* line-height: 1.5;
  flex-grow: 1; */
  &:active,
  &:focus {
    border-color: none;
    outline: none;
  }
`;

export const StyledTextArea = styled.textarea`
  border: none;
  color: ${({ theme }) => theme.colors.mainGray};
  border-radius: 0.4rem;
`;

export const StyledSelect = styled.select`
  border: none;
  color: ${({ theme }) => theme.colors.mainGray};
  background-color: #fff;
  line-height: 1.5;
  height: 5rem;
  border-radius: 0.4rem;
`;

export const StyledCheckboxWrapper = styled.div`
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
`;

export const StyledCheckbox = styled.input`
  float: left;
  margin-left: -1.5em;
`;

export const StyledSelectItem = styled.span`
  font-size: 1.2rem;
  padding: 1rem;
  display: block;
  height: 1.5rem;
  &:hover {
    cursor: pointer;
    background-color: #c5c5c5;
  }
  width: '100%';
`;

export const StyledOptionsWrapper = styled.div`
  max-height: 23rem;
  overflow-y: auto;
  background-color: #f6f6f6;
  margin-top: -1rem;
  box-shadow: 0 0.2rem 1rem 0 rgba(0, 0, 0, 0.15);
`;

export const StyledInputErrorMessage = styled.span`
  color: #ff0000;
  font-size: 1.2rem;
`;
