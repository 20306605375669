import { App as AppContainer, Avatar } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { ColumnsType } from 'antd/es/table';
import _ from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { BiSolidBadgeCheck } from 'react-icons/bi';
import { FaUserInjured } from 'react-icons/fa';
import { MdCheckCircle } from 'react-icons/md';
import { getMedicalRecordsByPatient } from '../../../api/dossierPatientService';
import { getPatients } from '../../../api/patientService';
import { removePharmacyArticlesApi } from '../../../api/pharmacyService';
import { getArticlesApi } from '../../../api/referentialService';
import { StyledButton } from '../../../components/button/style';
import EmptyNotSelected from '../../../components/emptyData/EmptyNotSelected';
import SectionHeader from '../../../components/header/SectionHeader';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import Loader from '../../../components/loader';
import MedicalRecordPatientList from '../../../components/patientHistory/MedicalRecordPatientList';
import {
  StyledCol,
  StyledRow,
  StyledSelectOption,
  StyledSelectPicker,
  StyledSpace,
} from '../../../components/style';
import PageContentLeftLayout from '../../../layout/PageContentLeftLayout';
import PageContentRightLayout from '../../../layout/PageContentRightLayout';
import { StyledLayoutGridWrapper } from '../../../layout/style';
import {
  CreatePharmacyOutputReducer,
  getCreatePharmacyOutputDefaultInitialState,
} from '../../../reducers/pharmacy/createPharmacyOutput';
import chartColors from '../../../styles/color';
import {
  CreateMedicalRecordApiResponse,
  GetArticlesResponse,
  GetPatientApiResponse,
  PatientDto,
  RemovePharmacyArticlesRequestBody,
} from '../../../types';
import { getApiErrorMsg, mapStateToRequestBody } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import {
  StyleListContainer,
  StyledBlocContainer,
  StyledMedicalRecordsPatientHeader,
} from '../../style';
import AddRemoveOutputArticles from './AddRemoveOutputArticles';

type OutputsArticlesProps = {
  handleChangeCurrentTab: (tabValue: number) => void;
};

const OutputsArticles: React.FC<OutputsArticlesProps> = ({ handleChangeCurrentTab }) => {
  const { notification } = AppContainer.useApp();
  const [pharmacyOutputState, pharmacyOutputReducer] = useReducer(
    CreatePharmacyOutputReducer,
    getCreatePharmacyOutputDefaultInitialState(),
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedPatient, setSelectedPatient] = useState<number | null>(null);
  const [currentPatient, setCurrentPatient] = useState<PatientDto>();
  const [patientsList, setPatientsList] = useState<GetPatientApiResponse[]>([]);
  const [articles, setArticles] = useState<GetArticlesResponse>([]);
  const [currentMedicalRecord, setCurrentMedicalRecord] =
    useState<CreateMedicalRecordApiResponse | null>(null);
  const [medicalRecords, setMedicalRecords] = useState<
    Array<CreateMedicalRecordApiResponse>
  >([]);

  const loadPatients = () => {
    setIsLoading(true);
    Promise.resolve(getPatients())
      .then(result => {
        setPatientsList(result);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadArticles = async () => {
    setIsLoading(true);
    Promise.resolve(getArticlesApi())
      .then(result => {
        setArticles(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadPatientMedicalRecords = () => {
    setIsLoading(true);
    Promise.resolve(getMedicalRecordsByPatient(currentPatient?.id as number))
      .then(result => {
        setMedicalRecords(result);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleResetSelectedMedicalRecord = () => {
    setSelectedPatient(null);
    setCurrentPatient(undefined);
    setCurrentMedicalRecord(null);
  };

  useEffect(() => {
    loadArticles();
    loadPatients();
  }, []);

  const createOutput = () => {
    setIsLoading(true);
    try {
      removePharmacyArticlesApi(
        mapStateToRequestBody<RemovePharmacyArticlesRequestBody>(
          pharmacyOutputState.articles.map(article => ({
            idArticle: article.idArticle,
            quantityRemoved: article.quantityRemoved,
            idMedicalrecord: currentMedicalRecord?.id as number,
          })),
        ),
      ).then(() => {
        notification.success({
          message: SuccesssMsgTitle,
          description: 'Articles sorties avec succès',
        });
        setIsLoading(false);
        handleChangeCurrentTab(0);
      });
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(currentPatient)) {
      loadPatientMedicalRecords();
    }
  }, [currentPatient]);

  const columnsMedicalRecords: ColumnsType<CreateMedicalRecordApiResponse> = [
    {
      title: 'No Dossier',
      dataIndex: 'numMedicalRecord',
      render: (value, record) => (
        <StyledSpace direction='vertical'>
          <StyledSpace split='|'>
            <div style={{ fontWeight: 700 }}> {record.numMedicalRecord}</div>
            <span>{record.createdDate}</span>
          </StyledSpace>
        </StyledSpace>
      ),
    },
    {
      title: 'Service',
      dataIndex: 'serviceDto.id',
    },
    {
      title: 'Actions',
      align: 'right',
      render: (value, record) => {
        return (
          <StyledSpace>
            {record.id === currentMedicalRecord?.id ? (
              <Avatar
                style={{ backgroundColor: chartColors.deepBlue }}
                icon={<BiSolidBadgeCheck size={30} />}
              />
            ) : (
              <StyledButton
                shape='circle'
                icon={<MdCheckCircle />}
                size='middle'
                onClick={() => {
                  setCurrentMedicalRecord(record);
                }}
              />
            )}
          </StyledSpace>
        );
      },
    },
  ];

  const renderPatientSelector = () => {
    return (
      <Loader showSpinner={isLoading}>
        <StyledBlocContainer style={{ padding: 20 }}>
          <FormFieldLabel libelle='Patient' />
          <StyledSelectPicker
            placeholder='Rechercher par nom, CNI ou Numéro patient'
            onClear={() => handleResetSelectedMedicalRecord()}
            style={{ width: '100%' }}
            allowClear
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            onChange={(value, option: DefaultOptionType['data']) => {
              pharmacyOutputReducer({ type: 'resetOutputArticles' });
              setSelectedPatient(value as number);
              setCurrentMedicalRecord(null);
              setCurrentPatient(option?.data ?? []);
            }}
            defaultValue={''}
            value={selectedPatient}>
            {_.map(patientsList, item => (
              <StyledSelectOption
                key={item.id}
                value={item.id}
                data={item}
                label={`${item.firstname} ${item.name} | ${item.cni} | ${item.numPatient}`}>
                <StyledSpace split='|'>
                  <span style={{ fontWeight: 700 }}>{item.numPatient}</span>
                  {`${item.firstname} ${item.name}`}
                  <span style={{ fontWeight: 700 }}>{item.cni}</span>
                </StyledSpace>
              </StyledSelectOption>
            ))}
          </StyledSelectPicker>
        </StyledBlocContainer>
      </Loader>
    );
  };

  const renderMedicalRecordPatient = () => {
    if (_.isEmpty(currentPatient) || _.isUndefined(currentPatient)) {
      return (
        <EmptyNotSelected
          message='Aucun patient sélectionné'
          description='Veuillez sélectionner un patient pour voir des dossiers médicaux'
          icon={<FaUserInjured size={80} />}
        />
      );
    }
    return (
      <>
        <StyledMedicalRecordsPatientHeader>
          <SectionHeader title='Dossier' count={medicalRecords.length} />
        </StyledMedicalRecordsPatientHeader>
        <StyleListContainer
          style={{
            padding: '0px',
            paddingBottom: '30px',
            position: 'relative',
            height: '100vh',
          }}>
          <MedicalRecordPatientList
            dataMedicalRecords={medicalRecords}
            customColumns={columnsMedicalRecords}
            showListHeader={false}
            showTableHeader={false}
            hasPagination={false}
          />
        </StyleListContainer>
      </>
    );
  };

  const renderConfirmButton = () => {
    return (
      <StyledCol span={24}>
        {!_.isEmpty(pharmacyOutputState.articles) ? (
          <StyledButton
            block
            type='primary'
            loading={isLoading}
            disabled={isLoading}
            onClick={() => createOutput()}>
            Valider la sortie
          </StyledButton>
        ) : null}
      </StyledCol>
    );
  };

  const renderContent = () => {
    return _.isUndefined(currentMedicalRecord) || _.isNull(currentMedicalRecord) ? (
      <EmptyNotSelected
        message='Dossier médical non sélectionné'
        description='Veuillez sélectionner un dossier médical'
      />
    ) : (
      <StyleListContainer
        style={{ padding: '0px 20px', paddingBottom: 180, height: '100vh' }}>
        <SectionHeader
          title={`Sortie d'article sur le dossier ${String.fromCharCode(176)} ${
            currentMedicalRecord?.numMedicalRecord
          }`}
          count={0}
        />
        <StyledRow gutter={[16, 16]}>
          <StyledCol span={24}>
            <AddRemoveOutputArticles
              reducer={pharmacyOutputReducer}
              state={pharmacyOutputState}
              items={articles}
              isLoading={isLoading}
              listTitle='Sélectionnez un ou plusieurs articles'
              validationComponent={renderConfirmButton}
            />
          </StyledCol>
        </StyledRow>
      </StyleListContainer>
    );
  };

  return (
    <StyledLayoutGridWrapper style={{ marginTop: 4 }}>
      <PageContentLeftLayout layerShowPadding='false'>
        <div style={{ backgroundColor: `${chartColors.blue50}20` }}>
          {renderPatientSelector()}
          {renderMedicalRecordPatient()}
        </div>
      </PageContentLeftLayout>
      <PageContentRightLayout layerShowPadding='false'>
        <div style={{ overflowY: 'auto' }}>{renderContent()}</div>
      </PageContentRightLayout>
    </StyledLayoutGridWrapper>
  );
};

export default OutputsArticles;
