import _ from 'lodash';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Login from '../containers/login';
import { UserDto } from '../types';
import { getDataFromStorage } from '../utils/storage';

const LoginPage: React.FC = () => {
  const userData = getDataFromStorage<UserDto>('connectedUserData');
  const navigate = useNavigate();

  const checkIfUserLogged = async () => {
    if (!_.isUndefined(userData) && !_.isNull(userData)) {
      navigate('/consulter-dossier', { replace: true });
    }
  };

  useEffect(() => {
    checkIfUserLogged();
  }, []);
  return <Login />;
};

export default LoginPage;
