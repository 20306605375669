import { useState } from 'react';
import { IProfessionItem, ReferentialViewProps } from '../../../types';
import ProfessionForm from './ProfessionForm';
import ProfessionsList from './ProfessionsList';

const ProfessionRef = () => {
  const [activeView, setActiveView] = useState<ReferentialViewProps>('LIST_ITEMS');
  const [selectedItem, setSelectedItem] = useState<IProfessionItem>();

  const handleNavigateToView = (newView: ReferentialViewProps) => {
    setActiveView(newView);
  };

  const handleUpdateSelectedItem = (item?: IProfessionItem) => {
    setSelectedItem(item);
  };

  const renderContent = () => {
    switch (activeView) {
      case 'LIST_ITEMS':
        return (
          <ProfessionsList
            navigateToview={handleNavigateToView}
            updateCurrentItem={handleUpdateSelectedItem}
          />
        );
      case 'EDIT_ITEM':
        return (
          <ProfessionForm
            navigateToview={handleNavigateToView}
            currentData={selectedItem}
          />
        );
      case 'NEW_ITEM':
        return <ProfessionForm navigateToview={handleNavigateToView} />;
    }
  };

  return <>{renderContent()}</>;
};

export default ProfessionRef;
