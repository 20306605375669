/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer, Form } from 'antd';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FaTablets } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import {
  createMedicationApi,
  getMedicationsByDesignationApi,
} from '../../../../api/medicationService';
import { getUnits } from '../../../../api/referenceService';
import { StyledButton } from '../../../../components/button/style';
import FormFieldLabel from '../../../../components/label/FormFieldLabel';
import Loader from '../../../../components/loader';
import CustomModal from '../../../../components/modal/Modal';
import {
  StyledCol,
  StyledDivider,
  StyledInput,
  StyledRow,
  StyledSelectOption,
  StyledSelectPicker,
  StyledSpace,
  StyledTable,
} from '../../../../components/style';
import {
  CreatePrescriptionAction,
  CreatePrescriptionState,
} from '../../../../reducers/prescription/types';
import {
  CreateMedicationRequestBody,
  GetUnitResponse,
  IMedicationItem,
  IMedicationPrescription,
  UserDto,
} from '../../../../types';
import { getApiErrorMsg } from '../../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../../utils/constants';
import { ScrollContainer } from '../../../style';
import MedicationItemCard from './MedicationItemCard';

interface AddRemoveMedicationProps {
  listTitle: string;
  isLoading: boolean;
  isModalVisible: boolean;
  handleCloseModalVisibility: () => void;
  reducer: React.Dispatch<CreatePrescriptionAction>;
  state: CreatePrescriptionState;
  userData: UserDto;
}

type MedicationFormProps = { cisCode: string; designation: string; idUnit: number };

const AddRemoveMedication: React.FC<AddRemoveMedicationProps> = ({
  listTitle,
  isLoading,
  state,
  reducer,
  userData,
  isModalVisible,
  handleCloseModalVisibility,
}) => {
  const { notification } = AppContainer.useApp();
  const [paginationData, setPaginationData] = useState({
    pageNo: 0,
    pageSize: 150,
    sortBy: 'designation',
  });
  const [totalItems, setTotalItems] = useState<number>(1);

  const [form] = Form.useForm<MedicationFormProps>();
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [savedItems, setSavedItems] = useState<IMedicationItem[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key | any>([]);
  const [checkedItems, setCheckedItems] = useState<IMedicationItem[]>([]);
  const [medicationsData, setMedicationsData] = useState<IMedicationItem[]>([]);
  const [units, setUnits] = useState<GetUnitResponse[]>([]);
  const [isLoadingUnits, setIsLoadingUnits] = useState<boolean>(false);
  const [isLoadingMedications, setIsLoadingmedication] = useState<boolean>(false);

  const [showCreateMedicationForm, setShowCreateMedicationForm] =
    useState<boolean>(false);
  const [isLoadingCreateMedication, setisLoadingCreateMedication] =
    useState<boolean>(false);

  const filteredItems = _.differenceBy(medicationsData, savedItems, 'id');
  // const filteredItems = _.differenceBy(medicationsData, savedItems, 'id').filter(item =>
  //   item?.designation.toLowerCase().includes(searchFilter),
  // );

  const itemsColumns = [
    {
      title: 'label',
      dataIndex: 'cisCode',
      render: (_value, record) => <>{record.cisCode}</>,
    },
    {
      title: 'label',
      dataIndex: 'label',
      render: (_value, record) => <>{record.designation}</>,
    },
  ];

  const debouncedLoadMedications = useCallback(
    _.debounce((newSearchFilter: string) => {
      loadMedications(newSearchFilter);
    }, 2000),
    [],
  );

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(event.target.value);
    debouncedLoadMedications(event.target.value);
  };

  const loadMedications = async (searchFilter: string) => {
    setIsLoadingmedication(true);
    Promise.resolve(
      getMedicationsByDesignationApi({ ...paginationData, designation: searchFilter }),
    )
      .then(result => {
        setMedicationsData(result.content);
        setTotalItems(result.totalElements);
        setIsLoadingmedication(false);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
        setIsLoadingmedication(false);
      });
  };

  useEffect(() => {
    loadMedications(searchFilter);
  }, [paginationData]);

  useEffect(() => {
    const defaultState = _.map(state.prescriptionLines, 'idMedication');
    setSelectedRowKeys(defaultState);
  }, [state.prescriptionLines]);

  const handCloseModalVisility = () => {
    setShowCreateMedicationForm(false);
    handleCloseModalVisibility();
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any) => {
    // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    setCheckedItems(selectedRows);
    // console.log(selectedRows);
  };

  const itemsSelections = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const mapToProformaLine = (medications: IMedicationItem[]) => {
    const prescriptionLinesItems: IMedicationPrescription[] = [];

    medications.map(item => {
      const proformaLineItem: IMedicationPrescription = {
        idMedication: item.id,
        id: item.id,
        quantity: 1,
        medicationDesignation: item.designation,
        posology: '',
        treatmentDuration: '',
        unit: '',
      };

      prescriptionLinesItems.push(proformaLineItem);
    });

    return prescriptionLinesItems;
  };

  const handleValidateItems = () => {
    // On verifie que les éléments ajoutés n'existent pas déja
    const itemsToAdd = _.differenceBy(checkedItems, state.prescriptionLines, 'id');
    setSavedItems(_.concat(savedItems, itemsToAdd));
    reducer({
      type: 'setPrescriptionMedications',
      payload: mapToProformaLine(itemsToAdd),
    });
    handleCloseModalVisibility();
  };

  const removeItemFromSavedItem = (currentMedicationId: number) => {
    const result = savedItems.filter(item => item.id !== currentMedicationId);
    setSelectedRowKeys(selectedRowKeys.filter(itemId => itemId !== currentMedicationId));
    setSavedItems(result);
    reducer({ type: 'removeMedicationLine', payload: currentMedicationId });
  };

  const loadUnits = async () => {
    setIsLoadingUnits(true);
    Promise.resolve(getUnits())
      .then(result => {
        setUnits(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoadingUnits(false);
      });
  };

  useEffect(() => {
    loadUnits();
  }, []);

  const createMedication = async () => {
    try {
      await form.validateFields().then(async row => {
        const medicationData: CreateMedicationRequestBody = {
          cisCode: row.cisCode,
          designation: row.designation,
          createdBy: userData?.id,
          updatedBy: userData?.id,
          idUnit: row.idUnit,
        };

        setisLoadingCreateMedication(true);
        await createMedicationApi(medicationData).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `${row.designation} a été enregistrée!`,
          });
          loadMedications(searchFilter);
          form.resetFields();
        });
      });
    } catch (error: any) {
      if (_.isUndefined(error.values)) {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      }
    } finally {
      setisLoadingCreateMedication(false);
    }
  };

  const cancelCreateMedication = () => {
    setShowCreateMedicationForm(false);
  };

  const handlePaginate = (page: number, pageSize: number) => {
    setPaginationData(state => ({ ...state, pageNo: page - 1, pageSize: pageSize }));
  };

  const renderModalSelectionList = () => {
    return (
      <StyledTable
        rowKey='id'
        size='small'
        showHeader={false}
        bordered={false}
        dataSource={filteredItems}
        pagination={{
          current: paginationData.pageNo + 1,
          pageSize: paginationData.pageSize,
          total: totalItems,
          size: 'small',
          onChange(page, pageSize) {
            handlePaginate(page, pageSize);
          },
        }}
        rowSelection={itemsSelections}
        columns={itemsColumns}
      />
    );
  };

  const renderSelectedItems = () => {
    return (
      <StyledRow gutter={16}>
        {state.prescriptionLines.map(item => (
          <StyledCol
            key={item.medicationDesignation}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}>
            <MedicationItemCard
              medicationData={item}
              key={item.medicationDesignation}
              state={state}
              reducer={reducer}
              handleRemoveRubric={() => removeItemFromSavedItem(item.idMedication)}
            />
          </StyledCol>
        ))}
      </StyledRow>
    );
  };

  /**
   * Liste des médicaments a sélectionnés et a ajouter a la prescription
   * @returns JSX.Element
   */
  const renderMedicationSelection = () => {
    return (
      <>
        <StyledInput
          placeholder='Rechercher un médicament'
          value={searchFilter}
          onChange={e => handleChangeSearch(e)}
          prefix={<FiSearch />}
        />
        <ScrollContainer style={{ height: '50vh' }}>
          <div>
            <Loader showSpinner={isLoadingMedications}>
              {renderModalSelectionList()}
            </Loader>
          </div>
        </ScrollContainer>
      </>
    );
  };

  /**
   * Formulaire d'enregistrement de nouveaux médicaments
   * @returns JSX.Element
   */
  const renderMedicationForm = () => {
    return (
      <Form
        disabled={isLoading}
        form={form}
        component={false}
        initialValues={{
          cisCode: '',
          designation: '',
          idUnit: null,
        }}>
        <StyledRow gutter={[16, 16]} align='middle' justify='center'>
          <StyledCol span={24}>
            <FormFieldLabel libelle='Code CIS du médicament' />
            <Form.Item style={{ margin: 0 }} name='cisCode'>
              <StyledInput size='large' placeholder='Code CIS du médicament' />
            </Form.Item>
          </StyledCol>
          <StyledCol span={24}>
            <FormFieldLabel libelle='Désignation du médicament' isRequired />
            <Form.Item
              style={{ margin: 0 }}
              name='designation'
              rules={[
                { required: true, message: 'Désignation obligatoire' },
                { whitespace: false },
              ]}>
              <StyledInput size='large' placeholder='Désignation du médicament' />
            </Form.Item>
          </StyledCol>

          <StyledCol span={24}>
            <FormFieldLabel libelle='Unité' isRequired />
            <Form.Item
              style={{ margin: 0 }}
              name='idUnit'
              rules={[{ required: true, message: 'Unité obligatoire' }]}>
              <StyledSelectPicker
                loading={isLoadingUnits}
                placeholder="Sélectionnez l'unité"
                style={{ width: '100%' }}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }>
                {_.map(units, item => (
                  <StyledSelectOption key={item.id} value={item.id} label={item.libelle}>
                    {item.libelle}
                  </StyledSelectOption>
                ))}
              </StyledSelectPicker>
            </Form.Item>
          </StyledCol>
          <StyledDivider />
          <StyledCol span={24}>
            <StyledSpace>
              <StyledButton
                type='primary'
                htmlType='submit'
                disabled={isLoading}
                loading={isLoading}
                onClick={createMedication}>
                Enregistrer le médicament
              </StyledButton>
            </StyledSpace>
          </StyledCol>
        </StyledRow>
      </Form>
    );
  };

  return (
    <>
      {renderSelectedItems()}

      {isModalVisible && (
        <CustomModal
          isVisible={isModalVisible}
          handleVisibility={handCloseModalVisility}
          title={showCreateMedicationForm ? 'Enregistrer un nouvel examen' : listTitle}
          afterCloseModal={() => setSearchFilter('')}
          footer={[
            <StyledSpace
              key='modal_actions'
              direction='vertical'
              style={{ width: '100%' }}>
              {!showCreateMedicationForm ? (
                <>
                  <StyledButton
                    key='validate_medication'
                    type='primary'
                    onClick={handleValidateItems}
                    size='middle'
                    loading={isLoading}
                    disabled={
                      isLoading || isLoadingCreateMedication || _.isEmpty(checkedItems)
                    }
                    block>
                    Valider et Fermer
                  </StyledButton>
                  <StyledButton
                    key='new_medication_btn'
                    disabled={isLoadingCreateMedication}
                    size='middle'
                    icon={<FaTablets />}
                    block
                    type='dashed'
                    onClick={() => setShowCreateMedicationForm(true)}>
                    Ajouter un nouveau médicament
                  </StyledButton>
                </>
              ) : (
                <>
                  <StyledButton
                    key='cancel_medication_btn'
                    disabled={isLoadingCreateMedication}
                    size='middle'
                    block
                    danger
                    onClick={() => cancelCreateMedication()}>
                    Revenir aux médicaments
                  </StyledButton>
                </>
              )}
            </StyledSpace>,
          ]}>
          {showCreateMedicationForm
            ? renderMedicationForm()
            : renderMedicationSelection()}
        </CustomModal>
      )}
    </>
  );
};

export default AddRemoveMedication;
