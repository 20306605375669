import { App as AppContainer } from 'antd';
import _ from 'lodash';
import { useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  createAntecedentApi,
  getAntecedentByMedicalRecord,
  updateAntecedentApi,
} from '../../../../api/dossierPatientService';
import { getAntecedentApi } from '../../../../api/referentialService';
import Space from '../../../../components/Space';
import { StyledButton } from '../../../../components/button/style';
import FormFieldLabel from '../../../../components/label/FormFieldLabel';
import Loader from '../../../../components/loader';
import { StyledCol, StyledInputNumber, StyledRow } from '../../../../components/style';
import {
  createAntecedentReducer,
  getCreateAntecedentDefaultInitialState,
} from '../../../../reducers/antecedent/CreateAntecedent';
import { CreateAntecedentState } from '../../../../reducers/antecedent/types';
import {
  CheckBoxAntecedentItem,
  CreateAntecedentApiRequestBody,
  CreateMedicalRecordApiResponse,
  NavigationMode,
  StepperComponentProps,
} from '../../../../types';
import { getApiErrorMsg } from '../../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../../utils/constants';
import { getDataFromStorage } from '../../../../utils/storage';
import { StyledMedicalRecordBlocContainer } from '../../../style';
import AddRemoveAntecedent from './AddRemoveAntecedent';

const Antecedent: React.FC<StepperComponentProps> = () => {
  const location = useLocation();
  const locationState = location.state as { mode: NavigationMode };

  const { notification } = AppContainer.useApp();

  // const [currentAntecedentId, setCurrentAntecedentId] = useState<any>(undefined);
  const [selectedAntecedents, setSelectedAntecedents] = useState<
    CheckBoxAntecedentItem[]
  >([]);

  const medicalRecord =
    getDataFromStorage<CreateMedicalRecordApiResponse>('medicalRecord');
  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [antecedents, setAntecedents] = useState<CheckBoxAntecedentItem[]>([]);
  const [state, reducer] = useReducer(
    createAntecedentReducer,
    getCreateAntecedentDefaultInitialState(),
  );

  const loadMedicalRecordAntecedent = async () => {
    setIsLoading(true);

    try {
      const result = await getAntecedentByMedicalRecord(medicalRecord?.id);
      // console.log(result);
      setSelectedAntecedents(result.antecedents);
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description:
          typeof error === 'object' ? JSON.stringify(error) : (error as string),
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!_.isNull(medicalRecord)) {
      // Charger les résultats d'Interrogatoires liés au dossier
      loadMedicalRecordAntecedent();
    }
  }, []);

  useEffect(() => {
    const fetchAntecedent = async () => {
      try {
        setIsLoading(true);
        const result = await getAntecedentApi();
        setAntecedents(result);
      } catch (error) {
        notification.error({
          message: ErrorMsgTitle,
          description:
            typeof error === 'object' ? JSON.stringify(error) : (error as string),
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchAntecedent();
  }, []);

  const postAntecedent = async () => {
    try {
      setIsLoading(true);
      // console.log(state);
      await createAntecedentApi(mapStateToRequestBody(state)).then(() => {
        notification.success({
          message: SuccesssMsgTitle,
          description: "L'antécédent a bien été créé.",
        });
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateAntecedent = async () => {
    try {
      setIsLoading(true);
      // console.log(state);
      await updateAntecedentApi(mapStateToRequestBody(state)).then(() => {
        notification.success({
          message: SuccesssMsgTitle,
          description: "L'antécédent a bien été mis à jour.",
        });
      });
      // console.log(result);
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const mapStateToRequestBody = (
    state: CreateAntecedentState,
  ): CreateAntecedentApiRequestBody => {
    const antecedents = [
      ...(state.chirurgicalAntecedents || []),
      ...(state.medicalAntecedents || []),
      ...(state.familyAntecedents || []),
      ...(state.gynecoAntecedents || []),
      ...(state.modedevieAntecedents || []),
    ];
    const result = {
      antecedents: antecedents,
      idMedicalRecord: medicalRecord && medicalRecord.id,
      numMedicalRecord: medicalRecord && medicalRecord.numMedicalRecord,
    } as CreateAntecedentApiRequestBody;
    return result;
  };

  const renderActionsButton = () => {
    if (
      locationState.mode == NavigationMode.READ_MODIFY_MODE ||
      !_.isEmpty(selectedAntecedents)
    ) {
      return (
        <StyledButton
          size='large'
          type='primary'
          onClick={() => updateAntecedent()}
          loading={isLoading}
          disabled={isLoading}>
          Mettre à jour
        </StyledButton>
      );
    }
    return (
      <StyledButton
        size='large'
        type='primary'
        onClick={() => postAntecedent()}
        loading={isLoading}
        disabled={isLoading}>
        Valider
      </StyledButton>
    );
  };

  const renderForm = () => {
    return (
      <StyledMedicalRecordBlocContainer
        style={{
          backgroundColor: '#FFF',
          padding: '30px 20px',
        }}>
        <StyledRow gutter={[16, 16]}>
          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}>
            <AddRemoveAntecedent
              title='Médicaux'
              items={antecedents}
              listTitle='Sélectionnez un ou plusieurs Antécédents'
              reducer={reducer}
              reducerTypeLabel='setMedicalAntecedent'
              antecedentcategory='MEDICAUX'
              value={selectedAntecedents}
            />
          </StyledCol>
          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}>
            <AddRemoveAntecedent
              title='Chirurgicaux'
              items={antecedents}
              listTitle='Sélectionnez un ou plusieurs Antécédents'
              reducer={reducer}
              reducerTypeLabel='setChirurgicalAntecedent'
              antecedentcategory='CHIRURGICAUX'
              value={selectedAntecedents}
            />
          </StyledCol>
          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}>
            <AddRemoveAntecedent
              title='Gynéco-obstétrique'
              items={antecedents}
              listTitle='Sélectionnez un ou plusieurs Antécédents'
              reducer={reducer}
              reducerTypeLabel='setGynecoAntecedent'
              antecedentcategory='GYNECO-OBSTETRIQUE'
              value={selectedAntecedents}>
              <StyledRow style={{ width: '100%' }} gutter={[8, 8]}>
                <StyledCol
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  lg={{ span: 12 }}
                  xl={{ span: 12 }}>
                  <FormFieldLabel libelle='Nombre de grossesses' />
                  <StyledInputNumber style={{ width: '100%' }} />
                </StyledCol>
                <StyledCol
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  lg={{ span: 12 }}
                  xl={{ span: 12 }}>
                  <FormFieldLabel libelle="Nombre d'enfants" />
                  <StyledInputNumber style={{ width: '100%' }} />
                </StyledCol>
              </StyledRow>
            </AddRemoveAntecedent>
          </StyledCol>

          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}>
            <AddRemoveAntecedent
              title='Familiaux'
              items={antecedents}
              listTitle='Sélectionnez un ou plusieurs Antécédents familiaux'
              reducer={reducer}
              reducerTypeLabel='setFamilyAntecedent'
              antecedentcategory='FAMILIAUX'
              value={selectedAntecedents}
            />
          </StyledCol>

          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}>
            <AddRemoveAntecedent
              title='Mode de vie'
              items={antecedents}
              listTitle='Sélectionnez un ou plusieurs Antécédents'
              reducer={reducer}
              reducerTypeLabel='setModeDeVieAntecedent'
              antecedentcategory='MODEDEVIE'
              value={selectedAntecedents}
            />
          </StyledCol>
          <StyledCol
            span={24}
            style={{ display: 'flex', placeItems: 'center', placeContent: 'center' }}>
            {!_.isNull(medicalRecord) && renderActionsButton()}
          </StyledCol>
        </StyledRow>
      </StyledMedicalRecordBlocContainer>
    );
  };

  return (
    <>
      <Space marginTop='2rem' />
      <Loader showSpinner={isLoading}>{renderForm()}</Loader>
    </>
  );
};

export default Antecedent;
