import { App as AppContainer, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FaUserLock } from 'react-icons/fa';
import { getRightsApi } from '../../../api/rightService';
import { getRolesApi } from '../../../api/roleService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import Loader from '../../../components/loader';
import { StyledDivider, StyledSpace, StyledTooltip } from '../../../components/style';
import {
  GetRightsResponse,
  GetRolesResponse,
  IRoleItem,
  ReferentialViewProps,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle } from '../../../utils/constants';
import AddRemoveRightsFromRole from './AddRemoveRightsFromRole';

type RolesListProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  updateCurrentItem: (p: IRoleItem) => void;
};

const RolesList: React.FC<RolesListProps> = ({ navigateToview, updateCurrentItem }) => {
  const { notification } = AppContainer.useApp();
  const [rolesData, setRolesData] = useState<GetRolesResponse>([]);
  const [rightsData, setRightsData] = useState<GetRightsResponse>([]);
  const [selectedRole, setSelectedRole] = useState<IRoleItem>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModalRights, setShowModalRights] = useState<boolean>(false);

  // const handleUpdate = (role: IRoleItem) => {
  //   updateCurrentItem(role);
  //   navigateToview('EDIT_ITEM');
  // };

  // const deleteRole = async (role: IRoleItem) => {
  //   try {
  //     setIsLoading(true);
  //     Promise.resolve(deleteRoleApi(role.id as number)).then(() => {
  //       loadRoles();
  //       notification.success({
  //         message: SuccesssMsgTitle,
  //         description: `Le rôle a été supprimé`,
  //       });
  //     });
  //   } catch (error) {
  //     notification.error({
  //       message: ErrorMsgTitle,
  //       description: getApiErrorMsg(ErrorDeleteMsg),
  //     });
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const handleRemoveRole = (role: IRoleItem) => {
  //   modal.confirm({
  //     title: 'Confirmation de suppression',
  //     icon: <BsExclamationTriangleFill size={40} color={chartColors.warning} />,
  //     content: `Êtes-vous sûr de vouloir supprimer le rôle ${role.label} de manière permanente ?`,
  //     okText: 'Oui, je Confirme',
  //     cancelText: 'Annuler',
  //     onOk: () => deleteRole(role),
  //   });
  // };

  const handleModalRights = (role?: IRoleItem) => {
    if (_.isUndefined(role)) {
      setSelectedRole(undefined);
      loadRoles();
    } else {
      setSelectedRole(role);
    }
    setShowModalRights(state => !state);
  };

  const loadRights = async () => {
    setIsLoading(true);
    Promise.resolve(getRightsApi())
      .then(result => {
        setRightsData(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadRoles = async () => {
    setIsLoading(true);
    Promise.resolve(getRolesApi())
      .then(result => {
        setRolesData(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadRoles();
    loadRights();
  }, []);

  const columnData: ColumnsType<IRoleItem> = [
    {
      title: 'Code',
      dataIndex: 'code',
      width: 150,
    },
    {
      title: 'Désignation',
      dataIndex: 'label',
    },
    {
      align: 'right',
      render: (_value, record) => {
        return (
          <StyledSpace>
            {/* <StyledButton
              shape='circle'
              size='middle'
              icon={<FiEdit />}
              onClick={() => handleUpdate(record)}
            /> */}

            <StyledTooltip title='Gérer les Droits'>
              <StyledButton
                danger
                shape='circle'
                size='middle'
                icon={<FaUserLock />}
                onClick={() => handleModalRights(record)}
              />
            </StyledTooltip>

            {/* <StyledButton
              type='primary'
              shape='circle'
              size='middle'
              danger
              icon={<FiTrash2 />}
              onClick={() => handleRemoveRole(record)}
            /> */}
          </StyledSpace>
        );
      },
    },
  ];

  const renderHeader = () => {
    return (
      <SectionHeader title='Rôle' count={rolesData.length}>
        {/* <StyledButton
          icon={<BsPlus />}
          type='primary'
          onClick={() => navigateToview('NEW_ITEM')}>
          Ajouter un rôle
        </StyledButton> */}
      </SectionHeader>
    );
  };

  const renderModalRights = () => {
    return (
      <AddRemoveRightsFromRole
        rightsData={rightsData}
        currentRole={selectedRole}
        isModalVisible={showModalRights}
        handCloseModalVisility={handleModalRights}
      />
    );
  };

  const renderContent = () => {
    return (
      <>
        <StyledDivider />
        <Table
          rowKey='id'
          bordered={false}
          columns={columnData}
          dataSource={rolesData}
          size='small'
          pagination={false}
        />
      </>
    );
  };

  return (
    <>
      {renderHeader()}
      <Loader showSpinner={isLoading}>{renderContent()}</Loader>
      {showModalRights && renderModalRights()}
    </>
  );
};

export default RolesList;
