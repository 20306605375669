import { App as AppContainer, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { BsExclamationTriangleFill, BsPlus } from 'react-icons/bs';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import {
  deleteBiologicalExamApi,
  getBiologicalExamsApi,
} from '../../../api/biologicalExamService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import Loader from '../../../components/loader';
import { StyledDivider, StyledSpace, StyledTag } from '../../../components/style';
import chartColors from '../../../styles/color';
import {
  GetBiologicalExamsResponse,
  IBiologicalExamItem,
  ReferentialViewProps,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import {
  ErrorDeleteMsg,
  ErrorMsgTitle,
  SuccesssMsgTitle,
} from '../../../utils/constants';

type BiologicalExamsListProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  updateCurrentItem: (p: IBiologicalExamItem) => void;
};

const BiologicalExamsList: React.FC<BiologicalExamsListProps> = ({
  navigateToview,
  updateCurrentItem,
}) => {
  const { notification, modal } = AppContainer.useApp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [biologicalExams, setBiologicalExams] = useState<GetBiologicalExamsResponse>([]);

  const handleUpdate = (biologicalExam: IBiologicalExamItem) => {
    updateCurrentItem(biologicalExam);
    navigateToview('EDIT_ITEM');
  };

  const columnData: ColumnsType<IBiologicalExamItem> = [
    {
      title: 'Désignation',
      dataIndex: 'designation',
    },
    {
      title: 'Norme',
      dataIndex: 'norm',
    },
    {
      title: 'Unité usuelle',
      dataIndex: 'usual_unit',
    },
    {
      title: 'Catégorie|Sous-catégorie',
      render: (_value, record) => {
        return (
          <>
            <StyledTag color={chartColors.deepBlue}>
              {record.biologicalAnalysisSubCategorieDto.label}
            </StyledTag>
            <StyledTag color={chartColors.warning}>
              {
                record.biologicalAnalysisSubCategorieDto.biologicalAnalysisCategoryDto
                  .label
              }
            </StyledTag>
          </>
        );
      },
    },
    {
      title: 'Crée le',
      dataIndex: 'createdDate',
    },
    {
      title: 'Actions',
      align: 'left',
      render: (_value, record) => {
        return (
          <StyledSpace>
            <StyledButton
              shape='circle'
              size='middle'
              icon={<FiEdit />}
              onClick={() => handleUpdate(record)}
            />
            <StyledButton
              shape='circle'
              size='middle'
              danger
              icon={<FiTrash2 />}
              onClick={() => handleRemoveExam(record)}
            />
          </StyledSpace>
        );
      },
    },
  ];

  const loadBiologicalExams = async () => {
    setIsLoading(true);
    Promise.resolve(getBiologicalExamsApi())
      .then(result => {
        setBiologicalExams(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteBiologicalExam = async (biologicalExam: IBiologicalExamItem) => {
    try {
      setIsLoading(true);
      await deleteBiologicalExamApi(biologicalExam.id as number).then(() => {
        loadBiologicalExams();
        notification.success({
          message: SuccesssMsgTitle,
          description: `L'examen a été supprimé`,
        });
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(ErrorDeleteMsg),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveExam = (biologicalExam: IBiologicalExamItem) => {
    modal.confirm({
      title: 'Confirmation de suppression',
      icon: <BsExclamationTriangleFill size={40} color={chartColors.warning} />,
      content: `Êtes-vous sûr de vouloir supprimer l"examen ${biologicalExam.designation} de manière permanente ?`,
      okText: 'Oui, je Confirme',
      cancelText: 'Annuler',
      onOk: () => deleteBiologicalExam(biologicalExam),
    });
  };

  useEffect(() => {
    loadBiologicalExams();
  }, []);

  const renderHeader = () => {
    return (
      <SectionHeader title='Examen(s) biologique' count={biologicalExams.length}>
        <StyledButton
          icon={<BsPlus />}
          type='primary'
          onClick={() => navigateToview('NEW_ITEM')}>
          Ajouter un examen biologique
        </StyledButton>
      </SectionHeader>
    );
  };

  const renderContent = () => {
    return (
      <>
        <StyledDivider />
        <Table
          rowKey='id'
          bordered={false}
          columns={columnData}
          dataSource={biologicalExams}
          size='small'
          pagination={false}
        />
      </>
    );
  };

  return (
    <>
      {renderHeader()}
      <Loader showSpinner={isLoading}>{renderContent()}</Loader>
    </>
  );
};

export default BiologicalExamsList;
