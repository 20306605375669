/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer } from 'antd';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { getHospitalizationExamsApi } from '../../../../api/hospitalizationService';
import { StyledButton } from '../../../../components/button/style';
import Loader from '../../../../components/loader';
import CustomModal from '../../../../components/modal/Modal';
import {
  StyledCol,
  StyledInput,
  StyledRow,
  StyledSpace,
  StyledTable,
} from '../../../../components/style';
import dayjsInstance from '../../../../dayjs';
import {
  CreateHospitPrescriptionAction,
  CreateHospitalizationPrescriptionState,
} from '../../../../reducers/prescription/types';
import {
  HospitPrescpritionItem,
  HospitalizationExamItem,
  UserDto,
} from '../../../../types';
import { getApiErrorMsg } from '../../../../utils/commun';
import { DATE_FORMAT, ErrorMsgTitle } from '../../../../utils/constants';
import HospitalizationExamForm from '../../../referential/hospitalizationExams/HospitalizationExamForm';
import { ScrollContainer } from '../../../style';
import HospiExamCard from './HospitExamCard';

interface AddRemoveHospitExamProps {
  listTitle: string;
  loading: boolean;
  isModalVisible: boolean;
  handleCloseModalVisibility: () => void;
  reducer: React.Dispatch<CreateHospitPrescriptionAction>;
  state: CreateHospitalizationPrescriptionState;
  userData: UserDto;
}

type LoadingProps = {
  hospitExams: boolean;
  createExam: boolean;
};

type DataProps = {
  savedItems: Array<HospitalizationExamItem>;
  checkedItems: Array<HospitalizationExamItem>;
  selectedRowKeys: Array<React.Key | any>;
  searchFilter: string;
  hospitalisationsExamsData: Array<HospitalizationExamItem>;
};

const AddRemoveHospitExam: FC<AddRemoveHospitExamProps> = props => {
  const { notification } = AppContainer.useApp();
  const { state, reducer, handleCloseModalVisibility, isModalVisible, listTitle } = props;

  const [data, setData] = useState<DataProps>({
    checkedItems: [],
    hospitalisationsExamsData: [],
    savedItems: [],
    searchFilter: '',
    selectedRowKeys: [],
  });

  const filteredItems = _.differenceBy(
    data.hospitalisationsExamsData,
    data.savedItems,
    'id',
  ).filter(item => item?.designation.toLowerCase().includes(data.searchFilter));

  const [isLoading, setIsLoading] = useState<LoadingProps>({
    hospitExams: false,
    createExam: false,
  });

  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);

  const itemsColumns = [
    {
      title: 'label',
      dataIndex: 'designation',
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any) => {
    setData(c => ({ ...c, selectedRowKeys: newSelectedRowKeys }));
    setData(c => ({ ...c, checkedItems: selectedRows }));
  };

  const itemsSelections = {
    selectedRowKeys: data.selectedRowKeys,
    onChange: onSelectChange,
  };

  const mapToLine = (hospitExams: HospitalizationExamItem[]) => {
    const prescriptionLinesItems: HospitPrescpritionItem[] = [];

    hospitExams.map(item => {
      const proformaLineItem: HospitPrescpritionItem = {
        idHospitalizatrionExam: item.id,
        quantity: 1,
        hospitalizationExamDesignation: item.designation,
        comments: '',
        askDate: dayjsInstance(new Date(), DATE_FORMAT).toString(),
      };

      prescriptionLinesItems.push(proformaLineItem);
    });

    return prescriptionLinesItems;
  };

  const handleValidateItems = () => {
    // On verifie que les éléments ajoutés n'existent pas déja
    const itemsToAdd = _.differenceBy(
      data.checkedItems,
      state.prescriptionLineHospitalization,
      'idHospitalizatrionExam',
    );
    setData(c => ({ ...c, savedItems: _.concat(data.savedItems, itemsToAdd) }));
    reducer({
      type: 'setHospiPrescriptionLine',
      payload: mapToLine(itemsToAdd),
    });
    handleCloseModalVisibility();
  };

  const removeItemFromSavedItem = (currentMedicationId: number) => {
    const result = data.savedItems.filter(item => item.id !== currentMedicationId);
    setData(c => ({
      ...c,
      selectedRowKeys: data.selectedRowKeys.filter(
        itemId => itemId !== currentMedicationId,
      ),
    }));
    setData(c => ({ ...c, savedItems: result }));
    reducer({ type: 'removeHospiPrescriptionLine', payload: currentMedicationId });
  };

  const loadHospitalizationExams = async () => {
    setIsLoading(c => ({ ...c, hospitExams: true }));
    Promise.resolve(getHospitalizationExamsApi())
      .then(result => {
        setData(c => ({ ...c, hospitalisationsExamsData: result }));
        setIsLoading(c => ({ ...c, hospitExams: false }));
      })
      .catch(error => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
        setIsLoading(c => ({ ...c, hospitExams: false }));
      });
  };

  useEffect(() => {
    loadHospitalizationExams();
  }, []);

  useEffect(() => {
    const defaultState = _.map(
      state.prescriptionLineHospitalization,
      'idHospitalizatrionExam',
    );
    setData(c => ({ ...c, selectedRowKeys: defaultState }));
  }, [state.prescriptionLineHospitalization]);

  const handCloseModalVisility = () => {
    setShowCreateForm(false);
    handleCloseModalVisibility();
  };

  const renderModalSelectionList = () => {
    return (
      <StyledTable
        rowKey='id'
        size='small'
        showHeader={false}
        bordered={false}
        dataSource={filteredItems}
        pagination={false}
        rowSelection={itemsSelections}
        columns={itemsColumns}
      />
    );
  };

  const renderExamsSelection = () => {
    return (
      <>
        <StyledInput
          placeholder='Rechercher un examen'
          value={data.searchFilter}
          onChange={e => setData(c => ({ ...c, searchFilter: e.target.value }))}
          prefix={<FiSearch />}
        />
        <ScrollContainer style={{ height: '50vh' }}>
          <div>
            <Loader showSpinner={isLoading.hospitExams}>
              {renderModalSelectionList()}
            </Loader>
          </div>
        </ScrollContainer>
      </>
    );
  };

  const renderSelectedItems = () => {
    return (
      <StyledRow gutter={16}>
        {state.prescriptionLineHospitalization.map(item => (
          <StyledCol
            key={item.hospitalizationExamDesignation}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}>
            <HospiExamCard
              hospiData={item}
              key={item.hospitalizationExamDesignation}
              state={state}
              reducer={reducer}
              handleRemove={() => removeItemFromSavedItem(item.idHospitalizatrionExam)}
            />
          </StyledCol>
        ))}
      </StyledRow>
    );
  };

  const renderCreateForm = () => {
    return (
      <HospitalizationExamForm
        showBack={false}
        handleSuccess={() => {
          loadHospitalizationExams();
          setShowCreateForm(false);
        }}
      />
    );
  };

  return (
    <>
      {renderSelectedItems()}

      {isModalVisible && (
        <CustomModal
          isVisible={isModalVisible}
          handleVisibility={handCloseModalVisility}
          title={showCreateForm ? undefined : listTitle}
          afterCloseModal={() => setData(c => ({ ...c, searchFilter: '' }))}
          footer={[
            <StyledSpace
              key='modal_actions'
              direction='vertical'
              style={{ width: '100%' }}>
              {!showCreateForm ? (
                <>
                  <StyledButton
                    key='validate_hospitalization_exam'
                    type='primary'
                    onClick={handleValidateItems}
                    size='middle'
                    loading={isLoading.hospitExams}
                    disabled={isLoading.hospitExams || _.isEmpty(data.checkedItems)}
                    block>
                    Valider et Fermer
                  </StyledButton>
                  <StyledButton
                    key='new_medication_btn'
                    disabled={isLoading.createExam}
                    size='middle'
                    icon={<FaPlus />}
                    block
                    type='dashed'
                    onClick={() => setShowCreateForm(true)}>
                    Ajouter un nouvel examen d&apos;hospitalisation
                  </StyledButton>
                </>
              ) : (
                <StyledButton
                  key='cancel_medication_btn'
                  disabled={isLoading.createExam}
                  size='middle'
                  block
                  danger
                  onClick={() => setShowCreateForm(false)}>
                  Revenir aux examens
                </StyledButton>
              )}
            </StyledSpace>,
          ]}>
          {showCreateForm ? renderCreateForm() : renderExamsSelection()}
        </CustomModal>
      )}
    </>
  );
};

export default AddRemoveHospitExam;
