import { App as AppContainer, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { BsExclamationTriangleFill, BsPlus } from 'react-icons/bs';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import {
  deleteBiologicalAnalysisSubCategoryApi,
  getBiologicalAnalysisSubCategoriesApi,
} from '../../../api/referentialService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import Loader from '../../../components/loader';
import { StyledDivider, StyledSpace } from '../../../components/style';
import chartColors from '../../../styles/color';
import {
  BiologicalAnalysisCategoryDto,
  BiologicalAnalysisSubCategoryDto,
  GetBiologicalAnalysisSubCategoriesResponse,
  ReferentialViewProps,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import {
  ErrorDeleteMsg,
  ErrorMsgTitle,
  SuccesssMsgTitle,
} from '../../../utils/constants';

type BiologicalExamSubCategoryListProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  updateCurrentItem: (p: BiologicalAnalysisSubCategoryDto) => void;
};

const BiologicalExamSubCategoriesList: React.FC<BiologicalExamSubCategoryListProps> = ({
  navigateToview,
  updateCurrentItem,
}) => {
  const { notification, modal } = AppContainer.useApp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [biologicalExamSubCategories, setBiologicalExamSubCategories] =
    useState<GetBiologicalAnalysisSubCategoriesResponse>([]);

  const handleUpdate = (category: BiologicalAnalysisSubCategoryDto) => {
    updateCurrentItem(category);
    navigateToview('EDIT_ITEM');
  };

  const columnData: ColumnsType<BiologicalAnalysisSubCategoryDto> = [
    {
      title: 'Code',
      dataIndex: 'code',
      width: 150,
    },
    {
      title: 'Libellé',
      dataIndex: 'label',
    },
    {
      title: 'Catégorie',
      dataIndex: 'biologicalAnalysisCategoryDto',
      render: value => value.label,
    },
    {
      title: 'Actions',
      align: 'left',
      render: (_value, record) => {
        return (
          <StyledSpace>
            <StyledButton
              shape='circle'
              size='middle'
              icon={<FiEdit />}
              onClick={() => handleUpdate(record)}
            />
            <StyledButton
              shape='circle'
              size='middle'
              danger
              icon={<FiTrash2 />}
              onClick={() => handleRemoveCategory(record)}
            />
          </StyledSpace>
        );
      },
    },
  ];

  const loadBiologicalExamSubCategories = async () => {
    setIsLoading(true);
    Promise.resolve(getBiologicalAnalysisSubCategoriesApi())
      .then(result => {
        setBiologicalExamSubCategories(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteBiologicalExamSubCategory = async (
    biologicalSubCategory: BiologicalAnalysisCategoryDto,
  ) => {
    try {
      setIsLoading(true);
      Promise.resolve(
        deleteBiologicalAnalysisSubCategoryApi(biologicalSubCategory.id as number),
      ).then(() => {
        loadBiologicalExamSubCategories();
        notification.success({
          message: SuccesssMsgTitle,
          description: `La sous-catégorie a été supprimée`,
        });
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(ErrorDeleteMsg),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveCategory = (biologicalSubCategory: BiologicalAnalysisCategoryDto) => {
    modal.confirm({
      title: 'Confirmation de suppression',
      icon: <BsExclamationTriangleFill size={40} color={chartColors.warning} />,
      content: `Êtes-vous sûr de vouloir supprimer la sous-catégorie ${biologicalSubCategory.label} de manière permanente ?`,
      okText: 'Oui, je Confirme',
      cancelText: 'Annuler',
      onOk: () => deleteBiologicalExamSubCategory(biologicalSubCategory),
    });
  };

  useEffect(() => {
    loadBiologicalExamSubCategories();
  }, []);

  const renderHeader = () => {
    return (
      <SectionHeader
        title="Sous-catégories d'examens biologique"
        count={biologicalExamSubCategories.length}>
        <StyledButton
          icon={<BsPlus />}
          type='primary'
          onClick={() => navigateToview('NEW_ITEM')}>
          Ajouter une sous-catégorie
        </StyledButton>
      </SectionHeader>
    );
  };

  const renderContent = () => {
    return (
      <>
        <StyledDivider />
        <Table
          rowKey='id'
          bordered={false}
          columns={columnData}
          dataSource={biologicalExamSubCategories}
          size='small'
          pagination={false}
        />
      </>
    );
  };

  return (
    <>
      {renderHeader()}
      <Loader showSpinner={isLoading}>{renderContent()}</Loader>
    </>
  );
};

export default BiologicalExamSubCategoriesList;
