import { IBiologicalExamPrescription } from './biologicalExam.types';
import { IImageryExamPrescription } from './imageryExam.types';
import { PharmacyInvoiceLine } from './pharmacy.types';
import {
  BiologicalPrescriptionLineDto,
  ImageryPrescriptionLineDto,
} from './prescription.types';

export type InvoiceRubricsActionType = 'setRubricsInvoicePlan';
export type InvoiceProformaActionType = 'setProformaLinesRubric';

export type InvoiceViewType =
  | 'INVOICE_PLAN_LIST'
  | 'INVOICE_PLAN_FORM'
  | 'INVOICE_PLAN_RUBRICS_LIST'
  | 'INVOICE_RUBRIC_FORM';

export type InvoiceCreationTypeProps = 'INVOICE' | 'CREDIT_NOTE';

export enum EInvoiceType {
  BIOLOGICAL = 'BIOLOGICAL_ANALYSIS',
  IMAGERY = 'IMAGERY_ANALYSIS',
  PHARMACY = 'PHARMACY_ANALYSIS',
  OTHERS = 'OTHERS_ANALYSYS',
}

export type GetInvoicePlansResponse = {
  id: number;
  createdDate: string;
  updatedDate: string;
  updatedBy: string | number;
  label: string;
  active: boolean;
};

export interface IInvoiceRubricItem {
  id: number;
  code?: string;
  createdDate: string;
  updatedDate: string;
  updatedBy: number;
  label: string;
  active: boolean;
  mode: string;
  classique_amount_JO: number;
  classique_amount_D_N_JF: number;
  mutualiste_amount_JO: number;
  mutualiste_amount_D_N_JF: number;
}

export type GetInvoiceRubricsResponse = Array<IInvoiceRubricItem>;
export type CreateInvoiceRubricRequestBody = Omit<
  IInvoiceRubricItem,
  'id' | 'createdDate' | 'updatedDate'
>;

export interface CreateInvoicePlanRequestBody {
  id: number;
  createdDate: string;
  updatedDate: string;
  updatedBy: string | number;
  label: string;
  active: boolean;
}

export interface ProformaLineDto {
  id?: number;
  idRubric: number;
  rubricLabel: string;
  ratioLabel: string;
  ratio: number;
  quantity: number;
  unit: string;
  unitPrice: number;
  amountTotal: number;
  amountAssu: number;
  amountPatient: number;
}

export interface CreateProformaRequestBody {
  idPatient: number;
  idmedicalRecord: number | null;
  createdBy: number;
  bonNumber?: string;
  idInvoicePlanHead: number;
  proformaLines: ProformaLineDto[];
  invoiceLineBiological: IBiologicalExamPrescription[];
  invoiceLineImagery: IImageryExamPrescription[];
  invoicePharmayLineDto: PharmacyInvoiceLine[];
}

export interface CreateCreditNoteProformaRequestBody {
  idInvoice: number;
  createdBy: number;
}

export interface CreateInvoiceRequestBody {
  idProforma: number;
  createdBy: number;
  typeInvoice: InvoiceCreationTypeProps;
}

export interface CreateProformaResponse {
  id: number;
  idProformaHead: number;
  bonNumber: string;
  numPatient: string;
  numProforma: string;
  idPatient: number;
  patientName: string;
  patientFirstname: string;
  placeOfResidenceLabel: string;
  idMedicalRecord: number;
  numMedicalRecord: string;
  createdDate: string;
  createdBy: number;
  amountTotal: number;
  amountAssu: number;
  amountPatient: number;
  amountPatientTaxe: number;
  amountDue: number;
  idInvoicePlanHead: number;
  invoicePlanHeadLabel: string;
  treated: boolean;
  idInvoiceRef: number;
  numInvoiceRef: string;
  numInvoice: string;
  paymentDate: string;
  num: string;
  idInsurance?: number;
  insuranceLabel?: string;
  idSubscriber?: number;
  subscriberLabel?: string;
  insuRatio?: number;
  invoicesLines: Array<ProformaLineDto & { rubricLabel: string }>;
  invoiceLineBiologicalDto: BiologicalPrescriptionLineDto[];
  invoiceLineImageryDto: ImageryPrescriptionLineDto[];
  invoicePharmayLine: PharmacyInvoiceLine[];
}

export interface GetRubricsByInvoiceIdResponse extends ProformaLineDto {
  rubricLabel: string;
}

export interface UpdateRubricsIntoInvoicePlanRequestBody {
  idInvoicePlan: number;
  idRubrics: number[];
}

export interface SearchInvoiceApiRequestBody {
  dateDebut: string | null;
  dateEnd: string | null;
  dateDebutPayment?: string | null;
  dateEndPayment?: string | null;
  num?: string | null;
  idPatient?: number | null;
  invoice?: boolean;
  proforma?: boolean;
  creditNote?: boolean;
  paid?: boolean;
}

export type SearchInvoiceApiResponse = {
  id: number;
  createdDate: string;
  numProforma: string;
  numInvoice: string;
  idPatient: number;
  patientName: string;
  patientFirstname: string;
  placeOfResidenceLabel: string;
  idMedicalRecord: number;
  numMedicalRecord: string;
  createdBy: number;
  amountTotal: number;
  amountAssu: number;
  amountPatient: number;
  amountDue: number;
  idInvoicePlanHead: number;
  invoicePlanHeadLabel: string;
  treated: boolean;
  datePayment: string;
  idInvoiceRef: number;
  numInvoiceRef: string;
  numPatient: string;
  bonNumber: string;
};

export type GetInvoiceDuplicataResponse = CreateProformaResponse;

export interface SearchInvoiceApiRequest {
  dateDebut: string | null;
  dateEnd: string | null;
  num: string | null;
  idPatient: 0 | null;
  invoice: boolean;
  proforma: boolean;
}

export type InvoiceRubricAmountRequestBody = {
  idMedicalRecord: number;
  idRubric: number;
};

export type InvoicePlanDataProps = GetInvoicePlansResponse;
export type InvoicePlanRubricDataProps = GetInvoiceRubricsResponse;
export type InvoiceRubricsByPlanDataProps = GetInvoicePlansResponse & {
  rubrics: ProformaLineDto[];
};

export enum InvoiceRubricMode {
  MONTANT_FIXE = 'MONTANT_FIXE',
  MONTANT_VAR = 'MONTANT_VAR',
}

export enum InvoiceCreationType {
  INVOICE = 'INVOICE',
  CREDIT_NOTE = 'CREDIT_NOTE',
}
