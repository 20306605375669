import { App as AppContainer, Spin } from 'antd';
import _ from 'lodash';
import { useState } from 'react';
import { BiChevronDownCircle, BiChevronUpCircle } from 'react-icons/bi';
import { FaUserInjured } from 'react-icons/fa';
import { FiFolderPlus } from 'react-icons/fi';
import { MdPersonalInjury } from 'react-icons/md';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { searchPatientApi } from '../../api/patientService';
import { StyledButton } from '../../components/button/style';
import EmptyNotFound from '../../components/emptyData/EmptyNotFound';
import EmptyNotSelected from '../../components/emptyData/EmptyNotSelected';
import FormFieldLabel from '../../components/label/FormFieldLabel';
import Loader from '../../components/loader';
import PageHeader from '../../components/pageHeader/PageHeader';
import DetailsMedicalRecordPatient from '../../components/patientHistory/DetailsMedicalRecordPatient';
import SearchResult from '../../components/searchResult/SearchResult';
import {
  StyledCol,
  StyledDatePicker,
  StyledInput,
  StyledRow,
  StyledSelectOption,
  StyledSelectPicker,
  StyledTooltip,
} from '../../components/style';
import dayjsInstance from '../../dayjs';
import PageContentLeftLayout from '../../layout/PageContentLeftLayout';
import PageContentRightLayout from '../../layout/PageContentRightLayout';
import VerticalLayout from '../../layout/VerticalLayout';
import civilitiesMock from '../../mocks/getCivilityMock.json';
import chartColors from '../../styles/color';
import {
  NavigationMode,
  PatientDto,
  SearchPatientApiRequestBody,
  SearchPatientResult,
  StorageType,
} from '../../types';
import { DATE_FORMAT, ErrorMsgTitle } from '../../utils/constants';
import { removeDataFromStorage } from '../../utils/storage';
import { LeftLayoutContainer, StyleListContainer, StyledBlocContainer } from '../style';

const SearchPatientRecord: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });
  const { notification } = AppContainer.useApp();

  const [name, setName] = useState<string>('');
  const [civility, setCivility] = useState<string | null>(null);
  const [firstName, setFirstName] = useState<string>('');
  const [numPatient, setNumPatient] = useState<string>('');
  const [birthDate, setBirthDate] = useState<string | null>(null);
  const [cni, setCni] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [items, setItems] = useState<SearchPatientResult[]>([]);
  const [currentPatient, setCurrentPatient] = useState<PatientDto>();
  const [showPatientFilter, setShowPatientFilter] = useState<boolean>(true);

  const [, setShowMedicalRecordDrawer] = useState<boolean>(false);

  const search = async () => {
    setIsLoading(true);
    setCurrentPatient(undefined);
    const body: SearchPatientApiRequestBody = {
      name: _.isEmpty(name) ? null : name,
      firstName: _.isEmpty(firstName) ? null : firstName,
      numPatient: _.isEmpty(numPatient) ? null : numPatient,
      birthDay: _.isEmpty(birthDate) ? null : birthDate,
      phoneNumber: _.isEmpty(phoneNumber) ? null : phoneNumber,
      cni: _.isEmpty(cni) ? null : cni,
      civility: _.isEmpty(civility) ? null : civility,
    };
    try {
      const result = await searchPatientApi(body);
      setItems(result.patients as SearchPatientResult[]);
      setShowPatientFilter(false);
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description:
          typeof error === 'object' ? JSON.stringify(error) : (error as string),
      });
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   search();
  // }, []);

  // const handleSwitchPatientSearchFilter = () => {
  //   setShowPatientFilter(state => !state);
  // };

  const handleResetSelectedMedicalRecord = () => {
    setCurrentPatient(undefined);
  };

  const handleSelectPatient = (patient: PatientDto) => {
    setCurrentPatient(patient);
    if (isMobile) {
      setShowMedicalRecordDrawer(true);
    }
  };

  const renderContent = () => {
    return (
      <Spin spinning={isLoading}>
        {_.isEmpty(items) ? (
          <EmptyNotFound
            message='Aucun patient dans la liste'
            description='Aucun patient Trouvé, essayer de faire une recherche'
            icon={<MdPersonalInjury size={100} color={chartColors.black} />}
          />
        ) : (
          <StyleListContainer
            style={{
              height: showPatientFilter ? 'calc(100vh - 650px)' : 'calc(100vh - 200px)',
            }}>
            <SearchResult
              items={items}
              handleSetSelectedPatient={handleSelectPatient}
              currentSelectedPatient={currentPatient}
            />
          </StyleListContainer>
        )}
      </Spin>
    );
  };

  const renderMedicalRecordPatient = () => {
    if (_.isEmpty(currentPatient)) {
      return (
        <EmptyNotSelected
          message='Aucun patient sélectionné'
          description='veuillez effectuer une recherche et sélectionner un patient pour afficher ses dossiers ici'
          icon={<FaUserInjured size={80} />}
        />
      );
    }
    return (
      <DetailsMedicalRecordPatient
        patientData={currentPatient}
        resetSelectedRecord={handleResetSelectedMedicalRecord}
      />
    );
  };

  const renderSearchPatientFilters = () => {
    return (
      <Loader showSpinner={isLoading}>
        <StyledBlocContainer
          style={{
            backgroundColor: `${chartColors.blue}20`,
            padding: '20px 20px',
            boxShadow: '0px 5px 10px #00000030',
          }}>
          <StyledRow gutter={[12, 12]}>
            <StyledCol span={12}>
              <FormFieldLabel libelle='Civilité' />
              <StyledSelectPicker
                size='middle'
                placeholder='Civilité'
                style={{ width: '100%' }}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                onChange={value => {
                  setCivility(value as string);
                }}
                defaultValue={''}
                value={civility}>
                {_.map(civilitiesMock, item => (
                  <StyledSelectOption
                    key={item.code}
                    value={item.value}
                    label={item.libelle}>
                    {item.libelle}
                  </StyledSelectOption>
                ))}
              </StyledSelectPicker>
            </StyledCol>
            <StyledCol span={12}>
              <FormFieldLabel libelle='CNI' />
              <StyledInput
                name='identityCardNumber'
                placeholder='CNI'
                onChange={e => {
                  setCni(e.target.value);
                }}
                value={cni}
              />
            </StyledCol>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Numéro patient' />
              <StyledInput
                name='numPatient'
                placeholder='Numéro patient'
                onChange={e => {
                  setNumPatient(e.target.value);
                }}
                value={numPatient}
              />
            </StyledCol>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Nom du patient' />
              <StyledInput
                name='name'
                placeholder='Nom du patient'
                required
                onChange={e => {
                  setName(e.target.value);
                }}
                value={name}
              />
            </StyledCol>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Prénoms du patient' />
              <StyledInput
                name='firstname'
                placeholder='Prénoms du patient'
                onChange={e => {
                  setFirstName(e.target.value);
                }}
                value={firstName}
              />
            </StyledCol>

            <StyledCol span={12}>
              <FormFieldLabel libelle='Date de naissance' />
              <StyledDatePicker
                name='birthDate'
                placeholder='Date de naissance'
                style={{ width: '100%' }}
                onChange={(_value, dateString) => {
                  setBirthDate(Array.isArray(dateString) ? dateString[0] : dateString);
                }}
                value={
                  _.isEmpty(birthDate) ? null : dayjsInstance(birthDate, DATE_FORMAT)
                }
                // disabledDate={current => dayjsInstance().isBefore(current, 'day')}
                format={DATE_FORMAT}
              />
            </StyledCol>
            <StyledCol span={12}>
              <FormFieldLabel libelle='Téléphone' />
              <StyledInput
                name='phoneNumber'
                placeholder='Numéro de téléphone du patient'
                onChange={e => {
                  setPhoneNumber(e.target.value);
                }}
                value={phoneNumber}
              />
            </StyledCol>
            <StyledCol span={24}>
              <StyledButton type='primary' size='middle' onClick={() => search()}>
                Rechercher
              </StyledButton>
            </StyledCol>
          </StyledRow>
        </StyledBlocContainer>
      </Loader>
    );
  };

  return (
    <VerticalLayout layerShowPadding='false'>
      <div style={{ display: 'grid', gridTemplateColumns: '25% 75%', flex: 1 }}>
        <PageContentLeftLayout
          headerTitle={
            <PageHeader title='Patient' count={items && items.length}>
              <StyledTooltip placement='left' title='Créer un nouveau dossier'>
                <StyledButton
                  shape='circle'
                  size='middle'
                  type='primary'
                  icon={<FiFolderPlus />}
                  onClick={() => {
                    removeDataFromStorage(StorageType.SS, 'medicalRecord');
                    navigate('/dossier-patient', {
                      state: {
                        mode: NavigationMode.CREATE_MODE,
                      },
                    });
                  }}
                />
              </StyledTooltip>
            </PageHeader>
          }>
          {showPatientFilter === false ? (
            <StyledButton
              className='mx-auto mt-5'
              type='dashed'
              size='middle'
              icon={<BiChevronDownCircle />}
              onClick={() => setShowPatientFilter(true)}>
              Afficher la recherche
            </StyledButton>
          ) : (
            <>
              {renderSearchPatientFilters()}
              <StyledButton
                className='mx-auto mt-5'
                type='dashed'
                size='middle'
                icon={<BiChevronUpCircle />}
                onClick={() => setShowPatientFilter(false)}>
                Masquer la recherche
              </StyledButton>
            </>
          )}
          <LeftLayoutContainer>{renderContent()}</LeftLayoutContainer>
        </PageContentLeftLayout>
        {!isMobile && (
          <PageContentRightLayout>{renderMedicalRecordPatient()}</PageContentRightLayout>
        )}
      </div>
    </VerticalLayout>
  );
};

export default SearchPatientRecord;
