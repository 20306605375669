import React, { useState } from 'react';
import { InvoicePlanDataProps, InvoiceViewType } from '../../../../types';
import InvoiceRubricForm from '../InvoiceRubricSetting/InvoiceRubricForm';
import InvoicePlanForm from './InvoicePlanForm';
import InvoicePlansList from './InvoicePlansList';

interface InvoicePlansProps {
  handleSelectInvoicePlan: (invoicePlanArg: InvoicePlanDataProps) => void;
}

const InvoicePlanSetting: React.FC<InvoicePlansProps> = ({ handleSelectInvoicePlan }) => {
  const [activeInvoiceSettingView, setActiveInvoiceSettingView] =
    useState<InvoiceViewType>('INVOICE_PLAN_LIST');
  const [currentPlan, setCurrentPlan] = useState<InvoicePlanDataProps>();

  const toggleShowForm = (invoiceView: InvoiceViewType) => {
    setActiveInvoiceSettingView(invoiceView);
    setCurrentPlan(undefined);
  };

  const handleEditInvoicePlan = (plan: InvoicePlanDataProps) => {
    setCurrentPlan(plan);
    setActiveInvoiceSettingView('INVOICE_PLAN_FORM');
  };

  const renderContent = () => {
    switch (activeInvoiceSettingView) {
      case 'INVOICE_PLAN_FORM':
        return (
          <InvoicePlanForm
            toggleInvoicePlanForm={toggleShowForm}
            currentInvoicePlan={currentPlan}
          />
        );
      case 'INVOICE_PLAN_LIST':
        return (
          <InvoicePlansList
            toggleInvoicePlanForm={toggleShowForm}
            handleSelectInvoicePlan={handleSelectInvoicePlan}
            handleEditInvoicePlan={handleEditInvoicePlan}
          />
        );
      case 'INVOICE_RUBRIC_FORM':
        return <InvoiceRubricForm toggleInvoicePlanForm={toggleShowForm} />;

      default:
        return (
          <InvoicePlansList
            toggleInvoicePlanForm={toggleShowForm}
            handleSelectInvoicePlan={handleSelectInvoicePlan}
            handleEditInvoicePlan={handleEditInvoicePlan}
          />
        );
    }
  };

  return <>{renderContent()}</>;
};

export default InvoicePlanSetting;
