import { PropsWithChildren } from 'react';
import { StyledSpin } from '../style';
interface LoaderProps {
  showSpinner: boolean;
}
const Loader: React.FC<PropsWithChildren<LoaderProps>> = ({
  children,
  showSpinner = false,
}) => {
  return (
    // <LoaderWrapper>
    //   <LoaderContent>
    //     <LoaderBody>
    //       <LoaderIcon />
    //     </LoaderBody>
    //   </LoaderContent>
    // </LoaderWrapper>

    <StyledSpin
      spinning={showSpinner}
      // indicator={<PuffLoader color={chartColors.blue} />}
    >
      {children}
    </StyledSpin>
  );
};

export default Loader;
