import { App as AppContainer } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logOutUser } from '../api/loginService';
import dayjsInstance from '../dayjs';
import { parseJwt } from '../utils/commun';

type SessionTimerProps = {
  token: string | null;
};

const SessionTimer: React.FC<SessionTimerProps> = ({ token }) => {
  const { notification } = AppContainer.useApp();
  const [expiresIn, setExpiresIn] = useState<number | null>(null);
  const currentTime = dayjsInstance();

  const navigate = useNavigate();

  const logOut = () => {
    navigate('/', { replace: true });
    logOutUser();
    notification.warning({
      message: 'Session expirée',
      description: 'Votre session a expirée. Veuillez vous reconnecter.',
      duration: 5000,
    });
  };

  useEffect(() => {
    if (token) {
      const initialValue = JSON.parse(token);
      const jwt: string = initialValue.jwt;

      // Vérifiez la date d'expiration du JWT token
      const decodedToken = parseJwt(jwt);
      const expirationTime = dayjsInstance.unix(decodedToken.exp);
      const timeDiff = expirationTime.diff(currentTime, 'seconds');
      if (timeDiff > 0) {
        setExpiresIn(timeDiff);
        const timer = setInterval(() => {
          setExpiresIn(prevTime => (prevTime !== null ? prevTime - 1 : prevTime));
        }, 1000);

        return () => clearInterval(timer);
      } else {
        setExpiresIn(0);
        logOut();
      }
    }
  }, [token, expiresIn]);

  // const formatTime = () => {
  //   if (expiresIn !== null) {
  //     const hours = Math.floor(expiresIn / 3600);
  //     const minutes = Math.floor((expiresIn % 3600) / 60);
  //     const seconds = expiresIn % 60;
  //     return `${hours.toString().padStart(2, '0')}:${minutes
  //       .toString()
  //       .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  //   }
  //   return '';
  // };

  return (
    <div style={{ position: 'absolute', right: '10px', bottom: '10px' }}>
      {/* {process.env.NODE_ENV === 'development' ? `Session expires in ${formatTime()}` : ''} */}
    </div>
  );
};

export default SessionTimer;
