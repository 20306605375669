/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { getInvoiceRubricAmountsApi } from '../../api/invoiceService';
import { getUnits } from '../../api/referenceService';
import { StyledButton } from '../../components/button/style';
import CustomModal from '../../components/modal/Modal';
import { StyledCol, StyledInput, StyledRow, StyledSpace } from '../../components/style';
import {
  CreateInvoiceProformaAction,
  CreateManualInvoiceProformaState,
} from '../../reducers/invoicePlan/types';
import chartColors from '../../styles/color';
import {
  CreateMedicalRecordApiResponse,
  GetInvoiceRubricsResponse,
  GetUnitResponse,
  IInvoiceRubricItem,
  ProformaLineDto,
} from '../../types';
import { getApiErrorMsg, thousandSeparatorFormat } from '../../utils/commun';
import { ErrorMsgTitle, RUBRICS_TO_DISABLE } from '../../utils/constants';
import { ScrollContainer } from '../style';
import RubricItemCard from './RubricItemCard';

interface AddRemoveRubricProps {
  items: GetInvoiceRubricsResponse;
  listTitle: string;
  value?: ProformaLineDto[];
  isLoading: boolean;
  isModalVisible: boolean;
  handleCloseModalVisibility: () => void;
  selectedMedicalRecord: CreateMedicalRecordApiResponse | null;
  // handleUpdate: (args: InvoicePlanRubricDataProps[]) => void;
  reducer: React.Dispatch<CreateInvoiceProformaAction>;
  state: CreateManualInvoiceProformaState;
}
const AddRemoveInvoiceRubric: React.FC<AddRemoveRubricProps> = ({
  items,
  listTitle,
  isLoading,
  handleCloseModalVisibility,
  isModalVisible,
  state,
  reducer,
  selectedMedicalRecord,
}) => {
  const { notification } = AppContainer.useApp();
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [savedItems, setSavedItems] = useState<GetInvoiceRubricsResponse>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [checkedItems, setCheckedItems] = useState<GetInvoiceRubricsResponse>([]);
  const [unitsData, setUnitsData] = useState<GetUnitResponse[]>([]);
  const [, setIsLoadingUnits] = useState<boolean>(false);
  const [isLoadingRubrics, setIsLoadingRubrics] = useState<boolean>(false);

  const filteredItems = _.differenceBy(items, savedItems, 'id').filter(item =>
    item?.label.includes(searchFilter),
  );

  const itemsColumns: ColumnsType<IInvoiceRubricItem> = [
    {
      title: 'Rubriques',
      dataIndex: 'label',
      render: (_value, record) => (
        <>
          {record.label} <StyledSpace></StyledSpace>
        </>
      ),
    },
    {
      title: 'Montant classique',
      align: 'center',
      children: [
        {
          title: 'Jour Ouvrable',
          dataIndex: 'classique_amount_JO',
          align: 'center',
        },
        {
          title: 'Dimanche, Nuit et Férié',
          dataIndex: 'classique_amount_D_N_JF',
          align: 'center',
        },
      ],
    },
    {
      title: 'Montant Mutualiste',
      align: 'center',
      children: [
        {
          title: 'Jour Ouvrable',
          dataIndex: 'mutualiste_amount_JO',
          align: 'center',
        },
        {
          title: 'Dimanche, Nuit et Férié',
          dataIndex: 'mutualiste_amount_D_N_JF',
          align: 'center',
        },
      ],
    },
  ];

  const loadUnits = () => {
    setIsLoadingUnits(true);
    Promise.resolve(getUnits())
      .then(result => {
        setUnitsData(result);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsLoadingUnits(false);
      });
  };

  useEffect(() => {
    loadUnits();
  }, []);

  useEffect(() => {
    const defaultState = _.map(state.proformaLines, 'idRubric');
    setSelectedRowKeys(defaultState);
  }, [state.proformaLines]);

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any) => {
    // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    setCheckedItems(selectedRows);
    // console.log(selectedRows);
  };

  const itemsSelections = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: record => ({
      disabled: RUBRICS_TO_DISABLE.includes(record.code),
    }),
  };

  // const isBusinessDayAndDaytime = () => {
  //   return isBusinessDay() && !isHolyday() && isDayTime() ? true : false;
  // };

  // const findRubricValue = (item: IInvoiceRubricItem) => {
  //   if (isBusinessDayAndDaytime()) {
  //     if ((selectedMedicalRecord?.idInsurance as number) === 0) {
  //       return item.classique_amount_JO;
  //     } else {
  //       return item.mutualiste_amount_JO;
  //     }
  //   } else {
  //     if ((selectedMedicalRecord?.idInsurance as number) === 0) {
  //       return item.classique_amount_D_N_JF;
  //     } else {
  //       return item.mutualiste_amount_D_N_JF;
  //     }
  //   }
  // };

  // const findInsuranceAmount = (percentage: number, amount: number) => {
  //   return amount * (percentage / 100);
  // };

  const mapToProformaLine = async (rubrics: GetInvoiceRubricsResponse) => {
    const proformaLinesItems: ProformaLineDto[] = [];
    try {
      setIsLoadingRubrics(true);
      await Promise.all(
        rubrics.map(async (item, idx) => {
          try {
            const rubric = await getInvoiceRubricAmountsApi({
              idMedicalRecord: selectedMedicalRecord?.id as number,
              idRubric: item.id,
            });

            const proformaLineItem: ProformaLineDto = { ...rubric, id: rubric.idRubric };
            proformaLinesItems.push(proformaLineItem);

            if (rubrics.length === idx + 1) {
              setIsLoadingRubrics(false);
              handleCloseModalVisibility();
            }
          } catch (error) {
            setIsLoadingRubrics(false);
            notification.error({
              message: ErrorMsgTitle,
              description: getApiErrorMsg(error),
            });
          }
        }),
      );
    } catch (error) {
      setIsLoadingRubrics(false);
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    }

    return proformaLinesItems;
  };
  // const mapToProformaLine = (rubrics: GetInvoiceRubricsResponse) => {
  //   const proformaLinesItems: ProformaLineDto[] = [];
  //   rubrics.map(item => {
  //     const tempInsuranceAmount =
  //       (selectedMedicalRecord?.idInsurance as number) === 0
  //         ? 0
  //         : findInsuranceAmount(
  //             selectedMedicalRecord?.insuRatio as number,
  //             findRubricValue(item),
  //           );

  //     const tempPatientAmount = findRubricValue(item) - tempInsuranceAmount;

  //     const proformaLineItem: ProformaLineDto = {
  //       idRubric: item.id,
  //       id: item.id,
  //       amountPatient: tempPatientAmount,
  //       amountAssu: tempInsuranceAmount,
  //       amountTotal: tempInsuranceAmount + tempPatientAmount,
  //       ratio: selectedMedicalRecord?.insuRatio as number,
  //       rubricLabel: item.label,
  //       quantity: 1,
  //       unitPrice: tempInsuranceAmount + tempPatientAmount,
  //       unit: '',
  //     };

  //     proformaLinesItems.push(proformaLineItem);
  //   });

  //   return proformaLinesItems;
  // };

  const handleValidateItems = async () => {
    // On verifie que les éléments ajoutés n'existent pas déja
    const itemsToAdd = _.differenceBy(checkedItems, state.proformaLines, 'id');
    setSavedItems(_.concat(savedItems, itemsToAdd));
    mapToProformaLine(itemsToAdd).then(proformaItems => {
      console.log(proformaItems);
      reducer({ type: 'setProformaRubrics', payload: proformaItems });
    });
  };

  const removeItemFromSavedItem = (currentRubricId: number) => {
    const result = savedItems.filter(item => item.id !== currentRubricId);
    setSelectedRowKeys(selectedRowKeys.filter(itemId => itemId !== currentRubricId));
    setSavedItems(result);
    reducer({ type: 'removeProformaRubrics', payload: currentRubricId });
  };

  const renderModalSelectionList = () => {
    return (
      <Table
        rowKey='id'
        size='small'
        bordered={false}
        dataSource={filteredItems}
        pagination={false}
        rowSelection={itemsSelections}
        columns={itemsColumns}
      />
    );
  };

  const renderSelectedItems = () => {
    return savedItems.map((item, idx) => {
      return (
        <RubricItemCard
          rubricData={item}
          key={`${item.id}_${item.label}_${idx}`}
          state={state}
          reducer={reducer}
          handleRemoveRubric={() => removeItemFromSavedItem(item.id)}
          unitsData={unitsData}
        />
      );
    });
  };

  const renderSelectedRubricsTotal = () => {
    let totalAssu = 0;
    let totalPatient = 0;
    let totalTTC = 0;

    state.proformaLines.map(item => {
      const itemAssu = item.amountAssu * item.quantity;
      const itemPatient = item.amountPatient * item.quantity;

      totalAssu = totalAssu + itemAssu;
      totalPatient = totalPatient + itemPatient;
      totalTTC = totalAssu + totalPatient;
    });

    return (
      <StyledRow
        style={{
          marginBottom: 20,
          backgroundColor: chartColors.blue50,
          padding: 10,
          fontWeight: 700,
        }}>
        <StyledCol span={9}>TOTAL</StyledCol>
        <StyledCol span={5} style={{ textAlign: 'right' }}>
          MONTANT TOTAL: {thousandSeparatorFormat(totalTTC)}
        </StyledCol>
        <StyledCol span={5} style={{ textAlign: 'right' }}>
          PART ASSURANCE: {thousandSeparatorFormat(totalAssu)}
        </StyledCol>
        <StyledCol span={5} style={{ textAlign: 'right' }}>
          PART PATIENT: {thousandSeparatorFormat(totalPatient)}
        </StyledCol>
      </StyledRow>
    );
  };

  return (
    <>
      {renderSelectedItems()}
      {!_.isEmpty(savedItems) && renderSelectedRubricsTotal()}

      <CustomModal
        isVisible={isModalVisible}
        handleVisibility={handleCloseModalVisibility}
        title={listTitle}
        afterCloseModal={() => setSearchFilter('')}
        width='1000px'
        footer={[
          <StyledButton
            key='cancel'
            type='primary'
            onClick={() => handleValidateItems()}
            size='middle'
            loading={isLoading || isLoadingRubrics}
            disabled={isLoading || isLoadingRubrics}
            block>
            Valider et Fermer
          </StyledButton>,
        ]}>
        <StyledInput
          placeholder='Rechercher un élément'
          value={searchFilter}
          onChange={e => setSearchFilter(e.target.value)}
          prefix={<FiSearch />}
        />
        <ScrollContainer style={{ height: '50vh' }}>
          <div>{renderModalSelectionList()}</div>
        </ScrollContainer>
      </CustomModal>
    </>
  );
};

export default AddRemoveInvoiceRubric;
