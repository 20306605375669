import SimpleBar from 'simplebar-react';
import styled, { css } from 'styled-components';
import BgBlur from '../assets/images/bg_blur.png';
import BgMed from '../assets/images/bg_med.webp';
import chartColors from '../styles/color';
import { StyledLabelProps } from '../types';
import { Card } from 'antd';

const coloredBgMotion = css`
  /* background: ${() => `url(${BgMed}) no-repeat center`}; */
  background: ${() =>
    `linear-gradient(70deg, ${chartColors.deepBlue}, ${chartColors.blue1}), url(${BgMed}) no-repeat center`};
  background-blend-mode: overlay;
  background-size: cover;
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

export const StyledCard = styled.div`
  border-radius: 1rem;
  padding: 1.5rem;
  color: #788099;
`;
export const StyledRequiredMark = styled.span`
  position: absolute;
  &::after {
    content: '*';
    font-size: 1.6rem;
    color: red;
    font-weight: 500;
  }
`;
export const StyledLabel = styled.label<StyledLabelProps>`
  font-size: 1.3rem;
  margin-bottom: 1rem;
  display: block;
  font-family: Metropolis, sans-serif;
  font-weight: 600;
`;

export const StyledTab = styled.span<{ active: boolean }>`
  padding: 1rem;
  width: 15rem;
  text-align: center;
  background-color: ${({ active }) => (active ? '#b7c6f4' : 'transparent')};
  color: ${({ active }) => (active ? '#fff' : '#788099')};
  cursor: pointer;
  border-top-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
`;

export const StyledStepperContentWrapper = styled.div`
  margin-top: 2.4rem;
  @media (max-width: 992px) {
    margin-top: 0;
  }
`;

export const StyledAddTextButton = styled.div`
  cursor: pointer;
  color: #5076e8;
  font-weight: bold;
`;

export const StyledChoiceWrapper = styled.div`
  border: 1px solid gray;
  border-radius: 0.4rem;
  padding: 1rem;
`;

export const StyledSelectedAntecedent = styled.span`
  border: 1px solid #c5c5c5;
  border-radius: 0.4rem;
  padding: 0.5rem;
  background: #c5c5c5;
  color: #fff;
`;

export const StyledWrapper = styled.div`
  background-color: #edf0f8;
  width: 100vw;
  height: 100vh;
`;
export const StyledConnexionContent = styled.div`
  margin: 0 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0;
  }
`;

export const StyledConnexionImg = styled.img`
  width: 50%;
  flex: 1;
  margin-top: 7rem;
  @media (max-width: 768px) {
    width: 40rem;
    height: 30rem;
    margin-top: 0;
  }
`;

export const StyledConnexionLabel = styled.label`
  display: block;
  color: ${({ theme }) => theme.colors.blue};
  font-weight: bold;
  margin-bottom: 5px;
`;

export const StyledInput = styled.input`
  height: 3.8rem;
  width: 20rem;
  border: 0;
  border-radius: 0.5rem;
  padding: 0;
  padding-left: 0.3rem;
  color: #495057;
  &:focus {
    outline: none !important;
    border: 1px solid #4a91e2;
  }
`;

export const StyledLogo = styled.div`
  border-bottom: 0.3rem solid #5076e8;
  width: 10rem;
  margin: 0 auto;
  margin-bottom: 13rem;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
    margin-bottom: 1rem;
  }
`;

export const StyledPlus = styled.span`
  font-weight: 900;
  font-size: 4rem;
  color: ${({ theme }) => theme.colors.blue};
`;

export const StyledTabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  /* border-bottom: 2px solid #b7c6f4; */
  /* height: 4rem; */
  /* width: fit-content; */
  @media (max-width: 992px) {
    flex-direction: column;
    border-bottom: none;
  }
`;

export const TabsAndContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 992px) {
    flex-direction: row;
    gap: 3rem;
  }
`;

export const StyledCreateMedicalRecordWrapper = styled.div`
  display: flex;
  gap: 3rem;
  @media (max-width: 992px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const StyledInterrogatoireWrapper = styled.div`
  display: flex;
  gap: 5rem;
  @media (max-width: 992px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const StyledCreateParaclinicalExamWrapper = styled.div`
  display: flex;
  gap: 5rem;
  @media (max-width: 992px) {
    flex-direction: column;
    gap: 1rem;
  }
`;
export const StyledCreateParaclinicalExamContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

export const StyledPrimarytext = styled.span`
  color: ${chartColors.blue};
`;

export const StyledLoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 4rem;

  @media screen and (max-width: 390px) {
    padding: 3rem;
  }

  @media (min-width: 391px) and (max-width: 768px) {
    padding: 3rem;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    padding: 2rem;
  }
`;

export const StyleFormHeader = styled.div`
  font-weight: 500;
  font-family: 'Sharp-Sans', sans-serif;
  font-size: 1.2em;
  margin-top: -10px;
`;

export const StyledLoginFormWrapper = styled.div`
  background-color: ${chartColors.white};
  box-shadow: 0px 0px 10px #00000010;
  filter: ${() => `drop-shadow(0 50px 5.75rem ${chartColors.deepBlue}56)`};
  flex-direction: column;
  margin-top: 25px;
  min-height: 500px;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  width: 26vw;

  @media screen and (max-width: 390px) {
    width: 95vw;
  }

  @media (min-width: 391px) and (max-width: 768px) {
    width: 80vw;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    width: 45vw;
  }
`;

export const StyledLoginLeftContainer = styled.div`
  position: relative;
  flex: 1;
  background-size: cover;
  object-fit: contain;
  ${coloredBgMotion}

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledLoginRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(${BgBlur}) no-repeat;
  background-size: cover;
  padding-left: auto;
  padding-right: auto;
  /* &::after {
    position: absolute;
    content: '';
    height: 100px;
    width: 100px;
  } */
`;

export const StyledLoginContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100vh;
  width: 100%;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledLoginWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: #ffffff;
`;

export const StyledLoginHeader = styled.div`
  font-family: 'Mudclaw', sans-serif;
  text-transform: uppercase;
  font-size: 3rem;
  line-height: 30px;
  text-align: center;
  @media (max-width: 992px) {
    line-height: normal;
    font-size: 2rem;
  }
`;

export const StyledLoginHeaderSubtitle = styled.div`
  font-family: 'Sharp Sans', sans-serif;
  text-align: center;
  max-width: 400px;
  margin: auto auto;
`;

export const StyledLoginLogoTitle = styled.div`
  position: relative;
  font-family: 'Mudclaw', sans-serif;
  text-align: center;
  font-size: 2em;
  color: ${chartColors.blue};
`;

export const StyledVersion = styled.div`
  text-align: center;
  font-family: 'Sharp Sans', sans-serif;
  bottom: 10px;
  left: 10px;
  background-color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8em;
`;

export const StyleListContainer = styled(SimpleBar)`
  display: flex;
  justify-content: columns;
  padding: 0px 20px;
  padding-top: 10px;

  & .simplebar-scrollbar::before {
    background-color: ${chartColors.blue};
  }
`;

export const StyledBlocContainer = styled.div`
  /* border-radius: 6px; */
  min-height: fit-content;
  /* box-shadow: 0px 5px 10px #00000030; */
`;
export const StyledMedicalRecordBlocContainer = styled.div`
  /* border-radius: 6px; */
  /* height: 100%; */
`;
export const StyledMedicalRecordWrapper = styled.div`
  border-radius: 10px;
  height: inherit;
  /* background-color: ${chartColors.blue}; */
  margin-top: 20px;
`;

export const StyledMedicalRecordHeaderItemLabel = styled.span`
  font-size: 1.8rem;
  color: #5e5e5e;
  margin-bottom: 1rem;
  font-family: Metropolis, sans-serif;
  font-weight: 600;
`;
export const StyledMedicalRecordHeaderItemValue = styled.span`
  font-size: 1.5rem;
  color: ${chartColors.blue};
  margin-bottom: 1rem;
  font-family: Metropolis, sans-serif;
  font-weight: 600;
`;

export const StyledMedicalRecordsPatientHeader = styled.div`
  padding: 0px 20px;
  position: sticky;
  z-index: 1;
  background: white;
  box-shadow: 0px 5px 10px #00000010;
  top: 0px;
  width: 100%;
`;

export const ScrollContainer = styled(SimpleBar)``;

export const ConfigLayoutPageContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const ConfigLayoutMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  padding-top: 10px;
  padding-bottom: 60px;
  justify-content: columns;
  overflow-y: scroll;
  height: calc(100vh - 100px);
  & .simplebar-scrollbar::before {
    background-color: ${chartColors.blue};
  }
`;

export const LeftLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 0px 20px; */
  padding-top: 10px;
  /* padding-bottom: 60px; */
  justify-content: columns;
  flex: 1;
`;

export const ConfigLayoutMenuWrapper = styled(SimpleBar)`
  width: 400px;
  display: flex;
  padding-bottom: 120px;
`;
export const ConfigLayoutContentWrapper = styled.div`
  flex: 1;
  background-color: ${chartColors.white};
`;

export const MedecinAppointmentInfoLevel = styled.div`
  font-family: 'Metropolis', sans-serif;
  font-weight: 500;
`;
export const MedecinAppointmentInfoName = styled.div`
  font-family: 'Metropolis', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: ${chartColors.deepBlue};
  font-size: 1.2em;
`;
export const MedecinAppointmentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 100%;
  margin-right: 50px;
`;

export const MedecinAppointmentContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MedecinAppointmentWrapper = styled.div`
  background-color: ${chartColors.white};
  box-shadow: 0px 10px 10px ${chartColors.black}10;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
`;

export const DashboardCard = styled(Card)<{ $borderColor?: string }>`
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 5px;
    background-color: ${props =>
      props.$borderColor ? props.$borderColor : 'transparent'};
  }
`;

export const LayoutMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  padding-top: 10px;
  padding-bottom: 60px;
  justify-content: columns;
  overflow-y: scroll;
  height: calc(100vh - 100px);
  & .simplebar-scrollbar::before {
    background-color: ${chartColors.blue};
  }
`;

export const LayoutContentWrapper = styled.div`
  flex: 1;
  background-color: ${chartColors.white};
`;

export const LayoutMenuWrapper = styled(SimpleBar)`
  transition: 0.3s ease-in-out;
  width: 400px;
  display: flex;
  padding-bottom: 120px;
  box-shadow: '10px 0px 10px #00000010';
`;

export const LayoutPageContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
`;
