import _ from 'lodash';
import { CreateImageryPrescriptionAction, CreateImageryPrescriptionState } from './types';

export const getCreateImageryPrescriptionDefaultInitialState =
  (): CreateImageryPrescriptionState => {
    return {
      prescriptionLinesImagery: [],
      invalidInputs: [],
    } as CreateImageryPrescriptionState;
  };

export const CreateImageryPrescriptionReducer = (
  state: CreateImageryPrescriptionState,
  action: CreateImageryPrescriptionAction,
) => {
  const newState = { ...state };
  switch (action.type) {
    case 'setImageryPrescriptionExams':
      newState.prescriptionLinesImagery = _.concat(
        state.prescriptionLinesImagery,
        action.payload,
      );
      return newState;

    case 'removeImageryExamLine':
      newState.prescriptionLinesImagery = state.prescriptionLinesImagery.filter(
        item => item.idImageryExam !== action.payload,
      );
      return newState;

    case 'setQuantityImageryExam': {
      const imageryExamId = action.payload.idImageryExam;
      const imageryExamValue = action.payload.value;

      return {
        ...state,
        prescriptionLinesImagery: state.prescriptionLinesImagery.map(m =>
          m.idImageryExam === imageryExamId ? { ...m, quantity: imageryExamValue } : m,
        ),
      };
    }

    case 'setResetImageryPrescritionExams': {
      newState.prescriptionLinesImagery = [];
      return newState;
    }

    case 'setInvalidInputs':
      newState.invalidInputs = action.payload;
      return newState;

    default:
      return state;
  }
};
