import React from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { StyledButton } from '../../../../components/button/style';
import FormFieldLabel from '../../../../components/label/FormFieldLabel';
import {
  StyledCardItem,
  StyledCol,
  StyledInputNumber,
  StyledInputTextArea,
  StyledRow,
} from '../../../../components/style';

import {
  CreateHospitPrescriptionAction,
  CreateHospitalizationPrescriptionState,
} from '../../../../reducers/prescription/types';
import chartColors from '../../../../styles/color';
import { HospitPrescpritionItem } from '../../../../types';

interface HospiExamCardProps {
  hospiData: HospitPrescpritionItem;
  reducer: React.Dispatch<CreateHospitPrescriptionAction>;
  state: CreateHospitalizationPrescriptionState;
  handleRemove: () => void;
}

const HospiExamCard: React.FC<HospiExamCardProps> = ({
  hospiData,
  reducer,
  state,
  handleRemove,
}) => {
  const checkExistingRubric = (): number => {
    const indexFound = state.prescriptionLineHospitalization.findIndex(
      item => item.idHospitalizatrionExam === hospiData.idHospitalizatrionExam,
    );
    if (indexFound !== -1) {
      return indexFound;
    }
    return 0;
  };

  return (
    <StyledCardItem
      style={{ margin: '10px 0px' }}
      styles={{
        header: {
          fontFamily: 'Metropolis',
          textTransform: 'uppercase',
          backgroundColor: chartColors.blue50,
        },
      }}
      title={hospiData.hospitalizationExamDesignation}
      size='small'
      extra={
        <StyledButton
          danger
          size='small'
          icon={<FiTrash2 />}
          onClick={() => handleRemove()}
        />
      }>
      <StyledRow gutter={[8, 8]}>
        <StyledCol span={24}>
          <FormFieldLabel libelle='Quantité' isRequired />
          <StyledInputNumber
            required
            style={{ width: '100%' }}
            placeholder='Quantité'
            min={1}
            onChange={val =>
              reducer({
                type: 'setQuantityHospit',
                payload: {
                  idHospitalizatrionExam: hospiData.idHospitalizatrionExam,
                  value: val as number,
                },
              })
            }
            value={state.prescriptionLineHospitalization[checkExistingRubric()]?.quantity}
          />
        </StyledCol>

        <StyledCol span={24}>
          <FormFieldLabel libelle='Commentaires' isRequired />
          <StyledInputTextArea
            required
            rows={2}
            style={{ width: '100%' }}
            placeholder='Commentaires'
            onChange={e =>
              reducer({
                type: 'setCommentHospit',
                payload: {
                  idHospitalizatrionExam: hospiData.idHospitalizatrionExam,
                  value: e.target.value,
                },
              })
            }
            value={state.prescriptionLineHospitalization[checkExistingRubric()]?.comments}
          />
        </StyledCol>
      </StyledRow>
    </StyledCardItem>
  );
};

export default HospiExamCard;
