/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { App as AppContainer } from 'antd';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserDto } from '../types';
import { getDataFromStorage } from '../utils/storage';
import SessionTimer from './SessionTimer';
import Footer from './footer/Footer';
import Header from './header/Header';
import Menu from './menu/Menu';
import { StyledContentLayout } from './style';

const WithHeader = ({
  WrappedComponent,
}: {
  WrappedComponent: React.ReactNode;
}): React.ReactElement => {
  const { notification } = AppContainer.useApp();
  const [isMenuClosed, setIsMenuClosed] = useState<boolean>(true);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const { pathname } = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);

  const user = getDataFromStorage<UserDto>('connectedUserData');
  const jwt0: string = sessionStorage.getItem('jwt')!;

  const navigate = useNavigate();

  const isUserAuthenticated = () => {
    if (!_.isEmpty(user) && !_.isUndefined(user) && !_.isNull(user)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!isUserAuthenticated()) {
      notification.warning({
        message: 'Accès non autorisé',
        description: 'Vous devez être connecté pour continuer',
      });
      navigate('/', { replace: true });
    }
  }, [pathname]);

  const render = () => {
    return (
      <>
        {!isTabletOrMobile && (
          <Menu
            isclosed={isMenuClosed}
            handleMenuOpenClose={() => setIsMenuClosed(!isMenuClosed)}
          />
        )}
        <StyledContentLayout
          ref={containerRef}
          $isTabletOrMobile={isTabletOrMobile}
          $isclosed={isMenuClosed.toString()}>
          <Header />
          {WrappedComponent}
          <Footer />
        </StyledContentLayout>
      </>
    );
  };

  return (
    <>
      {isUserAuthenticated() && render()}
      {<SessionTimer token={jwt0 ?? ''} />}
    </>
  );
};

export default WithHeader;
