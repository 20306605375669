/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer } from 'antd';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { updateUserRolesApi } from '../../../api/roleService';
import { getUsersFullPrivilegesApi } from '../../../api/userService';
import { StyledButton } from '../../../components/button/style';
import CustomModal from '../../../components/modal/Modal';
import { StyledInput, StyledTable } from '../../../components/style';
import { GetRolesResponse, IUserItem, UpdateUserRolesRequestBody } from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { ScrollContainer } from '../../style';
import Loader from '../../../components/loader';

type AddRemoveUserRoleProps = {
  isModalVisible: boolean;
  handCloseModalVisility: () => void;
  currentUser?: IUserItem;
  rolesData: GetRolesResponse;
};

const AddRemoveUserRole: FC<AddRemoveUserRoleProps> = ({
  isModalVisible,
  handCloseModalVisility,
  currentUser,
  rolesData,
}) => {
  const { notification } = AppContainer.useApp();
  const [searchFilter, setSearchFilter] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key | any>([]);
  const [defaultSelected, setDefaultSelected] = useState<Array<number>>([]);
  //   const [savedItems, setSavedItems] = useState<IRoleItem[]>([]);
  //   const [checkedItems, setCheckedItems] = useState<IRoleItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userRoles, setUserRoles] = useState<GetRolesResponse>([]);
  const [isLoadingRoles, setIsLoadingRoles] = useState<boolean>(false);

  const filteredItems = rolesData.filter(role => role?.label.includes(searchFilter));
  const itemsColumns = [{ title: 'label', dataIndex: 'label' }];

  const loadUserRoles = () => {
    try {
      setIsLoadingRoles(true);
      Promise.resolve(getUsersFullPrivilegesApi(currentUser?.id as number)).then(res =>
        setUserRoles(res.roles),
      );
      setIsLoadingRoles(false);
    } catch (error) {
      setIsLoadingRoles(false);
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
    }
  };

  useEffect(() => {
    loadUserRoles();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(userRoles) && !_.isUndefined(userRoles)) {
      const defaultState = _.map(userRoles, 'id');
      // console.log(defaultState);

      //   setSavedItems(currentUser?.roles as IRoleItem[]);
      setDefaultSelected(defaultState as number[]);
      setSelectedRowKeys(defaultState);
    }
  }, [userRoles]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    // setCheckedItems(selectedRows);
  };

  const itemsSelections = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const mapDataToRequestBody = () => {
    const data = {
      idUser: currentUser?.id as number,
      idRolesToAdd: selectedRowKeys as Array<number>,
      idRolesToRemove: defaultSelected.filter(
        el => !(selectedRowKeys as Array<number>).includes(el),
      ),
    } as UpdateUserRolesRequestBody;
    return data;
  };

  const handleUpdateUserRoles = () => {
    setIsLoading(true);
    Promise.resolve(updateUserRolesApi(mapDataToRequestBody()))
      .then(() => {
        notification.success({
          message: SuccesssMsgTitle,
          description: 'Rôles mis à jour avec succès!',
        });

        handCloseModalVisility();
      })
      .catch(error => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderModalSelectionList = () => {
    return (
      <Loader showSpinner={isLoadingRoles || isLoading}>
        <StyledTable
          rowKey='id'
          size='small'
          showHeader={false}
          bordered={false}
          dataSource={filteredItems}
          pagination={false}
          rowSelection={itemsSelections}
          columns={itemsColumns}
        />
      </Loader>
    );
  };

  return (
    <CustomModal
      isVisible={isModalVisible}
      handleVisibility={handCloseModalVisility}
      title={`Ajouter/Rétirer des rôles à ${currentUser?.name} ${currentUser?.firstname}`}
      afterCloseModal={() => setSearchFilter('')}
      footer={[
        <StyledButton
          key='cancel'
          type='primary'
          onClick={() => handleUpdateUserRoles()}
          loading={isLoading}
          disabled={isLoading}
          size='middle'
          block>
          Valider et Fermer
        </StyledButton>,
      ]}>
      <StyledInput
        placeholder='Rechercher un élément'
        value={searchFilter}
        onChange={e => setSearchFilter(e.target.value)}
        prefix={<FiSearch />}
      />
      <ScrollContainer style={{ height: '50vh' }}>
        <div>{renderModalSelectionList()}</div>
      </ScrollContainer>
    </CustomModal>
  );
};

export default AddRemoveUserRole;
