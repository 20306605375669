import { Tab } from '@headlessui/react';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { StyledButton } from '../../components/button/style';
import PageHeader from '../../components/pageHeader/PageHeader';
import { StyledDivider, StyledSpace } from '../../components/style';
import VerticalLayout from '../../layout/VerticalLayout';
import chartColors from '../../styles/color';
import {
  CreateMedicalRecordApiResponse,
  ITab,
  NavigationMode,
  RIGHTS,
  PERMISSIONS,
  UserDto,
} from '../../types';
import { getDataFromStorage } from '../../utils/storage';
import { StyledMedicalRecordHeaderItemLabel } from '../style';
import ClinicalExam from './clinicalExam';
import Diagnostic from './diagnostic';
import Evolution from './evolution';
import ParaClinical from './paraClinicalExam';

interface UpdateMedicalHeaderProps {
  medicalRecord?: CreateMedicalRecordApiResponse;
}

// const RenderTabsHeader = ({
//   medicalRecord,
//   userRights,
// }: {
//   medicalRecord: CreateMedicalRecordApiResponse;
//   currentTab: number;
//   handleTabChanged: (value: SegmentedValue) => void;
//   userRights: Array<string>;
// }) => {
//   const [enableTab, setEnableTab] = useState(false);

//   const tabs: Array<ITab & { content: JSX.Element }> = [
//     {
//       value: 1,
//       label: 'Examen clinique',
//       disabled: false,
//       rights: [
//         RIGHTS.ADMIN,
//         `${RIGHTS.DOSSIER}${PERMISSIONS.CONSULT}`,
//         `${RIGHTS.DOSSIER}${PERMISSIONS.CREATION}`,
//       ],
//       content: <ClinicalExam checkMedicalRecord={checkMedicalRecord} />,
//     },
//     {
//       value: 2,
//       label: 'Examen paraclinique',
//       disabled: !enableTab,
//       rights: [
//         `${RIGHTS.PARACLI}${PERMISSIONS.CONSULT}`,
//         `${RIGHTS.PARACLI}${PERMISSIONS.CREATION}`,
//       ],
//       content: <ParaClinical />,
//     },
//     {
//       value: 3,
//       label: 'Diagnostic',
//       disabled: !enableTab,
//       rights: [],
//       content: <Diagnostic />,
//     },
//     {
//       value: 4,
//       label: 'Evolution',
//       disabled: !enableTab,
//       rights: [],
//       content: <Evolution />,
//     },
//   ];

//   const filteredTabs = tabs.filter(tab =>
//     tab?.rights.some(right => userRights.includes(right)),
//   );

//   useEffect(() => {
//     if (!_.isNull(medicalRecord)) {
//       setEnableTab(true);
//     }
//   }, [medicalRecord]);

//   return (
//     <>
//       <Tab.List className='flex flex-row space-x-2 pt-2 px-5 bg-white sticky top-[72px] z-10 border-b-blue-700 border-b-4 items-end'>
//         {filteredTabs.map((tabHead, idx) => (
//           <Tab as={Fragment} key={idx}>
//             {({ selected }) => (
//               <StyledButton
//                 type={selected ? 'primary' : 'text'}
//                 className='md:basis-1/3 rounded-none rounded-t-xl'
//                 shape='default'
//                 size={selected ? 'large' : 'middle'}
//                 disabled={tabHead.disabled}
//                 style={{
//                   backgroundColor: !selected ? chartColors.lightGray : '',
//                 }}>
//                 {tabHead.label}
//               </StyledButton>
//             )}
//           </Tab>
//         ))}
//       </Tab.List>
//       <Tab.Panels style={{ padding: '10px 20px' }}>
//         {filteredTabs.map((tab, idx) => (
//           <Tab.Panel key={idx}>{tab.content}</Tab.Panel>
//         ))}
//       </Tab.Panels>
//     </>
//   );
// };

const UpdateMedicalRecordHeader: React.FC<UpdateMedicalHeaderProps> = ({
  medicalRecord,
}) => {
  return (
    <StyledMedicalRecordHeaderItemLabel
      style={{
        marginBottom: -2,
        marginLeft: 10,
      }}>
      <StyledSpace split='|'>
        <div>
          <span style={{ color: chartColors.deepBlue }}>
            {medicalRecord?.numMedicalRecord}
          </span>{' '}
          <span style={{ textTransform: 'lowercase' }}>créé le </span>
          {medicalRecord?.createdDate}
        </div>
        {medicalRecord?.patientDto.numPatient && (
          <div>
            <span style={{ textTransform: 'initial' }}>
              N<sup>o</sup>Patient:
            </span>
            <span style={{ color: chartColors.deepBlue }}>
              {medicalRecord?.patientDto.numPatient}
            </span>
          </div>
        )}
      </StyledSpace>
    </StyledMedicalRecordHeaderItemLabel>
  );
};

const PatientRecord: React.FC = () => {
  const [showMedicalRecordheader, setShowMedicalRecordheader] = useState<boolean>(false);
  const medicalRecord =
    getDataFromStorage<CreateMedicalRecordApiResponse>('medicalRecord');

  const [currentTab, setCurrentTab] = useState<number>(0);
  const location = useLocation();
  const state = location.state as { mode: NavigationMode };
  const currentUser = getDataFromStorage<UserDto>('connectedUserData');
  const userRights: Array<string> = currentUser.rights.map(r => r.code);
  const [enableTab, setEnableTab] = useState<boolean>(false);

  useEffect(() => {
    if (!_.isNull(medicalRecord)) {
      setEnableTab(true);
    }
  }, [medicalRecord]);

  useEffect(() => {
    if (!_.isNull(medicalRecord)) {
      // Récupération des infos du MedicalRecord en cours
      setShowMedicalRecordheader(true);
    }
  }, [medicalRecord]);

  const checkMedicalRecord = () => {
    // Verification Medical Record
    const tempMedicalRecord =
      getDataFromStorage<CreateMedicalRecordApiResponse>('medicalRecord');
    if (!_.isNull(tempMedicalRecord)) {
      // Récupération des infos du MedicalRecord en cours
      setShowMedicalRecordheader(true);
    }
  };

  const renderHeader = () => {
    if (!state || state.mode === NavigationMode.CREATE_MODE) {
      return (
        <Fragment>
          <PageHeader
            title='Création de dossier'
            showBack
            subContent={
              showMedicalRecordheader === true && (
                <UpdateMedicalRecordHeader medicalRecord={medicalRecord} />
              )
            }
          />
        </Fragment>
      );
    }
    return (
      <>
        <PageHeader
          title='Modification de dossier'
          subContent={<UpdateMedicalRecordHeader medicalRecord={medicalRecord} />}
          showBack
        />
        <StyledDivider style={{ margin: 0 }} />
      </>
    );
  };

  const tabs: Array<ITab & { content: JSX.Element }> = [
    {
      value: 1,
      label: 'Examen clinique',
      disabled: false,
      rights: [
        RIGHTS.ADMIN,
        `${RIGHTS.DOSSIER}${PERMISSIONS.CONSULT}`,
        `${RIGHTS.DOSSIER}${PERMISSIONS.CREATION}`,
      ],
      content: <ClinicalExam checkMedicalRecord={checkMedicalRecord} />,
    },
    {
      value: 2,
      label: 'Examen paraclinique',
      disabled: !enableTab,
      rights: [
        RIGHTS.ADMIN,
        `${RIGHTS.PARACLI}${PERMISSIONS.CONSULT}`,
        `${RIGHTS.PARACLI}${PERMISSIONS.CREATION}`,
      ],
      content: <ParaClinical />,
    },
    {
      value: 3,
      label: 'Diagnostic',
      disabled: !enableTab,
      rights: [RIGHTS.ADMIN],
      content: <Diagnostic />,
    },
    {
      value: 4,
      label: 'Evolution',
      disabled: !enableTab,
      rights: [RIGHTS.ADMIN],
      content: <Evolution />,
    },
  ];

  const filteredTabs = tabs.filter(tab =>
    tab.rights.some(right => userRights.includes(right)),
  );

  const renderTabs = () => {
    return (
      <>
        <Tab.List className='flex flex-row space-x-2 pt-2 px-5 bg-white sticky top-[72px] z-10 border-b-blue-700 border-b-4 items-end'>
          {filteredTabs.map((tabHead, idx) => (
            <Tab as={Fragment} key={idx}>
              {({ selected }) => (
                <StyledButton
                  type={selected ? 'primary' : 'text'}
                  className='md:basis-1/3 rounded-none rounded-t-xl'
                  shape='default'
                  size={selected ? 'large' : 'middle'}
                  disabled={tabHead.disabled}
                  style={{
                    backgroundColor: !selected ? chartColors.lightGray : '',
                  }}>
                  {tabHead.label}
                </StyledButton>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels style={{ padding: '10px 20px' }}>
          {filteredTabs.map((tab, idx) => (
            <Tab.Panel key={idx}>{tab.content}</Tab.Panel>
          ))}
        </Tab.Panels>
      </>
    );
  };

  useEffect(() => {
    if (!_.isNull(medicalRecord)) {
      setEnableTab(true);
    }
  }, [medicalRecord]);

  return (
    <>
      <VerticalLayout>
        {renderHeader()}
        <Tab.Group selectedIndex={currentTab} onChange={setCurrentTab}>
          {renderTabs()}
        </Tab.Group>
      </VerticalLayout>
    </>
  );
};

export default PatientRecord;
