import React from 'react';
import { CreatePharmacyOrderResponse, ISite } from '../../types';
import chartColors from '../../styles/color';
import { DataTableCell, Table, TableBody, TableCell, TableHeader } from '../pdfTable';
import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { thousandSeparatorFormat } from '../../utils/commun';

interface PharmacyOrderDocProps {
  data: CreatePharmacyOrderResponse;
  isDuplicata: boolean;
  siteInfos: ISite;
}

const RowItem = ({
  title,
  value,
  isBold,
  isBgSet,
}: {
  title: string;
  value: string | number | undefined;
  isBold?: boolean;
  isBgSet?: boolean;
}) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 5,
        fontWeight: isBold ? 600 : 500,
        backgroundColor: isBgSet ? chartColors.blue50 : '#FFF',
      }}>
      <View>
        <Text>{title}</Text>
      </View>
      <View wrap={false}>
        <Text>{value}</Text>
      </View>
    </View>
  );
};

const PharmacyOrderDoc: React.FC<PharmacyOrderDocProps> = ({ data, siteInfos }) => {
  return (
    <Document>
      <Page style={styles.body}>
        {/* Title */}
        <View
          wrap={false}
          style={{ marginBottom: 10, border: `1px solid ${chartColors.black}` }}>
          <Text
            style={{
              textAlign: 'center',
              fontSize: 40,
              fontWeight: 600,
              textTransform: 'capitalize',
              border: `1px solid ${chartColors.black}`,
              backgroundColor: chartColors.lightGray,
            }}>
            BON DE COMMANDE
          </Text>
        </View>

        {/* Header */}
        <View wrap={false} style={styles.header}>
          <View wrap={false}>
            <Image
              src={{ uri: siteInfos.url as string, method: 'GET', headers: {}, body: '' }}
              style={{ width: 100 }}
            />
          </View>
          {/* N{String.fromCharCode(176)} de commande:  */}
          <View wrap={false} style={styles.headerDateContainer}>
            <Text>Date: {data.createdDate}</Text>
            <Text style={styles.bold}>{data.num}</Text>
          </View>
        </View>

        {/* Infos Entreprise et Client Section */}
        <View wrap={false} style={styles.userHeader}>
          <View wrap={false}>
            <Text style={[styles.bold, { color: chartColors.blue }]}>
              {siteInfos.name}
            </Text>
            <Text>{siteInfos.address}</Text>
          </View>

          <View wrap={false}>
            <Text style={[styles.bold, { color: chartColors.blue }]}>
              {data.nameSupplier}
            </Text>
            <Text>{data.telSupplier}</Text>
            <Text>{data.emailSupplier}</Text>
          </View>
        </View>

        {/* Liste des articles */}
        <Table data={data.orderLines}>
          <TableHeader
            includeLeftBorder={false}
            includeRightBorder={false}
            textAlign='center'>
            <TableCell
              fontSize={11}
              style={[
                styles.columnTitle,
                { border: 'none', flex: 3, textAlign: 'left' },
              ]}>
              Description
            </TableCell>
            <TableCell style={[styles.columnTitle, { border: 'none', flex: 1 }]}>
              Qté
            </TableCell>
            <TableCell
              style={[
                styles.columnTitle,
                { border: 'none', flex: 1, textAlign: 'right' },
              ]}>
              PU HT
            </TableCell>
            <TableCell
              style={[
                styles.columnTitle,
                { border: 'none', flex: 1, textAlign: 'right' },
              ]}>
              TVA
            </TableCell>
            <TableCell
              style={[
                styles.columnTitle,
                { border: 'none', flex: 1, textAlign: 'right' },
              ]}>
              <Text>Total HT</Text>
            </TableCell>
          </TableHeader>
          <TableBody
            zebra
            renderTopBorder
            evenRowColor={chartColors.blue50}
            oddRowColor='#FFF'
            includeLeftBorder={false}
            includeRightBorder={false}
            fontSize='13px'>
            <DataTableCell
              style={[styles.columnContent, { flex: 3 }]}
              getContent={r => r.designation}
            />
            <DataTableCell
              style={[styles.columnContent, { flex: 1, textAlign: 'center' }]}
              getContent={r => r.quantityOrdered}
            />
            <DataTableCell
              style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
              getContent={r => thousandSeparatorFormat(r.unitPrice)}
            />
            <DataTableCell
              style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
              getContent={r => thousandSeparatorFormat(r.amountTVA)}
              // getContent={r => thousandSeparatorFormat(r.unitPrice * TVA)}
            />
            <DataTableCell
              style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
              // getContent={r => thousandSeparatorFormat(r.quantity * r.unitPrice)}
              getContent={r => thousandSeparatorFormat(r.amountHT)}
            />
          </TableBody>
        </Table>

        {/* Total et Taxes */}
        <View wrap={false} style={styles.totalContainer}>
          <View
            wrap={false}
            style={{
              width: 200,
              display: 'flex',
              flexDirection: 'column',
              borderTop: '2px solid #000',
            }}>
            <RowItem
              title='Total HT'
              isBgSet
              value={thousandSeparatorFormat(data.amountHT)}
            />

            <RowItem
              title='Total TVA'
              isBgSet
              value={thousandSeparatorFormat(data.amountTVA)}
            />

            <RowItem
              title='Total TTC'
              isBold
              value={thousandSeparatorFormat(data.amountTTC)}
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

Font.register({
  family: 'Metropolis',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
  fonts: [
    { src: require('../../assets/fonts/Metropolis-Regular.woff') },
    { src: require('../../assets/fonts/Metropolis-Bold.woff'), fontWeight: 600 },
    { src: require('../../assets/fonts/Metropolis-Light.woff'), fontWeight: 400 },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 12,
    fontFamily: 'Metropolis',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 50,
  },
  headerDateContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: chartColors.blue50,
    padding: 10,
    height: 50,
  },
  userHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  objetContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  bold: {
    fontWeight: 600,
  },
  columnTitle: {
    fontSize: 12,
    fontWeight: 600,
  },
  columnContent: {
    border: 'none',
    fontSize: 12,
    borderColor: chartColors.blue50,
  },
  totalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 15,
  },
  noteContainer: {
    marginTop: 15,
    fontSize: 10,
    textAlign: 'justify',
  },
  pageFooter: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  duplicata: {
    position: 'absolute',
    zIndex: -100,
    textAlign: 'center',
    left: 5,
    right: 5,
    top: 300,
    fontSize: 98,
    fontWeight: 700,
    textTransform: 'capitalize',
    transform: 'rotate(-45deg)',
    opacity: 0.2,
  },
});

export default PharmacyOrderDoc;
