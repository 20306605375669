import {
  GetArticlesResponse,
  NumberOfMedicalRecordsRequestBody,
  StockAlertRequestBody,
  TotalAmountOfExpensesRequestBody,
  TotalAmountOfInvoicesRequestBody,
} from '../types';
import { axiosInstance } from './axiosInstance';

export const getNumberOfMedicalRecordsApi = (
  data: NumberOfMedicalRecordsRequestBody,
): Promise<number> => {
  return axiosInstance
    .post('/numberOfMedicalRecords', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération du nombre de dossier Médicaux. Raison: ${message}`,
      );
    });
};

export const getTotalAmountOfInvoicesApi = (
  data: TotalAmountOfInvoicesRequestBody,
): Promise<{
  amountHT: number;
  amountTVA: number;
  amountTTC: number;
}> => {
  return axiosInstance
    .post('/totalAmountOfInvoices', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération du montant des factures. Raison: ${message}`,
      );
    });
};

export const getTotalAmountOfExpensesApi = (
  data: TotalAmountOfExpensesRequestBody,
): Promise<{
  amountHT: number;
  amountTVA: number;
  amountTTC: number;
}> => {
  return axiosInstance
    .post('/totalAmountOfExpenses', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération du montant des dépenses. Raison: ${message}`,
      );
    });
};

export const getStocksAlertApi = (
  data: StockAlertRequestBody,
): Promise<GetArticlesResponse> => {
  return axiosInstance
    .post('/stockAlert', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération des stocks en alerte. Raison: ${message}`,
      );
    });
};
