/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  App as AppContainer,
  Empty,
  Form,
  Image,
  Table,
  Tooltip,
  UploadFile,
} from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import { AxiosError } from 'axios';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FiEdit, FiEye, FiTrash } from 'react-icons/fi';
import {
  deletImageryResultsFromPrescriptionLineApi,
  getImageryResultsByPrescriptionLineApi,
  searchImageryPrescriptionsApi,
  updateImageryPrescriptionLineApi,
  uploadImageryPrescriptionFiles,
} from '../../api/prescriptionService';
import { StyledButton } from '../../components/button/style';
import StyledDrawer from '../../components/drawer/StyledDrawer';
import SectionHeader from '../../components/header/SectionHeader';
import FormFieldLabel from '../../components/label/FormFieldLabel';
import {
  StyledCheckbox,
  StyledCol,
  StyledDivider,
  StyledInputTextArea,
  StyledRow,
  StyledSpace,
  StyledTag,
} from '../../components/style';
import StyledUploader from '../../components/uploader';
import ImageryResultDrawer from '../../components/viewer/ImageryResultDrawer';
import chartColors from '../../styles/color';
import {
  CreateMedicalRecordApiResponse,
  IImageryPrescriptionItem,
  ImageryPrescriptionLine,
  ImageryResultsPrescriptionLine,
  ImageryResultsPrescriptionLineResponse,
  SearchImageryPrescriptionResponse,
  UpdateImageryFilesPrescriptionLineRequestBody,
} from '../../types';
import { getApiErrorMsg } from '../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../utils/constants';

type ImageryPrescriptionsProps = IImageryPrescriptionItem;
interface ExternalInvoiceProps {
  selectedPatientId: number | null;
  selectedMedicalRecord: CreateMedicalRecordApiResponse | null;
}
type PrescriptionFormProps = { comments: string; done: boolean; files: UploadFile[] };

/**
 * Imageries/IRM
 * @param param0
 * @returns
 */
const ExternalAnalysis: React.FC<ExternalInvoiceProps> = ({
  selectedMedicalRecord,
  selectedPatientId,
}) => {
  const [form] = Form.useForm<PrescriptionFormProps>();
  const { notification } = AppContainer.useApp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingResult, setIsLoadingResult] = useState<boolean>(false);
  const [isDeletingResult, setIsDeletingResult] = useState<boolean>(false);
  const [selectedPrescriptionLine, setSelectedPrescriptionLine] =
    useState<ImageryPrescriptionLine>();
  const [showEditResultDrawer, setEditShowResultDrawer] = useState<boolean>(false);
  const [showResultDrawer, setShowResultDrawer] = useState<boolean>(false);
  const [imageryPrescriptionsData, setImageryPrescriptionsData] =
    useState<SearchImageryPrescriptionResponse>([]);
  const [results, setResults] = useState<ImageryResultsPrescriptionLineResponse>([]);

  const validatePrescriptionLineResult = async () => {
    setIsLoading(true);
    try {
      await form.validateFields().then(async row => {
        const prescriptionData = {
          idImageryExam: selectedPrescriptionLine?.idImageryExam as number,
          imageryExamDesignation:
            selectedPrescriptionLine?.imageryExamDesignation as string,
          quantity: selectedPrescriptionLine?.quantity as number,
          comments: row.comments,
          done: row.done,
        };

        const prescriptionFiles: UpdateImageryFilesPrescriptionLineRequestBody = {
          comments: row.comments,
          idPrescriptionLineImg: selectedPrescriptionLine?.id as number,
          files: row.files,
        };

        const resultRequest = [
          updateImageryPrescriptionLineApi(
            selectedPrescriptionLine?.id as number,
            prescriptionData,
          ),
        ];

        if (!_.isEmpty(row.files)) {
          resultRequest.push(uploadImageryPrescriptionFiles(prescriptionFiles));
        }

        Promise.all(resultRequest).then(
          () => {
            loadPatientImageryPrescriptions();
            notification.success({
              message: 'Mise à jour effectuée',
              description: `${selectedPrescriptionLine?.imageryExamDesignation} a été mis à jour!`,
            });
            setEditShowResultDrawer(false);
          },
          (err: AxiosError) => {
            notification.error({
              message: "Une erreur s'est produite",
              description: getApiErrorMsg(err.message),
            });
          },
        );
      });
    } catch (error: any) {
      if (_.isUndefined(error.values)) {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  // const loadImageryResult = async (idsResults: number[]) => {
  //   if (!_.isEmpty(idsResults)) {
  //     setIsLoadingResult(true);
  //     const tempResults: { uri: string }[] = [];
  //     idsResults?.map((resultId, idx) =>
  //       Promise.resolve(getImageryPrescriptionLineResultApi(resultId as number)).then(
  //         result => {
  //           tempResults.push({ uri: result });
  //           if (idsResults.length === idx + 1) {
  //             setIsLoadingResult(false);
  //           }
  //         },
  //       ),
  //     );
  //     setResults(tempResults);
  //     setShowResultDrawer(true);
  //   }
  // };

  const loadImageryResultsByPrescriptionLine = async (idPrescriptionLine: number) => {
    setIsLoadingResult(true);
    Promise.resolve(getImageryResultsByPrescriptionLineApi(idPrescriptionLine))
      .then(res => {
        setResults(res);
      })
      .finally(() => {
        setIsLoadingResult(false);
      });
  };

  const handleEditPrescriptionLine = (record: ImageryPrescriptionLine) => {
    form.resetFields(['done', 'comments']);
    setSelectedPrescriptionLine(record);
    setEditShowResultDrawer(true);
    loadImageryResultsByPrescriptionLine(record.id);
  };

  const handleShowPrescriptionLineResult = (record: ImageryPrescriptionLine) => {
    setSelectedPrescriptionLine(record);
    loadImageryResultsByPrescriptionLine(record.id).then(() => setShowResultDrawer(true));
  };

  const handleModalResult = () => {
    setEditShowResultDrawer(false);
    form.resetFields(['done', 'comments']);
    setSelectedPrescriptionLine(undefined);
    setResults([]);
  };

  const imageryPrescriptioncolumnData: ColumnsType<ImageryPrescriptionsProps> = [
    {
      title: 'Numéro',
      dataIndex: 'numMedicalRecord',
      render: (_value, record, index) => (
        <StyledSpace>
          <span
            style={{
              fontFamily: 'Metropolis',
              textTransform: 'uppercase',
              color: chartColors.blue,
              fontWeight: 700,
            }}>
            Prescription {`#${index + 1}`}
          </span>
          <StyledTag color={chartColors.blue}>Créé le {record.createdDate}</StyledTag>
        </StyledSpace>
      ),
    },
  ];

  const imageryExamscolumn: ColumnsType<ImageryPrescriptionLine> = [
    {
      title: 'Examen',
      dataIndex: 'imageryExamDesignation',
    },

    {
      title: 'Statut',
      align: 'center',
      width: 60,
      render: (value, record) => {
        return record.done ? (
          <StyledTag color='success'>Effectuée</StyledTag>
        ) : (
          <StyledTag color='warning'>En attente</StyledTag>
        );
      },
    },
    {
      title: 'Actions',
      align: 'left',
      width: 100,
      render: (_value, record) => {
        return (
          <StyledSpace>
            <Tooltip title='Mettre à jour les résultats'>
              <StyledButton
                icon={<FiEdit />}
                shape='circle'
                size='middle'
                onClick={() => handleEditPrescriptionLine(record)}
              />
            </Tooltip>
            <Tooltip
              title={
                _.isEmpty(record.results)
                  ? 'Aucun résultat disponible'
                  : 'Voir les résultats'
              }>
              <StyledButton
                icon={<FiEye />}
                shape='circle'
                size='middle'
                loading={isLoadingResult}
                disabled={isLoadingResult || _.isEmpty(record.results)}
                onClick={() => handleShowPrescriptionLineResult(record)}
              />
            </Tooltip>
          </StyledSpace>
        );
      },
    },
  ];

  const loadPatientImageryPrescriptions = async () => {
    setIsLoading(true);
    Promise.resolve(
      searchImageryPrescriptionsApi({
        idMedicalRecord: selectedMedicalRecord?.id as number,
        idPatient: selectedPatientId as number,
        paid: true,
      }),
    )
      .then(result => {
        setImageryPrescriptionsData(result);
        setIsLoading(false);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseViewer = () => {
    setShowResultDrawer(false);
  };

  const handleDeleteExamResultFile = (examResult: ImageryResultsPrescriptionLine) => {
    setIsDeletingResult(true);
    Promise.resolve(deletImageryResultsFromPrescriptionLineApi(examResult.id))
      .then(() => {
        notification.success({
          message: SuccesssMsgTitle,
          description: 'Résultat supprimé avec succès',
        });
        loadImageryResultsByPrescriptionLine(selectedPrescriptionLine?.id as number);
        loadPatientImageryPrescriptions();
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsDeletingResult(false);
      });
  };

  useEffect(() => {
    if (!_.isNull(selectedMedicalRecord) && !_.isNull(selectedPatientId)) {
      loadPatientImageryPrescriptions();
    }
  }, [selectedMedicalRecord, selectedPatientId]);

  const renderImageryPrescriptions = () => {
    return (
      <div style={{ padding: '0px 20px' }}>
        <StyledRow gutter={[20, 20]}>
          <StyledCol span={24}>
            <SectionHeader
              title="prescription(s) d'imagerie"
              count={imageryPrescriptionsData.length}
            />
            <Table
              rowKey='id'
              showHeader={false}
              dataSource={imageryPrescriptionsData}
              size='small'
              columns={imageryPrescriptioncolumnData}
              pagination={false}
              bordered={false}
              loading={isLoading}
              expandable={{
                expandedRowRender: record => (
                  <Table
                    rowKey='id'
                    bordered={false}
                    pagination={false}
                    columns={imageryExamscolumn}
                    dataSource={record.prescriptionLineImageryDto}
                  />
                ),
                defaultExpandAllRows: true,
              }}
            />
          </StyledCol>
        </StyledRow>
      </div>
    );
  };

  const renderContent = () => {
    return renderImageryPrescriptions();
  };

  const renderModalResult = () => {
    return (
      <StyledDrawer
        drawerTitle={`Résultat - ${selectedPrescriptionLine?.imageryExamDesignation}`}
        isDrawerOpen={showEditResultDrawer}
        closeDrawer={handleModalResult}
        placement='right'
        extraActions={
          <>
            <StyledButton
              key='cancel_invoice'
              type='primary'
              size='middle'
              danger
              disabled={isLoading}
              onClick={handleModalResult}>
              Fermer
            </StyledButton>
          </>
        }>
        <div style={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
          <Form form={form} component={false}>
            <StyledRow gutter={[16, 16]} style={{ padding: 30 }}>
              <StyledCol span={24}>
                <StyledSpace>
                  <Form.Item
                    name='done'
                    valuePropName='checked'
                    style={{ margin: 0 }}
                    initialValue={selectedPrescriptionLine?.done ?? false}>
                    <StyledCheckbox
                      disabled={(selectedPrescriptionLine?.idInvoiceHead as number) > 0}>
                      Marquer comme Effectué
                    </StyledCheckbox>
                  </Form.Item>
                </StyledSpace>
              </StyledCol>
              <StyledCol span={24}>
                <FormFieldLabel libelle='Commentaire' isRequired />
                <Form.Item
                  style={{ margin: 0 }}
                  name='comments'
                  rules={[{ required: true, message: 'Commentaire obligatoire' }]}
                  initialValue={selectedPrescriptionLine?.comments ?? ''}>
                  <StyledInputTextArea placeholder='Ecrivez un commentaire' rows={4} />
                </Form.Item>
              </StyledCol>

              <StyledCol span={24}>
                <FormFieldLabel libelle='Résultats joints' />
                <div style={{ marginBottom: 10, width: '100%' }}>
                  {!_.isEmpty(selectedPrescriptionLine?.results) ? (
                    <Image.PreviewGroup>
                      <StyledRow gutter={[20, 20]}>
                        {results.map((r, key) => (
                          <StyledCol
                            key={key}
                            style={{
                              border: '1px solid #b6b6b6',
                              padding: '10px 10px',
                              borderRadius: '15px',
                              marginRight: 10,
                            }}>
                            <Image width={100} height={100} src={r.url} />
                            <StyledButton
                              style={{ position: 'absolute', top: '0px', right: '0px' }}
                              size='middle'
                              icon={<FiTrash />}
                              danger
                              onClick={() => handleDeleteExamResultFile(r)}
                              shape='circle'
                              disabled={isDeletingResult}
                              loading={isDeletingResult}
                            />
                          </StyledCol>
                        ))}
                      </StyledRow>
                    </Image.PreviewGroup>
                  ) : (
                    <Empty
                      description='Aucun résultat trouvé / disponible'
                      style={{ width: '100%' }}
                    />
                  )}
                </div>
              </StyledCol>
              <StyledCol span={24}>
                <FormFieldLabel libelle='Joindre un ou plusieurs fichier' />
                <div style={{ marginBottom: 10 }}>
                  <Form.Item name='files'>
                    <StyledUploader
                      allowMultiple
                      maxFileNumber={5}
                      acceptedFiles={['PNG', 'JPEG']}
                      callback={uploadedFiles =>
                        form.setFieldValue('files', uploadedFiles)
                      }
                    />
                  </Form.Item>
                </div>
              </StyledCol>
              <StyledDivider />
              <StyledCol span={24}>
                <StyledButton
                  type='primary'
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={() => validatePrescriptionLineResult()}>
                  Enregistrer le résultat
                </StyledButton>
              </StyledCol>
            </StyledRow>
          </Form>
        </div>
      </StyledDrawer>
    );
  };

  const renderDisplayResultDrawer = () => {
    return (
      <ImageryResultDrawer
        files={results}
        drawerTitle={`Résultats d'imagerie - ${selectedPrescriptionLine?.imageryExamDesignation} `}
        closeDrawer={() => setShowResultDrawer(false)}
        extraActions={
          <>
            <StyledButton
              key='close_display_result_drawer'
              type='primary'
              size='middle'
              danger
              disabled={isLoading}
              onClick={() => handleCloseViewer()}>
              Fermer
            </StyledButton>
          </>
        }
        isDrawerOpen={showResultDrawer}
      />
    );
  };

  return (
    <>
      <div style={{ padding: '0px' }}>{renderContent()}</div>
      {showEditResultDrawer && renderModalResult()}
      {showResultDrawer && renderDisplayResultDrawer()}
    </>
  );
};

export default ExternalAnalysis;
