import React from 'react';
import { StyledButton } from '../button/style';

type TimeSlotProps = {
  hour: string;
  isSelected: boolean;
  isDisabled: boolean;
  handleSelectTime: () => void;
};

const TimeSlot: React.FC<TimeSlotProps> = ({
  hour,
  isSelected,
  isDisabled = false,
  handleSelectTime,
}) => {
  return (
    <div style={{ padding: '5px 0px' }}>
      <StyledButton
        onClick={handleSelectTime}
        type={isSelected === false ? 'dashed' : 'primary'}
        block
        disabled={isDisabled}>
        {hour}
      </StyledButton>
    </div>
  );
};

export default TimeSlot;
