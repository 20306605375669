import { useState } from 'react';
import { IAntecedentItem, ReferentialViewProps } from '../../../types';
import AntecedentForm from './AntecedentForm';
import AntecedentsList from './AntecedentsList';

const AntecedentsRef = () => {
  const [activeView, setActiveView] = useState<ReferentialViewProps>('LIST_ITEMS');
  const [selectedAntecedent, setSelectedAntecedent] = useState<IAntecedentItem>();

  const handleNavigateToView = (newView: ReferentialViewProps) => {
    setActiveView(newView);
  };

  const handleUpdateSelectedAntecedent = (antecedent?: IAntecedentItem) => {
    setSelectedAntecedent(antecedent);
  };

  const renderContent = () => {
    switch (activeView) {
      case 'LIST_ITEMS':
        return (
          <AntecedentsList
            navigateToview={handleNavigateToView}
            updateCurrentItem={handleUpdateSelectedAntecedent}
          />
        );
      case 'EDIT_ITEM':
        return (
          <AntecedentForm
            navigateToview={handleNavigateToView}
            currentData={selectedAntecedent}
          />
        );
      case 'NEW_ITEM':
        return <AntecedentForm navigateToview={handleNavigateToView} />;
    }
  };

  return <>{renderContent()}</>;
};

export default AntecedentsRef;
