import { useState } from 'react';
import { MdOutlineFilterList, MdOutlineFilterListOff } from 'react-icons/md';
import { StyledButton } from '../../components/button/style';
import PageHeader from '../../components/pageHeader/PageHeader';
import VerticalLayout from '../../layout/VerticalLayout';
import {
  LayoutContentWrapper,
  LayoutMenuContainer,
  LayoutMenuWrapper,
  LayoutPageContainer,
} from '../style';
import chartColors from '../../styles/color';

const KpiAssistedDecision: React.FC = () => {
  const [showLeftlayout, setShowLeftlayout] = useState<boolean>(true);

  const toggleSearch = () => {
    setShowLeftlayout(state => !state);
  };

  const renderHeader = () => {
    return <PageHeader title='KPI et Décision assistée' />;
  };

  const renderSearchFilters = () => {
    return <></>;
  };

  const renderSearchContent = () => {
    return <></>;
  };

  const renderContent = () => {
    return (
      <>
        {showLeftlayout && (
          <LayoutMenuWrapper
            style={{ maxHeight: '90vh', backgroundColor: `${chartColors.blue50}20` }}>
            {renderSearchFilters()}
          </LayoutMenuWrapper>
        )}
        <LayoutContentWrapper style={{ maxHeight: '90vh', position: 'relative' }}>
          <LayoutMenuContainer>{renderSearchContent()}</LayoutMenuContainer>
          <StyledButton
            type='primary'
            icon={showLeftlayout ? <MdOutlineFilterListOff /> : <MdOutlineFilterList />}
            style={{
              position: 'absolute',
              top: '16px',
              borderRadius: '0px 5px 5px 0px',
              border: '0px',
            }}
            onClick={toggleSearch}
            shape='default'>
            {showLeftlayout ? 'Masquer' : 'Afficher'}
          </StyledButton>
        </LayoutContentWrapper>
      </>
    );
  };

  return (
    <VerticalLayout>
      {renderHeader()}
      <LayoutPageContainer>{renderContent()}</LayoutPageContainer>
    </VerticalLayout>
  );
};

export default KpiAssistedDecision;
