import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import dayjsInstance from '../../dayjs';
import { GetMedecinAppointmentsResponse, TimeSlotProps } from '../../types';
import { StyledButton } from '../button/style';
import AppointmentDay from './AppointmentDay';
import {
  TimeSlotCalendarContainer,
  TimeSlotCalendarWrapper,
  TimeSlotNavigationContainer,
  TimeSlotNavigationWrapper,
} from './style';

const days = dayjsInstance.weekdays(true);

interface ITimeslotCalendar {
  callback: (timeslot: TimeSlotProps) => void;
  absencesTimeslots: TimeSlotProps[];
  currentAppointments: GetMedecinAppointmentsResponse;
  allowTimeslotSelection?: boolean;
  allowDisabledTimeslots?: boolean;
  resetData: () => void;
}

const AppointmentCalendar: React.FC<ITimeslotCalendar> = ({
  callback,
  absencesTimeslots,
  currentAppointments,
  allowTimeslotSelection = true,
  resetData,
}) => {
  const [week, setWeek] = useState(dayjsInstance().week());
  const [daysWeekDate, setDaysWeekDate] = useState<Dayjs[]>([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlotProps>();

  const [appointments, setAppointments] = useState<GetMedecinAppointmentsResponse>([]);
  const [absences, setAbsences] = useState<TimeSlotProps[]>([]);

  const handleUpdateTimeSlots = (timeSlot: TimeSlotProps) => {
    if (
      selectedTimeSlot?.date === timeSlot.date &&
      selectedTimeSlot.hour === timeSlot.hour
    ) {
      setSelectedTimeSlot(undefined);
      callback(timeSlot);
    } else {
      setSelectedTimeSlot(timeSlot);
      callback(timeSlot);
    }
  };

  useEffect(() => {
    console.log(currentAppointments);

    setAppointments(currentAppointments);
  }, [currentAppointments]);

  useEffect(() => {
    setAbsences(absencesTimeslots);
  }, [absencesTimeslots]);

  useEffect(() => {
    const tempDates: Dayjs[] = [];
    const initialdate = dayjsInstance().week(week).startOf('week');
    for (let i = 0; i < days.length; i++) {
      tempDates.push(initialdate.add(i, 'day'));
    }
    setDaysWeekDate(tempDates);
  }, [week]);

  const nextWeek = () => {
    setWeek(week + 1);
  };

  const prevWeek = () => {
    if (week > 0) {
      setWeek(week - 1);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <TimeSlotCalendarWrapper>
        <TimeSlotCalendarContainer>
          {days.map((day, index) => (
            <AppointmentDay
              key={day}
              day={day}
              dayDates={daysWeekDate}
              dateIndex={index}
              handleUpdateTimeSlots={handleUpdateTimeSlots}
              selectedTimeSlot={selectedTimeSlot}
              absencesTimeslots={absences && absences}
              currentAppointments={appointments}
              shouldSelected={allowTimeslotSelection}
              resetData={resetData}
            />
          ))}
        </TimeSlotCalendarContainer>
      </TimeSlotCalendarWrapper>
      <TimeSlotNavigationWrapper>
        <TimeSlotNavigationContainer>
          <StyledButton
            onClick={prevWeek}
            icon={<FiChevronLeft />}
            shape='circle'
            size='large'
          />
          <StyledButton
            onClick={nextWeek}
            icon={<FiChevronRight />}
            shape='circle'
            size='large'
          />
        </TimeSlotNavigationContainer>
      </TimeSlotNavigationWrapper>
    </div>
  );
};

export default AppointmentCalendar;
