import { useState } from 'react';
import { IActItem, ReferentialViewProps } from '../../../types';
import ActForm from './ActForm';
import ActsList from './ActsList';

const ActSetting = () => {
  const [activeView, setActiveView] = useState<ReferentialViewProps>('LIST_ITEMS');
  const [selectedAct, setSelectedAct] = useState<IActItem>();

  const handleNavigateToView = (newView: ReferentialViewProps) => {
    setActiveView(newView);
  };

  const handleUpdateSelectedRatio = (item?: IActItem) => {
    setSelectedAct(item);
  };

  const renderContent = () => {
    switch (activeView) {
      case 'LIST_ITEMS':
        return (
          <ActsList
            navigateToview={handleNavigateToView}
            updateCurrentItem={handleUpdateSelectedRatio}
          />
        );
      case 'EDIT_ITEM':
        return (
          <ActForm navigateToview={handleNavigateToView} currentData={selectedAct} />
        );
      case 'NEW_ITEM':
        return <ActForm navigateToview={handleNavigateToView} />;
    }
  };

  return <>{renderContent()}</>;
};

export default ActSetting;
