/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer, Form } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import {
  createHospitalizationExamApi,
  updateHospitalizationExamApi,
} from '../../../api/hospitalizationService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import {
  StyledCol,
  StyledDivider,
  StyledInput,
  StyledInputNumber,
  StyledRow,
  StyledSpace,
} from '../../../components/style';
import {
  CreateHospitalizationExamRequestBody,
  HospitalizationExamItem,
  ReferentialViewProps,
  UserDto,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { getDataFromStorage } from '../../../utils/storage';
import { StyledBlocContainer } from '../../style';

type HospitExamFormProps = {
  navigateToview?: (p: ReferentialViewProps) => void;
  currentData?: HospitalizationExamItem;
  showBack?: boolean;
  handleSuccess?: () => void;
};

type FormPropertiesProps = {
  id: number;
  designation: string;
  amountClassic: number;
  amountMutual: number;
};

const HospitalizationExamForm: React.FC<HospitExamFormProps> = ({
  navigateToview,
  currentData,
  handleSuccess,
  showBack,
}) => {
  const { notification } = AppContainer.useApp();
  const [form] = Form.useForm<FormPropertiesProps>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [ratios, setRatios] = useState<GetRatiosResponse>([]);

  const user = getDataFromStorage<UserDto>('connectedUserData');

  const handleBackClick = () => {
    navigateToview && navigateToview('LIST_ITEMS');
  };
  // const loadRatios = async () => {
  //   setIsLoading(true);
  //   Promise.resolve(getRatiosApi())
  //     .then(result => {
  //       setRatios(result);
  //     })
  //     .catch(e => {
  //       notification.error({
  //         message: ErrorMsgTitle,
  //         description: getApiErrorMsg(e),
  //       });
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   loadRatios();
  // }, []);

  const renderHeader = () => {
    return (
      <SectionHeader
        title={
          _.isUndefined(currentData)
            ? "Nouvel examen d'hospitalisation"
            : "Modification examen d'hospitalisation"
        }
        onBack={showBack ? handleBackClick : undefined}
      />
    );
  };

  const mapPropsToRequest = (row: FormPropertiesProps) => {
    const itemData: CreateHospitalizationExamRequestBody = {
      designation: row.designation,
      createdBy: user.id,
      lastUpdatedBy: user.id,
      amountClassic: row.amountClassic,
      amountMutual: row.amountMutual,
    };

    return itemData;
  };

  const createItem = async () => {
    setIsLoading(true);
    try {
      await form.validateFields().then(async row => {
        Promise.resolve(createHospitalizationExamApi(mapPropsToRequest(row))).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `${row.designation} a été enregistré!`,
          });
          {
            handleSuccess
              ? handleSuccess()
              : navigateToview && navigateToview('LIST_ITEMS');
          }
        });
      });
    } catch (error: any) {
      if (_.isUndefined(error.values)) {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      }
      setIsLoading(false);
    }
  };

  const editItem = async () => {
    try {
      await form.validateFields().then(async row => {
        setIsLoading(true);
        await updateHospitalizationExamApi(
          currentData?.id as number,
          mapPropsToRequest(row),
        ).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `L'examen a été modifié!`,
          });
          {
            handleSuccess
              ? handleSuccess()
              : navigateToview && navigateToview('LIST_ITEMS');
          }
        });
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    return (
      <StyledBlocContainer style={{ padding: 20 }}>
        <Form
          disabled={isLoading}
          form={form}
          component={false}
          initialValues={{
            // ratioId: _.isUndefined(currentData) ? null : currentData.ratioId,
            designation: _.isUndefined(currentData) ? '' : currentData.designation,
            amountClassic: _.isUndefined(currentData) ? '' : currentData.amountClassic,
            amountMutual: _.isUndefined(currentData) ? '' : currentData.amountMutual,
          }}>
          <StyledRow gutter={[16, 16]} align='middle' justify='center'>
            <StyledCol span={24}>
              <FormFieldLabel libelle="Désignation de l'examen" isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='designation'
                rules={[
                  { required: true, message: 'Désignation obligatoire' },
                  { whitespace: false },
                ]}>
                <StyledInput size='large' placeholder="Désignation de l'examen" />
              </Form.Item>
            </StyledCol>

            {/* <StyledCol span={24}>
              <FormFieldLabel libelle='Coefficient' />
              <Form.Item
                style={{ margin: 0 }}
                name='ratioId'
                // rules={[{ required: true, message: 'Coefficient obligatoire' }]}
              >
                <StyledSelectPicker
                  placeholder='Coefficient'
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }>
                  {_.map(ratios, item => (
                    <StyledSelectOption key={item.id} value={item.id} label={item.code}>
                      {item.code}
                    </StyledSelectOption>
                  ))}
                </StyledSelectPicker>
              </Form.Item>
            </StyledCol> */}

            <StyledCol span={24} lg={{ span: 12 }} xl={{ span: 12 }}>
              <FormFieldLabel libelle='Montant classique' />
              <Form.Item
                style={{ margin: 0 }}
                name='amountClassic'
                // rules={[{ required: true, message: 'Montant obligatoire' }]}
              >
                <StyledInputNumber
                  style={{ width: '100%' }}
                  size='large'
                  placeholder='Montant classsic'
                  min={0}
                />
              </Form.Item>
            </StyledCol>

            <StyledCol span={24} lg={{ span: 12 }} xl={{ span: 12 }}>
              <FormFieldLabel libelle='Montant Mutualiste' />
              <Form.Item
                style={{ margin: 0 }}
                name='amountMutual'
                // rules={[{ required: true, message: 'Montant obligatoire' }]}
              >
                <StyledInputNumber
                  style={{ width: '100%' }}
                  size='large'
                  placeholder='Montant Mutualiste'
                  min={0}
                />
              </Form.Item>
            </StyledCol>

            <StyledDivider />
            <StyledCol span={24}>
              <StyledSpace>
                <StyledButton
                  type='primary'
                  htmlType='submit'
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={_.isUndefined(currentData) ? createItem : editItem}>
                  {_.isUndefined(currentData)
                    ? "Enregistrer l'examen d'hospitalisation"
                    : "Modifier l'examen d'hospitalisation"}
                </StyledButton>
              </StyledSpace>
            </StyledCol>
          </StyledRow>
        </Form>
      </StyledBlocContainer>
    );
  };

  return (
    <>
      {renderHeader()}
      <StyledDivider />
      {renderContent()}
    </>
  );
};

export default HospitalizationExamForm;
