/* eslint-disable react/no-unescaped-entities */
import { Upload, App as AppContainer } from 'antd';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import React, { useState } from 'react';
import { FaUpload } from 'react-icons/fa';
import chartColors from '../../styles/color';
import { FileFormats, UploadFileFormats } from '../../types';

interface IUploaderProps {
  allowMultiple?: boolean;
  fileName?: string;
  acceptedFiles?: FileFormats[];
  acceptedFilesDescription?: string;
  maxFileNumber?: number;
  callback: (files: UploadFile[]) => void;
}

const { Dragger } = Upload;

const StyledUploader: React.FC<IUploaderProps> = ({
  allowMultiple = false,
  fileName = 'file',
  acceptedFiles = ['JPEG', 'PNG'],
  acceptedFilesDescription,
  maxFileNumber = 1,
  callback,
}) => {
  const { message } = AppContainer.useApp();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const acceptedFormat = acceptedFiles.map(f => UploadFileFormats[f].toString());

  const props: UploadProps = {
    fileList: fileList,
    name: fileName,
    multiple: allowMultiple,
    accept: acceptedFormat.toString(),
    maxCount: maxFileNumber,
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    onChange(info) {
      const { fileList } = info;
      const tempFiles: UploadFile[] = [];
      fileList.map(file => {
        if (!acceptedFormat.includes(file.type as string)) {
          message.error(`Le format du fichier ${file.name} est incorrect`);
        } else if ((file?.size as number) > 2000000) {
          message.error(`La taille du fichier ${file.name} est supérieur a 2Mo`);
        } else if ((file?.name.length as number) > 100) {
          message.error(
            `Le nom du fichier ${file.name} doit être inférieur à 100 caractères`,
          );
        } else {
          tempFiles.push(file);
        }
      });
      setFileList(tempFiles);
      callback(fileList);
    },
    beforeUpload() {
      return false;
    },
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      callback(newFileList);
      return false;
    },
  };

  return (
    <Dragger {...props}>
      <FaUpload size={50} color={chartColors.blue} />
      <p className='ant-upload-text'>
        Cliquez ou faites glisser le fichier vers cette zone pour l'importer
      </p>
      <p className='ant-upload-hint'>
        {acceptedFilesDescription ??
          `Les formats de fichiers acceptés sont ${acceptedFiles.map(f => `${f}`)}`}
      </p>
    </Dragger>
  );
};

export default StyledUploader;
