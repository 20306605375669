import { CreateAutoInvoiceProformaState, CreateInvoiceProformaAction } from './types';

export const getCreateAutoInvoiceProformaDefaultInitialState =
  (): CreateAutoInvoiceProformaState => {
    return {
      plan: undefined,
      rubrics: [],
      invalidInputs: [],
    } as CreateAutoInvoiceProformaState;
  };

export const CreateAutoInvoiceProformaReducer = (
  state: CreateAutoInvoiceProformaState,
  action: CreateInvoiceProformaAction,
) => {
  const newState = { ...state };
  switch (action.type) {
    case 'setPlanRubrics': {
      newState.rubrics = action.payload;
      return newState;
    }

    case 'removePlan': {
      newState.rubrics = [];
      newState.plan = undefined;
      return newState;
    }

    case 'setPlan': {
      newState.plan = action.payload;
      return newState;
    }

    case 'setUnitRubric': {
      const rubricId = action.payload.id;
      const rubricValue = action.payload.value;
      return {
        ...state,
        rubrics: state.rubrics.map(r =>
          r.id === rubricId ? { ...r, unit: rubricValue } : r,
        ),
      };
    }

    case 'setAmountPatientRubric': {
      const rubricId = action.payload.id;
      const rubricValue = action.payload.value;
      return {
        ...state,
        rubrics: state.rubrics.map(r =>
          r.idRubric === rubricId ? { ...r, amountPatient: rubricValue } : r,
        ),
      };
    }

    case 'setAmountAssuRubric': {
      const rubricId = action.payload.id;
      const rubricValue = action.payload.value;
      return {
        ...state,
        rubrics: state.rubrics.map(r =>
          r.idRubric === rubricId ? { ...r, amountAssu: rubricValue } : r,
        ),
      };
    }

    case 'setAmountTotalRubric': {
      const rubricId = action.payload.id;
      const rubricValue = action.payload.value;
      return {
        ...state,
        rubrics: state.rubrics.map(r =>
          r.idRubric === rubricId ? { ...r, amountTotal: rubricValue } : r,
        ),
      };
    }

    case 'setQuantityRubric': {
      const rubricId = action.payload.id;
      const rubricValue = action.payload.value;
      return {
        ...state,
        rubrics: state.rubrics.map(r =>
          r.id === rubricId
            ? {
                ...r,
                quantity: rubricValue,
              }
            : r,
        ),
      };
    }

    case 'setUnitPriceRubric': {
      const rubricId = action.payload.id;
      const rubricValue = action.payload.value;
      return {
        ...state,
        rubrics: state.rubrics.map(r =>
          r.id === rubricId ? { ...r, unitPrice: rubricValue } : r,
        ),
      };
    }

    case 'setInvalidInputs':
      newState.invalidInputs = action.payload;
      return newState;

    default:
      return state;
  }
};
