/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer, Form } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { createRoleApi, updateRoleApi } from '../../../api/roleService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import {
  StyledCol,
  StyledDivider,
  StyledInput,
  StyledRow,
  StyledSpace,
} from '../../../components/style';
import { CreateRoleRequestBody, IRoleItem, ReferentialViewProps } from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { StyledBlocContainer } from '../../style';

type RoleFormProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  currentData?: IRoleItem;
};

type FormPropertiesProps = {
  id: number;
  code: string;
  label: string;
};

const RoleForm: React.FC<RoleFormProps> = ({ navigateToview, currentData }) => {
  const { notification } = AppContainer.useApp();
  const [form] = Form.useForm<FormPropertiesProps>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const renderHeader = () => {
    return (
      <SectionHeader
        title={_.isUndefined(currentData) ? 'Nouveau rôle' : 'Modification rôle'}
        onBack={() => navigateToview('LIST_ITEMS')}
      />
    );
  };

  const mapPropsToRequest = (row: FormPropertiesProps) => {
    const roleData: CreateRoleRequestBody = {
      code: row.code,
      label: row.label,
    };

    return roleData;
  };

  const createRole = async () => {
    try {
      await form.validateFields().then(async row => {
        setIsLoading(true);
        await createRoleApi(mapPropsToRequest(row)).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `${row.label} a été enregistré!`,
          });
          navigateToview('LIST_ITEMS');
        });
      });
    } catch (error: any) {
      if (_.isUndefined(error.values)) {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const editRole = async () => {
    try {
      await form.validateFields().then(async row => {
        setIsLoading(true);
        await updateRoleApi(currentData?.id as number, mapPropsToRequest(row)).then(
          () => {
            notification.success({
              message: SuccesssMsgTitle,
              description: `Le rôle a été modifié!`,
            });
            navigateToview('LIST_ITEMS');
          },
        );
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    return (
      <StyledBlocContainer style={{ padding: 20 }}>
        <Form
          disabled={isLoading}
          form={form}
          component={false}
          initialValues={{
            code: _.isUndefined(currentData) ? '' : currentData.code,
            label: _.isUndefined(currentData) ? '' : currentData.label,
          }}>
          <StyledRow gutter={[16, 16]} align='middle' justify='center'>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Code du rôle' />
              <Form.Item style={{ margin: 0 }} name='code'>
                <StyledInput size='large' placeholder='Code du rôle' />
              </Form.Item>
            </StyledCol>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Libellé du rôle' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='label'
                rules={[
                  { required: true, message: 'Libellé obligatoire' },
                  { whitespace: false },
                ]}>
                <StyledInput size='large' placeholder='Libellé du rôle' />
              </Form.Item>
            </StyledCol>
            <StyledDivider />
            <StyledCol span={24}>
              <StyledSpace>
                <StyledButton
                  type='primary'
                  htmlType='submit'
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={_.isUndefined(currentData) ? createRole : editRole}>
                  {_.isUndefined(currentData)
                    ? 'Enregistrer le rôle'
                    : 'Modifier le rôle'}
                </StyledButton>
              </StyledSpace>
            </StyledCol>
          </StyledRow>
        </Form>
      </StyledBlocContainer>
    );
  };

  return (
    <>
      {renderHeader()}
      <StyledDivider />
      {renderContent()}
    </>
  );
};

export default RoleForm;
