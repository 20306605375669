/* eslint-disable react/no-unescaped-entities */
import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import _ from 'lodash';
import React from 'react';
import chartColors from '../../styles/color';
import {
  CreateMedicalRecordApiResponse,
  CreateProformaResponse,
  ISite,
  PatientDto,
} from '../../types';
import { thousandSeparatorFormat } from '../../utils/commun';
import { DataTableCell, Table, TableBody, TableCell, TableHeader } from '../pdfTable';
import {
  CURRENCY,
  RUBRICS_LABELS,
  RUBRICS_LABELS_TO_DISABLE,
} from '../../utils/constants';
import { TableRow } from '../pdfTable/TableRow';

interface ValidatedInvoiceDocProps {
  data: CreateProformaResponse;
  medicalRecordData?: CreateMedicalRecordApiResponse;
  title?: string;
  isDuplicata: boolean;
  patientData?: PatientDto;
  siteInfos: ISite;
}

const RowItem = ({
  title,
  value,
  isBold,
  isBgSet,
}: {
  title: string;
  value: string | number | undefined;
  isBold?: boolean;
  isBgSet?: boolean;
}) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 5,
        fontWeight: isBold ? 600 : 500,
        backgroundColor: isBgSet ? chartColors.blue50 : '#FFF',
      }}>
      <View>
        <Text>{title}</Text>
      </View>
      <View wrap={false}>
        <Text>{value}</Text>
      </View>
    </View>
  );
};

const ValidatedInvoiceDoc: React.FC<ValidatedInvoiceDocProps> = ({
  data,
  title,
  isDuplicata,
  patientData,
  siteInfos,
  medicalRecordData,
}) => {
  const renderTitle = () => (
    <View
      wrap={false}
      style={{ marginBottom: 10, border: `1px solid ${chartColors.black}` }}>
      <Text
        style={{
          textAlign: 'center',
          fontSize: 30,
          fontWeight: 600,
          color: chartColors.blue,
          backgroundColor: chartColors.lightGray,
          textTransform: 'capitalize',
        }}>
        {title}
      </Text>
    </View>
  );
  const renderHeader = () => (
    <View wrap={false} style={styles.header}>
      <View wrap={false}>
        <Image
          src={{ uri: siteInfos.url as string, method: 'GET', headers: {}, body: '' }}
          style={{ width: 100 }}
        />
        <View wrap={false} style={{ flex: 1 }}>
          <Text style={[styles.bold, { color: chartColors.blue }]}>{siteInfos.name}</Text>
          <Text>{siteInfos.address}</Text>
        </View>
      </View>
      <View wrap={false} style={[styles.headerDateContainer, styles.bold]}>
        <Text>Date: {data.createdDate}</Text>
        <Text>N°Facture: {data.numInvoice ?? data.num}</Text>
        <Text>N°Patient: {data.numPatient}</Text>
        <Text>N°Dossier: {data.numMedicalRecord}</Text>
        <Text>N°Bon: {data.bonNumber}</Text>
      </View>
    </View>
  );

  const renderUserHeader = () => (
    <View wrap={false} style={styles.userHeader}>
      <View wrap={false} style={{ flex: 1 }}>
        <Text style={[styles.bold, { color: chartColors.blue }]}>
          {_.isUndefined(patientData)
            ? `${data.patientName} ${data.patientFirstname}`
            : `${patientData?.name} ${patientData?.firstname}`}
        </Text>
        <Text>{patientData?.phoneNumber}</Text>
        <Text>{data.placeOfResidenceLabel}</Text>
      </View>
    </View>
  );

  const renderInsuranceInfo = () => (
    <View wrap={false} style={{ marginBottom: 30 }}>
      {!_.isUndefined(medicalRecordData) ? (
        medicalRecordData.idInsurance > 0 ? (
          <>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={[styles.bold]}>Assurance: </Text>
              <Text>{medicalRecordData.insuranceLabel}</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={[styles.bold]}>Souscripteur: </Text>
              <Text>{medicalRecordData.subscriberLabel}</Text>
            </View>
          </>
        ) : (
          <Text>CLIENT COMPTANT</Text>
        )
      ) : data.idInsurance && data.idInsurance > 0 ? (
        <>
          {' '}
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={[styles.bold]}>Assurance: </Text>
            <Text>{data.insuranceLabel}</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={[styles.bold]}>Souscripteur: </Text>
            <Text>{data.subscriberLabel}</Text>
          </View>
        </>
      ) : (
        <Text>CLIENT COMPTANT</Text>
      )}
    </View>
  );

  const renderTableHeader = () => (
    <TableHeader textAlign='center'>
      <TableCell
        fontSize={10}
        style={[
          styles.columnTitle,
          { color: chartColors.deepBlue, border: 'none', flex: 3, textAlign: 'left' },
        ]}>
        Description
      </TableCell>
      <TableCell
        style={[
          styles.columnTitle,
          { color: chartColors.deepBlue, border: 'none', flex: 1, textAlign: 'right' },
        ]}>
        Qté
      </TableCell>
      <TableCell
        style={[
          styles.columnTitle,
          { color: chartColors.deepBlue, border: 'none', flex: 1, textAlign: 'right' },
        ]}>
        Ratio
      </TableCell>
      <TableCell
        style={[
          styles.columnTitle,
          { color: chartColors.deepBlue, border: 'none', flex: 1, textAlign: 'right' },
        ]}>
        PU.
      </TableCell>
      <TableCell
        style={[
          styles.columnTitle,
          { color: chartColors.deepBlue, border: 'none', flex: 1, textAlign: 'right' },
        ]}>
        Total
      </TableCell>
      <TableCell
        style={[
          styles.columnTitle,
          { color: chartColors.deepBlue, border: 'none', flex: 1, textAlign: 'right' },
        ]}>
        Part Assu.
      </TableCell>
      <TableCell
        style={[
          styles.columnTitle,
          { color: chartColors.deepBlue, border: 'none', flex: 1, textAlign: 'right' },
        ]}>
        Part Pat.
      </TableCell>
    </TableHeader>
  );

  const renderImageryLines = () => {
    return data.invoiceLineImageryDto.map((r, key) => (
      <TableRow
        includeBottomBorder={false}
        includeLeftBorder={false}
        includeRightBorder={false}
        includeTopBorder={false}
        key={`img_${key}`}>
        <DataTableCell
          style={[styles.columnContent, { flex: 3 }]}
          getContent={() => r.imageryExamDesignation}
        />
        <DataTableCell
          style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
          getContent={() => r.quantity}
        />
        <DataTableCell
          style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
          getContent={() => r.ratioLabel}
        />
        <DataTableCell
          style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
          getContent={() => thousandSeparatorFormat(r.unitPrice)}
        />
        <DataTableCell
          style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
          getContent={() => thousandSeparatorFormat(r.amountTotal)}
        />
        <DataTableCell
          style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
          getContent={() => thousandSeparatorFormat(r.amountAssu)}
        />
        <DataTableCell
          style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
          getContent={() => thousandSeparatorFormat(r.amountPatient)}
        />
      </TableRow>
    ));
  };

  const renderBiologicalLines = () => {
    return data.invoiceLineBiologicalDto.map((r, key) => (
      <TableRow
        includeBottomBorder={false}
        includeLeftBorder={false}
        includeRightBorder={false}
        includeTopBorder={false}
        key={`bio_${key}`}>
        <DataTableCell
          style={[styles.columnContent, { flex: 3 }]}
          getContent={() => r.biologicalExamDesignation}
        />
        <DataTableCell
          style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
          getContent={() => r.quantity}
        />
        <DataTableCell
          style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
          getContent={() => r.ratioLabel}
        />
        <DataTableCell
          style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
          getContent={() => thousandSeparatorFormat(r.unitPrice)}
        />
        <DataTableCell
          style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
          getContent={() => thousandSeparatorFormat(r.amountTotal)}
        />
        <DataTableCell
          style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
          getContent={() => thousandSeparatorFormat(r.amountAssu)}
        />
        <DataTableCell
          style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
          getContent={() => thousandSeparatorFormat(r.amountPatient)}
        />
      </TableRow>
    ));
  };

  const renderTableBody = () => (
    <TableBody
      renderChildren={true}
      zebra
      renderTopBorder
      includeBottomBorder
      evenRowColor={chartColors.blue50}
      oddRowColor='#FFF'
      includeLeftBorder={false}
      includeRightBorder={false}
      fontSize='11px'>
      {data.invoicesLines.map((r, key) => {
        return (
          <>
            {r.rubricLabel === RUBRICS_LABELS_TO_DISABLE.ANABIO &&
            !_.isEmpty(data.invoiceLineBiologicalDto)
              ? renderBiologicalLines()
              : null}

            {r.rubricLabel === RUBRICS_LABELS_TO_DISABLE.ANAIMG &&
            !_.isEmpty(data.invoiceLineImageryDto)
              ? renderImageryLines()
              : null}
            <TableRow
              includeLeftBorder={false}
              includeRightBorder={false}
              even={RUBRICS_LABELS.includes(r.rubricLabel)}
              evenRowColor={chartColors.lightGray}
              key={key}>
              <DataTableCell
                style={[styles.columnContent, styles.bold, { flex: 3 }]}
                getContent={() => r.rubricLabel}
              />
              <DataTableCell
                style={[
                  styles.columnContent,
                  styles.bold,
                  { flex: 1, textAlign: 'right' },
                ]}
                getContent={() => r.quantity}
              />
              <DataTableCell
                style={[
                  styles.columnContent,
                  styles.bold,
                  { flex: 1, textAlign: 'right' },
                ]}
                getContent={() => `${r.ratioLabel}`}
              />
              <DataTableCell
                style={[
                  styles.columnContent,
                  styles.bold,
                  { flex: 1, textAlign: 'right' },
                ]}
                getContent={() => thousandSeparatorFormat(r.unitPrice)}
              />
              <DataTableCell
                style={[
                  styles.columnContent,
                  styles.bold,
                  { flex: 1, textAlign: 'right' },
                ]}
                getContent={() => thousandSeparatorFormat(r.amountTotal)}
              />
              <DataTableCell
                style={[
                  styles.columnContent,
                  styles.bold,
                  { flex: 1, textAlign: 'right' },
                ]}
                getContent={() => thousandSeparatorFormat(r.amountAssu)}
              />
              <DataTableCell
                style={[
                  styles.columnContent,
                  styles.bold,
                  { flex: 1, textAlign: 'right' },
                ]}
                getContent={() => thousandSeparatorFormat(r.amountPatient)}
              />
            </TableRow>
          </>
        );
      })}
    </TableBody>
  );

  const renderTotalContainer = () => (
    <View wrap={false} style={styles.totalContainer}>
      <View
        wrap={false}
        style={{
          width: 200,
          display: 'flex',
          flexDirection: 'column',
          borderTop: '2px solid #000',
        }}>
        <RowItem
          title={`Total (${CURRENCY})`}
          isBold
          value={thousandSeparatorFormat(data.amountTotal as number)}
        />
        <RowItem
          title={`Part Assu. (${CURRENCY})`}
          isBgSet
          value={thousandSeparatorFormat(data.amountAssu as number)}
        />
        <RowItem
          title={`Part Patient (${CURRENCY})`}
          isBgSet
          value={thousandSeparatorFormat(data.amountPatient as number)}
        />
        <RowItem
          title={`Taxes (${CURRENCY})`}
          isBgSet
          value={thousandSeparatorFormat(data.amountPatientTaxe as number)}
        />
        <RowItem
          title={`Montant restant dû (${CURRENCY})`}
          isBgSet
          value={thousandSeparatorFormat(data.amountDue as number)}
        />
      </View>
    </View>
  );

  return (
    <Document>
      <Page style={styles.body}>
        {renderTitle()}
        {renderHeader()}
        {renderUserHeader()}
        {renderInsuranceInfo()}

        <Table data={data.invoicesLines ?? []}>
          {renderTableHeader()}
          {renderTableBody()}
        </Table>
        {!_.isEmpty(data.invoicePharmayLine) && (
          <View wrap={false}>
            <Text style={[styles.bold, { color: chartColors.blue }]}>
              Articles de pharmacie
            </Text>
            <Table data={data.invoicePharmayLine}>
              <TableHeader
                includeLeftBorder={false}
                includeRightBorder={false}
                textAlign='left'>
                <TableCell style={[styles.columnTitle, { border: 'none', flex: 1 }]}>
                  Référence
                </TableCell>
                <TableCell
                  fontSize={11}
                  style={[
                    styles.columnTitle,
                    { border: 'none', flex: 3, textAlign: 'left' },
                  ]}>
                  Désignation
                </TableCell>
                <TableCell style={[styles.columnTitle, { border: 'none', flex: 1 }]}>
                  Qté
                </TableCell>
                <TableCell
                  style={[
                    styles.columnTitle,
                    { border: 'none', flex: 1, textAlign: 'right' },
                  ]}>
                  <Text>Montant HT</Text>
                </TableCell>
              </TableHeader>
              <TableBody
                zebra
                renderTopBorder
                evenRowColor={chartColors.blue50}
                oddRowColor='#FFF'
                includeLeftBorder={false}
                includeRightBorder={false}
                fontSize='13px'>
                <DataTableCell
                  style={[styles.columnContent, { flex: 1 }]}
                  getContent={r => r.referenceArticle}
                />
                <DataTableCell
                  style={[styles.columnContent, { flex: 3 }]}
                  getContent={r => r.designation}
                />
                <DataTableCell
                  style={[styles.columnContent, { flex: 1 }]}
                  getContent={r => r.quantity}
                />
                <DataTableCell
                  style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
                  // getContent={r => thousandSeparatorFormat(r.quantity * r.unitPrice)}
                  getContent={r => thousandSeparatorFormat(r.amount)}
                />
              </TableBody>
            </Table>
          </View>
        )}

        {renderTotalContainer()}

        {isDuplicata && <Text style={styles.duplicata}>DUPLICATA</Text>}
      </Page>

      {/* {!_.isEmpty(data.invoiceLineBiologicalDto) ||
      !_.isEmpty(data.invoiceLineImageryDto) ||
      !_.isEmpty(data.invoicePharmayLine) ? (
        <Page style={styles.body}>
          {!_.isEmpty(data.invoiceLineBiologicalDto) && (
            <View wrap={false} style={{ marginBottom: 20 }}>
              <Text style={[styles.bold, { color: chartColors.blue }]}>
                Examens biologiques
              </Text>
              <Table data={data.invoiceLineBiologicalDto}>
                <TableHeader
                  includeLeftBorder={false}
                  includeRightBorder={false}
                  textAlign='left'>
                  <TableCell
                    style={[
                      styles.columnTitle,
                      { border: 'none', flex: 3, textAlign: 'left' },
                    ]}>
                    Désignation
                  </TableCell>
                  <TableCell
                    style={[
                      styles.columnTitle,
                      { border: 'none', flex: 1, textAlign: 'right' },
                    ]}>
                    PU.
                  </TableCell>
                  <TableCell
                    style={[
                      styles.columnTitle,
                      { border: 'none', flex: 1, textAlign: 'right' },
                    ]}>
                    Total
                  </TableCell>
                  <TableCell
                    style={[
                      styles.columnTitle,
                      { border: 'none', flex: 1, textAlign: 'right' },
                    ]}>
                    Ratio
                  </TableCell>
                  <TableCell
                    style={[
                      styles.columnTitle,
                      { border: 'none', flex: 1, textAlign: 'right' },
                    ]}>
                    Part Assu.
                  </TableCell>
                  <TableCell
                    style={[
                      styles.columnTitle,
                      { border: 'none', flex: 1, textAlign: 'right' },
                    ]}>
                    Part Pat.
                  </TableCell>
                </TableHeader>
                <TableBody
                  zebra
                  renderTopBorder
                  evenRowColor={chartColors.blue50}
                  oddRowColor='#FFF'
                  includeLeftBorder={false}
                  includeRightBorder={false}
                  fontSize='13px'>
                  <DataTableCell
                    style={[styles.columnContent, { flex: 3 }]}
                    getContent={r => r.biologicalExamDesignation}
                  />
                  <DataTableCell
                    style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
                    getContent={r => thousandSeparatorFormat(r.amountTotal)}
                  />
                  <DataTableCell
                    style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
                    getContent={r => thousandSeparatorFormat(r.amountTotal)}
                  />
                  <DataTableCell
                    style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
                    getContent={r => `${r.ratioLabel}`}
                  />
                  <DataTableCell
                    style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
                    getContent={r => thousandSeparatorFormat(r.amountAssu)}
                  />
                  <DataTableCell
                    style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
                    getContent={r => thousandSeparatorFormat(r.amountPatient)}
                  />
                </TableBody>
              </Table>
            </View>
          )}

          {!_.isEmpty(data.invoiceLineImageryDto) && (
            <View wrap={false} style={{ marginBottom: 20 }}>
              <Text style={[styles.bold, { color: chartColors.blue }]}>
                Examens d'imagerie
              </Text>
              <Table data={data.invoiceLineImageryDto}>
                <TableHeader
                  includeLeftBorder={false}
                  includeRightBorder={false}
                  textAlign='center'>
                  <TableCell
                    fontSize={11}
                    style={[
                      styles.columnTitle,
                      { border: 'none', flex: 3, textAlign: 'left' },
                    ]}>
                    Désignation
                  </TableCell>
                  <TableCell
                    style={[
                      styles.columnTitle,
                      { border: 'none', flex: 1, textAlign: 'right' },
                    ]}>
                    Ratio
                  </TableCell>
                  <TableCell
                    style={[
                      styles.columnTitle,
                      { border: 'none', flex: 1, textAlign: 'right' },
                    ]}>
                    PU.
                  </TableCell>
                  <TableCell
                    style={[
                      styles.columnTitle,
                      { border: 'none', flex: 1, textAlign: 'right' },
                    ]}>
                    Total
                  </TableCell>
                  <TableCell
                    style={[
                      styles.columnTitle,
                      { border: 'none', flex: 1, textAlign: 'right' },
                    ]}>
                    Part Assu.
                  </TableCell>
                  <TableCell
                    style={[
                      styles.columnTitle,
                      { border: 'none', flex: 1, textAlign: 'right' },
                    ]}>
                    Part Pat.
                  </TableCell>
                </TableHeader>
                <TableBody
                  zebra
                  renderTopBorder
                  evenRowColor={chartColors.blue50}
                  oddRowColor='#FFF'
                  includeLeftBorder={false}
                  includeRightBorder={false}
                  fontSize='13px'>
                  <DataTableCell
                    style={[styles.columnContent, { flex: 3 }]}
                    getContent={r => r.imageryExamDesignation}
                  />
                  <DataTableCell
                    style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
                    getContent={r => `${r.ratioLabel}`}
                  />
                  <DataTableCell
                    style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
                    getContent={r => thousandSeparatorFormat(r.unitPrice)}
                  />
                  <DataTableCell
                    style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
                    getContent={r => thousandSeparatorFormat(r.amountTotal)}
                  />
                  <DataTableCell
                    style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
                    getContent={r => thousandSeparatorFormat(r.amountAssu)}
                  />
                  <DataTableCell
                    style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
                    getContent={r => thousandSeparatorFormat(r.amountPatient)}
                  />
                </TableBody>
              </Table>
            </View>
          )}

          {!_.isEmpty(data.invoicePharmayLine) && (
            <View wrap={false}>
              <Text style={[styles.bold, { color: chartColors.blue }]}>
                Articles de pharmacie
              </Text>
              <Table data={data.invoicePharmayLine}>
                <TableHeader
                  includeLeftBorder={false}
                  includeRightBorder={false}
                  textAlign='left'>
                  <TableCell style={[styles.columnTitle, { border: 'none', flex: 1 }]}>
                    Référence
                  </TableCell>
                  <TableCell
                    fontSize={11}
                    style={[
                      styles.columnTitle,
                      { border: 'none', flex: 3, textAlign: 'left' },
                    ]}>
                    Désignation
                  </TableCell>
                  <TableCell style={[styles.columnTitle, { border: 'none', flex: 1 }]}>
                    Qté
                  </TableCell>
                  <TableCell
                    style={[
                      styles.columnTitle,
                      { border: 'none', flex: 1, textAlign: 'right' },
                    ]}>
                    <Text>Montant HT</Text>
                  </TableCell>
                </TableHeader>
                <TableBody
                  zebra
                  renderTopBorder
                  evenRowColor={chartColors.blue50}
                  oddRowColor='#FFF'
                  includeLeftBorder={false}
                  includeRightBorder={false}
                  fontSize='13px'>
                  <DataTableCell
                    style={[styles.columnContent, { flex: 1 }]}
                    getContent={r => r.referenceArticle}
                  />
                  <DataTableCell
                    style={[styles.columnContent, { flex: 3 }]}
                    getContent={r => r.designation}
                  />
                  <DataTableCell
                    style={[styles.columnContent, { flex: 1 }]}
                    getContent={r => r.quantity}
                  />
                  <DataTableCell
                    style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
                    // getContent={r => thousandSeparatorFormat(r.quantity * r.unitPrice)}
                    getContent={r => thousandSeparatorFormat(r.amount)}
                  />
                </TableBody>
              </Table>
            </View>
          )}

          {isDuplicata && <Text style={styles.duplicata}>DUPLICATA</Text>}
        </Page>
      ) : null} */}
    </Document>
  );
};

Font.register({
  family: 'Metropolis',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
  fonts: [
    { src: require('../../assets/fonts/Metropolis-Regular.woff') },
    { src: require('../../assets/fonts/Metropolis-Bold.woff'), fontWeight: 600 },
    { src: require('../../assets/fonts/Metropolis-Light.woff'), fontWeight: 400 },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 11,
    fontFamily: 'Metropolis',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  headerDateContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: chartColors.blue50,
    rowGap: 12,
    padding: 10,
    height: 110,
  },
  userHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  objetContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  bold: {
    fontWeight: 600,
  },
  columnTitle: {
    fontSize: 10,
    fontWeight: 600,
  },
  columnContent: {
    border: 'none',
    fontSize: 10,
    borderColor: chartColors.blue50,
  },
  totalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 15,
  },
  noteContainer: {
    marginTop: 15,
    fontSize: 10,
    textAlign: 'justify',
  },
  pageFooter: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  duplicata: {
    position: 'absolute',
    zIndex: -100,
    textAlign: 'center',
    left: 5,
    right: 5,
    top: 300,
    fontSize: 98,
    fontWeight: 700,
    textTransform: 'capitalize',
    transform: 'rotate(-45deg)',
    opacity: 0.2,
  },
});

export default ValidatedInvoiceDoc;
