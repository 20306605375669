import {
  TbLayoutSidebarLeftCollapse,
  TbLayoutSidebarRightCollapse,
} from 'react-icons/tb';
import { useLocation } from 'react-router-dom';
import LogoApiPatientCrBl from '../../assets/images/logo_api_patient_cr_bl.svg';
import LogoApiPatientLgBl from '../../assets/images/logo_api_patient_lg_bl.svg';
import chartColors from '../../styles/color';
import { UserDto } from '../../types';
import { getDataFromStorage } from '../../utils/storage';
import { StyledDivider, StyledTooltip } from '../style';
import {
  RouteItemsProps,
  filteredRoutesByUserRights,
  routes,
  standardRoutes,
} from './routes';
import {
  StyledDesktopMenuItem,
  StyledDesktopMenuItemIcon,
  StyledDesktopMenuItemTitle,
  StyledDesktopStandardMenuItem,
  StyledDesktopStandardMenuItemIcon,
  StyledDesktopStandardMenuItemTitle,
  StyledMenuDesktopContainer,
  StyledMenuDesktopWrapper,
  StyledMenuHeader,
  StyledMenuToggleButton,
} from './style';

interface MenuProps {
  isclosed: boolean;
  handleMenuOpenClose: () => void;
}
const Menu: React.FC<MenuProps> = ({ isclosed, handleMenuOpenClose }) => {
  const { pathname } = useLocation();
  const user = getDataFromStorage<UserDto>('connectedUserData');

  const toggleDesktopMenu = () => {
    handleMenuOpenClose();
  };

  // const renderRouteItemProps = (routeItem: RouteItemsProps) => {
  //   const isItemActive = routeItem.focusedLinks.includes(pathname);

  //   const itemProps = {
  //     to: routeItem.path,
  //     isclosed: isclosed.toString(),
  //     isactive: isItemActive.toString(),
  //   };
  //   return itemProps;
  // };

  /**
   * The function `renderRouteItemProps` takes a `routeItem` object and returns an object with
   * properties `to`, `isclosed`, and `isactive` based on the `routeItem` and the current `pathname`.
   * @param {RouteItemsProps} routeItem - The `routeItem` parameter is an object that represents a
   * route item. It contains properties such as `focusedLinks`, `pathname`, and `path`.
   * @returns The function `renderRouteItemProps` returns an object `itemProps` with the following
   * properties:
   */
  const renderRouteItemProps = (routeItem: RouteItemsProps) => {
    const isItemActive = routeItem.focusedLinks.some(link => {
      if (typeof link === 'string') {
        return link === pathname;
      } else {
        return link.test(pathname);
      }
    });

    const itemProps = {
      to: routeItem.path,
      $isclosed: isclosed.toString(),
      $isactive: isItemActive.toString(),
    };
    return itemProps;
  };

  const renderDesktopRoutes = () => {
    return filteredRoutesByUserRights(routes, user.rights).map((routeItem, idx) => {
      const itemProps = renderRouteItemProps(routeItem);

      return (
        <StyledTooltip
          key={idx}
          title={routeItem.title}
          placement='right'
          color={chartColors.mainGray}>
          <StyledDesktopMenuItem {...itemProps}>
            <StyledDesktopMenuItemIcon {...itemProps}>
              {routeItem.icon}
            </StyledDesktopMenuItemIcon>
            <StyledDesktopMenuItemTitle {...itemProps}>
              {routeItem.title}
            </StyledDesktopMenuItemTitle>
          </StyledDesktopMenuItem>
        </StyledTooltip>
      );
    });
  };

  const renderDesktopStandardRoutes = () => {
    return filteredRoutesByUserRights(standardRoutes, user.rights).map(
      (routeItem, idx) => {
        const itemProps = renderRouteItemProps(routeItem);
        return (
          <StyledTooltip
            key={idx}
            title={routeItem.title}
            placement='right'
            color={chartColors.mainGray}>
            <StyledDesktopStandardMenuItem {...itemProps}>
              <StyledDesktopStandardMenuItemIcon {...itemProps}>
                {routeItem.icon}
              </StyledDesktopStandardMenuItemIcon>
              <StyledDesktopStandardMenuItemTitle {...itemProps}>
                {routeItem.title}
              </StyledDesktopStandardMenuItemTitle>
            </StyledDesktopStandardMenuItem>
          </StyledTooltip>
        );
      },
    );
  };

  return (
    <>
      <StyledMenuDesktopWrapper $isclosed={isclosed.toString()}>
        <StyledMenuHeader>
          {isclosed ? (
            <img src={LogoApiPatientCrBl} style={{ height: '1.3em' }} />
          ) : (
            <img src={LogoApiPatientLgBl} style={{ height: '1.3em' }} />
          )}
          <StyledDivider style={{ marginBottom: 0 }} />
        </StyledMenuHeader>
        <StyledMenuDesktopContainer>
          {renderDesktopRoutes()}
          <StyledDivider />
          {renderDesktopStandardRoutes()}
        </StyledMenuDesktopContainer>
        <StyledMenuToggleButton
          $isclosed={isclosed.toString()}
          onClick={() => toggleDesktopMenu()}>
          {isclosed ? <TbLayoutSidebarRightCollapse /> : <TbLayoutSidebarLeftCollapse />}
        </StyledMenuToggleButton>
      </StyledMenuDesktopWrapper>
    </>
  );
};

export default Menu;
