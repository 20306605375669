import { App as AppContainer, Avatar } from 'antd';
import React, { useEffect, useState } from 'react';
import { getMedecinAppointmentsByIdApi } from '../../api/appointmentService';
import { getUserAbsenceByIdApi } from '../../api/userService';
import AppointmentCalendar from '../../components/appointmentCalendar';
import { StyledButton } from '../../components/button/style';
import {
  GetMedecinAppointmentsResponse,
  GetUsersAbsencesResponse,
  IUserItem,
  StorageType,
  TimeSlotProps,
} from '../../types';
import { convertToHHMM, getApiErrorMsg } from '../../utils/commun';
import { ErrorMsgTitle } from '../../utils/constants';
import {
  MedecinAppointmentContainer,
  MedecinAppointmentInfo,
  MedecinAppointmentInfoLevel,
  MedecinAppointmentInfoName,
  MedecinAppointmentWrapper,
} from '../style';
import Loader from '../../components/loader';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { saveInStorage } from '../../utils/storage';

type MedecinAppointmentProps = {
  medecinData: IUserItem;
};

const MedecinAppointment: React.FC<MedecinAppointmentProps> = ({ medecinData }) => {
  const { notification } = AppContainer.useApp();
  const navigate = useNavigate();
  const [userAbsences, setUserAbsences] = useState<GetUsersAbsencesResponse>([]);
  const [currentAppointments, setCurrentAppointments] =
    useState<GetMedecinAppointmentsResponse>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedAppointment, setSelectedAppointment] = useState<TimeSlotProps>();

  const loadMedecinAbsences = async () => {
    try {
      setIsLoading(true);
      Promise.resolve(getUserAbsenceByIdApi(medecinData.id as number)).then(
        res => {
          const timesSlots = res.map(t => ({
            ...t,
            hour: convertToHHMM(t.hour),
          }));
          setUserAbsences(timesSlots);
        },
        err => {
          notification.error({
            message: ErrorMsgTitle,
            description: getApiErrorMsg(err),
          });
          setIsLoading(false);
        },
      );
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
    } finally {
      setIsLoading(false);
    }
  };

  const loadMedecinAppointments = async () => {
    try {
      setIsLoading(true);

      const res = await getMedecinAppointmentsByIdApi(medecinData.id as number);
      const timesSlots = res.map(t => ({
        ...t,
        timeSlot: {
          id: t.timeSlot.id,
          hour: convertToHHMM(t.timeSlot.hour),
          date: t.timeSlot.date,
        },
      }));
      setCurrentAppointments(timesSlots);
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadMedecinAbsences();
    loadMedecinAppointments();
  }, []);

  const handleAppointment = () => {
    if (_.isUndefined(selectedAppointment)) {
      notification.warning({
        message: 'Date de rendez-vous obligatoire',
        description: 'Veuillez sélectionner une date de rendez-vous',
      });
    } else {
      saveInStorage(StorageType.LS, 'appointmentData', {
        medecin: medecinData,
        appointmentDay: selectedAppointment,
      });
      navigate('prise-de-rendez-vous');
    }
  };

  return (
    <MedecinAppointmentWrapper>
      <MedecinAppointmentContainer>
        <Avatar size={80} shape='square' style={{ marginRight: 10 }}>
          {medecinData.firstname.charAt(0)}
          {medecinData.name.charAt(0)}
        </Avatar>
        <MedecinAppointmentInfo>
          <div>
            <MedecinAppointmentInfoName>
              {`${medecinData.level ?? ''}
              ${medecinData.firstname ?? ''}
              ${medecinData.name ?? ''}`}
            </MedecinAppointmentInfoName>
            <MedecinAppointmentInfoLevel>
              {medecinData.serviceDto.name}
            </MedecinAppointmentInfoLevel>
          </div>
          <div style={{ flex: 1 }}>
            <StyledButton type='primary' onClick={handleAppointment}>
              Prendre un rendez-vous
            </StyledButton>
          </div>
        </MedecinAppointmentInfo>
        <Loader showSpinner={isLoading}>
          <AppointmentCalendar
            absencesTimeslots={userAbsences}
            currentAppointments={currentAppointments}
            callback={selectedAppointment => {
              setSelectedAppointment(selectedAppointment);
            }}
            allowTimeslotSelection={true}
            resetData={loadMedecinAppointments}
          />
        </Loader>
      </MedecinAppointmentContainer>
    </MedecinAppointmentWrapper>
  );
};

export default MedecinAppointment;
