/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer, Form } from 'antd';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { createMedicationApi, updateMedicationApi } from '../../../api/medicationService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import {
  StyledCol,
  StyledDivider,
  StyledInput,
  StyledRow,
  StyledSelectOption,
  StyledSelectPicker,
  StyledSpace,
} from '../../../components/style';
import {
  CreateMedicationRequestBody,
  GetUnitResponse,
  IMedicationItem,
  ReferentialViewProps,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { StyledBlocContainer } from '../../style';
import { getUnits } from '../../../api/referenceService';

type MedicationFormProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  currentData?: IMedicationItem;
};

type FormProperties = { cisCode: string; designation: string; idUnit: number };

const MedicationForm: React.FC<MedicationFormProps> = ({
  navigateToview,
  currentData,
}) => {
  const { notification } = AppContainer.useApp();
  const [form] = Form.useForm<FormProperties>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [units, setUnits] = useState<GetUnitResponse[]>([]);

  const loadUnits = async () => {
    setIsLoading(true);
    Promise.resolve(getUnits())
      .then(result => {
        setUnits(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadUnits();
  }, []);

  const renderHeader = () => {
    return (
      <SectionHeader
        title={
          _.isUndefined(currentData) ? 'Nouveau médicament' : 'Modification médicament'
        }
        onBack={() => navigateToview('LIST_ITEMS')}
      />
    );
  };

  const createMedication = async () => {
    try {
      await form.validateFields().then(async row => {
        const medicationData: CreateMedicationRequestBody = {
          cisCode: row.cisCode,
          designation: row.designation,
          idUnit: row.idUnit,
        };

        setIsLoading(true);
        await createMedicationApi(medicationData).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `${row.designation} a été enregistrée!`,
          });
          navigateToview('LIST_ITEMS');
        });
      });
    } catch (error: any) {
      if (_.isUndefined(error.values)) {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const editMedication = async () => {
    try {
      await form.validateFields().then(async row => {
        const medicationData: CreateMedicationRequestBody = {
          cisCode: row.cisCode,
          designation: row.designation,
          idUnit: row.idUnit,
        };

        setIsLoading(true);
        await updateMedicationApi(currentData?.id as number, medicationData).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `Le médicament a été modifié!`,
          });
          navigateToview('LIST_ITEMS');
        });
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    return (
      <StyledBlocContainer style={{ padding: 20 }}>
        <Form
          disabled={isLoading}
          form={form}
          component={false}
          initialValues={{
            cisCode: '',
            designation: _.isUndefined(currentData) ? '' : currentData.designation,
            idUnit: null,
          }}>
          <StyledRow gutter={[16, 16]} align='middle' justify='center'>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Code CIS du médicament' />
              <Form.Item style={{ margin: 0 }} name='cisCode'>
                <StyledInput size='large' placeholder='Code CIS du médicament' />
              </Form.Item>
            </StyledCol>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Désignation du médicament' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='designation'
                rules={[
                  { required: true, message: 'Désignation obligatoire' },
                  { whitespace: false },
                ]}>
                <StyledInput size='large' placeholder='Désignation du médicament' />
              </Form.Item>
            </StyledCol>

            <StyledCol span={24}>
              <FormFieldLabel libelle='Unité' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='idUnit'
                rules={[{ required: true, message: 'Unité obligatoire' }]}>
                <StyledSelectPicker
                  placeholder="Sélectionnez l'unité"
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }>
                  {_.map(units, item => (
                    <StyledSelectOption
                      key={item.id}
                      value={item.id}
                      label={item.libelle}>
                      {item.libelle}
                    </StyledSelectOption>
                  ))}
                </StyledSelectPicker>
              </Form.Item>
            </StyledCol>
            <StyledDivider />
            <StyledCol span={24}>
              <StyledSpace>
                <StyledButton
                  type='primary'
                  htmlType='submit'
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={
                    _.isUndefined(currentData) ? createMedication : editMedication
                  }>
                  {_.isUndefined(currentData)
                    ? 'Enregistrer le médicament'
                    : 'Modifier le médicament'}
                </StyledButton>
              </StyledSpace>
            </StyledCol>
          </StyledRow>
        </Form>
      </StyledBlocContainer>
    );
  };

  return (
    <>
      {renderHeader()}
      <StyledDivider />
      {renderContent()}
    </>
  );
};

export default MedicationForm;
