import React, { useState } from 'react';
import { IUserItem, ReferentialViewProps } from '../../../types';
import UsersList from './UsersList';
import UserForm from './UserForm';

const UserSetting = () => {
  const [activeView, setActiveView] = useState<ReferentialViewProps>('LIST_ITEMS');
  const [selectedUser, setSelectedUser] = useState<IUserItem>();

  const handleNavigateToView = (newView: ReferentialViewProps) => {
    setActiveView(newView);
  };

  const handleUpdateSelectedMedication = (user?: IUserItem) => {
    setSelectedUser(user);
  };

  const renderContent = () => {
    switch (activeView) {
      case 'LIST_ITEMS':
        return (
          <UsersList
            navigateToview={handleNavigateToView}
            updateCurrentItem={handleUpdateSelectedMedication}
          />
        );
      case 'EDIT_ITEM':
        return (
          <UserForm navigateToview={handleNavigateToView} currentData={selectedUser} />
        );
      case 'NEW_ITEM':
        return <UserForm navigateToview={handleNavigateToView} />;
    }
  };

  return <>{renderContent()}</>;
};

export default UserSetting;
