import { PDFViewer } from '@react-pdf/renderer';
import CashboxInvoiceListDoc from '../../components/document/CashboxInvoiceListDoc';
import InvoiceProformaDoc from '../../components/document/InvoiceProformaDoc';
import MedicalRecordDoc from '../../components/document/MedicalRecordDoc';
import PharmacyOrderDoc from '../../components/document/PharmacyOrderDoc';
import PrescriptionDoc from '../../components/document/PrescriptionDoc';
import ValidatedInvoiceDoc from '../../components/document/ValidatedInvoiceDoc';
import {
  CreateMedicalRecordApiResponse,
  CreatePharmacyOrderResponse,
  CreateProformaResponse,
  IBiologicalPrescriptionItem,
  IImageryPrescriptionItem,
  IMedicalRecordDetails,
  IPrescriptionItem,
  ISite,
  PatientDto,
  PdfDocumentType,
  SearchInvoiceApiRequestBody,
  SearchInvoiceApiResponse,
} from '../../types';
import { getDataFromStorage } from '../../utils/storage';

interface RenderDocumentprops<T, R> {
  documentType: PdfDocumentType;
  documentData: T;
  extraData?: R;
  isDuplicata: boolean;
}

type PrescriptionDataType =
  | IBiologicalPrescriptionItem
  | IPrescriptionItem
  | IImageryPrescriptionItem;

type ValidatedInvoiceProps = RenderDocumentprops<
  CreateProformaResponse,
  {
    title: string;
    patientData?: PatientDto;
    medicalRecord: CreateMedicalRecordApiResponse;
  }
>['extraData'];

const RenderDocument = <T, R = unknown>({
  documentData,
  documentType,
  extraData,
  isDuplicata,
}: RenderDocumentprops<T, R>): JSX.Element => {
  const siteData = getDataFromStorage<ISite>('siteData');
  const extra = extraData as ValidatedInvoiceProps;
  const renderContent = () => {
    switch (documentType) {
      case 'PROFORMA_INVOICE':
        return (
          <InvoiceProformaDoc
            siteInfos={siteData}
            data={documentData as CreateProformaResponse}
            isDuplicata={isDuplicata}
            patientData={extra?.patientData}
            medicalRecordData={extra?.medicalRecord as CreateMedicalRecordApiResponse}
          />
        );
      case 'VALIDATED_INVOICE': {
        return (
          <ValidatedInvoiceDoc
            siteInfos={siteData}
            title={extra?.title}
            medicalRecordData={extra?.medicalRecord as CreateMedicalRecordApiResponse}
            data={documentData as CreateProformaResponse}
            isDuplicata={isDuplicata}
          />
        );
      }
      case 'CASHBOX_INVOICE_LIST':
        return (
          <CashboxInvoiceListDoc
            siteInfos={siteData}
            data={documentData as SearchInvoiceApiResponse[]}
            searchData={extraData as SearchInvoiceApiRequestBody}
            isDuplicata={isDuplicata}
          />
        );
      case 'PRESCRIPTION_PDF':
        return (
          <PrescriptionDoc
            siteInfos={siteData}
            data={documentData as PrescriptionDataType}
          />
        );

      case 'MEDICAL_RECORD_PDF':
        return (
          <MedicalRecordDoc
            siteInfos={siteData}
            data={documentData as IMedicalRecordDetails}
            isManyRecord={false}
          />
        );

      case 'ALL_MEDICAL_RECORDS_PDF':
        return (
          <MedicalRecordDoc
            siteInfos={siteData}
            data={documentData as IMedicalRecordDetails[]}
            isManyRecord={true}
          />
        );
      case 'PHARMACY_ARTICLE_ORDER':
        return (
          <PharmacyOrderDoc
            siteInfos={siteData}
            data={documentData as CreatePharmacyOrderResponse}
            isDuplicata={false}
          />
        );

      default:
        return (
          <InvoiceProformaDoc
            siteInfos={siteData}
            data={documentData as CreateProformaResponse}
            isDuplicata={isDuplicata}
            patientData={extra?.patientData}
            medicalRecordData={extra?.medicalRecord as CreateMedicalRecordApiResponse}
          />
        );
    }
  };

  return (
    <PDFViewer
      width='100%'
      height='100%'
      style={{ backgroundColor: '#FFF', border: 'none' }}>
      {renderContent()}
    </PDFViewer>
  );
};

export default RenderDocument;
