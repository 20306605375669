import { App as AppContainer, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { getActsPricingsApi } from '../../../api/actsService';
import Loader from '../../../components/loader';
import { StyledDivider } from '../../../components/style';
import { ReferentialViewProps, GetActsResponse, IActItem } from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle } from '../../../utils/constants';
import SectionHeader from '../../../components/header/SectionHeader';

type ActsListProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  updateCurrentItem: (p: IActItem) => void;
};

const ActsList: React.FC<ActsListProps> = () => {
  const { notification } = AppContainer.useApp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [acts, setActs] = useState<GetActsResponse>([]);

  //   const handleUpdate = (item: IActItem) => {
  //     updateCurrentItem(item);
  //     navigateToview('EDIT_ITEM');
  //   };

  const columnData: ColumnsType<IActItem> = [
    {
      title: "Type d'acte",
      dataIndex: 'type_act',
    },
    {
      title: "Type d'assurance",
      dataIndex: 'type_Assurance',
    },
    {
      title: 'Montant jour ouvrable',
      dataIndex: 'amount_JO',
    },
    {
      title: 'Montant Dimanche, Nuit et jour férié',
      dataIndex: 'amount_D_N_JF',
    },

    // {
    //   title: 'Actions',
    //   align: 'left',
    //   render: (_value, record) => {
    //     return (
    //       <StyledSpace>
    //         <StyledButton
    //           shape='circle'
    //           size='middle'
    //           icon={<FiEdit />}
    //           onClick={() => handleUpdate(record)}
    //         />
    //         <StyledButton
    //           shape='circle'
    //           size='middle'
    //           danger
    //           icon={<FiTrash2 />}
    //           onClick={() => handleRemove(record)}
    //         />
    //       </StyledSpace>
    //     );
    //   },
    // },
  ];

  const loadData = async () => {
    setIsLoading(true);
    Promise.resolve(getActsPricingsApi())
      .then(result => {
        setActs(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //   const deleteItem = async (item: IActItem) => {
  //     try {
  //       setIsLoading(true);
  //       Promise.resolve(
  //         deleteInvoiceRubricApi(item.id as number).then(() => {
  //           loadData();
  //           notification.success({
  //             message: SuccesssMsgTitle,
  //             description: `Le coefficient a été supprimé`,
  //           });
  //         }),
  //       );
  //     } catch (error) {
  //       notification.error({
  //         message: ErrorMsgTitle,
  //         description: getApiErrorMsg(ErrorDeleteMsg),
  //       });
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   const handleRemove = (item: IActItem) => {
  //     modal.confirm({
  //       title: 'Confirmation de suppression',
  //       icon: <BsExclamationTriangleFill size={40} color={chartColors.warning} />,
  //       content: `Êtes-vous sûr de vouloir supprimer l'assurance ${item.name} de manière permanente ?`,
  //       okText: 'Oui, je Confirme',
  //       cancelText: 'Annuler',
  //       onOk: () => deleteItem(item),
  //     });
  //   };

  useEffect(() => {
    loadData();
  }, []);

  const renderHeader = () => {
    return (
      <SectionHeader title='Acte' count={acts.length}>
        {/* <StyledButton
            icon={<BsPlus />}
            type='primary'
            onClick={() => navigateToview('NEW_ITEM')}>
            Ajouter une assurance
          </StyledButton> */}
      </SectionHeader>
    );
  };

  const renderContent = () => {
    return (
      <>
        <StyledDivider />
        <Table
          rowKey='id'
          bordered={false}
          columns={columnData}
          dataSource={acts}
          size='small'
          pagination={false}
        />
      </>
    );
  };

  return (
    <>
      {renderHeader()}
      <Loader showSpinner={isLoading}>{renderContent()}</Loader>
    </>
  );
};

export default ActsList;
