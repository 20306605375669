import _ from 'lodash';
import { CreatePrescriptionState, CreatePrescriptionAction } from './types';

export const getCreatePrescriptionDefaultInitialState = (): CreatePrescriptionState => {
  return {
    prescriptionLines: [],
    invalidInputs: [],
  } as CreatePrescriptionState;
};

export const CreatePrescriptionReducer = (
  state: CreatePrescriptionState,
  action: CreatePrescriptionAction,
) => {
  const newState = { ...state };
  switch (action.type) {
    case 'setPrescriptionMedications':
      newState.prescriptionLines = _.concat(state.prescriptionLines, action.payload);
      return newState;

    case 'removeMedicationLine':
      newState.prescriptionLines = state.prescriptionLines.filter(
        item => item.idMedication !== action.payload,
      );
      return newState;

    case 'setQuantityMedication': {
      const medicationId = action.payload.idMedication;
      const medicationValue = action.payload.value;

      return {
        ...state,
        prescriptionLines: state.prescriptionLines.map(m =>
          m.idMedication === medicationId ? { ...m, quantity: medicationValue } : m,
        ),
      };
    }

    case 'setPosologyMedication': {
      const medicationId = action.payload.idMedication;
      const medicationValue = action.payload.value;
      return {
        ...state,
        prescriptionLines: state.prescriptionLines.map(m =>
          m.idMedication === medicationId ? { ...m, posology: medicationValue } : m,
        ),
      };
    }

    case 'setTreatDurationMedication': {
      const medicationId = action.payload.idMedication;
      const medicationValue = action.payload.value;
      return {
        ...state,
        prescriptionLines: state.prescriptionLines.map(m =>
          m.idMedication === medicationId
            ? { ...m, treatmentDuration: medicationValue }
            : m,
        ),
      };
    }

    case 'setResetPrescriptionMedications': {
      newState.prescriptionLines = [];
      return newState;
    }

    case 'setInvalidInputs':
      newState.invalidInputs = action.payload;
      return newState;

    default:
      return state;
  }
};
