/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer, Form } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  createArticleApi,
  getSuppliersApi,
  updateArticleApi,
} from '../../../api/referentialService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import {
  StyledCol,
  StyledDivider,
  StyledInput,
  StyledInputNumber,
  StyledRow,
  StyledSelectOption,
  StyledSelectPicker,
  StyledSpace,
} from '../../../components/style';
import {
  CreateArticleItemRequestBody,
  GetSuppliersResponse,
  IArticleItem,
  ReferentialViewProps,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { StyledBlocContainer } from '../../style';

type ArticleFormProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  currentData?: IArticleItem;
};

type FormPropertiesProps = {
  id: number;
  reference: string;
  designation: string;
  idSupplier: number;
  amount: number;
};

const ArticleForm: React.FC<ArticleFormProps> = ({ navigateToview, currentData }) => {
  const { notification } = AppContainer.useApp();
  const [form] = Form.useForm<FormPropertiesProps>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [suppliers, setSuppliers] = useState<GetSuppliersResponse>([]);

  const loadSuppliers = async () => {
    setIsLoading(true);
    Promise.resolve(getSuppliersApi())
      .then(result => {
        setSuppliers(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadSuppliers();
  }, []);

  const renderHeader = () => {
    return (
      <SectionHeader
        title={
          _.isUndefined(currentData) ? 'Nouveau consommable' : 'Modification consommable'
        }
        onBack={() => navigateToview('LIST_ITEMS')}
      />
    );
  };

  const mapPropsToRequest = (row: FormPropertiesProps) => {
    const itemData: CreateArticleItemRequestBody = {
      designation: row.designation,
      reference: row.reference,
      idSupplier: row.idSupplier,
      amount: row.amount,
    };

    return itemData;
  };

  const createItem = async () => {
    try {
      await form.validateFields().then(async row => {
        setIsLoading(true);
        Promise.resolve(createArticleApi(mapPropsToRequest(row))).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `${row.designation} a été enregistré!`,
          });
          navigateToview('LIST_ITEMS');
        });
      });
    } catch (error: any) {
      if (_.isUndefined(error.values)) {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const editItem = async () => {
    try {
      await form.validateFields().then(async row => {
        setIsLoading(true);
        await updateArticleApi(currentData?.id as number, mapPropsToRequest(row)).then(
          () => {
            notification.success({
              message: SuccesssMsgTitle,
              description: `Le consommable a été modifié!`,
            });
            navigateToview('LIST_ITEMS');
          },
        );
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    return (
      <StyledBlocContainer style={{ padding: 20 }}>
        <Form
          disabled={isLoading}
          form={form}
          component={false}
          initialValues={{
            reference: _.isUndefined(currentData) ? '' : currentData.reference,
            designation: _.isUndefined(currentData) ? '' : currentData.designation,
            amount: _.isUndefined(currentData) ? '' : currentData.amount,
            idSupplier:
              _.isUndefined(currentData) || currentData.supplier.id === 0
                ? null
                : currentData.supplier.id,
          }}>
          <StyledRow gutter={[16, 16]} align='middle' justify='center'>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Référence consommable' />
              <Form.Item style={{ margin: 0 }} name='reference'>
                <StyledInput size='large' placeholder='Référence consommable' />
              </Form.Item>
            </StyledCol>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Désignation du consommable' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='designation'
                rules={[
                  { required: true, message: 'Désignation obligatoire' },
                  { whitespace: false },
                ]}>
                <StyledInput size='large' placeholder='Désignation consommable' />
              </Form.Item>
            </StyledCol>

            <StyledCol span={24}>
              <FormFieldLabel libelle='Fournisseur' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='idSupplier'
                rules={[{ required: true, message: 'Fournisseur obligatoire' }]}>
                <StyledSelectPicker
                  placeholder='Fournisseur'
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }>
                  {_.map(suppliers, item => (
                    <StyledSelectOption key={item.id} value={item.id} label={item.name}>
                      {item.name}
                    </StyledSelectOption>
                  ))}
                </StyledSelectPicker>
              </Form.Item>
            </StyledCol>

            <StyledCol span={24}>
              <FormFieldLabel libelle='Montant' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='amount'
                rules={[
                  { required: true, message: 'Montant du consommable obligatoire' },
                ]}>
                <StyledInputNumber
                  size='large'
                  style={{ width: '100%' }}
                  placeholder='Montant du consommable'
                  min={1}
                />
              </Form.Item>
            </StyledCol>

            <StyledDivider />
            <StyledCol span={24}>
              <StyledSpace>
                <StyledButton
                  type='primary'
                  htmlType='submit'
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={_.isUndefined(currentData) ? createItem : editItem}>
                  {_.isUndefined(currentData)
                    ? 'Enregistrer le consommable'
                    : 'Modifier le consommable'}
                </StyledButton>
              </StyledSpace>
            </StyledCol>
          </StyledRow>
        </Form>
      </StyledBlocContainer>
    );
  };

  return (
    <>
      {renderHeader()}
      <StyledDivider />
      {renderContent()}
    </>
  );
};

export default ArticleForm;
