import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { App as AppContainer } from 'antd';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { getMedicalRecordsApi } from '../../api/dossierPatientService';
import chartColors from '../../styles/color';
import {
  GetMedicalRecordResponseConsultation,
  NavigationMode,
  Patient,
  SearchPatientResult,
} from '../../types/';
import { ErrorMsgTitle } from '../../utils/constants';
import Space from '../Space';
import Loader from '../loader';
import {
  StyledResponsiveTableHeader,
  StyledResponsiveTableRowValue,
  StyledResponsiveTableWrapper,
  StyledTableHeader,
  StyledTableHeaderWrapper,
  StyledTableRowItem,
  StyledTableRowWrapper,
  UserDataWrapper,
} from '../style';

interface PatientHistoryProps {
  data?: SearchPatientResult;
}
const PatientHistory: React.FC<PatientHistoryProps> = ({ data }) => {
  const { notification } = AppContainer.useApp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { patient: Patient };
  const [consultations, setConsultations] = useState<
    GetMedicalRecordResponseConsultation[]
  >([]);

  useEffect(() => {
    const fetchMedicalRecords = async () => {
      setIsLoading(true);
      try {
        const result = await getMedicalRecordsApi();
        // const result = await getMedicalRecordsApi(state.patient.id);
        setConsultations(result);
      } catch (error) {
        notification.error({
          message: ErrorMsgTitle,
          description:
            typeof error === 'object' ? JSON.stringify(error) : (error as string),
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchMedicalRecords();
  }, [state.patient.id]);

  if (!data) {
    return null;
  }

  const renderResponsiveTable = () => {
    return consultations.map(item => {
      return (
        <StyledResponsiveTableWrapper
          key={item.id}
          onClick={() => {
            navigate('/dossier-patient', {
              state: {
                mode: NavigationMode.READ_MODIFY_MODE,
              },
            });
          }}>
          <div>
            <StyledResponsiveTableHeader>N° dossier</StyledResponsiveTableHeader>
            <StyledResponsiveTableHeader>Date</StyledResponsiveTableHeader>
            <StyledResponsiveTableHeader>
              Date de cloture du dossier
            </StyledResponsiveTableHeader>
            <StyledResponsiveTableHeader>
              {' '}
              Motif de consultation
            </StyledResponsiveTableHeader>
            <div
              style={{
                color: chartColors.blue,
                display: 'flex',
                gap: '0.5rem',
              }}>
              <FontAwesomeIcon icon={faCircleChevronRight} />
              <span style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>Voir plus</span>
            </div>
          </div>
          <div>
            <StyledResponsiveTableRowValue>{item.id}</StyledResponsiveTableRowValue>
            <StyledResponsiveTableRowValue>
              {item.createdDate}
            </StyledResponsiveTableRowValue>
            <StyledResponsiveTableRowValue>
              {item.closingDate}
            </StyledResponsiveTableRowValue>
            <StyledResponsiveTableRowValue>
              {item.consultationReason}
            </StyledResponsiveTableRowValue>
          </div>
        </StyledResponsiveTableWrapper>
      );
    });
  };

  const renderTableHeader = () => {
    return (
      <StyledTableHeaderWrapper style={{ gap: '2rem' }}>
        <StyledTableHeader>N° dossier</StyledTableHeader>
        <StyledTableHeader>Date</StyledTableHeader>
        <StyledTableHeader style={{ width: '20.5rem' }}>
          Date de cloture du dossier
        </StyledTableHeader>
        <StyledTableHeader style={{ width: '16.5rem' }}>
          Motif de consultation
        </StyledTableHeader>
        <StyledTableHeader></StyledTableHeader>
      </StyledTableHeaderWrapper>
    );
  };

  const renderItems = () => {
    return consultations.map(item => {
      return (
        <StyledTableRowWrapper
          key={item.id}
          style={{ gap: '2rem' }}
          onClick={() => {
            navigate('/dossier-patient', {
              state: {
                mode: NavigationMode.READ_MODIFY_MODE,
              },
            });
          }}>
          <StyledTableRowItem>{item.id}</StyledTableRowItem>
          <StyledTableRowItem>{item.createdDate}</StyledTableRowItem>
          <StyledTableRowItem style={{ width: '20rem' }}>
            {item.closingDate}
          </StyledTableRowItem>
          <StyledTableRowItem>{item.consultationReason}</StyledTableRowItem>
          <StyledTableRowItem style={{ fontSize: '2.5rem', color: '#5076e8' }}>
            &#62;
          </StyledTableRowItem>
        </StyledTableRowWrapper>
      );
    });
  };

  const renderContent = () => {
    if (isLoading) {
      return;
    }
    if (isTabletOrMobile) {
      return <>{renderResponsiveTable()}</>;
    }
    return (
      <>
        {renderTableHeader()}
        {renderItems()}
      </>
    );
  };

  return (
    <>
      <UserDataWrapper>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>Numéro Patient: </span>
            <Space marginTop='1rem' />
            <span>Date de naissance: </span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{state.patient?.id}</span>
            <Space marginTop='1rem' />
            <span>{state.patient?.birthDate}</span>
          </div>
        </div>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>Adresse: </span>
            <Space marginTop='1rem' />
            <span>Numéro de téléphone: </span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Space marginTop='1rem' />
            <span>{state.patient?.phoneNumber}</span>
          </div>
        </div>
      </UserDataWrapper>
      <Space marginTop='3rem' />
      <Loader showSpinner={isLoading}>{renderContent()}</Loader>
    </>
  );
};

export default PatientHistory;
