import { useState } from 'react';
import { IRoomItem, ReferentialViewProps } from '../../../types';
import RoomsList from './RoomsList';
import RoomForm from './RoomForm';

const RoomRef = () => {
  const [activeView, setActiveView] = useState<ReferentialViewProps>('LIST_ITEMS');
  const [selectedItem, setSelectedItem] = useState<IRoomItem>();

  const handleNavigateToView = (newView: ReferentialViewProps) => {
    setActiveView(newView);
  };

  const handleUpdateSelectedItem = (item?: IRoomItem) => {
    setSelectedItem(item);
  };

  const renderContent = () => {
    switch (activeView) {
      case 'LIST_ITEMS':
        return (
          <RoomsList
            navigateToview={handleNavigateToView}
            updateCurrentItem={handleUpdateSelectedItem}
          />
        );
      case 'EDIT_ITEM':
        return (
          <RoomForm navigateToview={handleNavigateToView} currentData={selectedItem} />
        );
      case 'NEW_ITEM':
        return <RoomForm navigateToview={handleNavigateToView} />;
    }
  };

  return <>{renderContent()}</>;
};

export default RoomRef;
