import { App as AppContainer } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  createInvoiceApi,
  createProformaApi,
  getInvoicePlansApi,
} from '../../api/invoiceService';
import { StyledButton } from '../../components/button/style';
import EmptyNotSelected from '../../components/emptyData/EmptyNotSelected';
import {
  StyledCol,
  StyledInput,
  StyledRow,
  StyledSpace,
  StyledTag,
  StyledTooltip,
} from '../../components/style';

import { BsFilePlus } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { getUnits } from '../../api/referenceService';
import SectionHeader from '../../components/header/SectionHeader';
import FormFieldLabel from '../../components/label/FormFieldLabel';
import Loader from '../../components/loader';
import PdfDrawerViewer from '../../components/viewer/PdfDrawerViewer';
import {
  CreateAutoInvoiceProformaState,
  CreateInvoiceProformaAction,
} from '../../reducers/invoicePlan/types';
import chartColors from '../../styles/color';
import {
  CreateMedicalRecordApiResponse,
  CreateProformaRequestBody,
  CreateProformaResponse,
  EInvoiceType,
  GetInvoicePlansResponse,
  GetUnitResponse,
  PatientDto,
  PharmacyLinesResponse,
  SearchBiologicalPrescriptionLinesResponse,
  SearchImageryPrescriptionLinesResponse,
  UserDto,
} from '../../types';
import { getApiErrorMsg, renderInvoiceTitle } from '../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../utils/constants';
import { getDataFromStorage } from '../../utils/storage';
import RenderDocument from '../document';
import { StyleListContainer } from '../style';
import AddRemoveInvoicePlan from './AddRemoveInvoicePlan';
import BiologicalPrescritionsCard from './BiologicalPrescritionsCard';
import ImageryPrescriptionsCard from './ImageryPrescriptionsCard';
import PharmacyLinesCard from './PharmacyLinesCard';

interface AutoProformaInvoiceProps {
  selectedPatientId: number | null;
  selectedMedicalRecord: CreateMedicalRecordApiResponse | null;
  currentPatient?: PatientDto;
  biologicalPrescriptionsLines: SearchBiologicalPrescriptionLinesResponse;
  imageryPrescriptionsLines: SearchImageryPrescriptionLinesResponse;
  pharmacyInvoiceLines: PharmacyLinesResponse;
  state: CreateAutoInvoiceProformaState;
  reducer: React.Dispatch<CreateInvoiceProformaAction>;
  invoiceType: EInvoiceType;
  isParentLoading: boolean;
}

const AutoProformaInvoice: React.FC<AutoProformaInvoiceProps> = ({
  selectedMedicalRecord,
  selectedPatientId,
  biologicalPrescriptionsLines,
  imageryPrescriptionsLines,
  pharmacyInvoiceLines,
  currentPatient,
  reducer,
  state,
  invoiceType,
  isParentLoading,
}) => {
  const user = getDataFromStorage<UserDto>('connectedUserData');
  const navigate = useNavigate();

  const { notification } = AppContainer.useApp();

  const [plansData, setPlansData] = useState<GetInvoicePlansResponse[]>([]);
  const [unitsData, setUnitsData] = useState<GetUnitResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showProformaPdf, setShowProformaPdf] = useState<boolean>(false);
  const [proformaPdfData, setProformaPdfData] = useState<CreateProformaResponse>();

  const [showInvoicePdf, setShowInvoicePdf] = useState<boolean>(false);
  const [invoicePdfData, setInvoicePdfData] = useState<CreateProformaResponse>();

  const [showItemsModal, setShowItemsModal] = useState<boolean>(false);
  const [bonNumber, setBonNumber] = useState(selectedMedicalRecord?.bonNumber ?? '');

  const [exams, setExams] = useState<{
    biological: {
      keys: React.Key[];
      selectedRows: SearchBiologicalPrescriptionLinesResponse;
    };
    imagery: { keys: React.Key[]; selectedRows: SearchImageryPrescriptionLinesResponse };
  }>({
    biological: {
      keys: [],
      selectedRows: [],
    },
    imagery: {
      keys: [],
      selectedRows: [],
    },
  });

  // const [state, reducer] = useReducer(
  //   CreateAutoInvoiceProformaReducer,
  //   getCreateAutoInvoiceProformaDefaultInitialState(),
  // );

  const onSelectBiologicalExamChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: SearchBiologicalPrescriptionLinesResponse,
  ) => {
    setExams(prevState => ({
      ...prevState,
      biological: {
        keys: newSelectedRowKeys,
        selectedRows: selectedRows,
      },
    }));
  };

  const onSelectImageryExamChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: SearchImageryPrescriptionLinesResponse,
  ) => {
    setExams(prevState => ({
      ...prevState,
      imagery: {
        keys: newSelectedRowKeys,
        selectedRows: selectedRows,
      },
    }));
  };

  const loadInvoicePlansAndUnits = async () => {
    setIsLoading(true);

    try {
      Promise.all([getInvoicePlansApi(), getUnits()]).then(result => {
        setPlansData(result[0]);
        setUnitsData(result[1]);
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description:
          typeof error === 'object' ? JSON.stringify(error) : (error as string),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const mapStateToRequestBody = (
    state: CreateAutoInvoiceProformaState,
  ): CreateProformaRequestBody => {
    const result: CreateProformaRequestBody = {
      bonNumber: bonNumber,
      createdBy: user?.id,
      idPatient: selectedPatientId ?? null,
      idmedicalRecord: selectedMedicalRecord?.id,
      idInvoicePlanHead: state.plan?.id,
      proformaLines: state.rubrics,
      invoiceLineBiological: [EInvoiceType.BIOLOGICAL].includes(invoiceType)
        ? exams.biological.selectedRows
        : [],
      invoiceLineImagery: [EInvoiceType.IMAGERY].includes(invoiceType)
        ? exams.imagery.selectedRows
        : [],
      invoicePharmayLineDto: [EInvoiceType.PHARMACY].includes(invoiceType)
        ? pharmacyInvoiceLines
        : [],
    } as CreateProformaRequestBody;
    return result;
  };

  const redirectToInvoicePage = () => {
    navigate('/facturation', {
      replace: true,
    });
  };

  const createInvoice = async () => {
    try {
      setIsLoading(true);
      const result = await createInvoiceApi({
        createdBy: user?.id,
        idProforma: proformaPdfData?.id as number,
        typeInvoice: 'INVOICE',
      });
      notification.success({
        message: SuccesssMsgTitle,
        description: 'La facture a bien été créé.',
      });
      setShowProformaPdf(false);
      setInvoicePdfData(result);
      setShowInvoicePdf(true);
      navigate('/facturation');
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const createProforma = async () => {
    try {
      if (_.isNull(selectedMedicalRecord?.id)) {
        notification.error({
          message: ErrorMsgTitle,
          description: 'Veuillez sélectionner un dossier',
        });
      } else if (
        (selectedMedicalRecord?.idInsurance as number) > 0 &&
        _.isEmpty(bonNumber)
      ) {
        notification.error({
          message: ErrorMsgTitle,
          description: 'Le numéro de bon est obligatoire',
        });
      } else {
        setIsLoading(true);
        const result = await createProformaApi(mapStateToRequestBody(state));
        notification.success({
          message: SuccesssMsgTitle,
          description: 'La proforma a bien été créé.',
        });
        setProformaPdfData(result);
        setShowProformaPdf(true);
      }
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const resetInvoicePdfViewer = () => {
    setShowInvoicePdf(false);
    setInvoicePdfData(undefined);
    navigate('/facturation');
  };

  const handleItemsVisibilityModal = () => {
    setShowItemsModal(state => !state);
  };

  useEffect(() => {
    loadInvoicePlansAndUnits();
  }, []);

  const renderInvoiceRubricsByPlan = () => {
    return (
      <StyledCol span={24}>
        <AddRemoveInvoicePlan
          items={plansData}
          isLoading={isLoading}
          listTitle='Sélectionnez un ou plusieurs plans de facture'
          reducer={reducer}
          state={state}
          unitsData={unitsData}
          handleCloseModalVisibility={handleItemsVisibilityModal}
          isModalVisible={showItemsModal}
          selectedMedicalRecord={selectedMedicalRecord}
        />
      </StyledCol>
    );
  };

  const renderConfirmButton = () => {
    return (
      <StyledCol span={24}>
        {!_.isEmpty(state.rubrics) ||
        !_.isEmpty(exams.biological.selectedRows) ||
        !_.isEmpty(exams.imagery.selectedRows) ||
        !_.isEmpty(pharmacyInvoiceLines) ? (
          <StyledButton
            block
            type='primary'
            loading={isLoading}
            disabled={isLoading}
            onClick={() => createProforma()}>
            Créer proforma
          </StyledButton>
        ) : null}
      </StyledCol>
    );
  };

  const renderHeaderActions = () => {
    return (
      <StyledSpace>
        <StyledTooltip
          color='volcano'
          title={
            state.rubrics.length === 1 &&
            'Veuillez retirer le plan actuel pour sélectionner un autre'
          }>
          <StyledButton
            loading={isLoading}
            disabled={isLoading} // savedItems.length !== 0
            size='middle'
            icon={<BsFilePlus />}
            block
            type='dashed'
            onClick={() => {
              setShowItemsModal(true);
            }}>
            Sélectionnez un plan
          </StyledButton>
        </StyledTooltip>
        {renderConfirmButton()}
      </StyledSpace>
    );
  };

  const renderBiologicalPrescriptionsLines = () => {
    return (
      <StyledCol span={24}>
        <BiologicalPrescritionsCard
          prescriptionLines={biologicalPrescriptionsLines}
          handleRowChange={onSelectBiologicalExamChange}
          selectedRowKeys={exams.biological.keys}
          isLoading={isParentLoading || isLoading}
        />
      </StyledCol>
    );
  };

  const renderImageryPrescriptionsLines = () => {
    return (
      <StyledCol span={24}>
        <ImageryPrescriptionsCard
          prescriptionLines={imageryPrescriptionsLines}
          handleRowChange={onSelectImageryExamChange}
          selectedRowKeys={exams.imagery.keys}
          isLoading={isParentLoading || isLoading}
        />
      </StyledCol>
    );
  };

  const renderPharmacyInvoiceLines = () => {
    return (
      <StyledCol span={24}>
        <PharmacyLinesCard phamacyLines={pharmacyInvoiceLines} />
      </StyledCol>
    );
  };

  const renderInsuranceCard = () => {
    const InsuranceHeader =
      selectedMedicalRecord?.idInsurance && selectedMedicalRecord?.idInsurance > 0 ? (
        <StyledTag color={chartColors.success}>
          Assuré {`${selectedMedicalRecord?.insuRatio}%`}
        </StyledTag>
      ) : (
        <StyledTag color={chartColors.danger}>Non Assuré</StyledTag>
      );

    return (
      <StyledSpace align='center'>
        <span>Nouvelle facture</span> {InsuranceHeader}
      </StyledSpace>
    );
  };

  const renderContent = () => {
    if (_.isNull(selectedPatientId) || _.isUndefined(selectedPatientId)) {
      return (
        <EmptyNotSelected
          description='Veuillez sélectionner le patient à facturer'
          message='Aucun patient Sélectionné'
        />
      );
    }
    return (
      <div style={{ padding: '0px 20px', paddingBottom: '180px' }}>
        <SectionHeader title={renderInsuranceCard()}>
          {renderHeaderActions()}
        </SectionHeader>
        <Loader showSpinner={isLoading}>
          <StyledRow gutter={[20, 20]}>
            <StyledCol span={24}>
              <FormFieldLabel
                libelle='Numéro de bon'
                isRequired={(selectedMedicalRecord?.idInsurance as number) > 0}
              />
              <StyledInput
                size='large'
                placeholder='Numéro de bon'
                onChange={e => setBonNumber(e.target.value)}
                value={bonNumber}
                disabled={(selectedMedicalRecord?.idInsurance as number) <= 0}
              />
            </StyledCol>

            {renderInvoiceRubricsByPlan()}

            {[EInvoiceType.BIOLOGICAL, EInvoiceType.OTHERS].includes(invoiceType) &&
            !_.isEmpty(biologicalPrescriptionsLines)
              ? renderBiologicalPrescriptionsLines()
              : null}

            {[EInvoiceType.IMAGERY, EInvoiceType.OTHERS].includes(invoiceType) &&
            !_.isEmpty(imageryPrescriptionsLines)
              ? renderImageryPrescriptionsLines()
              : null}

            {[EInvoiceType.PHARMACY, EInvoiceType.OTHERS].includes(invoiceType) &&
            !_.isEmpty(pharmacyInvoiceLines)
              ? renderPharmacyInvoiceLines()
              : null}
          </StyledRow>
        </Loader>
      </div>
    );
  };

  return (
    <>
      <StyleListContainer style={{ padding: '0px', paddingTop: '10px' }}>
        <div style={{ backgroundColor: 'white', height: '100%' }}>{renderContent()}</div>
      </StyleListContainer>

      {showProformaPdf && (
        <PdfDrawerViewer
          drawerTitle={`Facture Proforma ${proformaPdfData?.numProforma} `}
          closeDrawer={() => setShowProformaPdf(false)}
          extraActions={
            <>
              <StyledButton
                key='create_invoice'
                type='primary'
                size='middle'
                loading={isLoading}
                disabled={isLoading}
                onClick={() => createInvoice()}>
                Créer la facture
              </StyledButton>
              <StyledButton
                key='cancel_invoice'
                type='primary'
                size='middle'
                danger
                disabled={isLoading}
                onClick={() => redirectToInvoicePage()}>
                Quitter
              </StyledButton>
            </>
          }
          isDrawerOpen={showProformaPdf}>
          <RenderDocument<
            CreateProformaResponse | undefined,
            {
              patientData?: PatientDto;
              medicalRecord: CreateMedicalRecordApiResponse;
            }
          >
            documentData={proformaPdfData}
            documentType='PROFORMA_INVOICE'
            isDuplicata={false}
            extraData={{
              patientData: currentPatient,
              medicalRecord: selectedMedicalRecord as CreateMedicalRecordApiResponse,
            }}
          />
        </PdfDrawerViewer>
      )}

      {showInvoicePdf && (
        <PdfDrawerViewer
          drawerTitle={`Facture ${invoicePdfData?.num}`}
          closeDrawer={() => resetInvoicePdfViewer()}
          extraActions={
            <>
              <StyledButton
                key='cancel_invoice'
                type='primary'
                size='middle'
                danger
                disabled={isLoading}
                onClick={() => resetInvoicePdfViewer()}>
                Quitter
              </StyledButton>
            </>
          }
          isDrawerOpen={showInvoicePdf}>
          <RenderDocument<
            CreateProformaResponse | undefined,
            {
              title: string;
              patientData?: PatientDto;
              medicalRecord: CreateMedicalRecordApiResponse;
            }
          >
            documentData={invoicePdfData as CreateProformaResponse}
            documentType='VALIDATED_INVOICE'
            extraData={{
              title: renderInvoiceTitle(invoiceType),
              patientData: currentPatient,
              medicalRecord: selectedMedicalRecord as CreateMedicalRecordApiResponse,
            }}
            isDuplicata={false}
          />
        </PdfDrawerViewer>
      )}
    </>
  );
};

export default AutoProformaInvoice;
