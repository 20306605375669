import dayjs from 'dayjs';
import localFr from 'dayjs/locale/fr';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import dayjsBusinessDays from 'dayjs-business-days2';

const holidaysOptions = {
  holidayFormat: 'DD/MM/YYYY',
};

const dayjsInstance = dayjs;
dayjsInstance.extend(weekOfYear);
dayjsInstance.extend(dayOfYear);
dayjsInstance.extend(localeData);
dayjsInstance.extend(weekday);
dayjsInstance.extend(dayjsBusinessDays, holidaysOptions);

dayjsInstance.locale(localFr);

export default dayjsInstance;
