/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer, Form } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  createBiologicalAnalysisSubCategoryApi,
  getBiologicalAnalysisCategoriesApi,
  updateBiologicalAnalysisSubCategoryApi,
} from '../../../api/referentialService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import {
  StyledCol,
  StyledDivider,
  StyledInput,
  StyledRow,
  StyledSelectOption,
  StyledSelectPicker,
  StyledSpace,
} from '../../../components/style';
import {
  BiologicalAnalysisSubCategoryDto,
  CreateBiologicalAnalysisSubCategoryRequestBody,
  GetBiologicalAnalysisCategoriesResponse,
  ReferentialViewProps,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { StyledBlocContainer } from '../../style';

type BiologicalExamSubCategorieFormProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  currentData?: BiologicalAnalysisSubCategoryDto;
};

const BiologicalExamSubCategorieForm: React.FC<BiologicalExamSubCategorieFormProps> = ({
  navigateToview,
  currentData,
}) => {
  const { notification } = AppContainer.useApp();
  const [form] = Form.useForm<{
    code: string;
    label: string;
    idBiologicalAnalysisCategory: number;
  }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [biologicalExamCategories, setBiologicalExamCategories] =
    useState<GetBiologicalAnalysisCategoriesResponse>([]);

  const loadBiologicalExamCategories = async () => {
    setIsLoading(true);
    Promise.resolve(getBiologicalAnalysisCategoriesApi())
      .then(result => {
        setBiologicalExamCategories(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadBiologicalExamCategories();
  }, []);

  const renderHeader = () => {
    return (
      <SectionHeader
        title={
          _.isUndefined(currentData)
            ? "Nouvelle sous-catégorie d'examen biologique"
            : "Modification sous-catégorie d'examen biologique"
        }
        onBack={() => navigateToview('LIST_ITEMS')}
      />
    );
  };

  const createBiologicalExamCategory = async () => {
    try {
      await form.validateFields().then(async row => {
        const categoryData: CreateBiologicalAnalysisSubCategoryRequestBody = {
          code: row.code,
          label: row.label,
          idBiologicalAnalysisCategory: row.idBiologicalAnalysisCategory,
        };

        setIsLoading(true);
        await createBiologicalAnalysisSubCategoryApi(categoryData).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `${row.label} a été enregistrée!`,
          });
          navigateToview('LIST_ITEMS');
        });
      });
    } catch (error: any) {
      if (_.isUndefined(error.values)) {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const editBiologicalExamCategory = async () => {
    try {
      await form.validateFields().then(async row => {
        const categoryData: CreateBiologicalAnalysisSubCategoryRequestBody = {
          code: row.code,
          label: row.label,
          idBiologicalAnalysisCategory: row.idBiologicalAnalysisCategory,
        };

        setIsLoading(true);
        await updateBiologicalAnalysisSubCategoryApi(
          currentData?.id as number,
          categoryData,
        ).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `La sous-catégorie a été modifiée!`,
          });
          navigateToview('LIST_ITEMS');
        });
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    return (
      <StyledBlocContainer style={{ padding: 20 }}>
        <Form
          disabled={isLoading}
          form={form}
          component={false}
          initialValues={{
            code: _.isUndefined(currentData) ? '' : currentData.code,
            label: _.isUndefined(currentData) ? '' : currentData.label,
            idBiologicalAnalysisCategory: _.isUndefined(currentData)
              ? null
              : currentData.biologicalAnalysisCategoryDto.id,
          }}>
          <StyledRow gutter={[16, 16]} align='middle' justify='center'>
            {/* <StyledCol span={24}>
              <FormFieldLabel libelle='Code de la sous-catégorie' />
              <Form.Item style={{ margin: 0 }} name='code'>
                <StyledInput
                  size='large'
                  placeholder='Code sous-catégorie examen biologique'
                />
              </Form.Item>
            </StyledCol> */}
            <StyledCol span={24}>
              <FormFieldLabel libelle='Libellé de la sous-catégorie' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='label'
                rules={[
                  { required: true, message: 'Libellé obligatoire' },
                  { whitespace: false },
                ]}>
                <StyledInput
                  size='large'
                  placeholder='Libellé sous-catégorie examen biologique'
                />
              </Form.Item>
            </StyledCol>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Catégorie parente' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='idBiologicalAnalysisCategory'
                rules={[{ required: true, message: 'Catégorie parente obligatoire' }]}>
                <StyledSelectPicker
                  placeholder='Catégorie parente'
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }>
                  {_.map(biologicalExamCategories, item => (
                    <StyledSelectOption key={item.id} value={item.id} label={item.label}>
                      {item.label}
                    </StyledSelectOption>
                  ))}
                </StyledSelectPicker>
              </Form.Item>
            </StyledCol>
            <StyledDivider />
            <StyledCol span={24}>
              <StyledSpace>
                <StyledButton
                  type='primary'
                  htmlType='submit'
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={
                    _.isUndefined(currentData)
                      ? createBiologicalExamCategory
                      : editBiologicalExamCategory
                  }>
                  {_.isUndefined(currentData)
                    ? 'Enregistrer la catégorie'
                    : 'Modifier la catégorie'}
                </StyledButton>
              </StyledSpace>
            </StyledCol>
          </StyledRow>
        </Form>
      </StyledBlocContainer>
    );
  };

  return (
    <>
      {renderHeader()}
      <StyledDivider />
      {renderContent()}
    </>
  );
};

export default BiologicalExamSubCategorieForm;
