import { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import dayjsInstance from '../../dayjs';
import { GetMedecinAppointmentsResponse, TimeSlotProps } from '../../types';
import { generateTimesIntervals, timeToMinutes } from '../../utils/commun';
import { DATE_FORMAT } from '../../utils/constants';
import AppointmentTimeSlot from './AppointmentTimeSlot';
import {
  TimeSlotCalendarDayContainer,
  TimeSlotCalendarDayHeader,
  TimeSlotCalendarDayHeaderDate,
  TimeSlotCalendarDayHeaderTitle,
  TimeSlotTimeContainer,
} from './style';

type AppointmentDayProps = {
  day: string;
  dateIndex: number;
  dayDates: Dayjs[];
  handleUpdateTimeSlots: (timeSlot: TimeSlotProps) => void;
  selectedTimeSlot?: TimeSlotProps;
  absencesTimeslots: TimeSlotProps[];
  currentAppointments: GetMedecinAppointmentsResponse;
  shouldSelected?: boolean;
  resetData: () => void;
};

const AppointmentDay: React.FC<AppointmentDayProps> = ({
  day,
  dateIndex,
  dayDates,
  handleUpdateTimeSlots,
  selectedTimeSlot,
  absencesTimeslots,
  currentAppointments,
  shouldSelected,
  resetData,
}) => {
  const currentDay = dayDates[dateIndex];
  const checkIsTimeslotDisabled = (hour: string): boolean => {
    const currentDate = dayjsInstance();
    const referenceDate = dayDates[dateIndex];

    const isDateInPast = currentDate.diff(referenceDate, 'day') > 0;
    const isSameDay = currentDate.isSame(referenceDate, 'day');
    const isTimeInPast =
      isSameDay && timeToMinutes(currentDate.format('HH:mm')) > timeToMinutes(hour);

    return isDateInPast || isTimeInPast;
  };

  const checkIsTimeslotInAbsence = (timeSlot: TimeSlotProps): boolean => {
    return (
      _.findIndex(absencesTimeslots, { date: timeSlot.date, hour: timeSlot.hour }) !== -1
    );
  };

  const checkIsTimeslotAlreadyAppointed = (timeSlotParam: TimeSlotProps): boolean => {
    const index = _.findIndex(
      currentAppointments,
      t =>
        t.timeSlot.date === timeSlotParam.date && t.timeSlot.hour === timeSlotParam.hour,
    );
    return index !== -1;
  };

  const getAppointedTimeslotId = (timeSlotParam: TimeSlotProps): number | undefined => {
    const index = _.findIndex(currentAppointments, {
      timeSlot: {
        date: timeSlotParam.date,
        hour: timeSlotParam.hour,
      },
    });

    if (index !== -1) {
      return currentAppointments[index].timeSlot.id;
    }
    // Return undefined if no matching appointment is found
  };

  const checkIsTimeslotSelected = (timeslot: TimeSlotProps): boolean => {
    return (
      selectedTimeSlot?.date === timeslot.date && selectedTimeSlot?.hour === timeslot.hour
    );
  };

  return (
    <TimeSlotCalendarDayContainer>
      <TimeSlotCalendarDayHeader>
        <TimeSlotCalendarDayHeaderTitle>
          {`${day.charAt(0).toUpperCase()}${day.slice(1)} `}
        </TimeSlotCalendarDayHeaderTitle>
        <TimeSlotCalendarDayHeaderDate>
          {`${!_.isUndefined(dayDates) && currentDay?.format('DD MMM')}`}
        </TimeSlotCalendarDayHeaderDate>
      </TimeSlotCalendarDayHeader>
      <TimeSlotTimeContainer>
        {generateTimesIntervals().map(hour => (
          <AppointmentTimeSlot
            day={currentDay?.format(DATE_FORMAT)}
            key={day.concat(hour.toString())}
            timeslotId={getAppointedTimeslotId({
              date: currentDay?.format(DATE_FORMAT),
              hour: hour,
            })}
            hour={hour}
            isAppointed={checkIsTimeslotAlreadyAppointed({
              date: currentDay?.format(DATE_FORMAT),
              hour: hour,
            })}
            isDisabled={
              checkIsTimeslotDisabled(hour) ||
              checkIsTimeslotInAbsence({
                date: currentDay?.format(DATE_FORMAT),
                hour: hour,
              })
            }
            isSelected={checkIsTimeslotSelected({
              date: currentDay?.format(DATE_FORMAT),
              hour: hour,
            })}
            disableSelection={!shouldSelected}
            handleSelectTime={() =>
              handleUpdateTimeSlots({
                date: currentDay.format(DATE_FORMAT),
                hour: hour,
              })
            }
            resetData={resetData}
          />
        ))}
      </TimeSlotTimeContainer>
    </TimeSlotCalendarDayContainer>
  );
};

export default AppointmentDay;
