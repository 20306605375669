export interface IRoleItem {
  id?: number;
  code: string;
  label: string;
}
export enum ROLES {
  ADMIN = 'ROLE_ADMIN',
  PERS_ADMIN = 'ROLE_PERS_ADMIN',
  MEDECIN = 'ROLE_MEDECIN',
  INFIRMIER = 'ROLE_INFIR',
  LABO = 'ROLE_LABO',
  MANAGER = 'ROLE_MANAGER',
  FACTURATION = 'ROLE_FACTURE',
  CAISSE = 'ROLE_CAISSE',
  HOSPI = 'ROLE_HOSP',
  PHARMACIE = 'ROLE_PHARMA',
}

export type GetRolesResponse = Array<IRoleItem>;
export type CreateRoleRequestBody = IRoleItem & Omit<IRoleItem, 'id'>;
export type UpdateUserRolesRequestBody = {
  idUser: number;
  idRolesToAdd: number[];
  idRolesToRemove: number[];
};
export type UpdateRightsFromRoleRequestBody = {
  idRole: number;
  idRights: number[];
};
