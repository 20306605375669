/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'antd';
import _ from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTvaApi } from '../../api/invoiceService';
import { getConnectedUserData, login } from '../../api/loginService';
import { getSiteInfo } from '../../api/siteService';
import Space from '../../components/Space';
import { StyledButton } from '../../components/button/style';
import {
  StyledCol,
  StyledInput,
  StyledPasswordInput,
  StyledRow,
} from '../../components/style';
import SuccessOrErrorMessage from '../../components/successErrorMessage';
import chartColors from '../../styles/color';
import { StorageType } from '../../types';
import { saveInStorage } from '../../utils/storage';
import { StyledConnexionLabel, StyledLoginFormContainer } from '../style';

type FormPropertiesProps = {
  username: string;
  password: string;
};

const LoginForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<FormPropertiesProps>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const getToken = async () => {
    try {
      await form.validateFields().then(async row => {
        setIsLoading(true);
        const result = await login({ username: row.username, pwd: row.password });
        if (!saveInStorage(StorageType.SS, 'jwt', result)) {
          throw Error('Erreur inattendue lors de la connexion');
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const jwt0: string = sessionStorage.getItem('jwt')!;
        const initialValue = JSON.parse(jwt0);
        const jwt: string = initialValue.jwt;

        const userDataResult = await getConnectedUserData(
          { username: row.username },
          jwt,
        );
        if (!saveInStorage(StorageType.SS, 'connectedUserData', userDataResult)) {
          throw Error(
            `Erreur inattendue lors de la recupération des données de l'utilisateur connecté`,
          );
        }

        const siteData = await getSiteInfo();
        const TVAData = await getTvaApi();

        if (!saveInStorage(StorageType.SS, 'siteData', siteData)) {
          throw Error(`Erreur inattendue lors de la recupération des données du site`);
        }
        if (!saveInStorage(StorageType.LS, 'tva', TVAData.value)) {
          throw Error(`Erreur inattendue lors de la recupération de la TVA`);
        }
        setIsLoading(false);
        navigate('/consulter-dossier');
      });
    } catch (error: any) {
      if (_.isUndefined(error.values)) {
        setError(`${error}`);
        setIsLoading(false);
      }
    }
  };
  return (
    <>
      {error && (
        <>
          <SuccessOrErrorMessage type='error' text={error} />
        </>
      )}

      <StyledLoginFormContainer onKeyUp={e => (e.key === 'Enter' ? getToken() : null)}>
        <Form
          autoComplete='off'
          onFinish={getToken}
          disabled={isLoading}
          form={form}
          component={false}
          initialValues={{ nationality: 1 }}>
          <StyledRow gutter={[16, 16]}>
            <StyledCol span={24}>
              <StyledConnexionLabel htmlFor='username'>Identifiant</StyledConnexionLabel>
              <Form.Item
                style={{ margin: 0 }}
                name='username'
                rules={[
                  { required: true, message: 'Identifiant obligatoire' },
                  { whitespace: false },
                ]}>
                <StyledInput size='large' placeholder='Entrez votre identifiant' />
              </Form.Item>
            </StyledCol>
            <StyledCol span={24}>
              <StyledConnexionLabel htmlFor='password'>Mot de passe</StyledConnexionLabel>
              <Form.Item
                style={{ margin: 0 }}
                name='password'
                rules={[
                  { required: true, message: 'Mot de passe obligatoire' },
                  { whitespace: false },
                ]}>
                <StyledPasswordInput
                  size='large'
                  placeholder='Entrez votre mot de passe'
                />
              </Form.Item>
            </StyledCol>
            <StyledCol span={24}>
              <Form.Item>
                <Space marginTop='4rem' />
                <StyledButton
                  loading={isLoading}
                  size='large'
                  htmlType='submit'
                  block
                  onClick={() => getToken()}
                  style={{
                    backgroundColor: chartColors.blue,
                    fontWeight: 'bold',
                    boxShadow: `0px 0px 40px ${chartColors.blue}AA`,
                    color: chartColors.white,
                    height: '50px',
                  }}>
                  Connexion
                </StyledButton>
              </Form.Item>
            </StyledCol>
          </StyledRow>
        </Form>
      </StyledLoginFormContainer>
    </>
  );
};

export default LoginForm;
