import { Avatar } from 'antd';
import React from 'react';
import { BsHouseFill, BsPen } from 'react-icons/bs';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import chartColors from '../../../styles/color';
import { ISite, ReferentialViewProps } from '../../../types';
import { getDataFromStorage } from '../../../utils/storage';

type SiteInfosProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  updateCurrentItem: (p: ISite) => void;
};

const SiteInfos: React.FC<SiteInfosProps> = ({ navigateToview, updateCurrentItem }) => {
  const currentSiteData = getDataFromStorage<ISite>('siteData');
  const renderHeader = () => {
    return (
      <SectionHeader title='Infos du site'>
        <StyledButton icon={<BsPen />} type='primary' onClick={() => handleUpdate()}>
          Modifier
        </StyledButton>
      </SectionHeader>
    );
  };

  const handleUpdate = () => {
    updateCurrentItem(currentSiteData);
    navigateToview('EDIT_ITEM');
  };

  const renderContent = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '150px',
            backgroundColor: chartColors.blue50,
            borderRadius: 10,
            gap: '20px',
          }}>
          <Avatar shape='square' src={currentSiteData.url} size={150} />
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: '-10px',
              justifyContent: 'flex-end',
              padding: '10px 0px',
            }}>
            <h1 style={{ fontWeight: 600, color: chartColors.deepBlue, marginBottom: 0 }}>
              {currentSiteData.name}
            </h1>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 8,
              }}>
              <BsHouseFill color={chartColors.deepBlue} />
              <div style={{ fontSize: '1.2em' }}>{currentSiteData.address}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};

export default SiteInfos;
