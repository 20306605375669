import { App as AppContainer, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { FiEye } from 'react-icons/fi';
import {
  getImageryResultsByPrescriptionLineApi,
  searchImageryPrescriptionsApi,
} from '../../../api/prescriptionService';
import { StyledButton } from '../../../components/button/style';
import { StyledCol, StyledRow, StyledSpace, StyledTag } from '../../../components/style';
import ImageryResultDrawer from '../../../components/viewer/ImageryResultDrawer';
import chartColors from '../../../styles/color';
import {
  CreateMedicalRecordApiResponse,
  IImageryPrescriptionItem,
  ImageryPrescriptionLine,
  ImageryResultsPrescriptionLineResponse,
  SearchImageryPrescriptionResponse,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle } from '../../../utils/constants';
import { getDataFromStorage } from '../../../utils/storage';

const IrmExams = () => {
  const medicalRecord =
    getDataFromStorage<CreateMedicalRecordApiResponse>('medicalRecord');
  const { notification } = AppContainer.useApp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageryPrescriptionsData, setImageryPrescriptionsData] =
    useState<SearchImageryPrescriptionResponse>([]);
  const [selectedPrescriptionLine, setSelectedPrescriptionLine] =
    useState<ImageryPrescriptionLine>();
  const [isLoadingResult, setIsLoadingResult] = useState<boolean>(false);
  const [showResultDrawer, setShowResultDrawer] = useState<boolean>(false);
  const [results, setResults] = useState<ImageryResultsPrescriptionLineResponse>([]);

  const loadPatientImageryPrescriptions = async () => {
    setIsLoading(true);
    Promise.resolve(
      searchImageryPrescriptionsApi({
        idMedicalRecord: medicalRecord?.id as number,
        idPatient: medicalRecord.patientDto.id as number,
        paid: null,
      }),
    )
      .then(result => {
        setImageryPrescriptionsData(result);
        setIsLoading(false);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadImageryResultsByPrescriptionLine = async (idPrescriptionLine: number) => {
    setIsLoadingResult(true);
    Promise.resolve(getImageryResultsByPrescriptionLineApi(idPrescriptionLine))
      .then(res => {
        setResults(res);
      })
      .finally(() => {
        setIsLoadingResult(false);
      });
  };

  const handleShowPrescriptionLineResult = (record: ImageryPrescriptionLine) => {
    setSelectedPrescriptionLine(record);
    loadImageryResultsByPrescriptionLine(record.id).then(() => setShowResultDrawer(true));
  };

  const handleCloseViewer = () => {
    setShowResultDrawer(false);
  };

  useEffect(() => {
    loadPatientImageryPrescriptions();
  }, []);

  const imageryPrescriptionColumnData: ColumnsType<IImageryPrescriptionItem> = [
    {
      title: 'Numéro',
      dataIndex: 'id',
      className: 'prescription_exam_header',
      render: (value, record) => (
        <StyledSpace>
          <span
            style={{
              fontFamily: 'Metropolis',
              textTransform: 'uppercase',
              color: chartColors.blue,
              fontWeight: 700,
            }}>
            Prescription {`#${value}`}
          </span>
          <StyledTag color={chartColors.blue}>Créé le {record.createdDate}</StyledTag>
        </StyledSpace>
      ),
    },
  ];

  const imageryExamscolumn: ColumnsType<ImageryPrescriptionLine> = [
    {
      title: 'Examen',
      dataIndex: 'imageryExamDesignation',
    },
    {
      title: 'Résultats',
      align: 'center',
      width: 400,
      dataIndex: 'comments',
      className: 'imagery_exam_result',
      render: value => value ?? '',
    },
    {
      title: 'Statut',
      dataIndex: 'done',
      align: 'center',
      width: 100,
      render: (value, record) => {
        return record.done ? (
          <StyledTag color='success'>Effectuée</StyledTag>
        ) : (
          <StyledTag color='warning'>En attente</StyledTag>
        );
      },
    },

    {
      title: 'Effectué le',
      dataIndex: 'doneDate',
      align: 'left',
      width: 100,
    },
    {
      title: 'Actions',
      align: 'left',
      width: 100,
      render: (_value, record) => {
        return (
          <StyledSpace>
            <Tooltip
              title={
                _.isEmpty(record.results)
                  ? 'Aucun résultat disponible'
                  : 'Voir les résultats'
              }>
              <StyledButton
                icon={<FiEye />}
                shape='circle'
                size='middle'
                loading={isLoadingResult}
                disabled={isLoadingResult || _.isEmpty(record.results)}
                onClick={() => handleShowPrescriptionLineResult(record)}
              />
            </Tooltip>
          </StyledSpace>
        );
      },
    },
  ];

  const renderImageryPrescriptions = () => {
    return (
      <div>
        <StyledRow gutter={[20, 20]}>
          <StyledCol span={24}>
            <Table<IImageryPrescriptionItem>
              rowKey='id'
              showHeader={false}
              dataSource={imageryPrescriptionsData}
              size='small'
              columns={imageryPrescriptionColumnData}
              pagination={false}
              bordered={false}
              loading={isLoading}
              expandable={{
                expandedRowRender: record => (
                  <Table
                    rowKey='id'
                    bordered={false}
                    pagination={false}
                    columns={imageryExamscolumn}
                    dataSource={record.prescriptionLineImageryDto}
                  />
                ),
                defaultExpandAllRows: true,
              }}
            />
          </StyledCol>
        </StyledRow>
      </div>
    );
  };

  const renderDisplayResultDrawer = () => {
    return (
      <ImageryResultDrawer
        files={results}
        drawerTitle={`Résultats d'imagerie - ${selectedPrescriptionLine?.imageryExamDesignation} `}
        closeDrawer={() => setShowResultDrawer(false)}
        extraActions={
          <>
            <StyledButton
              key='close_display_result_drawer'
              type='primary'
              size='middle'
              danger
              disabled={isLoading}
              onClick={() => handleCloseViewer()}>
              Fermer
            </StyledButton>
          </>
        }
        isDrawerOpen={showResultDrawer}
      />
    );
  };

  return (
    <>
      {renderImageryPrescriptions()}
      {showResultDrawer && renderDisplayResultDrawer()}
    </>
  );
};

export default IrmExams;
