import { useState } from 'react';
import FunctionalSignForm from './FunctionalSignForm';
import FunctionalSignsList from './FunctionalSignsList';
import { IFunctionalSignItem, ReferentialViewProps } from '../../../types';

const FunctionalSignsRef = () => {
  const [activeView, setActiveView] = useState<ReferentialViewProps>('LIST_ITEMS');
  const [selectedItem, setSelectedItem] = useState<IFunctionalSignItem>();

  const handleNavigateToView = (newView: ReferentialViewProps) => {
    setActiveView(newView);
  };

  const handleUpdateSelectedItem = (item?: IFunctionalSignItem) => {
    setSelectedItem(item);
  };

  const renderContent = () => {
    switch (activeView) {
      case 'LIST_ITEMS':
        return (
          <FunctionalSignsList
            navigateToview={handleNavigateToView}
            updateCurrentItem={handleUpdateSelectedItem}
          />
        );
      case 'EDIT_ITEM':
        return (
          <FunctionalSignForm
            navigateToview={handleNavigateToView}
            currentData={selectedItem}
          />
        );
      case 'NEW_ITEM':
        return <FunctionalSignForm navigateToview={handleNavigateToView} />;
    }
  };

  return <>{renderContent()}</>;
};

export default FunctionalSignsRef;
