import { CreateDiagnosticAction, CreateDiagnosticState } from './types';

export const getCreateDiagnosticDefaultInitialState = (): CreateDiagnosticState => {
  return {
    diagnosticComment: '',
    diagnostics: [],
    diagnosticDate: '',
    invalidInputs: [],
  } as CreateDiagnosticState;
};

export const CreateDiagnosticReducer = (
  state: CreateDiagnosticState,
  action: CreateDiagnosticAction,
) => {
  const newState = { ...state };
  switch (action.type) {
    case 'setCommentDiagnostic':
      newState.diagnosticComment = action.payload;
      return newState;
    case 'setDiagnostics':
      newState.diagnostics = action.payload;
      return newState;
    case 'setCreatedDateDiagnostic':
      newState.diagnosticDate = action.payload;
      return newState;
    case 'setInvalidInputs':
      newState.invalidInputs = action.payload;
      return newState;
    default:
      return state;
  }
};
