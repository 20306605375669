import { App as AppContainer } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import _ from 'lodash';
import { useEffect, useReducer, useState } from 'react';
import {
  getInvoiceRubricsApi,
  getRubricsByInvoicePlanApi,
  updateRubricsIntoInvoicePlanApi,
} from '../../../api/invoiceService';
import SectionHeader from '../../../components/header/SectionHeader';
import Loader from '../../../components/loader';
import { StyledTag } from '../../../components/style';
import { UpdateRubricsIntoInvoicePlanState } from '../../../reducers/invoicePlan/types';
import {
  UpdateRubricsIntoInvoicePlanReducer,
  getUpdateRubricsIntoInvoicePlanDefaultInitialState,
} from '../../../reducers/invoicePlan/updateRubricsIntoInvoicePlan';
import {
  IInvoiceRubricItem,
  InvoicePlanDataProps,
  UpdateRubricsIntoInvoicePlanRequestBody,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import AddRemoveRubric from './InvoiceRubricSetting/AddRemoveRubric';

interface RubricsProps {
  selectedInvoicePlan: InvoicePlanDataProps;
  resetSelectedPlan: () => void;
}

const InvoicePlanRubrics: React.FC<RubricsProps> = ({
  selectedInvoicePlan,
  resetSelectedPlan,
}) => {
  const [state, reducer] = useReducer(
    UpdateRubricsIntoInvoicePlanReducer,
    getUpdateRubricsIntoInvoicePlanDefaultInitialState(),
  );

  const [invoicePlanRubricsData, setInvoicePlanRubricsData] = useState<
    IInvoiceRubricItem[]
  >([]);

  const { notification } = AppContainer.useApp();
  const [rubricList, setRubricList] = useState<IInvoiceRubricItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const invoicePlanRubricsColumns: ColumnsType<IInvoiceRubricItem> = [
    {
      title: '#',
      dataIndex: 'id',
      render: (value, rrecord, index) => <>{index + 1}</>,
    },
    {
      title: 'Libellé',
      dataIndex: 'label',
    },
    {
      title: 'Montant classique',
      align: 'center',
      children: [
        {
          title: 'Jour Ouvrable',
          dataIndex: 'classique_amount_JO',
          align: 'center',
        },
        {
          title: 'Dimanche, Nuit et Férié',
          dataIndex: 'classique_amount_D_N_JF',
          align: 'center',
        },
      ],
    },
    {
      title: 'Montant Mutualiste',
      align: 'center',
      children: [
        {
          title: 'Jour Ouvrable',
          dataIndex: 'mutualiste_amount_JO',
          align: 'center',
        },
        {
          title: 'Dimanche, Nuit et Férié',
          dataIndex: 'mutualiste_amount_D_N_JF',
          align: 'center',
        },
      ],
    },
    {
      title: 'Etat',
      align: 'center',
      dataIndex: 'active',
      render: value =>
        value === true ? (
          <StyledTag color='green'>Actif</StyledTag>
        ) : (
          <StyledTag color='volcano'>Inactif</StyledTag>
        ),
    },
    {
      title: 'Date de création',
      dataIndex: 'createdDate',
    },
  ];

  const loadInvoiceRubrics = async () => {
    Promise.resolve(getInvoiceRubricsApi())
      .then(result => {
        setRubricList(result);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadRubricsByInvoicePlan = (invoicePlanId: number) => {
    setIsLoading(true);
    Promise.resolve(getRubricsByInvoicePlanApi(invoicePlanId))
      .then(result => {
        setInvoicePlanRubricsData(result);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadInvoiceRubrics();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(selectedInvoicePlan)) {
      loadRubricsByInvoicePlan(selectedInvoicePlan.id);
    }
  }, [selectedInvoicePlan]);

  const mapStateToRequestBody = (
    state: UpdateRubricsIntoInvoicePlanState,
  ): UpdateRubricsIntoInvoicePlanRequestBody => {
    console.log(state.rubrics);
    const result = {
      idInvoicePlan: selectedInvoicePlan.id,
      idRubrics: state.rubrics,
    } as UpdateRubricsIntoInvoicePlanRequestBody;
    return result;
  };

  const updateRubricsIntoInvoicePlan = async () => {
    try {
      setIsLoading(true);
      await updateRubricsIntoInvoicePlanApi(
        selectedInvoicePlan.id,
        mapStateToRequestBody(state),
      ).then(() => {
        notification.success({
          message: SuccesssMsgTitle,
          description: 'Les rubriques ont été mises à jour.',
        });
        loadRubricsByInvoicePlan(selectedInvoicePlan.id);
      });
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SectionHeader
        title={`Rubriques Plan ${selectedInvoicePlan.label}`}
        count={invoicePlanRubricsData.length}
        onBack={() => resetSelectedPlan()}>
        <AddRemoveRubric
          reducer={reducer}
          listTitle={`Rubriques ${selectedInvoicePlan.label}`}
          value={invoicePlanRubricsData}
          items={rubricList}
          reducerTypeLabel='setRubricsInvoicePlan'
          handleUpdate={updateRubricsIntoInvoicePlan}
          isLoading={isLoading}
        />
      </SectionHeader>
      <Loader showSpinner={isLoading}>
        <Table<IInvoiceRubricItem>
          rowKey='id'
          dataSource={invoicePlanRubricsData}
          size='small'
          columns={invoicePlanRubricsColumns}
          bordered={false}
        />
      </Loader>
    </>
  );
};

export default InvoicePlanRubrics;
