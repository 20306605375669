import React, { useState } from 'react';
import { BiologicalAnalysisSubCategoryDto, ReferentialViewProps } from '../../../types';
import BiologicalExamSubCategorieForm from './BiologicalExamSubCategorieForm';
import BiologicalExamSubCategoriesList from './BiologicalExamSubCategoriesList';

const BiologicalExamSubCategoriesRef: React.FC = () => {
  const [activeView, setActiveView] = useState<ReferentialViewProps>('LIST_ITEMS');
  const [selectedCategory, setSelectedCategory] =
    useState<BiologicalAnalysisSubCategoryDto>();

  const handleNavigateToView = (newView: ReferentialViewProps) => {
    setActiveView(newView);
  };

  const handleUpdateSelectedCategorie = (
    categorie?: BiologicalAnalysisSubCategoryDto,
  ) => {
    setSelectedCategory(categorie);
  };

  const renderContent = () => {
    switch (activeView) {
      case 'LIST_ITEMS':
        return (
          <BiologicalExamSubCategoriesList
            navigateToview={handleNavigateToView}
            updateCurrentItem={handleUpdateSelectedCategorie}
          />
        );
      case 'EDIT_ITEM':
        return (
          <BiologicalExamSubCategorieForm
            navigateToview={handleNavigateToView}
            currentData={selectedCategory}
          />
        );
      case 'NEW_ITEM':
        return <BiologicalExamSubCategorieForm navigateToview={handleNavigateToView} />;
    }
  };

  return <>{renderContent()}</>;
};

export default BiologicalExamSubCategoriesRef;
