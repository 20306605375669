import { RecievePharmacyArticleAction, RecievePharmacyArticleState } from './types';

export const getRecieveArticlesDefaultInitialState = (): RecievePharmacyArticleState => {
  return {
    articles: [],
    invalidInputs: [],
  } as RecievePharmacyArticleState;
};

export const RecieveArticlesReducer = (
  state: RecievePharmacyArticleState,
  action: RecievePharmacyArticleAction,
) => {
  const newState = { ...state };
  switch (action.type) {
    case 'setArticlesToRecieve':
      newState.articles = action.payload;
      return newState;

    case 'setQuantityArticleQteToRecieve': {
      const orderLineId = action.payload.id;
      const qte = action.payload.value;

      return {
        ...state,
        articles: state.articles.map(r =>
          r.id === orderLineId ? { ...r, quantityToReceive: qte } : r,
        ),
      };
    }

    default:
      return state;
  }
};
