import { useMediaQuery } from 'react-responsive';
import MobileHeader from './MobileHeader';
import UserHeader from './UserHeader';
import { StyledHeaderWrapper } from './style';

const Header: React.FC = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' });

  return (
    <StyledHeaderWrapper>
      {isTabletOrMobile ? <MobileHeader /> : <div></div>}
      <UserHeader />
    </StyledHeaderWrapper>
  );
};

export default Header;
