/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer, Avatar, Form, UploadFile } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { createSiteApi, getSiteInfo, updateSiteApi } from '../../../api/siteService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import {
  StyledCol,
  StyledDivider,
  StyledInput,
  StyledRow,
  StyledSpace,
} from '../../../components/style';
import StyledUploader from '../../../components/uploader';
import {
  CreateSiteRequestBody,
  ISite,
  ReferentialViewProps,
  StorageType,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { removeDataFromStorage, saveInStorage } from '../../../utils/storage';
import { StyledBlocContainer } from '../../style';

type SiteFormProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  currentData?: ISite;
};

type FormPropertiesProps = {
  id: number;
  name: string;
  address: string;
  files: UploadFile[];
};

const SiteForm: React.FC<SiteFormProps> = ({ navigateToview, currentData }) => {
  const { notification } = AppContainer.useApp();
  const [form] = Form.useForm<FormPropertiesProps>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const renderHeader = () => {
    return (
      <SectionHeader
        title={_.isUndefined(currentData) ? 'Site' : 'Modification Infos Site'}
        onBack={() => navigateToview('LIST_ITEMS')}
      />
    );
  };

  const mapPropsToRequest = (row: FormPropertiesProps) => {
    const siteData: CreateSiteRequestBody = {
      name: row.name,
      address: row.address,
      logo: row.files,
      currentSiteData: currentData as ISite,
    };

    return siteData;
  };

  const createSite = async () => {
    try {
      await form.validateFields().then(async row => {
        setIsLoading(true);
        await createSiteApi(mapPropsToRequest(row)).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `${row.name} a été enregistré!`,
          });
          navigateToview('LIST_ITEMS');
        });
      });
    } catch (error: any) {
      if (_.isUndefined(error.values)) {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const editSite = async () => {
    try {
      await form.validateFields().then(async row => {
        setIsLoading(true);
        await updateSiteApi(currentData?.id as number, mapPropsToRequest(row)).then(
          () => {
            notification.success({
              message: SuccesssMsgTitle,
              description: `Le site a été modifié!`,
            });

            getSiteInfo().then(result => {
              removeDataFromStorage(StorageType.SS, 'siteData');
              if (saveInStorage(StorageType.SS, 'siteData', result)) {
                navigateToview('LIST_ITEMS');
              }
            });
          },
        );
      });
    } catch (error) {
      console.log(error);

      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    return (
      <StyledBlocContainer style={{ padding: 20 }}>
        <Form
          disabled={isLoading}
          form={form}
          component={false}
          initialValues={{
            name: _.isUndefined(currentData) ? '' : currentData.name,
            address: _.isUndefined(currentData) ? '' : currentData.address,
          }}>
          <StyledRow gutter={[16, 16]} align='middle' justify='center'>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Nom du site' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='name'
                rules={[
                  { required: true, message: 'Nom du site obligatoire' },
                  { whitespace: false },
                ]}>
                <StyledInput size='large' placeholder='Nom du site' />
              </Form.Item>
            </StyledCol>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Adresse du site' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='address'
                rules={[
                  { required: true, message: 'Adresse du site obligatoire' },
                  { whitespace: false },
                ]}>
                <StyledInput size='large' placeholder='Adresse du site' />
              </Form.Item>
            </StyledCol>

            <StyledCol span={20}>
              <FormFieldLabel libelle='Logo du site' />
              <div style={{ marginBottom: 10 }}>
                <Form.Item name='files'>
                  <StyledUploader
                    maxFileNumber={1}
                    acceptedFiles={['PNG', 'JPEG']}
                    callback={uploadedFiles => form.setFieldValue('files', uploadedFiles)}
                  />
                </Form.Item>
              </div>
            </StyledCol>

            <StyledCol span={4}>
              <Avatar shape='square' src={currentData?.url} size={160} />
            </StyledCol>
            <StyledDivider />
            <StyledCol span={24}>
              <StyledSpace>
                <StyledButton
                  type='primary'
                  htmlType='submit'
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={_.isUndefined(currentData) ? createSite : editSite}>
                  {_.isUndefined(currentData)
                    ? 'Enregistrer le site'
                    : 'Modifier le site'}
                </StyledButton>
              </StyledSpace>
            </StyledCol>
          </StyledRow>
        </Form>
      </StyledBlocContainer>
    );
  };

  return (
    <>
      {renderHeader()}
      <StyledDivider />
      {renderContent()}
    </>
  );
};

export default SiteForm;
