/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer, Form } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createAppointmentApi } from '../../api/appointmentService';
import { getPatients } from '../../api/patientService';
import { StyledButton } from '../../components/button/style';
import { StyledTitle } from '../../components/header/style';
import CustomPhoneInput from '../../components/input/CustomPhoneInput';
import FormFieldLabel from '../../components/label/FormFieldLabel';
import Loader from '../../components/loader';
import PageHeader from '../../components/pageHeader/PageHeader';
import {
  StyledCol,
  StyledDatePicker,
  StyledDivider,
  StyledInput,
  StyledRow,
  StyledSelectOption,
  StyledSelectPicker,
  StyledSpace,
} from '../../components/style';
import dayjsInstance from '../../dayjs';
import PageContentLeftLayout from '../../layout/PageContentLeftLayout';
import PageContentRightLayout from '../../layout/PageContentRightLayout';
import VerticalLayout from '../../layout/VerticalLayout';
import { StyledLayoutGridWrapper } from '../../layout/style';
import chartColors from '../../styles/color';
import {
  CreateAppointmentRequestBody,
  GetPatientApiResponse,
  IUserItem,
  PatientDto,
  StorageType,
  TimeSlotProps,
} from '../../types';
import { getApiErrorMsg, mapStateToRequestBody } from '../../utils/commun';
import { DATE_FORMAT, ErrorMsgTitle, SuccesssMsgTitle } from '../../utils/constants';
import { getDataFromStorage, removeDataFromStorage } from '../../utils/storage';
import { StyledBlocContainer } from '../style';
import { Dayjs } from 'dayjs';

type PatientFormProps = {
  namePatient: string;
  firstnamePatient: string;
  birthDayPatient: Dayjs;
  telephonePatient: string;
};

const AppointmentContainer: React.FC = () => {
  const [patientForm] = Form.useForm<PatientFormProps>();
  const { notification } = AppContainer.useApp();
  const navigate = useNavigate();
  const appointmentData = getDataFromStorage<{
    medecin: IUserItem;
    appointmentDay: TimeSlotProps;
  }>('appointmentData');
  const [isLoadingPatient, setIsLoadingPatient] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patientsList, setPatientsList] = useState<GetPatientApiResponse[]>([]);
  const [selectedPatient, setSelectedPatient] = useState<number | null>(null);
  const [currentPatient, setCurrentPatient] = useState<PatientDto | null>(null);
  const [selectManualPatient, setSelectManualPatient] = useState<boolean>(false);

  const loadPatients = () => {
    setIsLoadingPatient(true);
    Promise.resolve(getPatients())
      .then(result => {
        setPatientsList(result);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsLoadingPatient(false);
      });
  };

  useEffect(() => {
    loadPatients();
  }, []);

  const handleResetSelectedMedicalRecord = () => {
    setSelectedPatient(null);
    setCurrentPatient(null);
  };

  const handleCreateAppointment = () => {
    try {
      setIsLoading(true);
      Promise.resolve(
        createAppointmentApi(
          mapStateToRequestBody<CreateAppointmentRequestBody>({
            firstnamePatient: currentPatient?.firstname as string,
            namePatient: currentPatient?.name as string,
            birthDayPatient: currentPatient?.birthDate as string,
            idPatient: currentPatient?.id as number,
            timeSlot: appointmentData.appointmentDay,
            idMedecin: appointmentData.medecin.id as number,
            telephonePatient: currentPatient?.phoneNumber as string,
          }),
        ),
      ).then(() => {
        notification.success({
          message: SuccesssMsgTitle,
          description: 'Le rendez-vous a bien été enregistré',
        });
        setIsLoading(false);
        navigate('/rendez-vous', { replace: true });
        removeDataFromStorage(StorageType.LS, 'appointmentData');
      });
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
      setIsLoading(false);
    }
  };

  const handleCreateAppointmentManually = async () => {
    try {
      await patientForm.validateFields().then(async row => {
        setIsLoading(true);
        Promise.resolve(
          createAppointmentApi(
            mapStateToRequestBody<CreateAppointmentRequestBody>({
              firstnamePatient: row.firstnamePatient,
              namePatient: row.namePatient,
              birthDayPatient: row.birthDayPatient?.format(DATE_FORMAT),
              telephonePatient: row.telephonePatient,
              timeSlot: appointmentData.appointmentDay,
              idMedecin: appointmentData.medecin.id as number,
            }),
          ),
        ).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: 'Le rendez-vous a bien été enregistré',
          });
          setIsLoading(false);
          navigate('/rendez-vous', { replace: true });
          removeDataFromStorage(StorageType.LS, 'appointmentData');
        });
      });
    } catch (error: any) {
      if (_.isUndefined(error.values)) {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      }
    }
  };

  const handleManualPatient = () => {
    setSelectManualPatient(state => !state);
    if (selectManualPatient === true) {
      patientForm.resetFields();
    } else {
      handleResetSelectedMedicalRecord();
    }
  };

  const renderHeader = () => {
    return (
      <>
        <PageHeader title='Prise de rendez-vous' showBack />
      </>
    );
  };

  // const EmptyPatientContent = (
  //   <div
  //     style={{
  //       display: 'flex',
  //       flexDirection: 'column',
  //       alignItems: 'center',
  //       justifyContent: 'center',
  //       fontFamily: 'Metropolis, sans-serif',
  //       rowGap: 5,
  //       padding: '15px 0px',
  //     }}>
  //     <FaUserAltSlash size={60} />
  //     <div style={{ fontWeight: 600, color: chartColors.deepBlue }}>
  //       Aucun patient n&apos;a été trouvé
  //     </div>
  //     <StyledButton type='primary' onClick={handleManualPatient}>
  //       Ajouter un patient
  //     </StyledButton>
  //   </div>
  // );

  // Selecteur de patient
  const renderPatientSelector = () => {
    return (
      <Loader showSpinner={isLoadingPatient}>
        <StyledBlocContainer
          style={{
            backgroundColor: `${chartColors.blue}20`,
            padding: '20px 20px',
            boxShadow: '0px 5px 10px #00000030',
          }}>
          <StyledRow gutter={[16, 16]}>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Patient à programmer' />
              <StyledSelectPicker
                placeholder='Rechercher par nom, CNI ou Numéro patient'
                onClear={() => handleResetSelectedMedicalRecord()}
                style={{ width: '100%' }}
                allowClear
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                onChange={(value, option: DefaultOptionType['data']) => {
                  setSelectedPatient(value as number);
                  setCurrentPatient(option?.data ?? null);
                }}
                defaultValue={''}
                value={selectedPatient}>
                {_.map(patientsList, item => (
                  <StyledSelectOption
                    key={item.id}
                    value={item.id}
                    data={item}
                    label={`${item.firstname} ${item.name} | ${item.cni} | ${item.numPatient}`}>
                    <StyledSpace split='|'>
                      <span style={{ fontWeight: 700 }}>{item.numPatient}</span>
                      {`${item.firstname} ${item.name}`}
                      <span style={{ fontWeight: 700 }}>{item.cni}</span>
                    </StyledSpace>
                  </StyledSelectOption>
                ))}
              </StyledSelectPicker>
            </StyledCol>
            <StyledCol>
              <StyledButton type='primary' onClick={handleManualPatient} ghost>
                Saisir les informations du patient
              </StyledButton>
            </StyledCol>
          </StyledRow>
        </StyledBlocContainer>
      </Loader>
    );
  };

  // Information Manuelle du patient
  const renderPatientForm = () => {
    return (
      <StyledBlocContainer
        style={{
          backgroundColor: `${chartColors.blue}20`,
          padding: '20px 20px',
          boxShadow: '0px 5px 10px #00000030',
        }}>
        <Form disabled={isLoading} form={patientForm} component={false}>
          <StyledTitle>Informations du patient</StyledTitle>
          <StyledRow gutter={[16, 16]}>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Nom du patient' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='namePatient'
                rules={[
                  { required: true, message: 'Nom obligatoire' },
                  { whitespace: false },
                ]}>
                <StyledInput size='large' placeholder='Nom du patient' />
              </Form.Item>
            </StyledCol>

            <StyledCol span={24}>
              <FormFieldLabel libelle='Prénoms du patient' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='firstnamePatient'
                rules={[
                  { required: true, message: 'Prénoms obligatoire' },
                  { whitespace: false },
                ]}>
                <StyledInput size='large' placeholder='Prénoms du patient' />
              </Form.Item>
            </StyledCol>

            <StyledCol span={24}>
              <FormFieldLabel libelle='Numéro de téléphone' isRequired={true} />
              <Form.Item
                style={{ margin: 0 }}
                name='telephonePatient'
                rules={[
                  {
                    required: true,
                    message: 'Numéro de téléphone obligatoire',
                  },
                  { whitespace: false },
                ]}
                valuePropName='value'>
                <CustomPhoneInput
                  placeholder='Numéro de téléphone'
                  value={patientForm.getFieldValue('telephonePatient')}
                  onChange={val => {
                    patientForm.setFieldValue('telephonePatient', val);
                  }}
                />
              </Form.Item>
            </StyledCol>

            <StyledCol span={24}>
              <FormFieldLabel libelle='Date de naissance' isRequired />
              <Form.Item
                style={{ margin: 0 }}
                name='birthDayPatient'
                rules={[
                  { required: true, message: 'Date de naissance obligatoire' },
                  { type: 'object', message: 'Format Incorrect' },
                ]}>
                <StyledDatePicker
                  placeholder='Date de naissance'
                  style={{ width: '100%' }}
                  disabledDate={current => dayjsInstance().isBefore(current, 'day')}
                  format={DATE_FORMAT}
                />
              </Form.Item>
            </StyledCol>
          </StyledRow>
          <StyledDivider />
          <StyledButton type='primary' onClick={handleManualPatient} ghost>
            Sélectionner un patient existant
          </StyledButton>
        </Form>
      </StyledBlocContainer>
    );
  };

  const renderContent = () => {
    return (
      <div style={{ padding: '20px' }}>
        <StyledRow gutter={[16, 16]}>
          <StyledCol span={24}>
            <div
              style={{
                backgroundColor: chartColors.lightGray,
                padding: '20px',
                borderRadius: 10,
              }}>
              <div>Date et Heure du rendez-vous</div>
              <span style={{ fontSize: '3rem', fontWeight: 'bold' }}>
                {`${appointmentData.appointmentDay?.date}  ${appointmentData.appointmentDay?.hour}`}
              </span>
            </div>
          </StyledCol>
          <StyledCol span={24}>
            <div
              style={{
                backgroundColor: chartColors.blue50,
                padding: '20px',
                borderRadius: 10,
              }}>
              <div>Médecin</div>
              <span style={{ fontSize: '3rem', fontWeight: 'bold' }}>
                {`${appointmentData.medecin?.level ?? ''} 
                ${appointmentData.medecin?.firstname ?? ''}
                ${appointmentData.medecin?.name ?? ''}`}
              </span>
            </div>
          </StyledCol>

          <StyledCol span={24}>
            <StyledButton
              type='primary'
              disabled={selectManualPatient === false && _.isNull(currentPatient)}
              onClick={
                selectManualPatient === false
                  ? handleCreateAppointment
                  : handleCreateAppointmentManually
              }>
              Enregistrer le rendez-vous
            </StyledButton>
          </StyledCol>
        </StyledRow>
      </div>
    );
  };

  return (
    <VerticalLayout enableVerticalScroll='false'>
      {renderHeader()}
      <StyledLayoutGridWrapper>
        <PageContentLeftLayout layerShowPadding='false'>
          {selectManualPatient === true ? renderPatientForm() : renderPatientSelector()}
        </PageContentLeftLayout>
        <PageContentRightLayout layerShowPadding='false'>
          <div style={{ overflowY: 'auto' }}>
            <Loader showSpinner={isLoading}>{renderContent()}</Loader>
          </div>
        </PageContentRightLayout>
      </StyledLayoutGridWrapper>
    </VerticalLayout>
  );
};

export default AppointmentContainer;
