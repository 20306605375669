import {
  GetEnterModeResponse,
  GetFunctionalSignsResponse,
  GetNationalityResponse,
  GetReasonsForConsultationResponse,
  GetRegionResponse,
  GetResidenceResponse,
  GetTreatmentstakenResponse,
  GetUnitResponse,
} from '../types';
import { axiosInstance } from './axiosInstance';

export const getNationality = (): Promise<GetNationalityResponse> => {
  return axiosInstance
    .get('/nationality')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des nationalités. Raison: ${message}`);
    });
};

export const getEnterMode = (): Promise<GetEnterModeResponse> => {
  return axiosInstance
    .get('/enterMode')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération des modes d'entrée. Raison: ${message}`,
      );
    });
};

export const getResidence = (): Promise<GetResidenceResponse> => {
  return axiosInstance
    .get('/placeOfResidence')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération des places de résidence. Raison: ${message}`,
      );
    });
};

export const getRegions = (): Promise<GetRegionResponse> => {
  return axiosInstance
    .get('/regions')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des regions. Raison: ${message}`);
    });
};

export const getReasonsForConsultation =
  (): Promise<GetReasonsForConsultationResponse> => {
    return axiosInstance
      .get('/reasonsForConsultation')
      .then(response => Promise.resolve(response.data))
      .catch(err => {
        const message = err?.response ? err.response.data?.error : `${err}`;
        throw Error(
          `Erreur lors de la recupération des motifs de consultations. Raison: ${message}`,
        );
      });
  };

export const getFunctionalSigns = (): Promise<GetFunctionalSignsResponse> => {
  return axiosInstance
    .get('/functionalSigns')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération des signes fonctionnels. Raison: ${message}`,
      );
    });
};

export const getTreatmentstaken = (): Promise<GetTreatmentstakenResponse> => {
  return axiosInstance
    .get('/treatmentsTaken')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération des traitements pris. Raison: ${message}`,
      );
    });
};

export const getUnits = (): Promise<GetUnitResponse[]> => {
  return axiosInstance
    .get('/units')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des unités. Raison: ${message}`);
    });
};
