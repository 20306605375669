/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer, Form } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { createRatioApi, updateRatioApi } from '../../../api/ratioService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import {
  StyledCol,
  StyledDivider,
  StyledInput,
  StyledInputNumber,
  StyledRow,
  StyledSpace,
} from '../../../components/style';
import { CreateRatioRequestBody, IRatioItem, ReferentialViewProps } from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { StyledBlocContainer } from '../../style';

type RatioFormProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  currentData?: IRatioItem;
};

type FormPropertiesProps = CreateRatioRequestBody;

const RatioForm: React.FC<RatioFormProps> = ({ navigateToview, currentData }) => {
  const { notification } = AppContainer.useApp();
  const [form] = Form.useForm<FormPropertiesProps>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const renderHeader = () => {
    return (
      <SectionHeader
        title={
          _.isUndefined(currentData) ? 'Nouveau Coefficient' : 'Modification Coefficient'
        }
        onBack={() => navigateToview('LIST_ITEMS')}
      />
    );
  };

  const mapPropsToRequest = (row: FormPropertiesProps) => {
    const itemData: CreateRatioRequestBody = {
      code: row.code,
      ratio: row.ratio,
    };

    return itemData;
  };

  const createItem = async () => {
    try {
      await form.validateFields().then(async row => {
        setIsLoading(true);
        Promise.resolve(createRatioApi(mapPropsToRequest(row))).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: `le coefficient a été enregistré!`,
          });
          navigateToview('LIST_ITEMS');
        });
      });
    } catch (error: any) {
      if (_.isUndefined(error.values)) {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const editItem = async () => {
    try {
      await form.validateFields().then(async row => {
        setIsLoading(true);
        await updateRatioApi(currentData?.id as number, mapPropsToRequest(row)).then(
          () => {
            notification.success({
              message: SuccesssMsgTitle,
              description: `Le coefficient a été modifié!`,
            });
            navigateToview('LIST_ITEMS');
          },
        );
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    return (
      <StyledBlocContainer style={{ padding: 20 }}>
        <Form
          disabled={isLoading}
          form={form}
          component={false}
          initialValues={{
            code: _.isUndefined(currentData) ? '' : currentData.code,
            ratio: _.isUndefined(currentData) ? 0 : currentData.ratio,
          }}>
          <StyledRow gutter={[16, 16]} align='middle' justify='center'>
            <StyledCol span={24}>
              <FormFieldLabel libelle='Code coefficient' />
              <Form.Item
                style={{ margin: 0 }}
                name='code'
                rules={[
                  { required: true, message: 'Code coeficient obligatoire' },
                  { whitespace: false },
                ]}>
                <StyledInput size='large' placeholder='Code coefficient' />
              </Form.Item>
            </StyledCol>

            <StyledCol span={24}>
              <FormFieldLabel libelle='Valeur' />
              <Form.Item
                style={{ margin: 0 }}
                name='ratio'
                rules={[
                  {
                    required: true,
                    message: 'Valeur coefficient obligatoire',
                  },
                ]}>
                <StyledInputNumber min={0} style={{ width: '100%' }} size='large' />
              </Form.Item>
            </StyledCol>

            <StyledDivider />
            <StyledCol span={24}>
              <StyledSpace>
                <StyledButton
                  type='primary'
                  htmlType='submit'
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={_.isUndefined(currentData) ? createItem : editItem}>
                  {_.isUndefined(currentData)
                    ? 'Enregistrer le coefficient'
                    : 'Modifier le coefficient'}
                </StyledButton>
              </StyledSpace>
            </StyledCol>
          </StyledRow>
        </Form>
      </StyledBlocContainer>
    );
  };

  return (
    <>
      {renderHeader()}
      <StyledDivider />
      {renderContent()}
    </>
  );
};

export default RatioForm;
