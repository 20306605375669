/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import _ from 'lodash';

const axiosInstance = axios.create();
const axiosAuthInstance = axios.create();

axiosInstance.interceptors.request.use(
  req => {
    if (_.isEmpty(req.headers['Content-Type'])) {
      req.headers.set('Content-type', 'application/json');
    }
    // On vérifie s'il s'agit de la requete de connexion pour definir la baseUrl

    // Env - Must be configured
    req.baseURL = process.env.REACT_APP_API_BASE_URL;
    // req.baseURL = 'https://localhost:443/apipatient/v1/api';
    // req.baseURL = 'https://demo.apipatient.backend.v2.generispartners.com/apipatient/v1/api';
    // req.baseURL = 'https://oasis.apipatient.backend.generispartners.com/apipatient/v1/api';
    // req.baseURL = 'https://maison-st-raphael.apipatient.backend.generispartners.com/apipatient/v1/api';

    // Vérification et ajout du jwt si disponible
    const jwt0: string = sessionStorage.getItem('jwt')!;
    if (!_.isNull(jwt0)) {
      const initialValue = JSON.parse(jwt0);
      const jwt: string = initialValue.jwt;

      // Assurez-vous que req.headers est défini
      req.headers = req.headers || {};
      req.headers['Authorization'] = jwt;
      // req.headers.set('Authorization', jwt);
    }

    req.timeout = 30000;
    return req;
  },
  error => {
    return Promise.reject(error);
  },
);

axiosAuthInstance.interceptors.request.use(
  req => {
    if (_.isEmpty(req.headers['Content-Type'])) {
      req.headers.set('Content-type', 'application/json');
    }

    // Env - Must be configured
    req.baseURL = process.env.REACT_APP_AUTH_API_BASE_URL;
    // req.baseURL = 'https://localhost:443/apipatient/v1';
    // req.baseURL = 'https://demo.apipatient.backend.v2.generispartners.com/apipatient/v1';
    // req.baseURL = 'https://oasis.apipatient.backend.generispartners.com/apipatient/v1';
    // req.baseURL = 'https://maison-st-raphael.apipatient.backend.generispartners.com/apipatient/v1';

    req.timeout = 30000;
    return req;
  },
  error => {
    return Promise.reject(error);
  },
);

/**
 * La fonction « apiRequest » est une fonction asynchrone qui effectue une requête API à l'aide d'Axios
 * dans TypeScript, en gérant les données de requête et les réponses d'erreur.
 * @param {AxiosRequestConfig} config - Le paramètre `config` dans la fonction `apiRequest` est de type
 * `AxiosRequestConfig`. Il est utilisé pour fournir des options de configuration pour la requête HTTP
 * Axios, telles que l'URL, la méthode, les en-têtes et d'autres paramètres de requête.
 * @param {TRequest} [requestData] - Le paramètre `requestData` dans la fonction `apiRequest` est un
 * type générique représentant les données que vous souhaitez envoyer dans la requête à l'API. Il vous
 * permet de spécifier le type de données que vous envoyez dans le corps de la demande.
 * @returns La fonction `apiRequest` renvoie une promesse qui se résout en une donnée de réponse de
 * type `TResponse`.
 */
const apiRequest = async <TRequest, TResponse>(
  config: AxiosRequestConfig,
  requestData?: TRequest,
): Promise<TResponse> => {
  try {
    if (requestData) {
      config.data = requestData;
    }
    const response: AxiosResponse<TResponse> = await axiosInstance(config);
    return response.data;
  } catch (error: any) {
    const message = error?.response?.data?.error || `${error}`;
    throw new Error(`Erreur de l'API: ${message}`);
  }
};

export { axiosAuthInstance, axiosInstance, apiRequest };
