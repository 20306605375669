import { FilterDropdownProps } from 'antd/es/table/interface';

// List of enums

export enum StorageType {
  SS = 'SessionStorage',
  LS = 'LocalStorage',
}

export enum NavigationMode {
  CREATE_MODE,
  READ_MODIFY_MODE,
}

// List of Interfaces

export interface CodeLabel {
  id?: number;
  code: string;
  libelle: string;
}

export interface OptionValueLabel {
  value: string | number;
  label: string;
}

export interface UploadedFile {
  id: number;
  data: File;
}

export interface StyledLabelProps {
  fontSize?: string | number;
  color?: string;
}

export interface StepperComponentProps {
  handleNext?: () => void;
  handlePrev?: () => void;
  checkMedicalRecord?: () => void;
}

export interface TableColumnSearchInterface<T> {
  dataIndex: keyof T;
  filterDropdownComponent: (e: FilterDropdownProps) => React.ReactNode;
  filterIconComponent?: React.ReactNode;
}

export interface LayoutContainerProps {
  $layerShowPadding?: 'true' | 'false';
  $enableVerticalScroll?: 'true' | 'false';
}

export interface ITab {
  value: number;
  label: string;
  disabled?: boolean;
  rights: string[];
}

// List of types

export type StorageKeys =
  | 'tva'
  | 'jwt'
  | 'connectedUserData'
  | 'idMedicalRecord'
  | 'medicalRecord'
  | 'appointmentData'
  | 'siteData'
  | 'pharmacyOrderHead';

export type CheckBoxItem = CodeLabel & { checked?: boolean };

export type GetUnitResponse = {
  id: string;
  libelle: string;
};

export type TimeSlotProps = { id?: number; date: string; hour: string };

export type FileFormats = 'PDF' | 'JPEG' | 'PNG' | 'XLSX' | 'DOCX';

export const UploadFileFormats = {
  PDF: 'application/pdf',
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword',
  XLSX: 'text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',
};

export type PaginatedResponse<C> = {
  totalPages: number;
  totalElements: number;
  first: boolean;
  last: boolean;
  size: number;
  content: C;
  number: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  numberOfElements: number;
  pageable: {
    offset: number;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    pageNumber: number;
    pageSize: number;
    unpaged: boolean;
    paged: boolean;
  };
  empty: boolean;
};

export type PaginationRequestBody = {
  pageNo: number;
  pageSize: number;
  sortBy: string;
};
