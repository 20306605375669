/* eslint-disable camelcase */
import { CreatePhysicalAction, CreatePhysicalState } from './types';

export const getCreatePhysicalDefaultInitialState = (): CreatePhysicalState => {
  return {
    generalState: '',
    resume: '',
    clinicPresentation: '',
    diagnosticHypothese: '',
    diurese: null,
    fc: null,
    respiratory_rate: null,
    size: null,
    spO2: null,
    ta: null,
    weight: null,
    temperature: null,
    invalidInputs: [],
  } as CreatePhysicalState;
};

export const createPhysicalReducer = (
  state: CreatePhysicalState,
  action: CreatePhysicalAction,
): CreatePhysicalState => {
  const newState = { ...state };
  switch (action.type) {
    case 'setGeneralState':
      newState.generalState = action.payload;
      return newState;
    case 'setResume':
      newState.resume = action.payload;
      return newState;
    case 'setClinicPresentation':
      newState.clinicPresentation = action.payload;
      return newState;
    case 'setDiagnosticHypothese':
      newState.diagnosticHypothese = action.payload;
      return newState;
    case 'setTa':
      newState.ta = action.payload;
      return newState;
    case 'setFc':
      newState.fc = action.payload;
      return newState;
    case 'setRespiratoryRate':
      newState.respiratory_rate = action.payload;
      return newState;
    case 'setSize':
      newState.size = action.payload;
      return newState;
    case 'setSpO2':
      newState.spO2 = action.payload;
      return newState;
    case 'setWeight':
      newState.weight = action.payload;
      return newState;
    case 'setDiurese':
      newState.diurese = action.payload;
      return newState;
    case 'setTemperature':
      newState.temperature = action.payload;
      return newState;
    case 'setInvalidInputs':
      newState.invalidInputs = action.payload;
      return newState;
    default:
      return state;
  }
};
