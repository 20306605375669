/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';

import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import { FiSearch, FiX } from 'react-icons/fi';
import { StyledButton } from '../../../components/button/style';
import CustomModal from '../../../components/modal/Modal';
import { StyledCardItem, StyledInput, StyledTable } from '../../../components/style';
import { CreateDiagnosticAction } from '../../../reducers/diagnostic/types';
import { IDiagnosticItem } from '../../../types';
import { ScrollContainer, StyledAddTextButton } from '../../style';
import chartColors from '../../../styles/color';

type AddRemoveDiagnostic = {
  title: string;
  items: IDiagnosticItem[];
  listTitle: string;
  reducer: React.Dispatch<CreateDiagnosticAction>;
  value?: IDiagnosticItem[];
};

const AddRemoveDiagnostic: React.FC<AddRemoveDiagnostic> = ({
  reducer,
  items,
  value,
  title,
  listTitle,
}) => {
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [savedItems, setSavedItems] = useState<IDiagnosticItem[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[] | any>([]);
  const [checkedItems, setCheckedItems] = useState<IDiagnosticItem[]>([]);

  const filteredItems = items.filter(item => item?.label.includes(searchFilter));

  useEffect(() => {
    if (!_.isEmpty(value) && !_.isUndefined(value)) {
      const defaultState = _.map(value, 'id');
      console.log(defaultState);

      setSavedItems(value);
      setSelectedRowKeys(defaultState);
    }
  }, [value]);

  const itemsColumns = [{ title: 'label', dataIndex: 'label' }];

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setCheckedItems(selectedRows);
  };

  const itemsSelections = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handCloseModalVisility = () => {
    setIsModalVisible(false);
  };

  const removeItemFromSavedItem = (currentItem: IDiagnosticItem) => {
    setSelectedRowKeys(selectedRowKeys.filter(itemId => itemId !== currentItem.id));
    const result = savedItems.filter(item => item.id !== currentItem.id);
    setSavedItems(result);
    reducer({ type: 'setDiagnostics', payload: result });
  };

  const handleValidateItems = () => {
    setSavedItems(checkedItems);
    reducer({ type: 'setDiagnostics', payload: checkedItems });
    setIsModalVisible(false);
  };

  const renderModalSelectionList = () => {
    return (
      <StyledTable
        rowKey='id'
        size='small'
        showHeader={false}
        bordered={false}
        dataSource={filteredItems}
        pagination={false}
        rowSelection={itemsSelections}
        columns={itemsColumns}
      />
    );
  };

  const renderSelectedItems = () => {
    if (_.isEmpty(savedItems)) {
      return (
        <Empty
          style={{ width: '100%', margin: '0px' }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description='Aucun antécédent'
        />
      );
    } else {
      return savedItems.map(item => {
        return (
          <StyledButton.Group key={_.uniqueId(item.label)}>
            <StyledButton size='small'> {item.label}</StyledButton>
            <StyledButton
              size='small'
              danger
              icon={<FiX />}
              onClick={() => {
                removeItemFromSavedItem(item);
              }}
            />
          </StyledButton.Group>
        );
      });
    }
  };

  return (
    <>
      <StyledCardItem
        title={!_.isUndefined(title) && title}
        style={{ boxShadow: '0px 0px 5px #00000020' }}
        styles={{ header: { backgroundColor: chartColors.lightGray } }}
        extra={
          <StyledAddTextButton
            onClick={() => {
              setIsModalVisible(true);
            }}>
            <span style={{ marginRight: '0.5rem' }}>
              <FontAwesomeIcon icon={faCirclePlus} />
            </span>
            Ajouter
          </StyledAddTextButton>
        }>
        <div
          style={{
            display: 'flex',
            minHeight: '70px',
            flexWrap: 'wrap',
            flexDirection: 'row',
            columnGap: '5px',
            justifyContent: 'flex-start',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}>
          {renderSelectedItems()}
        </div>
      </StyledCardItem>

      <CustomModal
        isVisible={isModalVisible}
        handleVisibility={handCloseModalVisility}
        title={listTitle}
        afterCloseModal={() => setSearchFilter('')}
        footer={[
          <StyledButton
            key='cancel'
            type='primary'
            onClick={() => handleValidateItems()}
            size='middle'
            block>
            Valider et Fermer
          </StyledButton>,
        ]}>
        <StyledInput
          placeholder='Rechercher un élément'
          value={searchFilter}
          onChange={e => setSearchFilter(e.target.value)}
          prefix={<FiSearch />}
        />
        <ScrollContainer style={{ height: '50vh' }}>
          <div>{renderModalSelectionList()}</div>
        </ScrollContainer>
      </CustomModal>
    </>
  );
};

export default AddRemoveDiagnostic;
