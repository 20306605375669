import {
  UpdateRubricsIntoInvoicePlanAction,
  UpdateRubricsIntoInvoicePlanState,
} from './types';

export const getUpdateRubricsIntoInvoicePlanDefaultInitialState =
  (): UpdateRubricsIntoInvoicePlanState => {
    return {
      rubrics: [],
      invalidInputs: [],
    } as UpdateRubricsIntoInvoicePlanState;
  };

export const UpdateRubricsIntoInvoicePlanReducer = (
  state: UpdateRubricsIntoInvoicePlanState,
  action: UpdateRubricsIntoInvoicePlanAction,
) => {
  const newState = { ...state };
  switch (action.type) {
    case 'setRubricsInvoicePlan':
      newState.rubrics = action.payload;
      return newState;

    case 'setInvalidInputs':
      newState.invalidInputs = action.payload;
      return newState;
    default:
      return state;
  }
};
