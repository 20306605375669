/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer, Card, Form } from 'antd';
import { Dayjs } from 'dayjs';
import _ from 'lodash';
import { useEffect, useReducer, useState } from 'react';
import {
  createMedicalRecordApi,
  getConsultationsRubricsApi,
} from '../../../api/dossierPatientService';
import { getInsurancesApi } from '../../../api/insuranceService';
import { createInvoiceApi } from '../../../api/invoiceService';
import { searchPatientApi } from '../../../api/patientService';
import { getNationality, getRegions, getResidence } from '../../../api/referenceService';
import {
  getEthnicsApi,
  getProfessionsApi,
  getServicesApi,
} from '../../../api/referentialService';
import { getSubscribersApi } from '../../../api/subscriberService';
import { getDoctorsApi } from '../../../api/userService';
import Space from '../../../components/Space';
import { StyledButton } from '../../../components/button/style';
import CustomPhoneInput from '../../../components/input/CustomPhoneInput';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import Loader from '../../../components/loader';
import {
  StyledCol,
  StyledDatePicker,
  StyledDivider,
  StyledInput,
  StyledInputNumber,
  StyledRadio,
  StyledRow,
  StyledSelectOption,
  StyledSelectPicker,
  StyledSpace,
} from '../../../components/style';
import PdfDrawerViewer from '../../../components/viewer/PdfDrawerViewer';
import dayjsInstance from '../../../dayjs';
import {
  createDossierReducer,
  getCreateDossierDefaultInitialState,
} from '../../../reducers/dossier/createDossier';
import {
  CodeLabel,
  CreateMedicalRecordApiRequestBody,
  CreateMedicalRecordApiResponse,
  CreateProformaResponse,
  GetEthnicsResponse,
  GetInsurancesResponse,
  GetInvoiceRubricsResponse,
  GetProfessionsResponse,
  GetServicesResponse,
  GetSubscribersResponse,
  GetUsersResponse,
  PatientDto,
  SearchPatientApiRequestBody,
  StepperComponentProps,
  StorageType,
  UserDto,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { DATE_FORMAT, ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { getDataFromStorage, saveInStorage } from '../../../utils/storage';
import RenderDocument from '../../document';
import { StyledMedicalRecordBlocContainer } from '../../style';
import CustomModal from '../../../components/modal/Modal';
import ProfessionForm from '../../referential/profession/ProfessionForm';
import PlaceOfresidenceForm from '../../referential/placeOfResidence/PlaceOfresidenceForm';

type FormPropertiesProps = {
  createdBy: number;
  enterDate: Dayjs | null;
  enterMode?: string;
  personToContact: string;
  personToContactPhoneNumber: string;
  serviceDto: {
    id: number;
    name: string;
    code: string;
  };
  patientDto: {
    cni: string;
    numPatient?: string;
    civility: 'F' | 'M';
    name: string;
    firstName: string;
    birthDate: Dayjs | null;
    nationality: string | number;
    phoneNumber?: string;
    placeOfResidence?: string | number;
    profession?: string | number;
    regionOrigin?: string | number;
    job?: string | number;
    ethnic?: string | number;
  };
  bonNumber: string;
  idMedecin: number;
  idInsurance: number;
  idSubscriber: number;
  insuRegistrationNumber: string;
  insuRatio: number;
  idRubriqueConsulatation: number;
};

const Dossier: React.FC<StepperComponentProps> = ({ checkMedicalRecord }) => {
  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const { notification } = AppContainer.useApp();
  const user: UserDto = getDataFromStorage<UserDto>('connectedUserData');
  // const [enterModes, setEnterModes] = useState<CodeLabel[]>([]);
  const [residences, setResidences] = useState<CodeLabel[]>([]);
  const [medecins, setMedecins] = useState<GetUsersResponse>([]);
  const [services, setServices] = useState<GetServicesResponse>([]);
  const [insurances, setInsurances] = useState<GetInsurancesResponse>([]);
  const [subscribers, setSubscribers] = useState<GetSubscribersResponse>([]);
  const [professions, setProfessions] = useState<GetProfessionsResponse>([]);
  const [consultationRubrics, setConsultationRubrics] =
    useState<GetInvoiceRubricsResponse>([]);
  const [nationalities, setNationalities] = useState<CodeLabel[]>([]);
  const [regions, setRegions] = useState<CodeLabel[]>([]);
  const [ethnics, setEthnics] = useState<GetEthnicsResponse>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isMedicalRecordFound, setIsMedicalRecordFound] = useState<boolean>(false);
  const currentMedicalRecord: CreateMedicalRecordApiResponse =
    getDataFromStorage('medicalRecord');

  const [showProformaPdf, setShowProformaPdf] = useState<boolean>(false);
  const [proformaPdfData, setProformaPdfData] = useState<CreateProformaResponse>();

  const [showInvoicePdf, setShowInvoicePdf] = useState<boolean>(false);
  const [invoicePdfData, setInvoicePdfData] = useState<CreateProformaResponse>();

  const [showModalCreateContent, setShowModalCreateContent] = useState<boolean>(false);
  const [createContentType, setCreateContentType] = useState<
    'RESIDENCE' | 'PROFESSION'
  >();

  // const currentUser = getDataFromStorage<UserDto>('connectedUserData');

  const [state, reducer] = useReducer(
    createDossierReducer,
    getCreateDossierDefaultInitialState(),
  );

  const [form] = Form.useForm<FormPropertiesProps>();

  const NotFoundContent = ({
    btnTitle,
    onClick,
  }: {
    btnTitle: string;
    onClick: () => void;
  }) => {
    return (
      <div className='w-full mx-auto flex flex-col items-center space-y-3 my-5'>
        <span>Aucune donnée trouvée</span>
        <StyledButton onClick={onClick}>{`Ajouter ${btnTitle}`}</StyledButton>
      </div>
    );
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      // getEnterMode(),
      getProfessionsApi(),
      getResidence(),
      getNationality(),
      getRegions(),
      getEthnicsApi(),
      getInsurancesApi(),
      getSubscribersApi(),
      getDoctorsApi(),
      getServicesApi(),
      getConsultationsRubricsApi(),
    ])
      .then(results => {
        // setEnterModes(results[0]);
        setProfessions(results[0]);
        setResidences(results[1]);
        setNationalities(results[2]);
        setRegions(results[3]);
        setEthnics(results[4]);
        setInsurances(results[5]);
        setSubscribers(results[6]);
        setMedecins(results[7]);
        setServices(results[8]);
        setConsultationRubrics(results[9]);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleModalCreateContent = (contentType: 'RESIDENCE' | 'PROFESSION') => {
    setCreateContentType(contentType);
    setShowModalCreateContent(state => !state);
  };

  const handleResetCreateContent = async () => {
    setCreateContentType(undefined);
    setShowModalCreateContent(false);

    Promise.all([getProfessionsApi(), getResidence()]).then(
      result => {
        setProfessions(result[0]);
        setResidences(result[1]);
        setIsLoading(false);
      },
      (error: any) => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
        setIsLoading(false);
      },
    );
  };

  const search = async () => {
    setIsLoading(true);
    const body: SearchPatientApiRequestBody = {
      cni: form.getFieldValue(['patientDto', 'cni']),
    };
    try {
      const result = await searchPatientApi(body);
      // setItems(result.patients as SearchPatientResult[]);
      if (!_.isEmpty(result.patients)) {
        setIsMedicalRecordFound(true);
        const infosPatient: any = result.patients[0];

        notification.success({
          message: `Patient ${infosPatient.name} ${infosPatient.firstname} trouvé`,
          description: 'Ses informations ont été pré-remplies par defaut',
        });

        form.setFieldsValue({
          enterDate: _.isEmpty(infosPatient.birthDate)
            ? null
            : dayjsInstance(infosPatient.birthDate, DATE_FORMAT),

          patientDto: {
            name: (infosPatient.name as string).toUpperCase(),
            firstName: (infosPatient.firstname as string).toUpperCase(),
            phoneNumber: '+225'.concat(infosPatient.phoneNumber),
            civility: infosPatient.civility,
            birthDate: _.isEmpty(infosPatient.birthDate)
              ? null
              : dayjsInstance(infosPatient.birthDate, DATE_FORMAT),
            placeOfResidence:
              infosPatient.placeOfResidenceId != 0
                ? infosPatient.placeOfResidenceId
                : null,
            profession: infosPatient.professionId != 0 ? infosPatient.professionId : null,
            nationality:
              infosPatient.nationalityId != 0 ? infosPatient.nationalityId : null,
            ethnic: infosPatient.ethnicId != 0 ? infosPatient.ethnicId : null,
            regionOrigin:
              infosPatient.regionOriginId != 0 ? infosPatient.regionOriginId : null,
          },
        });
      } else {
        const tempCNI: string = form.getFieldValue(['patientDto', 'cni']);
        setIsMedicalRecordFound(false);
        notification.warning({
          description:
            "Aucun patient n'a été trouvé, veuillez vérifier si le numéro CNI est correct",
          message: 'Patient introuvable',
        });
        form.resetFields();
        form.setFieldValue(['patientDto', 'cni'], tempCNI);
      }
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description:
          typeof error === 'object' ? JSON.stringify(error) : (error as string),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const mapPropsToRequest = (row: FormPropertiesProps) => {
    const itemData: CreateMedicalRecordApiRequestBody = {
      createdBy: row.createdBy,
      enterDate:
        row.enterDate?.format(DATE_FORMAT) ?? dayjsInstance().format(DATE_FORMAT),
      enterMode: row.enterMode ? row.enterMode : '',
      personToContact: row.personToContact,
      personToContactPhoneNumber: row.personToContactPhoneNumber,
      patientDto: {
        cni: row.patientDto.cni,
        civility: row.patientDto.civility,
        name: row.patientDto.name,
        firstName: row.patientDto.firstName,
        birthDate: row.patientDto.birthDate?.format(DATE_FORMAT) ?? '',
        nationalityId: row.patientDto.nationality ? row.patientDto.nationality : 1,
        phoneNumber: row.patientDto.phoneNumber,
        placeOfResidenceId: row.patientDto.placeOfResidence
          ? row.patientDto.placeOfResidence
          : '',
        professionId: row.patientDto.profession ? row.patientDto.profession : '',
        regionOriginId: row.patientDto.regionOrigin ? row.patientDto.regionOrigin : '',
        ethnicId: row.patientDto.ethnic ? row.patientDto.ethnic : '',
      },
      serviceDto: {
        code: row.serviceDto.code,
        name: row.serviceDto.name,
        id: row.serviceDto.id,
      },
      bonNumber: row.bonNumber,
      idInsurance: row.idInsurance,
      idRubriqueConsulatation: row.idRubriqueConsulatation,
      idMedecin: row.idMedecin,
      idSubscriber: row.idSubscriber,
      insuRatio: row.insuRatio,
      insuRegistrationNumber: row.insuRegistrationNumber,
    };
    return itemData;
  };

  const createDossier = async () => {
    try {
      await form.validateFields().then(async row => {
        setIsLoading(true);
        Promise.resolve(createMedicalRecordApi(mapPropsToRequest(row))).then(result => {
          notification.success({
            message: SuccesssMsgTitle,
            description: 'Le dossier patient a bien été créé',
          });
          saveInStorage(StorageType.SS, 'medicalRecord', result);
          checkMedicalRecord && checkMedicalRecord();
          setIsLoading(false);

          // Display proforma
          setProformaPdfData(result.generatedInvoice);
          setShowProformaPdf(true);
        });
      });
    } catch (error: any) {
      if (_.isUndefined(error.values)) {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      }
      setIsLoading(false);
    }
  };

  const createInvoice = async () => {
    try {
      setIsLoading(true);
      const result = await createInvoiceApi({
        createdBy: user?.id,
        idProforma: proformaPdfData?.id as number,
        typeInvoice: 'INVOICE',
      });
      notification.success({
        message: SuccesssMsgTitle,
        description: 'La facture a bien été créé.',
      });
      setShowProformaPdf(false);
      setInvoicePdfData(result);
      setShowInvoicePdf(true);
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
    } finally {
      setIsLoading(false);
    }
  };

  const formInitialValues = {
    enterDate: _.isNull(currentMedicalRecord)
      ? dayjsInstance(state.enterDate, DATE_FORMAT)
      : !_.isEmpty(currentMedicalRecord.enterDate)
      ? dayjsInstance(currentMedicalRecord.enterDate, DATE_FORMAT)
      : dayjsInstance(state.enterDate, DATE_FORMAT),
    enterMode: !_.isNull(currentMedicalRecord) ? currentMedicalRecord.enterMode : null,
    personToContact: !_.isNull(currentMedicalRecord)
      ? currentMedicalRecord.personToContact
      : '',
    personToContactPhoneNumber: !_.isNull(currentMedicalRecord)
      ? currentMedicalRecord.personToContactPhoneNumber
      : '',
    patientDto: {
      cni: !_.isNull(currentMedicalRecord) ? currentMedicalRecord.patientDto.cni : '',
      name: !_.isNull(currentMedicalRecord) ? currentMedicalRecord.patientDto.name : '',
      firstName: !_.isNull(currentMedicalRecord)
        ? currentMedicalRecord.patientDto.firstname
        : '',
      civility: !_.isNull(currentMedicalRecord)
        ? currentMedicalRecord.patientDto.civility
        : '',
      phoneNumber: !_.isNull(currentMedicalRecord)
        ? currentMedicalRecord.patientDto.phoneNumber
        : '',
      nationality: !_.isNull(currentMedicalRecord)
        ? currentMedicalRecord.patientDto.nationalityId
        : 1,
      ethnic:
        !_.isNull(currentMedicalRecord) && currentMedicalRecord.patientDto.ethnicId != 0
          ? currentMedicalRecord.patientDto.ethnicId
          : 1,
      placeOfResidence:
        !_.isNull(currentMedicalRecord) &&
        currentMedicalRecord.patientDto.placeOfResidenceId != 0
          ? currentMedicalRecord.patientDto.placeOfResidenceId
          : null,
      regionOrigin:
        !_.isNull(currentMedicalRecord) &&
        currentMedicalRecord.patientDto.regionOriginId != 0
          ? currentMedicalRecord.patientDto.regionOriginId
          : 1,
      profession:
        !_.isNull(currentMedicalRecord) &&
        currentMedicalRecord.patientDto.professionId != 0
          ? currentMedicalRecord.patientDto.professionId
          : null,
      birthDate: _.isNull(currentMedicalRecord)
        ? null
        : !_.isEmpty(currentMedicalRecord.patientDto.birthDate)
        ? dayjsInstance(currentMedicalRecord.patientDto.birthDate, DATE_FORMAT)
        : null,
    },
    idMedecin:
      !_.isNull(currentMedicalRecord) && currentMedicalRecord.idMedecin > 0
        ? currentMedicalRecord.idMedecin
        : null,
    idInsurance:
      !_.isNull(currentMedicalRecord) && currentMedicalRecord.idInsurance > 0
        ? currentMedicalRecord.idInsurance
        : null,
    idSubscriber:
      !_.isNull(currentMedicalRecord) && currentMedicalRecord.idSubscriber > 0
        ? currentMedicalRecord.idSubscriber
        : null,
    insuRatio: !_.isNull(currentMedicalRecord) ? currentMedicalRecord.insuRatio : 0,
    bonNumber: !_.isNull(currentMedicalRecord) ? currentMedicalRecord.bonNumber : '',
    idRubriqueConsulatation:
      !_.isNull(currentMedicalRecord) && currentMedicalRecord.idRubriqueConsulatation > 0
        ? currentMedicalRecord.idRubriqueConsulatation
        : null,
    insuRegistrationNumber: !_.isNull(currentMedicalRecord)
      ? currentMedicalRecord.insuRegistrationNumber
      : '',
    serviceDto: {
      id:
        !_.isNull(currentMedicalRecord) &&
        (currentMedicalRecord.serviceDto.id as number) > 0
          ? currentMedicalRecord.serviceDto.id
          : null,
    },
  };

  const resetInvoicePdfViewer = () => {
    setShowInvoicePdf(false);
    setInvoicePdfData(undefined);
  };

  const resetProformaPdfViewer = () => {
    setShowProformaPdf(false);
  };

  const renderProformaDrawer = () => {
    return (
      <PdfDrawerViewer
        drawerTitle={`Proforma ${proformaPdfData?.numProforma} `}
        closeDrawer={resetProformaPdfViewer}
        extraActions={
          <>
            <StyledButton
              key='create_invoice'
              type='primary'
              size='middle'
              loading={isLoading}
              disabled={isLoading}
              onClick={() => createInvoice()}>
              Créer la facture
            </StyledButton>
            <StyledButton
              key='cancel_invoice'
              type='primary'
              size='middle'
              danger
              disabled={isLoading}
              onClick={resetProformaPdfViewer}>
              Quitter
            </StyledButton>
          </>
        }
        isDrawerOpen={showProformaPdf}>
        <RenderDocument<
          CreateProformaResponse | undefined,
          { patientData?: PatientDto; medicalRecordData: CreateMedicalRecordApiResponse }
        >
          documentData={proformaPdfData}
          extraData={{
            patientData: currentMedicalRecord.patientDto,
            medicalRecordData: currentMedicalRecord,
          }}
          documentType='PROFORMA_INVOICE'
          isDuplicata={false}
        />
      </PdfDrawerViewer>
    );
  };

  const renderInvoiceDrawer = () => {
    return (
      <PdfDrawerViewer
        drawerTitle={`Facture ${invoicePdfData?.num}`}
        closeDrawer={() => resetInvoicePdfViewer()}
        extraActions={
          <>
            <StyledButton
              key='cancel_invoice'
              type='primary'
              size='middle'
              danger
              disabled={isLoading}
              onClick={resetInvoicePdfViewer}>
              Quitter
            </StyledButton>
          </>
        }
        isDrawerOpen={showInvoicePdf}>
        <RenderDocument<
          CreateProformaResponse | undefined,
          {
            title: string;
            patientData?: PatientDto;
            medicalRecordData: CreateMedicalRecordApiResponse;
          }
        >
          documentData={invoicePdfData as CreateProformaResponse}
          documentType='VALIDATED_INVOICE'
          extraData={{
            title: 'FACTURE',
            patientData: currentMedicalRecord.patientDto,
            medicalRecordData: currentMedicalRecord,
          }}
          isDuplicata={false}
        />
      </PdfDrawerViewer>
    );
  };

  const createModalContent = () => {
    switch (createContentType) {
      case 'PROFESSION':
        return (
          <ProfessionForm showBack={false} handleSuccess={handleResetCreateContent} />
        );

      case 'RESIDENCE':
        return (
          <PlaceOfresidenceForm
            showBack={false}
            handleSuccess={handleResetCreateContent}
          />
        );

      default:
        break;
    }
  };

  const renderModalAddNewContent = () => {
    return (
      <CustomModal
        isVisible={showModalCreateContent}
        footer={[]}
        handleVisibility={handleResetCreateContent}
        width='600px'>
        {createModalContent()}
      </CustomModal>
    );
  };

  const renderContent = () => {
    return (
      <Form
        disabled={isLoading}
        form={form}
        component={false}
        initialValues={formInitialValues}>
        <StyledRow gutter={[16, 16]}>
          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 16 }}>
            <StyledRow gutter={[16, 16]}>
              <StyledCol span={24}>
                <StyledMedicalRecordBlocContainer
                  style={{ backgroundColor: '#FFF', padding: '30px 20px' }}>
                  <StyledRow gutter={[16, 16]}>
                    <StyledCol span={24}>
                      <FormFieldLabel libelle='Numéro CNI' isRequired={true} />
                      <StyledSpace.Compact
                        direction='horizontal'
                        style={{ width: '100%' }}
                        size='large'>
                        <Form.Item
                          style={{ margin: 0, width: '100%' }}
                          name={['patientDto', 'cni']}
                          rules={[
                            { required: true, message: 'CNI obligatoire' },
                            { whitespace: false },
                          ]}>
                          <StyledInput
                            placeholder='CNI'
                            disabled={isLoading || !_.isNull(currentMedicalRecord)}
                          />
                        </Form.Item>
                        {_.isNull(currentMedicalRecord) && (
                          <>
                            <StyledButton
                              type='primary'
                              size='large'
                              disabled={isLoading}
                              loading={isLoading}
                              onClick={() => search()}>
                              Vérifier CNI
                            </StyledButton>
                            {/* <StyledButton
                              type='primary'
                              size='large'
                              htmlType='reset'
                              icon={<FiRefreshCcw />}
                              disabled={isLoading || !_.isNull(currentMedicalRecord)}
                            /> */}
                          </>
                        )}
                      </StyledSpace.Compact>
                    </StyledCol>
                    <StyledDivider style={{ marginTop: '0px', marginBottom: '0px' }} />

                    <StyledCol
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <StyledSpace direction='horizontal' align='baseline'>
                        <FormFieldLabel libelle='Sexe' isRequired={true} />
                        <Form.Item
                          style={{ margin: 0, width: '100%' }}
                          name={['patientDto', 'civility']}
                          rules={[
                            { required: true, message: 'Civilité obligatoire' },
                            { whitespace: false },
                          ]}>
                          <StyledRadio.Group
                            size='large'
                            disabled={
                              isMedicalRecordFound || !_.isNull(currentMedicalRecord)
                            }>
                            <StyledRadio value='M'>M</StyledRadio>
                            <StyledRadio value='F'>F</StyledRadio>
                          </StyledRadio.Group>
                        </Form.Item>
                      </StyledSpace>
                    </StyledCol>

                    <StyledCol
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                      style={{ marginTop: 0 }}>
                      <FormFieldLabel libelle="Date d'entrée" />
                      <Form.Item style={{ margin: 0 }} name='enterDate'>
                        <StyledDatePicker
                          size='large'
                          placeholder="Date d'entrée"
                          style={{ width: '100%' }}
                          format={DATE_FORMAT}
                          disabled={!_.isNull(currentMedicalRecord)}
                        />
                      </Form.Item>
                    </StyledCol>

                    <StyledCol
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <FormFieldLabel libelle='Nom' isRequired={true} />
                      <Form.Item
                        style={{ margin: 0 }}
                        name={['patientDto', 'name']}
                        rules={[
                          { required: true, message: 'Nom obligatoire' },
                          { whitespace: false },
                        ]}>
                        <StyledInput
                          style={{ textTransform: 'uppercase' }}
                          size='large'
                          placeholder='Nom'
                          disabled={
                            isMedicalRecordFound || !_.isNull(currentMedicalRecord)
                          }
                        />
                      </Form.Item>
                    </StyledCol>

                    <StyledCol
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <FormFieldLabel libelle='Prénoms' isRequired={true} />
                      <Form.Item
                        style={{ margin: 0 }}
                        name={['patientDto', 'firstName']}
                        rules={[
                          { required: true, message: 'Prénoms obligatoire' },
                          { whitespace: false },
                        ]}>
                        <StyledInput
                          style={{ textTransform: 'uppercase' }}
                          size='large'
                          placeholder='Prénoms'
                          disabled={
                            isMedicalRecordFound || !_.isNull(currentMedicalRecord)
                          }
                        />
                      </Form.Item>
                    </StyledCol>

                    <StyledCol
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <FormFieldLabel libelle='Date de naissance' isRequired={true} />
                      <Form.Item
                        style={{ margin: 0 }}
                        name={['patientDto', 'birthDate']}
                        rules={[
                          { required: true, message: 'Date de naissance obligatoire' },
                          { type: 'object', message: 'Format Incorrect' },
                        ]}>
                        <StyledDatePicker
                          size='large'
                          placeholder='Date de naissance'
                          style={{ width: '100%' }}
                          disabledDate={current =>
                            dayjsInstance().isBefore(current, 'day')
                          }
                          disabled={
                            isMedicalRecordFound || !_.isNull(currentMedicalRecord)
                          }
                          format={DATE_FORMAT}
                        />
                      </Form.Item>
                    </StyledCol>

                    <StyledCol
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <FormFieldLabel libelle='Nationalité' isRequired={true} />
                      <Form.Item
                        style={{ margin: 0 }}
                        name={['patientDto', 'nationality']}
                        rules={[
                          {
                            required: true,
                            message: 'Nationalité obligatoire',
                            type: 'number',
                          },
                        ]}>
                        <StyledSelectPicker
                          placeholder='Nationalité'
                          style={{ width: '100%' }}
                          filterOption={(input, option) =>
                            (option?.label ?? '')
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          disabled={
                            isMedicalRecordFound || !_.isNull(currentMedicalRecord)
                          }>
                          {_.map(nationalities, item => (
                            <StyledSelectOption
                              key={item.id}
                              value={item.id}
                              label={item.libelle}>
                              {item.libelle}
                            </StyledSelectOption>
                          ))}
                        </StyledSelectPicker>
                      </Form.Item>
                    </StyledCol>

                    <StyledCol
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <FormFieldLabel libelle='Région' />
                      <Form.Item
                        style={{ margin: 0 }}
                        name={['patientDto', 'regionOrigin']}>
                        <StyledSelectPicker
                          placeholder='Région'
                          style={{ width: '100%' }}
                          filterOption={(input, option) =>
                            (option?.label ?? '')
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          disabled={!_.isNull(currentMedicalRecord)}>
                          {_.map(regions, item => (
                            <StyledSelectOption
                              key={item.id}
                              value={item.id}
                              label={item.libelle}>
                              {item.libelle}
                            </StyledSelectOption>
                          ))}
                        </StyledSelectPicker>
                      </Form.Item>
                    </StyledCol>

                    <StyledCol
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <FormFieldLabel libelle='Ethnie' />
                      <Form.Item style={{ margin: 0 }} name={['patientDto', 'ethnic']}>
                        <StyledSelectPicker
                          placeholder='Ethnie'
                          style={{ width: '100%' }}
                          filterOption={(input, option) =>
                            (option?.label ?? '')
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          disabled={!_.isNull(currentMedicalRecord)}>
                          {_.map(ethnics, item => (
                            <StyledSelectOption
                              key={item.id}
                              value={item.id}
                              label={item.libelle}>
                              {item.libelle}
                            </StyledSelectOption>
                          ))}
                        </StyledSelectPicker>
                      </Form.Item>
                    </StyledCol>

                    <StyledCol
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <FormFieldLabel libelle='Numéro de téléphone' isRequired={true} />
                      <Form.Item
                        style={{ margin: 0 }}
                        name={['patientDto', 'phoneNumber']}
                        rules={[
                          {
                            required: true,
                            message: 'Numéro de téléphone obligatoire',
                          },
                          { whitespace: false },
                        ]}
                        valuePropName='value'>
                        <CustomPhoneInput
                          placeholder='Numéro de téléphone'
                          value={state.patientDto.phoneNumber}
                          onChange={val => {
                            reducer({
                              type: 'setPhoneNumber',
                              payload: val,
                            });
                          }}
                          isDisabled={!_.isNull(currentMedicalRecord)}
                        />
                      </Form.Item>
                    </StyledCol>

                    <StyledCol
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <FormFieldLabel libelle='Lieu de résidence' isRequired={true} />
                      <Form.Item
                        style={{ margin: 0 }}
                        name={['patientDto', 'placeOfResidence']}
                        rules={[
                          {
                            required: true,
                            message: 'Lieu de résidence obligatoire',
                            type: 'number',
                          },
                        ]}>
                        <StyledSelectPicker
                          placeholder='Lieu de résidence'
                          style={{ width: '100%' }}
                          // labelInValue={true}
                          optionLabelProp='label'
                          notFoundContent={
                            <NotFoundContent
                              btnTitle='lieu de résidence'
                              onClick={() => handleModalCreateContent('RESIDENCE')}
                            />
                          }
                          filterOption={(input, option) =>
                            (option?.label ?? '')
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          disabled={!_.isNull(currentMedicalRecord)}
                          onChange={(value: any) => {
                            reducer({
                              type: 'setPlaceOfResidence',
                              payload: value,
                            });
                          }}
                          value={
                            state.patientDto.placeOfResidence
                              ? state.patientDto.placeOfResidence
                              : null
                          }>
                          {_.map(residences, item => (
                            <StyledSelectOption
                              key={item.code}
                              value={item.id}
                              label={item.libelle}>
                              {item.libelle}
                            </StyledSelectOption>
                          ))}
                        </StyledSelectPicker>
                      </Form.Item>
                    </StyledCol>

                    <StyledCol
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <FormFieldLabel libelle='Profession' isRequired={true} />
                      <Form.Item
                        style={{ margin: 0 }}
                        name={['patientDto', 'profession']}
                        rules={[
                          {
                            required: true,
                            message: 'Profession obligatoire',
                            type: 'number',
                          },
                        ]}>
                        <StyledSelectPicker
                          placeholder='Profession'
                          style={{ width: '100%' }}
                          filterOption={(input, option) =>
                            (option?.label ?? '')
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          optionLabelProp='label'
                          notFoundContent={
                            <NotFoundContent
                              btnTitle='profession'
                              onClick={() => handleModalCreateContent('PROFESSION')}
                            />
                          }
                          disabled={!_.isNull(currentMedicalRecord)}
                          onChange={(value: any) => {
                            reducer({
                              type: 'setProfession',
                              payload: value,
                            });
                          }}
                          value={state.patientDto.job ? state.patientDto.job : null}>
                          {_.map(professions, item => (
                            <StyledSelectOption
                              key={item.id}
                              value={item.id}
                              label={item.libelle}>
                              {item.libelle}
                            </StyledSelectOption>
                          ))}
                        </StyledSelectPicker>
                      </Form.Item>
                    </StyledCol>
                  </StyledRow>
                </StyledMedicalRecordBlocContainer>
              </StyledCol>

              <StyledCol span={24}>
                <StyledMedicalRecordBlocContainer
                  style={{ backgroundColor: '#FFF', padding: '30px 20px' }}>
                  <StyledRow gutter={[16, 16]}>
                    <StyledCol
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <FormFieldLabel libelle='Personne à contacter' />
                      <Form.Item style={{ margin: 0 }} name='personToContact'>
                        <StyledInput
                          size='large'
                          placeholder='Nom et prénoms du contact'
                          disabled={
                            isMedicalRecordFound || !_.isNull(currentMedicalRecord)
                          }
                        />
                      </Form.Item>
                    </StyledCol>

                    <StyledCol
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <FormFieldLabel libelle='Numéro de téléphone du contact' />
                      <Form.Item
                        style={{ margin: 0 }}
                        name='personToContactPhoneNumber'
                        valuePropName='value'>
                        <CustomPhoneInput
                          placeholder='Numéro de téléphone du contact'
                          value={state.personToContactPhoneNumber}
                          onChange={val => {
                            reducer({
                              type: 'setPersonToContactPhoneNumber',
                              payload: val,
                            });
                          }}
                          isDisabled={
                            isMedicalRecordFound || !_.isNull(currentMedicalRecord)
                          }
                        />
                      </Form.Item>
                    </StyledCol>
                  </StyledRow>
                </StyledMedicalRecordBlocContainer>
              </StyledCol>

              <StyledCol span={24}>
                <StyledMedicalRecordBlocContainer
                  style={{
                    backgroundColor: '#FFF',
                    padding: '30px 20px',
                  }}>
                  <StyledRow gutter={[16, 16]}>
                    <StyledCol
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <FormFieldLabel libelle='Médecin' />
                      <Form.Item style={{ margin: 0 }} name='idMedecin'>
                        <StyledSelectPicker
                          placeholder='Médecin'
                          style={{ width: '100%' }}
                          filterOption={(input, option) =>
                            (option?.label ?? '')
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          disabled={!_.isNull(currentMedicalRecord)}>
                          {_.map(medecins, item => (
                            <StyledSelectOption
                              key={item.id}
                              value={item.id}
                              label={`${item.firstname} ${item.name}`}>
                              {`${item.firstname} ${item.name}`}
                            </StyledSelectOption>
                          ))}
                        </StyledSelectPicker>
                      </Form.Item>
                    </StyledCol>

                    <StyledCol
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <FormFieldLabel libelle='Service' />
                      <Form.Item
                        style={{ margin: 0 }}
                        name={['serviceDto', 'id']}
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: 'Service obligatoire',
                        //     type: 'number',
                        //   },
                        // ]}
                      >
                        <StyledSelectPicker
                          placeholder='Service'
                          style={{ width: '100%' }}
                          filterOption={(input, option) =>
                            (option?.label ?? '')
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          disabled={!_.isNull(currentMedicalRecord)}>
                          {_.map(services, item => (
                            <StyledSelectOption
                              key={item.id}
                              value={item.id}
                              label={item.name}>
                              {item.name}
                            </StyledSelectOption>
                          ))}
                        </StyledSelectPicker>
                      </Form.Item>
                    </StyledCol>
                  </StyledRow>
                </StyledMedicalRecordBlocContainer>
              </StyledCol>
            </StyledRow>
          </StyledCol>

          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 8 }}>
            <StyledRow gutter={[16, 16]}>
              <StyledCol span={24}>
                <Card title='FACTURATION' styles={{ body: { padding: 0 } }}>
                  <StyledMedicalRecordBlocContainer
                    style={{ backgroundColor: '#FFF', padding: '30px 20px' }}>
                    <StyledRow gutter={[16, 16]}>
                      <StyledCol span={24}>
                        <FormFieldLabel libelle='Type de consultation' isRequired />
                        <Form.Item
                          style={{ margin: 0 }}
                          name='idRubriqueConsulatation'
                          rules={[
                            {
                              required: true,
                              message: 'Type de consultation obligatoire',
                              type: 'number',
                            },
                          ]}>
                          <StyledSelectPicker
                            placeholder='Type de consultation'
                            style={{ width: '100%' }}
                            filterOption={(input, option) =>
                              (option?.label ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            disabled={!_.isNull(currentMedicalRecord)}>
                            {_.map(consultationRubrics, item => (
                              <StyledSelectOption
                                key={item.id}
                                value={item.id}
                                label={item.label}>
                                {item.label}
                              </StyledSelectOption>
                            ))}
                          </StyledSelectPicker>
                        </Form.Item>
                      </StyledCol>
                    </StyledRow>
                  </StyledMedicalRecordBlocContainer>
                </Card>
              </StyledCol>

              <StyledCol span={24}>
                <Card title='ASSURANCE' styles={{ body: { padding: 0 } }}>
                  <StyledMedicalRecordBlocContainer
                    style={{ backgroundColor: '#FFF', padding: '30px 20px' }}>
                    <StyledRow gutter={[16, 16]}>
                      <StyledCol span={24}>
                        <FormFieldLabel libelle='Assurance' />
                        <Form.Item style={{ margin: 0 }} name='idInsurance'>
                          <StyledSelectPicker
                            placeholder='Assurance'
                            style={{ width: '100%' }}
                            // labelInValue={true}
                            allowClear
                            optionLabelProp='label'
                            filterOption={(input, option) =>
                              (option?.label ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            disabled={!_.isNull(currentMedicalRecord)}
                            onChange={(value: any) => {
                              console.log(value);

                              if ([null, '', 0, undefined].includes(value)) {
                                form.setFieldsValue({
                                  bonNumber: '',
                                  insuRegistrationNumber: '',
                                });
                              }
                              reducer({
                                type: 'setIdInsurance',
                                payload: value,
                              });
                            }}
                            value={state.idInsurance ? state.idInsurance : null}>
                            {_.map(insurances, item => (
                              <StyledSelectOption
                                key={item.name}
                                value={item.id}
                                label={item.name}>
                                {item.name}
                              </StyledSelectOption>
                            ))}
                          </StyledSelectPicker>
                        </Form.Item>
                      </StyledCol>

                      <StyledCol span={24}>
                        <FormFieldLabel libelle='Souscripteur' />
                        <Form.Item style={{ margin: 0 }} name='idSubscriber'>
                          <StyledSelectPicker
                            placeholder='Souscripteur'
                            style={{ width: '100%' }}
                            // labelInValue={true}
                            optionLabelProp='label'
                            filterOption={(input, option) =>
                              (option?.label ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            disabled={!_.isNull(currentMedicalRecord)}
                            onChange={(value: any) => {
                              reducer({
                                type: 'setIdSubscriber',
                                payload: value,
                              });
                            }}
                            value={state.idInsurance ? state.idInsurance : null}>
                            {_.map(subscribers, item => (
                              <StyledSelectOption
                                key={item.id}
                                value={item.id}
                                label={item.name}>
                                {item.name} - {item.typeInsurance}
                              </StyledSelectOption>
                            ))}
                          </StyledSelectPicker>
                        </Form.Item>
                      </StyledCol>

                      <StyledCol span={24}>
                        <FormFieldLabel
                          libelle='Matricule'
                          isRequired={
                            (!_.isNull(currentMedicalRecord) &&
                              currentMedicalRecord.idRubriqueConsulatation > 0) ||
                            form.getFieldValue('idInsurance') > 0
                              ? true
                              : false
                          }
                        />
                        <Form.Item
                          style={{ margin: 0 }}
                          name='insuRegistrationNumber'
                          rules={[
                            {
                              required:
                                (!_.isNull(currentMedicalRecord) &&
                                  currentMedicalRecord.idRubriqueConsulatation > 0) ||
                                form.getFieldValue('idInsurance') > 0,
                              message: 'Matricule obligatoire',
                              type: 'string',
                            },
                          ]}>
                          <StyledInput
                            size='large'
                            placeholder='Matricule'
                            disabled={!_.isNull(currentMedicalRecord)}
                          />
                        </Form.Item>
                      </StyledCol>

                      <StyledCol span={24}>
                        <FormFieldLabel
                          libelle='Numéro de bon'
                          isRequired={
                            (!_.isNull(currentMedicalRecord) &&
                              currentMedicalRecord.idRubriqueConsulatation > 0) ||
                            form.getFieldValue('idInsurance') > 0
                              ? true
                              : false
                          }
                        />
                        <Form.Item
                          style={{ margin: 0 }}
                          name='bonNumber'
                          rules={[
                            {
                              required:
                                (!_.isNull(currentMedicalRecord) &&
                                  currentMedicalRecord.idRubriqueConsulatation > 0) ||
                                form.getFieldValue('idInsurance') > 0,
                              message: 'Numéro de bon obligatoire',
                              type: 'string',
                            },
                          ]}>
                          <StyledInput
                            size='large'
                            placeholder='Numéro de bon'
                            disabled={!_.isNull(currentMedicalRecord)}
                          />
                        </Form.Item>
                      </StyledCol>

                      <StyledCol span={24}>
                        <FormFieldLabel libelle='Pourcentage' />
                        <Form.Item style={{ margin: 0 }} name='insuRatio'>
                          <StyledInputNumber
                            size='large'
                            style={{ width: '100%' }}
                            placeholder='Pourcentage'
                            min={0}
                            max={100}
                            step={5}
                            suffix='%'
                            disabled={!_.isNull(currentMedicalRecord)}
                          />
                        </Form.Item>
                      </StyledCol>
                    </StyledRow>
                  </StyledMedicalRecordBlocContainer>
                </Card>
              </StyledCol>
            </StyledRow>
          </StyledCol>

          {_.isNull(currentMedicalRecord) && (
            <StyledCol
              span={24}
              style={{ display: 'flex', placeItems: 'center', placeContent: 'center' }}>
              <StyledButton
                type='primary'
                onClick={() => createDossier()}
                loading={isLoading}
                disabled={isLoading || !_.isNull(currentMedicalRecord)}>
                Valider
              </StyledButton>
            </StyledCol>
          )}
        </StyledRow>
      </Form>
    );
  };

  return (
    <>
      <Loader showSpinner={isLoading}>{renderContent()}</Loader>
      <Space marginTop='2rem' />

      {showProformaPdf && renderProformaDrawer()}
      {showInvoicePdf && renderInvoiceDrawer()}
      {showModalCreateContent && renderModalAddNewContent()}
    </>
  );
};

export default Dossier;
