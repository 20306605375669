import { useState } from 'react';
import { IRatioItem, ReferentialViewProps } from '../../../types';
import RatioForm from './RatioForm';
import RatiosList from './RatiosList';

const RatioSetting = () => {
  const [activeView, setActiveView] = useState<ReferentialViewProps>('LIST_ITEMS');
  const [selectedRatio, setSelectedRatio] = useState<IRatioItem>();

  const handleNavigateToView = (newView: ReferentialViewProps) => {
    setActiveView(newView);
  };

  const handleUpdateSelectedRatio = (role?: IRatioItem) => {
    setSelectedRatio(role);
  };

  const renderContent = () => {
    switch (activeView) {
      case 'LIST_ITEMS':
        return (
          <RatiosList
            navigateToview={handleNavigateToView}
            updateCurrentItem={handleUpdateSelectedRatio}
          />
        );
      case 'EDIT_ITEM':
        return (
          <RatioForm navigateToview={handleNavigateToView} currentData={selectedRatio} />
        );
      case 'NEW_ITEM':
        return <RatioForm navigateToview={handleNavigateToView} />;
    }
  };

  return <>{renderContent()}</>;
};

export default RatioSetting;
