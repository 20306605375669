import {
  CreateAntecedentRequestBody,
  CreateArticleItemRequestBody,
  CreateBiologicalAnalysisCategoryRequestBody,
  CreateBiologicalAnalysisSubCategoryRequestBody,
  CreateConsultationReasonItemRequestBody,
  CreateDiagnosticItemRequestBody,
  CreateEthnicItemRequestBody,
  CreateFunctionalSignItemRequestBody,
  CreateImageryExamItemRequestBody,
  CreatePlaceOfResidenceItemRequestBody,
  CreateProfessionItemRequestBody,
  CreateRegionItemRequestBody,
  CreateServiceRequestBody,
  CreateSupplierItemRequestBody,
  GetAntecedentResponse,
  GetArticlesResponse,
  GetBiologicalAnalysisCategoriesResponse,
  GetBiologicalAnalysisSubCategoriesResponse,
  GetConsultationReasonsResponse,
  GetDiagnosticsResponse,
  GetEthnicsResponse,
  GetFunctionalSignsResponse,
  GetImageryExamsResponse,
  GetPlacesOfResidenceResponse,
  GetProfessionsResponse,
  GetRegionsResponse,
  GetServicesResponse,
  GetSuppliersResponse,
} from '../types';

import { axiosInstance } from './axiosInstance';

/**
 * Biological Analysis categories CRUD
 */
export const getBiologicalAnalysisCategoriesApi =
  (): Promise<GetBiologicalAnalysisCategoriesResponse> => {
    return axiosInstance
      .get('/biologicalAnalysesCategories')
      .then(response => Promise.resolve(response.data))
      .catch(err => {
        const message = err?.response ? err.response.data?.error : `${err}`;
        throw Error(
          `Erreur lors de la recupération des catégories d'analyses biologiques. Raison: ${message}`,
        );
      });
  };

export const createBiologicalAnalysisCategoryApi = (
  data: CreateBiologicalAnalysisCategoryRequestBody,
): Promise<string> => {
  return axiosInstance
    .post('/biologicalAnalysisCategory', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la création d'une catégorie d'analyse biologique. Raison: ${message}`,
      );
    });
};

export const updateBiologicalAnalysisCategoryApi = (
  idCategory: number,
  data: CreateBiologicalAnalysisCategoryRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/biologicalAnalysisCategory?id=${idCategory}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la mise à jour d'une catégorie d'analyse biologique. Raison: ${message}`,
      );
    });
};

export const deleteBiologicalAnalysisCategoryApi = (
  idCategory: number,
): Promise<string> => {
  return axiosInstance
    .delete(`/biologicalAnalysisCategory?id=${idCategory}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la suppression d'une catégorie d'analyse biologique. Raison: ${message}`,
      );
    });
};

/**
 * Biological Analysis sub-categories CRUD
 */
export const getBiologicalAnalysisSubCategoriesApi =
  (): Promise<GetBiologicalAnalysisSubCategoriesResponse> => {
    return axiosInstance
      .get('/biologicalAnalysesSubCategories')
      .then(response => Promise.resolve(response.data))
      .catch(err => {
        const message = err?.response ? err.response.data?.error : `${err}`;
        throw Error(
          `Erreur lors de la recupération des sous-catégories d'analyses biologiques. Raison: ${message}`,
        );
      });
  };

export const createBiologicalAnalysisSubCategoryApi = (
  data: CreateBiologicalAnalysisSubCategoryRequestBody,
): Promise<string> => {
  return axiosInstance
    .post('/biologicalAnalysisSubCategory', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la mise à jour d'une sous-catégorie d'analyse biologique. Raison: ${message}`,
      );
    });
};

export const updateBiologicalAnalysisSubCategoryApi = (
  idSubCategory: number,
  data: CreateBiologicalAnalysisSubCategoryRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/biologicalAnalysisSubCategory?id=${idSubCategory}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la mise à jour d'une sous-catégorie d'analyse biologique. Raison: ${message}`,
      );
    });
};

export const deleteBiologicalAnalysisSubCategoryApi = (
  idSubCategory: number,
): Promise<string> => {
  return axiosInstance
    .delete(`/biologicalAnalysisSubCategory?id=${idSubCategory}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la mise à jour d'une sous-catégorie d'analyse biologique. Raison: ${message}`,
      );
    });
};

export const getBiologicalAnalysisSubCategoriesByCategory = (
  idCategory: number,
): Promise<GetBiologicalAnalysisSubCategoriesResponse> => {
  return axiosInstance
    .get(`/biologicalAnalysesSubCategoriesByCategory?idCategory=${idCategory}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération des sous-catégories d'analyses biologiques par catégorie. Raison: ${message}`,
      );
    });
};

// Diagnostics
export const getDiagnosticsApi = (): Promise<GetDiagnosticsResponse> => {
  return axiosInstance
    .get('/diagnostics')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des diagnostics. Raison: ${message}`);
    });
};

export const createDiagnosticApi = (
  data: CreateDiagnosticItemRequestBody,
): Promise<string> => {
  return axiosInstance
    .post('/diagnostic', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création du diagnostic. Raison: ${message}`);
    });
};

export const updateDiagnosticApi = (
  idDiagnostic: number,
  data: CreateDiagnosticItemRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/diagnosticPredefined?id=${idDiagnostic}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la mise à jour du diagnostic. Raison: ${message}`);
    });
};

export const deleteDiagnosticApi = (idDiagnostic: number): Promise<string> => {
  return axiosInstance
    .delete(`/diagnostic?id=${idDiagnostic}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la suppression du diagnostic. Raison: ${message}`);
    });
};

// Services
export const getServicesApi = (): Promise<GetServicesResponse> => {
  return axiosInstance
    .get('/services')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des services. Raison: ${message}`);
    });
};

export const createServiceApi = (data: CreateServiceRequestBody): Promise<string> => {
  return axiosInstance
    .post('/service', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création du service. Raison: ${message}`);
    });
};

export const updateServiceApi = (
  idService: number,
  data: CreateServiceRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/service?id=${idService}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la mise à jour du service. Raison: ${message}`);
    });
};

export const deleteServiceApi = (idService: number): Promise<string> => {
  return axiosInstance
    .delete(`/service?id=${idService}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la suppression du service. Raison: ${message}`);
    });
};

// Antécédents
export const getAntecedentApi = (): Promise<GetAntecedentResponse> => {
  return axiosInstance
    .get('/antecedent')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors des antécédents. Raison: ${message}`);
    });
};

export const createAntecedentItemApi = (
  data: CreateAntecedentRequestBody,
): Promise<string> => {
  return axiosInstance
    .post('/antecedent', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création d'antécédent. Raison: ${message}`);
    });
};

export const updateAntecedentItemApi = (
  idEntecedent: number,
  data: CreateAntecedentRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/antecedent?id=${idEntecedent}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la mise à jour de l'antecedent. Raison: ${message}`);
    });
};

export const deleteAntecedentApi = (idAntecedent: number): Promise<string> => {
  return axiosInstance
    .delete(`/antecedent?id=${idAntecedent}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la suppression du service. Raison: ${message}`);
    });
};

// Profession
export const getProfessionsApi = (): Promise<GetProfessionsResponse> => {
  return axiosInstance
    .get('/profession')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des professions. Raison: ${message}`);
    });
};

export const createProfessionApi = (
  data: CreateProfessionItemRequestBody,
): Promise<string> => {
  return axiosInstance
    .post('/profession', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création de profession. Raison: ${message}`);
    });
};

export const updateProfessionApi = (
  idProfession: number,
  data: CreateProfessionItemRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/profession?id=${idProfession}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la mise à jour de profession. Raison: ${message}`);
    });
};

export const deleteProfessionApi = (idProfession: number): Promise<string> => {
  return axiosInstance
    .delete(`/profession?id=${idProfession}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la suppression de profession. Raison: ${message}`);
    });
};

// Ethnics
export const getEthnicsApi = (): Promise<GetEthnicsResponse> => {
  return axiosInstance
    .get('/ethnics')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des ethnics. Raison: ${message}`);
    });
};

export const createEthnicApi = (data: CreateEthnicItemRequestBody): Promise<string> => {
  return axiosInstance
    .post('/ethnic', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création de ethnic. Raison: ${message}`);
    });
};

export const updateEthnicApi = (
  idEthnic: number,
  data: CreateEthnicItemRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/ethnic?id=${idEthnic}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la mise à jour de ethnic. Raison: ${message}`);
    });
};

export const deleteEthnicApi = (idEthnic: number): Promise<string> => {
  return axiosInstance
    .delete(`/ethnic?id=${idEthnic}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la suppression de ethnic. Raison: ${message}`);
    });
};

// Regions
export const getRegionsApi = (): Promise<GetRegionsResponse> => {
  return axiosInstance
    .get('/regions')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des regions. Raison: ${message}`);
    });
};

export const createRegionApi = (data: CreateRegionItemRequestBody): Promise<string> => {
  return axiosInstance
    .post('/region', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création de ethnic. Raison: ${message}`);
    });
};

export const updateRegionApi = (
  idRegion: number,
  data: CreateRegionItemRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/region?id=${idRegion}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la mise à jour de la region. Raison: ${message}`);
    });
};

export const deleteRegionApi = (idRegion: number): Promise<string> => {
  return axiosInstance
    .delete(`/region?id=${idRegion}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la suppression de la region. Raison: ${message}`);
    });
};

// Articles
export const getArticlesApi = (): Promise<GetArticlesResponse> => {
  return axiosInstance
    .get('/articles')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des articles. Raison: ${message}`);
    });
};

export const createArticleApi = (data: CreateArticleItemRequestBody): Promise<string> => {
  return axiosInstance
    .post('/article', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création de l'article. Raison: ${message}`);
    });
};

export const updateArticleApi = (
  idRegion: number,
  data: CreateArticleItemRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/article?id=${idRegion}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la mise à jour de l'article. Raison: ${message}`);
    });
};

export const deleteArticleApi = (idRegion: number): Promise<string> => {
  return axiosInstance
    .delete(`/article?id=${idRegion}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la suppression de l'article. Raison: ${message}`);
    });
};

// Places of Residence
export const getPlacesOfResidenceApi = (): Promise<GetPlacesOfResidenceResponse> => {
  return axiosInstance
    .get('/placeOfResidence')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération des lieux de résidence. Raison: ${message}`,
      );
    });
};

export const createPlaceOfResidenceApi = (
  data: CreatePlaceOfResidenceItemRequestBody,
): Promise<string> => {
  return axiosInstance
    .post('/placeOfResidence', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création du lieu de résidence. Raison: ${message}`);
    });
};

export const updatePlaceOfResidenceApi = (
  idPlaceOfResidence: number,
  data: CreatePlaceOfResidenceItemRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/placeOfResidence?id=${idPlaceOfResidence}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la mise à jour du lieu de résidence. Raison: ${message}`,
      );
    });
};

export const deletePlaceOfResidenceApi = (
  idPlaceOfResidence: number,
): Promise<string> => {
  return axiosInstance
    .delete(`/placeOfResidence?id=${idPlaceOfResidence}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la suppression du lieu de résidence. Raison: ${message}`,
      );
    });
};

// Imagery Exams
export const getImageryExamsApi = (): Promise<GetImageryExamsResponse> => {
  return axiosInstance
    .get('/imageryExams')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération des examens d'imagerie Raison: ${message}`,
      );
    });
};

export const createImageryExamApi = (
  data: CreateImageryExamItemRequestBody,
): Promise<string> => {
  return axiosInstance
    .post('/imageryExam', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création d'examen d'imagerie. Raison: ${message}`);
    });
};

export const updateImageryExamApi = (
  idImageryExam: number,
  data: CreateImageryExamItemRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/imageryExam?id=${idImageryExam}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la mise à jour de l'examen. Raison: ${message}`);
    });
};

export const deleteImageryExamApi = (idImageryExam: number): Promise<string> => {
  return axiosInstance
    .delete(`/imageryExam?id=${idImageryExam}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la suppression de l'examen. Raison: ${message}`);
    });
};

// Functional Signs
export const getFunctionalSignsApi = (): Promise<GetFunctionalSignsResponse> => {
  return axiosInstance
    .get('/functionalSigns')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération des signes fonctionnels. Raison: ${message}`,
      );
    });
};

export const createFunctionalSignApi = (
  data: CreateFunctionalSignItemRequestBody,
): Promise<string> => {
  return axiosInstance
    .post('/functionalSign', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la création d'un signe fonctionnel. Raison: ${message}`,
      );
    });
};

export const updateFonctionalSignApi = (
  idFunctionalSign: number,
  data: CreateRegionItemRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/functionalSign?id=${idFunctionalSign}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la mise à jour du signe fonctionnel. Raison: ${message}`,
      );
    });
};

export const deleteFunctionalSignApi = (idFonctionalSign: number): Promise<string> => {
  return axiosInstance
    .delete(`/functionalSign?id=${idFonctionalSign}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la suppression du signe fonctionnel. Raison: ${message}`,
      );
    });
};

// Reasons for Consultation
export const getConsultationReasonsApi = (): Promise<GetConsultationReasonsResponse> => {
  return axiosInstance
    .get('/reasonsForConsultation')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération des raisons de consultation. Raison: ${message}`,
      );
    });
};

export const createFConsultationReasonApi = (
  data: CreateConsultationReasonItemRequestBody,
): Promise<string> => {
  return axiosInstance
    .post('/reasonsForConsultation', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la création d'une raison de consultation. Raison: ${message}`,
      );
    });
};

export const updateConsultationReasonSignApi = (
  idConsultationReason: number,
  data: CreateRegionItemRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/reasonsForConsultation?id=${idConsultationReason}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la mise à jour d'une raison de consultation'. Raison: ${message}`,
      );
    });
};

export const deleteConsultationReasonApi = (
  idConsultationReason: number,
): Promise<string> => {
  return axiosInstance
    .delete(`/reasonsForConsultation?id=${idConsultationReason}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la suppression d'une raison de consultation. Raison: ${message}`,
      );
    });
};

// Suppliers (Fournisseurs)
export const getSuppliersApi = (): Promise<GetSuppliersResponse> => {
  return axiosInstance
    .get('/suppliers')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des fournisseurs. Raison: ${message}`);
    });
};

export const createSupplierApi = (
  data: CreateSupplierItemRequestBody,
): Promise<string> => {
  return axiosInstance
    .post('/supplier', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création du fournisseur. Raison: ${message}`);
    });
};

export const updateSupplierApi = (
  idRegion: number,
  data: CreateSupplierItemRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/supplier?id=${idRegion}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la mise à jour du fournisseur. Raison: ${message}`);
    });
};

export const deleteSupplierApi = (idSupplier: number): Promise<string> => {
  return axiosInstance
    .delete(`/supplier?id=${idSupplier}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la suppression du fournisseur. Raison: ${message}`);
    });
};
