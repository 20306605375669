import React from 'react';
import { IActItem, ReferentialViewProps } from '../../../types';

type ActFormProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  currentData?: IActItem;
};

const ActForm: React.FC<ActFormProps> = () => {
  return <div>ActForm</div>;
};

export default ActForm;
