import { App as AppContainer, ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import locale from 'antd/locale/fr_FR';
import 'dayjs/locale/fr';
import 'react-international-phone/style.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import 'simplebar-react/dist/simplebar.min.css';
import { ThemeProvider } from 'styled-components';
import './App.css';
import WithHeader from './components/higherOrderComponentsUtils';
import AppErrorBoundary from './components/misc/AppErrorBoundary';
import { routerConfig } from './components/routerConfig';
import { StyledToken } from './components/styleToken';
import RootLayout from './layout/RootLayout';
import LoginPage from './pages/LoginPage';
import chartColors from './styles/color';
import themeGlobal from './styles/theme';

function App() {
  const generateRoutes = () => {
    return routerConfig.map(r => {
      if (r.children) {
        return {
          path: r.path,
          children: r.children.map(c => {
            return {
              path: c.path,
              element: <WithHeader WrappedComponent={c.element} />,
            };
          }),
        };
      }
      return { path: r.path, element: <WithHeader WrappedComponent={r.element} /> };
    });
  };

  const mainRouter = createBrowserRouter([
    {
      path: '/',
      element: <RootLayout />,
      errorElement: <AppErrorBoundary />,
      children: [
        {
          index: true,
          element: <LoginPage />,
        },
        ...generateRoutes(),
      ],
    },
  ]);

  return (
    <ThemeProvider theme={{ ...themeGlobal }}>
      <ConfigProvider
        locale={locale}
        theme={{
          components: StyledToken,
          token: {
            colorPrimary: chartColors.blue,
            colorBgTextActive: chartColors.blue,
          },
        }}>
        <AppContainer
          message={{
            maxCount: 1,
            top: 100,
            duration: 2,
            rtl: true,
            prefixCls: 'my-message',
          }}
          notification={{ placement: 'bottomRight', maxCount: 1 }}>
          <RouterProvider router={mainRouter} />
        </AppContainer>
      </ConfigProvider>
    </ThemeProvider>
  );
}

export default App;
