import styled from 'styled-components';
import { LayoutContainerProps } from '../types';

export const StyledLayoutLeftHeader = styled.div``;

export const StyledLayoutLeftContent = styled.div`
  flex: 1;
`;

export const StyledLeftLayoutWrapper = styled.div<LayoutContainerProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.blue50}01;
  padding-top: ${({ $layerShowPadding }) => $layerShowPadding === 'true' && '6.5rem'};
  position: relative;
  height: inherit;
  @media screen and (max-width: 992px) {
    /* padding-top: 0rem; */
  }
`;

export const StyledRightLayoutWrapper = styled.div<LayoutContainerProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: ${({ $layerShowPadding }) =>
    $layerShowPadding === 'true' ? '6.5rem' : '0px'};
  @media screen and (max-width: 992px) {
    /* padding-top: 0rem; */
  }
`;

export const StyledVerticalLayoutWrapper = styled.div<LayoutContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  /* overflow-x: hidden;
  overflow-y: ${({ $enableVerticalScroll }) =>
    $enableVerticalScroll === 'true' ? 'auto' : 'hidden'}; */
  min-height: 97vh;
  /* height: 100vh; */
  /* background-color: ${({ theme }) => theme.colors.white}; */
  padding-top: ${({ $layerShowPadding }) =>
    $layerShowPadding === 'true' ? '6.5rem' : '0px'};

  @media screen and (max-width: 992px) {
    /* padding: 0rem; */
  }
`;

export const StyledLayoutFlexWrapper = styled.div``;

export const StyledLayoutGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;
  height: 100%;
  flex: 1;
  @media screen and (max-width: 720px) {
    grid-template-columns: 100%;
  }
`;
