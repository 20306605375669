import { PERMISSIONS, RIGHTS } from '../types';

export const DOSSIER_TAB_PERM = [
  RIGHTS.ADMIN,
  `${RIGHTS.DOSSIER}${PERMISSIONS.CONSULT}`,
  `${RIGHTS.DOSSIER}${PERMISSIONS.CREATION}`,
];
export const INTERROG_TAB_PERM = [RIGHTS.ADMIN];
export const ANTECEDENT_TAB_PERM = [RIGHTS.ADMIN];
export const EXAM_PHYSIQ_TAB_PERM = [RIGHTS.ADMIN];
export const AVOIR_PERM = [RIGHTS.ADMIN, `${RIGHTS.FACTURATION}${PERMISSIONS.AVOIR}`];
