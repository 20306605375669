import { FiTrash2 } from 'react-icons/fi';
import { StyledButton } from '../../../components/button/style';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import {
  StyledCardItem,
  StyledCol,
  StyledInputNumber,
  StyledRow,
  StyledSpace,
  StyledTag,
} from '../../../components/style';
import {
  CreatePharmacyOrderAction,
  CreatePharmacyOrderState,
} from '../../../reducers/pharmacy/types';
import chartColors from '../../../styles/color';
import { IPharmacyOrderArticle } from '../../../types';

interface OrderArticleCardProps {
  articleData: IPharmacyOrderArticle;
  reducer: React.Dispatch<CreatePharmacyOrderAction>;
  state: CreatePharmacyOrderState;
  handleRemove: () => void;
}

const OrderArticleCard: React.FC<OrderArticleCardProps> = ({
  articleData,
  reducer,
  state,
  handleRemove,
}) => {
  const checkExistingArticle = (): number => {
    const indexFound = state.articles.findIndex(
      item => item.idArticle === articleData.id,
    );

    if (indexFound !== -1) {
      return indexFound;
    }
    return 0;
  };

  return (
    <StyledCardItem
      style={{ margin: '20px 0px' }}
      styles={{
        header: {
          fontFamily: 'Metropolis',
          textTransform: 'uppercase',
          backgroundColor: chartColors.blue50,
        },
      }}
      title={
        <StyledSpace>
          {articleData.designation}
          <StyledTag>{articleData.reference}</StyledTag>
        </StyledSpace>
      }
      size='small'
      extra={
        <StyledButton
          danger
          size='small'
          icon={<FiTrash2 />}
          onClick={() => handleRemove()}>
          Retirer
        </StyledButton>
      }>
      <StyledRow gutter={[20, 20]}>
        <StyledCol span={8}>
          <FormFieldLabel libelle='Prix unitaire' />
          <StyledInputNumber
            style={{ width: '100%' }}
            min={1}
            readOnly
            disabled
            // onChange={val =>
            //   reducer({
            //     type: 'setUnitPriceArticle',
            //     payload: { id: articleData.id as number, value: val as number },
            //   })
            // }
            value={state.articles[checkExistingArticle()]?.unitPrice ?? 1}
          />
        </StyledCol>

        <StyledCol span={8}>
          <FormFieldLabel libelle='Quantité' />
          <StyledInputNumber
            style={{ width: '100%' }}
            placeholder='Quantité'
            min={1}
            onChange={val =>
              reducer({
                type: 'setQuantityArticle',
                payload: { id: articleData.id as number, value: val as number },
              })
            }
            value={state.articles[checkExistingArticle()]?.quantityOrdered}
          />
        </StyledCol>

        <StyledCol span={8}>
          <FormFieldLabel libelle='Montant HT' />
          <StyledInputNumber
            style={{ width: '100%' }}
            placeholder='Montant HT'
            min={1}
            readOnly
            disabled
            value={
              state.articles[checkExistingArticle()]?.unitPrice *
              state.articles[checkExistingArticle()]?.quantityOrdered
            }
          />
        </StyledCol>
      </StyledRow>
    </StyledCardItem>
  );
};

export default OrderArticleCard;
