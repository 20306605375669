/* eslint-disable react/no-unescaped-entities */
import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import dayjsInstance from '../../dayjs';
import chartColors from '../../styles/color';
import {
  ISite,
  SearchInvoiceApiRequestBody,
  SearchInvoiceApiResponse,
} from '../../types';
import { thousandSeparatorFormat } from '../../utils/commun';
import { DataTableCell, Table, TableBody, TableCell, TableHeader } from '../pdfTable';
import { DATE_FORMAT } from '../../utils/constants';

interface InvoiceProformaDocProps {
  data: SearchInvoiceApiResponse[];
  searchData: SearchInvoiceApiRequestBody;
  isDuplicata: boolean;
  siteInfos: ISite;
}

const RowItem = ({
  title,
  value,
  isBold,
  isBgSet,
  isFlexSet,
}: {
  title: string;
  value: string | number | undefined;
  isBold?: boolean;
  isBgSet?: boolean;
  isFlexSet?: boolean;
}) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        paddingVertical: 5,
        justifyContent: 'flex-end',
        fontWeight: isBold ? 600 : 500,
        backgroundColor: isBgSet ? chartColors.blue50 : '#FFF',
        flex: isFlexSet ? 8 : 1,
      }}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          textAlign: 'left',
        }}>
        <Text>{title}</Text>
      </View>
      <View wrap={false}>
        <Text style={{ textAlign: 'right' }}>{value}</Text>
      </View>
    </View>
  );
};

const CashboxInvoiceListDoc: React.FC<InvoiceProformaDocProps> = ({
  data,
  searchData,
  siteInfos,
}) => {
  const [totalAssu, setTotalAssu] = useState(0);
  const [totalPatient, setTotalPatient] = useState(0);
  const [montantTotal, setMontantTotal] = useState(0);

  const calutateTotal = () => {
    let tempAssu = 0;
    let tempPatient = 0;

    data.map(l => {
      tempAssu = tempAssu + l.amountAssu;
      tempPatient = tempPatient + l.amountPatient;
    });
    setTotalAssu(tempAssu);
    setTotalPatient(tempPatient);
    setMontantTotal(tempAssu + tempPatient);
  };

  useEffect(() => {
    calutateTotal();
  }, []);
  return (
    <Document>
      <Page style={styles.body} orientation='landscape'>
        {/* Header */}
        <View wrap={false} style={styles.header}>
          <View wrap={false}>
            <Image
              src={{ uri: siteInfos.url as string, method: 'GET', headers: {}, body: '' }}
              style={{ width: 40 }}
            />
          </View>
          <View wrap={false} style={{ textAlign: 'right' }}>
            <Text style={[styles.bold, { color: chartColors.blue }]}>
              {siteInfos.name}
            </Text>
            <Text>{siteInfos.address}</Text>
          </View>
        </View>
        <View
          wrap={false}
          style={{ marginBottom: 10, border: `1px solid ${chartColors.black}` }}>
          <Text
            style={{
              textAlign: 'center',
              fontSize: 30,
              fontWeight: 600,
              color: chartColors.blue,
              backgroundColor: chartColors.lightGray,
              textTransform: 'capitalize',
            }}>
            ETAT DE LA CAISSE
          </Text>
        </View>

        {/* Paramètres de la recherche */}
        <View
          wrap={false}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 10,
            fontSize: 11,
          }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}>
            <Text style={styles.bold}>Période de facturation: </Text>
            <Text>{`${searchData.dateDebut} - ${searchData.dateEnd}`}</Text>
          </View>

          <View
            wrap={false}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}>
            <Text style={styles.bold}>Payée seulement ? </Text>
            <Text>{searchData.paid ? 'OUI' : 'NON'}</Text>
          </View>
          <View
            wrap={false}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}>
            <Text style={styles.bold}>Date: </Text>
            <Text>{dayjsInstance().format(DATE_FORMAT)}</Text>
          </View>
        </View>

        {/* Liste des Rubriques */}
        <Table data={data}>
          <TableHeader
            includeLeftBorder={false}
            includeRightBorder={false}
            textAlign='center'>
            <TableCell
              style={[
                styles.columnTitle,
                { border: 'none', flex: 1, textAlign: 'left' },
              ]}>
              No Facture
            </TableCell>
            <TableCell style={[styles.columnTitle, { border: 'none', flex: 1 }]}>
              Date Facture
            </TableCell>
            <TableCell
              style={[
                styles.columnTitle,
                { border: 'none', flex: 1, textAlign: 'center' },
              ]}>
              No Dossier
            </TableCell>
            <TableCell
              style={[
                styles.columnTitle,
                { border: 'none', flex: 3, textAlign: 'left' },
              ]}>
              Patient
            </TableCell>
            <TableCell
              style={[
                styles.columnTitle,
                { border: 'none', flex: 1, textAlign: 'center' },
              ]}>
              Statut
            </TableCell>
            <TableCell
              style={[
                styles.columnTitle,
                { border: 'none', flex: 1, textAlign: 'right' },
              ]}>
              <Text>Date paiement</Text>
            </TableCell>
            <TableCell
              style={[
                styles.columnTitle,
                { border: 'none', flex: 1, textAlign: 'right' },
              ]}>
              <Text>Total TVA</Text>
            </TableCell>
            <TableCell
              style={[
                styles.columnTitle,
                { border: 'none', flex: 1, textAlign: 'right' },
              ]}>
              <Text>Total HT</Text>
            </TableCell>
            <TableCell
              style={[
                styles.columnTitle,
                { border: 'none', flex: 1, textAlign: 'right' },
              ]}>
              <Text>Total TTC</Text>
            </TableCell>
          </TableHeader>
          <TableBody
            zebra
            renderTopBorder
            evenRowColor={chartColors.blue50}
            oddRowColor='#FFF'
            includeLeftBorder={false}
            includeRightBorder={false}>
            <DataTableCell
              style={[styles.columnContent, { flex: 1 }]}
              getContent={r => r.numInvoice}
            />
            <DataTableCell
              style={[styles.columnContent, { flex: 1, textAlign: 'center' }]}
              getContent={r => r.createdDate}
            />
            <DataTableCell
              style={[styles.columnContent, { flex: 1, textAlign: 'center' }]}
              getContent={r => r.numMedicalRecord}
            />
            <DataTableCell
              style={[styles.columnContent, { flex: 3, textAlign: 'left' }]}
              getContent={r => `${r.patientFirstname} ${r.patientName}`}
            />
            <DataTableCell
              style={[styles.columnContent, { flex: 1, textAlign: 'center' }]}
              getContent={r => (r.datePayment !== null ? 'Encaissé' : 'En Attente')}
            />
            <DataTableCell
              style={[styles.columnContent, { flex: 1, textAlign: 'center' }]}
              getContent={r => r.datePayment}
            />
            <DataTableCell
              style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
              getContent={r => thousandSeparatorFormat(r.amountTVA)}
            />
            <DataTableCell
              style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
              getContent={r => thousandSeparatorFormat(r.amountHT)}
            />
            <DataTableCell
              style={[styles.columnContent, { flex: 1, textAlign: 'right' }]}
              getContent={r => thousandSeparatorFormat(r.amountTTC)}
            />
          </TableBody>
        </Table>

        {/* Total */}
        <View
          wrap={false}
          style={{
            display: 'flex',
            flexDirection: 'row',
            borderTop: '2px solid #000',
            marginTop: 0,
          }}>
          <RowItem title='TOTAUX' isBgSet isBold isFlexSet value=':' />
          <RowItem title='' isBgSet isBold value={thousandSeparatorFormat(totalAssu)} />
          <RowItem
            title=''
            isBgSet
            isBold
            value={thousandSeparatorFormat(totalPatient)}
          />
          <RowItem
            title=''
            isBgSet
            isBold
            value={thousandSeparatorFormat(montantTotal)}
          />
        </View>

        {/* Footer et Pagination */}
        <View wrap={false} fixed style={styles.pageFooter}>
          {/* <Text fixed>Clinique Demo - ApiPatient, Tous droits réservés</Text> */}
          <Text
            render={({ pageNumber, totalPages }) => `Page ${pageNumber} / ${totalPages}`}
          />
        </View>
      </Page>
    </Document>
  );
};

Font.register({
  family: 'Metropolis',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
  fonts: [
    { src: require('../../assets/fonts/Metropolis-Regular.woff') },
    { src: require('../../assets/fonts/Metropolis-Bold.woff'), fontWeight: 600 },
    { src: require('../../assets/fonts/Metropolis-Light.woff'), fontWeight: 400 },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 16,
    fontSize: 12,
    fontFamily: 'Metropolis',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  headerDateContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: chartColors.blue50,
    padding: 10,
    height: 42,
  },
  userHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  objetContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  bold: {
    fontWeight: 600,
  },
  columnTitle: {
    fontSize: 10,
    fontWeight: 600,
    paddingVertical: 5,
  },
  columnContent: {
    border: 'none',
    fontSize: 11,
    borderColor: chartColors.blue50,
    paddingVertical: 5,
  },
  totalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 15,
  },
  noteContainer: {
    marginTop: 15,
    fontSize: 10,
    textAlign: 'justify',
  },
  pageFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'absolute',
    fontSize: 11,
    bottom: 10,
    left: 16,
    right: 16,
    // textAlign: 'center',
    color: 'grey',
  },
});

export default CashboxInvoiceListDoc;
