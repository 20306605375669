/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer } from 'antd';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import {
  getAllRightsFromRoleApi,
  updateRightsFromRoleApi,
} from '../../../api/rightService';
import { StyledButton } from '../../../components/button/style';
import Loader from '../../../components/loader';
import CustomModal from '../../../components/modal/Modal';
import { StyledInput, StyledTable } from '../../../components/style';
import {
  GetRightsResponse,
  IRoleItem,
  UpdateRightsFromRoleRequestBody,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { ScrollContainer } from '../../style';

type AddRemoveRightsFromRoleProps = {
  isModalVisible: boolean;
  handCloseModalVisility: () => void;
  currentRole?: IRoleItem;
  rightsData: GetRightsResponse;
};

const AddRemoveRightsFromRole: FC<AddRemoveRightsFromRoleProps> = ({
  isModalVisible,
  handCloseModalVisility,
  currentRole,
  rightsData,
}) => {
  const { notification } = AppContainer.useApp();
  const [searchFilter, setSearchFilter] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key | any>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingRights, setIsLoadingRights] = useState<boolean>(false);

  const filteredItems = rightsData.filter(droit => droit?.label.includes(searchFilter));

  const itemsColumns = [{ title: 'label', dataIndex: 'label' }];

  const loadRightsFromRole = () => {
    setIsLoadingRights(true);
    Promise.resolve(getAllRightsFromRoleApi(currentRole?.id as number))
      .then(result => {
        const defaultState = _.map(result, 'id');
        console.log(defaultState);

        setSelectedRowKeys(defaultState);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoadingRights(false);
      });
  };

  useEffect(() => {
    if (!_.isEmpty(currentRole) && !_.isUndefined(currentRole)) {
      loadRightsFromRole();
    }
  }, []);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const itemsSelections = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const mapDataToRequestBody = () => {
    const data = {
      idRole: currentRole?.id as number,
      idRights: selectedRowKeys,
    } as UpdateRightsFromRoleRequestBody;
    return data;
  };

  const handleUpdateUserRights = () => {
    setIsLoading(true);
    Promise.resolve(updateRightsFromRoleApi(mapDataToRequestBody()))
      .then(() => {
        notification.success({
          message: SuccesssMsgTitle,
          description: 'Droits mis à jour avec succès!',
        });

        handCloseModalVisility();
      })
      .catch(error => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderModalSelectionList = () => {
    return (
      <StyledTable
        rowKey='id'
        size='small'
        showHeader={false}
        bordered={false}
        dataSource={filteredItems}
        pagination={false}
        rowSelection={itemsSelections}
        columns={itemsColumns}
      />
    );
  };

  return (
    <CustomModal
      isVisible={isModalVisible}
      handleVisibility={handCloseModalVisility}
      title={`Ajouter/Rétirer des droits - ${currentRole?.label}`}
      afterCloseModal={() => setSearchFilter('')}
      footer={[
        <StyledButton
          key='cancel'
          type='primary'
          onClick={() => handleUpdateUserRights()}
          loading={isLoading}
          disabled={isLoading || isLoadingRights}
          size='middle'
          block>
          Valider et Fermer
        </StyledButton>,
      ]}>
      <StyledInput
        placeholder='Rechercher un élément'
        value={searchFilter}
        onChange={e => setSearchFilter(e.target.value)}
        prefix={<FiSearch />}
      />
      <ScrollContainer style={{ height: '50vh' }}>
        <Loader showSpinner={isLoadingRights}>
          <div>{renderModalSelectionList()}</div>
        </Loader>
      </ScrollContainer>
    </CustomModal>
  );
};

export default AddRemoveRightsFromRole;
