import React from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { StyledButton } from '../../../components/button/style';
import { StyledCardItem } from '../../../components/style';

import {
  CreateImageryPrescriptionAction,
  CreateImageryPrescriptionState,
} from '../../../reducers/prescription/types';
import chartColors from '../../../styles/color';
import { IImageryExamPrescription } from '../../../types';

interface ImageryExamItemCardProps {
  imageryExamData: IImageryExamPrescription;
  reducer: React.Dispatch<CreateImageryPrescriptionAction>;
  state: CreateImageryPrescriptionState;
  handleRemoveRubric: () => void;
}

const ImageryExamItemCard: React.FC<ImageryExamItemCardProps> = ({
  imageryExamData,
  handleRemoveRubric,
}) => {
  // const checkExistingRubric = (): number => {
  //   const indexFound = state.prescriptionLinesImagery.findIndex(
  //     item => item.idImageryExam === imageryExamData.idImageryExam,
  //   );
  //   if (indexFound !== -1) {
  //     return indexFound;
  //   }
  //   return 0;
  // };

  return (
    <StyledCardItem
      style={{ margin: '10px 0px' }}
      styles={{
        header: {
          fontFamily: 'Metropolis',
          textTransform: 'uppercase',
          backgroundColor: chartColors.blue50,
        },
      }}
      title={imageryExamData.imageryExamDesignation}
      size='small'
      extra={
        <StyledButton
          danger
          size='small'
          icon={<FiTrash2 />}
          onClick={() => handleRemoveRubric()}
        />
      }
    />
    // <StyledRow gutter={[20, 20]}>
    //     <StyledCol span={8}>
    //       <FormFieldLabel libelle='Quantité' />
    //       <StyledInputNumber
    //         style={{ width: '100%' }}
    //         placeholder='Quantité'
    //         min={1}
    //         onChange={val =>
    //           reducer({
    //             type: 'setQuantityImageryExam',
    //             payload: {
    //               idImageryExam: imageryExamData.idImageryExam,
    //               value: val as number,
    //             },
    //           })
    //         }
    //         value={state.prescriptionLinesImagery[checkExistingRubric()]?.quantity}
    //       />
    //     </StyledCol>
    //     <StyledCol span={21}>
    //       <FormFieldLabel libelle='Commentaire' />
    //       <StyledInput
    //         style={{ width: '100%' }}
    //         placeholder='Posologie'
    //         onChange={e =>
    //           reducer({
    //             type: 'set',
    //             payload: {
    //               idMedication: imageryExamData.idImageryExam,
    //               value: e.target.value,
    //             },
    //           })
    //         }
    //         value={
    //           state.prescriptionLinesBiological[checkExistingRubric()]
    //             ?.biologicalExamDesignation
    //         }
    //       />
    //     </StyledCol>
    //   </StyledRow>
  );
};

export default ImageryExamItemCard;
