import { App as AppContainer, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { BsExclamationTriangleFill, BsPlus } from 'react-icons/bs';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import {
  GetInvoiceRubricsResponse,
  IInvoiceRubricItem,
  ReferentialViewProps,
} from '../../../types';
import { StyledDivider, StyledSpace, StyledTag } from '../../../components/style';
import { StyledButton } from '../../../components/button/style';
import {
  deleteInvoiceRubricApi,
  getInvoiceRubricsApi,
} from '../../../api/invoiceService';
import {
  ErrorDeleteMsg,
  ErrorMsgTitle,
  RUBRICS_TO_DISABLE,
  SuccesssMsgTitle,
} from '../../../utils/constants';
import { getApiErrorMsg } from '../../../utils/commun';
import chartColors from '../../../styles/color';
import SectionHeader from '../../../components/header/SectionHeader';
import Loader from '../../../components/loader';

type InvoiceRubricsListProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  updateCurrentItem: (p: IInvoiceRubricItem) => void;
};

const InvoiceRubricsList: React.FC<InvoiceRubricsListProps> = ({
  navigateToview,
  updateCurrentItem,
}) => {
  const { notification, modal } = AppContainer.useApp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rubrics, setRubrics] = useState<GetInvoiceRubricsResponse>([]);

  const handleUpdate = (item: IInvoiceRubricItem) => {
    updateCurrentItem(item);
    navigateToview('EDIT_ITEM');
  };

  const columnData: ColumnsType<IInvoiceRubricItem> = [
    {
      title: 'Libellé',
      dataIndex: 'label',
    },
    {
      title: 'Montant classique',
      align: 'center',
      children: [
        {
          title: 'Jour Ouvrable',
          dataIndex: 'classique_amount_JO',
          align: 'center',
        },
        {
          title: 'Dimanche, Nuit et Férié',
          dataIndex: 'classique_amount_D_N_JF',
          align: 'center',
        },
      ],
    },
    {
      title: 'Montant Mutualiste',
      align: 'center',
      children: [
        {
          title: 'Jour Ouvrable',
          dataIndex: 'mutualiste_amount_JO',
          align: 'center',
        },
        {
          title: 'Dimanche, Nuit et Férié',
          dataIndex: 'mutualiste_amount_D_N_JF',
          align: 'center',
        },
      ],
    },
    {
      title: 'Mode',
      dataIndex: 'mode',
    },
    {
      title: 'Statut',
      dataIndex: 'active',
      render: value =>
        value === true ? (
          <StyledTag color={chartColors.success}>Actif</StyledTag>
        ) : (
          <StyledTag color='volcano'>Inactif</StyledTag>
        ),
    },
    {
      title: 'Actions',
      align: 'left',
      render: (_value, record) => {
        return (
          <StyledSpace>
            {!RUBRICS_TO_DISABLE.includes(record.code as string) && (
              <>
                <StyledButton
                  shape='circle'
                  size='middle'
                  icon={<FiEdit />}
                  onClick={() => handleUpdate(record)}
                />
                <StyledButton
                  shape='circle'
                  size='middle'
                  danger
                  icon={<FiTrash2 />}
                  onClick={() => handleRemove(record)}
                />
              </>
            )}
          </StyledSpace>
        );
      },
    },
  ];

  const loadData = async () => {
    setIsLoading(true);
    Promise.resolve(getInvoiceRubricsApi())
      .then(result => {
        setRubrics(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteItem = async (item: IInvoiceRubricItem) => {
    try {
      setIsLoading(true);
      Promise.resolve(
        deleteInvoiceRubricApi(item.id as number).then(() => {
          loadData();
          notification.success({
            message: SuccesssMsgTitle,
            description: `La rubrique a été supprimée`,
          });
        }),
      );
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(ErrorDeleteMsg),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemove = (item: IInvoiceRubricItem) => {
    modal.confirm({
      title: 'Confirmation de suppression',
      icon: <BsExclamationTriangleFill size={40} color={chartColors.warning} />,
      content: `Êtes-vous sûr de vouloir supprimer la rubrique ${item.label} de manière permanente ?`,
      okText: 'Oui, je Confirme',
      cancelText: 'Annuler',
      onOk: () => deleteItem(item),
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const renderHeader = () => {
    return (
      <SectionHeader title='Rubrique' count={rubrics.length}>
        <StyledButton
          icon={<BsPlus />}
          type='primary'
          onClick={() => navigateToview('NEW_ITEM')}>
          Ajouter une rubrique
        </StyledButton>
      </SectionHeader>
    );
  };

  const renderContent = () => {
    return (
      <>
        <StyledDivider />
        <Table
          rowKey='id'
          bordered={false}
          columns={columnData}
          dataSource={rubrics}
          size='small'
          pagination={false}
        />
      </>
    );
  };

  return (
    <>
      {renderHeader()}
      <Loader showSpinner={isLoading}>{renderContent()}</Loader>
    </>
  );
};

export default InvoiceRubricsList;
