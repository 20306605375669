import { AxiosError } from 'axios';
import {
  CreatePatientRequestBody,
  CreatePatientResponse,
  GetPatientApiResponse,
  SearchPatientApiRequestBody,
  SearchPatientApiResponse,
} from '../types';
import { axiosInstance } from './axiosInstance';

export const getPatients = (): Promise<GetPatientApiResponse[]> => {
  return axiosInstance
    .get('/patients')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des patients. Raison: ${message}`);
    });
};

export const searchPatientApi = (
  data: SearchPatientApiRequestBody,
): Promise<SearchPatientApiResponse> => {
  return axiosInstance
    .post('/searchPatient', { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recherche de patient. Raison: ${message}`);
    });
};

export const savePatientApi = (
  data: CreatePatientRequestBody,
): Promise<CreatePatientResponse> => {
  return axiosInstance
    .post('/savePatient', data)
    .then(response => Promise.resolve(response.data))
    .catch((err: AxiosError) => {
      console.log(err.message);

      const message = err?.response ? err.response.data : `${err}`;
      throw Error(`Erreur lors de la création du patient. Raison: ${message}`);
    });
};
