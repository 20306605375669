/* eslint-disable camelcase */
import { App as AppContainer } from 'antd';
import _ from 'lodash';
import { useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  getPhysicalByMedicalRecord,
  updatePhysicalApi,
} from '../../../api/dossierPatientService';
import Space from '../../../components/Space';
import { StyledButton } from '../../../components/button/style';
import CustomMaskedInput from '../../../components/input/CustomMaskedInput';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import Loader from '../../../components/loader';
import {
  StyledCardItem,
  StyledCol,
  StyledDivider,
  StyledInput,
  StyledInputNumber,
  StyledInputTextArea,
  StyledRow,
} from '../../../components/style';
import {
  createPhysicalReducer,
  getCreatePhysicalDefaultInitialState,
} from '../../../reducers/physical/createPhysical';
import { CreatePhysicalState } from '../../../reducers/physical/types';
import chartColors from '../../../styles/color';
import {
  CreateMedicalRecordApiResponse,
  CreatePhysicalRequestBody,
  NavigationMode,
  StepperComponentProps,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { getDataFromStorage } from '../../../utils/storage';
import { StyledMedicalRecordBlocContainer } from '../../style';

const Physique: React.FC<StepperComponentProps> = () => {
  const location = useLocation();
  const locationState = location.state as { mode: NavigationMode };

  const { notification } = AppContainer.useApp();
  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const medicalRecord =
    getDataFromStorage<CreateMedicalRecordApiResponse>('medicalRecord');

  const [state, reducer] = useReducer(
    createPhysicalReducer,
    getCreatePhysicalDefaultInitialState(),
  );

  const loadMedicalRecordPhysical = async () => {
    setIsLoading(true);

    try {
      const result = await getPhysicalByMedicalRecord(medicalRecord?.id);
      // console.log(result);
      reducer({
        type: 'setClinicPresentation',
        payload: result.clinicPresentation,
      });
      reducer({
        type: 'setDiagnosticHypothese',
        payload: result.diagnosticHypothese,
      });
      reducer({
        type: 'setResume',
        payload: result.resume,
      });
      reducer({
        type: 'setGeneralState',
        payload: result.generalState,
      });
      reducer({
        type: 'setFc',
        payload: result.fc,
      });
      reducer({
        type: 'setRespiratoryRate',
        payload: result.respiratory_rate,
      });
      reducer({
        type: 'setDiurese',
        payload: result.diurese,
      });
      reducer({
        type: 'setSpO2',
        payload: result.spO2,
      });
      reducer({
        type: 'setTa',
        payload: result.ta,
      });
      reducer({
        type: 'setTemperature',
        payload: result.temperature,
      });
      reducer({
        type: 'setSize',
        payload: result.size,
      });
      reducer({
        type: 'setWeight',
        payload: result.weight,
      });
      // setSelectedAntecedents(result.antecedents);
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description:
          typeof error === 'object' ? JSON.stringify(error) : (error as string),
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!_.isNull(medicalRecord)) {
      // Charger les résultats d'Interrogatoires liés au dossier
      loadMedicalRecordPhysical();
    }
  }, []);

  const mapStateToRequestBody = (
    state: CreatePhysicalState,
  ): CreatePhysicalRequestBody => {
    const requestBody = {} as CreatePhysicalRequestBody;
    requestBody.clinicPresentation = state.clinicPresentation;
    requestBody.resume = state.resume;
    requestBody.diagnosticHypothese = state.diagnosticHypothese;
    requestBody.generalState = state.generalState;
    requestBody.idMedicalRecord = requestBody.idMedicalRecord =
      medicalRecord && medicalRecord?.id;
    requestBody.fc = state.fc;
    requestBody.respiratory_rate = state.respiratory_rate;
    requestBody.size = state.size;
    requestBody.spO2 = state.spO2;
    requestBody.ta = state.ta;
    requestBody.temperature = state.temperature;
    requestBody.weight = state.weight ?? 0;
    requestBody.diurese = state.diurese;
    return requestBody;
  };

  const updatePhysical = async () => {
    try {
      setIsLoading(true);
      const result = await updatePhysicalApi(
        medicalRecord?.id,
        mapStateToRequestBody(state),
      );
      notification.success({
        message: SuccesssMsgTitle,
        description: 'Physique a bien été mis à jour.',
      });
      console.log(result);
    } catch (error) {
      notification.error({
        message: SuccesssMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderActionsButton = () => {
    if (locationState.mode == NavigationMode.READ_MODIFY_MODE) {
      return (
        <StyledButton
          size='large'
          type='primary'
          onClick={() => updatePhysical()}
          loading={isLoading}
          disabled={isLoading}>
          Mettre à jour
        </StyledButton>
      );
    }
    return (
      <StyledButton
        size='large'
        type='primary'
        onClick={() => updatePhysical()}
        loading={isLoading}
        disabled={isLoading}>
        Valider
      </StyledButton>
    );
  };

  const renderIMC = () => {
    const weight = _.isNull(state.weight) ? 0 : state.weight;
    const size = _.isNull(state.size) ? 0 : state.size;
    let IMC: number;

    if (weight === 0 || size === 0) {
      IMC = 0;
    } else {
      IMC = weight / Math.pow(size, 2);
    }

    return (
      <span>
        {IMC.toPrecision(3)} kg/m<sup>2</sup>
      </span>
    );
  };

  const renderForm = () => {
    return (
      <>
        <StyledRow gutter={[16, 16]}>
          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}>
            <StyledCardItem
              title='Prise de constantes'
              styles={{ header: { backgroundColor: chartColors.lightGray } }}>
              <StyledRow gutter={[16, 16]}>
                <StyledCol xs={{ span: 24 }} lg={{ span: 12 }}>
                  <FormFieldLabel libelle='Tension Artérielle (TA)' />
                  <CustomMaskedInput
                    value={_.isNull(state.ta) ? undefined : state.ta}
                    handleChange={e =>
                      reducer({
                        type: 'setTa',
                        payload: e.target.value,
                      })
                    }
                    inputName='tension_arterielle'
                    inputSize='large'
                    mask='999/999'
                    contentAfter='mmHg'
                  />
                </StyledCol>

                <StyledCol xs={{ span: 24 }} lg={{ span: 12 }}>
                  <FormFieldLabel libelle='Pouls/Fréquence cardiaque (FC)' />
                  <StyledInputNumber
                    name='pouls'
                    placeholder='Pouls/Fréquence cardiaque'
                    type='number'
                    required
                    size='large'
                    min={0}
                    addonAfter='bpm'
                    style={{ width: '100%' }}
                    onChange={val => {
                      reducer({
                        type: 'setFc',
                        payload: val as number,
                      });
                    }}
                    value={state.fc}
                  />
                </StyledCol>

                <StyledCol xs={{ span: 24 }} lg={{ span: 12 }}>
                  <FormFieldLabel libelle='Température (To)' />
                  <StyledInputNumber
                    name='temperature'
                    placeholder='Temperature'
                    type='number'
                    required
                    size='large'
                    min={0}
                    addonAfter={
                      <>
                        <sup>O</sup>C
                      </>
                    }
                    style={{ width: '100%' }}
                    onChange={val => {
                      reducer({
                        type: 'setTemperature',
                        payload: val as number,
                      });
                    }}
                    value={state.temperature}
                  />
                </StyledCol>

                <StyledCol xs={{ span: 24 }} lg={{ span: 12 }}>
                  <FormFieldLabel libelle='Saturation en oxygène (SpO2)' />
                  <StyledInputNumber
                    name='oxygene'
                    placeholder='Saturation en oxygène'
                    size='large'
                    min={0}
                    style={{ width: '100%' }}
                    required
                    addonAfter='%'
                    onChange={val => {
                      reducer({ type: 'setSpO2', payload: val as number });
                    }}
                    value={state.spO2}
                  />
                </StyledCol>

                <StyledCol xs={{ span: 24 }} lg={{ span: 12 }}>
                  <FormFieldLabel libelle='Poids (Pds)' />
                  <StyledInputNumber
                    name='weight'
                    placeholder='Poids'
                    size='large'
                    min={0}
                    style={{ width: '100%' }}
                    addonAfter='Kg'
                    onChange={val => {
                      reducer({ type: 'setWeight', payload: val as number });
                    }}
                    value={state.weight}
                  />
                </StyledCol>

                <StyledCol xs={{ span: 24 }} lg={{ span: 12 }}>
                  <FormFieldLabel libelle='Taille (T)' />
                  <StyledInputNumber
                    name='size'
                    placeholder='Taille'
                    size='large'
                    min={0}
                    style={{ width: '100%' }}
                    addonAfter='m'
                    onChange={val => {
                      reducer({ type: 'setSize', payload: val as number });
                    }}
                    value={state.size}
                  />
                </StyledCol>

                <StyledCol xs={{ span: 24 }} lg={{ span: 12 }}>
                  <FormFieldLabel libelle='Fréquence respiratoire (FC)' />
                  <StyledInputNumber
                    name='respiratory_rate'
                    placeholder='Fréquence respiratoire'
                    type='number'
                    required
                    size='large'
                    min={0}
                    addonAfter='cpm'
                    style={{ width: '100%' }}
                    onChange={val => {
                      reducer({
                        type: 'setRespiratoryRate',
                        payload: val as number,
                      });
                    }}
                    value={state.respiratory_rate}
                  />
                </StyledCol>

                <StyledCol xs={{ span: 24 }} lg={{ span: 12 }}>
                  <FormFieldLabel libelle='Diurèse (Do)' />
                  <StyledInput
                    name='diurese'
                    placeholder='Diurèse'
                    type='text'
                    size='large'
                    addonAfter='ml/24h'
                    onChange={e => {
                      reducer({ type: 'setDiurese', payload: e.target.value });
                    }}
                    value={state.diurese as string}
                  />
                </StyledCol>
              </StyledRow>

              <StyledDivider />

              {/* Calcul de l'IMC */}
              <StyledRow>
                <StyledCol span={6}>IMC DU PATIENT : </StyledCol>
                <StyledCol style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {renderIMC()}
                </StyledCol>
              </StyledRow>
            </StyledCardItem>
          </StyledCol>

          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}>
            <StyledMedicalRecordBlocContainer
              style={{
                backgroundColor: '#FFF',
                padding: '30px 20px',
              }}>
              <StyledRow gutter={[16, 16]}>
                <StyledCol span={24}>
                  <FormFieldLabel libelle='Présentation clinique' />
                  <StyledInputTextArea
                    placeholder='Présentation Clinique'
                    rows={4}
                    maxLength={500}
                    showCount
                    onChange={e =>
                      reducer({
                        type: 'setClinicPresentation',
                        payload: e.target.value,
                      })
                    }
                    value={state.clinicPresentation || ''}
                  />
                </StyledCol>

                <StyledCol span={24}>
                  <FormFieldLabel libelle='Résumé' />
                  <StyledInputTextArea
                    rows={5}
                    maxLength={255}
                    showCount
                    placeholder='Résumé'
                    onChange={e =>
                      reducer({
                        type: 'setResume',
                        payload: e.target.value,
                      })
                    }
                    value={state.resume || ''}
                  />
                </StyledCol>

                <StyledCol span={24}>
                  <FormFieldLabel libelle='Hypothèses de diagnostic' />
                  <StyledInputTextArea
                    rows={4}
                    placeholder='Hypothèses de diagnostic'
                    maxLength={500}
                    showCount
                    onChange={e => {
                      reducer({
                        type: 'setDiagnosticHypothese',
                        payload: e.target.value,
                      });
                    }}
                    value={state.diagnosticHypothese || ''}
                  />
                </StyledCol>
              </StyledRow>
            </StyledMedicalRecordBlocContainer>
          </StyledCol>
        </StyledRow>

        <StyledRow gutter={[16, 16]} style={{ marginTop: 10 }}>
          <StyledCol
            span={24}
            style={{ display: 'flex', placeItems: 'center', placeContent: 'center' }}>
            {!_.isNull(medicalRecord) && renderActionsButton()}
          </StyledCol>
        </StyledRow>
      </>
    );
  };

  return (
    <>
      <Space marginTop='2rem' />
      <Loader showSpinner={isLoading}>{renderForm()}</Loader>
    </>
  );
};

export default Physique;
