import React from 'react';
import { StyledSpace } from '../style';
import { StyledButton } from '../button/style';
import chartColors from '../../styles/color';
import { IConfigMenu } from '../../types';

const ConfigMenu = <K,>({
  menuItems,
  activeTab,
  handleSelectMenuRef,
}: IConfigMenu<K>) => {
  return (
    <StyledSpace direction='vertical' style={{ width: '100%' }}>
      {menuItems.map((menu, idx) => (
        <StyledButton
          key={idx}
          type='primary'
          icon={menu.icon}
          block
          onClick={() => handleSelectMenuRef(menu)}
          ghost={activeTab !== menu.key}
          style={{
            justifyContent: 'flex-start',
            borderRadius: '0px',
            border: 0,
            backgroundColor:
              activeTab === menu.key
                ? chartColors.white
                : menu.darkBg
                ? chartColors.lightGray
                : 'transparent',
            color: activeTab === menu.key ? chartColors.blue : chartColors.mainGray,
          }}>
          {menu.name}
        </StyledButton>
      ))}
    </StyledSpace>
  );
};

export default ConfigMenu;
