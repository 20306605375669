import { App as AppContainer, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FC, useEffect, useState } from 'react';
import { BsExclamationTriangleFill, BsPlus } from 'react-icons/bs';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { deleteAntecedentApi, getAntecedentApi } from '../../../api/referentialService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import Loader from '../../../components/loader';
import { StyledDivider, StyledSpace } from '../../../components/style';
import chartColors from '../../../styles/color';
import {
  GetAntecedentResponse,
  IAntecedentItem,
  ReferentialViewProps,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import {
  ErrorDeleteMsg,
  ErrorMsgTitle,
  SuccesssMsgTitle,
} from '../../../utils/constants';

type AntecedentsListProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  updateCurrentItem: (p: IAntecedentItem) => void;
};

const AntecedentsList: FC<AntecedentsListProps> = ({
  navigateToview,
  updateCurrentItem,
}) => {
  const { notification, modal } = AppContainer.useApp();
  const [antecedentsData, setAntecedentsData] = useState<GetAntecedentResponse>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleUpdate = (medication: IAntecedentItem) => {
    updateCurrentItem(medication);
    navigateToview('EDIT_ITEM');
  };

  const deleteAntecedent = async (antecedent: IAntecedentItem) => {
    try {
      setIsLoading(true);
      Promise.resolve(deleteAntecedentApi(antecedent.id as number)).then(() => {
        loadAntecedents();
        notification.success({
          message: SuccesssMsgTitle,
          description: `L'antécédent a été supprimé`,
        });
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(ErrorDeleteMsg),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveAntecedent = (antecedent: IAntecedentItem) => {
    modal.confirm({
      title: 'Confirmation de suppression',
      icon: <BsExclamationTriangleFill size={40} color={chartColors.warning} />,
      content: `Êtes-vous sûr de vouloir supprimer l'antécédent ${antecedent.libelle} de manière permanente ?`,
      okText: 'Oui, je Confirme',
      cancelText: 'Annuler',
      onOk: () => deleteAntecedent(antecedent),
    });
  };

  const loadAntecedents = async () => {
    setIsLoading(true);
    Promise.resolve(getAntecedentApi())
      .then(result => {
        setAntecedentsData(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadAntecedents();
  }, []);

  const columnData: ColumnsType<IAntecedentItem> = [
    {
      title: 'Code',
      dataIndex: 'code',
      width: 150,
    },
    {
      title: 'Désignation',
      dataIndex: 'libelle',
    },
    {
      title: 'Catégorie',
      dataIndex: 'category',
      width: 100,
    },
    {
      title: 'Créé le',
      dataIndex: 'createdDate',
      width: 100,
    },
    {
      title: 'Actions',
      align: 'left',
      render: (_value, record) => {
        return (
          <StyledSpace>
            <StyledButton
              shape='circle'
              size='middle'
              icon={<FiEdit />}
              onClick={() => handleUpdate(record)}
            />
            <StyledButton
              shape='circle'
              size='middle'
              danger
              icon={<FiTrash2 />}
              onClick={() => handleRemoveAntecedent(record)}
            />
          </StyledSpace>
        );
      },
    },
  ];

  const renderHeader = () => {
    return (
      <SectionHeader title='Antécédent' count={antecedentsData.length}>
        <StyledButton
          icon={<BsPlus />}
          type='primary'
          onClick={() => navigateToview('NEW_ITEM')}>
          Ajouter un antécédent
        </StyledButton>
      </SectionHeader>
    );
  };

  const renderContent = () => {
    return (
      <>
        <StyledDivider />
        <Table
          rowKey='id'
          bordered={false}
          columns={columnData}
          dataSource={antecedentsData}
          size='small'
          pagination={false}
        />
      </>
    );
  };

  return (
    <>
      {renderHeader()}
      <Loader showSpinner={isLoading}>{renderContent()}</Loader>
    </>
  );
};

export default AntecedentsList;
