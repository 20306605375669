import { App as AppContainer } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  createInvoiceApi,
  createProformaApi,
  getInvoiceRubricsApi,
} from '../../api/invoiceService';
import { StyledButton } from '../../components/button/style';
import EmptyNotSelected from '../../components/emptyData/EmptyNotSelected';
import {
  StyledCol,
  StyledInput,
  StyledRow,
  StyledSpace,
  StyledTag,
} from '../../components/style';

import { BsNodePlus } from 'react-icons/bs';
import SectionHeader from '../../components/header/SectionHeader';
import FormFieldLabel from '../../components/label/FormFieldLabel';
import Loader from '../../components/loader';
import PdfDrawerViewer from '../../components/viewer/PdfDrawerViewer';
import {
  CreateInvoiceProformaAction,
  CreateManualInvoiceProformaState,
} from '../../reducers/invoicePlan/types';
import chartColors from '../../styles/color';
import {
  CreateMedicalRecordApiResponse,
  CreateProformaRequestBody,
  CreateProformaResponse,
  EInvoiceType,
  GetInvoiceRubricsResponse,
  PatientDto,
  PharmacyLinesResponse,
  SearchBiologicalPrescriptionLinesResponse,
  SearchImageryPrescriptionLinesResponse,
  UserDto,
} from '../../types';
import { getApiErrorMsg, renderInvoiceTitle } from '../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../utils/constants';
import { getDataFromStorage } from '../../utils/storage';
import RenderDocument from '../document';
import { StyleListContainer } from '../style';
import AddRemoveInvoiceRubric from './AddRemoveInvoiceRubric';
import BiologicalPrescritionsCard from './BiologicalPrescritionsCard';
import ImageryPrescriptionsCard from './ImageryPrescriptionsCard';
import PharmacyLinesCard from './PharmacyLinesCard';

interface ManualProformaInvoiceprops {
  selectedPatientId: number | null;
  currentPatient?: PatientDto;
  selectedMedicalRecord: CreateMedicalRecordApiResponse | null;
  biologicalPrescriptionsLines: SearchBiologicalPrescriptionLinesResponse;
  imageryPrescriptionsLines: SearchImageryPrescriptionLinesResponse;
  pharmacyInvoiceLines: PharmacyLinesResponse;
  state: CreateManualInvoiceProformaState;
  reducer: React.Dispatch<CreateInvoiceProformaAction>;
  invoiceType: EInvoiceType;
  isParentLoading: boolean;
}

const ManualProformaInvoice: React.FC<ManualProformaInvoiceprops> = ({
  selectedPatientId,
  selectedMedicalRecord,
  biologicalPrescriptionsLines,
  imageryPrescriptionsLines,
  pharmacyInvoiceLines,
  currentPatient,
  reducer,
  state,
  invoiceType,
  isParentLoading,
}) => {
  const navigate = useNavigate();

  const { notification } = AppContainer.useApp();
  const [rubricsData, setRubricsData] = useState<GetInvoiceRubricsResponse>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showProformaPdf, setShowProformaPdf] = useState<boolean>(false);
  const [proformaPdfData, setProformaPdfData] = useState<CreateProformaResponse>();

  const [showInvoicePdf, setShowInvoicePdf] = useState<boolean>(false);
  const [invoicePdfData, setInvoicePdfData] = useState<CreateProformaResponse>();

  const [showItemsModal, setShowItemsModal] = useState<boolean>(false);
  const [bonNumber, setBonNumber] = useState(selectedMedicalRecord?.bonNumber ?? '');

  const [exams, setExams] = useState<{
    biological: {
      keys: React.Key[];
      selectedRows: SearchBiologicalPrescriptionLinesResponse;
    };
    imagery: { keys: React.Key[]; selectedRows: SearchImageryPrescriptionLinesResponse };
  }>({
    biological: {
      keys: [],
      selectedRows: [],
    },
    imagery: {
      keys: [],
      selectedRows: [],
    },
  });

  const user: UserDto = getDataFromStorage<UserDto>('connectedUserData');

  const onSelectBiologicalExamChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: SearchBiologicalPrescriptionLinesResponse,
  ) => {
    setExams(prevState => ({
      ...prevState,
      biological: {
        keys: newSelectedRowKeys,
        selectedRows: selectedRows,
      },
    }));
  };

  const onSelectImageryExamChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: SearchImageryPrescriptionLinesResponse,
  ) => {
    setExams(prevState => ({
      ...prevState,
      imagery: {
        keys: newSelectedRowKeys,
        selectedRows: selectedRows,
      },
    }));
  };

  const loadRubrics = () => {
    setIsLoading(true);
    Promise.resolve(getInvoiceRubricsApi())
      .then(result => {
        setRubricsData(result);
        setIsLoading(false);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
        setIsLoading(false);
      });
  };

  const mapStateToRequestBody = (
    state: CreateManualInvoiceProformaState,
  ): CreateProformaRequestBody => {
    const result: CreateProformaRequestBody = {
      bonNumber: bonNumber,
      createdBy: user?.id,
      idPatient: selectedPatientId ?? null,
      idmedicalRecord: selectedMedicalRecord?.id,
      idInvoicePlanHead: 1,
      proformaLines: state.proformaLines.map(proformaLine => ({
        ...proformaLine,
        amountAssu: proformaLine.amountAssu * proformaLine.quantity,
        amountPatient: proformaLine.amountPatient * proformaLine.quantity,
        amountTotal: proformaLine.amountTotal * proformaLine.quantity,
      })),
      invoiceLineBiological: [EInvoiceType.BIOLOGICAL].includes(invoiceType)
        ? exams.biological.selectedRows
        : [],
      invoiceLineImagery: [EInvoiceType.IMAGERY].includes(invoiceType)
        ? exams.imagery.selectedRows
        : [],
      invoicePharmayLineDto: [EInvoiceType.PHARMACY].includes(invoiceType)
        ? pharmacyInvoiceLines
        : [],
    } as CreateProformaRequestBody;
    return result;
  };

  const redirectToInvoicePage = () => {
    navigate('/facturation', {
      replace: true,
    });
  };

  const createProforma = async () => {
    setIsLoading(true);
    try {
      if ((selectedMedicalRecord?.idInsurance as number) > 0 && _.isEmpty(bonNumber)) {
        notification.error({
          message: ErrorMsgTitle,
          description: 'Le numéro de bon est obligatoire',
        });
        setIsLoading(false);
      } else {
        const result = await createProformaApi(mapStateToRequestBody(state));
        notification.success({
          message: SuccesssMsgTitle,
          description: 'La proforma a bien été créé.',
        });
        setProformaPdfData(result);
        setShowProformaPdf(true);
      }
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
    } finally {
      setIsLoading(false);
    }
  };

  const createInvoice = async () => {
    try {
      setIsLoading(true);
      const result = await createInvoiceApi({
        createdBy: user?.id,
        idProforma: proformaPdfData?.id as number,
        typeInvoice: 'INVOICE',
      });
      notification.success({
        message: SuccesssMsgTitle,
        description: 'La facture a bien été créé.',
      });
      setIsLoading(false);
      setShowProformaPdf(false);
      setInvoicePdfData(result);
      setShowInvoicePdf(true);
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
      setIsLoading(false);
    }
  };

  const resetInvoicePdfViewer = () => {
    setShowInvoicePdf(false);
    setInvoicePdfData(undefined);
    navigate('/facturation');
  };

  const handleItemsVisibilityModal = () => {
    setShowItemsModal(state => !state);
  };

  useEffect(() => {
    loadRubrics();
  }, []);

  const renderInvoiceRubrics = () => {
    return (
      // <StyledRow gutter={[20, 20]}>
      <StyledCol span={24}>
        <AddRemoveInvoiceRubric
          items={rubricsData}
          isLoading={isLoading}
          value={state.proformaLines}
          listTitle='Sélectionnez une ou plusieurs rubriques'
          reducer={reducer}
          state={state}
          isModalVisible={showItemsModal}
          handleCloseModalVisibility={handleItemsVisibilityModal}
          selectedMedicalRecord={selectedMedicalRecord}
        />
      </StyledCol>
    );
  };

  const renderConfirmButton = () => {
    return (
      <StyledCol span={24}>
        {!_.isEmpty(state.proformaLines) ||
        !_.isEmpty(exams.biological.selectedRows) ||
        !_.isEmpty(exams.imagery.selectedRows) ||
        !_.isEmpty(pharmacyInvoiceLines) ? (
          <StyledButton
            block
            type='primary'
            loading={isLoading}
            disabled={isLoading}
            onClick={() => createProforma()}>
            Créer proforma
          </StyledButton>
        ) : null}
      </StyledCol>
    );
  };

  const renderHeaderActions = () => {
    return (
      <StyledSpace>
        <StyledButton
          loading={isLoading}
          disabled={isLoading}
          size='middle'
          icon={<BsNodePlus />}
          block
          type='dashed'
          onClick={() => {
            setShowItemsModal(true);
          }}>
          Ajouter une Rubrique
        </StyledButton>
        {renderConfirmButton()}
      </StyledSpace>
    );
  };

  const renderBiologicalPrescriptionsLines = () => {
    return (
      <StyledCol span={24}>
        <BiologicalPrescritionsCard
          prescriptionLines={biologicalPrescriptionsLines}
          handleRowChange={onSelectBiologicalExamChange}
          selectedRowKeys={exams.biological.keys}
          isLoading={isParentLoading || isLoading}
        />
      </StyledCol>
    );
  };

  const renderImageryPrescriptionsLines = () => {
    return (
      <StyledCol span={24}>
        <ImageryPrescriptionsCard
          prescriptionLines={imageryPrescriptionsLines}
          handleRowChange={onSelectImageryExamChange}
          selectedRowKeys={exams.imagery.keys}
          isLoading={isParentLoading || isLoading}
        />
      </StyledCol>
    );
  };

  const renderPharmacyInvoiceLines = () => {
    return (
      <StyledCol span={24}>
        <PharmacyLinesCard phamacyLines={pharmacyInvoiceLines} />
      </StyledCol>
    );
  };

  const renderInsuranceCard = () => {
    const InsuranceHeader =
      selectedMedicalRecord?.idInsurance && selectedMedicalRecord?.idInsurance > 0 ? (
        <StyledTag color={chartColors.success}>
          Assuré {`${selectedMedicalRecord?.insuRatio}%`}
        </StyledTag>
      ) : (
        <StyledTag color={chartColors.danger}>Non Assuré</StyledTag>
      );

    return (
      <StyledSpace align='center'>
        <span>Nouvelle facture</span> {InsuranceHeader}
      </StyledSpace>
    );
  };

  const renderContent = () => {
    if (_.isNull(selectedMedicalRecord) || _.isUndefined(selectedMedicalRecord)) {
      return (
        <EmptyNotSelected
          description='Veuillez sélectionner le patient à facturer'
          message='Aucun patient Sélectionné'
        />
      );
    }

    return (
      <div style={{ padding: '0px 20px', paddingBottom: 180 }}>
        <SectionHeader title={renderInsuranceCard()}>
          {renderHeaderActions()}
        </SectionHeader>
        <Loader showSpinner={isLoading}>
          <StyledRow gutter={[20, 20]}>
            <StyledCol span={24}>
              <FormFieldLabel
                libelle='Numéro de bon'
                isRequired={(selectedMedicalRecord?.idInsurance as number) > 0}
              />
              <StyledInput
                size='large'
                placeholder='Numéro de bon'
                onChange={e => setBonNumber(e.target.value)}
                value={bonNumber}
                disabled={(selectedMedicalRecord?.idInsurance as number) <= 0}
              />
            </StyledCol>

            {renderInvoiceRubrics()}
            {[EInvoiceType.BIOLOGICAL].includes(invoiceType) &&
            !_.isEmpty(biologicalPrescriptionsLines)
              ? renderBiologicalPrescriptionsLines()
              : null}
            {[EInvoiceType.IMAGERY].includes(invoiceType) &&
            !_.isEmpty(imageryPrescriptionsLines)
              ? renderImageryPrescriptionsLines()
              : null}
            {[EInvoiceType.PHARMACY].includes(invoiceType) &&
            !_.isEmpty(pharmacyInvoiceLines)
              ? renderPharmacyInvoiceLines()
              : null}
          </StyledRow>
        </Loader>
      </div>
    );
  };

  return (
    <>
      <StyleListContainer style={{ padding: '0px', paddingTop: '10px' }}>
        <div style={{ backgroundColor: 'white' }}>{renderContent()}</div>
      </StyleListContainer>

      {showProformaPdf && (
        <PdfDrawerViewer
          drawerTitle={`Proforma ${proformaPdfData?.numProforma} `}
          closeDrawer={() => setShowProformaPdf(false)}
          extraActions={
            <>
              <StyledButton
                key='create_invoice'
                type='primary'
                size='middle'
                loading={isLoading}
                disabled={isLoading}
                onClick={() => createInvoice()}>
                Créer la facture
              </StyledButton>
              <StyledButton
                key='cancel_invoice'
                type='primary'
                size='middle'
                danger
                disabled={isLoading}
                onClick={() => redirectToInvoicePage()}>
                Quitter
              </StyledButton>
            </>
          }
          isDrawerOpen={showProformaPdf}>
          <RenderDocument<
            CreateProformaResponse | undefined,
            {
              patientData?: PatientDto;
              medicalRecord: CreateMedicalRecordApiResponse;
            }
          >
            documentData={proformaPdfData}
            extraData={{
              patientData: currentPatient,
              medicalRecord: selectedMedicalRecord as CreateMedicalRecordApiResponse,
            }}
            documentType='PROFORMA_INVOICE'
            isDuplicata={false}
          />
        </PdfDrawerViewer>
      )}

      {showInvoicePdf && (
        <PdfDrawerViewer
          drawerTitle={`Facture ${invoicePdfData?.num}`}
          closeDrawer={() => resetInvoicePdfViewer()}
          extraActions={
            <>
              <StyledButton
                key='cancel_invoice'
                type='primary'
                size='middle'
                danger
                disabled={isLoading}
                onClick={() => resetInvoicePdfViewer()}>
                Quitter
              </StyledButton>
            </>
          }
          isDrawerOpen={showInvoicePdf}>
          <RenderDocument<
            CreateProformaResponse | undefined,
            {
              title: string;
              patientData?: PatientDto;
              medicalRecord: CreateMedicalRecordApiResponse;
            }
          >
            documentData={invoicePdfData as CreateProformaResponse}
            documentType='VALIDATED_INVOICE'
            extraData={{
              title: renderInvoiceTitle(invoiceType),
              patientData: currentPatient,
              medicalRecord: selectedMedicalRecord as CreateMedicalRecordApiResponse,
            }}
            isDuplicata={false}
          />
        </PdfDrawerViewer>
      )}
    </>
  );
};

export default ManualProformaInvoice;
