/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer } from 'antd';
import _ from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { getArticlesApi, getSuppliersApi } from '../../../api/referentialService';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import PageHeader from '../../../components/pageHeader/PageHeader';
import {
  StyledCol,
  StyledRow,
  StyledSelectOption,
  StyledSelectPicker,
} from '../../../components/style';
import PageContentLeftLayout from '../../../layout/PageContentLeftLayout';
import PageContentRightLayout from '../../../layout/PageContentRightLayout';
import { StyledLayoutGridWrapper } from '../../../layout/style';
import {
  CreatePharmacyOrderReducer,
  getCreatePharmacyOrderDefaultInitialState,
} from '../../../reducers/pharmacy/createPharmacyOrder';
import chartColors from '../../../styles/color';
import {
  CreatePharmacyOrderRequestBody,
  CreatePharmacyOrderResponse,
  GetArticlesResponse,
  GetSuppliersResponse,
  IArticleItem,
  IUserItem,
} from '../../../types';
import { getApiErrorMsg, mapStateToRequestBody } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { StyledBlocContainer } from '../../style';
import AddRemoveArticles from './AddRemoveArticles';
import SectionHeader from '../../../components/header/SectionHeader';
import { StyledButton } from '../../../components/button/style';
import { createPharmacyOrdersApi } from '../../../api/pharmacyService';
import { useNavigate } from 'react-router-dom';
import PdfDrawerViewer from '../../../components/viewer/PdfDrawerViewer';
import RenderDocument from '../../document';
import { getConnectedUserData } from '../../../api/loginService';
import { getDataFromStorage } from '../../../utils/storage';

const OrderArticleForm: React.FC = () => {
  const navigate = useNavigate();
  const { notification } = AppContainer.useApp();
  const [pharmacyOrderState, pharmacyOrderReducer] = useReducer(
    CreatePharmacyOrderReducer,
    getCreatePharmacyOrderDefaultInitialState(),
  );
  const user = getDataFromStorage<IUserItem>('connectedUserData');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [idSupplier, setIdSupplier] = useState<number | null>(null);
  const [suppliers, setSuppliers] = useState<GetSuppliersResponse>([]);
  const [articles, setArticles] = useState<GetArticlesResponse>([]);
  const [filteredArticles, setFilteredArticles] = useState<Array<IArticleItem>>([]);

  const [showOrderPdf, setShowOrderPdf] = useState<boolean>(false);
  const [createdOrderData, setCreatedOrderData] = useState<CreatePharmacyOrderResponse>();

  const renderHeader = () => {
    return <PageHeader title='Nouvelle Commande' showBack />;
  };

  const loadSuppliers = async () => {
    setIsLoading(true);
    Promise.resolve(getSuppliersApi())
      .then(result => {
        setSuppliers(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadArticles = async () => {
    setIsLoading(true);
    Promise.resolve(getArticlesApi())
      .then(result => {
        setArticles(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filterArticlesBySupplierId = (supplierId: null | number) => {
    pharmacyOrderReducer({ type: 'resetArticles' });
    const tempArticles = articles.filter(article => article.supplier.id === supplierId);
    setFilteredArticles(tempArticles);
  };

  const redirectToOrderPage = () => {
    navigate('/pharmacie');
  };

  const createOrder = () => {
    try {
      setIsLoading(true);
      createPharmacyOrdersApi(
        mapStateToRequestBody<CreatePharmacyOrderRequestBody>({
          createdBy: user.id as number,
          idSupplier: idSupplier as number,
          orderLines: pharmacyOrderState.articles.map(line => {
            const { id, ...rest } = line;
            return rest;
          }),
        }),
      ).then(result => {
        notification.success({
          message: SuccesssMsgTitle,
          description: 'la commande a été enregistrée avec succès',
        });
        setIsLoading(false);
        setCreatedOrderData(result);
        setShowOrderPdf(true);
      });
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadSuppliers();
    loadArticles();
  }, []);

  const renderOrderPDF = () => {
    return (
      <PdfDrawerViewer
        drawerTitle={`Commande d'articles`}
        closeDrawer={() => setShowOrderPdf(false)}
        extraActions={
          <>
            <StyledButton
              key='cancel_invoice'
              type='primary'
              size='middle'
              danger
              disabled={isLoading}
              onClick={() => redirectToOrderPage()}>
              Quitter
            </StyledButton>
          </>
        }
        isDrawerOpen={showOrderPdf}>
        <RenderDocument<CreatePharmacyOrderResponse | undefined>
          documentData={createdOrderData}
          documentType='PHARMACY_ARTICLE_ORDER'
          isDuplicata={false}
        />
      </PdfDrawerViewer>
    );
  };

  const renderSelector = () => {
    return (
      <StyledBlocContainer
        style={{
          backgroundColor: `${chartColors.blue50}20`,
          padding: '20px 20px',
        }}>
        <StyledRow gutter={[12, 12]}>
          <StyledCol span={24}>
            <FormFieldLabel libelle='Fournisseur' />
            <StyledSelectPicker
              disabled={isLoading}
              placeholder='Sélectionner un fournisseur'
              onClear={() => setIdSupplier(null)}
              style={{ width: '100%' }}
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value: any) => {
                setIdSupplier(value);
                filterArticlesBySupplierId(value);
              }}
              defaultValue={''}
              value={idSupplier}>
              {_.map(suppliers, item => (
                <StyledSelectOption
                  key={item.id}
                  value={item.id}
                  data={item}
                  label={item.name}>
                  {item.name}
                </StyledSelectOption>
              ))}
            </StyledSelectPicker>
          </StyledCol>
        </StyledRow>
      </StyledBlocContainer>
    );
  };

  const renderConfirmButton = () => {
    return (
      <StyledCol span={24}>
        {!_.isEmpty(pharmacyOrderState.articles) && !_.isNull(idSupplier) ? (
          <StyledButton
            block
            type='primary'
            loading={isLoading}
            disabled={isLoading}
            onClick={() => createOrder()}>
            Créer la commande
          </StyledButton>
        ) : null}
      </StyledCol>
    );
  };

  const renderContent = () => {
    return (
      <StyledLayoutGridWrapper>
        <PageContentLeftLayout headerTitle={renderHeader()}>
          {renderSelector()}
        </PageContentLeftLayout>
        <PageContentRightLayout>
          <div style={{ padding: '0px 20px', paddingBottom: 180 }}>
            <SectionHeader
              title='Article(s) Sélectionné'
              count={pharmacyOrderState.articles.length}
            />
            <StyledRow gutter={[16, 16]}>
              <StyledCol span={24}>
                <AddRemoveArticles
                  reducer={pharmacyOrderReducer}
                  state={pharmacyOrderState}
                  items={filteredArticles}
                  isLoading={isLoading}
                  listTitle='Sélectionnez un ou plusieurs articles'
                  validationComponent={renderConfirmButton}
                />
              </StyledCol>
            </StyledRow>
          </div>
        </PageContentRightLayout>
      </StyledLayoutGridWrapper>
    );
  };

  return (
    <>
      {renderContent()} {showOrderPdf === true && renderOrderPDF()}
    </>
  );
};

export default OrderArticleForm;
