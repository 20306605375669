import { App as AppContainer } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import React, { useEffect, useReducer, useState } from 'react';
import {
  createBiologicalPrescriptionApi,
  createImageryPrescriptionApi,
  searchBiologicalPrescriptionsApi,
  searchImageryPrescriptionsApi,
} from '../../../api/prescriptionService';
import Loader from '../../../components/loader';
import {
  CreateBiologicalPrescriptionRequestBody,
  CreateImageryPrescriptionRequestBody,
  CreateMedicalRecordApiResponse,
  IBiologicalPrescriptionItem,
  IImageryPrescriptionItem,
  SearchBiologicalPrescriptionResponse,
  SearchImageryPrescriptionResponse,
  UserDto,
} from '../../../types';
import { getDataFromStorage } from '../../../utils/storage';

import { TableRowSelection } from 'antd/es/table/interface';
import _ from 'lodash';
import { BsNodePlus, BsPrinter } from 'react-icons/bs';
import { useMediaQuery } from 'react-responsive';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import ResponsiveTable from '../../../components/responsiveTable';
import { StyledCol, StyledRow, StyledSpace } from '../../../components/style';
import PdfDrawerViewer from '../../../components/viewer/PdfDrawerViewer';
import {
  CreateBiologicalPrescriptionReducer,
  getCreateBiologicalPrescriptionDefaultInitialState,
} from '../../../reducers/prescription/createBiologicalPrescription';
import {
  CreateImageryPrescriptionReducer,
  getCreateImageryPrescriptionDefaultInitialState,
} from '../../../reducers/prescription/createImageryPrescription';
import chartColors from '../../../styles/color';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import RenderDocument from '../../document';
import { StyledMedicalRecordBlocContainer } from '../../style';
import AddRemoveBiologicalExam from './AddRemoveBiologicalExam';
import AddRemoveImageryExam from './AddRemoveImageryExam';

type BiologicPrescriptionsProps = IBiologicalPrescriptionItem;
type ImageryPrescriptionsProps = IImageryPrescriptionItem;

type PrescriptionsHistoryView = 'LIST' | 'BIOLOGICAL' | 'IMAGERY';

const PrescriptionsHistory: React.FC = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const { notification } = AppContainer.useApp();
  const [biologicalState, biologicalReducer] = useReducer(
    CreateBiologicalPrescriptionReducer,
    getCreateBiologicalPrescriptionDefaultInitialState(),
  );
  const [imageryState, imageryReducer] = useReducer(
    CreateImageryPrescriptionReducer,
    getCreateImageryPrescriptionDefaultInitialState(),
  );

  const medicalRecord =
    getDataFromStorage<CreateMedicalRecordApiResponse>('medicalRecord');
  const user = getDataFromStorage<UserDto>('connectedUserData');

  const [biologicalPrescriptionsData, setBiologicalPrescriptionsData] =
    useState<SearchBiologicalPrescriptionResponse>([]);

  const [imageryPrescriptionsData, setImageryPrescriptionsData] =
    useState<SearchImageryPrescriptionResponse>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [activeView, setActiveView] = useState<PrescriptionsHistoryView>('LIST');

  const [selectedBiologicalRowKeys, setSelectedBiologicalRowKeys] = useState<React.Key[]>(
    [],
  );
  const [selectedBiologicalPrescriptionsRow, setSelectedBiologicalPrescriptionsRow] =
    useState<SearchBiologicalPrescriptionResponse>([]);

  const [selectedImageryRowKeys, setSelectedImageryRowKeys] = useState<React.Key[]>([]);
  const [selectedImageryPrescriptionsRow, setSelectedImageryPrescriptionsRow] =
    useState<SearchImageryPrescriptionResponse>([]);

  const [showBiologicalPrescriptionPdf, setShowBiologicalPrescriptionPdf] =
    useState<boolean>(false);
  const [showImageryPrescriptionPdf, setShowImageryPrescriptionPdf] =
    useState<boolean>(false);

  const [showBiologicalPrescriptionModal, setShowBiologicalPrescriptionModal] =
    useState<boolean>(false);
  const [showImageryPrescriptionModal, setShowImageryPrescriptionModal] =
    useState<boolean>(false);

  const loadPatientBiologicalPrescriptions = async () => {
    setIsLoading(true);
    Promise.resolve(
      searchBiologicalPrescriptionsApi({
        idMedicalRecord: medicalRecord.id,
        idPatient: medicalRecord.patientDto.id as number,
        paid: null,
      }),
    )
      .then(result => {
        setBiologicalPrescriptionsData(result);
        setIsLoading(false);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadPatientImageryPrescriptions = async () => {
    setIsLoading(true);
    Promise.resolve(
      searchImageryPrescriptionsApi({
        idMedicalRecord: medicalRecord.id,
        idPatient: medicalRecord.patientDto.id as number,
        paid: false,
      }),
    )
      .then(result => {
        setImageryPrescriptionsData(result);
        setIsLoading(false);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadPatientBiologicalPrescriptions();
    loadPatientImageryPrescriptions();
  }, []);

  const mapBiologicalStateToRequestBody = (): CreateBiologicalPrescriptionRequestBody => {
    const exams = biologicalState.prescriptionLinesBiological.map(item => {
      delete item.id;
      return item;
    });
    const result: CreateBiologicalPrescriptionRequestBody = {
      idPatient: medicalRecord.patientDto.id ?? null,
      idMedecin: user.id,
      idMedicalRecord: medicalRecord.id,
      prescriptionLineBiologicalInputDto: exams,
    } as CreateBiologicalPrescriptionRequestBody;
    return result;
  };

  const mapImageryStateToRequestBody = (): CreateImageryPrescriptionRequestBody => {
    const exams = imageryState.prescriptionLinesImagery.map(item => {
      delete item.id;
      return item;
    });
    const result: CreateImageryPrescriptionRequestBody = {
      idPatient: medicalRecord.patientDto.id ?? null,
      idMedecin: user.id,
      idMedicalRecord: medicalRecord.id,
      prescriptionLines: exams,
    } as CreateImageryPrescriptionRequestBody;
    return result;
  };

  const createBiologicalPrescription = async () => {
    try {
      setIsLoading(true);
      await createBiologicalPrescriptionApi(mapBiologicalStateToRequestBody()).then(
        () => {
          notification.success({
            message: SuccesssMsgTitle,
            description: 'La prescription a bien été créée.',
          });
          loadPatientBiologicalPrescriptions();
          resetPrescription();
        },
      );
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
    } finally {
      setIsLoading(false);
    }
  };

  const createImageryPrescription = async () => {
    try {
      setIsLoading(true);
      await createImageryPrescriptionApi(mapImageryStateToRequestBody()).then(() => {
        notification.success({
          message: SuccesssMsgTitle,
          description: 'La prescription a bien été créée.',
        });
        loadPatientImageryPrescriptions();
        resetPrescription();
      });
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
    } finally {
      setIsLoading(false);
    }
  };

  const resetPrescription = () => {
    setActiveView('LIST');
    biologicalReducer({ type: 'setResetBiologicalPrescritionExams' });
    imageryReducer({ type: 'setResetImageryPrescritionExams' });
  };

  const resetPrescriptionPdfViewer = () => {
    setSelectedBiologicalPrescriptionsRow([]);
    setSelectedBiologicalRowKeys([]);
    setShowBiologicalPrescriptionPdf(false);
    setShowImageryPrescriptionPdf(false);
  };

  const handleModalBiologicalPrescription = () => {
    setShowBiologicalPrescriptionModal(state => !state);
  };
  const handleModalImageryPrescription = () => {
    setShowImageryPrescriptionModal(state => !state);
  };

  const handleDisplayBiologicalPrescrptionPdf = () => {
    setShowBiologicalPrescriptionPdf(true);
  };

  const handleDisplayImageryPrescrptionPdf = () => {
    setShowImageryPrescriptionPdf(true);
  };

  const getData = () => {
    const datas: string[][] = [];
    biologicalPrescriptionsData.forEach(item => {
      datas.push([item.createdDate, 'pdf']);
    });
    return datas;
  };

  const onSelectBiologicalPrescriptionChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: IBiologicalPrescriptionItem[],
  ) => {
    setSelectedBiologicalRowKeys(newSelectedRowKeys);
    setSelectedBiologicalPrescriptionsRow(selectedRows);
  };

  const onSelectImageryPrescriptionChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: IImageryPrescriptionItem[],
  ) => {
    setSelectedImageryRowKeys(newSelectedRowKeys);
    setSelectedImageryPrescriptionsRow(selectedRows);
  };

  const biologicalPrescriptionRowSelection: TableRowSelection<IBiologicalPrescriptionItem> =
    {
      selectedRowKeys: selectedBiologicalRowKeys,
      getCheckboxProps: (record: IBiologicalPrescriptionItem) => ({
        disabled:
          selectedBiologicalRowKeys.length === 1 &&
          !selectedBiologicalRowKeys.includes(record.id),
        // (record.treated === true && record.idInvoiceRef > 0) ||
        // name: record.invoicePlanHeadLabel,
      }),
      onChange: onSelectBiologicalPrescriptionChange,
      selections: [Table.SELECTION_NONE],
      hideSelectAll: true,
    };

  const imageryPrescriptionRowSelection: TableRowSelection<IImageryPrescriptionItem> = {
    selectedRowKeys: selectedImageryRowKeys,
    getCheckboxProps: (record: IImageryPrescriptionItem) => ({
      disabled:
        selectedImageryRowKeys.length === 1 &&
        !selectedImageryRowKeys.includes(record.id),
      // (record.treated === true && record.idInvoiceRef > 0) ||
      // name: record.invoicePlanHeadLabel,
    }),
    onChange: onSelectImageryPrescriptionChange,
    selections: [Table.SELECTION_NONE],
    hideSelectAll: true,
  };

  const BiologicalPrescriptioncolumnData: ColumnsType<BiologicPrescriptionsProps> = [
    {
      title: 'Numéro',
      dataIndex: 'id',
    },
    {
      title: 'Date',
      dataIndex: 'createdDate',
    },
    {
      title: 'Nbr. examens',
      render: (value, record) => (
        <>{record.prescriptionLineBiologicalDto.length} examens</>
      ),
    },
    {
      align: 'left',
      render: () => {
        return (
          <StyledSpace>
            {/* <StyledButton shape='circle' size='middle' icon={<FiPrinter />} />
            <StyledButton shape='circle' size='middle' icon={<FiEdit2 />} /> */}
          </StyledSpace>
        );
      },
    },
  ];

  const ImageryPrescriptioncolumnData: ColumnsType<ImageryPrescriptionsProps> = [
    {
      title: 'Numéro',
      dataIndex: 'id',
    },
    {
      title: 'Date',
      dataIndex: 'createdDate',
    },
    {
      title: 'Nbr. examens',
      render: (value, record) => <>{record.prescriptionLineImageryDto.length} examens</>,
    },
    {
      align: 'left',
      render: () => {
        return (
          <StyledSpace>
            {/* <StyledButton shape='circle' size='middle' icon={<FiPrinter />} />
            <StyledButton shape='circle' size='middle' icon={<FiEdit2 />} /> */}
          </StyledSpace>
        );
      },
    },
  ];

  const renderBiologicalValidationAction = () => {
    return (
      <>
        {!_.isEmpty(biologicalState.prescriptionLinesBiological) && (
          <StyledButton
            block
            type='primary'
            loading={isLoading}
            disabled={isLoading}
            onClick={() => createBiologicalPrescription()}>
            Créer une prescription
          </StyledButton>
        )}
        <StyledButton
          block
          danger
          type='text'
          disabled={isLoading}
          onClick={() => resetPrescription()}>
          Annuler la prescription
        </StyledButton>
      </>
    );
  };

  const renderBiologicalPrescriptionHeaderActions = () => {
    return (
      <StyledSpace>
        <StyledButton
          loading={isLoading}
          disabled={isLoading}
          size='middle'
          icon={<BsNodePlus />}
          block
          type='dashed'
          onClick={() => {
            setShowBiologicalPrescriptionModal(true);
          }}>
          Sélectionnez un examen
        </StyledButton>

        {renderBiologicalValidationAction()}
      </StyledSpace>
    );
  };

  const renderSelectedBiologicalExams = () => {
    return (
      <>
        <SectionHeader title='Nouvelle prescription biologique'>
          {renderBiologicalPrescriptionHeaderActions()}
        </SectionHeader>
        <StyledRow gutter={[20, 20]}>
          <StyledCol span={24}>
            <AddRemoveBiologicalExam
              key={'biological_exam'}
              listTitle='Sélectionnez des examens biologiques'
              reducer={biologicalReducer}
              state={biologicalState}
              isLoading={false}
              handleCloseModalVisibility={handleModalBiologicalPrescription}
              isModalVisible={showBiologicalPrescriptionModal}
              // userData={user}
            />
          </StyledCol>
        </StyledRow>
      </>
    );
  };

  const renderImageryValidationAction = () => {
    return (
      <>
        {!_.isEmpty(imageryState.prescriptionLinesImagery) && (
          <StyledButton
            type='primary'
            loading={isLoading}
            disabled={isLoading}
            onClick={() => createImageryPrescription()}>
            Créer une prescription
          </StyledButton>
        )}
        <StyledButton
          danger
          type='text'
          disabled={isLoading}
          onClick={() => resetPrescription()}>
          Annuler la prescription
        </StyledButton>
      </>
    );
  };

  const renderImageryPrescriptionHeaderActions = () => {
    return (
      <StyledSpace style={{ marginTop: 20 }}>
        <StyledButton
          loading={isLoading}
          disabled={isLoading}
          size='middle'
          icon={<BsNodePlus />}
          block
          type='dashed'
          onClick={() => {
            setShowImageryPrescriptionModal(true);
          }}>
          Ajoutez un examen
        </StyledButton>
        {renderImageryValidationAction()}
      </StyledSpace>
    );
  };

  const renderSelectedImageryExams = () => {
    return (
      <>
        <SectionHeader title="Nouvelle prescription d'imagerie">
          {renderImageryPrescriptionHeaderActions()}
        </SectionHeader>
        <StyledRow gutter={[20, 20]}>
          <StyledCol span={24}>
            <AddRemoveImageryExam
              key={'imagery_exam'}
              listTitle="Sélectionnez des examens d'imagerie"
              reducer={imageryReducer}
              state={imageryState}
              isLoading={false}
              userData={user}
              isModalVisible={showImageryPrescriptionModal}
              handleCloseModalVisibility={handleModalImageryPrescription}
            />
          </StyledCol>
        </StyledRow>
      </>
    );
  };

  const renderBiologicalListActions = () => {
    return (
      <StyledSpace>
        {_.isEmpty(selectedBiologicalRowKeys) ? (
          <StyledButton
            block
            type='primary'
            loading={isLoading}
            disabled={isLoading}
            onClick={() => setActiveView('BIOLOGICAL')}>
            Créer une prescription biologique
          </StyledButton>
        ) : (
          <>
            <StyledButton
              type='primary'
              style={{ backgroundColor: chartColors.success }}
              loading={isLoading}
              disabled={isLoading}
              icon={<BsPrinter />}
              onClick={() => handleDisplayBiologicalPrescrptionPdf()}>
              Voir la prescription biologique
            </StyledButton>
          </>
        )}
      </StyledSpace>
    );
  };

  const renderImageryListActions = () => {
    return (
      <StyledSpace>
        {_.isEmpty(selectedImageryRowKeys) ? (
          <StyledButton
            block
            type='primary'
            loading={isLoading}
            disabled={isLoading}
            onClick={() => setActiveView('IMAGERY')}>
            Créer une prescription d&apos;imagerie
          </StyledButton>
        ) : (
          <>
            <StyledButton
              type='primary'
              style={{ backgroundColor: chartColors.success }}
              loading={isLoading}
              disabled={isLoading}
              icon={<BsPrinter />}
              onClick={() => handleDisplayImageryPrescrptionPdf()}>
              Voir la prescription d&apos;imagerie
            </StyledButton>
          </>
        )}
      </StyledSpace>
    );
  };

  const renderBiologicalPrescriptionList = () => {
    return (
      <StyledMedicalRecordBlocContainer
        style={{ backgroundColor: '#FFF', padding: '10px 10px' }}>
        <SectionHeader title='Prescriptions biologiques'>
          {renderBiologicalListActions()}
        </SectionHeader>
        {isTabletOrMobile ? (
          <ResponsiveTable headerNames={['Date', 'Document']} data={getData()} />
        ) : (
          <Table<BiologicPrescriptionsProps>
            rowKey='id'
            dataSource={biologicalPrescriptionsData}
            size='small'
            columns={BiologicalPrescriptioncolumnData}
            pagination={false}
            bordered={false}
            rowSelection={biologicalPrescriptionRowSelection}
          />
        )}
      </StyledMedicalRecordBlocContainer>
    );
  };

  const renderImageryPrescriptionList = () => {
    return (
      <StyledMedicalRecordBlocContainer
        style={{ backgroundColor: '#FFF', padding: '10px 10px' }}>
        <SectionHeader title="Prescriptions d'imagerie">
          {renderImageryListActions()}
        </SectionHeader>
        {isTabletOrMobile ? (
          <ResponsiveTable headerNames={['Date', 'Document']} data={getData()} />
        ) : (
          <Table<ImageryPrescriptionsProps>
            rowKey='id'
            dataSource={imageryPrescriptionsData}
            size='small'
            columns={ImageryPrescriptioncolumnData}
            pagination={false}
            bordered={false}
            rowSelection={imageryPrescriptionRowSelection}
          />
        )}
      </StyledMedicalRecordBlocContainer>
    );
  };

  const renderPrescriptionsList = () => {
    return (
      <StyledRow gutter={[16, 16]}>
        <StyledCol span={24}> {renderBiologicalPrescriptionList()} </StyledCol>
        <StyledCol span={24}> {renderImageryPrescriptionList()} </StyledCol>
      </StyledRow>
    );
  };

  const renderActiveView = () => {
    switch (activeView) {
      case 'LIST':
        return renderPrescriptionsList();

      case 'BIOLOGICAL':
        return renderSelectedBiologicalExams();

      case 'IMAGERY':
        return renderSelectedImageryExams();

      default:
        return renderPrescriptionsList();
    }
  };

  const renderContent = () => {
    return (
      <StyledRow justify='end' gutter={[16, 16]}>
        <StyledCol span={24}>
          <StyledMedicalRecordBlocContainer style={{ padding: '10px 10px' }}>
            {renderActiveView()}
          </StyledMedicalRecordBlocContainer>
        </StyledCol>
      </StyledRow>
    );
  };

  return (
    <>
      <Loader showSpinner={isLoading}>{renderContent()}</Loader>

      {/* Afficher l'ordonnance biologique sélectionné*/}
      {showBiologicalPrescriptionPdf && (
        <PdfDrawerViewer
          drawerTitle={`Apercu de l'ordonnance`}
          closeDrawer={resetPrescriptionPdfViewer}
          extraActions={
            <>
              <StyledButton
                key='cancel_viewer'
                type='primary'
                size='middle'
                danger
                disabled={isLoading}
                onClick={resetPrescriptionPdfViewer}>
                Quitter
              </StyledButton>
            </>
          }
          isDrawerOpen={showBiologicalPrescriptionPdf}>
          <RenderDocument<IBiologicalPrescriptionItem>
            documentData={selectedBiologicalPrescriptionsRow[0]}
            documentType='PRESCRIPTION_PDF'
            isDuplicata={false}
          />
        </PdfDrawerViewer>
      )}

      {/* Afficher l'ordonnance d'imagerie sélectionné*/}
      {showImageryPrescriptionPdf && (
        <PdfDrawerViewer
          drawerTitle={`Apercu de l'ordonnance`}
          closeDrawer={resetPrescriptionPdfViewer}
          extraActions={
            <>
              <StyledButton
                key='cancel_viewer'
                type='primary'
                size='middle'
                danger
                disabled={isLoading}
                onClick={resetPrescriptionPdfViewer}>
                Quitter
              </StyledButton>
            </>
          }
          isDrawerOpen={showImageryPrescriptionPdf}>
          <RenderDocument<IImageryPrescriptionItem>
            documentData={selectedImageryPrescriptionsRow[0]}
            documentType='PRESCRIPTION_PDF'
            isDuplicata={false}
          />
        </PdfDrawerViewer>
      )}
    </>
  );
};

export default PrescriptionsHistory;
