/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unescaped-entities */
import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import _ from 'lodash';
import React, { Fragment, PropsWithChildren } from 'react';
import chartColors from '../../styles/color';
import { IMedicalRecordDetails, ISite } from '../../types';

interface MedicalRecordDocProps {
  data: IMedicalRecordDetails | IMedicalRecordDetails[];
  title?: string;
  isManyRecord: boolean;
  siteInfos: ISite;
}

interface ISectionTitle {
  title: string;
  description?: string;
}
interface ISectionGroupTitle {
  title: string;
}

type MedicalRecordLineProps = {
  label: string;
  value?: string | number;
};

/* The `MedicalRecordLine` component is a functional component that takes in two props: `label` and
`value`. It renders a view (`<View>`) with a specific style (`styles.recordLine`) and a flex
direction of row. Inside the view, it renders two text components (`<Text>`) with specific styles
(`styles.bold` and `styles.label` for the label, and `styles.bold` and `styles.recordValue` for the
value). The label and value are passed as children to the text components. */
const MedicalRecordLine = ({ label, value }: MedicalRecordLineProps) => {
  return (
    <View style={[styles.recordLine, { display: 'flex', flexDirection: 'row' }]}>
      <Text style={[styles.bold, styles.label]}> {label}: </Text>
      <Text style={[styles.bold, styles.recordValue]}> {value} </Text>
    </View>
  );
};

const MedicalRecordDoc: React.FC<MedicalRecordDocProps> = ({
  data,
  isManyRecord = false,
  siteInfos,
}) => {
  /**
   * The function `renderPageFooter` returns a Text component with a fixed style, displaying the text
   * "Clinique Demo - ApiPatient, Tous droits réservés".
   * @returns The renderPageFooter function is returning a Text component with the text "Clinique Demo
   * - ApiPatient, Tous droits réservés" and the style defined by the styles.pageFooter object. The
   * "fixed" prop is also being passed to the Text component.
   */
  // const renderPageFooter = () => {
  //   return (
  //     <Text style={styles.pageFooter} fixed>
  //       Clinique Demo - ApiPatient, Tous droits réservés
  //     </Text>
  //   );
  // };

  /* The `renderPageHeader` function is responsible for rendering the header section of each page in
  the medical record document. It takes in a `createdDate` parameter, which is a string representing
  the date the medical record was created. */
  const renderPageHeader = (createdDate?: string) => {
    return (
      <View wrap={false} style={styles.header}>
        <View wrap={false}>
          <Image
            src={{ uri: siteInfos.url as string, method: 'GET', headers: {}, body: '' }}
            style={{ width: 100 }}
          />
        </View>
        {createdDate && (
          <View wrap={false} style={styles.headerDateContainer}>
            <Text style={styles.bold}>Crée le: {createdDate}</Text>
          </View>
        )}
      </View>
    );
  };

  const SectionTitle: React.FC<ISectionTitle> = ({ title, description }) => {
    return (
      <>
        <View
          style={[
            styles.sectionTitleContainer,
            { marginBottom: 10, backgroundColor: chartColors.deepBlue },
          ]}>
          <Text style={[styles.bold, { color: chartColors.white, fontSize: 18 }]}>
            {title}
          </Text>
        </View>
        <View>
          {description && (
            <Text
              style={[
                styles.sectionTitleDescription,
                { color: chartColors.black, fontSize: 11 },
              ]}>
              {description}
            </Text>
          )}
        </View>
      </>
    );
  };

  const SectionGroupTitle: React.FC<ISectionGroupTitle> = ({ title }) => {
    return (
      <View
        wrap={false}
        style={{ width: '100%', borderBottom: `1px solid ${chartColors.lightGray}` }}>
        <Text style={[styles.bold, { textTransform: 'uppercase' }]}>{title}</Text>
      </View>
    );
  };

  const PhysicalExamItem = ({
    title,
    value,
    unit,
  }: {
    title: string;
    value: any;
    unit: string;
  }) => {
    return (
      <View wrap={false} style={{ flex: 1 }}>
        <Text style={{ fontSize: '10px' }}>{title}</Text>
        <Text style={[styles.bold]}>{`${value} ${!_.isNull(value) ? unit : ''}`}</Text>
      </View>
    );
  };

  const SectionGroupContainer: React.FC<
    PropsWithChildren<{ groupTitle?: string; showBgColor?: boolean }>
  > = ({ children, groupTitle, showBgColor = true }) => {
    return (
      <>
        <View
          wrap={true}
          style={[
            styles.groupContainerStyle,
            { backgroundColor: showBgColor ? chartColors.blue50 : 'transparent' },
          ]}>
          {groupTitle && (
            <Text
              style={[
                styles.bold,
                {
                  fontSize: '10px',
                  textTransform: 'uppercase',
                  color: chartColors.deepBlue,
                  marginBottom: 10,
                },
              ]}>
              {groupTitle}
            </Text>
          )}
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>{children}</View>
        </View>
      </>
    );
  };

  const renderClinicExam = (data: IMedicalRecordDetails) => {
    const {
      medicalRecordExaminationDto,
      medicalRecordAntecedentDto,
      medicalRecordPhysicalDto,
    } = data;

    return (
      <>
        <View wrap={false}>
          <SectionTitle title='Section 1: Examen Clinique' />
        </View>

        <View style={{ margin: '15px 0px', rowGap: 10 }} wrap={false}>
          <SectionGroupTitle title='Interrogatoires' />
          {/* Reasons of Consultation */}
          <SectionGroupContainer groupTitle='Motif de consultation'>
            {medicalRecordExaminationDto.reasonForConsultation.map((reason, idx) => (
              <Text key={idx}>
                {`${reason.libelle} ${
                  medicalRecordExaminationDto.reasonForConsultation.length !== idx + 1
                    ? ' | '
                    : ''
                }`}
              </Text>
            ))}
          </SectionGroupContainer>

          {/* Functional Signs */}
          <SectionGroupContainer groupTitle='Signes fonctionnels'>
            {medicalRecordExaminationDto.functionalSigns.map((sign, idx) => (
              <Text key={idx}>
                {`${sign.libelle} ${
                  medicalRecordExaminationDto.functionalSigns.length !== idx + 1
                    ? ' | '
                    : ''
                }`}
              </Text>
            ))}
          </SectionGroupContainer>

          <SectionGroupContainer groupTitle='Traitements pris'>
            {medicalRecordExaminationDto.treatmentsTaken.map((treatment, idx) => (
              <Text key={idx}>
                {`${treatment.libelle} ${
                  medicalRecordExaminationDto.treatmentsTaken.length !== idx + 1
                    ? ' | '
                    : ''
                }`}
              </Text>
            ))}
          </SectionGroupContainer>
          <SectionGroupContainer groupTitle='Commentaires'>
            <Text>{medicalRecordExaminationDto.comments}</Text>
          </SectionGroupContainer>
        </View>

        <View style={{ margin: '15px 0px', rowGap: 10 }} wrap={false}>
          <SectionGroupTitle title='Antécédents' />
          <SectionGroupContainer groupTitle=''>
            {medicalRecordAntecedentDto.antecedents.map((antecedent, idx) => (
              <Text key={idx}>
                {`${antecedent.libelle} ${
                  medicalRecordAntecedentDto.antecedents.length !== idx + 1 ? ' | ' : ''
                }`}
              </Text>
            ))}
          </SectionGroupContainer>
        </View>

        <View style={[{ margin: '15px 0px', rowGap: 10 }]} wrap={false}>
          <SectionGroupTitle title='Examen Physique' />
          <View
            wrap={true}
            style={[
              styles.groupContainerStyle,
              {
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: chartColors.blue50,
              },
            ]}>
            <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
              <PhysicalExamItem
                title='Tension Artérielle'
                value={medicalRecordPhysicalDto.ta}
                unit='mmHg'
              />
              <PhysicalExamItem
                title='Pouls/Fréquence cardiaque'
                value={medicalRecordPhysicalDto.fc}
                unit='bpm'
              />
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
              <PhysicalExamItem
                title='Température'
                value={medicalRecordPhysicalDto.temperature}
                unit='°C'
              />
              <PhysicalExamItem
                title='Saturation en oxygène'
                value={medicalRecordPhysicalDto.spO2 ?? null}
                unit='%'
              />
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
              <PhysicalExamItem
                title='Poids'
                value={medicalRecordPhysicalDto.weight ?? null}
                unit='Kg'
              />
              <PhysicalExamItem
                title='Taille'
                value={medicalRecordPhysicalDto.size}
                unit='m'
              />
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
              <PhysicalExamItem
                title='Fréquence respiratoire'
                value={medicalRecordPhysicalDto.respiratory_rate}
                unit='cpm'
              />
              <PhysicalExamItem
                title='Diurèse'
                value={medicalRecordPhysicalDto.diurese}
                unit='ml/24'
              />
            </View>
          </View>
          <SectionGroupContainer groupTitle='Présentation clinique'>
            <Text>{medicalRecordPhysicalDto.clinicPresentation}</Text>
          </SectionGroupContainer>
          <SectionGroupContainer groupTitle='Résumé'>
            <Text>{medicalRecordPhysicalDto.resume}</Text>
          </SectionGroupContainer>
          <SectionGroupContainer groupTitle='Hypothèses de diagnostic'>
            <Text>{medicalRecordPhysicalDto.diagnosticHypothese}</Text>
          </SectionGroupContainer>
        </View>

        {/* {renderPageFooter()} */}
      </>
    );
  };

  const renderParaclinicExam = () => {
    return (
      <Page style={styles.body}>
        <View>
          <SectionTitle title='Section 2: Examen Paraclinique' />
        </View>

        <View style={{ margin: '15px 0px' }} wrap={false}>
          <SectionGroupTitle title='Examens Biologiques' />
          <View></View>
        </View>

        <View style={{ margin: '15px 0px' }} wrap={false}>
          <SectionGroupTitle title="Examens d'imagerie" />
          <View></View>
        </View>

        {/* {renderPageFooter()} */}
      </Page>
    );
  };

  const renderDiagnostic = () => {
    return (
      <Page style={styles.body}>
        <View>
          <SectionTitle title='Section 3: Diagnostic' />
        </View>
        {/* {renderPageFooter()} */}
      </Page>
    );
  };

  const renderEvolution = () => {
    return (
      <Page style={styles.body}>
        <View>
          <SectionTitle title='Section 4: Evolution' />
        </View>
        {/* {renderPageFooter()} */}
      </Page>
    );
  };

  /* The `renderPageTitle` function is responsible for rendering the title of the medical record on
  each page. It takes in a `medicalRecordNumber` parameter, which is a string representing the
  number of the medical record. */
  const renderPageTitle = (title: string, medicalRecordNumber?: string) => {
    return (
      <View
        wrap={false}
        style={{
          marginBottom: 10,
          border: `1px solid ${chartColors.black}`,
          backgroundColor: chartColors.lightGray,
        }}>
        <Text
          style={{
            textAlign: 'center',
            fontSize: 40,
            fontWeight: 600,
            textTransform: 'capitalize',
          }}>
          {title} {!_.isEmpty(medicalRecordNumber) && ` ${medicalRecordNumber}`}
        </Text>
      </View>
    );
  };

  /* The `renderSingleMedicalRecord` function is responsible for rendering a single medical record on a
  page. It takes in a `data` object of type `IMedicalRecordDetails` as a parameter. */
  const renderSingleMedicalRecord = (data: IMedicalRecordDetails, pageIndex: number) => {
    return (
      <>
        <Page style={styles.body}>
          {/* Header */}
          {renderPageHeader(data.createdDate)}

          {/* Titre */}
          {renderPageTitle('Dossier:', data.numMedicalRecord)}

          {/* Contenu ou sections */}
          <View style={styles.sectionContainer}>
            <MedicalRecordLine label='Dossier No' value={data.numMedicalRecord} />
            {pageIndex === 0 && (
              <>
                <MedicalRecordLine label='Civilité' value={data.patientDto.civility} />
                <MedicalRecordLine label='CNI' value={data.patientDto.cni} />
                <MedicalRecordLine label='Nom' value={data.patientDto.firstname} />
                <MedicalRecordLine label='Prénoms' value={data.patientDto.name} />
                <MedicalRecordLine label='Né le' value={data.patientDto.birthDate} />
                <MedicalRecordLine
                  label='Téléphone'
                  value={data.patientDto.phoneNumber}
                />
                <MedicalRecordLine
                  label='Nationalité'
                  value={data.patientDto.nationality}
                />
                <MedicalRecordLine label='Ethnie' value={data.patientDto.ethnic} />
                <MedicalRecordLine
                  label='Lieu de résidence'
                  value={data.patientDto.placeOfResidence}
                />
              </>
            )}
            <MedicalRecordLine label="Date d'entrée" value={data.enterDate} />
            <MedicalRecordLine label='Date de sortie' value={data.closingDate} />
          </View>

          {/* {renderPageFooter()} */}
        </Page>
        <Page style={styles.body}>{renderClinicExam(data)}</Page>
        {renderParaclinicExam()}
        {renderDiagnostic()}
        {renderEvolution()}
      </>
    );
  };

  /**
   * The function `renderMultipleRecords` takes an array of `IMedicalRecordDetails` objects
   * and maps over them to render each record individually.
   * @param {IMedicalRecordDetails[]} medicalRecords - An array of objects representing
   * medical records. Each object should have the following properties:
   * @returns The function `renderMultipleRecords` returns an array of rendered medical records.
   */
  const renderMultipleRecords = (medicalRecords: IMedicalRecordDetails[]) => {
    const patientData = medicalRecords[0].patientDto;
    return (
      <>
        {/* Page de couverture */}
        <Page style={styles.body}>
          {renderPageHeader()}

          {renderPageTitle('Infos Patient')}

          {/* Contenu ou sections */}
          <View style={styles.sectionContainer}>
            <MedicalRecordLine label='Civilité' value={patientData.civility} />
            <MedicalRecordLine label='CNI' value={patientData.cni} />
            <MedicalRecordLine label='Nom' value={patientData.firstname} />
            <MedicalRecordLine label='Prénoms' value={patientData.name} />
            <MedicalRecordLine label='Né le' value={patientData.birthDate} />
            <MedicalRecordLine label='Téléphone' value={patientData.phoneNumber} />
            <MedicalRecordLine label='Nationalité' value={patientData.nationality} />
            <MedicalRecordLine label='Ethnie' value={patientData.ethnic} />
            <MedicalRecordLine
              label='Lieu de résidence'
              value={patientData.placeOfResidence}
            />
          </View>

          {/* Pied de page */}
          {/* {renderPageFooter()} */}
        </Page>

        {medicalRecords.map((data, idx) => (
          <Fragment key={idx}>
            <Page style={styles.body}>
              {renderPageTitle('Dossier:', data.numMedicalRecord)}
              {renderClinicExam(data)}
            </Page>
            {renderParaclinicExam()}
            {renderDiagnostic()}
            {renderEvolution()}
          </Fragment>
        ))}
      </>
    );
  };

  return (
    <Document>
      {isManyRecord === true
        ? renderMultipleRecords(data as IMedicalRecordDetails[])
        : renderSingleMedicalRecord(data as IMedicalRecordDetails, 0)}
    </Document>
  );
};

Font.register({
  family: 'Metropolis',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
  fonts: [
    { src: require('../../assets/fonts/Metropolis-Regular.woff') },
    { src: require('../../assets/fonts/Metropolis-Bold.woff'), fontWeight: 600 },
    { src: require('../../assets/fonts/Metropolis-Light.woff'), fontWeight: 400 },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 12,
    fontFamily: 'Metropolis',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 0,
  },
  headerDateContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    height: 42,
  },
  bold: {
    fontWeight: 600,
  },
  pageFooter: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  recordLine: {
    marginBottom: 10,
  },
  label: {
    fontSize: 16,
    color: chartColors.blue,
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 100,
  },
  recordValue: {
    fontSize: 16,
    borderBottom: '0.5px solid dotted',
    borderBottomColor: 'grey',
    borderBottomStyle: 'dotted',
    marginLeft: 20,
    width: '98%',
  },
  sectionTitleContainer: {
    padding: '0px 10px',
    display: 'flex',
    border: `1px solid ${chartColors.deepBlue}`,
  },
  sectionTitleDescription: {
    fontWeight: 400,
  },
  groupContainerStyle: {
    padding: '10px',
    border: '1px dotted',
  },
});

export default MedicalRecordDoc;
