import { CreateInterrogatoireAction, CreateInterrogatoireState } from './types';

export const getCreateInterrogatoireDefaultInitialState =
  (): CreateInterrogatoireState => {
    return {
      reasonOfConsultation: [],
      functionalSigns: [],
      treatments: [],
      comments: '',
      invalidInputs: [],
    } as CreateInterrogatoireState;
  };

export const createInterrogatoireReducer = (
  state: CreateInterrogatoireState,
  action: CreateInterrogatoireAction,
): CreateInterrogatoireState => {
  const newState = { ...state };
  switch (action.type) {
    case 'setReasonOfConsultation':
      newState.reasonOfConsultation = action.payload;
      return newState;
    case 'setFonctionnalSigns':
      newState.functionalSigns = action.payload;
      return newState;
    case 'setTreatmentsTaken':
      newState.treatments = action.payload;
      return newState;
    case 'setComments':
      newState.comments = action.payload;
      return newState;
    case 'setInvalidInputs':
      newState.invalidInputs = action.payload;
      return newState;
    default:
      return state;
  }
};
