import { Fragment, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { StyledButton } from '../../../components/button/style';
import { StyledSteps } from '../../../components/style';
import { UserDto } from '../../../types';
import {
  ANTECEDENT_TAB_PERM,
  DOSSIER_TAB_PERM,
  EXAM_PHYSIQ_TAB_PERM,
  INTERROG_TAB_PERM,
} from '../../../utils/permissions';
import { getDataFromStorage } from '../../../utils/storage';
import { StyledMedicalRecordWrapper } from '../../style';
import Dossier from './Dossier';
import Physique from './Physique';
import Antecedent from './antecedent';
import Interrogatoire from './interrogatoire';

interface ClinicalExamProps {
  checkMedicalRecord: () => void;
}

type StepItem = {
  title: string;
  content: JSX.Element;
  rights: string[];
};

const ClinicalExam: React.FC<ClinicalExamProps> = ({ checkMedicalRecord }) => {
  const stepsItems: Array<StepItem> = [
    {
      title: 'Dossier',
      content: (
        <Dossier handleNext={() => next()} checkMedicalRecord={checkMedicalRecord} />
      ),
      rights: DOSSIER_TAB_PERM,
    },
    {
      title: 'Interrogatoires',
      content: <Interrogatoire handleNext={() => next()} handlePrev={() => prev()} />,
      rights: INTERROG_TAB_PERM,
    },
    {
      title: 'Antécédents',
      content: <Antecedent handleNext={() => next()} handlePrev={() => prev()} />,
      rights: ANTECEDENT_TAB_PERM,
    },
    {
      title: 'Examen Physique',
      content: <Physique handlePrev={() => prev()} />,
      rights: EXAM_PHYSIQ_TAB_PERM,
    },
  ];

  const [filteredSteps, setFilteredSteps] = useState<Array<StepItem>>([]);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const currentUser = getDataFromStorage<UserDto>('connectedUserData');
  const userRights: Array<string> = currentUser.rights.map(r => r.code);
  const [activeStep, setActiveStep] = useState<number>(0);

  useEffect(() => {
    const filtered = stepsItems.filter(step =>
      step.rights.some(right => userRights.includes(right)),
    );

    setFilteredSteps(filtered);
  }, [checkMedicalRecord]);

  const next = () => {
    setActiveStep(activeStep + 1);
  };

  const prev = () => {
    setActiveStep(activeStep - 1);
  };

  console.log(activeStep);

  const renderStepsFooter = () => {
    return (
      <div
        style={{
          marginBottom: 24,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        {activeStep >= 1 && <StyledButton onClick={() => prev()}>Précédent</StyledButton>}
        {activeStep === 0 && <div> </div>}
        {activeStep < filteredSteps.length - 1 && (
          <StyledButton type='primary' onClick={() => next()}>
            Suivant
          </StyledButton>
        )}
      </div>
    );
  };

  return (
    <Fragment>
      <StyledSteps
        current={activeStep}
        type={isTabletOrMobile ? 'inline' : 'default'}
        items={filteredSteps}
        className='examen_clinique_inline_step'
      />
      <StyledMedicalRecordWrapper>
        {renderStepsFooter()}
        {filteredSteps[activeStep]?.content}
      </StyledMedicalRecordWrapper>
    </Fragment>
  );
};

export default ClinicalExam;
