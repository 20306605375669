import { Empty, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { type TableRowSelection } from 'antd/es/table/interface';
import _ from 'lodash';
import React from 'react';
import { StyledCardItem, StyledSpace } from '../../components/style';
import chartColors from '../../styles/color';
import {
  ImageryPrescriptionLineDto,
  SearchImageryPrescriptionLinesResponse,
} from '../../types';
import { thousandSeparatorFormat } from '../../utils/commun';

type ImageryPrescriptionsCardProps = {
  prescriptionLines: SearchImageryPrescriptionLinesResponse;
  selectedRowKeys: React.Key[];
  handleRowChange: (
    keys: React.Key[],
    rows: SearchImageryPrescriptionLinesResponse,
  ) => void;
  isLoading: boolean;
};

const ImageryPrescriptionsCard: React.FC<ImageryPrescriptionsCardProps> = ({
  prescriptionLines,
  selectedRowKeys,
  handleRowChange,
  isLoading,
}) => {
  const rowSelection: TableRowSelection<ImageryPrescriptionLineDto> = {
    selectedRowKeys,
    onChange: handleRowChange,
    hideSelectAll: false,
  };

  const imageryExamscolumn: ColumnsType<ImageryPrescriptionLineDto> = [
    {
      title: 'Examen',
      dataIndex: 'imageryExamDesignation',
      // render: value => <FormFieldLabel libelle={value} />,
    },
    {
      title: 'PU.',
      dataIndex: 'unitPrice',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
    },
    {
      title: 'Ratio',
      dataIndex: 'ratioLabel',
      align: 'right',
    },
    {
      title: 'Total',
      dataIndex: 'amountTotal',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
    },
    {
      title: 'Part Assu.',
      dataIndex: 'amountAssu',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
    },
    {
      title: 'Part Patient',
      dataIndex: 'amountPatient',
      align: 'right',
      render: value => thousandSeparatorFormat(value),
    },
  ];

  const renderContent = () => {
    return _.isEmpty(prescriptionLines) ? (
      <Empty description="Aucun examen d'imagerie effectué" />
    ) : (
      <Table
        rowKey='id'
        size='small'
        bordered={false}
        pagination={false}
        columns={imageryExamscolumn}
        dataSource={prescriptionLines}
        rowSelection={rowSelection}
        loading={isLoading}
      />
    );
  };

  return (
    <StyledCardItem
      styles={{
        header: {
          fontFamily: 'Metropolis',
          textTransform: 'uppercase',
          backgroundColor: chartColors.lightGray,
        },
      }}
      title={<StyledSpace>Examens d&apos;imagérie</StyledSpace>}
      size='small'>
      {renderContent()}
    </StyledCardItem>
  );
};

export default ImageryPrescriptionsCard;
