import { useState } from 'react';
import { ReferentialViewProps } from '../../../types';
import { IInsuranceItem } from '../../../types/insurance.types';
import InsuranceForm from './InsuranceForm';
import InsurancesList from './InsurancesList';

const InsuranceSetting = () => {
  const [activeView, setActiveView] = useState<ReferentialViewProps>('LIST_ITEMS');
  const [selectedInsurance, setSelectedInsurance] = useState<IInsuranceItem>();

  const handleNavigateToView = (newView: ReferentialViewProps) => {
    setActiveView(newView);
  };

  const handleUpdateSelectedRatio = (role?: IInsuranceItem) => {
    setSelectedInsurance(role);
  };

  const renderContent = () => {
    switch (activeView) {
      case 'LIST_ITEMS':
        return (
          <InsurancesList
            navigateToview={handleNavigateToView}
            updateCurrentItem={handleUpdateSelectedRatio}
          />
        );
      case 'EDIT_ITEM':
        return (
          <InsuranceForm
            navigateToview={handleNavigateToView}
            currentData={selectedInsurance}
          />
        );
      case 'NEW_ITEM':
        return <InsuranceForm navigateToview={handleNavigateToView} />;
    }
  };

  return <>{renderContent()}</>;
};

export default InsuranceSetting;
