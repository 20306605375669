import React, { useState } from 'react';
import { BiologicalAnalysisCategoryDto, ReferentialViewProps } from '../../../types';
import BiologicalExamCategoriesList from './BiologicalExamCategoriesList';
import BiologicalExamCategorieForm from './BiologicalExamCategorieForm';

const BiologicalExamCategoriesRef: React.FC = () => {
  const [activeView, setActiveView] = useState<ReferentialViewProps>('LIST_ITEMS');
  const [selectedCategory, setSelectedCategory] =
    useState<BiologicalAnalysisCategoryDto>();

  const handleNavigateToView = (newView: ReferentialViewProps) => {
    setActiveView(newView);
  };

  const handleUpdateSelectedCategorie = (categorie?: BiologicalAnalysisCategoryDto) => {
    setSelectedCategory(categorie);
  };

  const renderContent = () => {
    switch (activeView) {
      case 'LIST_ITEMS':
        return (
          <BiologicalExamCategoriesList
            navigateToview={handleNavigateToView}
            updateCurrentItem={handleUpdateSelectedCategorie}
          />
        );
      case 'EDIT_ITEM':
        return (
          <BiologicalExamCategorieForm
            navigateToview={handleNavigateToView}
            currentData={selectedCategory}
          />
        );
      case 'NEW_ITEM':
        return <BiologicalExamCategorieForm navigateToview={handleNavigateToView} />;
    }
  };

  return <>{renderContent()}</>;
};

export default BiologicalExamCategoriesRef;
