import styled from 'styled-components';
import { StyledTitle } from '../header/style';
import chartColors from '../../styles/color';

export const StyledPageHeaderActions = styled.div``;

export const StyledPageHeaderTitle = styled(StyledTitle)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledPageHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  height: 70px;
  flex: 1;
  position: sticky;
  top: 70px;
`;

export const StyledPageHeaderWrapper = styled.div`
  display: flex;
  height: 70px;
  /* background-color: #edf0f8; */
  background-color: ${chartColors.deepBlue}20;
  justify-content: center;
  position: sticky;
  top: 0px;
  z-index: 5;
  backdrop-filter: blur(10px);
  border-bottom: 1px solid ${chartColors.lightGray};
  /* filter: ${({ theme }) => `drop-shadow(0px 2px 20px ${theme.colors.blue})`}; */
`;
