import chartColors from '../../styles/color';
import { StyledModalTitle } from '../header/style';
import { StyledModal } from '../style';

interface ModalProps {
  children: React.ReactNode;
  isVisible: boolean;
  handleVisibility: () => void;
  width?: string;
  height?: string;
  title?: string;
  footer?: Array<React.ReactNode>;
  afterCloseModal?: () => void;
  onOkModal?: () => void;
  okButtonText?: string;
}

const CustomModal: React.FC<ModalProps> = ({
  children,
  isVisible,
  handleVisibility,
  title,
  footer,
  afterCloseModal,
  onOkModal,
  okButtonText,
  width,
}) => {
  return (
    <StyledModal
      destroyOnClose
      open={isVisible}
      closable={true}
      onCancel={handleVisibility}
      title={
        <StyledModalTitle style={{ color: chartColors.deepBlue }}>
          {title}
        </StyledModalTitle>
      }
      footer={footer}
      afterClose={afterCloseModal}
      onOk={onOkModal}
      okText={okButtonText}
      width={width}>
      {children}
    </StyledModal>
  );
};

export default CustomModal;
