import { App as AppContainer, Avatar, Space } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { FaUserInjured } from 'react-icons/fa';
import { FiPrinter, FiX } from 'react-icons/fi';
import {
  getMedicalRecordDetailsByIdsApi,
  getMedicalRecordsByPatient,
} from '../../api/dossierPatientService';
import RenderDocument from '../../containers/document';
import { StyleListContainer } from '../../containers/style';
import dayjsInstance from '../../dayjs';
import chartColors from '../../styles/color';
import {
  CreateMedicalRecordApiResponse,
  IMedicalRecordDetails,
  PatientDto,
} from '../../types';
import { getApiErrorMsg } from '../../utils/commun';
import { DATE_FORMAT, ErrorMsgTitle } from '../../utils/constants';
import { StyledButton } from '../button/style';
import PdfDrawerViewer from '../viewer/PdfDrawerViewer';
import ConstantesPatients from './ConstantesPatient';
import MedicalRecordPatientList from './MedicalRecordPatientList';
import {
  StyledMedicalRecordPatientActionsContainer,
  StyledMedicalRecordPatientContent,
  StyledMedicalRecordPatientHeaderContainer,
  StyledMedicalRecordPatientHeaderInfos,
  StyledMedicalRecordPatientNumeroContainer,
  StyledMedicalRecordPatientWrapper,
} from './style';
import { StyledSpace } from '../style';

interface DetailsMedicalRecordProps {
  patientData: PatientDto;
  resetSelectedRecord: () => void;
  showHeader?: boolean;
  showActions?: boolean;
  showContent?: boolean;
  showConstantes?: boolean;
  showMedicalRecords?: boolean;
}

const DetailsMedicalRecordPatient: React.FC<DetailsMedicalRecordProps> = ({
  patientData,
  resetSelectedRecord,
  showContent = true,
  showActions = true,
  showHeader = true,
  showConstantes = false,
  showMedicalRecords = true,
}) => {
  const [medicalRecords, setMedicalRecords] = useState<
    Array<CreateMedicalRecordApiResponse>
  >([]);

  const { notification } = AppContainer.useApp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showMedicalRecordsPdf, setShowMedicalRecordsPdf] = useState<boolean>(false);
  const [medicalRecordDetails, setMedicalRecordDetails] = useState<
    IMedicalRecordDetails[]
  >([]);

  const loadPatientRecords = () => {
    setIsLoading(true);
    Promise.resolve(getMedicalRecordsByPatient(patientData?.id as number))
      .then(result => {
        setMedicalRecords(
          result.sort(
            (a, b) =>
              new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime(),
          ),
        );
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadMedicalRecordDetails = async (medicalRecordIds: number[]) => {
    return Promise.resolve(getMedicalRecordDetailsByIdsApi(medicalRecordIds));
  };

  const handleSelectMedicalRecord = () => {
    setIsLoading(true);
    const idsMedicalRecords = medicalRecords.map(record => record.id);
    loadMedicalRecordDetails(idsMedicalRecords).then(res => {
      setMedicalRecordDetails(res);
      setShowMedicalRecordsPdf(true);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!_.isEmpty(patientData)) {
      loadPatientRecords();
    }
  }, [patientData]);

  const handleCloseViewer = () => {
    setShowMedicalRecordsPdf(false);
  };

  const renderAge = () => {
    const age = dayjsInstance(new Date()).diff(
      dayjsInstance(patientData.birthDate as string, DATE_FORMAT),
      'year',
    );

    if (age > 1) {
      return `${age} an(s)`;
    }
    return `${age} an`;
  };

  const renderMedicalRecordHeader = () => {
    return (
      <StyledMedicalRecordPatientHeaderContainer>
        <StyledMedicalRecordPatientHeaderInfos>
          <Avatar shape='circle' size={80} icon={<FaUserInjured />} />
          <div>
            <div
              style={{
                fontSize: '3rem',
                fontWeight: '500',
                marginBottom: -10,
                textTransform: 'uppercase',
              }}>
              {`${patientData.name} ${patientData.firstname}`}
            </div>
            <div>
              <StyledSpace split='|'>
                {renderAge()}
                <span>{patientData.phoneNumber}</span>
              </StyledSpace>
            </div>
            <div>{patientData.placeOfResidence}</div>
          </div>
        </StyledMedicalRecordPatientHeaderInfos>
        <StyledMedicalRecordPatientNumeroContainer>
          <StyledButton
            size='large'
            shape='circle'
            type='primary'
            htmlType='reset'
            onClick={() => resetSelectedRecord()}>
            <FiX />
          </StyledButton>
        </StyledMedicalRecordPatientNumeroContainer>
      </StyledMedicalRecordPatientHeaderContainer>
    );
  };

  const rendermedicalRecordActions = () => {
    return (
      <StyledMedicalRecordPatientActionsContainer>
        <Space split='|'>
          {/* <StyledButton
            icon={<FiPhoneOutgoing color={chartColors.blue} />}
            type='text'
            size='middle'>
            Appeler
          </StyledButton> */}
          {/* <StyledButton
            icon={<FiSend color={chartColors.blue} />}
            type='text'
            size='middle'>
            Envoyer un mail
          </StyledButton> */}
          <StyledButton
            icon={<FiPrinter color={chartColors.blue} />}
            type='text'
            size='middle'
            disabled={medicalRecords.length === 0 || isLoading}
            onClick={handleSelectMedicalRecord}>
            Imprimer les dossiers
          </StyledButton>
        </Space>
      </StyledMedicalRecordPatientActionsContainer>
    );
  };

  const renderMedicalRecordContent = () => {
    return (
      <StyleListContainer>
        <StyledMedicalRecordPatientContent>
          {showConstantes && (
            <ConstantesPatients
              poids='80'
              taille='201'
              temperature='37'
              tension='172/12'
              pouls='75'
            />
          )}
          {/* <ConsultationsPatient /> */}
          {showMedicalRecords && (
            <MedicalRecordPatientList dataMedicalRecords={medicalRecords} />
          )}
        </StyledMedicalRecordPatientContent>
      </StyleListContainer>
    );
  };

  return (
    <>
      <StyledMedicalRecordPatientWrapper>
        {showHeader && renderMedicalRecordHeader()}
        {showActions && rendermedicalRecordActions()}
        {showContent && renderMedicalRecordContent()}
      </StyledMedicalRecordPatientWrapper>

      {/* Display All Medical Records PDF */}
      {showMedicalRecordsPdf && (
        <PdfDrawerViewer
          drawerTitle={`Dossiers patient - ${patientData.name} ${patientData.firstname} `}
          closeDrawer={() => setShowMedicalRecordsPdf(false)}
          extraActions={
            <>
              <StyledButton
                key='cancel_invoice'
                type='primary'
                size='middle'
                danger
                disabled={isLoading}
                onClick={() => handleCloseViewer()}>
                Fermer
              </StyledButton>
            </>
          }
          isDrawerOpen={showMedicalRecordsPdf}>
          <RenderDocument<IMedicalRecordDetails[] | undefined>
            documentData={medicalRecordDetails}
            documentType='ALL_MEDICAL_RECORDS_PDF'
            isDuplicata={false}
          />
        </PdfDrawerViewer>
      )}
    </>
  );
};
export default DetailsMedicalRecordPatient;
