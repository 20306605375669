import React, { useEffect, useState } from 'react';
import { GetRoomsResponse, IRoomItem, ReferentialViewProps } from '../../../types';
import { BsExclamationTriangleFill, BsPlus } from 'react-icons/bs';
import SectionHeader from '../../../components/header/SectionHeader';
import { StyledButton } from '../../../components/button/style';
import { Table, App as AppContainer } from 'antd';
import { StyledDivider, StyledSpace } from '../../../components/style';
import chartColors from '../../../styles/color';
import { getApiErrorMsg } from '../../../utils/commun';
import {
  ErrorDeleteMsg,
  ErrorMsgTitle,
  SuccesssMsgTitle,
} from '../../../utils/constants';
import { deleteRoomApi, getRoomsApi } from '../../../api/roomService';
import { ColumnsType } from 'antd/es/table';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import Loader from '../../../components/loader';

type RoomsListProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  updateCurrentItem: (p: IRoomItem) => void;
};

const RoomsList: React.FC<RoomsListProps> = ({ navigateToview, updateCurrentItem }) => {
  const { notification, modal } = AppContainer.useApp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rooms, setRooms] = useState<GetRoomsResponse>([]);

  const handleUpdate = (item: IRoomItem) => {
    updateCurrentItem(item);
    navigateToview('EDIT_ITEM');
  };

  const columnData: ColumnsType<IRoomItem> = [
    {
      title: 'Désignation',
      dataIndex: 'designation',
    },
    {
      title: 'Actions',
      align: 'left',
      render: (_value, record) => {
        return (
          <StyledSpace>
            <StyledButton
              shape='circle'
              size='middle'
              icon={<FiEdit />}
              onClick={() => handleUpdate(record)}
            />
            <StyledButton
              shape='circle'
              size='middle'
              danger
              icon={<FiTrash2 />}
              onClick={() => handleRemove(record)}
            />
          </StyledSpace>
        );
      },
    },
  ];

  const loadData = async () => {
    setIsLoading(true);
    Promise.resolve(getRoomsApi())
      .then(result => {
        setRooms(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteItem = async (item: IRoomItem) => {
    try {
      setIsLoading(true);
      Promise.resolve(
        deleteRoomApi(item.id as number).then(() => {
          loadData();
          notification.success({
            message: SuccesssMsgTitle,
            description: `La chambre a été supprimée`,
          });
        }),
      );
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(ErrorDeleteMsg),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemove = (item: IRoomItem) => {
    modal.confirm({
      title: 'Confirmation de suppression',
      icon: <BsExclamationTriangleFill size={40} color={chartColors.warning} />,
      content: `Êtes-vous sûr de vouloir supprimer la chambre ${item.designation} de manière permanente ?`,
      okText: 'Oui, je Confirme',
      cancelText: 'Annuler',
      onOk: () => deleteItem(item),
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const renderHeader = () => {
    return (
      <SectionHeader title='Chambre' count={rooms.length}>
        <StyledButton
          icon={<BsPlus />}
          type='primary'
          onClick={() => navigateToview('NEW_ITEM')}>
          Ajouter une chambre
        </StyledButton>
      </SectionHeader>
    );
  };

  const renderContent = () => {
    return (
      <>
        <StyledDivider />
        <Table
          rowKey='id'
          bordered={false}
          columns={columnData}
          dataSource={rooms}
          size='small'
          pagination={false}
        />
      </>
    );
  };

  return (
    <>
      {renderHeader()}
      <Loader showSpinner={isLoading}>{renderContent()}</Loader>
    </>
  );
};

export default RoomsList;
