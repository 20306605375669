import { InputRef } from 'antd';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import {
  CountrySelector,
  defaultCountries,
  usePhoneInput,
} from 'react-international-phone';
import { StyledButton } from '../button/style';
import { StyledInput, StyledSpace } from '../style';

interface CustomPhoneInputProps {
  value: null | string | undefined;
  onChange: (phone: string) => void;
  placeholder?: string;
  inputSize?: 'large' | 'middle' | 'small';
  isDisabled?: boolean;
}

const CustomPhoneInput: React.FC<CustomPhoneInputProps> = ({
  value,
  onChange,
  placeholder,
  inputSize,
  isDisabled,
}) => {
  const phoneInput = usePhoneInput({
    defaultCountry: 'ci',
    value: _.isNull(value) ? '' : value,
    forceDialCode: true,
    disableFormatting: true,
    onChange: data => {
      onChange(data.phone);
    },
    countries: defaultCountries,
    // countries: defaultCountries.filter(country => {
    //   const { iso2 } = parseCountry(country);
    //   return ['ci', 'fr', 'us', 'bf'].includes(iso2);
    // }),
  });

  const inputRef = useRef<InputRef>(null);

  // Need to reassign inputRef because antd provides not default ref
  useEffect(() => {
    if (phoneInput.inputRef && inputRef.current?.input) {
      phoneInput.inputRef.current = inputRef.current.input;
    }
  }, [inputRef, phoneInput.inputRef]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <StyledSpace.Compact size={inputSize ?? 'large'} block>
        <CountrySelector
          disabled={isDisabled}
          preferredCountries={['ci', 'fr', 'us']}
          selectedCountry={phoneInput.country.iso2}
          onSelect={country => phoneInput.setCountry(country.iso2)}
          renderButtonWrapper={({ children, rootProps }) => (
            <StyledButton
              {...rootProps}
              shape='default'
              style={{
                padding: '4px',
                height: '100%',
                zIndex: 1, // fix focus overlap
              }}>
              {children}
            </StyledButton>
          )}
          dropdownStyleProps={{
            style: {
              top: '35px',
            },
          }}
        />
        <StyledInput
          placeholder={placeholder ?? 'Numéro de téléphone'}
          type='tel'
          value={phoneInput.phone}
          onChange={phoneInput.handlePhoneValueChange}
          disabled={isDisabled}
        />
      </StyledSpace.Compact>
    </div>
  );
};

export default CustomPhoneInput;
