import { useState } from 'react';
import { IDiagnosticItem, ReferentialViewProps } from '../../../types';
import DiagnosticForm from './DiagnosticForm';
import DiagnosticsList from './DiagnosticsList';

const DiagnosticsRef = () => {
  const [activeView, setActiveView] = useState<ReferentialViewProps>('LIST_ITEMS');
  const [selectedDiagnostic, setSelectedDiagnostic] = useState<IDiagnosticItem>();

  const handleNavigateToView = (newView: ReferentialViewProps) => {
    setActiveView(newView);
  };

  const handleUpdateSelectedDiagnostic = (diagnotic?: IDiagnosticItem) => {
    setSelectedDiagnostic(diagnotic);
  };

  const renderContent = () => {
    switch (activeView) {
      case 'LIST_ITEMS':
        return (
          <DiagnosticsList
            navigateToview={handleNavigateToView}
            updateCurrentItem={handleUpdateSelectedDiagnostic}
          />
        );
      case 'EDIT_ITEM':
        return (
          <DiagnosticForm
            navigateToview={handleNavigateToView}
            currentData={selectedDiagnostic}
          />
        );
      case 'NEW_ITEM':
        return <DiagnosticForm navigateToview={handleNavigateToView} />;
    }
  };

  return renderContent();
};

export default DiagnosticsRef;
