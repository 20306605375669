import { RouteObject } from 'react-router-dom';
import CashboxPage from '../pages/CashboxPage';
import DashboardPage from '../pages/DashboardPage';
import HospitalizationPage from '../pages/HospitalizationPage';
import Ordonnancepage from '../pages/OrdonnancePage';
import ParaClinicAnalysisPage from '../pages/ParaClinicAnalysisPage';
import PatientRecordPage from '../pages/PatientRecordPage';
import PharmacyPage from '../pages/PharmacyPage';
import ProfilPage from '../pages/ProfilPage';
import ReferentialPage from '../pages/ReferentialPage';
import RendezVouspage from '../pages/RendezVousPage';
import SearchPatientRecordPage from '../pages/SearchPatientRecordPage';
import SettingPage from '../pages/SettingPage';
import ViewPatientHistoryPage from '../pages/ViewPatientHistoryPage';
import InvoicePage from '../pages/invoice/InvoicePage';
import InvoiceProformaPage from '../pages/invoice/InvoiceProformaPage';
import AppointmentPage from '../pages/AppointmentPage';
import MyActivityPage from '../pages/MyActivityPage';
import KpiAssistedDecisionPage from '../pages/KpiAssistedDecisionPage';
import OrderArticleForm from '../containers/pharmacy/ordersArticles/OrderArticleForm';
import CguPage from '../pages/CguPage';
import CollectingPage from '../pages/CollectingPage';

export const routerConfig: Array<RouteObject> = [
  { path: 'tableau-de-bord', element: <DashboardPage /> },
  { path: 'consulter-dossier', element: <SearchPatientRecordPage /> },
  { path: 'historique-patient', element: <ViewPatientHistoryPage /> },
  { path: 'dossier-patient', element: <PatientRecordPage /> },
  {
    path: 'rendez-vous',
    children: [
      { index: true, path: '', element: <RendezVouspage /> },
      { path: 'prise-de-rendez-vous', element: <AppointmentPage /> },
    ],
  },
  { path: 'ordonnances', element: <Ordonnancepage /> },
  { path: 'analyses-paracliniques', element: <ParaClinicAnalysisPage /> },
  { path: 'hospitalisation', element: <HospitalizationPage /> },
  {
    path: 'Pharmacie',
    children: [
      { index: true, path: '', element: <PharmacyPage /> },
      { path: ':tabId', element: <PharmacyPage /> },
      { path: 'nouvelle-commande', element: <OrderArticleForm /> },
    ],
  },
  { path: 'kpi-decision-assistee', element: <KpiAssistedDecisionPage /> },
  { path: 'mon-profil', element: <ProfilPage /> },
  { path: 'mon-activite', element: <MyActivityPage /> },
  { path: 'parametres', element: <SettingPage /> },
  { path: 'referentiels', element: <ReferentialPage /> },
  {
    path: 'facturation',
    children: [
      { index: true, path: '', element: <InvoicePage /> },
      { path: 'facturation-proforma', element: <InvoiceProformaPage /> },
    ],
  },
  {
    path: 'caisse',
    children: [{ index: true, path: '', element: <CashboxPage /> }],
  },
  {
    path: 'recouvrement',
    children: [{ index: true, path: '', element: <CollectingPage /> }],
  },
  { path: 'cgu', element: <CguPage /> },
];
