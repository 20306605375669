import { CreateAntecedentAction, CreateAntecedentState } from './types';

export const getCreateAntecedentDefaultInitialState = (): CreateAntecedentState => {
  return {
    medicalAntecedent: [],
    chirurgicalAntecedent: [],
    gynecoAntecedent: [],
    familyAntecedent: [],
    modedevieAntecedents: [],
    invalidInputs: [],
  } as unknown as CreateAntecedentState;
};

export const createAntecedentReducer = (
  state: CreateAntecedentState,
  action: CreateAntecedentAction,
): CreateAntecedentState => {
  const newState = { ...state };
  switch (action.type) {
    case 'setMedicalAntecedent':
      newState.medicalAntecedents = action.payload;
      return newState;
    case 'setChirurgicalAntecedent':
      newState.chirurgicalAntecedents = action.payload;
      return newState;
    case 'setGynecoAntecedent':
      newState.gynecoAntecedents = action.payload;
      return newState;
    case 'setFamilyAntecedent':
      newState.familyAntecedents = action.payload;
      return newState;
    case 'setModeDeVieAntecedent':
      newState.modedevieAntecedents = action.payload;
      return newState;
    case 'setInvalidInputs':
      newState.invalidInputs = action.payload;
      return newState;
    default:
      return state;
  }
};
