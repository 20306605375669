import React, { PropsWithChildren } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { StyledButton } from '../../components/button/style';
import { StyledCardItem, StyledSpace } from '../../components/style';
import { CreateAutoInvoiceProformaState } from '../../reducers/invoicePlan/types';
import chartColors from '../../styles/color';
import { InvoicePlanDataProps } from '../../types';

interface PlanItemCardProps {
  handleRemovePlan: () => void;
  state: CreateAutoInvoiceProformaState;
  isLoading: boolean;
}

const PlanItemCard: React.FC<PropsWithChildren<PlanItemCardProps>> = ({
  handleRemovePlan,
  state,
  children,
  isLoading,
}) => {
  const planData = state.plan as InvoicePlanDataProps;

  return (
    <StyledCardItem
      style={{ margin: '20px 0px' }}
      styles={{
        header: {
          fontFamily: 'Metropolis',
          textTransform: 'uppercase',
          backgroundColor: chartColors.blue50,
        },
      }}
      title={<StyledSpace>{planData?.label}</StyledSpace>}
      size='small'
      extra={
        <StyledButton
          danger
          size='small'
          disabled={isLoading}
          icon={<FiTrash2 />}
          onClick={handleRemovePlan}>
          Retirer
        </StyledButton>
      }>
      {children}
      {/* <Loader showSpinner={isLoading}>{renderRubrics()}</Loader> */}
    </StyledCardItem>
  );
};

export default PlanItemCard;
