import { useState } from 'react';
import ConsultationReasonsList from './ConsultationReasonsList';
import ConsultationReasonForm from './ConsultationReasonForm';
import { IConsultationReasonItem, ReferentialViewProps } from '../../../types';

const ConsultationReasonsRef = () => {
  const [activeView, setActiveView] = useState<ReferentialViewProps>('LIST_ITEMS');
  const [selectedItem, setSelectedItem] = useState<IConsultationReasonItem>();

  const handleNavigateToView = (newView: ReferentialViewProps) => {
    setActiveView(newView);
  };

  const handleUpdateSelectedItem = (item?: IConsultationReasonItem) => {
    setSelectedItem(item);
  };

  const renderContent = () => {
    switch (activeView) {
      case 'LIST_ITEMS':
        return (
          <ConsultationReasonsList
            navigateToview={handleNavigateToView}
            updateCurrentItem={handleUpdateSelectedItem}
          />
        );
      case 'EDIT_ITEM':
        return (
          <ConsultationReasonForm
            navigateToview={handleNavigateToView}
            currentData={selectedItem}
          />
        );
      case 'NEW_ITEM':
        return <ConsultationReasonForm navigateToview={handleNavigateToView} />;
    }
  };

  return <>{renderContent()}</>;
};

export default ConsultationReasonsRef;
