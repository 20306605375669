import { App as AppContainer } from 'antd';
import { isEmpty, isUndefined } from 'lodash';
import { useEffect, useReducer, useState } from 'react';
import {
  getDiagnosticbyMedicalRecord,
  updateDiagnosticApi,
} from '../../../api/dossierPatientService';
import { getDiagnosticsApi } from '../../../api/referentialService';
import { StyledButton } from '../../../components/button/style';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import Loader from '../../../components/loader';
import {
  StyledCol,
  StyledDatePicker,
  StyledInputTextArea,
  StyledRow,
} from '../../../components/style';
import {
  CreateDiagnosticReducer,
  getCreateDiagnosticDefaultInitialState,
} from '../../../reducers/diagnostic/createDiagnostic';
import { CreateDiagnosticState } from '../../../reducers/diagnostic/types';
import {
  CreateDiagnosticRequestBody,
  CreateMedicalRecordApiResponse,
  GetDiagnosticsResponse,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { DATE_FORMAT, ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { getDataFromStorage } from '../../../utils/storage';
import { StyledMedicalRecordBlocContainer } from '../../style';
import AddRemoveDiagnostic from './AddRemoveDiagnostic';
import dayjsInstance from '../../../dayjs';

const Pronostic: React.FC = () => {
  const { notification } = AppContainer.useApp();
  const medicalRecord =
    getDataFromStorage<CreateMedicalRecordApiResponse>('medicalRecord');

  const [state, reducer] = useReducer(
    CreateDiagnosticReducer,
    getCreateDiagnosticDefaultInitialState(),
  );

  const [diagnosticsData, setDiagnosticsData] = useState<GetDiagnosticsResponse>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadDiagnosticsByMedicalRecord = async () => {
    Promise.resolve(getDiagnosticbyMedicalRecord(medicalRecord.id)).then(result => {
      reducer({
        type: 'setCommentDiagnostic',
        payload: result.diagnosticComment,
      });
      reducer({
        type: 'setCreatedDateDiagnostic',
        payload: result.diagnosticDate,
      });
      reducer({
        type: 'setDiagnostics',
        payload: result.diagnostics,
      });
    });
  };

  useEffect(() => {
    setIsLoading(true);
    try {
      Promise.resolve(getDiagnosticsApi()).then(result => {
        setDiagnosticsData(result);
      });
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!isUndefined(medicalRecord)) {
      loadDiagnosticsByMedicalRecord();
    }
  }, []);

  const mapStateToRequestBody = (
    state: CreateDiagnosticState,
  ): CreateDiagnosticRequestBody => {
    const requestBody = {} as CreateDiagnosticRequestBody;
    requestBody.diagnosticDate = !isEmpty(state.diagnosticDate)
      ? state.diagnosticDate
      : dayjsInstance().format(DATE_FORMAT);
    requestBody.diagnosticComment = state.diagnosticComment;
    requestBody.idMedicalRecord = medicalRecord && medicalRecord?.id;
    requestBody.diagnostics = state.diagnostics;

    return requestBody;
  };

  const updateDiagnostic = async () => {
    try {
      setIsLoading(true);
      await updateDiagnosticApi(medicalRecord?.id, mapStateToRequestBody(state)).then(
        () => {
          notification.success({
            message: SuccesssMsgTitle,
            description: "'Le diagnostic patient a bien été mis à jour!'",
          });
        },
      );
    } catch (error) {
      notification.error({
        message: ErrorMsgTitle,
        description: getApiErrorMsg(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    return (
      <StyledMedicalRecordBlocContainer
        style={{ backgroundColor: '#FFF', padding: '30px 20px' }}>
        <StyledRow gutter={[16, 16]}>
          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}>
            <StyledRow gutter={[16, 16]}>
              <StyledCol span={24}>
                <FormFieldLabel libelle='Date du diagnostic' />
                <StyledDatePicker
                  name='date'
                  placeholder='Date du diagnostic'
                  style={{ width: '100%' }}
                  onChange={(value, dateString) => {
                    reducer({
                      type: 'setCreatedDateDiagnostic',
                      payload: Array.isArray(dateString) ? dateString[0] : dateString,
                    });
                  }}
                  value={
                    isEmpty(state.diagnosticDate)
                      ? dayjsInstance()
                      : dayjsInstance(state.diagnosticDate, DATE_FORMAT)
                  }
                  disabledDate={current => dayjsInstance().isBefore(current, 'day')}
                />
              </StyledCol>

              <StyledCol span={24}>
                <AddRemoveDiagnostic
                  listTitle='Sélectionnez un ou plusieurs diagnostics'
                  items={diagnosticsData}
                  reducer={reducer}
                  title='Sélectionnez un ou plusieurs diagnostics'
                  value={state.diagnostics}
                />
              </StyledCol>
            </StyledRow>
          </StyledCol>
          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}>
            <StyledRow gutter={[16, 16]}>
              <StyledCol span={24}>
                <FormFieldLabel libelle='Commentaire ' />
                <StyledInputTextArea
                  rows={9}
                  maxLength={500}
                  showCount
                  placeholder='Commentaire'
                  onChange={e =>
                    reducer({
                      type: 'setCommentDiagnostic',
                      payload: e.target.value,
                    })
                  }
                  value={state.diagnosticComment}
                />
              </StyledCol>
            </StyledRow>
          </StyledCol>
          {!isUndefined(medicalRecord) && (
            <StyledCol
              span={24}
              style={{
                display: 'flex',
                placeItems: 'center',
                placeContent: 'center',
                marginTop: '16px',
              }}>
              <StyledButton
                size='large'
                type='primary'
                loading={isLoading}
                disabled={isLoading}
                // disabled={isLoading || isEmpty(state.diagnosticComment)}
                onClick={() => updateDiagnostic()}>
                Mettre à jour
              </StyledButton>
            </StyledCol>
          )}
        </StyledRow>
      </StyledMedicalRecordBlocContainer>
    );
  };

  return <Loader showSpinner={isLoading}>{renderContent()}</Loader>;
};

export default Pronostic;
