import {
  CreateCreditNoteProformaRequestBody,
  CreateInvoicePlanRequestBody,
  CreateInvoiceRequestBody,
  CreateInvoiceRubricRequestBody,
  CreateProformaRequestBody,
  CreateProformaResponse,
  GetInvoiceDuplicataResponse,
  GetInvoicePlansResponse,
  GetInvoiceRubricsResponse,
  GetRubricsByInvoiceIdResponse,
  InvoiceRubricAmountRequestBody,
  ParameterItem,
  ProformaLineDto,
  SearchInvoiceApiRequestBody,
  SearchInvoiceApiResponse,
  UpdateRubricsIntoInvoicePlanRequestBody,
} from '../types';
import { axiosInstance } from './axiosInstance';

export const createInvoicePlanApi = (
  data: CreateInvoicePlanRequestBody,
): Promise<void> => {
  return axiosInstance
    .post('/invoicePlan', { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création de invoice plan. Raison: ${message}`);
    });
};

export const getInvoicePlansApi = (): Promise<GetInvoicePlansResponse[]> => {
  return axiosInstance
    .get('/invoicePlans')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération de Invoice plans. Raison: ${message}`);
    });
};

export const updateInvoicePlanApi = (
  invoiceId: number,
  data: CreateInvoicePlanRequestBody,
): Promise<void> => {
  return axiosInstance
    .put(`/invoicePlan?id=${invoiceId}`, { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la mise a jour de invoice plan. Raison: ${message}`);
    });
};

export const createInvoiceRubricApi = (
  data: CreateInvoiceRubricRequestBody,
): Promise<void> => {
  return axiosInstance
    .post('/rubric', { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création de invoice rubrics. Raison: ${message}`);
    });
};

export const getInvoiceRubricsApi = (): Promise<GetInvoiceRubricsResponse> => {
  return axiosInstance
    .get('/rubrics')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération de Invoice rubrics. Raison: ${message}`,
      );
    });
};

export const updateInvoiceRubricApi = (
  rubricId: number,
  data: CreateInvoiceRubricRequestBody,
): Promise<void> => {
  return axiosInstance
    .put(`/rubric?id=${rubricId}`, { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la mise a jour de invoice rubrics. Raison: ${message}`);
    });
};

export const deleteInvoiceRubricApi = (rubricId: number): Promise<void> => {
  return axiosInstance
    .delete(`/rubric?id=${rubricId}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la suppression de la rubrique. Raison: ${message}`);
    });
};

export const updateRubricsIntoInvoicePlanApi = (
  idInvoicePlan: number,
  data: UpdateRubricsIntoInvoicePlanRequestBody,
): Promise<void> => {
  return axiosInstance
    .put(`/rubricsIntoInvoicePlan?IdInvoicePlan=${idInvoicePlan}`, { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la mise a jour de rubrics into invoice plan. Raison: ${message}`,
      );
    });
};

export const getRubricsByInvoicePlanApi = (
  IdInvoicePlan: number,
): Promise<GetInvoiceRubricsResponse> => {
  return axiosInstance
    .get(`/rubricByIdInvoicePlan?idInvoicePlan=${IdInvoicePlan}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération de rubrics par Invoice plan. Raison: ${message}`,
      );
    });
};

export const createProformaApi = (
  data: CreateProformaRequestBody,
): Promise<CreateProformaResponse> => {
  return axiosInstance
    .post('/proforma', { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création de proforma. Raison: ${message}`);
    });
};

export const createCreditNoteProformaApi = (
  data: CreateCreditNoteProformaRequestBody,
): Promise<CreateProformaResponse> => {
  return axiosInstance
    .post('/creditNoteProforma', { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la création de credit note proforma. Raison: ${message}`,
      );
    });
};

export const createInvoiceApi = (
  data: CreateInvoiceRequestBody,
): Promise<CreateProformaResponse> => {
  return axiosInstance
    .post('/invoice', { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création de la facture. Raison: ${message}`);
    });
};

export const searchInvoiceApi = (
  data: SearchInvoiceApiRequestBody,
): Promise<SearchInvoiceApiResponse[]> => {
  return axiosInstance
    .post('/searchInvoices', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      console.log(err);
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recherche de patient. Raison: ${message}`);
    });
};

export const getInvoiceRubricAmountsApi = (
  data: InvoiceRubricAmountRequestBody,
): Promise<ProformaLineDto> => {
  return axiosInstance
    .post('/determineInvoiceRubrique', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      console.log(err);
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération de la rubrique. Raison: ${message}`);
    });
};

export const collectCashInApi = (invoiceId: number): Promise<void> => {
  return axiosInstance
    .get(`/cashCollection?idInvoice=${invoiceId}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la collection de la facture. Raison: ${message}`);
    });
};

export const getTvaApi = (): Promise<ParameterItem> => {
  return axiosInstance
    .get('tva')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération de la TVA. Raison: ${message}`);
    });
};

export const getRubricsByInvoiceId = (
  invoiceId: number,
): Promise<GetRubricsByInvoiceIdResponse[]> => {
  return axiosInstance
    .get(`/rubricsByIdInvoice?idInvoice=${invoiceId}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des rubriques Raison: ${message}`);
    });
};

export const getDuplicataApi = (
  invoiceId: number,
): Promise<GetInvoiceDuplicataResponse> => {
  return axiosInstance
    .get(`/duplicata?idInvoice=${invoiceId}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création du duplicata Raison: ${message}`);
    });
};
