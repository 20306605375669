/* eslint-disable react/no-unescaped-entities */
import PageHeader from '../../components/pageHeader/PageHeader';
import VerticalLayout from '../../layout/VerticalLayout';
import chartColors from '../../styles/color';
import { StyledBlocContainer } from '../style';
import CguParagraph from './CguParagraph';
import CguSubParagraph from './CguSubParagraph';

const CGU = () => {
  const renderContent = () => {
    return (
      <>
        <CguParagraph rank={1} title='Introduction'>
          <p>
            Bienvenue sur ApiPatient (l'"Application"), une plateforme de gestion
            électronique des dossiers médicaux fournie par Generis Partners (le
            "Fournisseur de Services"). Les présentes Conditions Générales d'Utilisation
            (les "CGU") régissent votre utilisation de l'Application.
          </p>
          <p>
            Avant d'utiliser l'Application, veuillez lire attentivement les présentes CGU
            et notre Politique de Confidentialité. En utilisant l'Application, vous
            acceptez de respecter ces CGU et notre Politique de Confidentialité.
          </p>
        </CguParagraph>

        <CguParagraph rank={2} title="Utilisation de l'Application">
          <p>
            L'Application est destinée aux professionnels de la santé et aux
            établissements de soins qui souhaitent gérer électroniquement les dossiers
            médicaux des patients. L'Application est un outil de gestion des données
            médicales et ne remplace pas les professionnels de santé.
          </p>
        </CguParagraph>

        <CguParagraph
          rank={3}
          title='Collecte, utilisation de Données et Politique de Confidentialité'>
          <p>
            Vos données personnelles et médicales sont collectées, traitées et stockées
            conformément à notre Politique de Confidentialité. En utilisant l'Application,
            vous consentez à la collecte et au traitement de vos données conformément à
            cette politique. Pour plus d'informations sur la collecte, l'utilisation et la
            protection de vos données, veuillez consulter notre Politique de
            Confidentialité.
          </p>
          <CguSubParagraph rank={3.1} title='Données Personnelles'>
            <p>
              Lorsque vous utilisez l'Application, nous pouvons collecter des données
              personnelles vous concernant. Les données personnelles incluent, sans
              toutefois s'y limiter, les informations suivantes :
            </p>
            <ul className='list-outside list-disc ml-10'>
              <li className=''>
                <span className='font-sans font-medium'>Informations de contact: </span>{' '}
                Nous pouvons collecter votre adresse e-mail, numéro de téléphone, adresse
                postale, et autres informations de contact pour faciliter la communication
                avec vous.
              </li>
              <li>
                <span className='font-sans font-medium'>
                  Informations professionnelles:{' '}
                </span>
                Si vous êtes un professionnel de la santé, nous pouvons collecter des
                informations liées à votre licence professionnelle, votre spécialité,
                votre établissement de soins, votre matricule et d'autres informations
                professionnelles pertinentes.
              </li>
            </ul>
          </CguSubParagraph>

          <CguSubParagraph rank={3.2} title='Données Médicales'>
            <p>
              En tant qu'Application de gestion électronique des dossiers médicaux, nous
              collectons et traitons des données médicales. Les données médicales
              incluent, mais ne sont pas limitées à, les informations suivantes :
            </p>
            <ul className='list-outside list-disc ml-10'>
              <li>
                <span className='font-sans font-medium'>Antécédents médicaux: </span>
                Nous collectons des informations sur les antécédents médicaux, y compris
                les maladies, les traitements antérieurs, les allergies, et d'autres
                détails pertinents.
              </li>
              <li>
                <span className='font-sans font-medium'>
                  Diagnostics et traitements:{' '}
                </span>
                Les diagnostics médicaux reçus et les traitements médicaux suivis sont
                enregistrés dans l'Application.
              </li>
              <li>
                <span className='font-sans font-medium'>Images médicales: </span>
                Les images radiologiques ou des résultats d'analyses médicales, ceux-ci
                peuvent être stockés dans l'Application pour consultation ultérieure.
              </li>
            </ul>
          </CguSubParagraph>

          <CguSubParagraph
            rank={3.3}
            title="Objectif de la Collecte et de l'Utilisation des Données">
            <p>
              Nous collectons ces données personnelles et médicales dans le but de fournir
              les services de l'Application. Les principaux objectifs de la collecte et de
              l'utilisation de ces données sont les suivants :
            </p>
            <ul className='list-outside list-disc ml-10'>
              <li>
                <span className='font-sans font-medium'>
                  Gestion des dossiers médicaux:{' '}
                </span>
                Les données personnelles et médicales sont collectées pour créer et gérer
                les dossiers médicaux électroniques des patients, facilitant ainsi la
                prestation de soins de santé de qualité.
              </li>
              <li>
                <span className='font-sans font-medium'>Communication: </span>
                Nous utilisons les informations de contact pour vous envoyer des
                notifications, des rappels de rendez-vous ( à terme), des rapports
                médicaux et d'autres informations importantes liées à votre santé.
              </li>
              <li>
                <span className='font-sans font-medium'>Facturation: </span>
                Les données personnelles et médicales peuvent être utilisées pour générer
                des factures liées aux services médicaux fournis.
              </li>
              <li>
                <span className='font-sans font-medium'>Recherche et Analyse: </span>
                Les données agrégées et anonymisées peuvent être utilisées à des fins de
                recherche médicale et d'analyse de données pour améliorer nos services et
                contribuer à l'avancement de la médecine.
              </li>
            </ul>
          </CguSubParagraph>

          <CguSubParagraph rank={3.4} title='Consentement'>
            <p>
              En utilisant l'Application, vous consentez à la collecte et à l'utilisation
              de vos données personnelles et médicales aux fins énoncées dans la présente
              Politique de Confidentialité. Vous êtes responsables du recueil de
              consentement auprès de vos patients.
            </p>
            <p>
              Vous avez le droit de retirer ce consentement à tout moment, conformément
              aux dispositions de la Politique de confidentialité.
            </p>
            <ul className='list-outside list-disc ml-10'>
              <li>
                <span className='font-sans font-medium'>Accès aux Données</span>
                <p>
                  En tant que professionnel de santé ou établissement de santé, vous avez
                  le droit d'accéder aux données personnelles et médicales stockées dans
                  l'Application. Vous pouvez consulter vos dossiers médicaux et autres
                  informations personnelles à tout moment.
                </p>
                <p>
                  Pour exercer votre droit d'accès, vous pouvez soumettre une demande
                  d'accès écrite ou électronique au responsable de la protection des
                  données (DPO) ou au directeur de la publication. Votre demande sera
                  traitée dans un délai raisonnable.
                </p>
                <p>
                  Afin de garantir la sécurité de vos données, nous pouvons vous demander
                  de vérifier votre identité avant de vous fournir l'accès à vos données.
                  Cela peut inclure la fourniture d'informations d'identification et de
                  réponses à des questions de sécurité.
                </p>
              </li>
              <li>
                <span className='font-sans font-medium'>Modification des Données</span>
                <p>
                  Si vous identifiez des inexactitudes ou des erreurs dans les données
                  personnelles ou médicales collectées, vous avez le droit de demander
                  leur correction. Nous nous efforcerons de mettre à jour vos données de
                  manière précise et en temps opportun.
                </p>
                <p>
                  Pour demander une modification de vos données, veuillez soumettre une
                  demande écrite ou électronique au responsable de la protection des
                  données ou au directeur de la publication. Votre demande sera examinée
                  et traitée conformément à nos procédures internes.
                </p>
              </li>
              <li>
                <span className='font-sans font-medium'>Suppression des Données</span>
                <p>
                  Vous avez le droit de demander la suppression de vos données
                  personnelles et médicales de l'Application. Cependant, ce droit n'est
                  pas absolu et est soumis aux obligations contractuelles, légales et
                  réglementaires, ainsi qu'aux intérêts légitimes de la santé publique.
                </p>
                <p>
                  Pour demander la suppression de vos données, veuillez soumettre une
                  demande écrite ou électronique au responsable de la protection des
                  données ou directeur de la publication. Votre demande sera examinée et
                  traitée conformément à nos procédures internes.
                </p>
                <p>
                  Nous conservons vos données personnelles et médicales pendant la durée
                  nécessaire pour atteindre les objectifs énoncés dans la présente
                  Politique de Confidentialité, à moins que la loi ne nous oblige à les
                  conserver plus longtemps. Une fois que les données ne sont plus
                  nécessaires, elles seront supprimées de manière sécurisée et définitive.
                </p>
                <p>
                  Pour exercer vos droits d'accès, de modification ou de suppression de
                  vos données, ou pour poser des questions sur la gestion de vos données,
                  veuillez nous contacter à{' '}
                  <a
                    href='mailto:hello@generispartners.com'
                    className='font-bold'
                    style={{ color: chartColors.deepBlue }}>
                    {' '}
                    hello@generispartners.com
                  </a>
                </p>
                <p>
                  Notre équipe dédiée à la protection des données est à votre disposition
                  pour vous assister dans ces démarches.
                </p>
              </li>
            </ul>
          </CguSubParagraph>
        </CguParagraph>

        <CguParagraph rank={4} title='Comptes Utilisateurs'>
          <p>
            Pour accéder à certaines fonctionnalités de l'Application, vous devrez créer
            un compte utilisateur. Vous êtes responsable de la confidentialité de vos
            identifiants de connexion. Tout accès à votre compte à l'aide de vos
            identifiants de connexion est réputé avoir été autorisé par vous.
          </p>
        </CguParagraph>

        <CguParagraph rank={5} title='Sécurité des Données'>
          <p>
            Le Fournisseur de Services s'engage à maintenir la sécurité de vos données
            personnelles et médicales. Des mesures de sécurité appropriées sont mises en
            place pour protéger vos données contre tout accès non autorisé.
          </p>
        </CguParagraph>

        <CguParagraph rank={6} title='Utilisation Responsable'>
          <p>
            Vous vous engagez à utiliser l'Application de manière responsable, en
            respectant les lois, les réglementations et les normes applicables. Vous vous
            engagez également à ne pas utiliser l'Application de manière à causer des
            dommages ou des perturbations à d'autres utilisateurs.
          </p>
        </CguParagraph>

        <CguParagraph rank={7} title='Résiliation'>
          <p>
            Le Fournisseur de Services se réserve le droit de résilier votre accès à
            l'Application en cas de non-respect des CGU ou de comportement inapproprié. En
            cas de résiliation, vous devrez cesser d'utiliser l'Application.
          </p>
        </CguParagraph>

        <CguParagraph
          rank={8}
          title='Modification des CGU et de la Politique de Confidentialité'>
          <p>
            Le Fournisseur de Services se réserve le droit de modifier les présentes CGU
            et la Politique de Confidentialité à tout moment. Les modifications seront
            publiées sur l'Application. Il vous incombe de consulter régulièrement ces
            documents.
          </p>
        </CguParagraph>

        <CguParagraph rank={9} title='Contact'>
          <p>
            Pour toute question ou préoccupation concernant les CGU, la Politique de
            Confidentialité ou l'Application en général, veuillez nous contacter à
            <a
              href='mailto:hello@generispartners.com'
              className='font-bold'
              style={{ color: chartColors.deepBlue }}>
              {' '}
              hello@generispartners.com
            </a>
          </p>
        </CguParagraph>
      </>
    );
  };
  return (
    <VerticalLayout>
      <PageHeader title="Conditions Générales d'Utilisation (CGU) et Politique de Confidentialité" />
      <StyledBlocContainer className='py-16 px-8 md:px-24 lg:px-60'>
        {renderContent()}
      </StyledBlocContainer>
    </VerticalLayout>
  );
};

export default CGU;
