import styled from 'styled-components';

export const MessageWrapper = styled.div<{ msgTpe: 'success' | 'error' }>`
  color: ${({ msgTpe }) => (msgTpe === 'success' ? '#155724' : '#721c24')};
  background-color: ${({ msgTpe }) => (msgTpe === 'success' ? '#d4edda' : '#f8d7da')};
  border-color: ${({ msgTpe }) => (msgTpe === 'success' ? '#c3e6cb' : '#f5c6cb')};
  padding: 1rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-bottom: 1em;
  /* min-width: 50rem; */
`;
