import React from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { StyledButton } from '../../components/button/style';
import FormFieldLabel from '../../components/label/FormFieldLabel';
import {
  StyledCardItem,
  StyledCol,
  StyledInputNumber,
  StyledRow,
  StyledSpace,
  StyledTag,
} from '../../components/style';
import {
  CreateInvoiceProformaAction,
  CreateManualInvoiceProformaState,
} from '../../reducers/invoicePlan/types';
import chartColors from '../../styles/color';
import { GetUnitResponse, IInvoiceRubricItem, InvoiceRubricMode } from '../../types';

interface RubricItemCardProps {
  rubricData: IInvoiceRubricItem;
  reducer: React.Dispatch<CreateInvoiceProformaAction>;
  state: CreateManualInvoiceProformaState;
  handleRemoveRubric: () => void;
  unitsData: GetUnitResponse[];
}

const RubricItemCard: React.FC<RubricItemCardProps> = ({
  rubricData,
  reducer,
  state,
  handleRemoveRubric,
}) => {
  const checkExistingRubric = (): number => {
    const indexFound = state.proformaLines.findIndex(
      item => item.idRubric === rubricData.id,
    );
    if (indexFound !== -1) {
      return indexFound;
    }
    return 0;
  };

  return (
    <StyledCardItem
      style={{ margin: '20px 0px' }}
      styles={{
        header: {
          fontFamily: 'Metropolis',
          textTransform: 'uppercase',
          backgroundColor: chartColors.blue50,
        },
      }}
      title={
        <StyledSpace>
          {rubricData.label}
          <StyledTag>{rubricData.mode}</StyledTag>
        </StyledSpace>
      }
      size='small'
      extra={
        <StyledButton
          danger
          size='small'
          icon={<FiTrash2 />}
          onClick={() => handleRemoveRubric()}>
          Retirer
        </StyledButton>
      }>
      <StyledRow gutter={[20, 20]}>
        <StyledCol span={3}>
          <FormFieldLabel libelle='Prix unitaire' />
          <StyledInputNumber
            style={{ width: '100%' }}
            min={1}
            onChange={val =>
              reducer({
                type: 'setUnitPriceRubric',
                payload: { id: rubricData.id, value: val as number },
              })
            }
            disabled
            readOnly
            value={
              state.proformaLines[checkExistingRubric()]?.unitPrice *
                state.proformaLines[checkExistingRubric()]?.quantity ?? 1
            }
          />
        </StyledCol>

        <StyledCol span={3}>
          <FormFieldLabel libelle='Quantité' />
          <StyledInputNumber
            style={{ width: '100%' }}
            placeholder='Quantité'
            min={1}
            onChange={val =>
              reducer({
                type: 'setQuantityRubric',
                payload: { id: rubricData.id, value: val as number },
              })
            }
            value={state.proformaLines[checkExistingRubric()]?.quantity}
            disabled={rubricData.mode === InvoiceRubricMode.MONTANT_FIXE}
            readOnly={rubricData.mode === InvoiceRubricMode.MONTANT_FIXE}
          />
        </StyledCol>

        <StyledCol span={6}>
          <FormFieldLabel libelle='Total' />
          <StyledInputNumber
            style={{ width: '100%' }}
            placeholder='Montant HT'
            min={1}
            onChange={val =>
              reducer({
                type: 'setAmountTotalRubric',
                payload: { id: rubricData.id, value: val as number },
              })
            }
            value={
              state.proformaLines[checkExistingRubric()]?.amountTotal *
              state.proformaLines[checkExistingRubric()]?.quantity
            }
            disabled={rubricData.mode === InvoiceRubricMode.MONTANT_FIXE}
            readOnly={rubricData.mode === InvoiceRubricMode.MONTANT_FIXE}
          />
        </StyledCol>

        <StyledCol span={6}>
          <FormFieldLabel libelle='Part Assu.' />
          <StyledInputNumber
            style={{ width: '100%' }}
            min={0}
            onChange={val =>
              reducer({
                type: 'setAmountAssuRubric',
                payload: { id: rubricData.id, value: val as number },
              })
            }
            disabled
            readOnly
            value={
              state.proformaLines[checkExistingRubric()]?.amountAssu *
                state.proformaLines[checkExistingRubric()]?.quantity ?? 1
            }
          />
        </StyledCol>

        <StyledCol span={6}>
          <FormFieldLabel libelle='Part Patient' />
          <StyledInputNumber
            style={{ width: '100%' }}
            min={0}
            onChange={val =>
              reducer({
                type: 'setAmountPatientRubric',
                payload: { id: rubricData.id, value: val as number },
              })
            }
            disabled
            readOnly
            value={
              state.proformaLines[checkExistingRubric()]?.amountPatient *
                state.proformaLines[checkExistingRubric()]?.quantity ?? 1
            }
          />
        </StyledCol>

        {/* <StyledCol span={4}>
          <FormFieldLabel libelle='Unité' />
          <StyledSelectPicker
            size='middle'
            placeholder='Unité'
            style={{ width: '100%' }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            onChange={(value: unknown) => {
              reducer({
                type: 'setUnitRubric',
                payload: { id: rubricData.id, value: value as string },
              });
            }}
            value={state.proformaLines[checkExistingRubric()]?.unit}>
            {_.map(unitsData, item => (
              <StyledSelectOption key={item.id} value={item.libelle} label={item.libelle}>
                {item.libelle}
              </StyledSelectOption>
            ))}
          </StyledSelectPicker>
        </StyledCol> */}
      </StyledRow>
    </StyledCardItem>
  );
};

export default RubricItemCard;
