import { useState } from 'react';
import { IServiceItem, ReferentialViewProps } from '../../../types';
import ServiceForm from './ServiceForm';
import ServicesList from './ServicesList';

const ServiceRef = () => {
  const [activeView, setActiveView] = useState<ReferentialViewProps>('LIST_ITEMS');
  const [selectedService, setSelectedService] = useState<IServiceItem>();

  const handleNavigateToView = (newView: ReferentialViewProps) => {
    setActiveView(newView);
  };

  const handleUpdateSelectedService = (service?: IServiceItem) => {
    setSelectedService(service);
  };

  const renderContent = () => {
    switch (activeView) {
      case 'LIST_ITEMS':
        return (
          <ServicesList
            navigateToview={handleNavigateToView}
            updateCurrentItem={handleUpdateSelectedService}
          />
        );
      case 'EDIT_ITEM':
        return (
          <ServiceForm
            navigateToview={handleNavigateToView}
            currentData={selectedService}
          />
        );
      case 'NEW_ITEM':
        return <ServiceForm navigateToview={handleNavigateToView} />;
    }
  };

  return renderContent();
};

export default ServiceRef;
