import { App as AppContainer } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { BsEyeFill, BsFilePlusFill } from 'react-icons/bs';
import { FaEdit } from 'react-icons/fa';
import { getInvoicePlansApi } from '../../../../api/invoiceService';
import { StyledButton } from '../../../../components/button/style';
import SectionHeader from '../../../../components/header/SectionHeader';
import Loader from '../../../../components/loader';
import { StyledSpace, StyledTag } from '../../../../components/style';
import { InvoicePlanDataProps, InvoiceViewType } from '../../../../types';
import { getApiErrorMsg } from '../../../../utils/commun';
import { ErrorMsgTitle } from '../../../../utils/constants';

interface InvoicePlansProps {
  handleSelectInvoicePlan: (invoicePlanArg: InvoicePlanDataProps) => void;
  handleEditInvoicePlan: (invoicePlanArg: InvoicePlanDataProps) => void;
  toggleInvoicePlanForm: (arg: InvoiceViewType) => void;
}

const InvoicePlansList: React.FC<InvoicePlansProps> = ({
  handleSelectInvoicePlan,
  toggleInvoicePlanForm,
  handleEditInvoicePlan,
}) => {
  const { notification } = AppContainer.useApp();

  const [invoicePlansData, setInvoicePlansData] = useState<InvoicePlanDataProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const invoicePlansColumns: ColumnsType<InvoicePlanDataProps> = [
    {
      title: '#',
      dataIndex: 'id',
    },
    {
      title: 'Libellé',
      dataIndex: 'label',
    },
    {
      title: 'Etat',
      dataIndex: 'active',
      render: value =>
        value === true ? (
          <StyledTag color='green'>Actif</StyledTag>
        ) : (
          <StyledTag color='volcano'>Inactif</StyledTag>
        ),
    },
    {
      title: 'Date de création',
      dataIndex: 'createdDate',
    },
    {
      align: 'left',
      render: (value, record: InvoicePlanDataProps) => (
        <StyledSpace>
          <StyledButton
            size='middle'
            shape='circle'
            icon={<BsEyeFill />}
            onClick={() => handleSelectInvoicePlan(record)}
          />
          <StyledButton
            size='middle'
            shape='circle'
            icon={<FaEdit />}
            onClick={() => handleEditInvoicePlan(record)}
          />
        </StyledSpace>
      ),
    },
  ];

  const loadInvoicePlans = async () => {
    setIsLoading(true);
    Promise.resolve(getInvoicePlansApi())
      .then((result: InvoicePlanDataProps[]) => {
        setInvoicePlansData(result);
      })
      .catch(e => {
        notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadInvoicePlans();
  }, []);

  const renderHeader = () => {
    return (
      <SectionHeader title='Plan(s) de facture' count={invoicePlansData.length}>
        <StyledButton
          type='primary'
          icon={<BsFilePlusFill />}
          size='middle'
          onClick={() => toggleInvoicePlanForm('INVOICE_PLAN_FORM')}>
          Nouveau Plan
        </StyledButton>
        {/* <StyledButton
          size='middle'
          icon={<BsNodePlus />}
          onClick={() => toggleInvoicePlanForm('INVOICE_RUBRIC_FORM')}>
          Nouvelle Rubrique
        </StyledButton> */}
      </SectionHeader>
    );
  };

  const renderContent = () => {
    return (
      <>
        <Table<InvoicePlanDataProps>
          rowKey='id'
          dataSource={invoicePlansData}
          size='small'
          columns={invoicePlansColumns}
          bordered={false}
        />
      </>
    );
  };

  return (
    <>
      {renderHeader()}
      <Loader showSpinner={isLoading}>{renderContent()}</Loader>
    </>
  );
};

export default InvoicePlansList;
