import { LoginApiResponse, StorageType, UserDataApiResponse } from '../types';
import { removeDataFromStorage } from '../utils/storage';
import { axiosAuthInstance, axiosInstance } from './axiosInstance';

export const login = (data: {
  username: string;
  pwd: string;
}): Promise<LoginApiResponse> => {
  // Décommenter le code ci-dessous et supprimer le mock lorsque le backend est prêt
  return axiosAuthInstance
    .post('/auth/token', { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la connexion. Raison: ${message}`);
    });
  /* return new Promise(resolve => {
    const result: LoginApiResponse = mockLogin as LoginApiResponse;
    setTimeout(() => {
      resolve(result);
    }, 1000);
  }); */
};

export const getConnectedUserData = (
  data: {
    username: string;
  },
  jwt: string,
): Promise<UserDataApiResponse> => {
  return axiosInstance
    .post(
      '/userData',
      { ...data },
      {
        headers: {
          Authorization: jwt,
        },
      },
    )
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération des données de l'utilisateur connecté. Raison: ${message}`,
      );
    });
};

export const logOutUser = () => {
  removeDataFromStorage(StorageType.SS, 'connectedUserData');
  removeDataFromStorage(StorageType.SS, 'jwt');
  removeDataFromStorage(StorageType.LS, 'tva');
  removeDataFromStorage(StorageType.SS, 'siteData');
};
