import { useState } from 'react';
import { BsBox, BsCardHeading, BsFileEarmark, BsGear, BsPercent } from 'react-icons/bs';
import { FaSitemap, FaUserLock } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';
import ConfigMenu from '../../components/menu/configMenu';
import PageHeader from '../../components/pageHeader/PageHeader';
import VerticalLayout from '../../layout/VerticalLayout';
import { ISettingMenuItem, SettingItemKeys } from '../../types';
import {
  ConfigLayoutContentWrapper,
  ConfigLayoutMenuContainer,
  ConfigLayoutMenuWrapper,
  ConfigLayoutPageContainer,
} from '../style';
import GeneralSetting from './GeneralSetting';
import InsuranceSetting from './InsuranceSetting';
import InvoicePlanRubricSetting from './InvoiceRubricSetting';
import InvoiceSetting from './InvoiceSetting';
import RatioSetting from './RatioSetting';
import RoleSetting from './RoleSetting';
import UserSetting from './UserSetting';
import SiteSetting from './siteSetting';
import ActSetting from './ActsSetting';
import ReceiptStampSetting from './ReceiptStampSetting';
import SubscriberSetting from './SubscriberSetting';

const settingMenuItems: Array<ISettingMenuItem> = [
  {
    key: SettingItemKeys.GENERAL,
    name: 'Général',
    component: <GeneralSetting />,
    icon: <BsGear />,
    value: 1,
  },
  {
    key: SettingItemKeys.INVOICE_PLAN,
    name: 'Plan de facture',
    component: <InvoiceSetting />,
    icon: <BsFileEarmark />,
    value: 2,
    darkBg: true,
  },
  {
    key: SettingItemKeys.INVOICE_RUBRIC,
    name: 'Rubriques de facturation',
    component: <InvoicePlanRubricSetting />,
    icon: <BsBox />,
    value: 3,
    darkBg: true,
  },
  {
    key: SettingItemKeys.RATIO,
    name: 'Coefficients',
    component: <RatioSetting />,
    icon: <BsPercent />,
    darkBg: true,
    value: 4,
  },
  {
    key: SettingItemKeys.ACTS,
    name: 'Tarifications Actes',
    component: <ActSetting />,
    icon: <BsCardHeading />,
    value: 5,
    darkBg: true,
  },
  {
    key: SettingItemKeys.INSURANCE,
    name: 'Assurances',
    component: <InsuranceSetting />,
    icon: <BsCardHeading />,
    value: 6,
    darkBg: true,
  },
  {
    key: SettingItemKeys.RECEIPT_STAMP,
    name: 'Tarification timbres',
    component: <ReceiptStampSetting />,
    icon: <BsCardHeading />,
    value: 7,
    darkBg: true,
  },
  {
    key: SettingItemKeys.SUBSCRIBERS,
    name: 'Souscripteurs',
    component: <SubscriberSetting />,
    icon: <BsCardHeading />,
    value: 8,
    darkBg: true,
  },
  {
    key: SettingItemKeys.USERS,
    name: 'Utilisateurs',
    component: <UserSetting />,
    icon: <FiUsers />,
    value: 9,
  },
  {
    key: SettingItemKeys.ROLES,
    name: 'Rôles',
    component: <RoleSetting />,
    icon: <FaUserLock />,
    value: 10,
  },
  {
    key: SettingItemKeys.SITE,
    name: 'Site',
    component: <SiteSetting />,
    icon: <FaSitemap />,
    value: 11,
  },
];

const Settings: React.FC = () => {
  const [activeRefTab, setActiveRefTab] = useState<SettingItemKeys>(
    SettingItemKeys.GENERAL,
  );
  const [activeRefTabContent, setActiveRefTabContent] = useState<number>(1);

  const handleSelectMenuRef = (menu: ISettingMenuItem) => {
    setActiveRefTab(menu.key);
    setActiveRefTabContent(menu.value);
  };

  const renderSettingsMenuItems = () => {
    return (
      <ConfigMenu<SettingItemKeys>
        activeTab={activeRefTab}
        handleSelectMenuRef={menu => handleSelectMenuRef(menu)}
        menuItems={settingMenuItems}
      />
    );
  };

  const renderSettingsMenuContent = () => {
    return settingMenuItems[activeRefTabContent - 1].component;
  };

  const renderContent = () => {
    return (
      <>
        <ConfigLayoutMenuWrapper style={{ maxHeight: '90vh' }}>
          {renderSettingsMenuItems()}
        </ConfigLayoutMenuWrapper>
        <ConfigLayoutContentWrapper style={{ maxHeight: '90vh' }}>
          <ConfigLayoutMenuContainer>
            {renderSettingsMenuContent()}
          </ConfigLayoutMenuContainer>
        </ConfigLayoutContentWrapper>
      </>
    );
  };

  return (
    <VerticalLayout enableVerticalScroll='false'>
      <PageHeader title='Paramètres' />
      <ConfigLayoutPageContainer>{renderContent()}</ConfigLayoutPageContainer>
    </VerticalLayout>
  );
};
export default Settings;
