const chartColors = {
  blue1: '#5076e8',
  blue: '#3864E8',
  deepBlue: '#3864E8',
  blue50: '#e2eaff',
  mainGray: '#788099',
  white: '#FFFFFF',
  black: '#000000',
  success: '#03C988',
  warning: '#F2BE22',
  lightGray: '#D9D9D9',
  danger: '#FF4D4F',
};

export default chartColors;
