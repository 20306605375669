import React, { PropsWithChildren } from 'react';
import chartColors from '../../styles/color';

type CguSubParagraphProps = {
  rank: number;
  title: string;
};

const CguSubParagraph: React.FC<PropsWithChildren<CguSubParagraphProps>> = ({
  rank,
  title,
  children,
}) => {
  return (
    <div className='ml-10' style={{ marginBottom: 16 }}>
      <span
        style={{
          color: chartColors.deepBlue,
          fontSize: '2rem',
        }}>{`${rank}. ${title}`}</span>
      <div style={{ fontSize: '1.5rem', fontFamily: 'Sharp sans, sans-serif' }}>
        {children}
      </div>
    </div>
  );
};

export default CguSubParagraph;
