import React from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { StyledButton } from '../../../../components/button/style';
import FormFieldLabel from '../../../../components/label/FormFieldLabel';
import {
  StyledCardItem,
  StyledCol,
  StyledInput,
  StyledInputNumber,
  StyledRow,
} from '../../../../components/style';

import {
  CreatePrescriptionAction,
  CreatePrescriptionState,
} from '../../../../reducers/prescription/types';
import chartColors from '../../../../styles/color';
import { IMedicationPrescription } from '../../../../types';

interface MedicationItemCardProps {
  medicationData: IMedicationPrescription;
  reducer: React.Dispatch<CreatePrescriptionAction>;
  state: CreatePrescriptionState;
  handleRemoveRubric: () => void;
}

const MedicationItemCard: React.FC<MedicationItemCardProps> = ({
  medicationData,
  reducer,
  state,
  handleRemoveRubric,
}) => {
  const checkExistingRubric = (): number => {
    const indexFound = state.prescriptionLines.findIndex(
      item => item.idMedication === medicationData.idMedication,
    );
    if (indexFound !== -1) {
      return indexFound;
    }
    return 0;
  };

  return (
    <StyledCardItem
      style={{ margin: '10px 0px' }}
      styles={{
        header: {
          fontFamily: 'Metropolis',
          textTransform: 'uppercase',
          backgroundColor: chartColors.blue50,
        },
      }}
      title={`${medicationData.medicationDesignation} ${
        medicationData.unit && `/ ${medicationData.unit}`
      }`}
      size='small'
      extra={
        <StyledButton
          danger
          size='small'
          icon={<FiTrash2 />}
          onClick={() => handleRemoveRubric()}
        />
      }>
      <StyledRow gutter={[8, 8]}>
        <StyledCol span={12}>
          <FormFieldLabel libelle='Quantité' isRequired />
          <StyledInputNumber
            required
            style={{ width: '100%' }}
            placeholder='Quantité'
            min={1}
            onChange={val =>
              reducer({
                type: 'setQuantityMedication',
                payload: {
                  idMedication: medicationData.idMedication,
                  value: val as number,
                },
              })
            }
            value={state.prescriptionLines[checkExistingRubric()]?.quantity}
          />
        </StyledCol>

        <StyledCol span={12}>
          <FormFieldLabel libelle='Durée (en jours)' isRequired />
          <StyledInput
            required
            style={{ width: '100%' }}
            placeholder='Durée (en Jours)'
            onChange={e =>
              reducer({
                type: 'setTreatDurationMedication',
                payload: {
                  idMedication: medicationData.idMedication,
                  value: e.target.value,
                },
              })
            }
            value={state.prescriptionLines[checkExistingRubric()]?.treatmentDuration}
          />
        </StyledCol>
        <StyledCol span={24}>
          <FormFieldLabel libelle='Posologie' isRequired />
          <StyledInput
            required
            style={{ width: '100%' }}
            placeholder='Posologie'
            onChange={e =>
              reducer({
                type: 'setPosologyMedication',
                payload: {
                  idMedication: medicationData.idMedication,
                  value: e.target.value,
                },
              })
            }
            value={state.prescriptionLines[checkExistingRubric()]?.posology}
          />
        </StyledCol>
      </StyledRow>
    </StyledCardItem>
  );
};

export default MedicationItemCard;
