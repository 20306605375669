import { App as AppContainer } from 'antd';

import _ from 'lodash';
import { useEffect, useReducer, useState } from 'react';
import {
  getEvolutionByMedicalRecord,
  updateEvolutionApi,
} from '../../../api/dossierPatientService';
import { StyledButton } from '../../../components/button/style';
import FormFieldLabel from '../../../components/label/FormFieldLabel';
import Loader from '../../../components/loader';
import {
  StyledCheckbox,
  StyledCol,
  StyledDatePicker,
  StyledInputTextArea,
  StyledRow,
} from '../../../components/style';
import {
  CreateEvolutionReducer,
  getCreateEvolutionDefaultInitialState,
} from '../../../reducers/evolution/createEvolution';
import { CreateEvolutionState } from '../../../reducers/evolution/types';
import {
  CreateEvolutionRequestBody,
  CreateMedicalRecordApiResponse,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { DATE_FORMAT, ErrorMsgTitle, SuccesssMsgTitle } from '../../../utils/constants';
import { getDataFromStorage } from '../../../utils/storage';
import { StyledMedicalRecordBlocContainer } from '../../style';
import dayjsInstance from '../../../dayjs';

const Evolution: React.FC = () => {
  const { notification } = AppContainer.useApp();
  const medicalRecord =
    getDataFromStorage<CreateMedicalRecordApiResponse>('medicalRecord');

  const [state, reducer] = useReducer(
    CreateEvolutionReducer,
    getCreateEvolutionDefaultInitialState(),
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    /* The code block `if (!_.isUndefined(medicalRecord)) { ... }` checks if the `medicalRecord`
   variable is defined or not. If it is defined, it executes the code inside the block. */
    if (!_.isUndefined(medicalRecord)) {
      Promise.resolve(getEvolutionByMedicalRecord(medicalRecord.id)).then(result => {
        reducer({
          type: 'setCommentEvolution',
          payload: result.evolution,
        });
        reducer({
          type: 'setEvolutionDate',
          payload: result.evolutionDate,
        });
        reducer({
          type: 'setClosedEvolution',
          payload: result.closed,
        });
      });
    }
  }, []);

  /**
   * The function `mapStateToRequestBody` takes in a state object and returns a request body object for
   * creating an evolution, with default values for evolution date and the medical record ID.
   * @param {CreateEvolutionState} state - The `state` parameter is of type `CreateEvolutionState`,
   * which is an object containing the current state of the evolution form.
   * @returns an object of type CreateEvolutionRequestBody.
   */
  const mapStateToRequestBody = (
    state: CreateEvolutionState,
  ): CreateEvolutionRequestBody => {
    const requestBody = {} as CreateEvolutionRequestBody;
    requestBody.evolutionDate = !_.isEmpty(state.evolutionDate)
      ? state.evolutionDate
      : dayjsInstance().format(DATE_FORMAT);
    requestBody.closed = state.closed;
    requestBody.evolution = state.evolution;
    // requestBody.idMedicalRecord = medicalRecord && medicalRecord?.id;

    return requestBody;
  };

  /**
   * The function `updateEvolution` is an asynchronous function that updates the evolution of a patient
   * and handles success and error messages.
   */
  const updateEvolution = async () => {
    try {
      setIsLoading(true);
      console.log(state);
      await updateEvolutionApi(medicalRecord?.id, mapStateToRequestBody(state)).then(
        () => {
          notification.success({
            message: SuccesssMsgTitle,
            description: "L'évolution du patient a bien été mise à jour.",
          });
        },
      );
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
    } finally {
      setIsLoading(false);
    }
  };

  /* The `renderContent` function is responsible for rendering the content of the Evolution component.
  It returns JSX code that represents the form for updating the evolution of a patient. The JSX code
  includes various components such as `StyledMedicalRecordBlocContainer`, `StyledRow`, `StyledCol`,
  `StyledDatePicker`, `StyledInputTextArea`, `StyledCheckbox`, and `StyledButton`. These components
  are styled using CSS and have various props and event handlers attached to them. The function also
  includes conditional rendering based on the `medicalRecord` variable, which determines whether the
  update button should be displayed or not. */
  const renderContent = () => {
    return (
      <StyledMedicalRecordBlocContainer
        style={{ backgroundColor: '#FFF', padding: '30px 20px' }}>
        <StyledRow>
          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}>
            <StyledRow gutter={[16, 16]}>
              <StyledCol span={24}>
                <FormFieldLabel libelle="Date d'enregistrement" isRequired={true} />
                <StyledDatePicker
                  name='date'
                  placeholder='Date'
                  style={{ width: '100%' }}
                  onChange={(value, dateString) => {
                    reducer({
                      type: 'setEvolutionDate',
                      payload: Array.isArray(dateString) ? dateString[0] : dateString,
                    });
                  }}
                  value={
                    _.isEmpty(state.evolutionDate)
                      ? dayjsInstance()
                      : dayjsInstance(state.evolutionDate, DATE_FORMAT)
                  }
                  disabledDate={current => dayjsInstance().isBefore(current, 'day')}
                />
              </StyledCol>
              <StyledCol span={24}>
                <FormFieldLabel libelle='Commentaires' />
                <StyledInputTextArea
                  rows={5}
                  maxLength={500}
                  showCount
                  placeholder='Commentaires'
                  onChange={e =>
                    reducer({ type: 'setCommentEvolution', payload: e.target.value })
                  }
                  value={state.evolution}
                />
              </StyledCol>
              <StyledCol span={24}>
                <StyledCheckbox
                  checked={state.closed}
                  defaultChecked={state.closed}
                  onChange={e =>
                    reducer({ type: 'setClosedEvolution', payload: e.target.checked })
                  }>
                  Dossier Clos
                </StyledCheckbox>
              </StyledCol>
              {!_.isUndefined(medicalRecord) && (
                <StyledCol span={24}>
                  <StyledButton
                    size='large'
                    type='primary'
                    disabled={
                      isLoading ||
                      ((_.isUndefined(state.closed) || _.isEqual(state.closed, false)) &&
                        _.isEmpty(state.evolution))
                    }
                    onClick={() => updateEvolution()}>
                    Mettre à jour
                  </StyledButton>
                </StyledCol>
              )}
            </StyledRow>
          </StyledCol>
        </StyledRow>
      </StyledMedicalRecordBlocContainer>
    );
  };

  return <Loader showSpinner={isLoading}>{renderContent()}</Loader>;
};

export default Evolution;
