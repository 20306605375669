import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useState } from 'react';
import { MdEditNote, MdPrint } from 'react-icons/md';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import {
  CreateMedicalRecordApiResponse,
  IMedicalRecordDetails,
  NavigationMode,
  StorageType,
} from '../../types';
import { saveInStorage } from '../../utils/storage';
import { StyledButton } from '../button/style';
import { StyledTitle } from '../header/style';
import { StyledSpace } from '../style';
import { StyledSectionPatientContainer } from './style';
import PdfDrawerViewer from '../viewer/PdfDrawerViewer';
import RenderDocument from '../../containers/document';
import { getMedicalRecordDetailsByIdsApi } from '../../api/dossierPatientService';

interface MedicalRecordPatientListProps {
  dataMedicalRecords: Array<CreateMedicalRecordApiResponse>;
  showListHeader?: boolean;
  showTableHeader?: boolean;
  showPrintBtn?: boolean;
  customColumns?: ColumnsType<CreateMedicalRecordApiResponse>;
  hasPagination?: boolean;
}

const MedicalRecordPatientList: React.FC<MedicalRecordPatientListProps> = ({
  dataMedicalRecords,
  showPrintBtn = true,
  showListHeader = true,
  showTableHeader = true,
  customColumns,
  hasPagination = true,
}) => {
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showMedicalRecordPdf, setshowMedicalRecordPdf] = useState<boolean>(false);
  const [selectedMedicalRecord, setSelectedMedicalRecord] =
    useState<IMedicalRecordDetails>();

  const loadMedicalRecordDetails = async (medicalRecordId: number) => {
    return Promise.resolve(getMedicalRecordDetailsByIdsApi([medicalRecordId]));
  };

  const columnData: ColumnsType<CreateMedicalRecordApiResponse> = [
    {
      title: 'No Dossier',
      dataIndex: 'numMedicalRecord',
    },
    {
      title: 'Crée le',
      dataIndex: 'createdDate',
      align: 'left',
      defaultSortOrder: 'descend',
    },
    {
      align: 'right',
      render: (_value, record) => {
        return (
          <StyledSpace>
            <StyledButton
              shape='circle'
              icon={<MdEditNote />}
              size='middle'
              onClick={() => {
                saveInStorage(StorageType.SS, 'medicalRecord', record);
                navigate('/dossier-patient', {
                  state: {
                    mode: NavigationMode.READ_MODIFY_MODE,
                  },
                });
              }}
            />
            {showPrintBtn && (
              <StyledButton
                shape='circle'
                icon={<MdPrint />}
                size='middle'
                onClick={() => {
                  handleSelectMedicalRecord(record);
                }}
              />
            )}
          </StyledSpace>
        );
      },
    },
  ];

  const handleSelectMedicalRecord = (medicalRecord: CreateMedicalRecordApiResponse) => {
    setIsLoading(true);
    loadMedicalRecordDetails(medicalRecord.id).then(res => {
      setSelectedMedicalRecord(res[0]);
      setshowMedicalRecordPdf(true);
      setIsLoading(false);
    });
  };

  const handleCloseViewer = () => {
    setshowMedicalRecordPdf(false);
    setSelectedMedicalRecord(undefined);
  };

  return (
    <>
      <StyledSectionPatientContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          {showListHeader && <StyledTitle>Dossiers Patients</StyledTitle>}
        </div>
        <div style={{ padding: '0px', marginTop: 10 }}></div>
        {isTabletOrMobile ? (
          ''
        ) : (
          <Table
            rowKey='id'
            size='small'
            showHeader={showTableHeader}
            columns={customColumns ?? columnData}
            dataSource={dataMedicalRecords}
            pagination={hasPagination && { pageSize: 10 }}
            loading={isLoading}
          />
        )}
      </StyledSectionPatientContainer>

      {showMedicalRecordPdf && (
        <PdfDrawerViewer
          drawerTitle={`Dossier ${selectedMedicalRecord?.id} `}
          closeDrawer={() => setshowMedicalRecordPdf(false)}
          extraActions={
            <>
              <StyledButton
                key='cancel_invoice'
                type='primary'
                size='middle'
                danger
                disabled={isLoading}
                onClick={() => handleCloseViewer()}>
                Fermer
              </StyledButton>
            </>
          }
          isDrawerOpen={showMedicalRecordPdf}>
          <RenderDocument<IMedicalRecordDetails | undefined>
            documentData={selectedMedicalRecord}
            documentType='MEDICAL_RECORD_PDF'
            isDuplicata={false}
          />
        </PdfDrawerViewer>
      )}
    </>
  );
};

export default MedicalRecordPatientList;
