import {
  CreateBiologicalExamRequestBody,
  GetBiologicalExamsResponse,
  IBiologicalExamItem,
} from '../types';
import { axiosInstance } from './axiosInstance';

export const getBiologicalExamsApi = (): Promise<GetBiologicalExamsResponse> => {
  return axiosInstance
    .get('/biologicalExam')
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la recupération des examens biologiques. Raison: ${message}`,
      );
    });
};

export const createBiologicalExamApi = (
  data: CreateBiologicalExamRequestBody,
): Promise<IBiologicalExamItem> => {
  return axiosInstance
    .post('/biologicalExam', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la création d'un examen biologique. Raison: ${message}`,
      );
    });
};

export const updateBiologicalExamApi = (
  biologicalExamId: number,
  data: CreateBiologicalExamRequestBody,
): Promise<string> => {
  return axiosInstance
    .put(`/biologicalExam?id=${biologicalExamId}`, { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la modification d'un examen biologique. Raison: ${message}`,
      );
    });
};

export const deleteBiologicalExamApi = (biologicalExamId: number): Promise<string> => {
  return axiosInstance
    .delete(`/biologicalExam?id=${biologicalExamId}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(
        `Erreur lors de la suppression de l'examen biologique. Raison: ${message}`,
      );
    });
};
