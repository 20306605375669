import React from 'react';
import packageJson from '../../../package.json';
import LogoApiPatient from '../../assets/images/logo_api_patient.svg';
import chartColors from '../../styles/color';
import {
  StyleFormHeader,
  StyledLoginContainer,
  StyledLoginFormWrapper,
  StyledLoginLeftContainer,
  StyledLoginRightContainer,
  StyledLoginWrapper,
  StyledVersion,
} from '../style';
import LoginForm from './LoginForm';

const Login: React.FC = () => {
  return (
    <StyledLoginWrapper>
      <StyledLoginContainer>
        <StyledLoginLeftContainer></StyledLoginLeftContainer>
        <StyledLoginRightContainer>
          <StyledLoginFormWrapper>
            <div
              style={{
                height: 100,
                backgroundColor: chartColors.deepBlue + 10,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <img src={LogoApiPatient} style={{ height: '3.5em' }} />
              <StyleFormHeader>Connexion à votre compte </StyleFormHeader>
            </div>
            <LoginForm />
            <StyledVersion>{`ApiPatient-${process.env.NODE_ENV}_build-${packageJson.version}`}</StyledVersion>
          </StyledLoginFormWrapper>
        </StyledLoginRightContainer>
      </StyledLoginContainer>
    </StyledLoginWrapper>
  );
};

export default Login;
