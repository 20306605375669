/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { BsNodePlus } from 'react-icons/bs';
import { FiSearch } from 'react-icons/fi';
import { StyledButton } from '../../../components/button/style';
import CustomModal from '../../../components/modal/Modal';
import {
  StyledInput,
  StyledSpace,
  StyledTable,
  StyledTag,
} from '../../../components/style';
import {
  CreatePharmacyOrderAction,
  CreatePharmacyOrderState,
} from '../../../reducers/pharmacy/types';
import { GetArticlesResponse, IPharmacyOrderArticle } from '../../../types';
import { ScrollContainer } from '../../style';
import OrderArticleCard from './OrderArticleCard';

interface AddRemoveArticlesProps {
  items: GetArticlesResponse;
  listTitle: string;
  isLoading: boolean;
  // handleUpdate: (args: InvoicePlanRubricDataProps[]) => void;
  reducer: React.Dispatch<CreatePharmacyOrderAction>;
  state: CreatePharmacyOrderState;
  validationComponent: () => JSX.Element;
}

const AddRemoveArticles: React.FC<AddRemoveArticlesProps> = ({
  items,
  state,
  reducer,
  isLoading,
  listTitle,
  validationComponent,
}) => {
  const [searchFilter, setSearchFilter] = useState<string>('');

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [savedItems, setSavedItems] = useState<IPharmacyOrderArticle[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [checkedItems, setCheckedItems] = useState<IPharmacyOrderArticle[]>([]);

  const filteredItems = _.differenceBy(items, savedItems, 'id').filter(item =>
    item?.designation.includes(searchFilter),
  );

  const itemsColumns = [
    {
      title: 'designation',
      dataIndex: 'designation',
      render: (_value, record) => (
        <>
          {record.designation} <StyledTag>{record.reference}</StyledTag>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (_.isEmpty(state.articles)) {
      setSavedItems([]);
      setSelectedRowKeys([]);
      setCheckedItems([]);
    }
  }, [state.articles]);

  useEffect(() => {
    const defaultState = _.map(state.articles, 'idArticle');
    setSelectedRowKeys(defaultState);
  }, [state.articles]);

  const handCloseModalVisility = () => {
    setIsModalVisible(false);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setCheckedItems(selectedRows);
  };

  const itemsSelections = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const mapToOrderLine = (articles: IPharmacyOrderArticle[]) => {
    const orderLinesItems: IPharmacyOrderArticle[] = [];

    articles.map(item => {
      const articleLineItem: IPharmacyOrderArticle = {
        id: item.id as number,
        idArticle: item.id as number,
        quantityOrdered: 1,
        designation: item.designation,
        reference: item.reference,
        unitPrice: item.amount as number,
      };

      orderLinesItems.push(articleLineItem);
    });

    return orderLinesItems;
  };

  const handleValidateItems = () => {
    // On verifie que les éléments ajoutés n'existent pas déja
    const itemsToAdd = _.differenceBy(checkedItems, state.articles, 'idArticle');
    setSavedItems(_.concat(savedItems, itemsToAdd));
    setIsModalVisible(false);
    reducer({ type: 'setArticles', payload: mapToOrderLine(itemsToAdd) });
  };

  const removeItemFromSavedItem = (currentArticleId: number) => {
    const result = savedItems.filter(item => item.id !== currentArticleId);
    setSelectedRowKeys(selectedRowKeys.filter(itemId => itemId !== currentArticleId));
    setSavedItems(result);
    reducer({ type: 'removeArticle', payload: currentArticleId });
  };

  const renderModalSelectionList = () => {
    return (
      <StyledTable
        rowKey='id'
        size='small'
        showHeader={false}
        bordered={false}
        dataSource={filteredItems}
        pagination={false}
        rowSelection={itemsSelections}
        columns={itemsColumns}
      />
    );
  };

  const renderSelectedItems = () => {
    return savedItems.map((item, idx) => {
      return (
        <OrderArticleCard
          articleData={item}
          key={`${item.idArticle}_${item.designation}_${idx}`}
          state={state}
          reducer={reducer}
          handleRemove={() => removeItemFromSavedItem(item.id as number)}
        />
      );
    });
  };

  return (
    <>
      <StyledSpace>
        <StyledButton
          loading={isLoading}
          disabled={isLoading}
          size='middle'
          icon={<BsNodePlus />}
          block
          type='dashed'
          onClick={() => {
            setIsModalVisible(true);
          }}>
          Ajouter un article
        </StyledButton>

        {validationComponent()}
      </StyledSpace>
      {renderSelectedItems()}
      <CustomModal
        isVisible={isModalVisible}
        handleVisibility={handCloseModalVisility}
        title={listTitle}
        afterCloseModal={() => setSearchFilter('')}
        footer={[
          <StyledButton
            key='cancel'
            type='primary'
            onClick={() => handleValidateItems()}
            size='middle'
            loading={isLoading}
            disabled={isLoading}
            block>
            Valider et Fermer
          </StyledButton>,
        ]}>
        <StyledInput
          placeholder='Rechercher un élément'
          value={searchFilter}
          onChange={e => setSearchFilter(e.target.value)}
          prefix={<FiSearch />}
        />
        <ScrollContainer style={{ height: '50vh' }}>
          <div>{renderModalSelectionList()}</div>
        </ScrollContainer>
      </CustomModal>
    </>
  );
};

export default AddRemoveArticles;
