/* eslint-disable @typescript-eslint/no-explicit-any */
import { App as AppContainer, Table } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import {
  getInvoiceRubricAmountsApi,
  getRubricsByInvoicePlanApi,
} from '../../api/invoiceService';
import { StyledButton } from '../../components/button/style';
import Loader from '../../components/loader';
import CustomModal from '../../components/modal/Modal';
import { StyledCol, StyledInput, StyledRow } from '../../components/style';
import {
  CreateAutoInvoiceProformaState,
  CreateInvoiceProformaAction,
} from '../../reducers/invoicePlan/types';
import chartColors from '../../styles/color';
import {
  CreateMedicalRecordApiResponse,
  GetInvoiceRubricsResponse,
  GetUnitResponse,
  InvoicePlanDataProps,
  ProformaLineDto,
} from '../../types';
import { getApiErrorMsg, thousandSeparatorFormat } from '../../utils/commun';
import { ErrorMsgTitle } from '../../utils/constants';
import { ScrollContainer } from '../style';
import PlanItemCard from './PlanItemCard';
import RubricPlanItemCard from './RubricPlanItem';

interface AddRemovePlanProps {
  items: InvoicePlanDataProps[];
  listTitle: string;
  isLoading: boolean;
  reducer: React.Dispatch<CreateInvoiceProformaAction>;
  state: CreateAutoInvoiceProformaState;
  unitsData: GetUnitResponse[];
  isModalVisible: boolean;
  handleCloseModalVisibility: () => void;
  selectedMedicalRecord: CreateMedicalRecordApiResponse | null;
  // handleUpdate: (args: InvoicePlanRubricDataProps[]) => void;
}

const AddRemoveInvoicePlan: React.FC<AddRemovePlanProps> = ({
  items,
  listTitle,
  isLoading,
  reducer,
  state,
  unitsData,
  handleCloseModalVisibility,
  isModalVisible,
  selectedMedicalRecord,
  // handleUpdate,
}) => {
  const { notification } = AppContainer.useApp();
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [savedItems, setSavedItems] = useState<GetInvoiceRubricsResponse>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key | any>([]);
  const [checkedItems, setCheckedItems] = useState<GetInvoiceRubricsResponse>([]);
  const [isLoadingRubrics, setIsLoadingRubrics] = useState<boolean>(false);

  const filteredItems = items.filter(item => item?.label.includes(searchFilter));

  const itemsColumns = [
    {
      title: 'label',
      dataIndex: 'label',
    },
  ];

  useEffect(() => {
    const defaultState = state.plan?.id;
    if (!_.isUndefined(defaultState)) {
      setSelectedRowKeys([defaultState]);
    }
  }, [state.plan]);

  // Selection et déselection d"une rubrique dans le modal
  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setCheckedItems(selectedRows);
  };

  const itemsSelections: TableRowSelection<InvoicePlanDataProps> = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: InvoicePlanDataProps) => ({
      disabled: selectedRowKeys.length === 1 && !selectedRowKeys.includes(record.id),
    }),
  };

  // Initialisation des valeurs de chaque rubrique du plan sélectionné
  const mapToProformaLine = async (rubrics: GetInvoiceRubricsResponse) => {
    const proformaLinesItems: ProformaLineDto[] = [];
    if (!_.isEmpty(rubrics)) {
      try {
        setIsLoadingRubrics(true);
        await Promise.all(
          rubrics.map(async (item, idx) => {
            try {
              const rubric = await getInvoiceRubricAmountsApi({
                idMedicalRecord: selectedMedicalRecord?.id as number,
                idRubric: item.id,
              });

              const proformaLineItem: ProformaLineDto = {
                ...rubric,
                id: rubric.idRubric,
              };
              proformaLinesItems.push(proformaLineItem);

              if (rubrics.length === idx + 1) {
                setIsLoadingRubrics(false);
                handleCloseModalVisibility();
              }
            } catch (error) {
              setIsLoadingRubrics(false);
              notification.error({
                message: ErrorMsgTitle,
                description: getApiErrorMsg(error),
              });
            }
          }),
        );
      } catch (error) {
        setIsLoadingRubrics(false);
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(error),
        });
      }
    }

    return proformaLinesItems;
  };

  // Récupération des rubriques des plans sélectionnés après validation du modal
  const loadAllRubrics = (idPlan: number) => {
    setIsLoadingRubrics(true);
    Promise.resolve(getRubricsByInvoicePlanApi(idPlan))
      .then(result => {
        setSavedItems(result);
        if (_.isEmpty(result)) {
          notification.error({
            message: 'Plan de facturation vide',
            description: "Ce plan n'a aucune rubrique, sélectionnez un autre",
          });
          setSelectedRowKeys([]);
          reducer({ type: 'removePlan' });
        } else {
          mapToProformaLine(result).then(proformaItems => {
            reducer({
              type: 'setPlanRubrics',
              payload: proformaItems,
            });
          });
          reducer({ type: 'setPlan', payload: checkedItems[0] });
        }
      })
      .catch(() => {
        // console.log(e);
      })
      .finally(() => {
        setIsLoadingRubrics(false);
      });
  };

  // Valider le plan sélectionné et charger ses rubriques avant de fermer le modal
  const handleValidateItems = () => {
    loadAllRubrics(checkedItems[0].id);
    reducer({ type: 'setPlan', payload: checkedItems[0] });
    // setSavedItems(checkedItems);
  };

  // Retirer le plan sélectionné avec toutes ses rubriques
  const removePlanWithRubrics = () => {
    setSelectedRowKeys([]);
    setSavedItems([]);
    reducer({ type: 'removePlan' });
  };

  // Afficher le plan sélectionné et ses rubriques
  const renderSelectedItems = () => {
    if (isLoadingRubrics) {
      return <Loader showSpinner={isLoadingRubrics} />;
    }
    return (
      <PlanItemCard
        state={state}
        handleRemovePlan={() => removePlanWithRubrics()}
        isLoading={isLoading}>
        {savedItems.map((rubricItem, idx) => (
          <RubricPlanItemCard
            key={`${rubricItem.id}_${idx}`}
            rubricData={rubricItem}
            state={state}
            reducer={reducer}
            unitsData={unitsData}
          />
        ))}
      </PlanItemCard>
    );
  };

  // Afficher la liste de InvoicePlan dans le modal
  const renderModalSelectionList = () => {
    return (
      <Table<InvoicePlanDataProps>
        rowKey='id'
        size='small'
        showHeader={false}
        bordered={false}
        dataSource={filteredItems}
        pagination={false}
        rowSelection={itemsSelections}
        columns={itemsColumns}
      />
    );
  };

  const renderSelectedRubricsTotal = () => {
    let totalAssu = 0;
    let totalPatient = 0;
    let totalTTC = 0;

    state.rubrics.map(item => {
      const itemAssu = item.amountAssu * item.quantity;
      const itemPatient = item.amountPatient * item.quantity;

      totalAssu = totalAssu + itemAssu;
      totalPatient = totalPatient + itemPatient;
      totalTTC = totalAssu + totalPatient;
    });

    return (
      <StyledRow
        style={{
          marginBottom: 20,
          backgroundColor: chartColors.blue50,
          padding: 10,
          fontWeight: 700,
        }}>
        <StyledCol span={9}>TOTAL</StyledCol>
        <StyledCol span={5} style={{ textAlign: 'right' }}>
          MONTANT TOTAL: {thousandSeparatorFormat(totalTTC)}
        </StyledCol>
        <StyledCol span={5} style={{ textAlign: 'right' }}>
          PART ASSURANCE: {thousandSeparatorFormat(totalAssu)}
        </StyledCol>
        <StyledCol span={5} style={{ textAlign: 'right' }}>
          PART PATIENT: {thousandSeparatorFormat(totalPatient)}
        </StyledCol>
      </StyledRow>
    );
  };

  return (
    <>
      {!_.isUndefined(state.plan) && renderSelectedItems()}
      {!_.isEmpty(savedItems) && renderSelectedRubricsTotal()}

      <CustomModal
        isVisible={isModalVisible}
        handleVisibility={handleCloseModalVisibility}
        title={listTitle}
        afterCloseModal={() => setSearchFilter('')}
        footer={[
          <StyledButton
            key='cancel'
            type='primary'
            onClick={() => handleValidateItems()}
            size='middle'
            loading={isLoading || isLoadingRubrics}
            disabled={isLoading || isLoadingRubrics || _.isEmpty(selectedRowKeys)}
            block>
            Valider et Fermer
          </StyledButton>,
        ]}>
        <StyledInput
          placeholder='Rechercher un élément'
          value={searchFilter}
          onChange={e => setSearchFilter(e.target.value)}
          prefix={<FiSearch />}
        />
        <ScrollContainer style={{ height: '50vh' }}>
          <div>{renderModalSelectionList()}</div>
        </ScrollContainer>
      </CustomModal>
    </>
  );
};

export default AddRemoveInvoicePlan;
