import { App as AppContainer, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FaUserLock, FaUserTag } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';
import { getRightsApi } from '../../../api/rightService';
import { getRolesApi } from '../../../api/roleService';
import { getUsersApi } from '../../../api/userService';
import { StyledButton } from '../../../components/button/style';
import SectionHeader from '../../../components/header/SectionHeader';
import Loader from '../../../components/loader';
import { StyledDivider, StyledSpace, StyledTooltip } from '../../../components/style';
import {
  GetRightsResponse,
  GetRolesResponse,
  GetUsersResponse,
  IUserItem,
  ReferentialViewProps,
} from '../../../types';
import { getApiErrorMsg } from '../../../utils/commun';
import { ErrorMsgTitle } from '../../../utils/constants';
import AddRemoveUserRight from './AddRemoveUserRight';
import AddRemoveUserRole from './AddRemoveUserRole';

type UsersListProps = {
  navigateToview: (p: ReferentialViewProps) => void;
  updateCurrentItem: (p: IUserItem) => void;
};

const UsersList: React.FC<UsersListProps> = ({ navigateToview, updateCurrentItem }) => {
  const { notification } = AppContainer.useApp();
  const [usersData, setUsersData] = useState<GetUsersResponse>([]);
  const [rolesData, setRolesData] = useState<GetRolesResponse>([]);
  const [rightsData, setRightsData] = useState<GetRightsResponse>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingResources, setIsLoadingResources] = useState<boolean>(false);
  const [showModalRoles, setShowModalRoles] = useState<boolean>(false);
  const [showModalRights, setShowModalRights] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IUserItem>();

  const handleUpdate = (user: IUserItem) => {
    updateCurrentItem(user);
    navigateToview('EDIT_ITEM');
  };

  const handleModalRoles = (user?: IUserItem) => {
    if (_.isUndefined(user)) {
      setSelectedUser(undefined);
      loadUsers();
    } else {
      setSelectedUser(user);
    }
    setShowModalRoles(state => !state);
  };

  const handleModalRights = (user?: IUserItem) => {
    if (_.isUndefined(user)) {
      setSelectedUser(undefined);
      loadUsers();
    } else {
      setSelectedUser(user);
    }
    setShowModalRights(state => !state);
  };

  //   const deleteUser = async (user: IUserItem) => {
  //     try {
  //       setIsLoading(true);
  //       Promise.resolve(deleteUserApi(user.id as number)).then(() => {
  //         loadUsers();
  //         notification.success({
  //           message: SuccesssMsgTitle,
  //           description: `La catégorie a été supprimée`,
  //         });
  //       });
  //     } catch (error) {
  //       notification.error({
  //         message: ErrorMsgTitle,
  //         description: getApiErrorMsg(ErrorDeleteMsg),
  //       });
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  // const handleRemoveUser = (user: IUserItem) => {
  //   modal.confirm({
  //     title: 'Confirmation de suppression',
  //     icon: <BsExclamationTriangleFill size={40} color={chartColors.warning} />,
  //     content: `Êtes-vous sûr de vouloir supprimer l'utilisateur ${
  //       user.firstname + '' + user.name
  //     } de manière permanente ?`,
  //     okText: 'Oui, je Confirme',
  //     cancelText: 'Annuler',
  //     onOk: () => console.log('delete'),
  //     //   onOk: () => deleteUser(user),
  //   });
  // };

  const loadUsers = async () => {
    setIsLoading(true);
    Promise.resolve(getUsersApi())
      .then(result => {
        setUsersData(result);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadResources = () => {
    setIsLoadingResources(true);
    Promise.all([getRolesApi(), getRightsApi()])
      .then(result => {
        setRolesData(result[0]);
        setRightsData(result[1]);
      })
      .catch(e => {
        notification.error({
          message: ErrorMsgTitle,
          description: getApiErrorMsg(e),
        });
      })
      .finally(() => {
        setIsLoadingResources(false);
      });
  };

  useEffect(() => {
    loadUsers();
    loadResources();
  }, []);

  const columnData: ColumnsType<IUserItem> = [
    {
      title: 'Nom et prénoms',
      render: (_value, record) => (
        <>{`${record.level ?? ''} ${record.name} ${record.firstname}`}</>
      ),
    },
    {
      title: 'Téléphone',
      dataIndex: 'tel',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Service',
      render: (_value, record) => <>{record.serviceDto.name}</>,
    },
    {
      title: 'Actions',
      align: 'left',
      render: (_value, record) => {
        return (
          <StyledSpace>
            {/* <StyledButton
              shape='circle'
              size='middle'
              icon={<FiEdit />}
              onClick={() => handleUpdate(record)}
            /> */}
            <StyledTooltip title='Gérer les rôles'>
              <StyledButton
                shape='circle'
                size='middle'
                icon={<FaUserTag />}
                onClick={() => handleModalRoles(record)}
              />
            </StyledTooltip>
            <StyledTooltip title='Gérer les Droits'>
              <StyledButton
                danger
                shape='circle'
                size='middle'
                icon={<FaUserLock />}
                onClick={() => handleModalRights(record)}
              />
            </StyledTooltip>
            {/* <StyledButton
              shape='circle'
              size='middle'
              danger
              icon={<FiTrash2 />}
              onClick={() => handleRemoveUser(record)}
            /> */}
          </StyledSpace>
        );
      },
    },
  ];

  const renderHeader = () => {
    return (
      <SectionHeader title='Utilisateur' count={usersData.length}>
        {/* <StyledButton
          icon={<BsPlus />}
          type='primary'
          onClick={() => navigateToview('NEW_ITEM')}>
          Ajouter un utilisateur
        </StyledButton> */}
      </SectionHeader>
    );
  };

  const renderContent = () => {
    return (
      <>
        <StyledDivider />
        <Table
          rowKey='id'
          bordered={false}
          columns={columnData}
          dataSource={usersData}
          size='small'
          pagination={false}
        />
      </>
    );
  };

  const renderUpdateRole = () => {
    return (
      <AddRemoveUserRole
        rolesData={rolesData}
        isModalVisible={showModalRoles}
        handCloseModalVisility={handleModalRoles}
        currentUser={selectedUser}
      />
    );
  };

  const renderUpdateRight = () => {
    return (
      <AddRemoveUserRight
        rightsData={rightsData}
        isModalVisible={showModalRights}
        handCloseModalVisility={handleModalRights}
        currentUser={selectedUser}
      />
    );
  };

  return (
    <>
      {renderHeader()}
      <Loader showSpinner={isLoading || isLoadingResources}>{renderContent()}</Loader>
      {showModalRoles && renderUpdateRole()}
      {showModalRights && renderUpdateRight()}
    </>
  );
};

export default UsersList;
