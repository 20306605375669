import { StyledLogoTitle } from '../menu/style';
import { StyledErrorHeaderWrapper } from './style';

const ErrorHeader = () => {
  return (
    <StyledErrorHeaderWrapper>
      <StyledLogoTitle>{'API PATIENT'}</StyledLogoTitle>
    </StyledErrorHeaderWrapper>
  );
};

export default ErrorHeader;
