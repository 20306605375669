import { App as AppContainer, Avatar, List } from 'antd';
import { useEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import { createUserAbsencesApi, getUserAbsenceByIdApi } from '../../api/userService';
import { StyledButton } from '../../components/button/style';
import SectionHeader from '../../components/header/SectionHeader';
import Loader from '../../components/loader';
import PageHeader from '../../components/pageHeader/PageHeader';
import { StyledCol, StyledRow, StyledSpace } from '../../components/style';
import TimeslotCalendar from '../../components/timeslotCalendar';
import VerticalLayout from '../../layout/VerticalLayout';
import chartColors from '../../styles/color';
import {
  CreateUserAbsenceRequestBody,
  GetUsersAbsencesResponse,
  IRoleItem,
  UserDto,
} from '../../types';
import {
  convertToHHMM,
  getApiErrorMsg,
  mapStateToRequestBody,
  stringifyRoles,
} from '../../utils/commun';
import { ErrorMsgTitle, SuccesssMsgTitle } from '../../utils/constants';
import { getDataFromStorage } from '../../utils/storage';
import { StyledMedicalRecordBlocContainer } from '../style';

type UserDetailsItemProps = { title: string; value: string | IRoleItem[] };

const Profil: React.FC = () => {
  const userData = getDataFromStorage<UserDto>('connectedUserData');
  const { notification } = AppContainer.useApp();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [userInitialAbsences, setUserInitialAbsences] =
    useState<GetUsersAbsencesResponse>([]);
  const [userAbsences, setUserAbsences] = useState<GetUsersAbsencesResponse>([]);

  const loadUserAbsences = async () => {
    try {
      setIsLoading(true);
      Promise.resolve(getUserAbsenceByIdApi(userData.id)).then(
        res => {
          const timesSlots = res.map(t => ({
            date: t.date,
            hour: convertToHHMM(t.hour),
          }));
          setUserInitialAbsences(timesSlots);
        },
        err => {
          notification.error({
            message: ErrorMsgTitle,
            description: getApiErrorMsg(err),
          });
          setIsLoading(false);
        },
      );
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
    } finally {
      setIsLoading(false);
    }
  };

  const handleValidateUserAbsences = () => {
    try {
      setIsLoading(true);
      Promise.resolve(
        createUserAbsencesApi(
          mapStateToRequestBody<CreateUserAbsenceRequestBody>({
            idUser: userData.id,
            timeSlots: userAbsences,
          }),
        ).then(() => {
          notification.success({
            message: SuccesssMsgTitle,
            description: 'Absences enregistrées avec succès !',
          });
          setIsLoading(false);
        }),
      );
    } catch (error) {
      notification.error({ message: ErrorMsgTitle, description: getApiErrorMsg(error) });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadUserAbsences();
  }, []);

  const userDetails: Array<UserDetailsItemProps> = [
    {
      title: 'Civilité',
      value: userData.firstname,
    },
    {
      title: 'Nom',
      value: userData.firstname,
    },
    {
      title: 'Prénoms',
      value: userData.name,
    },
    {
      title: 'Email',
      value: userData.email,
    },
    {
      title: 'Téléphone',
      value: userData.tel,
    },
    {
      title: 'Service',
      value: userData.serviceDto.name,
    },
    {
      title: 'Roles',
      value: userData.roles,
    },
  ];

  const renderProfilHeader = () => {
    return (
      <StyledSpace direction='horizontal'>
        <Avatar size={100} icon={<FaUser />} />
        <StyledSpace direction='vertical'>
          <div
            style={{ fontSize: '2.5em', fontFamily: 'Metropolis', fontWeight: 'bolder' }}>
            {userData?.firstname} {userData?.name}
          </div>
          <div
            style={{
              marginTop: '-20px',
              color: chartColors.blue,
              fontWeight: 'bolder',
              fontSize: '1.2em',
            }}>
            {userData?.serviceDto.name}
          </div>
        </StyledSpace>
      </StyledSpace>
    );
  };

  const renderHeader = () => {
    return (
      <StyledRow gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <StyledCol span={24}>
          <StyledMedicalRecordBlocContainer
            style={{
              backgroundColor: '#FFF',
              padding: '30px 20px',
            }}>
            <StyledCol span={24}>{renderProfilHeader()}</StyledCol>
          </StyledMedicalRecordBlocContainer>
        </StyledCol>
      </StyledRow>
    );
  };

  const renderValue = (item: UserDetailsItemProps) => {
    return item.title === 'Roles'
      ? stringifyRoles(item.value as IRoleItem[], 'LABEL').toString()
      : (item.value as string);
  };

  const renderContent = () => {
    return (
      <StyledRow gutter={[16, 16]}>
        <StyledCol
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}
          xxl={{ span: 12 }}>
          <StyledMedicalRecordBlocContainer
            style={{
              backgroundColor: '#FFF',
              padding: '10px 20px',
              height: '100%',
            }}>
            <StyledCol span={24}>
              <SectionHeader title='Détails du compte'>
                <StyledRow>
                  <StyledButton type='primary' size='small' icon={<MdEdit />}>
                    Modifier mes infos
                  </StyledButton>
                </StyledRow>
              </SectionHeader>
              <List
                itemLayout='horizontal'
                dataSource={userDetails}
                renderItem={(item, index) => (
                  <List.Item key={index}>
                    <List.Item.Meta title={item.title} description={renderValue(item)} />
                  </List.Item>
                )}
              />
            </StyledCol>
          </StyledMedicalRecordBlocContainer>
        </StyledCol>

        <StyledCol
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}
          xxl={{ span: 12 }}>
          <StyledRow gutter={[16, 16]}>
            {/* Section Absences */}
            <StyledCol span={24}>
              <StyledMedicalRecordBlocContainer
                style={{
                  backgroundColor: '#FFF',
                  padding: '10px 20px',
                  height: '100%',
                }}>
                <SectionHeader title='Absences' />
                <Loader showSpinner={isLoading}>
                  <TimeslotCalendar
                    callback={timeslots => setUserAbsences(timeslots)}
                    initialValues={userInitialAbsences}
                  />
                  <StyledButton
                    block
                    type='primary'
                    onClick={handleValidateUserAbsences}
                    disabled={isLoading}
                    loading={isLoading}>
                    Valider les absences
                  </StyledButton>
                </Loader>
              </StyledMedicalRecordBlocContainer>
            </StyledCol>
          </StyledRow>
        </StyledCol>
      </StyledRow>
    );
  };
  return (
    <VerticalLayout>
      <PageHeader title='Mon Profil' />
      <div
        style={{
          padding: '10px 20px',
          height: '100%',
        }}>
        {renderHeader()}
        {renderContent()}
      </div>
    </VerticalLayout>
  );
};

export default Profil;
