import _ from 'lodash';
import {
  CreateMedicationRequestBody,
  CreateMedicationResponse,
  GetMedicationsResponse,
  PaginationRequestBody,
} from '../types';
import { axiosInstance } from './axiosInstance';

export const getMedicationsApi = (
  data: PaginationRequestBody,
): Promise<GetMedicationsResponse> => {
  const urlSearchParams = new URLSearchParams();
  _.forEach(_.toPairs(data), ([key, value]) => {
    urlSearchParams.append(key, String(value));
  });

  return axiosInstance
    .get(`/medications?${urlSearchParams.toString()}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des médicaments. Raison: ${message}`);
    });
};

export const getMedicationsByDesignationApi = (
  data: PaginationRequestBody & { designation: string },
): Promise<GetMedicationsResponse> => {
  const urlSearchParams = new URLSearchParams();
  _.forEach(_.toPairs(data), ([key, value]) => {
    urlSearchParams.append(key, String(value));
  });

  return axiosInstance
    .get(`/medicationByDesignation?${urlSearchParams.toString()}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la recupération des médicaments. Raison: ${message}`);
    });
};

export const createMedicationApi = (
  data: CreateMedicationRequestBody,
): Promise<CreateMedicationResponse> => {
  return axiosInstance
    .post('/medication', data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la création d'un médicament. Raison: ${message}`);
    });
};

export const updateMedicationApi = (
  medicationId: number,
  data: CreateMedicationRequestBody,
): Promise<void> => {
  return axiosInstance
    .put(`/medication?id=${medicationId}`, { ...data })
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la modification d'un médicament. Raison: ${message}`);
    });
};

export const deleteMedicationApi = (medicationId: number): Promise<void> => {
  return axiosInstance
    .delete(`/medication?id=${medicationId}`)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      const message = err?.response ? err.response.data?.error : `${err}`;
      throw Error(`Erreur lors de la suppression d'un médicament. Raison: ${message}`);
    });
};
