import { Avatar } from 'antd';
import React from 'react';
import { FaUserInjured } from 'react-icons/fa';
import { FiChevronRight } from 'react-icons/fi';
import { PatientDto } from '../../types';
import { StyledButton } from '../button/style';
import {
  StyledPatientCardContainer,
  StyledPatientCardContent,
  StyledPatientCardName,
  StyledPatientCardPhone,
  StyledPatientInfosContainer,
} from './style';

interface PatientCardProps {
  infosPatient: PatientDto;
  handleSelectPatient: () => void;
  isSelected: boolean;
}

const PatientCard: React.FC<PatientCardProps> = ({
  infosPatient,
  handleSelectPatient,
  isSelected,
}) => {
  return (
    <StyledPatientCardContainer
      className={`group hover:text-white bg-white hover:bg-blue-500 ${
        isSelected && 'bg-blue-600 text-white'
      }`}
      onClick={() => handleSelectPatient()}>
      <StyledPatientCardContent>
        <Avatar shape='circle' size={65} icon={<FaUserInjured />} />
        <StyledPatientInfosContainer>
          <StyledPatientCardName>{`${infosPatient.name} ${infosPatient.firstname}`}</StyledPatientCardName>
          <StyledPatientCardPhone>{infosPatient.phoneNumber}</StyledPatientCardPhone>
        </StyledPatientInfosContainer>
      </StyledPatientCardContent>
      <StyledButton
        shape='circle'
        type={isSelected ? 'primary' : 'default'}
        icon={<FiChevronRight />}
      />
    </StyledPatientCardContainer>
  );
};

export default PatientCard;
