import { useState } from 'react';
import { StyledButton } from '../../../components/button/style';
import { StyledSteps } from '../../../components/style';
import { StyledMedicalRecordWrapper } from '../../style';
import DiagnosticExams from './DiagnosticExams';
import PrescriptionsHistory from './PrescriptionsHistory';

const stepsItems = [
  {
    title: 'Examens',
    content: <DiagnosticExams />,
  },
  {
    title: 'Prescriptions',
    content: <PrescriptionsHistory />,
  },
];

const ParaClinical: React.FC = () => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const next = () => {
    setActiveStep(activeStep + 1);
  };

  const prev = () => {
    setActiveStep(activeStep - 1);
  };

  const renderStepsFooter = () => {
    return (
      <div
        style={{
          marginBottom: 24,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        {activeStep >= 1 ? (
          <StyledButton onClick={() => prev()}>Précédent</StyledButton>
        ) : (
          <div></div>
        )}
        {activeStep < stepsItems.length - 1 && (
          <StyledButton type='primary' onClick={() => next()}>
            Suivant
          </StyledButton>
        )}
      </div>
    );
  };

  // const renderContent = () => {
  //   switch (currentStep) {
  //     case 0:
  //       return (
  //         <ParaClinicalExamHistory handleNewParaClinicalExam={() => setCurrentStep(1)} />
  //       );
  //     case 1:
  //       return (
  //         <CreateParaClinicalExam
  //           handleHistoryParaClinicalExam={() => setCurrentStep(0)}
  //         />
  //       );
  //     default:
  //       return null;
  //   }
  // };

  return (
    <>
      <StyledSteps
        current={activeStep}
        items={stepsItems}
        className='paraclinic__inline_step'
      />
      <StyledMedicalRecordWrapper>
        {renderStepsFooter()}
        {stepsItems[activeStep].content}
      </StyledMedicalRecordWrapper>
    </>
  );
};

export default ParaClinical;
