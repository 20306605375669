import type { ComponentToken as SegmentedComponentToken } from 'antd/es/segmented/style';
import type { OverrideToken } from 'antd/es/theme/interface';
import chartColors from '../styles/color';

const SegmentedToken: Partial<SegmentedComponentToken> = {
  itemActiveBg: chartColors.blue,
  itemSelectedBg: chartColors.blue,
};

export const StyledToken: OverrideToken = {
  Segmented: SegmentedToken,
};
